import { post ,get} from '../../until/axios_instans';

//分页查询用户信息
export function selectUserPage(data){
    return post('/api/ss/api/v1/user/selectUserPage',data);
}
//新增用户
export function insertUser(data){
    return post('/api/ss/api/v1/user/insertUser',data);
}
//更新用户
export function updateUser(data){
    return post('/api/ss/api/v1/user/updateUser',data);
}
//重置密码
export function resetPassword(data){
    return post('/api/ss/api/v1/user/resetPassword',data);
}
//用户停用与恢复
export function updateUserStatus(data){
    return post('/api/ss/api/v1/user/updateUserStatus',data);
}
//用户查询
export function getUserInfoById(data){
    return post('/api/ss/api/v1/user/getUserInfoById',data);
}
//修改密码
export function updatePassword(data){
    return post('/api/ss/api/v1/user/updatePassword',data);
}

//用户菜单
export function getPermissionButtonByUser(data){
    return post('/api/ss/api/v1/user/getPermissionButtonByUser',data);
}
// 上传头像
export function uploadFace(data) {
    return post('/api/ss/api/v1/user/uploadFace', data);
}

// 更新用户头像
export function savePortraitUrl(data) {
    return post('/api/ss/api/v1/user/savePortraitUrl', data);
}



