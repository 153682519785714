/*eslint eqeqeq: ["off", "smart"]*/
// 项目专业群
import React, {Component} from 'react';
import {Row, Col, Button, Form, Tooltip} from 'antd';
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';
import {getSpecialtyGroup} from '../../../../api/synchrodatment/index';

class Groupcontenttwo extends Component {
    state = {
        specialtyGroup:{},
        specialtyGroupDetailVos:[],
        degreeDic: JSON.parse(localStorage.getItem("allCommonDic")).degreeDic,
        taskId:'',
        schoolId: "",
        category: 0,
        specialtyType: 2, //专业群1,2取值不一样：专业群类型1专业群一 2专业群二
        upSeniorId:'4.1'
    };

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            taskId: jsonparam.taskId,
            schoolId: jsonparam.schoolId,
            category: jsonparam.category
        },() =>{
            this.getSpecialtyGroup();
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    getSpecialtyGroup = async () => {
        let {taskId, specialtyType} = this.state;
        let specialtyGroup = await getSpecialtyGroup({taskId: taskId, specialtyType: specialtyType});
        this.setState({specialtyGroupDetailVos: specialtyGroup.addSpecialtyGroupDetailVos, specialtyGroup})
    }

    // 预览第二页
    secondPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId,category:category};
        this.props.history.push({
            pathname: '/SpecialtyGroupScheduleTwo',
            search: JSON.stringify(param)
        })
    }

    render() {
        const {specialtyGroup, specialtyGroupDetailVos,taskId,schoolId,category,degreeDic,upSeniorId} = this.state;
        let academicDegreeName = null;
        if (degreeDic !== null && specialtyGroup != null && specialtyGroup.academicDegree != null) {
            let filter = degreeDic.filter(e => e.dicKey === specialtyGroup.academicDegree);
            academicDegreeName = filter[0].dicValue
        }
        return (
            <div className="Comment-GroupContent main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>
                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} upSeniorId={upSeniorId} category={category}/>}
                                </Col>
                                <Col span={16} className="right">
                                    <div className="content">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>11</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <div className="bottom">
                                            <h2>3.专业群二-建设任务书</h2>
                                            <div className="list">
                                                <h3>3-1 专业群二基本概况</h3>
                                                <ul className="ul-on">
                                                    <li>
                                                        <h4>专业群名称：<span>{specialtyGroup?specialtyGroup.specialtyName:""}</span></h4>
                                                        <h4>主要面向产业：<span>{specialtyGroup?specialtyGroup.facingIndustry:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        {/* todo 转换为汉字 */}
                                                        <h4>面向职业岗位群：
                                                            {
                                                                specialtyGroup ?
                                                                    <Tooltip title={specialtyGroup.postGroup}>
                                                                        <span>{specialtyGroup ? specialtyGroup.postGroup : ""}</span>
                                                                    </Tooltip>:
                                                                    <span>{specialtyGroup ? specialtyGroup.postGroup : ""}</span>
                                                            }
                                                        </h4>
                                                    </li>
                                                </ul>
                                                <h5>专业群二包含专业</h5>
                                                <ul>
                                                    {specialtyGroupDetailVos?specialtyGroupDetailVos.map((item, i) =>(
                                                        <div key={i}>
                                                            <li>
                                                                <h4>专业代码：<span>{item.professionCode}</span></h4>
                                                                <h4>专业名称：<span>{item.professionName}</span></h4>
                                                            </li>
                                                            <li>
                                                                <h4>所在院系：<span>{item.department}</span></h4>
                                                                <h4>所属专业大类：<span>{item.bigProfession}</span></h4>
                                                            </li>
                                                        </div>
                                                    )):"暂未填写"}
                                                </ul>
                                                <h5>专业群二建设负责人</h5>
                                                <ul>
                                                    <li>
                                                        <h4>姓名：<span>{specialtyGroup?specialtyGroup.userName:""}</span></h4>
                                                        <h4>性别：<span>{specialtyGroup && specialtyGroup.sex === 1? "男" :specialtyGroup.sex === 2? "女" : "未知"}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>出生日期：<span>{specialtyGroup?specialtyGroup.birthday:""}</span></h4>
                                                        <h4>学历：<span>{specialtyGroup?specialtyGroup.education:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>学位：<span>{academicDegreeName}</span></h4>
                                                        <h4>行政职务：<span>{specialtyGroup?specialtyGroup.administrativePosition:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>联系电话：<span>{specialtyGroup?specialtyGroup.phone:""}</span></h4>
                                                        <h4>专业技术职务：<span>{specialtyGroup?specialtyGroup.professionalTechnology:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>办公电话：<span>{specialtyGroup?specialtyGroup.workPhone:""}</span></h4>
                                                        <h4>QQ号码：<span>{specialtyGroup?specialtyGroup.qqNumber:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>职业技能技术：<span>{specialtyGroup?specialtyGroup.occupationSkill:""}</span></h4>
                                                        <h4>电子邮箱：<span>{specialtyGroup?specialtyGroup.email:""}</span></h4>
                                                    </li>
                                                </ul>
                                                <h3>3-1 专业群二-建设总目标</h3>
                                                <div className="cont">
                                                    {specialtyGroup?specialtyGroup.constructionObjectives:"暂未填写"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" onClick={()=> this.secondPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

Groupcontenttwo = Form.create()(Groupcontenttwo);
export default Groupcontenttwo;
