import {get, post } from '../../until/axios_instans';

//自定义图表——分析维度
export function queryOneDimension(data){
    return post('/api/ss/api/v1/chartdimension/queryOneDimension',data);
}
//自定义图表——数据设置
export function queryTwoDimension(data){
    return post('/api/ss/api/v1/chartdimension/queryTwoDimension',data);
}
//自定义图表——预览图表
export function preViewCustomChart(data){
    return post('/api/ss/api/v1/chart/preViewCustomChart',data);
}
//自定义图表——查询图表详情
export function queryCustomChartDetail(data){
    return post('/api/ss/api/v1/chart/queryCustomChartDetail',data);
}
//自定义图表——查询图表基本信息
export function queryCustomChart(data){
    return post('/api/ss/api/v1/chart/queryCustomChart',data);
}
//自定义图表——查询所有图表
export function queryAllCustomChart(data){
    return post('/api/ss/api/v1/chart/queryAllCustomChart',data);
}
//自定义图表——删除图表
export function deleteChart(data){
    return post('/api/ss/api/v1/chart/deleteChart',data);
}
//自定义图表——保存图表
export function saveCustomChart(data){
    return post('/api/ss/api/v1/chart/saveCustomChart',data);
}
//自定义图表——编辑图表名称
export function editCustomChartName(data){
    return post('/api/ss/api/v1/chart/editCustomChartName',data);
}
//清除筛选值
export function clearChartFilterValues(data){
    return post('/api/ss/api/v1/chartcondition/clearChartFilterValues',data);
}
//获取筛选条件，通过图表类型和维度类型
export function queryConfitionByCTAndDT(data){
    return post('/api/ss/api/v1/chartcondition/queryConfitionByCTAndDT',data);
}

//获取筛选条件的值，通过图表ID或（图表类型和维度类型）
export function queryConfitionValues(data){
    return post('/api/ss/api/v1/chartcondition/queryConfitionValues',data);
}
//添加筛选条件
export function addChartConfition(data){
    return post('/api/ss/api/v1/chartcondition/addChartConfition',data);
}
//清除筛选条件的值
export function clearChartFilter(data){
    return post('/api/ss/api/v1/chartcondition/clearChartFilter',data);
}
//编辑筛选条件
export function editChartConfition(data){
    return post('/api/ss/api/v1/chartcondition/editChartConfition',data);
}
//编辑筛选条件的值
export function editChartConfitionValues(data){
    return post('/api/ss/api/v1/chartcondition/editChartConfitionValues',data);
}
//任务与绩效进展走势
export function queryTaskMeritTrend(data){
    return post('/api/ss/api/v1/chart/queryTaskMeritTrend',data);
}
//任务与绩效进展走势——详情
export function queryTaskMeritTrendDetail(data){
    return post('/api/ss/api/v1/chart/queryTaskMeritTrendDetail',data);
}

//各建设任务支出占比
export function queryProjectTaskExpendRate(data){
    return post('/api/ss/api/v1/chart/queryProjectTaskExpendRate',data);
}
//各建设任务支出占比——详情
export function queryProjectTaskExpendRateDetail(data){
    return post('/api/ss/api/v1/chart/queryProjectTaskExpendRateDetail',data);
}
//查询绩效指标完成度
export function queryMeritCompleteDu(data){
    return post('/api/ss/api/v1/chart/queryMeritCompleteDu',data);
}
//查询绩效指标完成度——详情
export function queryMeritCompleteDuDetail(data){
    return post('/api/ss/api/v1/chart/queryMeritCompleteDuDetail',data);
}
//查询一级维度
export function queryOneDimesion(data){
    return post('/api/ss/api/v1/chart/queryOneDimesion',data);
}
//查询二级维度
export function queryTwoDimesion(data){
    return post('/api/ss/api/v1/chart/queryTwoDimesion',data);
}
//图表筛选条件查询
export function queryChartCondition(data){
    return post('/api/ss/api/v1/chart/queryChartCondition',data);
}
//自定义图表新增
export function addChart(data){
    return post('/api/ss/api/v1/chart/addChart',data);
}
//图表编辑
export function editChart(data){
    return post('/api/ss/api/v1/chart/editChart',data);
}
//查询学校任务图表
export function queryTaskChart(data){
    return post('/api/ss/api/v1/chart/queryTaskChart',data);
}
//概况图表建设任务完成进度
export function surveyProjectQueryYear(data){
    return post('/api/ss/api/v1/chart/surveyProjectQueryYear',data);
}
//概况图表绩效完成进度
export function surveyMeritQueryYear(data){
    return post('/api/ss/api/v1/chart/surveyMeritQueryYear',data);
}

//大屏学校基本信息
export function chartSchoolInfo(data){
    return post('/api/ss/api/v1/chart/chartSchoolInfo',data);
}

//概况图表查建设任务进度
export function surveyProjectQuery(data){
    return post('/api/ss/api/v1/chart/surveyProjectQuery',data);
}

//概况图表绩效完成进度
export function surveyMeritQuery(data){
    return post('/api/ss/api/v1/chart/surveyMeritQuery',data);
}

//概况图表资金使用情况
export function surveyExpendQuery(data){
    return post('/api/ss/api/v1/chart/surveyExpendQuery ',data);
}
//绩效指标达标情况
export function meritIndicatorsQuery(data){
    return post('/api/ss/api/v1/chart/meritIndicatorsQuery ',data);
}
//数据大屏设置查询
export function queryScreenInfo(data){
    return post('/api/ss/api/v1/chartAuth/queryScreenInfo ',data);
}
//报表管理及公开级别设置
export function setScreenLevel(data){
    return post('/api/ss/api/v1/chartAuth/setScreenLevel ',data);
}
//数据大屏设置密码
export function setScreenLevelPwd(data){
    return post('/api/ss/api/v1/chartAuth/setScreenLevelPwd ',data);
}
//设置报表展示/隐藏
export function setChartDisplay(data){
    return post('/api/ss/api/v1/chartAuth/setChartDisplay ',data);
}
//查询学校公开级别
export function queryAccessLevel(data){
    return post('/api/ss/api/v1/chartAuth/queryAccessLevel ',data);
}
//大屏图访问
export function accessChart(data){
    return post('/api/ss/api/v1/chartAuth/accessChart ',data);
}
//设置学校公开级别
export function setAccessLevel(data){
    return post('/api/ss/api/v1/chartAuth/setAccessLevel ',data);
}
//校验大屏密码
export function checkPwd(data){
    return post('/api/ss/api/v1/chartAuth/checkPwd ',data);
}
//excel导出
export function exportExcel(data){
    return post('/api/ss/api/v1/chart/exportExcel ',data);
}

//大屏需要返回的数据
export function largeScreenList(data){
    return post('/api/ss/api/v1/chart/largeScreenList ',data);
}
//查询默认id
export function queryDefaultIconId(data){
    return post('/api/ss/api/v1/chart/queryDefaultIconId ',data);
}




