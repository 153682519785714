/*绩效管理详情*/
import React, {Component} from "react";
import {Avatar, Button, Cascader, DatePicker, Form, Icon, Input, Modal, Select, Tooltip, Tabs} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {
    SchoolIcon,
    Indicator,
    TargetValue,
    TargetedUnit,
    Realization,
    Completeness,
    TaskPrincipal,
    Description, SchedulePlanning
} from "../../../../assets/svg";
import {
    meritGetInfoById, meritDeleteById, completionMerit, activationMerit, meritSave,
    meritIndicatorInfo, meritUpdateById, meritAssociatedTaskInfo, meritSonGetInfo, meritDynamicInfo, meritUpdateDirector,getCommonDic
} from '../../../../api/merit/';
import {queryCatgoryById} from "../../../../api/synchrodatment";
import {getDhAllCommonDic} from '../../../../api/synchrodatment'
import {selectUserList} from '../../../../api/project/';
import S from "gongyongicon";
import CompleteModal from '../../components/performanceDetails/components/completeModal'
import Cookies from "js-cookie";
import moment from "moment";

const {TextArea} = Input;
const {TabPane} = Tabs;
const {Option} = Select;
const {RangePicker} = DatePicker;

@withRouter
class SpecificDetails extends Component {
    state = {
        activationVisable: false,//激活绩效弹窗
        completeVisable: false,//完成绩效弹窗
        completeActivation: 0,//0显示完成绩效，1显示激活绩效
        deleteVisable: false,//删除绩效弹唱
        ModalVisible: false,//编辑绩效
        indexOptions: [],
        PrincipalModalVisible: false,//请选择负责人
        editModalTitel: '编辑绩效',
        meritId: this.props.match.params.id,
        meritList: [],
        datepickerInit:[],
        meritTypes: [
            {'label': '学校', 'value': 1},
            {'label': '专业群一', 'value': 2},
            {'label': '专业群二', 'value': 3},
            {'label': ' 其他', 'value': 4}

        ],
        meritTypess: [
            {'label': '学校', 'value': 1},
            {'label': '专业群', 'value': 2},
            {'label': ' 其他', 'value': 4}

        ],
        taskStatus: [
            {'label': '进行中', 'value': 1},
            {'label': '已暂停', 'value': 2},
            {'label': '已完成', 'value': 3},
            {'label': '已逾期', 'value': 4}

        ],
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        isAdmin: JSON.parse(Cookies.get('ssInfo')).userInfo.isAdmin,
        queryCatgory: {},
        userInfoList: [],
        statusButten: true,
        meritAssociatedTask: [],
        meritSonList: [],
        meritDynamicList: [],
        updateDirectorId: '',
        indicatorName: '',
        getDhAllCommonDic: [],
        levelValue:'',
        projectId:  JSON.parse(Cookies.get('merit')).projectId,
        startTime:undefined,
        endTime:undefined,
        endOpen: false,
        time:[],
        startDate:undefined,
        endDate:undefined,
        endValue:undefined,
        startValue:undefined,
        taskDocumentList: [
            {
                createrId: "1",
                createrName: "卧龙凤雏",
                fileAuth: 0,
                fileByte: 179880,
                id: "1474280402465726466",
                resourceName: "教育接口-1215(1).docx"

            }
        ],
    };

    // 初始化
    componentDidMount() {
        this.meritGetInfoById();
        this.queryCatgoryById();
        this.meritAssociatedTaskInfo();
        this.meritSonGetInfo();
        this.meritDynamicInfo();

    }

    //完成绩效按钮ok
    tocompleteOk = async () => {
        let data = this.formRef.getFormValues();
        if (data){
            await completionMerit(data).then(res => {
                this.meritGetInfoById();
                this.queryCatgoryById();
                this.meritAssociatedTaskInfo();
                this.meritSonGetInfo();
                this.meritDynamicInfo();
                this.setState({completeVisable: false})
            })
        }
    }
    //激活绩效按钮ok
    toactivationOk = async () => {
        let data = {
            id: this.state.meritId,
        }
        await activationMerit(data).then(res => {
            this.meritGetInfoById();
            this.queryCatgoryById();
            this.meritAssociatedTaskInfo();
            this.meritSonGetInfo();
            this.meritDynamicInfo();
            this.setState({activationVisable: false})
        })
    }
    //删除绩效按钮OK
    deleteOK = () => {
        this.setState({deleteVisable: false})
    }
    //取消完成、删除绩效
    hideModal = () => {
        this.setState({
            completeVisable: false,
            deleteVisable: false,
            ModalVisible: false,
            PrincipalModalVisible: false,
            activationVisable: false
        })
    }
    //返回主页面
    backTask = () => {
        this.props.history.go(-1)
    };
    meritGetInfoById = async () => {
        let data = {
            id: this.state.meritId,
        }
        await meritGetInfoById(data).then(res => {
            this.setState({
                meritList: res,
                levelValue:res.meritType
            })
        })
    }


    //统计单位
    async getCommonDic() {
        let date={
            groupName:'unit',
        }
        const unit = await getCommonDic(date);
        if (unit) {
            this.setState({
                getDhAllCommonDic: unit,
            });
        }
    }

    meritAssociatedTaskInfo = async () => {
        let data = {
            id: this.state.meritId,
        }
        await meritAssociatedTaskInfo(data).then(res => {
            this.setState({meritAssociatedTask: res})
        })
        console.log('meritAssociatedTask',this.state.meritAssociatedTask)
    }


    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }

    //确认删除
    deleteOk = async () => {
        let data = {
            id: this.state.meritId,
        }
        await meritDeleteById(data).then(res => {
            this.setState({
                deleteVisable: false
            })
            this.props.history.push({pathname: '/main/doublehigh/performancelist'})
            this.mesSuccess('删除成功');
        })
    }

    //负责人下拉
    async selectUserList() {
        let params = {
            schoolId: this.state.schoolId,
        };
        const page = await selectUserList(params);
        if (page) {
            this.setState({
                userInfoList: page,
            });
        }
    }

    //子任务查询
    async meritSonGetInfo() {
        let params = {
            id: this.state.meritId,
        };
        const page = await meritSonGetInfo(params);
        if (page) {
            this.setState({
                meritSonList: page,
            });
        }
    }

    //动态查询
    async meritDynamicInfo() {
        let params = {
            id: this.state.meritId,
        };
        const page = await meritDynamicInfo(params);
        if (page) {
            this.setState({
                meritDynamicList: page,
            });
        }
    }


    //编辑绩效层面选择回调
    levelonChange = (value) => {

        this.setState({
            levelValue: value,
            meritOneIndicatorId:undefined,
            meritTwoIndicatorId:undefined,
            meritThreeIndicatorId:undefined,
            oneIndicatorName: undefined,
            twoIndicatorName: undefined,
            threeIndicatorName: undefined,
            indicatorName:undefined,
        }, () => {
            this.meritIndicatorInfo()
        })
    }
    //待认领跳转
    toBeClaimed = () => {
        this.setState({PrincipalModalVisible: true}, () => {
            this.selectUserList()
        })
    }

    //负责人确定
    claimOk = async () => {
        if (!this.state.updateDirectorId) {
            this.mesError("请选择负责人")
            return false
        }
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let meritObj = {
                //表单里的
                id: this.state.meritList.id,
                directorId: this.state.updateDirectorId,
            };
            await meritUpdateDirector(meritObj).then(res => {
                this.mesSuccess('修改成功');
                this.setState({
                    PrincipalModalVisible: false,
                });
            }).then((res) => {
                this.meritGetInfoById();
                this.queryCatgoryById();
                this.meritAssociatedTaskInfo();
                this.meritSonGetInfo();
                this.meritDynamicInfo();
            })

        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }

    //选择负责人
    onUserChanges = (e) => {
        this.setState({updateDirectorId: e})
    }

    //指标下拉
    async meritIndicatorInfo() {
        let params = {
            schoolId: this.state.schoolId,
            meritTypeId: this.state.levelValue,
        };
        const date = await meritIndicatorInfo(params);
        if (date) {
            this.setState({
                indexOptions: date,
            });
        }
    }

    //新增界面跳转
    saveMerit = () => {
        this.selectUserList();
        this.meritIndicatorInfo();
        this.getCommonDic();
        this.props.form.setFieldsValue({
            id: this.state.meritList.id,
            meritName: undefined,
            meritType: undefined,
            targetValue: undefined,
            indicatorMeasure: undefined,
            meritDescribe: undefined,
            directorId: undefined,
            initialValue: undefined,
            oneIndicatorName: undefined,
            twoIndicatorName: undefined,
            threeIndicatorName: undefined,
            indicatorName:undefined,
        })
        this.setState({
            ModalVisible: true,
            editModalTitel: '新增绩效',
            endValue:undefined,
            startValue:undefined,
            startDate:undefined,
            endDate:undefined,
        })



    }

    //编辑界面跳转
    updateMerit =async () => {
        this.selectUserList();
        this.meritIndicatorInfo();
        this.getCommonDic();
        let data = {
            id: this.state.meritId,
        }
        await meritGetInfoById(data).then(res => {
            let indicatorName = [res.oneIndicatorName, res.twoIndicatorName,res.threeIndicatorName]
            this.props.form.setFieldsValue({
                id: res.id,
                meritName: res.meritName,
                meritType: res.meritType,
                threeIndicatorName:res.threeIndicatorName,
                targetValue: res.targetValue,
                indicatorMeasure: res.indicatorMeasure,
                meritDescribe: res.meritDescribe,
                directorId: res.directorId,
                indicatorName: indicatorName,

            })
            this.setState({
                ModalVisible: true,
                editModalTitel: '编辑绩效',
                indicatorName: indicatorName,
                endValue:res.endTime?res.endTime:undefined,
                startValue:res.beginTime?res.beginTime:undefined,
                startDate:res.beginTime?res.beginTime:undefined,
                endDate:res.endTime?res.endTime:undefined,
            })
            this.setState({
                meritList: res,
                levelValue:res.meritType
            })
        })

    }

    // Cascader显示最后一级
    displayRender = (label) => {
        if (label.length==1){
            this.state.oneIndicatorName=label[label.length - 1]
            this.state.twoIndicatorName=undefined
            this.state.threeIndicatorName=undefined
        }
        if (label.length==2){
            this.state.oneIndicatorName=label[label.length - 2]
            this.state.twoIndicatorName=label[label.length - 1]
            this.state.threeIndicatorName=undefined
        }
        if (label.length==3){
            this.state.oneIndicatorName=label[label.length - 3]
            this.state.twoIndicatorName=label[label.length - 2]
            this.state.threeIndicatorName=label[label.length - 1]
        }
        return label[label.length - 1];
    }

    updateOk = async () => {
        if (this.state.editModalTitel === '编辑绩效') {
            if (this.state.statusButten) {
                //开始时间和结束时间处理
                if (this.state.startValue && !this.state.endValue) {
                    this.mesWarning("请填入结束时间")
                    return undefined
                }
                if (!this.state.startValue && this.state.endValue) {
                    this.mesWarning("请填入开始时间")
                    return undefined
                }
                this.setState({statusButten: false})
                let formValue = this.props.form.getFieldsValue()
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        let meritObj = {
                            //表单里的
                            id: this.state.meritList.id,
                            meritName: formValue.meritName,
                            directorId: formValue.directorId,
                            meritType: formValue.meritType,
                            oneIndicatorName: this.state.oneIndicatorName,
                            twoIndicatorName: this.state.twoIndicatorName,
                            threeIndicatorName: this.state.threeIndicatorName,
                            targetValue: formValue.targetValue,
                            indicatorMeasure: formValue.indicatorMeasure,
                            meritDescribe: formValue.meritDescribe,
                            //Cookies 里的
                            schoolId: this.state.schoolId,
                            createrId: this.state.userId,
                            beginTime:this.state.startDate,
                            endTime:this.state.endDate,
                        };

                        meritUpdateById(meritObj).then(res => {
                            this.mesSuccess('修改成功');
                            this.setState({
                                ModalVisible: false,
                                editModalTitel: '',
                            });
                        }).then((res) => {
                            this.meritGetInfoById();
                            this.queryCatgoryById();
                            this.meritAssociatedTaskInfo();
                            this.meritSonGetInfo();
                            this.meritDynamicInfo();
                        })
                    }
                })

            }
            setTimeout(() => {   // 设置延迟事件，5秒后将执行
                this.setState({statusButten: true})   // 将isChange设置为true
            }, 3000);
        } else {
            if (this.state.statusButten) {
                //开始时间和结束时间处理
                if (this.state.startValue && !this.state.endValue) {
                    this.mesWarning("请填入结束时间")
                    return undefined
                }
                if (!this.state.startValue && this.state.endValue) {
                    this.mesWarning("请填入开始时间")
                    return undefined
                }
                this.setState({statusButten: false})
                let formValue = this.props.form.getFieldsValue()
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        let meritObj = {
                            projectId:this.state.projectId,
                            //自定义的
                            parentId: this.state.meritId,
                            //表单里的
                            meritName: formValue.meritName,
                            directorId: formValue.directorId,
                            meritType: formValue.meritType,
                            oneIndicatorName: this.state.oneIndicatorName,
                            twoIndicatorName: this.state.twoIndicatorName,
                            threeIndicatorName: this.state.threeIndicatorName,
                            targetValue: formValue.targetValue,
                            indicatorMeasure: formValue.indicatorMeasure,
                            meritDescribe: formValue.meritDescribe,
                            //Cookies 里的
                            schoolId: this.state.schoolId,
                            createrId: this.state.userId,
                            beginTime:this.state.startDate,
                            endTime:this.state.endDate,
                        };

                        meritSave(meritObj).then(res => {
                            this.mesSuccess('添加成功');
                            this.props.form.resetFields()
                            this.setState({
                                ModalVisible: false,
                                editModalTitel: '',
                                parentId: '',
                                meritName: '',
                                directorId: '',
                                meritType: '',
                                oneIndicatorName: '',
                                twoIndicatorName: '',
                                threeIndicatorName: '',
                                targetValue: '',
                                indicatorMeasure: '',
                                meritDescribe: '',
                                startDate:undefined,
                                endDate:undefined,
                                datepickerInit:[],
                            });
                            this.meritGetInfoById();
                            this.queryCatgoryById();
                            this.meritAssociatedTaskInfo();
                            this.meritSonGetInfo();
                            this.meritDynamicInfo();
                        })


                    }
                })

            }
            setTimeout(() => {   // 设置延迟事件，5秒后将执行
                this.setState({statusButten: true})   // 将isChange设置为true
            }, 3000);
        }


    }
    getChildInfo = (id) => {
        this.state.meritId = id
        this.meritGetInfoById();
        this.queryCatgoryById();
        this.meritAssociatedTaskInfo();
        this.meritSonGetInfo();
        this.meritDynamicInfo();
    }

    gotoTaskDetails = (id,projectId) => {
        this.props.history.push({pathname: '/main/doublehigh/operation/'+projectId+'/'+ id + '/?'+ this.state.meritId})
    }
    deleteResource = () =>{
        this.mesSuccess("删除成功")
    }

    startOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    //日期
    dateOnChangeOf = (value, dateString) => {
        console.log("value",value)
        this.state.startDate = value[0]?value[0].format('yyyy-MM-DD 00:00:00'):undefined
        this.state.endDate = value[1]?value[1].format('yyyy-MM-DD 00:00:00'):undefined
        this.setState({datepickerInit: value})
    }
    //日期框
    //日期框
    disabledEndDate = (endValue) => {
        const {startValue} = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    disabledStartDate = (startValue) => {
        const {endValue} = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };
    onChange = (field, value) => {
        this.setState({[field]: value})
    };
    onStartChange = (value) => {
        this.onChange("startValue", value);
        this.state.startDate = value?value.format('yyyy-MM-DD 00:00:00'):undefined
    };

    onEndChange = (value) => {
        this.onChange("endValue", value);
        this.state.endDate = value?value.format('yyyy-MM-DD 00:00:00'):undefined
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({endOpen: open});
    };
    onChangeTab = (e) => {
        this.state.projectStatus = e
        this.state.pageNum = 1
        // this.selectProjectPageList()
    }
    render() {
        const {
            ModalVisible, indexOptions, PrincipalModalVisible, editModalTitel, meritTypess, meritTypes, meritList, queryCatgory, userInfoList, meritDynamicList,
            meritAssociatedTask, taskStatus, meritSonList, indicatorName, getDhAllCommonDic,isAdmin,taskInfo,datepickerInit,endValue,startValue
        } = this.state
        const {getFieldDecorator} = this.props.form;
        const columns = [

        ]
        return (
            <div className='SpecificDetails' style={{'background': '#f0f2f5'}}>
                <div className="content">
                    <div className="cont-top">
                        <div className='button-topL' onClick={this.backTask}>
                            <Icon type="rollback"/>
                            <span> 返回</span></div>

                        <div className='button-topR'>
                            {meritList.meritStatus == 0 ?
                                <Button className={meritList.isDirectorId==0&&isAdmin==0?'disabled-btn':'btnN'} disabled={meritList.isDirectorId==0&&isAdmin==0}
                                        onClick={() => this.setState({completeVisable: true})}>完成绩效</Button> :
                                <Button className={meritList.isDirectorId==0&&isAdmin==0?'disabled-btn':'btnN'} disabled={meritList.isDirectorId==0&&isAdmin==0}
                                        onClick={() => this.setState({activationVisable: true})}>提交绩效</Button>
                            }
                            <Button className={meritList.isDirectorId==0&&isAdmin==0?'disabled-btn':'btnN'}  disabled={meritList.isDirectorId==0&&isAdmin==0} onClick={() => this.updateMerit()}>编辑绩效</Button>
                            {/*<Button className={(meritList.isDirectorId==0&&isAdmin==0)||(meritList.isNew==0)?'disabled-btn':'btnN'} disabled={(meritList.isDirectorId==0&&isAdmin==0)||(meritList.isNew==0)}  onClick={() => this.setState({deleteVisable: true})}>删除绩效</Button>*/}
                        </div>

                    </div>
                    <div className="cont-bottom">
                        <div className="cont-bottomL">
                            <div className='cont-bottomLT'>
                                <p></p>
                                <div className='titlee'
                                     style={{'fontSize': '20px', 'color': '#333333', 'Weight': '500'}}>绩效详情
                                </div>
                            </div>
                            <div className='cont-bottomLM'>
                                <div className='titleT'>
                                    <div className='taskidd'>{meritList.meritCode}</div>
                                    {
                                        meritList.meritName && meritList.meritName.length>60
                                            ?
                                            <Tooltip placement="bottomLeft" title={meritList.meritName}
                                                     overlayClassName='TitleTooltip'
                                            >
                                                <div className='taskidT'>{meritList.meritName}</div>
                                            </Tooltip>
                                            :
                                            <div className='taskidT'>{meritList.meritName}</div>
                                    }
                                </div>
                                <div className='titleB'>
                                    <div className='titleBDiv'>
                                        <span className='titleB0'><TaskPrincipal/><span>负责人：</span></span>
                                        {meritList.portraitId ? <Avatar icon="user" src={`${window.$$imgSrc(meritList.portraitId)}`}
                                                                        className={'user'}/> :
                                            <Avatar icon="user"
                                                    className={'user'}/>}
                                        {meritList.directorName ?
                                            <span>{meritList.directorName}</span> :
                                            <span className='claim' onClick={() => this.toBeClaimed()}>待认领</span>}
                                    </div>
                                    <div className='titleBDiv'><span
                                        className='titleB0'><SchedulePlanning/><span>日程规划：</span></span>
                                        {meritList&&meritList.beginTime?<span>{meritList.beginTime.split(' ')[0]} 至 {meritList.endTime.split(' ')[0]}</span>:<span>-- 至 --</span>}
                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <SchoolIcon/>
                                                    <span>层面：</span>
                                                </span>
                                        {queryCatgory === 1 ? this.state.meritTypes.map
                                        ((item, index) => {
                                            if (meritList.meritType === item.value) {
                                                return <span key={index}>{item.label}</span>
                                            }
                                        }) : this.state.meritTypess.map
                                        ((item, index) => {
                                            if (meritList.meritType === item.value) {
                                                return <span key={index}>{item.label}</span>
                                            }
                                        })}
                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <Indicator/>
                                                    <span>指标层级：</span>
                                                </span>
                                        {meritList.oneIndicatorName!==null&&meritList.twoIndicatorName!==null&&meritList.threeIndicatorName!==null?
                                            <span>{meritList.oneIndicatorName} / {meritList.twoIndicatorName} / {meritList.threeIndicatorName} </span>
                                            :meritList.oneIndicatorName!==null&&meritList.twoIndicatorName!==null&&meritList.threeIndicatorName===null?
                                                <span>{meritList.oneIndicatorName} / {meritList.twoIndicatorName}</span>
                                                :meritList.oneIndicatorName!==null&&meritList.twoIndicatorName===null&&meritList.threeIndicatorName===null?
                                                    <span>{meritList.oneIndicatorName}</span>
                                                    :''
                                        }


                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <TargetValue/>
                                                    <span>目标值：</span>
                                                </span>
                                        {meritList.targetValue&&meritList.targetValue.length>65?
                                            <Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={meritList.targetValue}>
                                                <span>{meritList.targetValue}</span>
                                            </Tooltip>:<span>{meritList.targetValue}</span>}
                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <TargetedUnit/>
                                                    <span>统计单位：</span>
                                                </span>
                                        <span>{meritList.indicatorMeasure}</span>
                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <Realization/>
                                                    <span>实现值：</span>
                                                </span>

                                        {meritList.completeValue?meritList.completeValue.length>65?
                                                <Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={meritList.completeValue}>
                                                    <span>{meritList.completeValue}</span>
                                                </Tooltip>: <span>{meritList.completeValue}</span>
                                            :<span>--</span>}
                                    </div>
                                    <div className='titleBDiv'>
                                                <span className='titleB0'>
                                                    <Completeness/>
                                                    <span>完成度：</span>
                                                </span>
                                        {meritList.completeRate ? <span>{meritList.completeRate}%</span> :
                                            <span>--</span>}
                                    </div>
                                    <div className='titleBDiv'>
                                        <div className='titleBDF'>
                                                    <span className='titleB0'>
                                                        <Description/>
                                                        <span>描述：</span>
                                                    </span>
                                            {meritList.meritDescribe?<span className='titleBtaskDescribe'>{meritList.meritDescribe}
                                            </span>:<span className='titleBtaskDescribe'>--</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='cont-bottomLB'>
                                <div className='cont-bottomLBTitle'>
                                    <p className='titleL'>佐证材料/案例</p>
                                    <div className='titleR'
                                         onClick={() => this.saveMerit()}>
                                        <Icon type="plus-circle"/>
                                        <span>添加佐证材料</span>
                                    </div>
                                </div>

                                <div className={'dynamic-cont'}>
                                    <ul className={'glzjlist'}>

                                        {
                                            this.state.taskDocumentList && this.state.taskDocumentList.length > 0 ? this.state.taskDocumentList.map((item) => (
                                                <li className="cont-bottomRT2UI">
                                                    <a onClick={() => this.getToDocumentInfo(item)} style={{'color':'#333333'}}> <span> {item.resourceName}</span></a>
                                                    <div className="cont-bottomRT2UID">
                                                        {
                                                            <div className='cont-bottomRT2UIDD'>
                                                                <a href={`${window.$$fujinDownload(item.uploadFileId + "/" + item.resourceName)}`}><span
                                                                    className='downloadColor11'> 下载</span></a>
                                                            </div>
                                                        }
                                                        <span className="bk"></span>
                                                        {
                                                            <div className='cont-bottomRT2UIDD'
                                                                 style={{"cursor": "pointer"}}>
                                                                    <span
                                                                        onClick={() => this.deleteResource(item.id)}> 删除</span>
                                                            </div>
                                                        }
                                                    </div>

                                                </li>
                                            )) : ""
                                        }
                                    </ul>
                                </div>
                                <div style={{display:'none'}} className='zirenwuList'>
                                    <ul>
                                        {meritSonList ? meritSonList.map((item) => (
                                            <li>
                                                <div className='zirenwuListTitle'>
                                                    <span className={'taskCode'}>{item.meritCode}</span>
                                                </div>
                                                <div className='zirenwuListTitle zirenwuListTitle1'>
                                                    {
                                                        item.meritName && item.meritName.length>30
                                                            ?
                                                            <Tooltip placement="bottomLeft" title={item.meritName}
                                                                     overlayClassName='TitleTooltip' className='zrws'>
                                                                <a onClick={() => this.getChildInfo(item.id)}><span
                                                                    className='zrw' style={{'color':'#333333'}}>{item.meritName}</span></a>
                                                            </Tooltip>
                                                            :
                                                            <a onClick={() => this.getChildInfo(item.id)}><span
                                                                className='zrw' style={{'color':'#333333'}}>{item.meritName}</span></a>
                                                    }

                                                </div>
                                                <div className={'zirenwuListright'}>
                                                    {item.completeRate ? <span>{item.completeRate}%</span> :
                                                        <span>--</span>}
                                                    {item.portraitId?<Avatar size="small" icon="user" src={`${window.$$imgSrc(item.portraitId)}`}
                                                                             style={{'margin': '-3px 20px 0'}}/>:
                                                        <Avatar size="small" icon="user"
                                                                style={{'margin': '-3px 20px 0'}}/>}
                                                </div>
                                            </li>)) : ""}
                                    </ul>

                                </div>

                            </div>
                        </div>
                        <div className={'cont-bottomR'}>
                            <div className={'cont-bottomRT'}>
                                <div className={'titleR'}>关联信息</div>
                                <p className={'glnum'}>该绩效被关联 {meritList.taskRelationTotal}次，被以下任务关联</p>
                                <ul className={'glullist'}>
                                    {meritAssociatedTask ? meritAssociatedTask.map((item) => (
                                        <li>

                                            {item.taskName&&item.taskName.length>35?
                                                <Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={item.taskName} onClick={() => this.gotoTaskDetails(item.id,item.projectId,1)}>
                                                    <div className={'renwuname'}>{item.taskName} onClick={() => this.gotoTaskDetails(item.id,item.projectId,1)}</div></Tooltip>:
                                                <div className={'renwuname'} onClick={() => this.gotoTaskDetails(item.id,item.projectId,1)}>{item.taskName}</div>}
                                            <div className={'renwuright'}>
                                                {taskStatus ? taskStatus.map
                                                ((items, index) => {
                                                    if (item.taskStatus === items.value) {
                                                        return <span className={'type'} key={index}>{items.label}</span>
                                                    }
                                                }) : ""}
                                                {/*<span className={'type'}>{item.taskStatus}</span>*/}
                                                {item.portraitId === null ? <img className={'userimg'} src={require('../../../../../src/assets/imgnew/default_avatar.png')}
                                                                                 alt={''}/> :
                                                    <img className={'userimg'} src={`${window.$$imgSrc(item.portraitId)}`}
                                                         alt={''}/>
                                                }
                                            </div>
                                        </li>)) : ""}
                                </ul>
                            </div>
                            <div className={'cont-bottomRT cont-bottomRB'}>
                                <div className={'titleR'}>动态</div>
                                <div className={'dynamic-cont'}>
                                    <ul className={'dtlist'}>
                                        {meritDynamicList ? meritDynamicList.map((item) => (
                                            <li>
                                                <span className={'left-time'}>{item.createDate}</span>
                                                <span className={'yuan'}>
                                                <i></i>
                                                </span>
                                                <ul className={'right-sj'}>
                                                    {item.meritDynamicList ? item.meritDynamicList.map((item2) => (
                                                        <li>
                                                            <span className={'right-name'}>{item2.editorName}</span>
                                                            <span className={'right-action'}>{item2.action}</span>
                                                            {(item2.meritName&&item2.meritName.length)+(item2.meritDetail&&item2.meritDetail.length)>25?<Tooltip overlayClassName={'gaikuang-tooltip'}
                                                                                                                                                                 placement="bottomLeft"
                                                                                                                                                                 title={item2.meritName+(item2.meritDetail?item2.meritDetail:'')}><span
                                                                className={'right-message'}>{item2.meritName}{item2.meritDetail}</span></Tooltip>:<span
                                                                className={'right-message'}>{item2.meritName}{item2.meritDetail}</span>}
                                                        </li>)) : ""}
                                                </ul>
                                            </li>)) : ""}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*激活绩效*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.activationVisable}
                    onOk={this.toactivationOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认激活该绩效？</p>
                </Modal>
                {/*完成绩效*/}
                <Modal
                    title="完成绩效"
                    visible={this.state.completeVisable}
                    onOk={this.tocompleteOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    destroyOnClose
                    centered={true}
                >
                    <div>
                        <CompleteModal
                            // onRef ={(form)=>this.xiangmuxinxi=form}
                            // onClose={this.onClose}
                            // infoProjects={this.infoProject}
                            meritList={meritList}
                            formRef={(form) => this.formRef = form}
                        ></CompleteModal>
                    </div>
                </Modal>
                {/*删除绩效*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.deleteVisable}
                    onOk={this.deleteOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>删除绩效后，该绩效及其子绩效将会被永久被删除，是否继续？</p>
                </Modal>
                {/*编辑任务*/}
                {/*{ editModalTitel==='编辑绩效'?<Modal className='projectTable-list' title={editModalTitel} visible={ModalVisible}*/}
                {/*       onOk={this.updateOk} onCancel={this.hideModal}>*/}
                {/*    :<Modal className='projectTable-list'*/}
                {/*            title={editModalTitel} visible={ModalVisible}*/}
                {/*            onOk={this.meritSonSave} onCancel={this.hideModal}>}*/}
                <Modal className='projectTable-list' maskClosable={false} title={editModalTitel} width={660} visible={ModalVisible}
                       onOk={this.updateOk} onCancel={this.hideModal}>
                    <div className='list-a'>
                        {/*{editModalTitel ? editModalTitel==='新增绩效'?*/}
                        {/*    <span></span>*/}
                        {/*    :<span hidden={true}></span>*/}
                        {/*    :<span hidden={true}></span>}*/}
                        <Form labelCol={{span: 6}} wrapperCol={{span: 14}} colon={false}>
                            <Form.Item label="绩效名称">
                                {
                                    getFieldDecorator('meritName', {
                                        rules: [
                                            {
                                                type: 'string',
                                                min: 2,
                                                max: 200,
                                                message: '绩效名称不能小于2或大于200字符!',
                                            },
                                            {
                                                required: true,
                                                message: '绩效名称不能为空!'
                                            },
                                        ]
                                    })(<Input autocomplete="off" disabled={meritList.isNew==0&&this.state.editModalTitel === '编辑绩效'} placeholder="请输入绩效名称"/>)
                                }
                            </Form.Item>
                            <Form.Item label="层面">
                                {
                                    getFieldDecorator('meritType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '层面不能为空!'
                                            }
                                        ]
                                    })(<Select
                                        onChange={this.levelonChange}
                                        disabled={meritList.isNew==0&&this.state.editModalTitel === '编辑绩效'}
                                        showSearch
                                        placeholder="请选择层面"
                                        className={'srk'}
                                        optionFilterProp="children"
                                        // filterOption={(input, option) =>
                                        //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {queryCatgory === 1 ? meritTypes && meritTypes.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>
                                        }) : meritTypess && meritTypess.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>
                                        })}
                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="指标层级">
                                {
                                    getFieldDecorator('indicatorName', {
                                        initialValue: indicatorName,
                                        rules: [{
                                            required: true,
                                            message: '指标层级不能为空'
                                        }]
                                    })
                                    (<Cascader fieldNames={{
                                        label: 'indicatorName',
                                        value: 'indicatorName',
                                        children: 'children'
                                    }}
                                               options={indexOptions}
                                               popupPlacement='bottomLeft'
                                               changeOnSelect
                                               disabled={meritList.isNew==0&&this.state.editModalTitel === '编辑绩效'}
                                               displayRender={this.displayRender}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                               placeholder="请选择指标层级"/>)


                                }
                            </Form.Item>
                            <Form.Item label="负责人">
                                {
                                    getFieldDecorator('directorId', {})(<Select
                                        showSearch="true"
                                        optionFilterProp="children"
                                        defaultValue=""
                                        placeholder="请选择负责人"
                                        allowClear={true}
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                                        {
                                            userInfoList && userInfoList.map(item =>
                                                <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                            )
                                        }
                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="目标值">
                                {
                                    getFieldDecorator('targetValue', {
                                        rules: [
                                            {
                                                type: 'string',
                                                min: 1,
                                                max: 200,
                                                message: '目标值不能大于200字符!',
                                            },
                                            {
                                                required: true,
                                                message: '目标值不能为空!'
                                            },
                                        ]
                                    })(<Input autocomplete="off"  disabled={meritList.isNew==0&&this.state.editModalTitel === '编辑绩效'} placeholder="请输入目标值"/>)
                                }
                            </Form.Item>
                            <Form.Item label="统计单位">
                                {
                                    getFieldDecorator('indicatorMeasure', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '统计单位不能为空!'
                                            }
                                        ]
                                    })(<Select defaultValue="" placeholder="请选择统计单位"    allowClear={true}   disabled={meritList.isNew==0&&this.state.editModalTitel === '编辑绩效'}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {
                                            getDhAllCommonDic && getDhAllCommonDic.map(item =>
                                                <Select.Option value={item.dicValue}>{item.dicValue}</Select.Option>
                                            )
                                        }
                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="规划日期" >
                                {
                                    getFieldDecorator('time')(
                                        <div>
                                            <DatePicker
                                                style={{'display': 'inline-block','width':'162px','float':'left'}}
                                                disabledDate={this.disabledStartDate}
                                                format="YYYY-MM-DD"
                                                value={startValue ? moment(startValue) : undefined}
                                                placeholder="请选择日期"
                                                onChange={this.onStartChange}
                                                onOpenChange={this.handleStartOpenChange}
                                                getCalendarContainer={triggerNode => triggerNode.parentNode}
                                            /><span style={{
                                            'display': 'inline-block',
                                            'width': '32px',
                                            'textAlign': 'center',
                                            'float': 'left'
                                        }}>至</span>
                                            <DatePicker
                                                style={{'display': 'inline-block',"width":'162px','float':'left'}}
                                                disabledDate={this.disabledEndDate}
                                                format="YYYY-MM-DD"
                                                value={endValue ? moment(endValue) : undefined}
                                                placeholder="请选择日期"
                                                onChange={this.onEndChange}
                                                open={this.state.endOpen}
                                                onOpenChange={this.handleEndOpenChange}
                                                getCalendarContainer={triggerNode => triggerNode.parentNode}
                                            />
                                        </div>
                                    )
                                }

                            </Form.Item>
                            <Form.Item label="绩效描述">
                                <div className={'textare-wrap'}>
                                    {
                                        getFieldDecorator('meritDescribe')(
                                            <TextArea maxLength={500}
                                                      placeholder="请输入绩效描述"
                                                      style={{width: '100%', resize: 'none'}}
                                                      rows={5}
                                                      defaultValue={''}
                                            />
                                        )
                                    }
                                    <span className='textarea_len'>
                                    {this.props.form.getFieldValue(`meritDescribe`) ? this.props.form.getFieldValue(`meritDescribe`).length : 0}/500
                                        </span>
                                </div>
                            </Form.Item>

                        </Form>
                    </div>
                </Modal>
                {/*认领负责人*/}
                <Modal
                    title="请选择负责人"
                    visible={PrincipalModalVisible}
                    onOk={this.claimOk}
                    onCancel={this.hideModal}
                    width={450}

                    closable={false}
                    className='modaltishi FZRstyle FZRstyle1'
                    maskClosable={false}
                    centered={true}
                >
                    <div id={'area'}>
                        <Select
                            style={{'width': '100%', 'font-weight': 'bold', 'color': 'black'}}
                            placeholder="请选择负责人"
                            allowClear={true}
                            onChange={(e) => this.onUserChanges(e)}
                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                            showSearch="true"
                            optionFilterProp="children"
                            open={userInfoList}
                        >
                            {
                                userInfoList && userInfoList.map(item =>
                                    <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                )
                            }
                        </Select>
                    </div>

                </Modal>

            </div>

        );
    }
}

SpecificDetails = Form.create()(SpecificDetails);
export default SpecificDetails;
