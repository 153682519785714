// 资金支出查看
import React, {Component} from "react";
import {Form, Table, Steps, Modal, Button, Tooltip, Select, Input} from "antd";
import style from './index.module.scss';
import {withRouter} from "react-router";
import {getExpendDetailInfo, nodeApproval, addExpendPrepare,cancleApproval} from "../../../../../../api/fund"
import {
    BackToLastPage, AlreadyPassed, Undone, Denied, ExamineApprove
} from '../../../../../../assets/svg'
import '../../../../../../styles/ssant.css'
import S from "gongyongicon";
import Cookies from "js-cookie";
import GongyongImagePreview from '../../../../../../components/GongyongImagePreview'

const {Option} = Select;

@withRouter
class Capitalexpenditurereview extends Component {
    state = {
        columns: [
            {
                title: '支出内容',
                dataIndex: 'expendDetailName',
                key: 'expendDetailName',
                align: 'center',
                width: 200,
            },
            {
                title: '经费类型',
                dataIndex: 'investSourceType',
                key: 'investSourceType',
                align: 'center',
                width: 200,
                render: (key) => <div>
                    {/*1、中央财政投入资金 2、地方财政投入资金 3、举办方投入资金 4、行业企业支持资金 5 学校自筹资金*/}
                    <span>
                        {key == 1 ? '中央财政投入资金' : key == 2 ? '地方各级财政投入资金' : key == 3 ? '举办方投入资金' : key == 4 ? '行业企业支持资金' : '学校自筹资金'}
                    </span>
                </div>

            },
            {
                title: '发生日期',
                dataIndex: 'fillTime',
                key: 'fillTime',
                align: 'center',
                width: 200,
            },
            {
                title: '金额（人民币元）',
                dataIndex: 'expendAmount',
                key: 'expendAmount',
                align: 'center',
                width: 200,
                render: (text, record) => {
                    return (
                        <div>
                            <span>{text.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                        </div>
                    )
                }
            },
        ],
        dataSource: [],
        viewphotoModal: false,
        stepsList: [
            {
                title: '曲丽丽（发起审批）',
                description: '2021-08-12 13:22'
            },
            {
                title: '周毛毛（已通过）',
                description: '2021-08-12 13:22',
                subTitle: '这是我的审批意见希望下次流程可以快一些'
            },
            {
                title: '曲丽丽（发起审批）',
                description: '2021-08-12 13:22'
            },
        ],
        jumpSource: JSON.parse(Cookies.get('jumpSource')).expendDetail ? JSON.parse(Cookies.get('jumpSource')).expendDetail : "",
        jumpSourceProjectName: "",
        expendId: this.props.match.params.id,//支出详情ID
        type: '0',//右上角状态图  0审批中，1已通过 2已拒绝 3已撤销；
        approvalStatusList: [
            {value: 1, label: "审批中"},
            {value: 2, label: "已通过"},
            {value: 3, label: "已拒绝"},
            {value: 4, label: "已撤销"},
        ],
        projectId: "",
        taskId: "",
        taskName: "",
        taskIsDelete: "",
        fillUserId: "",
        fillUserName: "",
        expendCode: "",
        createTime: "",
        expendSum: 30.00,
        approvalStatus: 1,
        approvalStatusName: "",
        permitResult: null,
        expendYear: "",
        expendType: 1,//支出类型
        expendTypeName: "",//支出名称
        expendTypeList: [
            {value: 1, label: "采购费"},
            {value: 2, label: "差旅费"},
            {value: 3, label: "培训费"},
            {value: 4, label: "其他"},
        ],
        expendContent: "",
        expendDetails: [],
        fundTypeVo: {},
        relatedPics: [],
        workflowApproval: [],
        isShowCancle: [],//是否显示撤销按钮
        isShowApproval: [],//是否显示审批按钮
        lastAppravalNodeId: "",//最后一个审批节点
        waitAppravalNodeId: "",//等待审批节点
        waitAppravalNodeIndex: 1,//当前待审批节点序号
        beyondInvestVisable: false,//超出预算提示
        approvalTipVisable: false,//审批提示
        approvalView: '',//审批意见
        approvalResult: '',//审批结果
        imgList: [],//图片列表
        approvalCancleVisable:false,
        // 图片预览的展示
        imagePreviewVisible: false,
        imagePreviewIndex:0,
        newimgList:[]
    }

    componentDidMount() {
        this.getExpendDetailInfo()
    }

    //region api
    //撤销
    cancleApproval = async () => {
        let data = {
            expendId: this.state.expendId,
        }
        await cancleApproval(data).then(res => {
            this.mesSuccess('撤销成功');
            this.backPage()
        })
    }
    //提交填报预处理
    addExpendPrepare = async () => {
        let data = {
            projectId: this.state.projectId,
            expendYear: this.state.expendYear,
            expendDetailList:this.state.dataSource,
            taskId:this.state.taskId
        }
        await addExpendPrepare(data).then(res => {
            if (res.isShowWarn === 1) {
                this.setState({
                    beyondInvestVisable: true,
                    amountSum: res.amountSum,
                    centerAmount: res.centerAmount,
                    localAmount: res.localAmount,
                    holderAmount: res.holderAmount,
                    enterpriseAmount: res.enterpriseAmount,
                    schoolAmount: res.schoolAmount,
                    isShowWarn: res.isShowWarn,
                })
            }
            if (res.isShowWarn === 0) {
                this.setState({beyondInvestVisable: false,approvalTipVisable: true}) //两个弹窗，用哪个显示那个
            }
        })
    }
    // 审批
    nodeApproval = async () => {

        let data = {
            expendId: this.state.expendId,
            approvalView: this.state.approvalView,
            approvalResult: this.state.approvalResult,
            workflowId: this.state.waitAppravalNodeId,
        }
        await nodeApproval(data).then(res => {
            this.setState({approvalTipVisable: false}, () => {
                this.mesSuccess('审批成功');
                this.backPage()
            })
        })
    }
    //查询支出详情
    getExpendDetailInfo = async () => {
        let data = {
            expendId: this.state.expendId,
        }
        await getExpendDetailInfo(data).then(res => {
            let approvalStatusName = ""
            for (let i = 0; i < this.state.approvalStatusList.length; i++) {
                if (this.state.approvalStatusList[i].value === res.approvalStatus) {
                    approvalStatusName = this.state.approvalStatusList[i].label
                }
            }
            let expendTypeName = ""
            for (let i = 0; i < this.state.expendTypeList.length; i++) {
                if (this.state.expendTypeList[i].value === res.expendType) {
                    expendTypeName = this.state.expendTypeList[i].label
                }
            }
            let newimgList = []
            if(res.relatedPics){
                res.relatedPics.map(item=>{
                    let obj = {id: item}
                    newimgList.push(obj)
                })
            }
            console.log('res.relatedPics',res.relatedPics,newimgList)
            this.setState({
                projectId: res.projectId,
                taskId: res.taskId,
                taskName: res.taskName,
                taskIsDelete: res.taskIsDelete,
                fillUserId: res.fillUserId,
                fillUserName: res.fillUserName,
                expendCode: res.expendCode,
                createTime: res.createTime,
                expendSum: res.expendSum,
                approvalStatus: res.approvalStatus,//状态：1、审批中2、已通过3、已拒绝  4、已撤销
                approvalStatusName: approvalStatusName,
                permitResult: res.permitResult,
                expendYear: res.expendYear,
                expendType: res.expendType,
                expendTypeName: expendTypeName,
                expendContent: res.expendContent,
                dataSource: res.expendDetails,
                fundTypeVo: res.fundTypeVo,
                relatedPics: res.relatedPics,
                workflowApproval: res.workflowApprovalVos,
                isShowCancle: res.workflowApprovalVos.isShowCancle,//是否显示撤销按钮
                isShowApproval: res.workflowApprovalVos.isShowApproval,//是否显示审批按钮
                lastAppravalNodeId: res.workflowApprovalVos.lastAppravalNodeId,//最后一个审批节点
                waitAppravalNodeId: res.workflowApprovalVos.waitAppravalNodeId,//等待审批节点
                waitAppravalNodeIndex: res.workflowApprovalVos.waitAppravalNodeIndex,//等待审批节点序号
                imgList: res.relatedPics,//图片列表
                newimgList,//图片预览列表，需要是[{id: ''}]这种格式
            })
        })
    }
    //endregion
    //返回主页面
    backPage = () => {
        if (this.state.jumpSource === 'task') {
            this.props.history.push({
                pathname: '/main/doublehigh/operation/' + this.state.projectId + '/' + this.state.taskId + '/financialdetailsone',
            })
        }
        if (this.state.jumpSource === 'project') {
            this.props.history.push({
                pathname: '/main/doublehigh/project/' + this.state.projectId, returnFlag: '3',
            })
        }
        if (this.state.jumpSource === 'invest') {
            this.props.history.push({
                pathname: '/main/doublehigh/doubleteam/' + this.state.projectId + '/'+this.state.taskName,
            })
        }
    };
    viewPhoto = (item,index) => {
        console.log('openphoto')
        console.log('item',item,this.state.imgList)
        console.log('111')
        this.setState({
            imagePreviewVisible: true,
            imagePreviewIndex:index,

        })
    };
    handleOk = e => {
        console.log(e);
        this.setState({
            viewphotoModal: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            viewphotoModal: false,
            beyondInvestVisable: false,//超出预算提示
            approvalTipVisable: false,//审批提示
            approvalCancleVisable:false//撤销提示
        });
    };
    //撤销提示
    undoBack = e => {
        this.setState({approvalCancleVisable:true})

    };
    reset = e => {
        this.backPage();

    };
    //提交审批
    tijiao = e => {
        // this.setState({completeVisable: true, approvalTipVisable: true}) //两个弹窗，用哪个显示那个
        if (this.state.approvalResult === '') {
            this.mesWarning('审批结果不能为空')
            return false
        }
        if(this.state.approvalView.length>100){
            return false
        }
        //通过
        if(this.state.approvalResult === '1'){
            this.addExpendPrepare()
        }
        //拒绝
        if(this.state.approvalResult === '2'){
            this.setState({approvalTipVisable:true})
        }

    };
    //超出提示后——已确认提交
    tocompleteOk = e => {
        // this.setState({completeVisable: true, approvalTipVisable: true}) //两个弹窗，用哪个显示那个
        if (this.state.approvalResult === '') {
            this.mesWarning('审批结果不能为空')
            return false
        }
    };

    render() {
        const {columns, dataSource, viewphotoModal, stepsList, type, workflowApproval, approvalStatus,approvalView, imagePreviewVisible,imagePreviewIndex,newimgList} = this.state;
        const {Step} = Steps;
        return (
            <div className={style.CapitalexpenditurereviewBG}>
                <div className={'CER_boxA'}>
                    {
                        approvalStatus === 1 ? <ExamineApprove/> : approvalStatus === 2 ?
                            <AlreadyPassed/> : approvalStatus === 3 ? <Denied/> :
                                <Undone/>
                    }

                    <div className={'CER_boxA_backBtn'} onClick={() => this.backPage()}>
                        <BackToLastPage/>
                        <span> 返回</span>
                    </div>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>基础信息</p></div>
                        <div className={'CER_boxA_contentDetails'}>
                            <div className={'DA'}>
                                <p><span>支出单号：{this.state.expendCode}</span></p>
                                <p><span>填报人：{this.state.fillUserName}</span></p>
                            </div>
                            <div className={'DB'}>
                                <p><span>关联任务：{this.state.taskName}</span></p>
                                <p><span>填报时间：{this.state.createTime.replace("T", " ")}</span></p>
                            </div>
                            <div className={'DC'}>
                                <h3>状态</h3>
                                <h4>{this.state.approvalStatusName}</h4>
                            </div>
                            <div className={'DD'}>
                                <h3>总计金额</h3>
                                <h4>¥{this.state.expendSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</h4>
                            </div>
                        </div>


                    </div>

                </div>
                <div className={'CER_boxB'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>支出信息</p></div>
                        <div className={'CER_boxA_contentDetails'} style={{display: 'block'}}>
                            <p className={'boxB_p'}>支出年度：{this.state.expendYear}年</p>
                            <p className={'boxB_p'}>支出类型：{this.state.expendTypeName}</p>
                            <p className={'boxB_p'} style={{marginBottom: '30px'}}>支出事由：{this.state.expendContent}</p>


                        </div>
                    </div>
                </div>
                <div className={'CER_boxC'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'} style={{height: '26px'}}><span></span><p>支出明细</p></div>
                        <div className={'CER_boxC_table'}>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                locale={{emptyText:<><div className="Empty-conet-ss">
                                        <div className="img">
                                            <img src={require("../../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            <span>暂无数据</span>
                                        </div>
                                    </div></>}}
                            />

                            <div className={'boxC_table_bottom'}>
                                <div>
                                    <div><h4>中央财政投入资金：￥{parseFloat(this.state.fundTypeVo.centerAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</h4><span></span>
                                        <h4>地方各级财政投入资金：￥{parseFloat(this.state.fundTypeVo.localAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</h4><span></span>
                                        <h4>行业企业支持资金：￥{parseFloat(this.state.fundTypeVo.enterpriseAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')} </h4></div>
                                    <div><h4>学校自筹资金：￥{parseFloat(this.state.fundTypeVo.schoolAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</h4><span></span>
                                        <h4>举办方投入资金：￥{parseFloat(this.state.fundTypeVo.holderAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</h4></div>
                                </div>
                                <div style={{lineHeight: '54px'}}><h2>总计</h2>
                                    <h5>￥{parseFloat(this.state.fundTypeVo.amountSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')} </h5></div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className={'CER_boxD'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>图片</p></div>
                        <div className={'CER_boxA_contentDetails'}>
                            <div>
                                <ul>
                                    {
                                        this.state.imgList && this.state.imgList.map((item,index) => (
                                            <li className={'CER_boxD_photo'} onClick={() => this.viewPhoto(item,index)}>
                                                <img src={`${window.$$imgSrc(item)}`} alt={''}/>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <GongyongImagePreview
                                visible={imagePreviewVisible}
                                imglist={this.state.newimgList}
                                curPreview={imagePreviewIndex}
                                cancel={() => {
                                    this.setState({
                                        imagePreviewVisible: false
                                    },()=>{
                                        document.body.classList.remove('pic_image_preview_add_body')
                                    })
                                }}
                            />

                        </div>
                    </div>
                </div>
                {/*{*/}
                {/*    title: '周毛毛（已通过）',*/}
                {/*    description: '2021-08-12 13:22',*/}
                {/*    subTitle: '这是我的审批意见希望下次流程可以快一些'*/}
                {/*},*/}
                <div className={'CER_boxE'}>
                    <div className={'CER_boxA_content'} style={{position: 'relative'}}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>流程</p></div>
                        <div className={'CER_boxA_contentDetails'}>
                            <Steps className='capitalexpenditurereview_steps' current={this.state.waitAppravalNodeIndex}
                                   size="small">
                                {
                                    workflowApproval.workflowVos ? workflowApproval.workflowVos.map((item, index) => {
                                        {
                                            // return item.subTitle ?
                                            //     <Step title={item.title} description={item.description}
                                            //           subTitle={item.subTitle} className={'subTitle'}/>
                                            //     : <Step title={item.title} description={item.description}/>

                                            return item.isStart === "1" ?
                                                <Step title={item.fillUserName + "(" + item.workflowName + ")"}
                                                      description={item.createTime.replace("T", " ").slice(0,item.createTime.replace("T", " ").length-3)}
                                                />
                                                :
                                                <Step
                                                    title={item.approveUserName + (item.approveStatusName == null ? "" : "(" + item.approveStatusName + ")")}
                                                    description={item.createTime ? item.createTime.replace("T", " ").slice(0,item.createTime.replace("T", " ").length-3) : ""}
                                                    subTitle={item.approvalView?item.approvalView:""}
                                                    className={'subTitle'}
                                                />
                                        }
                                    }) : ''
                                }
                            </Steps>

                        </div>
                    </div>

                </div>
                {
                    // 审批中
                    this.state.approvalStatus === 1 && this.state.isShowApproval === 0 && this.state.taskIsDelete === 0 ?
                        <div className={'CER_footer'}>
                            {this.state.isShowCancle === 1 ?
                                <div className={'quanxiansz_btnUndoBack'} onClick={() => this.undoBack()}>撤
                                    销</div> : ""}
                            {this.state.isShowCancle === 1 ?
                                <div className={'quanxiansz_btnReset'} onClick={() => this.reset()}>取 消</div> : ""}
                        </div> : ""
                }

                {
                    // 审批中，并且可以审批
                    this.state.approvalStatus === 1 && this.state.isShowApproval === 1 && this.state.taskIsDelete === 0 ?
                        <div className={'CER_footerone'}>
                            <div className={'CER_footer_left'}>
                                <div className={'CER_footer_leftL'}>
                                    <span className={'star'}>*</span><span className={'star_title'}>审批结果</span>
                                    <Select style={{width: 120}} onChange={(e) => this.setState({approvalResult: e})}
                                            placeholder='请选择'>
                                        <Option value="1">通过</Option>
                                        <Option value="2">不通过</Option>

                                    </Select>
                                </div>
                                <div className={'CER_footer_leftR'}>
                                    <span className={'star_title'} style={{width: '81px'}}>审批意见</span>
                                    <Input placeholder="" className={'footer_input'} value={this.state.approvalView}
                                           onChange={(e) => this.setState({approvalView: e.target.value})}/>
                                    {approvalView.length > 100 ? <div className={'chaochutip'}>审批意见不能大于100字</div> : ''}
                                </div>

                            </div>
                            <div>
                                <div className={'quanxiansz_btnUndoBack'} onClick={() => this.tijiao()}>提 交</div>
                                <div className={'quanxiansz_btnReset'} onClick={() => this.reset()}>取 消</div>
                            </div>
                        </div> : ""
                }


                <Modal
                    title="查看图片"
                    visible={viewphotoModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div style={{width: '140px', height: '140px', textAlign: 'center'}}>
                        <img
                            src={`${window.$$imgSrc(this.state.preview)}`}/>
                    </div>
                </Modal>
                {/*提示已超出预算额度*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.beyondInvestVisable}
                    onOk={()=>this.setState({beyondInvestVisable:false,approvalTipVisable:true})}
                    onCancel={this.handleCancel}
                    width={450}
                    closable={false}
                    className={style.aacapitalviewmodaltishi}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                        <span className={'tip'}>已超出预算额度，是否还要继续审批？</span>
                    </div>
                    {
                        // 中央财政投入资金
                        this.state.fundTypeVo.centerAmount === 0 ?
                            '' :
                            this.state.centerAmount >= 0 ? '':
                            <div className={'group'}>中央财政投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.centerAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>

                    }
                    {
                        // 地方各级财政投入资金
                        this.state.fundTypeVo.localAmount === 0 ?
                            '' :
                            this.state.localAmount >= 0 ? '':
                            <div className={'group'}>地方各级财政投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.localAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 举办方投入资金
                        this.state.fundTypeVo.holderAmount === 0 ?
                            '' :
                            this.state.holderAmount >= 0 ? '':
                            <div className={'group'}>举办方投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.holderAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 行业企业支持资金
                        this.state.fundTypeVo.enterpriseAmount === 0 ?
                            '' :
                            this.state.enterpriseAmount >= 0 ? '':
                            <div className={'group'}>行业企业支持资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.enterpriseAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 学校自筹资金
                        this.state.fundTypeVo.schoolAmount === 0 ?
                            '' :
                            this.state.schoolAmount >= 0 ? '':
                            <div className={'group'}>学校自筹资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.schoolAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                </Modal>
                {/*审批提示*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.approvalTipVisable}
                    onOk={this.nodeApproval}
                    onCancel={this.handleCancel}
                    width={450}
                    closable={false}
                    className={style.aacapitalviewmodaltishi}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                        <span className={'tip'}>审批结果提交确认</span>
                    </div>
                    <div className={'group'}><span className={'redzi'}>审批结果提交后无法撤回，</span>你还要继续吗</div>
                </Modal>

                {/*撤销提示*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.approvalCancleVisable}
                    onOk={this.cancleApproval}
                    onCancel={this.handleCancel}
                    width={450}
                    closable={false}
                    className={style.aacapitalviewmodaltishi}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                        <span className={'tip'}>支出申请撤销确认</span>
                    </div>
                    <div className={'group'}><span className={'redzi'}>支出申请撤销后无法取消，</span>你还要继续吗？</div>
                </Modal>
            </div>

        );
    }
}

Capitalexpenditurereview = Form.create()(Capitalexpenditurereview);
export default Capitalexpenditurereview;
