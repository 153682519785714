// 支出填报(建设任务)
import React, {Component} from "react";
import {Col, Form, Input, Select, Steps, Button, Modal, DatePicker} from "antd";
import {withRouter} from "react-router";
import {RedDelete, Add, BigRedAdd} from '../../../../../../assets/svg'
import UploadImg from '../../../../../../components/upload_img'
import style from './index.module.scss'
import S from "gongyongicon";
import {
    getTaskSelectByProjectId,
    addExpend,
    getWorkflow,
    getWorkflowByExpendId,
    addExpendPrepare
} from "../../../../../../api/fund"

const {TextArea} = Input;
const {Step} = Steps;

@withRouter
class Spendingallowed extends Component {
    state = {
        projectId: this.props.match.params.projectId,
        jumpTaskId: this.props.match.params.taskId,
        particularsNum: 1,
        particularsList: [0],//支出详情列表
        imgList: [{
            id: 'wwwwqq',
            bannerImg: '',
            mediaType: '',
            fId: ''
        }], // 图片列表（相关图片+加号图片）
        relatedImageList: [],//相关图片
        steps: [
            {
                title: '发起审批',
            },
            {
                title: '建设任务主管审批',
            },
            {
                title: '资金主管审批',
            },
        ],
        approvalCurrent: 0,//当前审批节点
        beyondInvestVisable: false,//超出预算提示
        taskSelectList: [],//任务下拉列表
        expendData: undefined,//支出填报数据
        expendDetailCenterSum: 0,//支出详情——中央财政投入资金合计
        expendDetailLocalSum: 0,//支出详情——地方财政投入资金合计
        expendDetailHolderSum: 0,//支出详情——举办方投入资金合计
        expendDetailEnterpriseSum: 0,//支出详情——行业企业支持资金合计
        expendDetailSchoolSum: 0,//支出详情——学校自筹资金合计
        expendDetailsSum: 0,//支出总合计


    }

    componentDidMount() {
        this.getTaskSelectByProjectId()//任务下拉框
        this.getWorkflow()
        console.log('jumpTaskId', this.state.jumpTaskId)
        console.log('this.state.taskId', this.state.taskId)
        this.props.form.setFieldsValue({taskRelevance: this.state.jumpTaskId === undefined?undefined:this.state.jumpTaskId}) //关联任务
        this.props.form.setFieldsValue({annualExpenditure: new Date().getFullYear()})
    }

    //region api接口
    //提交填报预处理
    addExpendPrepare = async () => {
        let annualExpenditure = this.props.form.getFieldValue('annualExpenditure') //支出年度
        let data = {
            projectId: this.state.projectId,
            taskId: this.props.form.getFieldValue('taskRelevance'),
            expendYear: annualExpenditure,
            expendDetailList:this.state.expendData.expendDetailList
        }
        await addExpendPrepare(data).then(res => {
            if (res.isShowWarn === 1) {
                this.setState({
                    beyondInvestVisable: true,
                    amountSum: res.amountSum,
                    centerAmount: res.centerAmount,
                    localAmount: res.localAmount,
                    holderAmount: res.holderAmount,
                    enterpriseAmount: res.enterpriseAmount,
                    schoolAmount: res.schoolAmount,
                    isShowWarn: res.isShowWarn,
                })
            }
            if (res.isShowWarn === 0) {
                this.addExpend()
            }
        })
    }
    //查询任务下拉框，通过建设任务ID
    getTaskSelectByProjectId = async () => {
        let data = {
            projectId: this.state.projectId
        }
        await getTaskSelectByProjectId(data).then(res => {
            if (res) {
                this.setState({
                    taskSelectList: res,
                })
            }
        })
    }
    //添加支出
    addExpend = async () => {
        await addExpend(this.state.expendData).then(res => {
            this.setState({beyondInvestVisable: false})
            this.mesSuccess("填报成功")
            this.goTobackPage()
        })
    }
    //跳转返回页面
    goTobackPage = ()=>{
        if (this.state.jumpTaskId) { // 任务跳转过来得
            this.props.history.push({
                pathname: '/main/doublehigh/operation/' + this.state.projectId + '/' + this.state.jumpTaskId + '/financialdetailsone',
            })
        }
        else{
            this.props.history.push({// 建设任务跳转过来得
                pathname: '/main/doublehigh/project/' + this.state.projectId, returnFlag: '3',
            })
        }
    }
    //获取工作流
    getWorkflow = async () => {
        await getWorkflow().then(res => {
            this.setState({steps: res, approvalCurrent: 0})
        })
    }
    //endregion

    // 添加明细
    addParticulars = () => {
        const {particularsNum} = this.state
        let particularsList = []
        let particularsNumm = particularsNum + 1
        for (let i = 0; i < particularsNumm; i++) {
            particularsList.push(i)
        }
        this.setState({particularsNum: particularsNumm, particularsList})
    }
    //删除明细
    deleteParticulars = () => {
        const {particularsNum, particularsList} = this.state
        this.setState({
            particularsNum: particularsNum - 1,
            particularsList: particularsList.splice(0, particularsNum - 1)
        })
    }
    // 上传图片
    handleImgChange = (fileId, mediaType, formName, fId) => { // 上传图片
        console.log('fileId', fileId)
        console.log('mediaType', mediaType)
        console.log('formName', formName)
        console.log('fId', fId)
        this.props.form.setFieldsValue({[formName]: fileId})
        let {imgList} = this.state
        imgList && imgList.map(item => {
            if (item.id === formName) {
                item.bannerImg = fileId
                item.mediaType = mediaType
                item.fId = fId
            }
        })
        this.state.relatedImageList = imgList
        let list = imgList.filter(item => item.bannerImg !== '')
        if (list.length < 8 && list.find((v) => v.bannerImg !== "") || list.length === 0) {
            let obj = {
                id: this.genID(),
                bannerImg: '',
                mediaType: ''
            }
            list.push(obj)
        }
        this.setState({
            imgList: list,
        }, () => {
            console.log('imgList', imgList)
        })
    }
    genID = () => {
        return Number(Math.random().toString().substr(3, 10) + Date.now()).toString(36);
    }
    //提交
    tijiao = () => {
        // this.setState({beyondInvestVisable: true})
        let expendDetails = [] // 支出详情
        let expendDetailsSum = [] // 支出详情合计
        this.props.form.validateFields((err, values) => {
            if (!err) {
                for (let i = 0; i < this.state.particularsNum; i++) {
                    let purpose = this.props.form.getFieldValue('purpose' + i)//支出内容
                    let fundsType = this.props.form.getFieldValue('fundsType' + i)//经费类型
                    let occurrenceTime = this.props.form.getFieldValue('occurrenceTime' + i)//发生日期
                    let money = this.props.form.getFieldValue('money' + i)//金额
                    expendDetails.push({
                        expendDetailName: purpose,
                        investSourceType: fundsType,
                        fillTime: occurrenceTime,
                        expendAmount: money
                    })
                }
                console.log('expendDetails', expendDetails)
                let taskRelevance = this.props.form.getFieldValue('taskRelevance') //关联任务
                let annualExpenditure = this.props.form.getFieldValue('annualExpenditure') //支出年度
                let disbursementType = this.props.form.getFieldValue('disbursementType') //支出类型
                let expenseReason = this.props.form.getFieldValue('expenseReason') //支出事由

                let relatedPic = ""
                let relatedImageList = this.state.relatedImageList

                console.log('this.state.relatedImageList', relatedImageList)
                for (let i = 0; i < relatedImageList.length; i++) {
                    if (relatedImageList[i].bannerImg) {
                        relatedPic = relatedPic + relatedImageList[i].bannerImg + ","
                    }
                }

                let data = {
                    projectId: this.state.projectId,//建设任务ID
                    taskId: taskRelevance,//任务ID
                    expendSum: this.state.expendDetailsSum,//支出总额
                    expendType: disbursementType,//支出类型
                    expendYear: annualExpenditure,//支出年度
                    expendContent: expenseReason,//支出事由
                    relatedPic: relatedPic,//图片
                    expendDetailList: expendDetails,//支出详情
                }
                this.setState({expendData: data}, () => {

                    this.addExpendPrepare()
                })

            }
        })
    }
    // 支出详情——资金来源类型发生变化
    expendDetailSourceChange = (e, index) => {
        this.setState({
            expendDetailCenterSum: 0,//支出详情——中央财政投入资金合计
            expendDetailLocalSum: 0,//支出详情——地方财政投入资金合计
            expendDetailHolderSum: 0,//支出详情——举办方投入资金合计
            expendDetailEnterpriseSum: 0,//支出详情——行业企业支持资金合计
            expendDetailSchoolSum: 0,//支出详情——学校自筹资金合计
            expendDetailsSum: 0//支出总合计
        }, () => {
            let item1 = 0
            let item2 = 0
            let item3 = 0
            let item4 = 0
            let item5 = 0
            let itemSum = 0
            for (let i = 0; i < this.state.particularsNum; i++) {
                console.log('this.state.particularsNum', this.state.particularsNum)
                let fundsType = this.props.form.getFieldValue('fundsType' + i)//经费类型
                let money = this.props.form.getFieldValue('money' + i)//金额
                console.log("money2", money)
                if (fundsType === "1" && Number(money) > 0) {//中央财政投入资金
                    item1 += Number(money)
                    itemSum += Number(money)

                }
                if (fundsType === "2" && Number(money) > 0) {//地方财政投入资金
                    item2 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "3" && Number(money) > 0) {//举办方投入资金
                    item3 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "4" && Number(money) > 0) {//行业企业支持资金
                    item4 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "5" && Number(money) > 0) {//学校自筹资金
                    item5 += Number(money)
                    itemSum += Number(money)
                }
                this.setState({
                    expendDetailCenterSum: item1,//支出详情——中央财政投入资金合计
                    expendDetailLocalSum: item2,//支出详情——地方财政投入资金合计
                    expendDetailHolderSum: item3,//支出详情——举办方投入资金合计
                    expendDetailEnterpriseSum: item4,//支出详情——行业企业支持资金合计
                    expendDetailSchoolSum: item5,//支出详情——学校自筹资金合计
                    expendDetailsSum: itemSum//支出总合计
                })
            }
        })
    }
    // 支出详情金额填报发生变化
    expendDetailAmountChange = (e, index) => {
        this.setState({
            expendDetailCenterSum: 0,//支出详情——中央财政投入资金合计
            expendDetailLocalSum: 0,//支出详情——地方财政投入资金合计
            expendDetailHolderSum: 0,//支出详情——举办方投入资金合计
            expendDetailEnterpriseSum: 0,//支出详情——行业企业支持资金合计
            expendDetailSchoolSum: 0,//支出详情——学校自筹资金合计
            expendDetailsSum: 0//支出总合计
        }, () => {
            let item1 = 0
            let item2 = 0
            let item3 = 0
            let item4 = 0
            let item5 = 0
            let itemSum = 0
            for (let i = 0; i < this.state.particularsNum; i++) {
                console.log('this.state.particularsNum', this.state.particularsNum)
                let fundsType = this.props.form.getFieldValue('fundsType' + i)//经费类型
                let money = this.props.form.getFieldValue('money' + i)//金额
                console.log("money2", money)
                if (fundsType === "1" && Number(money) > 0) {//中央财政投入资金
                    item1 += Number(money)
                    itemSum += Number(money)

                }
                if (fundsType === "2" && Number(money) > 0) {//地方财政投入资金
                    item2 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "3" && Number(money) > 0) {//举办方投入资金
                    item3 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "4" && Number(money) > 0) {//行业企业支持资金
                    item4 += Number(money)
                    itemSum += Number(money)
                }
                if (fundsType === "5" && Number(money) > 0) {//学校自筹资金
                    item5 += Number(money)
                    itemSum += Number(money)
                }
                this.setState({
                    expendDetailCenterSum: item1,//支出详情——中央财政投入资金合计
                    expendDetailLocalSum: item2,//支出详情——地方财政投入资金合计
                    expendDetailHolderSum: item3,//支出详情——举办方投入资金合计
                    expendDetailEnterpriseSum: item4,//支出详情——行业企业支持资金合计
                    expendDetailSchoolSum: item5,//支出详情——学校自筹资金合计
                    expendDetailsSum: itemSum//支出总合计
                })
            }
        })
    }

    // 取消
    quxiao = () => {
        this.goTobackPage()
    }

    onChangeOfTask = (e) => {
        this.setState({taskId: e})
        console.log('e1111111111111', e)
    }
    tocompleteOk = () => {
        this.setState({beyondInvestVisable: false})
    }
    hideModal = () => {
        this.setState({beyondInvestVisable: false})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {particularsList, imgList, steps, taskSelectList, approvalCurrent} = this.state
        return (
            <div className={style.spendingallowed}>
                <Form className={'spendingallowed-form-wrap'}>
                    <div className={'topcont'}>
                        <div className={'taskRelevance'}>
                            <div className={'title'}>关联任务</div>
                            <Form.Item className={'formgroup'}>
                                <label className={'labelname'}>关联任务</label>
                                {getFieldDecorator('taskRelevance', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择关联任务',
                                        },

                                    ],
                                })(<Select
                                    autocomplete="off"
                                    className={'input'}
                                    disabled={this.state.jumpTaskId !== undefined}
                                    placeholder={'请选择关联任务'}
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {taskSelectList && taskSelectList.map(item => (
                                        <Select.Option value={item.id}>{item.taskName}</Select.Option>
                                    ))}
                                </Select>)}
                            </Form.Item>
                        </div>
                        <div className={'taskRelevance'}>
                            <div className={'title'}>支出信息</div>
                            <Form.Item className={'formgroup'}>
                                <label className={'labelname'}>支出年度</label>
                                {getFieldDecorator('annualExpenditure', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择支出年度',
                                        },

                                    ],
                                })(<Select autocomplete="off" className={'input'} placeholder={'请选择支出年度'} getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                                    <Select.Option value='2019'>2019</Select.Option>
                                    <Select.Option value='2020'>2020</Select.Option>
                                    <Select.Option value='2021'>2021</Select.Option>
                                    <Select.Option value='2022'>2022</Select.Option>
                                    <Select.Option value='2023'>2023</Select.Option>
                                </Select>)}
                            </Form.Item>
                            <Form.Item className={'formgroup'}>
                                <label className={'labelname'}>支出类型</label>
                                {getFieldDecorator('disbursementType', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择支出类型',
                                        },

                                    ],
                                })(<Select autocomplete="off" className={'input'} placeholder={'请选择支出类型'}>
                                    <Select.Option value='1'>采购费</Select.Option>
                                    <Select.Option value='2'>差旅费</Select.Option>
                                    <Select.Option value='3'>培训费</Select.Option>
                                    <Select.Option value='4'>其他</Select.Option>
                                </Select>)}
                            </Form.Item>
                            <Form.Item className={'formgroup'}>
                                <label className={'labelname labelnameno'}>支出事由</label>
                                <div className={'span_text_wrap'}>
                                    {getFieldDecorator('expenseReason', {
                                        rules: [
                                            {
                                                max: 500,
                                                message: '填写支出事由，不能大于500个字',
                                            },

                                        ],
                                    })(<TextArea maxLength={500} autocomplete="off" className={'input textarea'}
                                                 placeholder={'请输入支出事由'}></TextArea>)}
                                    <span
                                        className={'span_text'}>{this.props.form.getFieldValue('expenseReason') ? this.props.form.getFieldValue('expenseReason').length : 0}/500</span>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                    <div className={'particularsList'}>
                        {
                            particularsList ? particularsList.map(index => {
                                return <div className={'particulars'}>
                                    <div className={'particularsCont'}>
                                        <div className={'titlecont'}>
                                            <div className={'titleleft'}>支出明细-{index + 1}</div>
                                            {
                                                particularsList.length > 1 ?
                                                    <div className={'titleright'}
                                                         onClick={() => this.deleteParticulars()}>
                                                        删除<RedDelete/>
                                                    </div> :
                                                    ""
                                            }

                                        </div>
                                        <div className={'particularsform'}>
                                            <Form.Item className={'formgroup'}>
                                                <label className={'labelname'}>支出内容</label>
                                                {getFieldDecorator(`purpose${index}`, {
                                                    rules: [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            max: 100,
                                                            message: '填写支出内容，不能大于100个字',
                                                        },

                                                    ],
                                                })(<Input autocomplete="off" className={'input'}
                                                          placeholder={'请输入支出内容'}/>)}
                                            </Form.Item>
                                            <Form.Item className={'formgroup'}>
                                                <label className={'labelname'}>经费类型</label>
                                                {getFieldDecorator(`fundsType${index}`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择经费类型',
                                                        },

                                                    ],
                                                })(<Select autocomplete="off"
                                                           className={'input'}
                                                           placeholder={'请选择经费类型'}
                                                           onChange={(e) => this.expendDetailSourceChange(e, index)}
                                                >
                                                    <Select.Option value='1'>中央财政投入资金</Select.Option>
                                                    <Select.Option value='2'>地方各级财政投入资金</Select.Option>
                                                    <Select.Option value='3'>举办方投入资金</Select.Option>
                                                    <Select.Option value='4'>行业企业支持资金</Select.Option>
                                                    <Select.Option value='5'>学校自筹资金</Select.Option>
                                                </Select>)}
                                            </Form.Item>
                                            <Form.Item className={'formgroup'}>
                                                <label className={'labelname'}>发生日期</label>
                                                {getFieldDecorator(`occurrenceTime${index}`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择发生日期',
                                                        },

                                                    ],
                                                })(<DatePicker
                                                    placeholder="请选择时间"
                                                    className={'input'}
                                                    getCalendarContainer={triggerNode => triggerNode.parentNode}
                                                />)}
                                            </Form.Item>
                                            <Form.Item className={'formgroup'}>
                                                <label className={'labelname labelnameone'}>金额 (人民币）</label>
                                                {getFieldDecorator(`money${index}`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请输入金额 ',
                                                        },
                                                        {
                                                            message: '请输入正确金额（金额不超过100亿，最多保留两位小数）',
                                                            pattern:'^\\d{1,10}(\\.\\d{1,2})?$',
                                                        },
                                                    ],
                                                })(<Input
                                                    autocomplete="off"
                                                    className={'input'}
                                                    placeholder={'请输入金额'}
                                                    onChange={(e) => this.expendDetailAmountChange(e, index)}/>)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            }) : ''
                        }
                    </div>
                    <div className={'addparticulars'} onClick={() => this.addParticulars()}>
                        <div><Add/>添加支出明细</div>
                    </div>
                    <div className={'tongji'}>
                        <div className={'tongjileft'}>
                            {this.state.expendDetailCenterSum > 0 ?
                                <div className={'tongjigruop'}>中央财政投入资金：<span>￥{parseFloat(this.state.expendDetailCenterSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                                </div> : ""}
                            {this.state.expendDetailLocalSum > 0 ? <div
                                className={'tongjigruop'}>地方各级财政投入资金：<span>￥{parseFloat(this.state.expendDetailLocalSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                            </div> : ""}
                            {this.state.expendDetailHolderSum > 0 ?
                                <div className={'tongjigruop'}>举办方投入资金：<span>￥{parseFloat(this.state.expendDetailHolderSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                                </div> : ""}
                            {this.state.expendDetailEnterpriseSum > 0 ? <div
                                className={'tongjigruop'}>行业企业支持资金：<span>￥{parseFloat(this.state.expendDetailEnterpriseSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')} </span>
                            </div> : ""}
                            {this.state.expendDetailSchoolSum > 0 ?
                                <div className={'tongjigruop'}>学校自筹资金：<span>￥{parseFloat(this.state.expendDetailSchoolSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                                </div> : ""}
                        </div>
                        <div className={'zongji'}>总计<span>￥{parseFloat(this.state.expendDetailsSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span></div>
                    </div>
                    <div className={'imageswrap'}>
                        <div className={'title'}>图片</div>
                        <div>
                            {
                                imgList.map((item, index) => {
                                    return (
                                        <Form.Item className={'imgformitem'}>
                                            <div className='uploadImgWapper'>
                                                {
                                                    getFieldDecorator(`${item.id}`, {
                                                        initialValue: '',
                                                    })(<UploadImg
                                                        systemType='ts'
                                                        handleImgChange={this.handleImgChange}
                                                        formName={`${item.id}`}
                                                        html={
                                                            <div className={'imghtml'}>
                                                                <BigRedAdd/>
                                                                <span>添加图片</span>
                                                            </div>
                                                        }/>)
                                                }
                                            </div>
                                        </Form.Item>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={'imageswrap liucheng'}>
                        <div className={'title'}>流程</div>
                        <Steps current={approvalCurrent}>
                            {
                                steps ? steps.map(item => {
                                    return <Step title={item.workflowName}/>
                                }) : ''
                            }
                        </Steps>
                    </div>
                    <div className={'footerwrap'}>
                        <Button type={'primary'} onClick={() => this.tijiao()}>提交</Button>
                        <Button className={'quxiao'} onClick={() => this.quxiao()}>取消</Button>
                    </div>
                </Form>
                {/*提示已超出预算额度*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.beyondInvestVisable}
                    onOk={this.addExpend}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className={style.spendingallowedmodaltishi}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                        <span className={'tip'}>已超出预算额度，是否还要继续提交？</span>
                    </div>
                    {
                        // 中央财政投入资金
                        this.state.expendDetailCenterSum === 0 ?
                            '' :
                            this.state.centerAmount >= 0 ? '':
                            <div className={'group'}>中央财政投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.centerAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>

                    }
                    {
                        // 地方各级财政投入资金
                        this.state.expendDetailLocalSum === 0 ?
                            '' :
                            this.state.localAmount >= 0 ? '':
                            <div className={'group'}>地方各级财政投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.localAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 举办方投入资金
                        this.state.expendDetailHolderSum === 0 ?
                            '' :
                            this.state.holderAmount >= 0 ? '':
                            <div className={'group'}>举办方投入资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.holderAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 行业企业支持资金
                        this.state.expendDetailEnterpriseSum === 0 ?
                            '' :
                            this.state.enterpriseAmount >= 0 ? '':
                            <div className={'group'}>行业企业支持资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.enterpriseAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }
                    {
                        // 学校自筹资金
                        this.state.expendDetailSchoolSum === 0 ?
                            '' :
                            this.state.schoolAmount >= 0 ? '':
                            <div className={'group'}>学校自筹资金 已超支<span
                                className={'redzi'}>{parseFloat(-this.state.schoolAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}元</span>
                            </div>
                    }

                </Modal>
            </div>
        );
    }
}

Spendingallowed = Form.create()(Spendingallowed);
export default Spendingallowed;
