/*角色权限设置*/
import React, {Component} from "react";
import {
    Form,Select
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {getSchoolExpertOpinion} from "../../../api/doublehigh/index"
import Cookies from "js-cookie";
const {Option} = Select;

@withRouter
class ExpertConsensus extends Component {
    state = {
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
    }

    componentDidMount() {
        this.getSchoolExpertOpinion()
    }

    getSchoolExpertOpinion =()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode
        }
        getSchoolExpertOpinion(data).then(res =>{
            console.log('res 999 ******', res)
            if(res){
                this.setState({
                    expertOpinionInfo: res
                })
            }
        })
    }


    goBack = () => this.props.history.go(-1)

    render() {
        const {expertOpinionInfo} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='ExpertConsensus'>
                <div className={'title_liwrp'}>
                    <div className= 'left-retu'onClick={this.goBack}>
                        <span>返回</span>
                    </div>
                </div>
                <div className={'ExpertConsensus_bottom'}>
                    <div className={'bot_left'}>
                        <div className={'top_let'}>
                            <span>年度综合评级</span>
                            <div className={'bg_icon'}>
                                <i className={'icon'}><img src={require('../../../assets/imgschool/icon_x.png')}/></i>
                                <b>{expertOpinionInfo && expertOpinionInfo.reviewGrade === 1 ? "优" :
                                    expertOpinionInfo && expertOpinionInfo.reviewGrade === 2 ? "良" :
                                        expertOpinionInfo && expertOpinionInfo.reviewGrade === 3 ? "中" :
                                            expertOpinionInfo && expertOpinionInfo.reviewGrade === 4 ? "差" : ''}</b>
                            </div>
                        </div>
                        <div className={'bot_let'}>

                            <span>本组评价水平</span>
                            <div className={'ul'}>
                                <div className={'li'}>
                                    <span className={'on'}>优</span>
                                    <b>{expertOpinionInfo ? expertOpinionInfo.optimalNum : ''}个</b>
                                    {expertOpinionInfo && expertOpinionInfo.reviewGrade === 1 ? <i>·&nbsp;本校所处区域</i> : ''}
                                </div>
                                <div className={'li'}>
                                    <span className={'ona'}>良</span>
                                    <b>{expertOpinionInfo ? expertOpinionInfo.goodNum : ''}个</b>
                                    {expertOpinionInfo && expertOpinionInfo.reviewGrade === 2 ? <i>·&nbsp;本校所处区域</i> : ''}
                                </div>
                                <div className={'li'}>
                                    <span className={'onb'}>中</span>
                                    <b>{expertOpinionInfo ? expertOpinionInfo.midNum : ''}个</b>
                                    {expertOpinionInfo && expertOpinionInfo.reviewGrade === 3 ? <i>·&nbsp;本校所处区域</i> : ''}
                                </div><div className={'li'}>
                                <span className={'onc'}>差</span>
                                <b>{expertOpinionInfo ? expertOpinionInfo.poorNum : ''}个</b>
                                {expertOpinionInfo && expertOpinionInfo.reviewGrade === 4 ? <i>·&nbsp;本校所处区域</i> : ''}
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className={'bot_right'}>
                        {expertOpinionInfo && expertOpinionInfo.reviewOpinion ? expertOpinionInfo.reviewOpinion : ''}
                    </div>
                </div>
            </div>

        );
    }
}

ExpertConsensus = Form.create()(ExpertConsensus);
export default ExpertConsensus;

