import reducers from './reducers'

const imgUrl='cvec-space-front-guanli/peixun/'

let defaultState = {
    collapsed: false,
    organlist: [
        {
            menuName: '首页',
            id: 'sub1',
            icon:imgUrl+'pt_menu_icon_1.png',
            children: [
                {
                    menuName: '培训工作台',
                    id: '1',
                    menuUrl: '/main/organ/pages/trainingHomepage'
                },
            ]
        },
    ]
}
const Organ = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Organ
