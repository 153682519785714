/*任务与绩效进展走势模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Tabs} from "antd";
import * as echarts from 'echarts';
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";
const { TabPane } = Tabs;
@withRouter
class Template2 extends Component {
    state={
        dateType: 3,
        xData : ['10月', '11月', '12月', '1月', '2月', '3月', '4月','5月','6月','7月','8月','9月'],
        yData :[12, 20, 15, 8, 7, 11, 13,10, 20, 10, 9, 7],
        personOfCompletedVos: [
            {
                completePerson: '张飒',
                completeCount: 12
            }
        ]
    }
    componentDidMount() {
        this.myecharts2()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    myecharts2 = ()=>{
        if (document.getElementById('myEcharts2')) {
            let myChart = echarts.init(document.getElementById('myEcharts2'));
            // 绘制图表
            let option = {
                tooltip: {
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>'+params.name+'</div>' +
                            '<div style="text-align: center">'+params.value
                            + '个</div>' +
                            '</div>'
                        return res
                    }
                },
                grid:{
                    left:25,
                    top: 30,
                    bottom:50,
                    right:50
                },
                xAxis: {
                    type: 'category',
                    data: this.state.xData,
                },
                color: '#588BF7',
                yAxis: {
                    type: 'value',
                    minInterval:1,
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type: 'dashed',
                            color: '#F3F3F3'
                        }
                    }
                },
                series: [
                    {
                        data: this.state.yData,
                        type: 'bar',
                        barWidth: 20,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(238, 238, 238, 0.3)'
                        }
                    }
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    onChangeTabofTrend = (item)=>{
        console.log('item',item)
    }
    //1七日内/30日/全年切换
    dateConversion = (dateType)=>{
        this.setState({dateType},()=>{

        })
    }
    render() {
        const {dateType} = this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'task-performance width100'}>
                        <div className={'title'}>
                            任务与绩效进展走势
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('任务与绩效进展走势','/main/doublehigh/editorUnnameds')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        <div className='tab-wrap'>
                            <Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabofTrend(item)}>
                                <TabPane tab={'任务'} key="1">
                                </TabPane>
                                <TabPane  tab={'绩效'} key="2">
                                </TabPane>
                            </Tabs>
                            <div className={'riqi'}>
                                <span className={dateType == 1 ? 'active' : ''} onClick={()=>{this.dateConversion(1)}}>7日</span>
                                <span className={dateType == 2 ? 'active' : ''} onClick={()=>{this.dateConversion(2)}}>30日</span>
                                <span className={dateType == 3 ? 'active' : ''} onClick={()=>{this.dateConversion(3)}}>全年</span>
                            </div>
                            <div className={'task-performance-cont'}>
                                <div className={'cont-left-echarts'}>
                                    <span className={'name'}>完成数量</span>
                                    <div className={'tempalte2-echarts'} id={'myEcharts2'}></div>
                                </div>
                                <div className={'cont-right-ranking'}>
                                    <span className={'name'}>负责人完成情况排名</span>
                                    <div className={'center-wrap'}>
                                        {
                                            this.state.personOfCompletedVos && this.state.personOfCompletedVos.map((item,index)=>(

                                                <div className={'center'}>
                                                    <div className={'center-left'}>
                                                        <span className={'top'}>{index+1}</span>
                                                        <span>{item.completePerson}</span>
                                                    </div>
                                                    <span className={'center-right'}>{item.completeCount}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

Template2 = Form.create()(Template2);
export default Template2;
