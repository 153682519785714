/*绩效指标完成度*/
import React, {Component} from "react";
import {
    Form,Select
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import DegreeCompletion from "../degreeCompletion";
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import Cookies from "js-cookie";
const {Option} = Select;

@withRouter
class CompletionIndicators extends Component {
    state = {
        activeIndex: 1,//初始化时默认显示第一个li
        queryCatgory:1,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
    }

    componentDidMount() {
        this.queryCatgoryById();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    goBack = () => this.props.history.go(-1)
    //切换标签
    handleTab = (index) => {
        this.setState({activeIndex: index})
        //查询专业群
        // this.getSpecialtyGroupByspecialtyType(index)
    }
    //查询专业群
    async queryCatgoryById() {
        let data={
            id: this.state.schoolId
        };
        //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    render() {
        const {activeIndex,queryCatgory} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='CompletionIndicators'>
                <div className={'title_liwrp'}>
                    <div className= 'left-retu'onClick={this.goBack}>
                        <span>返回</span>
                    </div>
                </div>
                <div className={'AnalysisPerformance_bottom'}>
                    {queryCatgory ==1 ?
                        <ul className='TitleUl'>
                            <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                                this.handleTab(1)
                            }}>学校层面绩效指标统计
                            </li>
                            <li className={activeIndex === 2 ? "actived" : ""} onClick={() => {
                                this.handleTab(2)
                            }}>专业群1层面绩效指标统计
                            </li>
                            <li className={activeIndex === 3 ? "actived" : ""} onClick={() => {
                                this.handleTab(3)
                            }}>专业群2层面绩效指标统计
                            </li>
                        </ul>:
                        <ul className='TitleUl'>
                            <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                                this.handleTab(1)
                            }}>学校层面绩效指标统计
                            </li>
                            <li className={activeIndex === 2 ? "actived" : ""} onClick={() => {
                                this.handleTab(2)
                            }}>专业群层面绩效指标统计
                            </li>
                        </ul>
                    }
                    <ul className='TitleUl'>
                        <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                            this.handleTab(1)
                        }}>学校层面绩效指标统计
                        </li>
                        <li className={activeIndex === 2 ? "actived" : ""} onClick={() => {
                            this.handleTab(2)
                        }}>专业群1层面绩效指标统计
                        </li>
                        <li className={activeIndex === 3 ? "actived" : ""} onClick={() => {
                            this.handleTab(3)
                        }}>专业群2层面绩效指标统计
                        </li>
                    </ul>
                    <div className={'Analysis_cont'}>
                        <DegreeCompletion/>
                    </div>
                </div>
            </div>

        );
    }
}

CompletionIndicators = Form.create()(CompletionIndicators);
export default CompletionIndicators;
