/*栏目管理*/
import React, {Component} from "react";
import {
    Form,Input, Modal, Table, Cascader, Button,
} from "antd";
import {addUserToRole, deleteById, getUserListByNameOrg, roleUserpageList,roleUserList} from "../../api/distributionuser"
import {getRoleById} from "../../api/role"
import {getOrganizationTreeList} from "../../api/organizationManage"
import "./index.scss";
import {withRouter} from "react-router";


@withRouter
class Distributionuser extends Component {
    state = {
        visible: false,
        searchValue: {//查询按钮给接口的参数（对象）
            name: "",//文本框
            orgName: ""//下拉选

        },
        orgOptions: [],//组织下拉框
        //主页————表格标题
        columns: [
            {
                title: '用户名称',
                dataIndex: 'humanName',
                key: 'humanName',
                align: 'center',
            },
            {
                title: '所属组织',
                dataIndex: 'orgName',
                key: 'orgName',
                align: 'center',
            },
            {
                title: '职务',
                key: 'position',
                dataIndex: 'position',
                align: 'center',
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <span className="span_a">
                        <span onClick={() => this.handRelieve(record)}>移除</span>
                    </span>
                ),
            },


        ],
        //弹框————表格标题
        columnsTable: [
            {
                title: '用户名称',
                dataIndex: 'humanName',
                key: 'humanName',
                // render: text => <a>{text}</a>,
            },
            {
                title: '手机号',
                key: 'phone',
                dataIndex: 'phone',
            },
            {
                title: '所属组织',
                key: 'orgName',
                dataIndex: 'orgName',
            },

        ],
        //主页面数据
        dataSource: [],
        roleId: this.props.match.params.id,
        //添加用户页面数据
        dataModel: [],

        //添加用户界面的组织下拉选
        options: [],
        //主页————搜索条件
        search: {
            userName: "",
            roleId: this.props.match.params.id,
        },
        //弹框————搜索条件
        searchModal: {
            userName: "",
            roleId: this.props.match.params.id,
            orgId: ""
        },
        pageNum: 1,
        pageSize: 20,
        total: 0,
        //弹框——默认被选中用户
        selectedUsers: [],
        pageNum2: 1,
        pageSize2: 10,
        total2: 0,
        roleInfo:{},
    }

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.state.selectedUsers = selectedRowKeys
        },
        getCheckboxProps: record => ({
            defaultChecked: record.selected === 1, // 配置默认勾选的列
        }),

    };

    componentDidMount() {
        this.roleUserpageList();//主页数据查询
        this.getOrganizationTreeList();//查询组织
        this.getRoleById();
    }

    // region 接口
    getRoleById = async() =>{
        let data = {id:this.state.roleId}
        await getRoleById(data).then(res=>{
            this.setState({roleInfo:{
                id:res.id,name:res.roleName,
                }})
        })
    }
    //添加用户列表到角色中
    addUserToRole = async () => {
        let data = {
            roleId: this.state.roleId,
            userIdList: this.state.selectedUsers
        }
        await addUserToRole(data).then(res => {
            this.mesSuccess("用户已添加到角色中")
        })
    }
    //移除角色下用户
    deleteById = async (data) => {
        await deleteById(data).then(res => {
            this.mesSuccess("用户已从角色下移除")
        })
    }
    //主页面——角色下用户分页列表
    roleUserpageList = async () => {
        let data = {
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            userName: this.state.search.userName,
            roleId: this.state.search.roleId,
        }
        await  roleUserpageList(data).then(res => {
            if(res){
                this.setState({dataSource: res.data, pageNum: res.pageNum, total: res.total})
            }

        }).then(()=>{
            this.handleCancel()
        })
    }
    //查询组织列表-下拉专用
    getOrganizationTreeList = async () => {
        await getOrganizationTreeList({}).then(res => {
            this.setState({options: res})
        })
    }
    // endregion

    // 弹框——角色下用户分页列表
    getUserListByNameOrg = async () => {
        let data = {
            pageNum:this.state.pageNum2,
            pageSize:this.state.pageSize2,
            userName: this.state.searchModal.userName,
            roleId: this.state.searchModal.roleId,
            orgId: this.state.searchModal.orgId
        }
        await  getUserListByNameOrg(data).then(res => {
            this.setState({dataModel: res})

        })
    }
    //添加用户——对话框
    showModal = () => {
        this.setState({
            visible: true,
            searchModal: {userName: "", roleId: this.state.searchModal.roleId,}
        });

        this.getUserListByNameOrg()
    };

    handleOk = () => {
        this.addUserToRole().then(res => {
            this.setState({
                visible: false,
            });
            this.roleUserpageList()
            this.getUserListByNameOrg()
        })

    };
    handleOkAgain = () => {
        this.addUserToRole().then(res => {
            this.roleUserpageList()
        })

    };
    //取消添加用户
    handleCancel = e => {
        this.setState({
            visible: false,
        });
        this.setState({searchModal:{userName: "",orgId: "",roleId:this.state.searchModal.roleId}})
        this.setState({selectedUsers:[]})
    };
    onChange = () => {

    }
    handRelieve = async (record) => {
        this.setState({
            userId: record.id,
        }, () => {
            const {fullName} = this.state
            this.hint({
                "title": '温馨提示',
                'type': 'warning',
                'content': `从当前角色中移除该用户，是否继续？`,
                'cancelText': '取消',
                'okText': '确定',
                'isCancel': true,
                'sure': this.deleteUserById
            })
        })
    }
    deleteUserById = async () => {
        // message.success('解除成功');
        this.deleteById({roleId:this.state.roleId,userId:this.state.userId}).then(res=>{
            this.roleUserpageList()
        })
    }

    onMessageSearch = () => {
        this.roleUserpageList()

    }

    // 重置
    reset() {
        this.setState({searchModal:{userName: "",orgId: "",roleId:this.state.searchModal.roleId}})
        this.props.form.resetFields()
    }

    //弹框————查询用户
    selectInfo = () => {
        let organizationIds = this.props.form.getFieldValue('organization')
        this.state.searchModal.orgId = organizationIds[organizationIds.length - 1]
        this.getUserListByNameOrg()


    }
    //获取文本款输入的值e是文本框，值需要再取value
    //弹框————姓名搜索
    inputClick(e) {
        this.state.searchModal.userName = e.target.value
    }

    //获取下拉选选择的值
    //组织
    onGenderChange(e) {
        this.state.searchValue.orgName = e
    }

    searchNameChange = (e) => {
        this.setState({search: {userName: e.target.value, roleId: this.state.search.roleId,}})
    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
            //列表方法
            this.selectlabel()
        })
    }
    showSizeChange = (current, size) => {
        this.setState({pageSize: size},() => {
            this.selectlabel()
        })
    }
    //查询按钮
    selectlabel = () =>{
        this.roleUserpageList();//主页数据查询

    }
    changePage2 = (pageNum) => {
        this.setState({
            pageNum2: pageNum
        }, () => {
            //列表方法
            this.selectlabel2()
        })
    }
    showSizeChange2 = (current, size) => {
        this.setState({pageSize2: size},() => {
            this.selectlabel2()
        })
    }
    //查询按钮
    selectlabel2 = () =>{
        this.getUserListByNameOrg();//弹框数据查询
    }
    // Cascader显示最后一级
    displayRender=(label) => {
        return label[label.length - 1];
    }

    render() {
        const {columns, dataSource, options} = this.state
        const {getFieldDecorator} = this.props.form;
        const {Search} = Input;
        let pagination = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            total: this.state.total,
            current: this.state.pageNum,
            showSizeChanger: true,
            onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            onShowSizeChange: this.showSizeChange
        }
        let pagination2 = {
            pageNum: this.state.pageNum2,
            pageSize: this.state.pageSize2,
            total: this.state.total2,
            current: this.state.pageNum2,
            showSizeChanger: true,
            onChange: this.changePage2,
            onShowSizeChange: this.showSizeChange2
        }
        return (
            <div className='cloumn_center'>
                <div className='top-box'>
                    <div className='left-box'>
                        <i className='red-i'></i>
                        {/*<i className='allot-i'>分配用户</i>*/}
                        <i className='allot-i'>{this.state.roleInfo.name}</i>
                    </div>
                    <div className='right-box'>
                        <div className='right-seek'>
                            <i className='i-seek'>用户搜索</i>
                            <Search
                                className="inputa"
                                placeholder="请输入用户名称"
                                onChange={this.searchNameChange}
                                onSearch={(e) => this.onMessageSearch(e)}
                            />
                        </div>
                        <span className='add' onClick={this.showModal}>
                            <i>添加用户</i>
                        </span>


                    </div>

                </div>
                <div className='tabless'>
                    {/*表格*/}
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        scroll={{ y: 600 }}
                        locale={{emptyText:<><div className="Empty-conet-ss">
                                <div className="img">
                                    <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                    <span>暂无数据</span>
                                </div>
                            </div></>}}
                    />

                </div>
                <Modal
                    title="添加用户"
                    visible={this.state.visible}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            添加
                        </Button>,
                    ]}
                    width={'880px'}
                    wrapClassName={'pop-upwindows'}
                >

                    <div className='pop-up'>
                        <i className='add_users'>组织</i>
                        {
                            getFieldDecorator('organization', {
                                initialValue: ''
                            })(
                                <Cascader style={{width: '260px'}}
                                          className='organization'
                                          fieldNames={{label: 'orgName', value: 'id', children: 'children'}}
                                          options={options}
                                          displayRender ={this.displayRender}
                                          changeOnSelect
                                          placeholder="请选择组织"
                                          getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                />
                            )
                        }

                        <i className='add_users'>姓名</i>
                        {
                            getFieldDecorator('name', {
                                initialValue: ''
                            })(
                                <Input style={{width: '260px'}} placeholder="请输入用户姓名" autocomplete="off"
                                       onChange={(e) => this.inputClick(e)}/>
                            )
                        }
                        <Button className='replacement' type="" onClick={() => this.reset()}>重置</Button>
                        <Button className='about' type="danger" onClick={() => this.selectInfo()}>查询</Button>
                    </div>


                    <div>
                        <Table className='table-ta'
                               rowSelection={this.rowSelection}
                               columns={this.state.columnsTable}
                               dataSource={this.state.dataModel}
                               scroll={{ y: 350 }}
                               rowKey="id"
                               pagination={false}

                        />
                    </div>


                </Modal>

            </div>


        );
    }
}

Distributionuser = Form.create()(Distributionuser);
export default Distributionuser;
