import {get, post } from '../../until/axios_instans';

// ———————————————————————————————————————— 审批 ————————————————————————————————————————
//节点审批
export function nodeApproval(data){
    return post('/api/ss/api/v1/approval/nodeApproval',data);
}
//取消审批
export function cancleApproval(data){
    return post('/api/ss/api/v1/approval/cancleApproval',data);
}

// ———————————————————————————————————————— 资金审批流程 ————————————————————————————————————————
//获取工作流程
export function getWorkflow(data){
    return post('/api/ss/api/v1/workflow/getWorkflow',data);
}

//获取工作流程,通过任务ID
export function getWorkflowByExpendId(data){
    return post('/api/ss/api/v1/workflow/getWorkflowByExpendId',data);
}


// ———————————————————————————————————————— 支出 ————————————————————————————————————————
//校级资金支出列表分页
export function selectExpendProjectPageList(data){
    return post('/api/ss/api/v1/expend/selectExpendProjectPageList',data);
}
//所有任务资金查询列表
export function selectExpendTaskPageList(data){
    return post('/api/ss/api/v1/expend/selectExpendTaskPageList',data);
}

//跳转添加支出 预处理
export function goToAddExpendPrepare(data){
    return post('/api/ss/api/v1/expend/goToAddExpendPrepare',data);
}
//添加支出 预处理
export function addExpendPrepare(data){
    return post('/api/ss/api/v1/expend/addExpendPrepare',data);
}
//添加支出
export function addExpend(data){
    return post('/api/ss/api/v1/expend/addExpend',data);
}
//查询资金支出统计，通过任务ID
export function getExpendStatisticByTaskId(data){
    return post('/api/ss/api/v1/expend/getExpendStatisticByTaskId',data);
}
//获取资金支出列表，通过任务ID
export function getExpendListByTaskId(data){
    return post('/api/ss/api/v1/expend/getExpendListByTaskId',data);
}
//查询资金支出统计，通过任务ID
export function getExpendStatisticByProjectId(data){
    return post('/api/ss/api/v1/expend/getExpendStatisticByProjectId',data);
}
//项目资金支出列表，通过建设任务ID
export function getExpendListByProjectId(data){
    return post('/api/ss/api/v1/expend/getExpendListByProjectId',data);
}
//查询任务下拉框，通过建设任务ID(不包括被删除的任务)
export function getTaskSelectByProjectId(data){
    return post('/api/ss/api/v1/expend/getTaskSelectByProjectId',data);
}
//查询任务下拉框，通过建设任务ID(包括被删除的任务)
export function getTaskSelectByPIdContainsDeleted(data){
    return post('/api/ss/api/v1/expend/getTaskSelectByPIdContainsDeleted',data);
}
//支出类型下拉
export function getExpendTypeSelect(){
    return post('/api/ss/api/v1/expend/getExpendTypeSelect');
}
//预算来源类型下拉
export function getInvestSourceSelect(){
    return post('/api/ss/api/v1/expend/getInvestSourceSelect');
}
//资金总览
export function selectExpendAllList(data){
    return post('/api/ss/api/v1/expend/selectExpendAllList',data);
}
//建设任务资金设置查询
export function selectExpendInvestList(data){
    return post('/api/ss/api/v1/expend/selectExpendInvestList',data);
}
//任务预算资金查询
export function selectTaskExpendInvestList(data){
    return post('/api/ss/api/v1/expend/selectTaskExpendInvestList',data);
}
//任务预算资金设置
export function setUpFundsTask(data){
    return post('/api/ss/api/v1/expend/setUpFundsTask',data);
}
//查询任务资金分配预算额度
export function queryBudgetTaskQuota(data){
    return post('/api/ss/api/v1/expend/queryBudgetTaskQuota',data);
}

//建设任务预算设置
export function setProjectInvest(data){
    return post('/api/ss/api/v1/projectinvest/setProjectInvest',data);
}

// ———————————————————————————————————————— 支出详情 ————————————————————————————————————————
//查询支出详情
export function getExpendDetailInfo(data){
    return post('/api/ss/api/v1/expenddetail/getExpendDetailInfo',data);
}

//建设任务预算设置反显数据
export function selectProjectInvestList(data){
    return post('/api/ss/api/v1/expend/selectProjectInvestList',data);
}

//建设任务预算设置信息查询
export function selectTaskExpendInvestInfo(data){
    return post('/api/ss/api/v1/expend/selectTaskExpendInvestInfo',data);
}
