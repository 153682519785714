/*任务支出饼图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';
import {meritIndicatorsQuery} from "../../../api/chart";
import Cookies from "js-cookie";

@withRouter
class MeetPerformanceIndicators extends Component {
    state = {
        meritIndicatorsQueryList:[],
        name:[],
        indicatorsNum:[],
        notIndicatorsNum:[],
        exceedIndicatorsNum:[],
        defaultType:1,
        chartMeritCompletionId:9,
        chartMeritCompletionType:9,
        chartId:9,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
    }

    componentDidMount() {
        this.onChangeMeritTab()

    }
    onChangeMeritTab = async () => {
        let data ={
            type:this.props.type,
            code:0,
            defaultType:this.state.defaultType,
            chartId:this.state.chartMeritCompletionId,
            chartType:this.state.chartMeritCompletionType,
            schoolId:this.state.schoolId
        }
        await meritIndicatorsQuery(data).then(e => {
            let name=[]//名称
            let indicatorsNum=[]//指标数
            let notIndicatorsNum=[]//未完成指标数
            let exceedIndicatorsNum=[]//超预期指标数
            e.expendList.map(item=>{
                name.push(item.name.slice(0,-2))
                indicatorsNum.push(item.indicatorsNum)
                notIndicatorsNum.push(item.notIndicatorsNum)
                exceedIndicatorsNum.push(item.exceedIndicatorsNum)
            })
            this.setState({
                name:name,
                indicatorsNum:indicatorsNum,
                notIndicatorsNum:notIndicatorsNum,
                exceedIndicatorsNum:exceedIndicatorsNum,
            },()=>{
                this.meetPerformanceIndicators()
            })


        })


    }
    meetPerformanceIndicators = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('meetPerformanceIndicators')){
            let data1 = this.state.indicatorsNum
            let data2 = this.state.notIndicatorsNum
            let data3 = this.state.exceedIndicatorsNum
            let myChart = echarts.init(document.getElementById('meetPerformanceIndicators'));
            const rich = {

                b: {

                    fontSize: 18,

                    fontWeight: 'bold'

                },

                n: {

                    fontSize: 14,

                    lineHeight: 20

                }

            }
            const makeMultiLine = (str, charQty) => {

                const strs = str.split('')

                const len = strs.length

                if (len < charQty + 1) {

                    return str

                }

                let result = ''

                strs.forEach((_, index) => {

                    result += _

                    if ((index + 1) % charQty === 0 && index < len - 1) {

                        result += '\n'

                    }

                })

                return result

            }
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#3E78ED','#23B899','#FFEE7E'],
                legend: {
                    itemHeight: 10,
                    itemWidth: 10,
                    data: [{name: '指标数', icon: 'circle'}, {name: '未完成指标数', icon: 'circle'}, {name: '超出预期完成数', icon: 'circle'}],
                    bottom: 0,
                    left: '20%',
                },
                grid: {
                    left: '10%',
                    top: 10,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.state.name,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            formatter: function (val) {
                                let str = makeMultiLine(val, 4) // 每 5 个字符一行
                                return `{n|${str}}` // 使用 rich 中的 n 来设置样式
                            },
                            rich: {
                                n: {
                                    fontSize: '12',
                                },
                            },
                            interval: 0, // 显示所有的 x 轴上的文字不隐藏
                        },
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        type: 'value',
                    },
                ],
                series: [
                    {
                        name: '指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1,
                        barWidth: 10
                    },
                    {
                        name: '未完成指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2,
                        barWidth: 10
                    },
                    {
                        name: '超出预期完成数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data3,
                        barWidth: 10
                    },

                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    render() {
        return (
            <div style={{height: '310px', float: 'left',width: '100%','marginTop': '25px'}}>
                <div id={'meetPerformanceIndicators'} className={'echarts'} style={{width: '100%', height: '310px'}}></div>
            </div>

        );
    }
}

MeetPerformanceIndicators = Form.create()(MeetPerformanceIndicators);
export default MeetPerformanceIndicators;
