/*图表添加筛选调教*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import style from './index.module.scss'
import {queryConfitionByCTAndDT} from "../../../../../api/chart/index"
import {Button, Form, Input,Checkbox} from "antd";


@withRouter
class ReportEditor extends Component {
    state = {
        noAddConditionList : this.props.noAddConditionList,
        conditionDatas:[],
        conditionSelected:[]
    }
    componentDidMount() {
        console.log('this.props.useChildFlag',this.props.useChildFlag)
        this.props.addCondition(this)
        if(this.props.useChildFlag){
            this.queryConfitionByCTAndDT()
        }
    }
    queryConfitionByCTAndDT = async () => {
        let data = {
            chartId: this.props.chartId,
            chartType:this.props.chartType,
            oneDimensionId:this.props.oneDimensionId,
        }
        await queryConfitionByCTAndDT(data).then(res => {
            if(res){
                console.log('res.addConditionVoList',res.addConditionVoList)
                console.log('res.allConditionVoList',res.allConditionVoList)
                let conditionDatas = []
                let conditionSelected = []
                for (let i = 0; i < res.allConditionVoList.length; i++) {
                    conditionDatas.push({label: res.allConditionVoList[i].conditionName,value:res.allConditionVoList[i].id})
                }
                for (let j = 0; j < res.addConditionVoList.length; j++) {
                    conditionSelected.push(res.addConditionVoList[j].id)
                }

                this.setState({conditionDatas:conditionDatas,conditionSelected:conditionSelected},()=>{
                    console.log('conditionDatasconditionSelected',conditionSelected)
                    console.log('conditionDatas',conditionDatas)
                    this.props.form.setFieldsValue({conditionSelected})
                })
            }

        })

    }
    onChange = (checkedValues) =>{
        console.log('checkedValues',checkedValues)
        this.setState({checkedValues:checkedValues})
    }
    getCheckValues = ()=>{
        // console.log('this.state.checkedValues',this.state.checkedValues)
        return this.state.checkedValues
    }
    render() {
        let {getFieldDecorator}=this.props.form
        return (
            <div className={style.taskstatEditor}>
                <Form>
                    <Form.Item className="form_li">
                        <span className="span_firs">筛选条件</span>
                        {getFieldDecorator('conditionSelected', {
                            rules: [
                                {
                                    required: false,
                                    message: `请输入密码`,
                                },
                            ],
                        })(<Checkbox.Group style={{ width: '100%' }}
                                           onChange={(val)=>this.onChange(val)}
                                           options={this.state.conditionDatas}
                        >
                        </Checkbox.Group>)
                        }
                    </Form.Item>

                </Form>
            </div>
        );
    }
}

ReportEditor = Form.create()(ReportEditor);
export default ReportEditor;
