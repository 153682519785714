/*数据大屏 绩效指标完成度-满意度指标*/
import React, {Component} from "react";
import {
    Form
} from "antd";
import {withRouter} from "react-router";
import * as echarts from "echarts";

@withRouter
class satisfactionIndex extends Component {
    state = {
    }
    componentDidMount() {
        this.props.fromSatisfactionIndex(this)
    }
    productIndex = () => {
        if(document.getElementById('satisfactionIndex')) {
            let myChart = echarts.init(document.getElementById('satisfactionIndex'));
            let option = {
                title: {
                    text: '满意度指标',//主标题文本
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        fontSize: 16,
                        color: 'rgba(255, 255, 255, 1)',
                        align: 'center'
                    }
                },
                grid: {
                    left: '5%',
                    top: '0'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['67%', '90%'],
                        avoidLabelOverlap: false,
                        color: ['#FF5B88', 'rgba(255, 255, 255, 0.2)',],
                        // hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        data: [
                            {
                                value: this.props.benefitMeritIndicatorCompleteDu/100,
                                itemStyle:{
                                    normal:{color:'#FF5B88'},
                                    emphasis:{color:'#FF5B88'}
                                }
                            },
                            {
                                value: 1 - this.props.benefitMeritIndicatorCompleteDu/100,
                                itemStyle:{
                                    normal:{color:'rgba(255, 255, 255, 0.2)'},
                                    emphasis:{color:'rgba(255, 255, 255, 0.2)'}
                                }
                            }
                        ],
                        itemStyle:{
                            borderWidth:3, //设置border的宽度有多大
                            borderColor:'rgba(8, 26, 48, 0.8)',
                        }
                    }
                ]
            };
            myChart.setOption(option)
            myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        return (
            <div id={'satisfactionIndex'} className={'echarts'} style={{width: '100%', height: '100%'}}></div>

        );
    }
}

satisfactionIndex = Form.create()(satisfactionIndex);
export default satisfactionIndex;
