/*新建报表*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form, Tabs,Button} from "antd";
import style from './index.module.scss';
import * as echarts from 'echarts';
const { TabPane } = Tabs;
@withRouter
class NewReport extends Component {
    state = {
        titleName:"自定义报表",
        tabKey:1,
        progressList:[
            {title:"任务与绩效进展走势",content:"了解任务与绩效完成进度及其走势",router: '/main/doublehigh/taskProgress'},
            {title:"任务统计",content:"了解任务的逾期情况",router: '/main/doublehigh/taskStatistical'},
            {title:"各建设任务支出占比",content:"了解任务与绩效完成进度及其走势",router: '/main/doublehigh/taskspendingpercent'},
            {title:"绩效指标达标情况",content:"了解任务的逾期情况",router: '/main/doublehigh/taskachievementofindicators'},
            {title:"绩效指标完成度",content:"了解任务与绩效完成进度及其走势",router: '/main/doublehigh/performanceIndicator'},
        ]
    }

    componentDidMount() {
    }
    reportFormType=(e)=>{
        this.setState({
            tabKey:e
        })
    }
    render() {
        const { titleName,tabKey,progressList } = this.state;
        //自定义报表
        const custom=<div className={style.custom}>
            <div className={style.two_title}>
                <img src={require("@/assets/img/custom.png")} alt=""/>
                <span>自定义报表</span>
            </div>
            <div className={style.report_content} onClick={()=>{this.props.history.push('/main/doublehigh/unnamedBlocks')}}>
                <div className={"vertical_line"}></div>
                <div>
                    <div className={style.content_title}>自定义报表</div>
                    <div className={style.content_title_two}>可创建自定义报表</div>
                </div>

            </div>
        </div>
        const summary=<div className={style.summary}>
            <div className={style.two_title}>
                <img src={require("@/assets/img/summary.png")} alt=""/>
                <span>概述报表</span>
            </div>
            <div className={style.report_content} onClick={()=>{this.props.history.push('/main/doublehigh/constructionscheduleview')}}>
                <div className="vertical_line vertical_line_summary"></div>
                <div>
                    <div className="content_title">概述报表</div>
                    <div className={style.content_title_two}>了解双高建设项目数据概况</div>
                </div>

            </div>
        </div>
        const progress=<div className={style.progress}><div className={style.two_title}>
            <img src={require("@/assets/img/progress.png")} alt=""/>
            <span>进度统计</span>
        </div>
            <div className="progressList_box">
            {
                progressList&&progressList.map(item=>(
                            <div className={style.report_content} onClick={()=>{this.props.history.push(`${item.router}`)}}>
                                <div className="vertical_line vertical_line_progress"></div>
                                <div>
                                    <div className="content_title">{item.title}</div>
                                    <div className={style.content_title_two}>{item.content}</div>
                                </div>

                            </div>

                ))
            }
            </div>
        </div>
        return (
            <div className={style.newReport}>
                <div className={style.title}>{titleName}</div>
                <div className={style.line}></div>
                <Tabs onChange={(e)=>this.reportFormType(e)}>
                    <TabPane tab="自定义报表" key="1">
                        {custom}
                    </TabPane>
                    <TabPane tab="概览报表" key="2">
                        {summary}
                    </TabPane>
                    <TabPane tab="进度统计" key="3">
                        {progress}
                    </TabPane>

                </Tabs>
                {/*{tabKey=='1'?custom:tabKey=='2'?summary:tabKey=='3'?progress:''}*/}
            </div>

        );
    }
}

NewReport = Form.create()(NewReport);
export default NewReport;
