import * as actionTypes from '../contants/index';

let userInfoList = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):''
const userInfo = (state = userInfoList, action) => {
	switch (action.type) {
		case actionTypes.SET_USERINFO:
			return action.data;
		default:
			return state;
	}
};

export default userInfo;
