/*数据大屏展示*/
import React, {Component} from "react";
import {
    Form,Tabs,Progress
} from "antd";
import {Titleicon,Timeicon,Goup} from '../../assets/svg'
import {withRouter} from "react-router";
import style from './index.module.scss';
import CompliancePerformanceIndicators from './components/compliancePerformanceIndicators' // 基西奥指标达标情况
import ScreenTaskPerformance from "./components/screenTaskPerformance"; //任务与绩效进展走势
import FundUsageLimit from './components/fundUsageLimit'
import ScreenTaskSpending from './components/screenTaskSpending' //各建设任务支出占比
import ProductIndex from './components/productIndex' //绩效指标完成度-产品指标
import PerformanceIndicator from "./components/performanceIndicator";  //任务统计-绩效指标
import SatisfactionIndex from './components/satisfactionIndex' //任务统计-满意度指标
import {chartSchoolInfo,queryAccessLevel,queryScreenInfo} from '../../api/chart/'
import {surveyProjectQuery,surveyMeritQuery,surveyExpendQuery,queryMeritCompleteDu,meritIndicatorsQuery,queryTaskChart,queryTaskMeritCompByDirector,queryTaskMeritTrend,largeScreenList} from "../../api/chart"
import {queryCatgoryById} from "../../api/synchrodatment";
import TaskPerformance from "../dataStatisticsShow/components/taskPerformance";
import TaskSpending from "../dataStatisticsShow/components/taskSpending";

const { TabPane } = Tabs;

@withRouter
class dataScreen extends Component {
    state = {
        surveyProjectQueryList: [],
        surveyProjectQuery: [],
        surveyMeritQuery: [],
        surveyMeritQueryList: [],
        surveyExpendQuery: [],
        surveyExpendQueryList: [],
        queryTaskChartList:[],
        meritIndicatorsQueryList:[],
        type:1,
        queryCatgory:0,
        schoolId: this.props.match.params.schoolId,
        code:0,
        schoolInfo: {
            catgory:"",
            gear:"",
            id:"",
            schoolName:"",
            specialtyGroupOne:"",
            specialtyGroupTwo:""
        },
        accessUrl: '',
        urlAddress: window.location.href,
        chartInfoList:[],
        //概况资金的传参
        chartExpendId:'',
        chartExpendType:3,
        //概况绩效的传参
        chartMeritId:'',
        chartMeritType:'',
        //概况建设任务进度的传参
        chartProjectId:'',
        chartProjectType:1,
        //任务与绩效进展走势-任务的传参
        chartTaskTrendId:'',
        chartTaskTrendType:'',
        //任务与绩效进展走势-绩效的传参
        chartMeritTrendId:'',
        chartMeritTrendType:'',
        //各建设任务支出占比
        chartProjectExpendId:'',
        chartProjectExpendType:'',
        //任务统计的传参
        chartTaskId:'',
        chartTaskType:'',
        //绩效指标完成度的传参
        chartMeritCompletionId:'',
        chartMeritCompletionType:'',
        //建设任务进度的传参
        chartMeritComplianceId:'',
        chartMeritComplianceType:'',
        trendType:1,//1任务2绩效
        personOfCompletedVos:[],//任务绩效负责人完成数量
        cengMian:1,//1学校，2群一，3群二，4其他
        cengMianRate:1,//1学校，2群一，3群二，4其他
        cengMianMeritCompleteDu:1,//1学校，2群一，3群二，4其他
        dateType:3,//1七日内 2一月内 3全年
        outputMeritIndicatorCompleteDu:'',
        benefitMeritIndicatorCompleteDu:'',
        satisfactionIndicatorCompleteDu:'',
        isLoading: false,
        urlCode: '1',
        screenTaskPerformanceKey:0,//子组件调用key

    }
    constructor(props) {
        super(props);
        this.queryAccessLevel()
    }

    componentDidMount() {

    }

    //获取学校公开级别
    queryAccessLevel = () =>{
        let data = {
            schoolId: this.state.schoolId,
            code: this.state.urlCode
        };

        queryAccessLevel(data).then(res => {
            let address = "http://"+window.location.host;
            if(res){
                if(res.auth === '1'){
                    window.open(address,'_self')
                }else if(res.auth === '2'){
                    if(window.location.href.indexOf("success") === -1){
                        window.open(address+'#/domesticDetection/'+this.state.schoolId,'_self')
                    }else{
                        this.setState({isLoading:true},()=>{
                            this.largeScreenList()
                            this.chartSchoolInfo()
                        })
                    }
                }else{
                    this.setState({isLoading:true},()=>{
                        this.largeScreenList()
                        this.chartSchoolInfo()
                    })
                }
            }
        })
    }
    //大屏图学校信息
    chartSchoolInfo = () => {
        let data = {
            schoolId: this.state.schoolId,
        };
        chartSchoolInfo(data).then(res => {
            if (res) {
                this.setState({
                    schoolInfo: res,
                });
            }
        })
    };
    //查询需要传的参数
    largeScreenList = async () => {
        let data={
            code:0,
            schoolId: this.state.schoolId,
        }
        await largeScreenList(data).then(e => {
            if (e){
                e.map((v, i) => {
                    if (v.id==='1'){
                        this.setState({
                            //概况建设任务进度的传参
                            chartProjectId:v.id,
                            chartProjectType:v.chartType,
                        })
                    }
                    if (v.id==='2'){
                        this.setState({
                            //概况绩效的传参
                            chartMeritId:v.id,
                            chartMeritType:v.chartType,
                        })
                    }
                    if (v.id==='3'){

                        this.setState({
                            chartExpendId:v.id,
                            chartExpendType:v.chartType,
                        })
                    }


                    if (v.id==='4'){
                        this.setState({
                            //任务与绩效进展走势-任务的传参
                            chartTaskTrendId:v.id,
                            chartTaskTrendType:v.chartType,
                        })
                    }
                    if (v.id==='5'){
                        this.setState({
                            //任务与绩效进展走势-绩效的传参
                            chartMeritTrendId:v.id,
                            chartMeritTrendType:v.chartType,
                        })
                    }
                    if (v.id==='6'){
                        this.setState({
                            //任务统计的传参
                            chartTaskId:v.id,
                            chartTaskType:v.chartType,
                        })
                    }
                    if (v.id==='7'){
                        this.setState({
                            //各建设任务支出占比
                            chartProjectExpendId:v.id,
                            chartProjectExpendType:v.chartType,

                        })
                    }
                    if (v.id==='8'){
                        this.setState({
                            //绩效达标的传参
                            chartMeritComplianceId:v.id,
                            chartMeritComplianceType:v.chartType,

                        })
                    }
                    if (v.id==='9'){
                        this.setState({
                            //绩效指标完成度的传参
                            chartMeritCompletionId:v.id,
                            chartMeritCompletionType:v.chartType,
                        })
                    }
                })
            }
        }).then(res=>{
            this.fromMerit.onChangeMeritTab()
            this.queryTaskExpendRate.queryProjectTaskExpendRate()
            this.surveyProjectQuery()
            this.surveyMeritQuery()
            this.surveyExpendQuery()
            this.queryTaskChart()
            this.queryTaskMeritTrend()
            this.queryMeritCompleteDu()
        })
    }
    //概况图表查建设任务进度
    surveyProjectQuery = async () => {
        let data={
            code:0,    //概况资金的传参
            //概况建设任务进度的传参
            chartId:this.state.chartProjectId,
            chartType:this.state.chartProjectType,
            schoolId:this.state.schoolId,
            defaultType:1

        }
        console.log("概况建设任务进度的传参-surveyExpendQuery:",JSON.stringify(data));
        await surveyProjectQuery(data).then(e => {
            this.setState({
                surveyProjectQuery:e.surveyVo,
            })
        })
    }

    //概况图表绩效进度
    surveyMeritQuery = async () => {
        let data={
            code:0,
            //概况绩效的传参
            chartId:this.state.chartMeritId,
            chartType:6,  //todo  this.state.chartMeritType,
            schoolId:this.state.schoolId,
            defaultType:1
        }
        await surveyMeritQuery(data).then(e => {
            this.setState({
                surveyMeritQuery:e.surveyVo
            })
        })
    }

    //概况图资金使用情况
    surveyExpendQuery = async () => {
        let data={
            code:0,
            chartId:this.state.chartExpendId,
            chartType:this.state.chartExpendType,
            schoolId:this.state.schoolId,
            defaultType:1
        }
        console.log("概况图资金使用情况-surveyExpendQuery:",JSON.stringify(data));
        await surveyExpendQuery(data).then(e => {
            this.setState({
                surveyExpendQuery:e.surveyVo,
            },()=>{
                this.formRef.fundUsageLimit()
            })
        })
    }

    //任务图
    queryTaskChart = async () => {
        let data ={
            code:0,
            chartId:this.state.chartTaskId,
            chartType: 6,  //todo  this.state.chartTaskType,
            schoolId:this.state.schoolId,
            defaultType:1
        }
        await queryTaskChart(data).then(e => {
            this.setState({
                queryTaskChartList:e,
            })
        })
    }

    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    onChangeTabMeetPerformanceIndicators = (item)=>{
        this.setState({type:item},()=>{
            this.fromMerit.onChangeMeritTab()
        })
    }
    //任务进展与走势的一些方法
    onChangeTabofTrend = (item)=>{
        this.setState({screenTaskPerformanceKey:this.state.screenTaskPerformanceKey+1,trendType:item},()=>{
            this.queryTaskMeritTrend()
        })
    }
    queryTaskMeritTrend = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType,
            schoolId:this.state.schoolId,
            chartId: this.state.trendType==='1'?this.state.chartTaskTrendId:this.state.chartMeritTrendId,
            chartType:this.state.trendType==='1'?this.state.chartTaskTrendType:this.state.chartMeritTrendType,
        }
        await queryTaskMeritTrend(data).then(res => {
            if(res){
                this.setState({personOfCompletedVos:res.personOfCompletedVos})

            }

        })
    }

    //1七日内/30日/全年切换
    dateConversion = (dateType)=>{
        this.setState({screenTaskPerformanceKey:this.state.screenTaskPerformanceKey+1,dateType},()=>{
            this.queryTaskMeritTrend()
        })
    }
    //支出占比一些方法
    onChangeTabOfcengMianRate = (item)=>{
        // this.setState({
        //     cengMianRate:item
        // })
        this.queryTaskExpendRate.queryProjectTaskExpendRate(item)

    }
    onChangeTabOfMeritCompleteDu = (item)=>{
        this.state.cengMianMeritCompleteDu=item
        this.queryMeritCompleteDu()
    }

    queryMeritCompleteDu = async () => {
        let data = {
            cengMian: this.state.cengMianMeritCompleteDu,
            chartId:this.state.chartMeritCompletionId,
            chartType:this.state.chartMeritCompletionType,
            schoolId:this.state.schoolId,
        }
        await queryMeritCompleteDu(data).then(res => {
            if(res){
                this.setState({
                    outputMeritIndicatorCompleteDu:parseFloat(res.outputMeritIndicatorCompleteDu).toFixed(2),
                    benefitMeritIndicatorCompleteDu:parseFloat(res.benefitMeritIndicatorCompleteDu).toFixed(2),
                    satisfactionIndicatorCompleteDu:parseFloat(res.satisfactionIndicatorCompleteDu).toFixed(2),
                },()=>{
                    this.fromProductIndex.productIndex()
                    this.fromPerformanceIndicator.performanceIndicator()
                    this.fromSatisfactionIndex.productIndex()
                })
            }
        })
    }
    render() {
        const {typeList,typeList1,typeList2,newReportModal,surveyProjectQuery,outputMeritIndicatorCompleteDu,satisfactionIndicatorCompleteDu,benefitMeritIndicatorCompleteDu,
            surveyProjectQueryList,surveyMeritQuery,surveyMeritQueryList,surveyExpendQuery,surveyExpendQueryList,
            cengMianRate, queryTaskChartList,meritIndicatorsQueryList,queryCatgory,activeS,activeT,activeY,schoolInfo,chartMeritComplianceId,chartMeritComplianceType,dateType,isLoading} =this.state
        let d = new Date();
        let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
        let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
        let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
        let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
        let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();
        let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
        if(isLoading){
        return (
            <div className={style.dataScreen} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-bigbg.png')}")`}}>
                <div className={'header-wrap'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-headerbg.png')}")`}}>
                    <div className={'header-left'}>
                        <Timeicon/>
                        <span className={'time'}>更新时间：{times}</span>
                    </div>
                    <div className={'header-center'}>
                        <div className={'text'}>{schoolInfo.schoolName}</div>
                    </div>
                    <div className={'header-right'}>
                        <div className={'text-wrap'} style={{background: `url("${window.$$setImgSrc('/ss-dataScreen-timebg.png')}") no-repeat 100%`}}>{schoolInfo.catgory?schoolInfo.catgory==1?"高水平学校":schoolInfo.catgory==2?"高水平专业群":"":""} 评级:{schoolInfo.gear ? schoolInfo.gear==1?"A":schoolInfo.gear==2?"B": schoolInfo.gear == 3 ?"C":"":""} 专业群:{schoolInfo.specialtyGroupOne} {schoolInfo.specialtyGroupTwo}</div>
                            </div>
                </div>
                <div className={'content-wrap'}>
                    <div className={'left-cont'}>
                        <div className={'left-echarts'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>概览</span>
                            </div>
                            <div className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-leftborder.png')}")`}}>
                                <ul className={'gailan-ul'}>
                                    <li>
                                        <span className={'li-title'}>建设任务进度</span>
                                        <span className={'li-number'}>{surveyProjectQuery.projectProgress}%</span>
                                        <div className={'progress-wrap'}>
                                            <Progress percent={surveyProjectQuery.projectProgress} className={'progress'} strokeWidth={'10px'} strokeColor={'#1CD1A1'} showInfo={false}/>
                                            {/*<span className={'up'} style={{left: '90%'}}></span>*/}
                                        </div>
                                        <div className={'li-text'}>近30日增长率：{surveyProjectQuery.growthRate}%<Goup/></div>
                                    </li>
                                    <li>
                                        <span className={'li-title'}>绩效完成度</span>
                                        <span className={'li-number'}>{surveyMeritQuery.meritProgress}%</span>
                                        <div className={'progress-wrap'}>
                                            <Progress percent={surveyMeritQuery.meritProgress} className={'progress'} strokeWidth={'10px'} strokeColor={'#F4DF58'} showInfo={false}/>
                                            {/*<span className={'up up1'} style={{left: '79%'}}></span>*/}
                                        </div>
                                        <div className={'li-text'}>平均完成度：{surveyMeritQuery.meritAverageProgress}%</div>
                                    </li>
                                    <li>
                                        <span className={'li-title'}>资金使用额度</span>
                                        <span className={'li-number'}>¥{surveyExpendQuery.expendUseQuota?surveyExpendQuery.expendUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0} 元</span>
                                        <div className={'fund-use'}><FundUsageLimit surveyExpendQuery={this.state.surveyExpendQuery} formRef={(form) => this.formRef = form}/></div>
                                        <div className={'li-text'}>月均使用额：¥{surveyExpendQuery.monthlyUseQuota?surveyExpendQuery.monthlyUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'left-echarts left-echarts-two'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>任务与绩效进展走势</span>
                            </div>
                            <div className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-leftborder-two.png')}")`}}>
                                <div className='tab-wrap'>
                                    <Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabofTrend(item)}>
                                        <TabPane tab={'任务'} key="1">
                                        </TabPane>
                                        <TabPane  tab={'绩效'} key="2">
                                        </TabPane>
                                    </Tabs>
                                    <div className={'riqi'}>
                                        <span className={dateType == 1 ? 'active' : ''} onClick={()=>{this.dateConversion(1)}}>7日</span>
                                        <span className={dateType == 2 ? 'active' : ''} onClick={()=>{this.dateConversion(2)}}>30日</span>
                                        <span className={dateType == 3 ? 'active' : ''} onClick={()=>{this.dateConversion(3)}}>全年</span>
                                    </div>
                                    <div className={'task-performance-cont'}>
                                        <div className={'cont-left-echarts'}>
                                            <span className={'name'}>完成数量</span>
                                            <div className={'taskPerformance'}>
                                                <ScreenTaskPerformance key={this.state.screenTaskPerformanceKey} trendType={this.state.trendType} dateType={this.state.dateType}/>
                                            </div>
                                        </div>
                                        <div className={'cont-right-ranking'}>
                                            <span className={'name'}>负责人完成情况排名</span>
                                            <div className={'center-wrap'}>
                                                {
                                                    this.state.personOfCompletedVos && this.state.personOfCompletedVos.map((item,index)=>(

                                                        <div className={'center'}>
                                                            <div className={'center-left'}>
                                                                <span className={'top'}>{index+1}</span>
                                                                <span className={'span-name'}>{item.completePerson}</span>
                                                            </div>
                                                            <span className={'center-right'}>{item.completeCount}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'left-echarts left-echarts-three'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>任务统计</span>
                            </div>
                            <ul className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-leftborder-three.png')}")`}}>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'text'}>任务总数</div>
                                        <div className={'number'}>{queryTaskChartList.totalTask?queryTaskChartList.totalTask:0}</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.totalTask?queryTaskChartList.totalTask/10:0} className={'progress'} strokeWidth={'.08rem'} strokeColor={'#FE5578'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.totalTask?queryTaskChartList.totalTask/10+'%':0+'%',background:'#FE5578'}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'number'}>{queryTaskChartList.doneTask?queryTaskChartList.doneTask:0}</div>
                                        <div className={'text'}>已完成</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.doneTask?queryTaskChartList.doneTask/10:0} className={'progress'} strokeWidth={'8px'} strokeColor={'#1CD1A1'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.doneTask?queryTaskChartList.doneTask/10+'%':0+'%',background:'#1CD1A1'}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'number'}>{queryTaskChartList.expiredTask?queryTaskChartList.expiredTask:0}</div>
                                        <div className={'text'}>已逾期</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.expiredTask?queryTaskChartList.expiredTask/10:0} className={'progress'} strokeWidth={'8px'} strokeColor={'#1B85F2'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.expiredTask?queryTaskChartList.expiredTask/10+'%':0+'%',background:'#1B85F2'}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'number'}>{queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask:0}</div>
                                        <div className={'text'}>未完成</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask/10:0} className={'progress'} strokeWidth={'8px'} strokeColor={'#1CD1A1'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask/10+'%':0+'%',background:'#1CD1A1'}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'number'}>{queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask:0}</div>
                                        <div className={'text'}>待认领</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask/10:0} className={'progress'} strokeWidth={'8px'} strokeColor={'#1B85F2'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask/10+'%':0+'%',background:'#1B85F2'}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'left-number'}>
                                        <div className={'number'}>{queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask:0}</div>
                                        <div className={'text'}>时间待定</div>
                                    </div>
                                    <div className={'right-eahcrts'}>
                                        <Progress percent={queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask/10:0} className={'progress'} strokeWidth={'8px'} strokeColor={'#FE5578'} showInfo={false}/>
                                        <div className={'circle'} style={{left: queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask/10+'%':0+'%',background:'#FE5578'}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={'right-cont'}>
                        <div className={'right-echarts'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg-right.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>绩效指标达标情况</span>
                            </div>
                            <div className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-rightborder.png')}")`}}>
                                {queryCatgory===1?<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群一'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'专业群二'} key="3">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>:<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>}
                                <ul className={'legend'}>
                                    <li>
                                        <span></span>
                                        <div className={'text'}>指标数</div>
                                    </li>
                                    <li>
                                        <span style={{'background':'#1CD1A1'}}></span>
                                        <div className={'text'}>未完成目标数</div>
                                    </li>
                                    <li>
                                        <span style={{'background':'#59BCFB'}}></span>
                                        <div className={'text'}>超出预期完成数</div>
                                    </li>
                                </ul>
                                <div className={'meetPerformanceIndicators'}>
                                    <CompliancePerformanceIndicators chartMeritComplianceId={chartMeritComplianceId} chartMeritComplianceType={chartMeritComplianceType}  fromMerit={(form) => this.fromMerit = form} key={this.state.type} type={this.state.type}  meritIndicatorsQueryList={meritIndicatorsQueryList} />
                                </div>
                            </div>
                        </div>
                        <div className={'right-echarts right-echarts-two'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg-right.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>各建设任务支出占比</span>
                            </div>
                            <div className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-rightborder-two.png')}")`}}>
                                {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群一'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'专业群二'} key="3">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>}
                                <div className={'taskSpending'}><ScreenTaskSpending   queryTaskExpendRate={(form) => this.queryTaskExpendRate = form} chartProjectExpendId={this.state.chartProjectExpendId}
                                    chartProjectExpendType={this.state.chartProjectExpendType} key={cengMianRate} cengMianRate={cengMianRate}/></div>
                            </div>
                        </div>
                        <div className={'right-echarts right-echarts-three'}>
                            <div className={'title'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-titlebg-right.png')}")`}}>
                                <Titleicon/>
                                <span className={'title-name'}>绩效指标完成度</span>
                            </div>
                            <div className={'cont'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-rightborder-three.png')}")`}}>
                                {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群一'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'专业群二'} key="3">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>}
                                <ul className={'echarts-ul'}>
                                    <li>
                                        <ProductIndex outputMeritIndicatorCompleteDu={outputMeritIndicatorCompleteDu}  fromProductIndex={(form) => this.fromProductIndex = form}/>
                                    </li>
                                    <li>
                                        <PerformanceIndicator satisfactionIndicatorCompleteDu={satisfactionIndicatorCompleteDu} fromPerformanceIndicator={(form) => this.fromPerformanceIndicator = form} />
                                    </li>
                                    <li>
                                        <SatisfactionIndex  benefitMeritIndicatorCompleteDu={benefitMeritIndicatorCompleteDu} fromSatisfactionIndex={(form) => this.fromSatisfactionIndex = form} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            );
        }else{
            return (<div></div>)
        }

    }
}

dataScreen = Form.create()(dataScreen);
export default dataScreen;
