import React, { Component } from 'react'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Affix } from 'antd'
import './index.scss'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



//怕影响其他系统预览资源，拷贝出一份进行修改
export default class FilePreview extends Component {
    state = {
        full_flag: true,
        scalcnum: 1,
        currentpage: 0,
        totalPage: 0,
        resourceDetail: {},
        more: false,
        pageWidth: 0
    };

    componentDidMount() {
        this.props.onRef(this);
        this.setPageWidth();
    }
    //获取内容区宽度
    setPageWidth = () => {
        let el = document.querySelector('.page_container');
        this.setState({ pageWidth: el?.clientWidth });
    }
    set_full_flag = (flag) => {
        this.setState({
            full_flag: !flag
        }, () => {
            this.props.setFullType(!flag)
        })
    };

    set_full_flag_child = (flag) => {
        this.setState({
            full_flag: !flag
        }, () => {
            this.setPageWidth();
        })
    };

    scalc_c = (type, n) => {
        if (type === '-' && n > 0.6) {
            this.setState({
                scalcnum: n - 0.1
            })
        } else if (type === '+' && n < 1.5) {
            this.setState({
                scalcnum: n * 1 + 0.1
            })
        }
    };
    onDocumentLoadSuccess = (e) => {
        this.setState({
            totalPage: e.numPages
        })
    };

    render() {
        const { full_flag, totalPage, scalcnum, pageWidth } = this.state
        const { src } = this.props;
        return (
            <div className="left_bottom" >
                <Affix offsetTop={70} className='c_affix'>
                    <div className="c_bl">
                        <div className="img_w" >
                        </div>
                        <div className="c_br">
                            <div>
                                <span onClick={() => this.scalc_c('-', scalcnum)} style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/resoure_img/reduce.png')} alt='' />
                                </span>
                                <span style={{ marginRight: '20px' }} onClick={() => this.scalc_c('+', scalcnum)}>
                                    <img src={require('../../assets/resoure_img/add.png')} alt='' />
                                </span>
                            </div>
                            <span onClick={() => this.set_full_flag(full_flag)}>
                                <img className='suofang' src={require(full_flag ? '../../assets/resoure_img/fangda.png' : "../../assets/resoure_img/suoxiao.png")} alt='' />
                            </span>
                        </div>
                    </div>
                </Affix>
                <div className="main_preview" style={{ overflow: 'auto' }}>
                    <div className='page_container'>
                        <Document
                            className='Document_wrap_c'
                            ref={(el) => this.Document_wrap_cel = el}
                            file={src}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={console.error}
                            renderMode="canvas"
                            loading="正在努力加载中"
                            externalLinkTarget="_blank"
                            renderTextLayer={true}
                        >
                            {
                                Array.from({ length: totalPage }, (v, k) => k + 2).map((v, i) => {
                                    return <div key={i}>
                                        <Page width={pageWidth} pageNumber={v - 1} key={i} scale={scalcnum} />
                                        <div className='pagenum'>{i + 1}</div>
                                    </div>
                                })
                            }
                        </Document>
                    </div>
                </div>
            </div>
        )
    }
}


