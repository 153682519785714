
import React from 'react'
import ReactEcharts from 'echarts-for-react';

class EchartsMapTwo extends React.Component {
    state = {
        dataname:['中央财政收入','地方财政收入','举办方投入','行业企业投入','学校自筹资金'],
        datamax:[],
        datavaule:[],
        datavaule1:[],
        datavaule2:[],
        datavaule3:[],
        dataTime: ['累计','2019','2020','2021'],
    }

    componentDidMount() {
        let schoolMoneyOverview = this.props.schoolMoneyOverview
        console.log('schoolMoneyOverview 888==========>>>>6666666', schoolMoneyOverview)
        console.log('schoolMoneyOverview 888==========>>>>6666666 centralVo', schoolMoneyOverview.centralVo)
        console.log('schoolMoneyOverview 888==========>>>>6666666 placeVo', schoolMoneyOverview.placeVo)
        console.log('schoolMoneyOverview 888==========>>>>6666666 hostVo', schoolMoneyOverview.hostVo)
        console.log('schoolMoneyOverview 888==========>>>>6666666 industryVo', schoolMoneyOverview.industryVo)
        console.log('schoolMoneyOverview 888==========>>>>6666666.schoolVo', schoolMoneyOverview.schoolVo)
        let datamax = []  //累计
        datamax.push(schoolMoneyOverview.centralVo.totalInvestPlaceMoney)
        datamax.push(schoolMoneyOverview.placeVo.totalInvestPlaceMoney)
        datamax.push(schoolMoneyOverview.hostVo.totalInvestPlaceMoney)
        datamax.push(schoolMoneyOverview.industryVo.totalInvestPlaceMoney)
        datamax.push(schoolMoneyOverview.schoolVo.totalInvestPlaceMoney)
        let datavaule = []  //19
        datavaule.push(schoolMoneyOverview.centralVo.nineteenInvestPlaceMoney)
        datavaule.push(schoolMoneyOverview.placeVo.nineteenInvestPlaceMoney)
        datavaule.push(schoolMoneyOverview.hostVo.nineteenInvestPlaceMoney)
        datavaule.push(schoolMoneyOverview.industryVo.nineteenInvestPlaceMoney)
        datavaule.push(schoolMoneyOverview.schoolVo.nineteenInvestPlaceMoney)
        let datavaule1 = []   //20
        datavaule1.push(schoolMoneyOverview.centralVo.twentyInvestPlaceMoney)
        datavaule1.push(schoolMoneyOverview.placeVo.twentyInvestPlaceMoney)
        datavaule1.push(schoolMoneyOverview.hostVo.twentyInvestPlaceMoney)
        datavaule1.push(schoolMoneyOverview.industryVo.twentyInvestPlaceMoney)
        datavaule1.push(schoolMoneyOverview.schoolVo.twentyInvestPlaceMoney)
        let datavaule2 = []   //21
        datavaule2.push(schoolMoneyOverview.centralVo.twentyOneInvestPlaceMoney)
        datavaule2.push(schoolMoneyOverview.placeVo.twentyOneInvestPlaceMoney)
        datavaule2.push(schoolMoneyOverview.hostVo.twentyOneInvestPlaceMoney)
        datavaule2.push(schoolMoneyOverview.industryVo.twentyOneInvestPlaceMoney)
        datavaule2.push(schoolMoneyOverview.schoolVo.twentyOneInvestPlaceMoney)
        this.setState({
            datamax: datamax,
            datavaule: datavaule,
            datavaule1: datavaule1,
            datavaule2: datavaule2,
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    optionPie = () => { // 雷达图
        const {dataname,datamax,datavaule,datavaule1,datavaule2,dataTime} = this.state
        return {
            title: {
                text: '资金（万元）',
                top: '18',
                left:'10%',
                textStyle: {  color: '#333333', fontSize: 14, fontWeight:'normal'},
            },
            tooltip: {
                show: true,
                trigger: "item",
            },
            legend: {
                data:dataTime,
                type: "scroll",
                icon:"circle",
                top: '15',
                itemGap: 30,
                itemWidth: 15,
                itemHeight: 15,
                textStyle: {
                    fontSize: '14',
                    color: '#333333',
                    padding:[4,0,0,0],
                },

            },
            radar: {
                center: ["50%", "60%"],
                radius: "65%",
                startAngle: 90,
                splitNumber: 4,
                splitArea: {
                    areaStyle: {
                        color: ["transparent"]
                    }
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#CFD8DC"
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#CFD8DC"
                    }
                },
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#333333' ,
                        fontSize: 14,
                    },
                },
                indicator: [
                    { name: dataname[0], axisLabel: {show: true}},
                    { name: dataname[1]},
                    { name: dataname[2]},
                    { name: dataname[3]},
                    { name: dataname[4]}
                ]
            },
            series: [
                {
                name: dataTime[0],
                type: "radar",
                symbol: "circle",
                symbolSize:8,
                areaStyle: {
                    normal: {
                        color: 'rgba(215,87,70, 0.18)',
                    }
                },
                itemStyle: {
                    borderWidth:2,
                    color: '#cd4867',
                    borderColor: '#ffffff',
                },
                lineStyle: {
                    normal: {
                        color: "#cd4867",
                        width: 2
                    }
                },
                data: [datamax]
            },
                {
                name: dataTime[1],
                type: "radar",
                symbol: "circle",
                symbolSize:8,
                areaStyle: {
                    normal: {
                        color: 'rgba(48,157,228, 0.18)',
                    }
                },
                itemStyle: {
                    borderWidth:2,
                    color: '#309DE4',
                    borderColor: '#ffffff',
                },
                lineStyle: {
                    normal: {
                        color: "#309DE4",
                        width: 2
                    }
                },
                data: [datavaule]
            },
                {
                name: dataTime[2],
                type: "radar",
                symbol: "circle",
                symbolSize:8,
                areaStyle: {
                    normal: {
                        color: 'rgba(39,205,162, 0.18)',
                    }
                },
                itemStyle: {
                    borderWidth:2,
                    color: '#27CDA2',
                    borderColor: '#ffffff',
                },
                lineStyle: {
                    normal: {
                        color: "#27CDA2",
                        width: 2
                    }
                },
                data: [datavaule1]
            },
                {
                name: dataTime[3],
                type: "radar",
                symbol: "circle",
                symbolSize:8,
                areaStyle: {
                    normal: {
                        color: 'rgba(255,187,60, 0.18)',
                    }
                },
                itemStyle: {
                    borderWidth:2,
                    color: '#FFBB3C',
                    borderColor: '#ffffff',
                },
                lineStyle: {
                    normal: {
                        color: "#FFBB3C",
                        width: 2
                    }
                },
                data: [datavaule2]
            },
            ]
        };
    }

    render() {
        return (
            <div className={'EchartsMapOne'} style={{width:'100%'}}>
                <ReactEcharts option={this.optionPie()} style={{height:'460px'}}/>
            </div>
        )
    }

}
export default EchartsMapTwo;
