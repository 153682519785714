/*数据大屏展示*/
import React, {Component} from "react";
import {
    Form, Tabs, Input,Button
} from "antd";
import {withRouter} from "react-router";
import {checkPwd} from "../../api/chart/index";
import style from './index.module.scss';
import {Logo, ProjectName} from "../../assets/svg";
const { TabPane } = Tabs;

@withRouter
class DomesticDetection extends Component {
    state = {
        schoolId: this.props.match.params.schoolId,
        accessUrl: window.location.href,

    };

    checkPwd = () =>{
        let b = this.state.accessUrl;
        let accessChartUrl = b.replace("domesticDetection", "dataScreen");
        let data = {
            schoolId: this.state.schoolId,
            pwd: this.props.form.getFieldValue('passWord'),
        };
        console.log("地址：",this.state.accessUrl);
        checkPwd(data).then(res => {
            window.open(accessChartUrl+'?success', '_self')
        })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className={style.domesticDetection}>
                <div className={'left'} style={{backgroundImage: `url("${window.$$setImgSrc('/ss-dataScreen-detection-bg.png')}")`}}>

                </div>
                <div className={'right'}>
                    <div className={'right-center'}>
                        <div className={'center-top'}>
                            {/*<img src={require("../../assets/img/Titleimg3.png")} className={'logo'}/>*/}
                            <span style={{height: "30px", margin: "0 10px 0 0", display: "block",marginTop:'-8px'}}>
                                 <Logo/>
                            </span>
                            <ProjectName/>
                            {/*<div className={'text'}>双高计划管理与智能检测平台</div>*/}
                        </div>
                        <Form>
                            <Form.Item>
                                {
                                    getFieldDecorator('passWord', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '访问密码不能为空！'
                                            },
                                            {
                                                type: 'string',
                                                required: true,
                                                min: 6,
                                                max: 12,
                                                pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$',
                                                message: '密码为6-12位字母+数字组合!',
                                            },
                                        ]
                                    })(<Input
                                        placeholder="请输入访问密码"
                                        autocomplete="off"
                                    />)
                                }
                            </Form.Item>
                        </Form>
                        <Button type={'primary'} onClick={this.checkPwd}>验证</Button>
                    </div>
                </div>
            </div>

        );
    }
}

DomesticDetection = Form.create()(DomesticDetection);
export default DomesticDetection;
