/*设置密码*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Form, Input, Radio} from "antd";


@withRouter
class ReportEditor extends Component {
    state = {
        isShow:this.props.isShow,
        // size:this.props.size,{/*暂时去除大小尺寸功能0*/}
        id:this.props.id,
    };
    componentDidMount() {
        this.props.reportEditor(this);
        console.log("fgasfa2333",this.props.id);
        this.props.form.setFieldsValue({isShow:this.state.isShow});
        // this.props.form.setFieldsValue({size:this.state.size}); {/*暂时去除大小尺寸功能1*/}
        this.props.form.setFieldsValue({id:this.state.id});
    }
    getFormValues = () => {
        let data = {
            isShow: this.props.form.getFieldValue('isShow'),
            size: this.props.form.getFieldValue('size'),
            id: this.props.form.getFieldValue('id')
        };
        this.props.form.validateFields((err, data) => {
            if (err) {
                return data = undefined
            }
        });
        return data;
    };

    render() {
        let {getFieldDecorator}=this.props.form;
        return (
            <div>
                <Form>
                    <Form.Item className="form_li">
                        {getFieldDecorator('id', {
                        })
                        }
                    </Form.Item>
                    <Form.Item className="form_li">
                        <span className="span_firs">显示状态</span>
                        {getFieldDecorator('isShow', {
                            rules: [
                                {
                                    required: false,
                                    // message: `请输入密码`,
                                },
                            ],
                        })(<Radio.Group  className="Radio_select">
                            <Radio value={1}>显示</Radio>
                            <Radio value={0}>隐藏</Radio>
                        </Radio.Group>)
                        }
                    </Form.Item>
                    {/*暂时去除大小尺寸功能2*/}
                    {/*<Form.Item className="form_li">*/}
                    {/*    <span className="span_firs">报表大小</span>*/}
                    {/*    {getFieldDecorator('size', {*/}
                    {/*        rules: [*/}
                    {/*            {*/}
                    {/*                required: false,*/}
                    {/*                // message: `请输入密码`,*/}
                    {/*            },*/}
                    {/*        ],*/}
                    {/*    })(<Radio.Group  className="Radio_select">*/}
                    {/*        <Radio value={1}>小尺寸</Radio>*/}
                    {/*        <Radio value={2}>大尺寸</Radio>*/}
                    {/*    </Radio.Group>)*/}
                    {/*    }*/}
                    {/*</Form.Item>*/}
                </Form>
            </div>
        );
    }
}

ReportEditor = Form.create()(ReportEditor);
export default ReportEditor;
