import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Form, Input, Button, Checkbox, Select, Cascader} from 'antd'
import {getUserInfoById} from '../../../api/user/';
const { TextArea } = Input;

class ModalUser extends Component {
    state={
        organizationList:this.props.orgTreeNodeList,
        count:0,
        userObj:{},
        editUserId:this.props.editUserId,
        lookUserId:this.props.lookUserId,
        isLook:false,
        textAreaDefaultValue: ''

    }
    componentDidMount() {
        this.getUserInfoById()
    }
    getUserInfoById = async ()=>{
        if(this.state.editUserId != ""){
            await getUserInfoById({id:this.state.editUserId}).then(res =>{
                console.log("resttttt",res);
                this.setState({
                    userObj:{
                        userId: res.id,
                        humanName: res.humanName,
                        phone: res.phone,
                        orgId: res.relation,
                        position: res.position,
                        remark: res.remark,
                    },
                    count:res.remark?res.remark.length:0,
                    textAreaDefaultValue: res.remark,
                })
            }).then(()=>{
                this.setFormValue()
            })
        }
        if(this.state.lookUserId != ""){
            this.setState({isLook:true})
            await getUserInfoById({id:this.state.lookUserId}).then(res =>{
                console.log("res",res)
                this.setState({
                    userObj:{
                        userId: res.id,
                        humanName: res.humanName,
                        phone: res.phone,
                        orgId: res.relation,
                        position: res.position,
                        remark: res.remark,
                    },
                    count:res.remark?res.remark.length:0,
                    textAreaDefaultValue: res.remark,
                })
            }).then(()=>{
                this.setFormValue()
            })
        }


    }
    //备注
    marksClick(e){
        console.log('Change:', e.target.value.length);
        this.setState({
            count:e.target.value.length
        })
    }
    //传值父组件form校验

    getFormValue = ()=>{
        let reasult=false
        let valus= this.props.form.getFieldsValue();
        this.props.form.validateFields((err, values) =>{
            if(err){
                return valus=undefined
            }
        })
        return valus;

    }
    //设置表单值
    setFormValue = ()=>{
        console.log("remark",this.state.textAreaDefaultValue)
        this.props.form.setFieldsValue({
            humanName: this.state.userObj.humanName,
            phone: this.state.userObj.phone,
            orgId: this.state.userObj.orgId,
            position: this.state.userObj.position,
            remark: this.state.userObj.remark,
            count:this.state.count
        })
    }
    // Cascader显示最后一级
    displayRender=(label) => {
        return label[label.length - 1];
    }
    render() {
        let {getFieldDecorator}=this.props.form
        let {count, textAreaDefaultValue}=this.state

        //用户名称校验
        const handleName=(rules,value,callback)=>{
            if(value){
                if (value.length<2 || value.length>10){
                    console.log("0000")
                    callback('不能小于2或大于10字符')
                    return
                }
            }else {
                callback()
            }
        }
        const style={position: "absolute",
            top: "-9px",
            right: "10px"
        }
        return (
            <div className="modal-user">
                <Form labelCol={{ span: 3 }} wrapperCol={{ span: 14 }} colon={false}>
                    <Form.Item label="用户名称">
                        {
                            getFieldDecorator('humanName', {
                                rules: [
                                    {
                                        min: 2,
                                        max: 10,
                                        required: true,
                                        message: '不能小于2或大于10字符',
                                    },
                                ]
                            })(<Input autocomplete="off" placeholder="请输入用户名称" disabled={this.state.isLook}/>)
                        }
                    </Form.Item>
                    <Form.Item label="手机号码">
                        {
                            getFieldDecorator('phone', {
                                rules: [
                                    {
                                        required: true,
                                        // pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                                        // pattern:/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9]|16[0-9])[0-9]{8}$/,
                                        pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|16[6]|18[0|1|2|3|4|5|6|7|8|9]|17[3|5|6|7|8]|19[9|8])\d{8}$/,
                                        message: '请输入正确手机号码'
                                    }
                                ]
                            })(<Input autocomplete="off" placeholder="请输入手机号码" disabled={this.state.isLook}/>)
                        }
                    </Form.Item>
                    <Form.Item label="所属组织">
                        {
                            getFieldDecorator('orgId', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入所属组织',
                                    },
                                ]
                            })(
                                <Cascader
                                    fieldNames={{label: 'orgName', value: 'id', children: 'children'}}
                                    options={this.props.orgTreeNodeList}
                                    placeholder="请选择所属组织"
                                    changeOnSelect
                                    displayRender={this.displayRender}
                                    disabled={this.state.isLook}
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                />

                            )}
                    </Form.Item>
                    <Form.Item label="职务">
                        {
                            getFieldDecorator('position',{
                                rules: [
                                    {
                                        min: 2,
                                        max: 10,
                                        // required: true,
                                        message: '不能小于2或大于10字符',
                                    },
                                ]

                            })(


                                <Input autocomplete="off" placeholder="请输入职务"
                                       disabled={this.state.isLook}
                                />)
                        }
                    </Form.Item>
                    <Form.Item label="备注">
                        {
                            getFieldDecorator('remark')(
                                        <TextArea maxLength={50}
                                                  placeholder="请输入备注"
                                                  style={{width: '100%', resize: 'none'}}
                                                  onChange={(e)=>this.marksClick(e)}
                                                  rows={5}
                                                  disabled={this.state.isLook}
                                                  defaultValue={textAreaDefaultValue}
                                        />



                            )
                        }
                        <div style={style} className="user-management-count">{count}/50</div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}



ModalUser = Form.create()(ModalUser);
export default ModalUser;
