/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react'
import axios from "axios";
import { message } from "antd";
import { getToken, setToken, removeToken } from "./token";
import md5 from "js-md5";
import { createHashHistory } from 'history';
import { isEmpty } from './isEmpty';
import messageObj from './err_code';
import Cookies from 'js-cookie';
import { loginUrl, baseUrl as baseURL, domainUrl } from '@/config/secret.js';
const Message = new React.Component()

const Http = axios.create({
    timeout: 300000,
    withCredentials: false,
    // baseURL
})
const history = createHashHistory();
Http.interceptors.request.use(
    (config) => {
        let data = config.data;
        data && data.isBold && (config.responseType = "blob");


        let now = new Date().getTime();
        let info = Cookies.get('ssInfo'),
            token = null;
        if (info) {
            token = JSON.parse(info).token;
        }else {
            history.replace("/login")

        }
        console.log('config.headers',config.headers)
        config.headers = {
            ...config.headers,
            'Authentication': token,
            "z-ts": now,
            "z-sign": md5(now + "")
        }
        console.log("config",config)
        return config
    },
    error => {
        Promise.reject(error).catch(e => { })
    }
);

Http.interceptors.response.use(
    response => {
        const res = response.data
        let token = response.headers["Authentication"]
        if (res.code === 551005 || res.code === 551004 || res.code === 551002 || res.code === 551003) {
            Cookies.remove('info', { path: '/', domain: domainUrl });
            // window.location = `${loginUrl}?url=${window.location.origin}`
        }
        if (!isEmpty(token)) {
            setToken(token)
        }
        if (res.code === 0
            || (response.config && response.config.responseType === "blob")
            // || (response.config)
        ) {
            if (res.result) {
                return res.result
            } else {
                return res
            }
        } else {
            return Promise.reject(res)
        }
    },
    err => {
        if (err.response && err.response.status) {
            if (err.response.status === 401) {
                if (err.response.status === 5503) { } else {
                    // message.warning('哎呀，有意外了，攻城狮正火速来援');
                    Message.mesWarning('哎呀，有意外了，攻城狮正火速来援');
                }

            }
        }
    }
)
export const get = (url, params = {}) => {
    return new Promise(resolve => {
        Http.get(url, { params: params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        Message.mesWarning(messageObj[err.code])
                        return;
                    } else if (err.code === 551005 || err.code === 551004 || err.code === 551002 || err.code === 551003) {
                        Message.mesError(err.message);
                    } else {
                        Message.mesError(err.message)
                    }
                }
            })
    })
}
export const post = (url, params = {}) => {
    return new Promise((resolve, errs) => {
        Http.post(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        Message.mesWarning(messageObj[err.code])
                        return;
                    } else if (err.code === 551005 || err.code === 551004 || err.code === 551002 || err.code === 551003) {
                        Message.mesError(err.message);
                    } else {
                        Message.mesError(err.message)
                    }
                    errs(err)
                }
            })
    })
}
export const del = (url, params = {}) => {
    return new Promise(resolve => {
        Http.delete(url, { params: params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        Message.mesWarning(messageObj[err.code])
                        return;
                    }
                    Message.mesError(err.message)
                }
            })
    })
}
export const put = (url, params = {}) => {
    return new Promise(resolve => {
        Http.put(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        Message.mesWarning(messageObj[err.code])
                        return;
                    }
                    Message.mesError(err.message)
                }
            })
    })
}

export default Http
