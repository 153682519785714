// 任务与绩效进展走势
import React, {Component} from "react";
import {Button, Form, Input, Modal, Select, Steps, Table,} from "antd";
import {withRouter} from "react-router";
import {Rededitor,Readsign} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from 'echarts';
import TaskstatEditor from "../taskstatEditor";
const {TextArea} = Input;
const {Step} = Steps;

@withRouter
class Unnamed extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '任务',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '优先级',
                align: "center",
                width: 100  ,
                dataIndex: 'investAmount',
                key: 'investAmount',

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'expendAmount',
                key: 'expendAmount',

            },

            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'Principal',
                key: 'Principal',

            },


            ],
        pageDataList: [
            {
                id: 1,
                projectName: '提升国际化水平提升国际化水平...',
                investAmount:'学校',
                expendAmount:'进行中',
                Principal:'李锦记',


            },
            {
                id: 2,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 3,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 4,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 5,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 6,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 7,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 8,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 9,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },


        ],



    }

    componentDidMount() {
        this.echarts()
    }

    echarts = ()=>{
        var xData2 = ["1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月"];
        var data1 = [30, 20, 30, 20, 20, 40,30, 20, 30, 20, 20, 40];
        if(document.getElementById('taskPerformance')) {
            let myChart = echarts.init(document.getElementById('taskPerformance'));
            let option = {
                xAxis: {
                    type: 'category',
                    data: ['10月', '11月', '12月', '1月', '2月', '3月', '4月','5月','6月','7月','8月','9月']
                },
                color: '#588BF7',
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130,120, 200, 150, 80, 70],
                        type: 'bar',
                        barWidth: 20,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(238, 238, 238, 0.3)'
                        }
                    }
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }

    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.performanceindicator}>
                <div className={'performanceindicator_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>未命名</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'}>
                            <span>完成</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>
                    </div>
                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <div id={'taskPerformance'} className={'echarts'} style={{width: '100%', height: '400px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>详情表</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 700,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'form_lable'}>
                            <Form>
                                <Form.Item label="分析维度">
                                    {
                                        getFieldDecorator('projectType', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select defaultValue="lucyA" placeholder="请选择"
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >
                                        </Select>)
                                    }
                                </Form.Item>
                                <Form.Item label="绩效指标">
                                {
                                    getFieldDecorator('projectType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: ''
                                            }
                                        ]
                                    })(<Select defaultValue="lucyA" placeholder="请选择"
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                    </Select>)
                                }
                            </Form.Item>
                                <Form.Item label="数据设置">
                                    {
                                        getFieldDecorator('projectType', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select defaultValue="lucyA" placeholder="请选择"
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >
                                        </Select>)
                                    }
                                </Form.Item>
                                <Form.Item label="完成度">
                                    {
                                        getFieldDecorator('projectType', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select defaultValue="lucyA" placeholder="请选择"
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >
                                        </Select>)
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                        <div className={'taskprogress_right_listbox'}>


                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'form_fl'}>
                            <Form>
                                <Form.Item label="状态">
                                    {
                                        getFieldDecorator('projectType', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select defaultValue="lucyA" placeholder="请选择"
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >
                                        </Select>)
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                        <div className={'Filter'}>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span onClick={this.handLook} className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Unnamed = Form.create()(Unnamed);
export default Unnamed;
