// 各建设任务支出占比
import React, {Component} from "react";
import {Form, Table, Tabs,Modal,Button} from "antd";
import {withRouter} from "react-router";
import { Readsign, Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../taskstatEditor";
import ScreeningCondition from "../screeningCondition";
const { TabPane } = Tabs;
@withRouter
class Taskspendingpercent extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '建设任务名称',
                dataIndex: 'constructionTaskName',
                width: 200,
                align: "center",
                key: 'constructionTaskName',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'level',
                key: 'level',

            },
            {
                title: '年份',
                align: "center",
                width: 200,
                dataIndex: 'year',
                key: 'year',

            },
            {
                title: '实际使用金额',
                align: "center",
                width: 200,
                dataIndex: 'ActualAmountUsed',
                key: 'ActualAmountUsed',

            },


        ],
        pageDataList: [
            {
                id: 1,
                constructionTaskName: '提升国际化水平',
                level:'王志伟',
                year:'2021',
                ActualAmountUsed:'4,555',


            },

        ],



    }

    componentDidMount() {
        this.echarts()
    }

    echarts = ()=>{
        if(document.getElementById('taskSpending')){
            let myChart = echarts.init(document.getElementById('taskSpending'));
            // 绘制图表
            let
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: (params) => {
                            console.log('params', params);
                            return <div><span>实际使用金额</span><span>{params.value}</span></div>;
                        },
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 550
                        }
                    },
                    color:['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58'],
                    grid: {
                        top: '15%',
                        left: '10%',
                        bottom: 5,
                    },
                    legend: {
                        type: "scroll",
                        top: '100',
                        left: '50%',
                        orient: 'vertical',
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        itemGap: 20,
                        data: [
                            { name: '提升国际化水平', icon: 'circle' },
                            { name: '提升信息化水平', icon: 'circle' },
                            { name: '提升学校治理水平', icon: 'circle' },
                            { name: '提升服务发展水平', icon: 'circle' },
                            { name: '提升校企合作水平', icon: 'circle' },
                            { name: '打造高水平专业群', icon: 'circle' }
                        ],
                        formatter: (name) => {
                            return name + '{a|' + '|  36%' + '}' + '{b|' + '¥4554' + '}';
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            //在rich中给formatter添加个别字体颜色
                            rich: {
                                a:{
                                    width: 120,
                                    fontSize: 14,
                                    color: '#333333',
                                    align: 'left'
                                },
                                b: {
                                    color: '#888888',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '##40a9ff',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            center: ['25%','50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        console.log('data',data.value)
                                        let html = `{b| 实际使用额度} \n {c|¥${data.value}}`;
                                        return html;
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                { value: 1048, name: '提升国际化水平' },
                                { value: 735, name: '提升信息化水平' },
                                { value: 580, name: '提升学校治理水平' },
                                { value: 484, name: '提升服务发展水平' },
                                { value: 300, name: '提升校企合作水平' },
                                { value: 300, name: '打造高水平专业群' }
                            ]
                        }
                    ]
                };
            myChart.setOption(option)
            myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
            myChart.on('mouseover', (v) => { //清空第一次选中
                if (v.dataIndex != 0) {
                    myChart.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: 0
                    });
                }
            });
            myChart.on('mouseout', (v) => { //鼠标离开圆环默认选第一次
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
            });
        }
    }

    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }



    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.taskspendingpercent}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>各建设任务支出占比</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'}>
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <Tabs className='all' defaultActiveKey="" onChange={this.onChangeTab}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane  tab={'其他'} key="3">
                                </TabPane>
                            </Tabs>
                            <div id={'taskSpending'} className={'echarts'} style={{width: '100%', height: '400px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>各建设任务支出占比</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 700,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>建设任务</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>实际使用金额</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition/>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span  onClick={this.handLook} className={'span_add'} disabled={true}>添加筛选条件</span>
                            </p>
                        </div>

                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>

                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

Taskspendingpercent = Form.create()(Taskspendingpercent);
export default Taskspendingpercent;
