/*筛选条件*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import style from './index.module.scss'
import {queryConfitionByCTAndDT,queryConfitionValues} from "../../../../../api/chart/index"
import {Button, Form, Input, Checkbox, TreeSelect} from "antd";


@withRouter
class screeningCondition extends Component {
    state = {
        name: '',
        treeData: [
            {
                id: 0,
                value: '高',
                title: '高'
            },
            {
                id: 1,
                value: '中',
                title: '中'
            },
            {
                id: 2,
                value: '低',
                title: '低'
            },
        ],
        dataSave: [],
        addConditionList: [],
        allConfitionList:[],
        allConfitionValues:[],


    }
    componentDidMount() {
        console.log('oneDimensionId',this.props.oneDimensionId)
        this.props.editCondition(this)
        if(this.props.useChildFlag){
            this.queryConfitionByCTAndDT()
            this.queryConfitionValues()
        }

    }
    // 复选框改变
    onChange=(value,id)=>{
        let addConditionList = this.state.addConditionList
        for (let i = 0; i < addConditionList.length; i++) {
            if(addConditionList[i].id == id){
                addConditionList[i] = {id:id,values:value}
            }
        }
        this.setState({addConditionList:addConditionList})
    }
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.addConditionList.length>0 && nextProps.addConditionList != this.state.dataSave){
    //         let dataSave = []
    //         for (let i = 0; i < nextProps.addConditionList.length; i++) {
    //             dataSave.push({conditionId:nextProps.addConditionList[i].id,conditionValues:nextProps.addConditionList[i].values})
    //         }
    //         this.setState({
    //             dataSave: dataSave,
    //
    //         },()=>{
    //         })
    //     }
    //     console.log('this.props.allConfitionValues',this.props.allConfitionValues)
    // }
    // 获取修改后的筛选条件

    getConditionValueChange = ()=>{
        let addConditionList = this.state.addConditionList
        let resultData = []
        for (let i = 0; i < addConditionList.length; i++) {
            resultData.push({conditionId:addConditionList[i].id,conditionValues:addConditionList[i].values.join(',')})
        }
        return resultData
    }

    queryConfitionByCTAndDT = async () => {
        let data = {
            chartId: this.props.chartId,
            chartType:this.props.chartType,
            oneDimensionId:this.props.oneDimensionId,
        }
        await queryConfitionByCTAndDT(data).then(res => {
            if(res){
                console.log('res.addConditionVoList',res.addConditionVoList)
                this.setState({addConditionList:res.addConditionVoList},()=>{
                })
            }

        })

    }

    //获取筛选条件的值，通过图表ID或（图表类型和维度类型）
    queryConfitionValues = async () => {
        let data = {
            chartType:this.props.chartType,
            oneDimensionId:this.props.oneDimensionId,
            cengMian:this.props.cengMian
        }
        await queryConfitionValues(data).then(res => {
            if(res){
                for (let i = 0; i < res.length; i++) {
                    res[i]['treeData'] = []
                    let typeValues = res[i].typeValues
                    for (let j = 0; j < typeValues.length; j++) {
                        res[i].treeData.push({
                            id:typeValues[j].type,
                            value: typeValues[j].type,
                            title: typeValues[j].label
                        })
                    }
                }
                this.setState({allConfitionValues:res},()=>{

                })
            }
        }).then(res=>{
            // console.log('allConfitionValues',this.state.allConfitionValues)
        })
    }

    render() {
        return (
            <div className={style.screeningCondition}>
                <ul className={'tianjianul'}>
                    {
                        this.state.addConditionList && this.state.addConditionList.map((item,index)=>(
                            this.state.allConfitionValues && this.state.allConfitionValues.map((item2,index2)=>(
                                item.id == item2.conditionId ?
                                    <li>
                                        <span className={'name'}>{item2.conditionName}</span>
                                        <TreeSelect
                                            treeDataSimpleMode
                                            dropdownClassName="TreeSelect_warp"
                                            className={'addglwdtree'}
                                            defaultValue={item.values}
                                            treeData={item2.treeData}
                                            onChange={(value)=>this.onChange(value,item2.conditionId)}
                                            treeCheckable={true}
                                            searchPlaceholder={'请添加筛选条件'}
                                            style= {{width:'100%'}}
                                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        />
                                    </li>:''
                            ))

                        ))
                    }


                </ul>
            </div>
        );
    }
}

screeningCondition = Form.create()(screeningCondition);
export default screeningCondition;
