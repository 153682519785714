/*eslint eqeqeq: ["off", "smart"]*/
// 第一页基本概况
import React, {Component} from 'react';
import {Form, Row, Col, Button } from 'antd';
import {Link} from "react-router-dom";
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';
import {coverBasicInfo} from '@/api/synchrodatment/index';

class Situationcontent extends Component {
    state = {
        taskBookVo:{},
        schoolVo:{},
        legalPersonVo:{},
        projectPersonVo:{},
        schoolNatureDic: JSON.parse(localStorage.getItem("allCommonDic")).schoolNatureDic,
        taskId:'',
        schoolId: "",
        category: 0, //（1.高水平学校2.高水平专业群）
        remarkShow:false,
        reviewStatus:localStorage.getItem("reviewStatus")?JSON.parse(localStorage.getItem("reviewStatus")):'',
        taskInfo: localStorage.getItem("taskInfo")?JSON.parse(localStorage.getItem("taskInfo")):'',
    };

    componentDidMount() {
        const taskIdAndSchoolIdAndCategory = this.props.location.search;  //地址栏截取
        const param = taskIdAndSchoolIdAndCategory.split("?")[1];
        if (param) {
            const codeParam = param.includes("%") ? decodeURI(param) : param;
            const jsonparam = JSON.parse(codeParam);
            this.setState({
                taskId: jsonparam.taskId,
                schoolId: jsonparam.schoolId,
                category: jsonparam.category,
            },() => {
                this.coverBasicInfo();
            })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    // // 预览第二页
    secondPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId, category:category};
        this.props.history.push({
            pathname: '/schoolschedule',
            search: JSON.stringify(param)
        })

    }

    coverBasicInfo = async () => {
        let {taskId, schoolId} = this.state;
        let previewCoverBasicInfoVo = await coverBasicInfo({taskId: taskId, schoolId: schoolId});
        this.setState({
            taskBookVo:previewCoverBasicInfoVo.taskBookVo,
            schoolVo:previewCoverBasicInfoVo.schoolVo,
            legalPersonVo:previewCoverBasicInfoVo.legalPersonVo,
            projectPersonVo:previewCoverBasicInfoVo.projectPersonVo
        })
    }
    toggleForm=(item,type)=>{
        const  {remarkShow}=this.state
        this.setState({
            remarkShow:!remarkShow,
        },()=>{
            let ele=document.getElementById(`intro`)
            if(!this.state.remarkShow){
                ele.style.height=48+'px'
            }else{
                ele.style.height=""
            }
        })
    }
    //返回上一级页面
    backtoHis(){
        this.props.history.push({pathname: '/main/doublehigh/synchrodatment'})
    }
    render() {
        const {taskBookVo, schoolVo, legalPersonVo, projectPersonVo,taskId,schoolId,category, schoolNatureDic,remarkShow,reviewStatus,taskInfo} = this.state;
        let schoolNatureName = null;
        if (schoolNatureDic !== null && schoolVo != null && schoolVo.schoolNature != null) {
            schoolNatureDic.filter(e => e.dicKey === schoolVo.schoolNature)
            schoolNatureName = schoolNatureDic[0].dicValue
        }
        return (
            <div className="Comment-SituationContent main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                <div className='buttonBack'><Button className='buttonBack0' onClick={()=>this.backtoHis()}>返回</Button></div>
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">

                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>

                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} category={category}/>}
                                </Col>
                                <Col span={16} className="right">
                                    {reviewStatus.isReporting === 1 && reviewStatus.auditStatus === 2?
                                        <div className="prompt-bottom">
                                            <div className="text">
                                                {/*<i><img src={require('../../assets/images/icon-23.png')}/></i>*/}
                                                <div className="cont-text" id={`intro`} style={{"height":"48px"}}>
                                                    <b>未通过原因:</b>
                                                    {reviewStatus.auditReason}
                                                </div>
                                                {remarkShow? <Link className='lilotpage-link' onClick={this.toggleForm}>收起</Link>:<Link className='lilotpage-link' onClick={this.toggleForm}>展开</Link>}
                                            </div>
                                        </div>:""
                                    }
                                    <div className="content">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>1</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <div className="step-top-common"><h3>高水平高职学校建设计划任务书填写表</h3></div>
                                        <div className="title-cent">
                                            <span className="icon-img">
                                            </span>
                                            <ul>
                                                <li>
                                                    <h3>申报学校：</h3>
                                                    <span>{taskBookVo.projectUnit}</span>
                                                </li>
                                                <li>
                                                    <h3>举办单位：</h3>
                                                    <span>{taskBookVo.organizer}</span>
                                                </li>
                                                <li>
                                                    <h3>所在省份：</h3>
                                                    <span>{taskBookVo.province}</span>
                                                </li>
                                                <li>
                                                    <h3>填表日期：</h3>
                                                    <span>{taskBookVo.fillingDate}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="bottom">
                                            <h2>1-1 学校基本概况</h2>
                                            <div className="list">
                                                <h3>1 项目学校概况</h3>
                                                <ul>
                                                    <li>
                                                        <h4>申报学校：<span>{schoolVo.schoolName}</span></h4>
                                                        <h4>所在地区：<span>{schoolVo.province}</span></h4>

                                                    </li>
                                                    <li>
                                                        <h4>建校时间：<span>{schoolVo.createSchoolTime}</span></h4>
                                                        <h4>院校性质：<span>{schoolNatureName}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>举办单位类型：<span>{schoolVo.organizerType}</span></h4>
                                                        <h4>邮编：<span>{schoolVo.postCode}</span></h4>
                                                    </li>
                                                    <li>
                                                        {/* todo 转换为汉字 */}
                                                        <h4>学校网址：<span>{schoolVo.schoolWebsite}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>通讯地址：<span>{schoolVo.address}</span></h4>
                                                    </li>
                                                </ul>
                                                <h5>法人代表信息</h5>
                                                <ul>
                                                    <li>
                                                        <h4>姓名：<span>{legalPersonVo.userName}</span></h4>
                                                        <h4>职务：<span>{legalPersonVo.userJob}</span></h4>
                                                        <h4>办公电话：<span>{legalPersonVo.workPhone}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>传真：<span>{legalPersonVo.userFax}</span></h4>
                                                        <h4>手机：<span>{legalPersonVo.phone}</span></h4>
                                                        <h4>电子邮箱：<span>{legalPersonVo.email}</span></h4>
                                                    </li>
                                                </ul>
                                                <h5>项目联系人</h5>
                                                <ul>
                                                    <li>
                                                        <h4>姓名：<span>{projectPersonVo.userName}</span></h4>
                                                        <h4>职务：<span>{projectPersonVo.userJob}</span></h4>
                                                        <h4>办公电话：<span>{projectPersonVo.workPhone}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>传真：<span>{projectPersonVo.userFax}</span></h4>
                                                        <h4>手机：<span>{projectPersonVo.phone}</span></h4>
                                                        <h4>电子邮箱：<span>{projectPersonVo.email}</span></h4>
                                                    </li>
                                                </ul>
                                                <h3>2 学校建设总目标</h3>
                                                <div className="cont">
                                                    {schoolVo.constructionObjectives?schoolVo.constructionObjectives:(
                                                        <div className="Empty-content">
                                                            <div className="text">
                                                                <span>暂无数据</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" onClick={()=> this.secondPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}


Situationcontent = Form.create()(Situationcontent);
export default Situationcontent;
