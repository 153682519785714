import { post } from '../../until/axios_instans';

//角色权限管理

//添加用户列表到角色中
export function addUserToRole(data){
    return post('/api/v1/sysuserrole/addUserToRole',data);
}
//移除角色下用户
export function deleteById(data){
    return post('/api/v1/sysuserrole/deleteById',data);
}
//查询用户列表by组织机构、用户名
export function getUserListByNameOrg(data){
    return post('/api/v1/sysuserrole/getUserListByNameOrg',data);
}
//角色下用户分页列表
export function roleUserpageList(data){
    return post('/api/v1/sysuserrole/roleUserpageList',data);
}

//获取权限按钮
export function getPermissionButton(data){
    return post('/api/ss/api/v1/role/getPermissionButton',data);
}

//获取角色信息
export function getRoleById(data){
    return post('/api/ss/api/v1/role/getRoleById',data);
}

//保存权限按钮
export function savePermissionButton(data){
    return post('/api/ss/api/v1/role/savePermissionButton',data);
}




