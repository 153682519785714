import React, {Component} from 'react'
import { Upload} from 'antd';
import { uploadFile } from '../../api/file';
import './index.scss';

export default class Upload_Img extends Component {
    state = {
        fileInfoList: [],
        fileId:'',
        isPreview:false,//是否预览
    }
    componentWillReceiveProps(props) {
        if (this.state.fileId !== props.value) {
            this.setState({ fileId: props.value });
        }
    }
    //删除图片
    delItemImg = e => {
        e.stopPropagation();
        this.setState({ fileId: '' });
        this.props.handleImgChange('','',this.props.formName)
    }
    //预览
    handleprew=(e)=>{
        e.stopPropagation();
        this.setState({
            isPreview:true
        })
    }
    //管理预览
    handleClose=()=>{
        this.setState({
            isPreview:false
        })
    }
    //上传图片
    beforeUpload =async (file) => {
        this.setState({ loading: true });
        const { handleImgChange,formName } = this.props;
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', this.props.systemType)
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            this.setState({
                fileId: res.fpName
            },()=>{
                handleImgChange && handleImgChange(res.fpName,  res.mediaType, formName,res.id);
            });

        }}

    render() {
        const {$$img, $$uploadRc} = window;
        const {fileInfoList,fileId,isPreview} = this.state;
        const {html} = this.props
        const imgType='.jpg,.png,.JPG,.PNG'
        const uploadButton=<div>
            {/*<img src={$$img(prefix + 'add_icon.png')} alt=""/>*/}
            <p>{html}</p>
        </div>
        const imgFileList =
            <div className='img-show'>
                <img  src={$$uploadRc(fileId)} alt="" />
                <div className="mask_bg">
                   <span className='mask_delete'>
                    <svg
                        onClick={this.delItemImg}
                        width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.84815 1.17969C7.97783 1.17969 6.45985 2.69268 6.39525 4.59217H2.03511C1.55067 4.59217 1.1543 4.99364 1.1543 5.49098C1.1543 5.98532 1.54774 6.3898 2.03513 6.3898H3.00698V16.4445C3.00698 18.332 4.2607 19.875 5.81099 19.875H13.7091C15.2565 19.875 16.5131 18.341 16.5131 16.4445V6.3958H17.394C17.8784 6.3958 18.2748 5.99431 18.2748 5.49697C18.2748 5.00262 17.8814 4.59816 17.394 4.59816H13.2951C13.2394 2.6927 11.7184 1.17971 9.84815 1.17971V1.17969ZM8.04537 4.59217C8.10996 3.61847 8.88804 2.86348 9.85107 2.86348C10.8141 2.86348 11.5922 3.62146 11.648 4.59217H8.04537ZM5.80805 18.1942C5.25899 18.1942 4.65414 17.4751 4.65414 16.4445V6.3958H14.8601V16.4535C14.8601 17.4811 14.2552 18.2032 13.7062 18.2032H5.80805V18.1942Z"
                            fill="white"/>
                        <path
                            d="M6.99234 16.034C7.39456 16.034 7.72636 15.6235 7.72636 15.1082V9.82618C7.72636 9.31086 7.39751 8.90039 6.99234 8.90039C6.59008 8.90039 6.2583 9.31086 6.2583 9.82618V15.1082C6.2583 15.6205 6.58127 16.034 6.99234 16.034ZM9.64952 16.034C10.0518 16.034 10.3835 15.6235 10.3835 15.1082V9.82618C10.3835 9.31086 10.0547 8.90039 9.64952 8.90039C9.24728 8.90039 8.91549 9.31086 8.91549 9.82618V15.1082C8.91549 15.6205 9.24728 16.034 9.64952 16.034ZM12.4447 16.034C12.8469 16.034 13.1787 15.6235 13.1787 15.1082V9.82618C13.1787 9.31086 12.8499 8.90039 12.4447 8.90039C12.0425 8.90039 11.7107 9.31086 11.7107 9.82618V15.1082C11.7107 15.6205 12.0307 16.034 12.4447 16.034Z"
                            fill="white"/>
                    </svg>
                     <svg onClick={this.handleprew} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8377 14.0856L12.4278 11.7618C13.6675 10.5112 14.4341 8.82172 14.4341 6.95931C14.4341 3.12205 11.1964 0 7.21707 0C3.23769 0 0 3.12205 0 6.95931C0 10.7966 3.23769 13.9186 7.21707 13.9186C8.86533 13.9186 10.382 13.3769 11.5984 12.4764L14.0522 14.8426C14.1036 14.8925 14.1648 14.9321 14.2321 14.9591C14.2995 14.9861 14.3717 15 14.4447 15C14.5176 15 14.5899 14.9861 14.6572 14.9591C14.7246 14.9321 14.7857 14.8925 14.8372 14.8426C14.9413 14.7423 14.9999 14.6062 15 14.4642C15.0001 14.3223 14.9417 14.1861 14.8377 14.0856ZM1.11032 6.95931C1.11032 3.71252 3.85003 1.07066 7.21707 1.07066C10.5841 1.07066 13.3238 3.71252 13.3238 6.95931C13.3238 10.2061 10.5841 12.8479 7.21707 12.8479C3.85003 12.8479 1.11032 10.2061 1.11032 6.95931Z" fill="white" />
                        <path d="M9.74674 6.42388H7.77259V4.55022C7.77259 4.40825 7.71411 4.27208 7.60999 4.17169C7.50588 4.07129 7.36467 4.01489 7.21744 4.01489C7.0702 4.01489 6.92899 4.07129 6.82488 4.17169C6.72077 4.27208 6.66228 4.40825 6.66228 4.55022V6.42388H4.71922C4.57198 6.42388 4.43078 6.48028 4.32666 6.58068C4.22255 6.68107 4.16406 6.81724 4.16406 6.95921C4.16406 7.10119 4.22255 7.23736 4.32666 7.33775C4.43078 7.43814 4.57198 7.49455 4.71922 7.49455H6.66228V9.36821C6.66228 9.51018 6.72077 9.64635 6.82488 9.74674C6.92899 9.84714 7.0702 9.90354 7.21744 9.90354C7.36467 9.90354 7.50588 9.84714 7.60999 9.74674C7.71411 9.64635 7.77259 9.51018 7.77259 9.36821V7.49455H9.74674C9.89398 7.49455 10.0352 7.43814 10.1393 7.33775C10.2434 7.23736 10.3019 7.10119 10.3019 6.95921C10.3019 6.81724 10.2434 6.68107 10.1393 6.58068C10.0352 6.48028 9.89398 6.42388 9.74674 6.42388Z" fill="white" />
                     </svg>
                </span>
                </div>
            </div>;
        return (
            <>
                <div className='upload-img'>
                    { <div className='img-box'>
                        <Upload
                            fileList={[]}
                            multiple={true}
                            beforeUpload={ this.beforeUpload}
                            listType="picture-card"
                            showUploadList={false}
                            accept={imgType}>
                            {fileId?imgFileList:uploadButton}
                        </Upload>

                    </div>}
                </div>
                {isPreview&& <div className="img_fixed">
                    <div className="img-prew">
                        <span onClick={this.handleClose} className="close">关闭</span>
                        <img src={$$uploadRc(fileId)} alt="" />
                    </div>
                </div> }
            </>
        )
    }
}
