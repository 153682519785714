import React, { useState, useEffect } from 'react';
import { Menu,message } from 'antd';
import {mesWarning} from '@/components/global_components/confirm/index.js'
import './index.scss';
const Message = new React.Component()
// import imgUrl from "@/assets/img/gongzuotai.png"
// import Image from '@/components/Image';
const { SubMenu } = Menu;
export default props => {
    let { menuList } = props;
    console.log("111menuList",menuList)
    const curPath = props.location.pathname;
    const [openKeys, setOpenKey] = useState(menuList&&menuList.map(v => v.id));
    const [selectedKeys, setSelKey] = useState([]);
    //查找和路由匹配的项
    let find = (arr) => {
        arr&&arr.find(v => {
            const activeArr = v.activeArr || [];
            if (v.menuUrl === curPath || activeArr.includes(curPath)) {
                setSelKey([v.id]);
            } else if (v.children) {
                find(v.children);
            }
        })
    }
    useEffect(() => {
        setOpenKey(menuList&&menuList.map(v => v.id));
        find(menuList);
    }, [menuList]);
    useEffect(() => {
        find(menuList);
    }, [curPath])
    //路由跳转
    let jump = (path) => {

        if(!path){
          //  message.warning('该功能正在建设中，敬请期待...');
          //   mesWarning('该功能正在建设中，敬请期待...');
            Message.mesWarning('菜单权限不足，请联系管理员...');
            return;
        }
        if (path === curPath) {
            return;
        }
        props.history.push(path);
    }
    //展开收起
    let change = (openKeys) => {
        setOpenKey(openKeys);
    }
    console.log("menuList",menuList)
    // let imgUrl=imgUrl
    // menuList[0].push(imgUrl)
    return <Menu
        className='my_menu'
        onOpenChange={change}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode="inline">
        {
            menuList && menuList.map(((v, index) => {

                if (v.children&&v.children.length>0) {
                    return <SubMenu key={v.id} title={<>
                    {v.icon?
                        <img className='menuImg' src={require(`@/assets/img/${v.icon}.png`)} alt=""/>:""}
                        <span>{v.menuName}</span>
                    </>

                    }>
                        {
                            v.children.map(k => {
                                if (!k.isHade) {
                                    return ( <Menu.Item onClick={() => jump(k.menuUrl)} key={k.id}>
                                        {k.menuName}
                                    </Menu.Item>)
                                }
                            })
                        }
                    </SubMenu>
                } else {
                    return <Menu.Item onClick={() => jump(v.menuUrl)} key={v.id}>
                        {v.icon ?
                        <img className='menuImg' src={require(`@/assets/img/${v.icon}.png`)} alt=""/> : ''}
                        <span>{v.menuName}</span>
                    </Menu.Item>
                }
            }))
        }
    </Menu>
}
