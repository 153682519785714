/*院校双高计划建设情况*/
import React, {Component} from "react";
import {Drawer, Form, Progress, Spin, Tabs} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import SituationFunds from "../situationFunds";
import GeneralFunds from './map1'
import GeneralTwo from "./map2";
import Cookies from "js-cookie";
import {getOverallFundSituation, selectSchoolMoneyOverview} from "../../../../api/doublehigh/index"
import {projectListOverview} from '../../../../api/project/';
import {queryCatgoryById} from "../../../../api/synchrodatment";
import {queryTaskChart, queryTaskMeritTrend, surveyMeritQueryYear, surveyProjectQueryYear} from "../../../../api/chart"
import TaskPerformance from "../../../dataStatisticsShow/components/taskPerformance";
import MeetPerformanceIndicators from "../../../dataStatisticsShow/components/meetPerformanceIndicators";
import PerformanceIndicator from "../../../dataStatisticsShow/components/performanceIndicator";

const { TabPane } = Tabs;
const that = new Component();

@withRouter
class CollegesUniversities extends Component {
    state = {
        onfunds:false,  //查看资金总体情况
        loading: false,  //加载中
        activeKey:"0",  //进度条切换
        projectStatYear: "",
        meritStatYear: "",
        isactiveKey:'0',
        searchIndex:0,  //默认第一个高亮
        exportIndex:0,
        dateType:3,//1七日内 2一月内 3全年
        taskPerformanceKey:0,
        trendType:1,//1任务2绩效
        personOfCompletedVos:[],//任务绩效负责人完成数量
        queryTaskChartList:[],
        meritIndicatorsQueryList:[],
        defaultType:1,
        chartTaskId:6,
        chartTaskType:6,
        chartMeritCompletionId:9,
        chartMeritCompletionType:9,
        customChartDataList:[],
        type:1,
        queryCatgory:0,
        chartProjectId:1,
        chartProjectType:1,
        chartMeritId:2,
        chartMeritType:2,
        surveyProjectQuery: [],
        surveyMeritQuery: [],
        cengMianMeritCompleteDu:1,//1学校，2群一，3群二，4其他
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        statisticalList:[      //任务统计数
            {
                id:'1',
                name:'任务总数',
                icon:1,
                num:1000,
                percent:'90'
            },
            {
                id:'2',
                name:'已完成',
                icon:2,
                num:200,
                percent:'80'
            },
            {
                id:'3',
                name:'已逾期',
                icon:3,
                num:0,
                percent:'0'
            },
            {
                id:'4',
                name:'未完成',
                icon:4,
                num:800,
                percent:'70'
            },
            {
                id:'5',
                name:'待认领',
                icon:5,
                num:789,
                percent:'90'
            },
            {
                id:'6',
                name:'时间待定',
                icon:6,
                num:789,
                percent:'90'
            },
        ],
        schoolIndicatorOneLevel:'',
        zhuData:[
            {serialNumber: 1, id: "488", name: "机械工程", resourceType: 1, resourceTypeName: "课程", number: 15,},
            {serialNumber: 2, id: "479", name: "mp4", resourceType: 0, resourceTypeName: "素材", number: 13,},
            {serialNumber: 3, id: "405", name: "30d47d7b153a47f18e14bbaeb0991a81", resourceType: 0,number:10},
            {serialNumber: 4, id: "422", name: "LoadRunner性能分析图解释", resourceType: 0, resourceTypeName: "素材",number:8},
            {serialNumber: 5, id: "423", name: "java语言特点", resourceType: 0, resourceTypeName: "素材", number: 1,},
        ],
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
        overallFundSituationInfo: [],
        schoolMoneyOverview: [],
    }

    componentDidMount() {
        this.queryCatgoryById()
        this.getOverallFundSituation()
        this.selectSchoolMoneyOverview()
        this.queryTaskMeritTrend()
        this.projectListOverview()
        this.surveyMeritQuery();
        this.surveyProjectQuery();
    }
    //建设任务数据总览
    async projectListOverview() {
        let params = {
            schoolId: this.state.schoolId,
        };
        const page = await projectListOverview(params);
        if (page) {
            this.setState({
                statisticalList: page,
            });
        }
    }

    getOverallFundSituation =()=>{
        this.setState({
            loading: true,
        },()=>{
            let data = {
                "investYear": 2021,
                "schoolEduCode": this.state.schoolEduCode
            }
            getOverallFundSituation(data).then(res => {
                console.log('resresres =====>>>>>> 第一层里面的 888', res)
                if(res){
                    this.setState({
                        overallFundSituationInfo: res,
                        loading:false
                    })
                }
            })
        })
    }
    selectSchoolMoneyOverview =()=>{
        let data = {
            "investYear": null,
            "schoolEduCode": this.state.schoolEduCode
        }
        selectSchoolMoneyOverview(data).then(res => {
            console.log('resresres =====>>>>>> 大的统计图 888', res)
            if(res){
                this.setState({
                    schoolMoneyOverview: res,
                })
            }
        })
    }

    onClose = () =>{
        this.setState({
            onfunds:false
        })
    }
    onLook = () =>{
        this.setState({
            onfunds:true
        })
    }
    callback = async (key) => {
        console.log("绩效哪个年度",JSON.stringify(key))
        if (key == 0){
            this.setState({
                projectStatYear: null
            },()=>this.surveyProjectQuery())
        }else if (key == 1){
            this.setState({
                projectStatYear: 2020
            },()=>this.surveyProjectQuery())
        }else if (key == 2){
            this.setState({
                projectStatYear: 2021
            },()=>this.surveyProjectQuery())
        }else if (key == 3){
            this.setState({
                projectStatYear: 2022
            },()=>this.surveyProjectQuery())
        }else if (key == 4){
            this.setState({
                projectStatYear: 2023
            },()=>this.surveyProjectQuery())
        }
    };
    callbackMerit = async (key) => {
        console.log("绩效哪个年度",JSON.stringify(key))
        if (key == 0){
            this.setState({
                meritStatYear: null
            },()=>this.surveyMeritQuery())
        }else if (key == 1){
            this.setState({
                meritStatYear: 2020
            },()=>this.surveyMeritQuery())
        }else if (key == 2){
            this.setState({
                meritStatYear: 2021
            },()=>this.surveyMeritQuery())
        }else if (key == 3){
            this.setState({
                meritStatYear: 2022
            },()=>this.surveyMeritQuery())
        }else if (key == 4){
            this.setState({
                meritStatYear: 2023
            },()=>this.surveyMeritQuery())
        }
    };
    setSearchIndex = (inx) =>{
        this.setState({
            searchIndex: inx,
        })
    }
    setExportIndex = (inx) =>{
        this.setState({
            exportIndex: inx,
        })
    }
    viewDetails = () =>{
        this.props.history.push('/main/doublehigh/completionIndicators')
    }
    //任务与绩效走势
    queryTaskMeritTrend = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType,
            schoolId:this.state.schoolId,
        }
        await queryTaskMeritTrend(data).then(res => {
            if(res){
                this.setState({personOfCompletedVos:res.personOfCompletedVos})
            }
        })
    }
    //1七日内/30日/全年切换
    dateConversion = (dateType)=>{
        this.setState({dateType,taskPerformanceKey:this.state.taskPerformanceKey+1},()=>{
            this.queryTaskMeritTrend()
        })
    }
    onChangeTabofTrend = (item)=>{
        this.setState({trendType:item,taskPerformanceKey:this.state.taskPerformanceKey+1},()=>{
            this.queryTaskMeritTrend()
        })
    }
    //任务图
    queryTaskChart = async () => {
        let data ={
            code:0,
            chartId:this.state.chartTaskId,
            chartType:this.state.chartTaskType,
            defaultType:this.state.defaultType,
            schoolId:this.state.schoolId
        }
        await queryTaskChart(data).then(e => {
            this.setState({
                queryTaskChartList:e,
            })
        })
    }
    //绩效达标
    onChangeTabMeetPerformanceIndicators = (item)=>{
        this.setState({type:item})
    }
    //绩效完成度
    onChangeTabOfMeritCompleteDu = (item)=>{
        this.setState({cengMianMeritCompleteDu:item})
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    viewDetailsa = () =>{
        this.props.history.push('/main/doublehigh/editorUnnameds')
    }
    viewDetailsb = () =>{
        this.props.history.push('/main/doublehigh/edittaskachievementofindicators')
    }
    viewDetailsc = () =>{
        this.props.history.push('/main/doublehigh/editorPerformanceindicator')
    }
    //概况图表查建设任务进度
    surveyProjectQuery = async () => {
        let data={
            statYear: this.state.projectStatYear,
            schoolId:this.state.schoolId
        }
        await surveyProjectQueryYear(data).then(e => {
            this.setState({
                surveyProjectQuery:e.surveyVo,
            })
        })
    }
    //概况图表绩效进度
    surveyMeritQuery = async () => {
        let data={
            statYear: this.state.meritStatYear,
            schoolId: this.state.schoolId
        };
        await surveyMeritQueryYear(data).then(e => {
            this.setState({
                surveyMeritQuery:e.surveyVo
            })
        })
    }
    render() {
        const {schoolMoneyOverview,overallFundSituationInfo,onfunds,loading,dateType,queryCatgory,meritIndicatorsQueryList,
            queryTaskChartList,surveyProjectQuery,surveyMeritQuery,schoolIndicatorOneLevel,statisticalList,searchIndex,exportIndex,zhuData } = this.state
        console.log('父组件 大大大 888', schoolMoneyOverview)
        const {getFieldDecorator} = this.props.form;
        const searchNum = ['任务', '绩效'];
        const exportList = ['7日', '30日', '全年'];
        return (
            <div className='CollegesUniversities'>
                <h3>“双高计划”项目建设情况概览</h3>
                <div className={'Universities_top'}>
                    <h4>整体概览</h4>
                    <div className={'ul_top'}>
                        <div className={'li'}>
                            <Tabs defaultActiveKey="0" onChange={this.callback.bind(this)}>
                                <Tabs.TabPane tab="累计" key="0">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2020" key="1">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2021" key="2">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2022" key="3">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2023" key="4">
                                </Tabs.TabPane>
                            </Tabs>
                            <div>
                                <div className={'li_Progress'}>
                                    <span className={'text'}>建设任务进度</span>
                                    <Progress percent={surveyProjectQuery.projectProgress} />
                                </div>
                                <div className={'li_text'}>
                                    <span className={'text'}>近30日增长率</span>
                                    <div className={'text_bottom'}>
                                        <i></i>
                                        <span>{surveyProjectQuery.growthRate}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'li'}>
                            <Tabs defaultActiveKey="0" onChange={this.callbackMerit.bind(this)}>
                                <Tabs.TabPane tab="累计" key="0">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2020" key="1">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2021" key="2">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2022" key="3">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="2023" key="4">
                                </Tabs.TabPane>
                            </Tabs>
                            <div>
                                <div className={'li_Progress'}>
                                    <span className={'text'}>绩效完成进度</span>
                                    <Progress percent={surveyMeritQuery.meritProgress} />
                                    {/*<div className={'percentage'}>{surveyMeritQuery.meritProgress}%</div>*/}
                                </div>
                                <div className={'li_text'}>
                                    <span className={'text'}>平均完成度</span>
                                    <div className={'text_bottom'}>
                                        <i></i>
                                        <span>{surveyMeritQuery.meritAverageProgress}%</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <h4>资金总体情况</h4>
                    {
                        loading?<Spin className="Spin-content—left" tip="加载中"/>:<div className={'center_weo'}>
                            <div className="General_le">
                                <GeneralFunds overallFundSituationInfo = {overallFundSituationInfo}/>
                            </div>
                            <div className={'General_rig'}>
                                <span onClick={this.onLook}>查看详情</span>
                                <div className={'right_eacrt'}>
                                    <GeneralTwo overallFundSituationInfo = {overallFundSituationInfo}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={'Universities_center'}>
                    <h3>整体概览</h3>
                    <div className={'center_statistical'}>
                        <h4>任务统计</h4>
                        <div className={'ul'}>
                            {
                                statisticalList.map((item,key)=>{
                                    return(
                                        <div className={'li'} key={key}>
                                            <div className={'li_top'}>
                                                <i>{
                                                    item.icon === 1?<img src={require('../../../../assets/imgschool/icon_s.png')}/>:
                                                        item.icon === 2?<img src={require('../../../../assets/imgschool/icon_p.png')}/>:
                                                            item.icon === 3?<img src={require('../../../../assets/imgschool/icon_l.png')}/>:
                                                                item.icon === 4?<img src={require('../../../../assets/imgschool/icon_z.png')}/>:
                                                                    item.icon === 5?<img src={require('../../../../assets/imgschool/icon_o.png')}/>:
                                                                        <img src={require('../../../../assets/imgschool/icon_j.png')}/>
                                                }</i>
                                                <h6>{item.name}</h6>
                                            </div>
                                            <div className={'li_title'}>
                                                <h5>{item.num}</h5>
                                                <span>个</span>
                                            </div>
                                            <div className={'li_bg'}>
                                                <Progress percent={item.percent?parseInt(item.percent):100} />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className={'statistical_bottom'}>
                        <div className={'title_to'}>
                            <h4>任务与绩效进展走势</h4>
                            <span onClick={()=>this.viewDetailsa()}>查看详情</span>
                        </div>
                        <div className='data_column'>
                            <div className={'column_head'}>
                                <div className={'head_left'}>
                                    <Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabofTrend(item)}>
                                        <TabPane tab={'任务'} key="1">
                                        </TabPane>
                                        <TabPane  tab={'绩效'} key="2">
                                        </TabPane>
                                    </Tabs>
                                </div>
                                <div className={'head_right'}>
                                    <div className={'right_import'}>
                                        <span className={dateType == 1 ? 'active' : ''} onClick={()=>{this.dateConversion(1)}}>7日</span>
                                        <span className={dateType == 2 ? 'active' : ''} onClick={()=>{this.dateConversion(2)}}>30日</span>
                                        <span className={dateType == 3 ? 'active' : ''} onClick={()=>{this.dateConversion(3)}}>全年</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'column_data'}>
                                <div className={'data_left'}>
                                    <h5>完成数量</h5>
                                    <TaskPerformance key={this.state.taskPerformanceKey} trendType={this.state.trendType} dateType={this.state.dateType}/>
                                </div>
                                <div className={'data_right'}>
                                    <h5>负责人完成情况排名</h5>
                                    <div className={'center-wrap'}>
                                        {
                                            this.state.personOfCompletedVos && this.state.personOfCompletedVos.map((item,index)=>(

                                                <div className={'right_text'}>
                                                    <div className={'text_index'}>{index+1}</div>
                                                    <div className={'text_name'}>{item.completePerson}</div>
                                                    <div className={'text_num'}>{item.completeCount}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'Universities_bottom'}>
                    <div className={'bottom_left'}>
                        <div className={'title_to'}>
                            <h4>绩效指标达标情况</h4>
                            <span onClick={()=>this.viewDetailsb()}>查看详情</span>
                        </div>
                        <div className={'left_cont'}>
                            <div className={'title_list'}>
                                {queryCatgory===1?<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群一'} key="2">
                                    </TabPane><TabPane  tab={'专业群二'} key="3">
                                </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>:<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>}
                            </div>
                            <div className={'eacts'}>
                                <MeetPerformanceIndicators   key={this.state.type} type={this.state.type}  meritIndicatorsQueryList={meritIndicatorsQueryList}/>
                            </div>
                        </div>
                    </div>
                    <div className={'bottom_right'}>
                        <div className={'title_to'}>
                            <h4>绩效指标完成度</h4>
                            <span onClick={()=>this.viewDetailsc()}>查看详情</span>
                        </div>
                        <div className={'left_cont'}>
                            <div className={'title_list'}>
                                {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群一'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'专业群二'} key="3">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                                    <TabPane tab={'学校'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'专业群'} key="2">
                                    </TabPane>
                                    <TabPane  tab={'其他'} key="4">
                                    </TabPane>
                                </Tabs>}
                            </div>
                            <div className={'eacts'}>
                                <PerformanceIndicator key={this.state.cengMianMeritCompleteDu} cengMian={this.state.cengMianMeritCompleteDu}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    title={
                        <div className='main-contWarp'>
                            <div className={'title_liwrp'}>
                                <div className= 'left-retu' onClick={this.onClose}>
                                    <span>返回</span>
                                </div>
                            </div>
                        </div>
                    }
                    placement="right"
                    className="CollegesUniversities_war"
                    closable={false}
                    onClose={this.onClose}
                    visible={onfunds}
                >
                    <SituationFunds schoolMoneyOverview = {schoolMoneyOverview} overallFundSituationInfo = {overallFundSituationInfo}/>
                </Drawer>
            </div>

        );
    }
}

CollegesUniversities = Form.create()(CollegesUniversities);
export default CollegesUniversities;
