import {get, post } from '../../until/axios_instans';

//绩效分页查询
export function meritPageList(data){
    return post('/api/ss/api/v1/merit/meritPageList',data);
}

//校级绩效表信息查询
export function meritGetInfoById(data){
    return post('/api/ss/api/v1/merit/meritGetInfoById',data);
}

//校级绩效关联任务信息查询
export function meritAssociatedTaskInfo(data){
    return post('/api/ss/api/v1/merit/meritAssociatedTaskInfo',data);
}

//子绩效查询
export function meritSonGetInfo(data){
    return post('/api/ss/api/v1/merit/meritSonGetInfo',data);
}

//新增绩效
export function meritSave(data){
    return post('/api/ss/api/v1/merit/meritSave',data);
}

//完成绩效
export function completionMerit(data){
    return post('/api/ss/api/v1/merit/completionMerit',data);
}

//激活绩效
export function activationMerit(data){
    return post('/api/ss/api/v1/merit/activationMerit',data);
}

//修改绩效
export function meritUpdateById(data){
    return post('/api/ss/api/v1/merit/meritUpdateById',data);
}

//删除绩效
export function meritDeleteById(data){
    return post('/api/ss/api/v1/merit/meritDeleteById',data);
}

//绩效动态表查询
export function meritDynamicInfo(data){
    return post('/api/ss/api/v1/merit/meritDynamicInfo',data);
}

//指标下拉
export function meritIndicatorInfo(data){
    return post('/api/ss/api/v1/merit/meritIndicatorInfo',data);
}

//修改负责人
export function meritUpdateDirector(data){
    return post('/api/ss/api/v1/merit/meritUpdateDirector',data);
}

//一级指标查询
export function meritOneIndicatorInfo(data){
    return post('/api/ss/api/v1/merit/meritOneIndicatorInfo',data);
}

//二级指标查询
export function meritTwoIndicatorInfo(data){
    return post('/api/ss/api/v1/merit/meritTwoIndicatorInfo',data);
}

//绩效总览
export function meritListOverview(data){
    return post('/api/ss/api/v1/merit/meritListOverview',data);
}

//字典表
export function getCommonDic(data){
    return get('/api/ss/api/v1/ss/commonDic/getCommonDic',data);
}

//绩效excel校验
export function meritExcelImportCheck(data){
    return post('/api/ss/api/v1/excel/meritExcelImportCheck',data);
}

//绩效excel导入
export function meritExcelImport(data){
    return post('/api/ss/api/v1/excel/meritExcelImport',data);
}

//导入记录
export function recordExcelImport(data){
    return post('/api/ss/api/v1/excel/recordExcelImport',data);
}

//年度绩效执行情况
export function meritYearExecuteInfo(data){
    return post('/api/ss/api/v1/merit/meritYearExecuteInfo',data);
}
//绩效佐证材料
export function meritYearDoc(data){
    return post('/api/ss/api/v1/merit/meritYearDoc',data);
}



