/*建设任务管理*/
import React, {Component} from "react";
import {
    Form, Button, Table, Spin, Progress,
} from "antd";
import "./index.scss";

import {withRouter} from "react-router";
import Cookies from "js-cookie";
import moment from "moment";
import {uploadFile} from '@/api/file/'
import {uploadNewFile, checkFile} from '@/api/project'
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
@withRouter
class Uploadfile extends Component {
    state = {
        tableData: [],//上传文件table列表
        fileData: [],
        columns: [
            {
                title: '资源名称', dataIndex: 'resourceShortName', key: 'resourceShortName', align: 'center', ellipsis: true,
                render: (resourceShortName, record) => {
                    return <div className='title-h3 title-h3b' style={{display: 'flex'}}>
                        <span className={'filename'}>{resourceShortName}</span>
                        {record.plan === 1 ? <Progress percent={100}  className="jindu" type="circle"/> : <Spin style={{marginLeft: '10px'}}/>}
                        {/*<span color="red" style={{color: '#3E78ED',cursor: 'pointer',marginLeft: '10px'}} onClick={()=>this.Changedittitle(record)}>编辑</span>*/}
                    </div>
                }
            },
            {
                title: '格式',
                dataIndex: 'resourceFormat',
                key: 'resourceFormat',
                align: 'center',
                width: 150
            },
            {
                title: '大小',
                dataIndex: 'resourceSize',
                key: 'resourceSize',
                align: 'center',
                width: 150,
                render: (text) => (
                    text < 1024 ? text + "K" : (text / 1024).toFixed(2) + "M"
                )
            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'center'
            },
            {
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                render: (text, record) => <div>
                    {
                        record.type === 1 ?
                            <Button icon="delete"
                                    onClick={() => this.deleteResource(record)}>
                                删除
                            </Button> :
                            record.type === 2 ?
                                <div>
                                    {
                                        this.state.isStop === false ?
                                            <Button icon="pause-circle" style={{marginRight: '10px'}}
                                                    onClick={this.changeType}>
                                                暂停
                                            </Button>
                                            :
                                            <Button icon="play-circle" style={{marginRight: '10px'}}
                                                    onClick={this.changeType}>
                                                继续
                                            </Button>
                                    }

                                    <Button icon="delete" onClick={() => this.deleteResource(record)}>
                                        删除
                                    </Button>
                                </div>
                                : <Button icon="delete"
                                          onClick={() => this.deleteResource(record)}>
                                    删除
                                </Button>
                    }
                </div>,
            },
        ],
        status: true,
        parentId: this.props.parentId,
        projectId: this.props.match.params.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({tableData: [], parentId: nextProps.parentId })
    }
    //以下是上传文件方法开始
    //上传方法
    multipartUpload = (e) => {
        let list = [...e.target.files];
        let {tableData} = this.state;
        if (tableData.length >= 20){
            // message.info("一次最多上传10个文件")
            this.mesWarning('一次最多上传20个文件')
            return
        }
        list.forEach(async (k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if(fileNameindex < 2 || fileNameindex > 40){
                this.mesError('上传的资源名称需大于2且小于40个字符!')
                return
            }
            if(k.size/1024/1024 > 200){
                this.mesError('上传的资源大小不能超过200M')
                return
            }
            if(k.size === 0){
                this.mesError('上传的资源不能为空！')
                return
            }
            if (!this.checkFileType(fileType)) {
                // message.error("上传的资源格式不符")
                this.mesError('上传的资源格式不符')
                return
            }
            if (tableData.find((v) => v.resourceName === k.name)) {
                // message.warning("该资源已上传")
                this.mesWarning('该资源已上传')
                document.getElementById('file').value = null;
                return
            }
            const {parentId, projectId, schoolId} = this.state
            let data = {
                fileName: k.name,
                parentId,
                projectId,
                schoolId,
            }
            let res = await checkFile(data)
            if(res.message === 'success'){
                let ymd = moment(new Date()).format('YYYY-MM-DD');
                ymd = ymd.replace(/-/g, '');
                let fieldId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
                fieldId = `cvec-space-front-guanli/${ymd}/${fieldId}`
                k["fieldId"] = fieldId
                if (!tableData.find((v) => v.resourceName === k.name)) {
                    tableData.push({
                        fileId: fieldId,
                        resourceName: k.name,
                        resourceShortName:k.name.slice(0,k.name.lastIndexOf('.')),
                        resourceFormat: fileType,
                        resourceSize: (k.size / 1024).toFixed(2),
                        operationTime: transTime(k.lastModifiedDate),
                        createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        plan: 0,
                    });
                }
                this.setState({
                    tableData: tableData,
                });

            }
            this.uploadFile(k)
            // let f = 0;
            // let fn = async (a) => {
            //     f++;
            //     let da = await this.uploadFile(list[a]);
            //     if (da === 1 && f < list.length) {
            //         return fn(f);
            //     }
            // };
            // fn(f);
        });
        e.target.value=''
    };

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "mp3、wav、wmv、doc/docx、xls/xlsx、ppt/pptx、pdf、txt、mp4、avi、rm/rmvb、mov、wmv、swf、flv、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    storeFile = (name, file, p) => {
        if (!file) return;
        let {tableData} = this.state;
        let item = tableData.find((v) => v.resourceName === name);
        item.plan = p;
        this.setState({tableData});
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        opt[name] = JSON.stringify(file);
        localStorage.setItem("uploadList", JSON.stringify(opt));
    };
    //上传文件
    uploadFile = (v) => {
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise  (async (reslove) => {
            if (v && opt[v.name]) {
                let data = JSON.parse(opt[v.name]);
                data.file = v;
                this.clearFile(v);
                reslove(1);
            } else {
                this.clearFile(v);
                reslove(1);
            }
        });
    };
    //上传完成
    clearFile = async (file) => {
        if (file) {
            if (file.size < 1024 * 1024) {
            //小于100kb的文件不支持分片上传，等返回上传成功结果后直接调整进度未100%
            let {tableData} = this.state;
            // let item = tableData.find((v) => v.resourceName === file.name);
            this.setState({tableData});
            }
            let opt = localStorage.getItem("uploadList")
                ? JSON.parse(localStorage.uploadList)
                : {};
            delete opt[file.name];
            localStorage.setItem("uploadList", JSON.stringify(opt));
            document.getElementById('file').value = null;
            const formdata = new FormData();
            formdata.append('file', file);
            formdata.append('folder', 'ss')
            await uploadFile(formdata).then(res=>{
                if(res){
                    let fileData = this.state.fileData
                    fileData.push(res)
                    this.setState({fileData},()=>{
                        if(this.state.tableData.length){
                            this.storeFile(file.name, file, 1);
                        }
                    })
                }

            })
        }
    };
    // 删除
    deleteResource = (record) => {
        let {tableData, fileData} = this.state;
        let index = tableData.findIndex((v) => {
            return v.fileId === record.fileId;
        });
        tableData.splice(index, 1);
        fileData.splice(index, 1)
        this.setState({
            tableData,
            fileData
        });
    }
    //发布
    handlesave = async () => {
        console.log("上传")
        const {tableData, status} = this.state
        if(tableData.length === 0){
            this.mesWarning("请上传文件")
        }else{
            // let param = {
            //     "chapterList": []
            // }
            let resourceOperateAdd = {};
            resourceOperateAdd.platform = "LS";
            resourceOperateAdd.resourceSourceType = 0;
            var saveStatus = true;
            for (let checkItem of tableData) {
                if (checkItem.plan !== 1) {
                    saveStatus = false
                    //message.warning("文件" + checkItem.resourceName + '上传未完成，不能保存')
                    this.mesWarning("文件" + checkItem.resourceName + '上传未完成，不能保存')
                    break
                }
            }
            if (!saveStatus) {
                return
            }
            this.props.toFatherValue(true);
            this.state.fileData.map ((item) => {
                if (status) {
                    this.setState({status: false}, async () => {
                        // let type = 'doc/docx、xls/xlsx、ppt/pptx、pdf、txt'.includes(item.resourceFormat) ? 2 : 'mp4、avi、rm/rmvb、mov、wmv、swf、flv'.includes(item.resourceFormat) ?
                        //     1 : 'mp3、ogg、wav、wmv'.includes(item.resourceFormat) ? 0 : 3

                        let data = {
                            createrId:JSON.parse(Cookies.get('ssInfo')).userInfo.id,
                            createrName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
                            schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id,
                            parentId: this.props.parentId,
                            projectId: this.props.match.params.id,
                            resourceFileQo: {
                                fileId: item.fpName,
                                resourceId: item.id,
                                resourceName: item.originFileName,
                                resourceSize: item.fileSize,
                                resourceSuffix: item.mediaType
                            }
                        }
                        uploadNewFile(data).then(res=>{
                            // if(res.message == 'success'){
                            this.mesSuccess('上传成功');
                            this.props.uploadVideo()
                            this.props.queryFileListById('',0)
                            this.setState({
                                tableData: [],
                                fileData: [],
                                status: true
                            })
                            this.props.toFatherValue(false);
                            // }
                        }).catch(err => {
                            if(err){
                                this.props.toFatherValue(false);
                                this.setState({status: true,tableData: [], fileData: [],})
                            }
                        })
                    })
                } else {
                    return
                }
            })
        }
    }
    render() {
        const {columns, tableData} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className={'sguploadFile'}>
                <span className="span">支持doc/docx、xls/xlsx、ppt/pptx、pdf、txt、mp4、avi、rm/rmvb、mov、wmv、swf、flv、mp3、wav、jpg、jpeg、png、bmp、gif，最大不可超过200M，最多不超过20个</span>
                <div className="Upload_cont">
                    <div className='upload_wapper'>
                        <label htmlFor="file" className="inputlabelBox">
                            <img className='btn_icon' src={require('@/assets/img/uplaod.svg')} alt={'文件上传'}/><span className="inputBox">本地上传</span>
                        </label>
                        <input
                            type="file"
                            accept="image"
                            onChange={this.multipartUpload}
                            name="myfile"
                            id="file"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className='table_title'>文件目录（{tableData.length}/20）</div>
                    <Table
                        className='label-table'
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        locale={{
                            emptyText: <>
                                <div className="Empty-conet">
                                    <div className="img">
                                        <img src={require(`@/assets/imgnew/pic-4.png`)} alt={'暂无数据'}/>
                                        <span>暂无数据</span>
                                    </div>
                                </div>
                            </>
                        }}
                    />
                </div>
            </div>
        );
    }
}

Uploadfile = Form.create()(Uploadfile);
export default Uploadfile;
