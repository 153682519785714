// 绩效指标达标情况-编辑
import React, {Component} from "react";
import {Form, Input, Progress, Steps, Table, Button, Modal, Select} from "antd";
import {withRouter} from "react-router";
import {Rededitor,Readsign,Redcheck,Goup} from '../../../../../assets/svg'
import style from './index.module.scss'
import TaskstatEditor from "../taskstatEditor";
import {downExcel} from '../../../../../api/login/';
import * as echarts from "echarts";
import ScreeningCondition from "../screeningCondition";
const {TextArea} = Input;
const {Step} = Steps;


@withRouter
class Constructionscheduleview extends Component {
    state = {
        titleDivLD:'建设任务进度',
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '建设任务名称',
                dataIndex: 'performanceIndicators',
                width: 200,
                align: "center",
                key: 'performanceIndicators',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'level',
                key: 'level',

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'indicatorsFirst',
                key: 'indicatorsFirst',

            },
             {
                title: '进度',
                align: "center",
                width: 200,
                dataIndex: 'completeness',
                key: 'completeness',

            },


            ],
        pageDataListA:[
            {
                id: 1,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 2,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 3,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 4,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 5,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id:6,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 7,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            },
            {
                id: 8,
                performanceIndicators: '建设任务进度水平',
                level:'学校1',
                indicatorsFirst:'已启动1',
                completeness:'97%'


            }

        ],//表格数据-建设任务进度

        pageDataListB:[
            {
                id: 1,
                performanceIndicators: '绩效完成度水平',
                level:'学校2',
                indicatorsFirst:'已启动2',
                completeness:'98%'


            },
        ],//表格数据-绩效完成度
        pageDataListC:[
            {
                id: 1,
                performanceIndicators: '资金使用额度',
                level:'学校3',
                indicatorsFirst:'已启动3',
                completeness:'99%'


            },
        ],//表格数据-绩效完成度




    }

    componentDidMount() {
        this.fundUsageLimit()
    }

    changeTable = (titleDivLD) =>{
        this.setState({
            titleDivLD:titleDivLD,
        })

    }
    //excel导出
    downExcel = () => {
        downExcel();
    };
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }
    fundUsageLimit = () => {
        // let data = [this.state.remainingAmount[0],this.state.remainingAmount[1],this.state.remainingAmount[2],this.state.remainingAmount[3],this.state.remainingAmount[4]]
        // let data1 = []
        // data.map(item=>{
        //     let newdata=0
        //     if(item < 0){
        //         newdata = -item
        //     }else{
        //         newdata = item
        //     }
        //     data1.push(newdata)
        // })
        let data = [20,30,60,10,80]
        let data1 = [20,30,60,10,80]
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('fundUsageLimit')){
            let myChart = echarts.init(document.getElementById('fundUsageLimit'));
            // 绘制图表
            let option = {
                color: ["#3E78ED"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data:["中央财政投入资金", "地方各级财政投入资金", "举办方投入资金", "行业企业支持资金", "学校自筹资金"],
                    show: true,
                    position: 'bottom',
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#3E78ED'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }




    render() {
        const {columns,pageDataList,pageSize, total, pageNum,
            titleDivLD,pageDataListA,pageDataListB,pageDataListC,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <div className={style.constructionscheduleview}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>概览</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'} onClick={() => this.downExcel()}>
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <ul className={'top-wrap'}>
                                <li onClick={()=>this.changeTable('建设任务进度')}>
                                    <div className={'text'}>建设任务进度</div>
                                    <div className={'percentage'}>80%</div>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#FF7214'} showInfo={false}/>
                                    <div className={'texttwo'}>
                                        <span>近30日增长率：12%</span>
                                        <Goup/>
                                    </div>
                                </li>
                                <li onClick={()=>this.changeTable('绩效完成度')}>
                                    <div className={'text'}>绩效完成进度</div>
                                    <div className={'percentage'}>80%</div>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#33C6C3'} showInfo={false}/>
                                    <div className={'texttwo'}>
                                        <span>平均完成度：89%</span>
                                    </div>
                                </li>
                                <li onClick={()=>this.changeTable('资金使用额度')}>
                                    <div className={'text'}>资金使用额度（元）</div>
                                    <div className={'percentage'}>¥568456</div>
                                    <div id={'fundUsageLimit'} className={'echarts'} style={{width: '100%', height: '45px',marginTop: '5px'}}></div>
                                    <div className={'texttwo'}>
                                        <span>月均使用额：¥6723</span>
                                    </div>
                                </li>
                            </ul>


                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>{titleDivLD}</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    {
                                        titleDivLD === '建设任务进度' ?
                                            <Table cellspacing={20} columns={columns}
                                                   className={'tableUserStyle'}
                                                   dataSource={pageDataListA}
                                                   scroll={{ x: 700,y:290}}
                                                   onChange={this.changePage}
                                                   pagination={pagination}
                                                   rowKey={0}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            /> : titleDivLD === '绩效完成度' ?
                                            <Table cellspacing={20} columns={columns}
                                                   className={'tableUserStyle'}
                                                   dataSource={pageDataListB}
                                                   scroll={{ x: 700,y:290}}
                                                   onChange={this.changePage}
                                                   pagination={pagination}
                                                   rowKey={1}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            /> :
                                            <Table cellspacing={20} columns={columns}
                                                   className={'tableUserStyle'}
                                                   dataSource={pageDataListC}
                                                   scroll={{ x: 700,y:290}}
                                                   onChange={this.changePage}
                                                   pagination={pagination}
                                                   rowKey={2}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            />
                                    }


                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度：</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>绩效</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>资金</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition/>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span onClick={this.handLook} className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

Constructionscheduleview = Form.create()(Constructionscheduleview);
export default Constructionscheduleview;
