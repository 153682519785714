import reducers from './reducers'
const imgUrl='cvec-space-front-guanli/zixun/icon/'


let defaultState = {
    collapsed: false,
    newsmanlist: [
        {
            menuName: '工作台',
            id: 'sub1',
            icon:'gzt',
            iconActive:imgUrl+'management-selected.png',
            menuUrl: '/main/doublehigh/workbench',
        },
        {
            menuName: '双高院校配置管理',
            id: 'sub2',
            icon:'pzgl',
            children: [
                {
                    menuName: '院校管理',
                    id: '1',
                    menuUrl: '/main/doublehigh/department'
                },
                {
                    menuName: '用户管理',
                    id: '2',
                    menuUrl: '/main/doublehigh/userManagement'
                },
                {
                    menuName: '角色权限管理',
                    id: '3',
                    menuUrl: '/main/doublehigh/rolement'
                },
                {
                    menuName: '学校与专业群管理',
                    id: '4',
                    menuUrl: '/main/doublehigh/professionGroup'
                },
                {
                    menuName: '数据同步管理',
                    id: '5',
                    menuUrl: '/main/doublehigh/synchrodatment'
                },
                // {
                //     menuName: '分配用户',
                //     id: '6',
                //     menuUrl: '/main/doublehigh/distributionuser/:id'
                // },
            ]
        },

    ]
}
const Doublehigh = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Doublehigh
