/*概览模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Progress} from "antd";
import * as echarts from 'echarts';
import {Goup,Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";

@withRouter
class Template1 extends Component {
    componentDidMount() {
        this.myecharts1()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    myecharts1 = ()=>{
        if (document.getElementById('myEcharts1')) {
            let myChart = echarts.init(document.getElementById('myEcharts1'));
            let data = ['11','22','33','44','55']
            let data1 =['1月','2月','3月','4月','5月']
            // 绘制图表
            let option = {
                color: ["#3E78ED"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00","")
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: data1,
                    show: true,
                    position: 'bottom',
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams) {
                                if (data[farams.dataIndex] > 0) {
                                    return '#3E78ED'
                                } else {
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    render() {
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'overview-wrap'}>
                        <div className={'title'}>
                            概览
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('概览','/main/doublehigh/editconstructionscheduleview')}><Task/></div>
                                <ThreeDian/>

                            </div>
                        </div>
                        <ul className={'top-wrap'}>
                            <li>
                                <div className={'text'}>建设任务进度</div>
                                <div className={'percentage'}>{30}%</div>
                                <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#FF7214'} showInfo={false}/>
                                <div className={'texttwo'}>
                                    <span>近30日增长率：{12}%</span>
                                    <Goup/>
                                </div>
                            </li>
                            <li>
                                <div className={'text'}>绩效完成进度</div>
                                <div className={'percentage'}>{45}%</div>
                                <Progress percent={45} className={'progress'} strokeWidth={'10px'} strokeColor={'#33C6C3'} showInfo={false}/>
                                <div className={'texttwo'}>
                                    <span>平均完成度：{23}%</span>
                                </div>
                            </li>
                            <li>
                                <div className={'text'}>资金使用额度</div>
                                <div className={'percentage'}>¥{3434}/元</div>
                                <div className={'tempalte1-echarts'} id={'myEcharts1'}></div>
                                <div className={'texttwo'}>
                                    <span>近5月月均使用额：¥{344}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        );
    }
}

Template1 = Form.create()(Template1);
export default Template1;
