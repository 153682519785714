import React, {Component} from "react";
import "./index.scss";
import {Table, Input, Form, Icon, Modal} from "antd";
import Cookies from "js-cookie";
import {queryRoleTypes, updateRoleTypes, insertRoleTypes, deleteRoleTypes} from '@/api/role/index'
const EditableContext = React.createContext();

class EditableCell extends React.Component {

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    min: 2,
                                    max: 10,
                                    message: `角色名称不能小于2或者大于10！`
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(<Input />)}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return (
            <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        );
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: "",
            addRoleType: false,
            roleName: '',
            isShowP: false,
            deleteVisible: false,
            id: '',
        };
        this.columns = [
            {
                title: "roleTypeName",
                dataIndex: "roleTypeName",
                width: "50%",
                editable: true
            },
            {
                title: "operation",
                dataIndex: "operation",
                align: 'right',
                width: '50%',
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
              <EditableContext.Consumer>
                {(form) => (
                    <div className='savebtn'>
                        <a
                            onClick={() => this.xiugai(form, record.id)}
                            style={{ marginRight: 8 }}
                        >
                            <Icon type="check-circle"/>
                        </a>
                        <a
                            onClick={() => this.cancel()}
                            style={{ marginRight: 8 }}
                        >
                            <Icon type="close-circle" />
                        </a>
                    </div>
                )}
              </EditableContext.Consumer>
            </span>
                    ) : (
                        <div>
                            <a
                                disabled={editingKey !== ""}
                                onClick={() => this.edit(record.id)}
                            >
                                <Icon type="edit" />
                                编辑
                            </a>
                            <a
                                disabled={editingKey !== ""}
                                onClick={() => this.deleteRole(record.id)}
                            >
                                <Icon type="delete" />
                                删除
                            </a>
                        </div>
                    );
                }
            }
        ];
    }
    componentDidUpdate(prevProps) {

    }
    componentDidMount() {
        this.props.triggerRef(this)
        this.searchlist()
    }
    chongzhi = () =>{
        this.setState({editingKey: "", addRoleType: false})
    }
    searchlist = () =>{
        let schoolId ={schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id}
        queryRoleTypes(schoolId).then(res =>{
            if(res){
                this.setState({data: res})
            }
        })
    }

    isEditing = (record) => record.id === this.state.editingKey; //编辑

    cancel = () => {    //取消
        this.setState({ editingKey: "" });
    };
    //修改保存
    xiugai = (form, key) => {
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            let id = key  //id
            let data = {
                id,
                schoolId: schoolInfo.id,
                roleTypeName: row.roleTypeName
            }
            updateRoleTypes(data).then(res => {
                this.setState({ editingKey: "" })
                this.searchlist()
                this.props.getRoleTypelist()
            })

        });
    }
    deleteRole = (id) =>{ //删除
        this.setState({deleteVisible: true, id})
    }
    edit(id) {
        this.setState({ editingKey: id });
    }
    addRoleType = () =>{ //新建角色分类
        this.setState({addRoleType: true})
        document.getElementsByClassName('ant-modal-body')[0].scrollTop=0

    }
    roleName = (e) =>{
        let roleName = e.target.value
        if(roleName.length >= 2 && roleName.length <= 10){
            this.setState({isShowP: false})
            this.setState({roleName})
        }else{
                this.setState({isShowP: true})
        }

    }
    saveRoleType = () =>{//新建保存
        let roleTypeName = this.state.roleName
        if(roleTypeName.length >= 2 && roleTypeName.length <= 10){
            let schoolId = JSON.parse(Cookies.get('ssInfo')).schoolInfo.id
            let data = {roleTypeName ,schoolId}
            insertRoleTypes(data).then(res => {
                if(res == 1){
                    this.mesSuccess('添加成功！')
                    this.setState({addRoleType: false})
                    this.searchlist()
                    this.props.getRoleTypelist()
                }
            })
        }else{
            this.setState({isShowP: true})
        }
    }
    deleteHandleOk = () =>{
        let data = {id: this.state.id, schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id}
        deleteRoleTypes(data).then(res => {
            if(res){
                this.setState({deleteVisible: false})
                this.searchlist()

            }
        })
    }
    deleteHandleCancel = () =>{
        this.setState({deleteVisible: false})
    }
    render() {
        const components = {
            body: {
                cell: EditableCell
            }
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === "text",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record)
                })
            };
        });

        return (
            <div className='addrolement'>
                <div className='addrole-btn' onClick={() => this.addRoleType()}>
                    <Icon type="plus-circle" />
                    <span>新建角色分类</span>
                </div>
                {this.state.addRoleType ? (
                    <div className='addRoleType'>
                        <Input autocomplete="off" placeholder={'请输入角色分类名称'} onChange={this.roleName}/>
                        <a
                            onClick={() => this.saveRoleType()}
                            style={{ marginRight: 8 }}
                        >
                            <Icon type="check-circle"/>
                        </a>
                        <a
                            onClick={() => this.setState({addRoleType: false})}
                            style={{ marginRight: 8 }}
                        >
                            <Icon type="close-circle" />
                        </a>
                        {this.state.isShowP ? <p>角色名称不能小于2或者大于10!</p> : ''}
                    </div>
                ) : ''}
                <EditableContext.Provider value={this.props.form}>
                    <Table
                        components={components}
                        bordered={false}
                        dataSource={this.state.data}
                        columns={columns}
                        showHeader={false}
                        pagination={ false }
                        rowClassName="editable-row"
                        locale={{
                            emptyText: <> </>
                        }}
                    />
                </EditableContext.Provider>
                {/*删除弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.deleteVisible}
                    onOk={this.deleteHandleOk}
                    onCancel={this.deleteHandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <Icon type="exclamation-circle" className='modal-icon'/>
                    <p>是否确定删除？</p>
                </Modal>
            </div>
        );
    }
}

EditableTable = Form.create()(EditableTable);
export default EditableTable;
