/*任务统计模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form, Progress} from "antd";
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";

@withRouter
class Template4 extends Component {
    state={

    }
    componentDidMount() {

    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    render() {
        const {queryCatgory} = this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'renwutonhji width50'}>
                        <div className={'title title100'}>
                            任务统计
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('任务统计','/main/doublehigh/editortaskStatistical')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        <ul className={'renwutonhji-ul'}>
                            <li>
                                <span>总任务数</span>
                                <span className={'number'} style={{color: '#3E78ED'}}>{6}</span>
                                <Progress percent={6} className={'progress'} strokeWidth={'10px'} strokeColor={'#3E78ED'} showInfo={false}/>
                            </li>
                            <li>
                                <span>已完成</span>
                                <span className={'number'} style={{color: '#F4DF58'}}>{56}</span>
                                <Progress percent={56} className={'progress'} strokeWidth={'10px'} strokeColor={'#F4DF58'} showInfo={false}/>
                            </li>
                            <li>
                                <span>已逾期</span>
                                <span className={'number'} style={{color: '#23B899'}}>{34}</span>
                                <Progress percent={34} className={'progress'} strokeWidth={'10px'} strokeColor={'#23B899'} showInfo={false}/>
                            </li>
                            <li>
                                <span>未完成</span>
                                <span className={'number'} style={{color: '#F07661'}}>{23}</span>
                                <Progress percent={23} className={'progress'} strokeWidth={'10px'} strokeColor={'#F07661'} showInfo={false}/>
                            </li>
                            <li>
                                <span>待认领</span>
                                <span className={'number'} style={{color: '#966CFC'}}>{24}</span>
                                <Progress percent={24} className={'progress'} strokeWidth={'10px'} strokeColor={'#966CFC'} showInfo={false}/>
                            </li>
                            <li>
                                <span>时间待定</span>
                                <span className={'number'} style={{color: '#7A79FF'}}>{78}</span>
                                <Progress percent={78} className={'progress'} strokeWidth={'10px'} strokeColor={'#7A79FF'} showInfo={false}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        );
    }
}

Template4 = Form.create()(Template4);
export default Template4;
