/*任务支出饼图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';
import {queryMeritCompleteDu} from "../../../api/chart/index"

@withRouter
class PerformanceIndicator extends Component {
    state = {
        cengMian:this.props.cengMian,
        outputMeritIndicatorCompleteDu:'',//产出指标
        benefitMeritIndicatorCompleteDu:'',//效益指标
        satisfactionIndicatorCompleteDu:''//满意度指标
    }

    componentDidMount() {
        this.queryMeritCompleteDu()
    }
    queryMeritCompleteDu = async () => {
        let data = {
            cengMian: this.state.cengMian
        }
        await queryMeritCompleteDu(data).then(res => {
            if(res){
                this.setState({
                    outputMeritIndicatorCompleteDu:parseFloat(res.outputMeritIndicatorCompleteDu).toFixed(2),
                    benefitMeritIndicatorCompleteDu:parseFloat(res.benefitMeritIndicatorCompleteDu).toFixed(2),
                    satisfactionIndicatorCompleteDu:parseFloat(res.satisfactionIndicatorCompleteDu).toFixed(2),
                },()=>{
                    this.performanceIndicator()
                })
            }
        })
    }
    performanceIndicator = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('performanceIndicator')){
            // let data = ['120','50','90']
            let data = [this.state.outputMeritIndicatorCompleteDu,this.state.satisfactionIndicatorCompleteDu,this.state.benefitMeritIndicatorCompleteDu]
            let myChart = echarts.init(document.getElementById('performanceIndicator'));
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        indicator: [
                            { text: '产出指标',min:0,max:150},
                            { text: '满意度指标',min:0,max:150},
                            { text: '效益指标',min:0,max:150},
                        ],
                        center: ['center', 'center'],
                        radius: 100,
                        // startAngle: 90,
                        splitNumber: 5,
                        name: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#333333'
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['rgba(255,255,255, 0.2)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204,0.3)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204, 1)'
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: '雷达图',
                        type: 'radar',
                        symbol: 'none',
                        symbolSize: 0,
                        areaStyle: {
                            normal: {
                                shadowBlur: 13,
                                shadowColor: 'rgba(0,0,0,.2)',
                                shadowOffsetX: 0,
                                shadowOffsetY: 10,
                                opacity: 1
                            }
                        },
                        data: [
                            {
                                value: data,
                                name: ' 一级绩效指标',
                                tooltip: {
                                    trigger: 'item'
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [{
                                                offset: 0,
                                                color: 'rgba(253, 149, 136, 0.4)'
                                            },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(253, 149, 136, 0.4)'
                                                }
                                            ],
                                            false
                                        ),
                                        opacity: 1 // 区域透明度
                                    },
                                },
                                label: {
                                    show: true,
                                    color:'rgba(0,0,0,0)',
                                    formatter: function(params) {
                                        return params.value;
                                    }
                                },
                                lineStyle:{
                                    color:'#FD9588'
                                },

                            }
                        ]
                    },
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    render() {
        return (
            <div style={{height: '340px', float: 'left',width: '100%','marginTop': '25px'}}>
                <div id={'performanceIndicator'} className={'echarts'} style={{width: '100%', height: '340px'}}></div>
            </div>

        );
    }
}

PerformanceIndicator = Form.create()(PerformanceIndicator);
export default PerformanceIndicator;
