//预算设置二级
import React, {Component} from "react";
import {Form, Input, Modal, Table, Tooltip,InputNumber} from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss'
import {
    selectTaskExpendInvestList, queryBudgetTaskQuota, setUpFundsTask, selectTaskExpendInvestInfo,
} from '../../../../../../api/fund/';

import {Backtrack} from "../../../../../../assets/svg";
@withRouter
class BudgetsettingLeveltwo extends Component {
    state = {
        columns: [
            {title: '任务名称', dataIndex: 'taskName', key: 'taskName', align: 'center', width: '200',
                render: (text,record) => {
                    return (record.taskName&&record.taskName.length>10?
                        <Tooltip  placement="bottomLeft" title={record.taskName} overlayClassName='TitleTooltip'
                        ><span>{record.taskName}</span></Tooltip>:<span>{record.taskName}</span>)
                }},
            {title: '小计(元)', dataIndex: 'total', key: 'total', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.total ? record.total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '中央财政投入资金(元)', dataIndex: 'centerInvest', key: 'centerInvest', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.centerInvest ? record.centerInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '地方各级财政投入资金(元)', dataIndex: 'localInvest', key: 'localInvest', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.localInvest ? record.localInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '举办方投入资金(元)', dataIndex: 'holderInvest', key: 'holderInvest', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.holderInvest ? record.holderInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '行业企业支持资金(元)', dataIndex: 'enterpriseInvest', key: 'enterpriseInvest', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.enterpriseInvest ? record.enterpriseInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '学校自筹资金(元)', dataIndex: 'schoolInvest', key: 'schoolInvest', align: 'center', width: '200',
                render: (text,record) => {
                    return (<div>{<span>{record.schoolInvest ? record.schoolInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }},
            {title: '操作', dataIndex: '', key: '', align:"center", width: '200',fixed:"right",
                render: (text, record) =>
                    <div className={'caozuo'}>
                        <span onClick={()=>this.chakan(record.id)}>查看</span>
                        <span onClick={()=>this.budgetSet(record.id,record.parentId,record.taskName,record.isNew)}>设置</span>
                    </div>
            },
        ],
        dataSource: [
            {
                assignmen: 1111,
                subtotal: 2222,
                capital1: 11,
                capital2: 22
            }
        ],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        budgetVisible: false,
        projectId: this.props.match.params.id,
        selectTaskExpendInvestList:[],
        parentId:this.props.match.params.parentId,
        projectName:this.props.match.params.projectName,
        queryBudgetTaskQuota:[],
        taskName:'',
        statusButten:true,
        taskId:'',
        amountInTotal: 0,
        name:'',//建设任务名称或者任务名称
        centerInvestTip: 0,//1设置超出预算显示警告 0不显示
        localInvestTip: 0,
        holderInvestTip: 0,
        enterpriseInvestTip: 0,
        schoolInvestTip: 0,
        isNew:0,
        parent:0,
        sumTaskExpendInvestList:[],
        backUpParentId: [this.props.match.params.parentId],
        sumTaskExpendInvest:[],
        centerInvestContrast:0,
        localInvestContrast:0,
        holderInvestContrast:0,
        enterpriseInvestContrast:0,
        schoolInvestContrast:0,
        schoolInvest:0,
    }

    componentDidMount() {
        this.selectTaskExpendInvestList().then(res=>{
            this.addheji()
        })
    }

    //查询设置任务查询
    selectTaskExpendInvestList = async () => {
        let data = {
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            projectId:this.state.projectId,
            parentId:this.state.parentId,
        }
        await selectTaskExpendInvestList(data).then(res => {
            this.setState({
                selectTaskExpendInvestList: res.pageData.data,
                name: res.name,
                total:res.pageData.total,
                sumTaskExpendInvest:res.sumTaskExpendInvest,
            })
        })
        console.log(this.state.selectTaskExpendInvestList,'this.state.selectTaskExpendInvestList')
    }

    //查询设置任务查询
    selectTaskExpendInvestInfo = async (id) => {
        let data = {
            projectId:this.state.projectId,
            parentId:this.state.parentId,
            id:id,
        }
        await selectTaskExpendInvestInfo(data).then(res => {
            this.props.form.setFieldsValue({
                centerInvest: res.centerInvest,
                localInvest: res.localInvest,
                holderInvest: res.holderInvest,
                enterpriseInvest: res.enterpriseInvest,
                schoolInvest:res.schoolInvest,

            })
            let amountInTotal = parseInt(res.centerInvest) + parseInt(res.localInvest) + parseInt(res.holderInvest) + parseInt(res.enterpriseInvest) + parseInt(res.schoolInvest)
            this.setState({amountInTotal:res.total,
                centerInvestContrast: res.centerInvest,
                localInvestContrast: res.localInvest,
                holderInvestContrast: res.holderInvest,
                enterpriseInvestContrast: res.enterpriseInvest,
                schoolInvestContrast:res.schoolInvest})

        })

    }
    //查询任务设置的分配额度
    queryBudgetTaskQuota = async (parentId) => {
        let data = {
            projectId:this.state.projectId,
            parentId:parentId,
        }
        await queryBudgetTaskQuota(data).then(res => {
            this.setState({
                queryBudgetTaskQuota: res,
            })
        })
        console.log(this.state.queryBudgetTaskQuota,'this.state.queryBudgetTaskQuota')
    }

    chakan = (parentId) =>{
        this.state.parentId=parentId
        if(this.state.backUpParentId.indexOf(parentId) === -1){
            this.state.backUpParentId.push(parentId)
            this.setState({backUpParentId: this.state.backUpParentId})
            console.log('backUpParentId',this.state.backUpParentId,parentId)
        }
        this.selectTaskExpendInvestList().then(res=> {
            if (document.getElementsByClassName('addnewrighttr').length > 0) {
                document.getElementsByClassName('addnewrighttr')[0].remove()
                this.addheji()
            }
        })
    }
    budgetSet = (id,parentId,taskName,isNew) =>{
        this.queryBudgetTaskQuota(parentId)
        this.selectTaskExpendInvestInfo(id)
        this.setState({budgetVisible: true,taskName:taskName,parent:parentId,taskId:id,isNew:isNew})
    }
    // 返回
    goback = ()=>{
        const backUpParentId = this.state.backUpParentId
        let parentId = backUpParentId[backUpParentId.length - 2]
        if(parentId){
            backUpParentId.pop()
            this.setState({parentId,backUpParentId},()=>{
                this.selectTaskExpendInvestList().then(res=>{
                    if (document.getElementsByClassName('addnewrighttr').length > 0) {
                        document.getElementsByClassName('addnewrighttr')[0].remove()
                        this.addheji()
                    }
                })
            })

        }else{
            this.props.history.push({
                pathname: '/main/doublehigh/fundsmanagement',
                returnFlag:'3',
            })
        }
    }
    budgetHandleOk = async() =>{
        if (this.state.centerInvestTip === 1 ||this.state.localInvestTip=== 1 ||this.state.holderInvestTip=== 1 || this.state.enterpriseInvestTip===1 || this.state.schoolInvestTip===1){
            return this.mesError('超出可分配预算额度，请核实后再继续!')}
            if (this.state.statusButten) {
                this.setState({statusButten: false})
                let formValue = this.props.form.getFieldsValue()
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        let data = {
                            projectId:this.state.projectId,
                            taskId:this.state.taskId,
                            centerInvest: formValue.centerInvest,
                            localInvest: formValue.localInvest,
                            holderInvest: formValue.holderInvest,
                            enterpriseInvest: formValue.enterpriseInvest,
                            schoolInvest: formValue.schoolInvest,
                        };
                        setUpFundsTask(data).then(res => {
                            this.mesSuccess('设置预算成功');
                            this.props.form.resetFields()
                            this.setState({
                                budgetVisible: false,
                            });
                        }).then((res) => {
                            this.selectTaskExpendInvestList().then(res=> {
                                if (document.getElementsByClassName('addnewrighttr').length > 0) {
                                    document.getElementsByClassName('addnewrighttr')[0].remove()
                                    this.addheji()
                                }
                            });
                        })
                    }
                })

            }
            setTimeout(() => {   // 设置延迟事件，5秒后将执行
                this.setState({statusButten: true})   // 将isChange设置为true
            }, 3000);

    }
    budgetHandleCancel = () =>{
        this.setState({budgetVisible: false,centerInvestTip: 0, localInvestTip: 0, holderInvestTip: 0, enterpriseInvestTip: 0, schoolInvestTip: 0})

    }
    inputchange =(s) =>{
        const {queryBudgetTaskQuota} = this.state
        setTimeout(() => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                let amountInTotal = parseFloat(values.centerInvest) + parseFloat(values.localInvest) + parseFloat(values.holderInvest) + parseFloat(values.enterpriseInvest) + parseFloat(values.schoolInvest)
                isNaN(amountInTotal) ? amountInTotal = 0 : amountInTotal = amountInTotal
                this.setState({amountInTotal})
                console.log( 'amountInTotal', amountInTotal)
                if(parseFloat(values.centerInvest) > parseFloat(queryBudgetTaskQuota.centerInvest+this.state.centerInvestContrast)){
                    this.setState({centerInvestTip: 1})
                }else{
                    this.setState({centerInvestTip: 0})
                }
                if(parseFloat(values.localInvest) > parseFloat(queryBudgetTaskQuota.localInvest+this.state.localInvestContrast)){
                    this.setState({localInvestTip: 1})
                }else{
                    this.setState({localInvestTip: 0})
                }
                if(parseFloat(values.holderInvest) > parseFloat(queryBudgetTaskQuota.holderInvest+this.state.holderInvestContrast)){
                    this.setState({holderInvestTip: 1})
                }else{
                    this.setState({holderInvestTip: 0})
                }
                if(parseFloat(values.enterpriseInvest) > parseFloat(queryBudgetTaskQuota.enterpriseInvest+this.state.enterpriseInvestContrast)){
                    this.setState({enterpriseInvestTip: 1})
                }else{
                    this.setState({enterpriseInvestTip: 0})
                }
                if(parseFloat(values.schoolInvest) > parseFloat(queryBudgetTaskQuota.schoolInvest+this.state.schoolInvestContrast)){
                    this.setState({schoolInvestTip: 1})
                }else{
                    this.setState({schoolInvestTip: 0})
                }
            });
        }, 0);
    }
    changePage = (paqination) => {
        this.setState({
            pageNum: paqination.current,
            pageSize: paqination.pageSize,
        }, () => {
            //列表方法
            this.selectTaskExpendInvestList().then(res=> {
                if (document.getElementsByClassName('addnewrighttr').length > 0) {
                    document.getElementsByClassName('addnewrighttr')[0].remove()
                    this.addheji()
                }
            })
        })
    }
    addheji = () =>{
        let hejitr = document.getElementsByClassName('addnewtr')[0]
        let NewElement = document.createElement("tr")
        NewElement.setAttribute('class','addnewrighttr ant-table-row ant-table-row-level-0');
        NewElement.innerHTML = '<td class="ant-table-row-cell-break-word"></td>'
        document.getElementsByClassName('ant-table-tbody')[0].appendChild(hejitr)
        document.getElementsByClassName('ant-table-tbody')[1].appendChild(NewElement)
    }
    render() {
        const {columns, dataSource,pageSize,total,pageNum,budgetVisible,name,sumTaskExpendInvest,selectTaskExpendInvestList,parent,isNew,queryBudgetTaskQuota,taskName,amountInTotal} = this.state
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            // onShowSizeChange: this.showSizeChange
        }
        const NewTr = <tr className={'addnewtr'}>
            <td className='ant-table-row-cell-break-word'  style={{textAlign: "center"}}>合计金额</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.total?sumTaskExpendInvest.total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.centerInvest?sumTaskExpendInvest.centerInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.localInvest?sumTaskExpendInvest.localInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.holderInvest?sumTaskExpendInvest.holderInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.enterpriseInvest?sumTaskExpendInvest.enterpriseInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumTaskExpendInvest.schoolInvest?sumTaskExpendInvest.schoolInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            {/*<td className={'ant-table-fixed-columns-in-body ant-table-row-cell-break-word'}></td>*/}
        </tr>
        return (
            <div className={style.budgetsettingLeveltwo}>
                <div className={'top'}>
                    <div className={'backtrackleft'} onClick={()=>this.goback()}>
                        <Backtrack/>返回
                    </div>
                    {name && name.length > 50 ? <Tooltip title={name}><div className={'toptitle'}>{name.substring(0,50)}</div></Tooltip> :
                    <div className={'toptitle'}>{name}</div>}
                </div>
                <div className={'table_wrap'}>
                    <Table className="main-box-header table-maina rolementStyle" cellspacing={20} columns={columns}
                           dataSource={selectTaskExpendInvestList}
                           pagination={pagination}
                           onChange={this.changePage}
                           scroll={{x: 1500, y: 600}}
                           locale={{emptyText:<><div className="Empty-conet-ss">
                                   <div className="img">
                                       <img src={require("../../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                       <span>暂无数据</span>
                                   </div>
                               </div></>}}
                    />
                    {NewTr}
                </div>
                <Modal
                    title="预算设置"
                    visible={budgetVisible}
                    onOk={this.budgetHandleOk}
                    onCancel={this.budgetHandleCancel}
                    width={660}
                    closable={false}
                    className={style.budgetsettingtwoModal}
                    maskClosable={false}
                    centered={true}
                >
                    <div>
                         {taskName&&taskName.length>35?<Tooltip  placement="bottomLeft" title={taskName} overlayClassName='TitleTooltip'
                    ><span className={'title'}>{taskName}</span></Tooltip>:<span className={'title'}>{taskName}</span>}
                             <Form>
                            <div className={'group'}>
                                <lable className={'labelname'}>中央财政投入资金(人民币元)</lable>
                                <Form.Item className={'formgroup'}>
                                    {getFieldDecorator('centerInvest', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入中央财政投入资金',
                                            },
                                        ],
                                    })(<InputNumber className={'input'} autocomplete="off"  disabled={parent==='0'&&isNew==0} onChange={this.inputchange}
                                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                              parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, '999,999,999')}
                                    />)}
                                    <span className={'text'}>可分配预算额度：{queryBudgetTaskQuota.centerInvest?queryBudgetTaskQuota.centerInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0
                                    }</span>
                                    {this.state.centerInvestTip === 1 ? <div className={'chaochutip'}>超出可分配预算额度，请核实后再继续！</div> : ''}
                                </Form.Item>
                            </div>
                            <div className={'group'}>
                                <lable className={'labelname'}>地方各级财政投入资金(人民币元)</lable>
                                <Form.Item className={'formgroup'}>
                                    {getFieldDecorator('localInvest', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入地方各级财政投入资金',
                                            },
                                        ],
                                    })(<InputNumber className={'input'} autocomplete="off" disabled={parent==='0'&&isNew==0} onChange={this.inputchange}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, '999,999,999')}
                                    />)}
                                    <span className={'text'}>可分配预算额度：{queryBudgetTaskQuota.localInvest?queryBudgetTaskQuota.localInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</span>
                                    {this.state.localInvestTip === 1 ? <div className={'chaochutip'}>超出可分配预算额度，请核实后再继续！</div> : ''}
                                </Form.Item>
                            </div>
                            <div className={'group'}>
                                <lable className={'labelname'}>举办方投入资金(人民币元)</lable>
                                <Form.Item className={'formgroup'}>
                                    {getFieldDecorator('holderInvest', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入举办方投入资金',
                                            },
                                        ],
                                    })(<InputNumber className={'input'} autocomplete="off" disabled={parent==='0'&&isNew==0} onChange={this.inputchange}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, '999,999,999')}
                                    />)}
                                    <span className={'text'}>可分配预算额度：{queryBudgetTaskQuota.holderInvest?queryBudgetTaskQuota.holderInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</span>
                                    {this.state.holderInvestTip === 1 ? <div className={'chaochutip'}>超出可分配预算额度，请核实后再继续！</div> : ''}
                                </Form.Item>
                            </div>
                            <div className={'group'}>
                                <lable className={'labelname'}>行业企业支持资金(人民币元）</lable>
                                <Form.Item className={'formgroup'}>
                                    {getFieldDecorator('enterpriseInvest', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入行业企业支持资金',
                                            },
                                        ],
                                    })(<InputNumber className={'input'} autocomplete="off"  disabled={parent==='0'&&isNew==0} onChange={this.inputchange}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, '999,999,999')}
                                    />)}
                                    <span className={'text'}>可分配预算额度：{queryBudgetTaskQuota.enterpriseInvest?queryBudgetTaskQuota.enterpriseInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</span>
                                    {this.state.enterpriseInvestTip === 1 ? <div className={'chaochutip'}>超出可分配预算额度，请核实后再继续！</div> : ''}
                                </Form.Item>
                            </div>
                            <div className={'group'}>
                                <lable className={'labelname'}>学校自筹资金(人民币元)</lable>
                                <Form.Item className={'formgroup'}>
                                    {getFieldDecorator('schoolInvest', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入学校自筹资金',
                                            },
                                        ],
                                    })(<InputNumber className={'input'} autocomplete="off" disabled={parent==='0'&&isNew==0} onChange={this.inputchange}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, '999,999,999')}
                                    />)}
                                    <span className={'text'}>可分配预算额度：{queryBudgetTaskQuota.schoolInvest?queryBudgetTaskQuota.schoolInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</span>
                                    {this.state.schoolInvestTip === 1 ? <div className={'chaochutip'}>超出可分配预算额度，请核实后再继续！</div> : ''}
                                </Form.Item>
                            </div>
                            <div className={'heji'}>
                                合计金额：<span className={'money'}>￥{amountInTotal?amountInTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0} </span>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>

        );
    }
}

BudgetsettingLeveltwo = Form.create()(BudgetsettingLeveltwo);
export default BudgetsettingLeveltwo;
