/*批量导入*/
import React, {Component} from "react";
import {Form, Steps, Upload,Button,Spin } from "antd";
import style from "./index.module.scss";
import {withRouter} from "react-router";
import {DownIcon, UploadFile,RemoveFile,UploadSuccess,RedError,GreenSuccess} from '../../../../../assets/svg'
import {meritExcelImportCheck,meritExcelImport,recordExcelImport} from '../../../../../api/merit/';
import {taskExcelImportCheck,taskExcelImport} from '../../../../../api/renwu/';
import {uploadFile} from '../../../../../api/file'
import Cookies from "js-cookie";
const { Step } = Steps;
@withRouter
class BulkImport extends Component {
    state = {
        fileList:[],
        fileListOne:null,
        current: 0,//0下载模板并上传Excel、1校验数据并导入
        progress: 0,  //0是上传文件之前  1是上传文件点确定验证  2是错误数据列表  3确定导入页 else导入成功
        code:this.props.code,
        file:'',
        errorList:[],
        totalCount:0,
        size:0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        projectId:this.props.projectId,
        resourceId:'',
    }

    // 初始化
    componentDidMount() {
    }
    beforeUpload = async (file) => {
        try {
            let key = Math.floor(Math.random()).toString(8) + new Date().getTime()
            const formdata = new FormData();
            formdata.append('file', file);
            formdata.append('folder', 'cbs')
            const fileNameindex = file.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = file.name.length; // 取到文件名长度
            const fileType = file.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                this.mesError('上传的资源格式不符')
                return
            }
            this.state.file=file
            console.log("this.state.file",this.state.file)
            console.log("file",file)
            let res = await uploadFile(formdata);
            formdata.append("fileId",key);
            this.setState({
                fileListOne:{fileName:res.originFileName,fileId:key},
                resourceId:res.id
            })
        } catch (e) {
        }

    }
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = ".xls,.xlsx"
        return checkType.includes(type)
    };
    //移除
    deleFildBook=()=>{
        this.setState({
            fileListOne:null
        })
    }
    //确定按钮
    bulkImportQd  = async  () =>{
        if(this.state.size >0){
            this.cancleBtn()
        }else {
            if (this.state.fileListOne === null) {
                this.mesError('请上传文件！')
            } else {
                this.setState({current: 1, progress: 1})
                // 校验
                const formdata = new FormData();
                formdata.append('file', this.state.file);
                if (this.state.code === 1) {
                    await meritExcelImportCheck(formdata).then(res => {
                        if (res.hasOwnProperty("meritExcelError")) {
                            this.setState({
                                current: 1,
                                progress: 2,
                                errorList: res.meritExcelError,
                                size: res.size,
                                totalCount: res.totalCount,
                            })
                        } else {
                            this.setState({
                                current: 1,
                                progress: 3,
                                size: res.size,
                                totalCount: res.totalCount,
                            })
                        }
                    })
                }
                if (this.state.code === 2) {
                    await taskExcelImportCheck(this.state.projectId, formdata).then(res => {
                        if (res.errorCount > 0) {
                            let errorList = []
                            for (let i = 0; i < res.taskImportErrorData.length; i++) {
                                errorList.push({
                                    count: res.taskImportErrorData[i].row,
                                    error: res.taskImportErrorData[i].describe
                                })
                            }
                            console.log('errorList', errorList)
                            this.setState({
                                current: 1,
                                progress: 2,
                                errorList: errorList,
                                size: res.errorCount,
                                totalCount: res.totalCount,
                            })

                        }
                        else{
                            this.setState({
                                current: 1,
                                progress: 3,
                                size: res.errorCount,
                                totalCount: res.totalCount,
                            })
                        }
                    })
                }
            }
        }

    }
    //确定导入
    confirmImport = ()=>{
        const formdata = new FormData();
        formdata.append('file', this.state.file);
        //绩效
        if(this.state.code === 1){
            let data={
                code:1,
                resourceId:this.state.resourceId,
            }
            recordExcelImport(data);
            meritExcelImport(formdata).then(res => {
                recordExcelImport(data);
                this.props.meritPageList();
                this.props.queryCatgoryById();
                this.cancleBtn();
                this.mesSuccess('导入成功');
            })

        }
        //任务
        if(this.state.code === 2){
            let data={
                code:2,
                resourceId:this.state.resourceId,
                projectId:this.state.projectId
            }
            taskExcelImport(this.state.projectId, formdata).then(res => {
                recordExcelImport(data);
                this.props.infoProjectById();
                this.props.getTaskPageList();
                this.cancleBtn();
                this.mesSuccess('导入成功');
            })
        }
    }
    //返回上一步
    returnBtn = ()=>{
        this.setState({
            current: 0,
            progress: 0,
        })
    }
    //取消操作
    cancleBtn=()=>{
        this.props.bulkImportCancel();//隐藏弹框
        this.setState({current: 0,progress: 0,size:0})//初始化流程
        this.deleFildBook();//删除添加的文件
    }

    render() {
        const {fileListOne,current,progress,errorList,size,totalCount} = this.state
        return (
            <div className={style.bulkimportwrap}>
                <div className={'bulk-import-top'}>
                    <Steps current={current}>
                        <Step key={0} title={'下载模板并上传Excel'} />
                        <Step key={1} title={'校验数据并导入'} />
                    </Steps>
                </div>
                {
                    progress === 0 ? <div className={'bulk-import-cont'}>
                        <div className={'cont-left'}>
                            {
                                this.state.code === 1 ?
                                    <a href={window.$$fujinDownload(window.$$meritDefaultFile)}>
                                        <div className={'downm-template'}>
                                            <DownIcon/>
                                            <span className={'text'}>下载模版</span>
                                        </div>
                                    </a>
                                    : this.state.code === 2 ?
                                    <a href={window.$$fujinDownload(window.$$taskDefaultFile)}>
                                        <div className={'downm-template'}>
                                            <DownIcon/>
                                            <span className={'text'}>下载模版</span>
                                        </div>
                                    </a> :
                                    <a>
                                        <div className={'downm-template'}>
                                            <DownIcon/>
                                            <span className={'text'}>下载模版</span>
                                        </div>
                                    </a>
                            }
                            <span className={'tipmessage'}>首先下载导入模板，填写信息</span>
                        </div>
                        <div className={'cont-left cont-right'}>
                            <div className={'downm-template'}>
                                <Upload
                                    fileList={[]}
                                    multiple={true}
                                    beforeUpload={this.beforeUpload}
                                    listType="picture-card"
                                    showUploadList={false}
                                    accept={'.xls,.xlsx'}>
                                    <UploadFile/>
                                    <span className={'text'}>上传文件</span>
                                </Upload>
                            </div>
                            <span className={'tipmessage'}>然后上传填好的文件</span>
                            {fileListOne&& <p className={'filenamewrap'}><UploadSuccess/><span className={'filename'}>{fileListOne.fileName}</span> <span className={'cha'} onClick={this.deleFildBook}><RemoveFile/></span> </p>}
                        </div>
                    </div> : progress === 1 ?
                        <div className={'bulk-import-cont'}>
                            <div className={'loadingwrap'}>
                                <Spin size="large" />
                                <div className={'loadfile'}>{fileListOne.fileName}</div>
                            </div>
                        </div> : progress === 2 ?
                        <div className={'bulk-import-cont'}>
                            <div className={'tipmessage'}>
                                <RedError/>
                                <span className={'text'}>注意：您一共上传  {totalCount}  条数据，共有 {size} 条错误，请修改后重新上传</span>
                                <div className={'simulatetable'}>
                                    <div className={'head'}>
                                        <div>行数</div>
                                        <div>错误类型</div>
                                    </div>
                                    <ul className={'ullist'}>
                                        {errorList?errorList.map((item, i) =>(
                                        <li>
                                            <div>{item.count}</div>
                                            <div>{item.error}</div>
                                        </li> )):""}
                                    </ul>
                                </div>
                            </div>
                        </div> : <div className={'successmessage'}><GreenSuccess/><span className={'text'}>您共有{this.state.totalCount}条数据准备导入</span></div>
                }
                <div className={'footerwrap'}>
                    {/*<Button className={'quxiao-btn'} onClick={()=>this.cancleBtn()}>取消</Button>*/}
                    {
                        this.state.progress === 3 ? <div><Button className={'quxiao-btn'} onClick={()=>this.returnBtn()}>返回上一步</Button><Button type={'primary'} onClick={()=>this.confirmImport()}>确定导入</Button> </div>
                            : <div><Button className={'quxiao-btn'} onClick={()=>this.cancleBtn()}>取消</Button><Button type={'primary'} onClick={()=>this.bulkImportQd()}>确定</Button></div>
                    }

                </div>
            </div>
        );
    }
}

BulkImport = Form.create()(BulkImport);
export default BulkImport;
