/*大屏展示任务支出饼图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';
import {queryProjectTaskExpendRate} from "../../../api/chart/index"

@withRouter
class screenTaskSpending extends Component {
    state = {
        cengMian:this.props.cengMianRate,
        expendAmountSum:0,
        data1:[],
        data2:[],
        datanull: false,
        rmb: 44360.64,
        schoolId: this.props.match.params.schoolId,

    }
    componentDidMount() {
        this.props.queryTaskExpendRate(this)
    }
    queryProjectTaskExpendRate = async (item) => {
        if (item){
            this.state.cengMian=item
        }
        let data = {
            cengMian:this.state.cengMian,
            chartType:this.props.chartProjectExpendType,
            chartId:this.props.chartProjectExpendId,
            schoolId:this.state.schoolId
        }
        await queryProjectTaskExpendRate(data).then(res => {
            if(res){
                let upChartProjectTaskExpendVo = res.upChartProjectTaskExpendVo
                let expendAmountSum = 0
                let data1 = []
                let data2 = []
                let before5Rate = 0
                let after5Amount = 0
                for (let i = 0; i < upChartProjectTaskExpendVo.length; i++) {
                    expendAmountSum += upChartProjectTaskExpendVo[i].amount
                    before5Rate += upChartProjectTaskExpendVo[i].rate
                    if(i<5){
                        data1.push({name: upChartProjectTaskExpendVo[i].projectName, icon: 'circle',amount:upChartProjectTaskExpendVo[i].amount,rate:upChartProjectTaskExpendVo[i].rate})
                        data2.push({value: upChartProjectTaskExpendVo[i].amount, name: upChartProjectTaskExpendVo[i].projectName})
                    }
                    else{
                        after5Amount += upChartProjectTaskExpendVo[i].amount
                    }
                }
                if(after5Amount>0){
                    data1.push({name: '其他', icon: 'circle',amount:after5Amount,rate:1-before5Rate})
                    data2.push({value: after5Amount, name: '其他'})
                }
                console.log('data2',data2)
                this.setState({
                    expendAmountSum:expendAmountSum,
                    data1:data1,
                    data2:data2,
                    rmb: data2[0] ? data2[0].value:''
                },()=>{
                    if(data2.length === 0){
                        this.screenTaskSpending()
                        this.setState({datanull: true})
                    }else{
                        this.setState({datanull: false},()=>{
                            this.screenTaskSpending()
                        })
                    }
                })
            }
        })
    }
    screenTaskSpending = () => {
        let rmb = 44360.64
        if(document.getElementById('screenTaskSpending')){
            let myChart = echarts.init(document.getElementById('screenTaskSpending'));
            // 绘制图表
            let
                option = {
                    tooltip: {
                        trigger: 'item',
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 550
                        },
                    },
                    color:['rgba(219, 145, 251, 1)','rgba(251, 145, 153, 1)','rgba(83, 231, 200, 1)','rgba(102, 72, 255, 1)','rgba(255, 228, 0, 1)','rgba(45, 153, 255, 1)'],
                    grid: {
                        top: '15%',
                        left: '0%',
                        right: '1%',
                        bottom: 5,
                    },
                    legend: {
                        type: "scroll",
                        top: '15%',
                        left: '50%',
                        orient: 'vertical',
                        selectedMode:false,
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        data: this.state.data1,
                        formatter: (name) => {
                            let data1 = this.state.data1
                            let rate = 0
                            let amount = 0
                            data1.map(item=>{
                                if(name === item.name){
                                    rate = item.rate
                                    amount = item.amount
                                }
                            })
                            rate = parseFloat(rate * 100).toFixed(2).replace(".00","")
                            return [
                                "{a|" +
                                echarts.format.truncateText(
                                    name,
                                    110,
                                    "14px",
                                    "…"//如果宽度超过110会出现...
                                ) +
                                "}",
                                "{e| |}",
                                "{b|" + rate + "%}",
                                "{c|￥" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','') + "}"  //a、b、x、跟下面的rich对应
                            ].join(" ");
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            color:'rgba(227, 73, 73, 1)',
                            //在rich中给formatter添加edittaskspendingpercent个别字体颜色
                            rich: {
                                a:{
                                    width: 120,
                                    fontSize: 14,
                                    color: '#FFFFFF',
                                    align: 'left'
                                },
                                b: {
                                    color: '#FFFFFF',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '#2384DD',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                                e:{
                                    color: '#129BFF'
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            center: ['30%','52%'],
                            radius: '80%',
                            avoidLabelOverlap: false,
                            label:{            //饼图图形上的文本标签
                                normal:{
                                    show:true,
                                    position:'inner', //标签的位置
                                    textStyle : {
                                        color: "#FFFFFF",
                                        fontSize : 14    //文字的字体大小
                                    },
                                    formatter:'{d}%'


                                }
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        return data.value
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.state.data2,
                            roseType: 'radius',
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal: {
                                    color: function(params) {
                                        let colorList = [
                                            {
                                                c1: ' #B558F6',
                                                c2: '#DB91FB'
                                            },
                                            {
                                                c1: ' #F65860',
                                                c2: '#FB9199'
                                            },
                                            {
                                                c1: ' #29CB97',
                                                c2: '#53E7C8'
                                            },
                                            {
                                                c1: ' #775CFD',
                                                c2: '#6648FF'
                                            },
                                            {
                                                c1: ' #FEC400',
                                                c2: '#FEC400'
                                            },
                                            {
                                                c1: '#5EB1FF',
                                                c2: '#2D99FF'
                                            }]
                                        return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{ //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                                            offset: 0,
                                            color: colorList[params.dataIndex].c1
                                        }, {
                                            offset: 1,
                                            color: colorList[params.dataIndex].c2
                                        }])
                                        /*  return colorList[params.dataIndex]*/
                                    }
                                }
                            }
                        }
                    ]
                };
            myChart.setOption(option)
            myChart.on("mouseover", this.eConsole);
            // myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    eConsole = (params) => {
        this.setState({rmb: params.data.value})
    }
    render() {
        return (
            <div style={{height: '100%', float: 'left',width: '100%'}}>
                {
                    this.state.datanull ? <div style={{height: '100%',width: '100%',color:'#FFFFFF',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'.16rem'}}>暂无数据</div> :
                        <div style={{height: '100%', float: 'left',width: '100%',position: 'relative'}}>
                            <div id={'screenTaskSpending'} className={'echarts'} style={{width: '100%', height: '100%',}}></div>
                            <span style={{position: 'absolute',top: '.1rem',left: '.2rem',color: '#FFFFFF',fontSize: '.14rem'}}>实际使用金额¥{this.state.rmb?this.state.rmb.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00',''):0}</span>
                        </div>
                }
            </div>

        );
    }
}

screenTaskSpending = Form.create()(screenTaskSpending);
export default screenTaskSpending;
