/*栏目管理*/
import React, {Component} from "react";
import moment from 'moment';
import {Input, Select, Modal, Form, Tabs,  DatePicker
} from "antd";
import "./addNewTask.scss";
import {withRouter} from "react-router";
import Cookies from "js-cookie";
import {getTaskSimpleById,getTaskProjectMemberList} from "../../../../../api/renwu"
const { TextArea } = Input;
@withRouter
class AddNewTask extends Component {
    state = {
        userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo : "",
        count:0,
        taskPriorityOptions:[
            {'label':'较低','value':1},
            {'label':'普通','value':2},
            {'label':'紧急','value':3},
            {'label':'非常紧急','value':4},
        ],
        taskPriorityDefault:2,
        taskStatusOptions:[
            {'label':'进行中','value':1},
            {'label':'已暂停','value':2},
            {'label':'已完成','value':3},
            {'label':'已逾期','value':4},
            {'label':'未开始','value':5},
            {'label':'未开始','value':0},
        ],
        taskInfo:this.props.taskInfo,
        beginTime:undefined,
        endTime:undefined,
        textAreaValue:'',
        taskId:this.props.taskId,
        projectId :this.props.projectId,
        projectName :this.props.projectName,
        taskChildId :this.props.taskChildId,
        taskFormOption :this.props.taskFormOption,//1编辑2新增3编辑子
        projectMemberList:[],
        director_id:'',
        isNew:0,
    };

    componentDidMount() {
        this.props.addNewTaskComponent(this)
        this.editFormData()
        this.getTaskProjectMemberList()
    }
    editFormData = ()=>{
        // 1.编辑任务、2.新增子任务，3.编辑子任务
        if(this.state.taskFormOption !== 2){
            let data = {}
            if(this.state.taskFormOption === 1){
                data={
                    id:this.state.taskId,
                    projectId:this.state.projectId,
                }
            }
            if(this.state.taskFormOption === 3){
                data={
                    id:this.state.taskChildId,
                    projectId:this.state.projectId,
                }
            }
            this.getTaskSimpleById(data)

        }else{
            this.props.form.setFieldsValue({
                projectName:this.state.projectName,
            })
            this.setState({isNew:1})
        }
    }
    getTaskSimpleById = async (data)=>{
        await getTaskSimpleById(data).then(res=>{
            this.setState({
                endTime:res.endTime?res.endTime.split("T")[0]:"",
                beginTime:res.beginTime?res.beginTime.split("T")[0]:"",
                isNew:res.isNew,
                count:res.taskDescribe?res.taskDescribe.length:0
            })
            this.props.form.setFieldsValue({
                projectName:res.projectName,
                taskName:res.taskName,
                taskDescribe:res.taskDescribe,
                taskPriority:res.taskPriority,

            })
            if(res.directorId){
                this.props.form.setFieldsValue({
                    directorId:res.directorId,
                })
            }
        })
    }
    // 查询建设任务成员列表
    getTaskProjectMemberList = async ()=>{
        let data = {
            projectId:this.state.projectId,
        }
        await getTaskProjectMemberList(data).then(res=>{
            this.setState({projectMemberList:res})
        })
    }
    marksClick(e){
        this.setState({
            count:e.target.value.length
        })
    }
// 开始日期
    onStartChange = value => {
        this.onChange('beginTime', value);
        let date = this.props.form.getFieldValue('date')
    };

    //结束日期
    onEndChange = value => {
        this.onChange('endTime', value);
    };

    disabledStartDate = beginTime => {
        const { endTime } = this.state;
        if (!beginTime || !endTime) {
            return false;
        }
        return beginTime.valueOf() > endTime.valueOf();
    };
    disabledEndDate = endTime => {
        const { beginTime } = this.state;
        if (!endTime || !beginTime) {
            return false;
        }
        return endTime.valueOf() <= beginTime.valueOf();
    };





    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };
    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };
    // 日期赋值
    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };
    getFormValue = ()=>{
        if(this.state.beginTime && !this.state.endTime){
            this.mesWarning("请填入结束时间")
            return undefined
        }
        if(!this.state.beginTime && this.state.endTime){
            this.mesWarning("请填入开始时间")
            return undefined
        }
        let endTime = null
        if(this.state.endTime){
            if(typeof this.state.endTime == "string"){
                endTime = this.state.endTime + " 23:59:59"
            }else{
                endTime = this.state.endTime.format('YYYY-MM-DD 23:59:59')
            }
        }else{
            endTime = ""
        }
        let beginTime = null
        if(this.state.beginTime ){
            if(typeof this.state.beginTime == "string"){
                beginTime = this.state.beginTime + " 00:00:01"
            }else{
                beginTime = this.state.beginTime.format('YYYY-MM-DD 00:00:01')
            }
        }else{
            beginTime = ""
        }

        let data = {
            projectId:this.state.projectId,
            taskName : this.props.form.getFieldValue('taskName'),
            taskPriority : this.props.form.getFieldValue('taskPriority'),
            beginTime : beginTime,
            endTime : endTime,
            directorId : this.props.form.getFieldValue('directorId')?this.props.form.getFieldValue('directorId'):"",
            taskDescribe: this.props.form.getFieldValue('taskDescribe'),
            id:this.state.taskFormOption===3?this.state.taskChildId:this.state.taskId,
        }
         this.props.form.validateFields((err, values) => {
            if (err) {
                return data=undefined
            }
        });
        return data

    }
    clearFormValue = ()=>{
        this.props.form.setFieldsValue({
            projectName:"",
            taskName:"",
            directorId:"",
            taskDescribe:"",
            taskPriority:"",
        })
    }
    onUserChange = (val)=>{
        this.setState({director_id: val})
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let {count,textAreaValue,taskPriorityOptions
            ,beginTime,endTime,endOpen} = this.state;

        const style={
            position: "absolute",
            bottom: "-5px",
            right: "9px"}


        return (
            <div className='quanxiansz_center'>
                {/*新建任务*/}

                    <Form labelCol={{ span: 4 }} wrapperCol={{ span: 17 }} colon={false}>
                        <Form.Item label="建设任务名称">
                            <label>{this.state.projectName}</label>
                        </Form.Item>

                        <Form.Item label="任务名称">
                            {
                                getFieldDecorator('taskName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '任务名称不能为空'
                                        },
                                        {
                                            type: 'string',
                                            min: 2,
                                            max: 200,
                                            message: '任务名称不能小于2或大于200字符!',
                                        },
                                    ]
                                })(<Input
                                    placeholder="请输入任务名称"
                                    autocomplete="off"
                                    disabled={this.state.isNew === 0}
                                />)
                            }
                        </Form.Item>
                        <Form.Item label="优先级"  className='taskPriorityForm'>
                            {
                                getFieldDecorator('taskPriority', {
                                })(<Select
                                    dropdownClassName='taskPriorityOption'
                                    placeholder="请选择优先级"
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {
                                        taskPriorityOptions&&taskPriorityOptions.map(item=>

                                            <Select.Option
                                                className='taskPriorityOptionOO'
                                                value={item.value}>{item.label}</Select.Option>

                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label="日程规划">
                            {
                                getFieldDecorator('date', {

                                })(<div >
                                    <DatePicker
                                        style={{'display':'inline-block','width':'197px','min-width': '197px'}}
                                        disabledDate={this.disabledStartDate}
                                        format="YYYY-MM-DD"
                                        value={beginTime? moment(beginTime):undefined}

                                        placeholder="请选择日期"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                    />
                                    <span style={{'display':'inline-block','width':'38px',   'textAlign':'center'}}>至</span>
                                    <DatePicker
                                        style={{'display':'inline-block','width':'197px','min-width': '197px'}}
                                        disabledDate={this.disabledEndDate}
                                        format="YYYY-MM-DD"
                                        value={endTime?moment(endTime):endTime}
                                        placeholder="请选择日期"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                    />
                                </div>)
                            }
                        </Form.Item>

                        <Form.Item label="负责人">
                            {
                                getFieldDecorator('directorId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '负责人不能为空'
                                        },
                                    ]
                                })(<Select
                                    dropdownClassName='taskPriorityOption'
                                    defaultValue=""
                                    placeholder="请选择负责人"
                                    allowClear={true}
                                    onChange={(e)=>this.onUserChange(e)}
                                    showSearch="true"
                                    optionFilterProp="children"
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {
                                        this.state.projectMemberList && this.state.projectMemberList.map(item=>
                                            <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>



                        <Form.Item label="描述">
                            <div className={'textare-wrap'}>
                            {
                                getFieldDecorator('taskDescribe')(

                                        <TextArea maxLength={500}
                                                  placeholder="请输入任务描述"
                                                  style={{ resize: 'none',height:'158px'}}
                                                  onChange={(e)=>this.marksClick(e)}
                                                  defaultValue={textAreaValue}
                                                  rows={5}
                                        />

                                )

                            }
                            <div className="user-management-count">{count}/500</div>
                            </div>
                        </Form.Item>
                    </Form>




            </div>

        );
    }
}

AddNewTask = Form.create()(AddNewTask);
export default AddNewTask;
