import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'antd/dist/antd.css';
import './reset_style/index.scss';
import store from './store'
import { Provider } from 'react-redux'
import { getBaseUrl } from "./api/Baseurl";
import '@/components/global_components/confirm/index.scss'
import '@/components/global_components/confirm/index.js'
function create(src) {
    return function (path) {
        if(!path)return;
        if (path[0] === '/') {
            return src + path.slice(1);
        } else {
            return src + path;
        }
    }
}
getBaseUrl().then(res => {
    window.$$uploadRc = create(res.uploadRc);
    window.$$uploadDoc = create(res.uploadDoc);
    window.$$imgSrc = create(res.imgSrc);
    window.$$imgSrcLcl = res.imgSrc;
    window.$$setImgSrc = create(res.staticImg);
    window.$$fujinDownload = create(res.fujinDownload);
    window.$$previewUrl = create(res.previewUrl);//任务书下载、建设方案下载、导出pdf
    window.$$Dh = create(res.dh);//任务书/建设任务书查看/下载绩效
    window.$$DhYear = create(res.dhYear);//年度绩效查看/下载
    window.$$taskDefaultFile = res.taskDefaultFile//导入任务模板
    window.$$meritDefaultFile = res.meritDefaultFile//导入绩效模板
    window.$$sgPreview = create(res.sgPreview)//部级预览

    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>
        ,
        document.getElementById('root')
    );
});
serviceWorker.unregister();
