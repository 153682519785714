/*专家评审查看打分*/
import React, {Component} from 'react';
import {Button, Form, Tabs, InputNumber, Popover, message, Input} from 'antd';
import {
    getExpertRatingDirectory, saveTwoLevelIndicator
} from '../../../../api/doublehigh';
import {fillInYear} from "../../../../config/secret";
import userInfo from "../../../../store/reducers/userInfo";
const { TabPane } = Tabs;
function getNumber(a, b) {
    var val = Number(a) + Number(b);

    if(!isNaN(parseFloat(val))) {
        val = val.toFixed(2);
    }
    return  val;
}
class ComponForm extends React.Component {
    state = {
        saveStatus: true,
        leftList:this.props.leftList,
        data: this.props.i,
        userMark: '',
    };
    componentDidMount() {

    }
    //计算合计
    countTotal(arr, keyName) {
        let $total = 0;
        $total = arr.reduce(function (total, currentValue, currentIndex, arr){
            return currentValue[keyName] ? (total + currentValue[keyName]) : total;
        }, 0);
        return $total;
    }

    progressScoreChange = (e, item, two) => {
        /* 不等于7就是专家 */
        // if(this.state.userMark !== 7){
        console.log('twotwotwo 999',two)
        let max = two.indicatorLevelId === '2.5.1.1' || two.indicatorLevelId === '3.5.1.1' || two.indicatorLevelId === '4.5.1.1' ? 28 :
            two.indicatorLevelId === '2.5.1.3' || two.indicatorLevelId === '3.5.1.3' || two.indicatorLevelId === '4.5.1.3' ? 10 :
                two.indicatorLevelId === '2.5.2.1' || two.indicatorLevelId === '3.5.2.1' || two.indicatorLevelId === '4.5.2.1' ? 10.5 :
                    two.indicatorLevelId === '2.5.2.2' || two.indicatorLevelId === '3.5.2.2' || two.indicatorLevelId === '4.5.2.2' ? 10.5 :
                        two.indicatorLevelId === '2.5.3.1' || two.indicatorLevelId === '3.5.3.1' || two.indicatorLevelId === '4.5.3.1' ? 7 :''
        console.log('max 99', max)
        const {leftList} = this.state
        if(e < 0 || e > max){
            // e = null

            console.log('this.props.form 99 ', this.props.form)
            this.forceUpdate(() =>{
                for (let i = 0; i <leftList.length; i++) {
                    if (leftList[i].id === item.id) {
                        let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                        for (let y = 0; y <expertEvaluationVoList.length; y++) {
                            if (expertEvaluationVoList[y].id === two.id) {
                                if(this.state.userMark === 7){
                                    expertEvaluationVoList[y].progressScore = null
                                    expertEvaluationVoList[y].totalScore = parseFloat((Number(expertEvaluationVoList[y].horizontalScore)).toFixed(2))
                                } else {
                                    expertEvaluationVoList[y].progressScore = expertEvaluationVoList[y].progressScoreReference
                                    expertEvaluationVoList[y].totalScore = parseFloat((Number((expertEvaluationVoList[y].progressScoreReference) + (expertEvaluationVoList[y].horizontalScore))).toFixed(2))
                                }
                                if(this.state.userMark === 7){
                                    this.props.form.setFieldsValue({
                                        progressScore: null
                                    })
                                } else {
                                    this.props.form.setFieldsValue({
                                        progressScore: expertEvaluationVoList[y].progressScoreReference
                                    })
                                }
                            }
                        }
                        leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
                        console.log('leftList[i].totalScore ==99=', leftList[i].totalScore)
                    }
                }

            });
            let _this = this
            _this.setState({
                leftList
            })
            _this.props.saveLeftList(leftList)
        } else {
            console.log('ee 99 ==', e)
            let r = /^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/;
            for (let i = 0; i <leftList.length; i++) {
                if (leftList[i].id === item.id) {
                    let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                    for (let y = 0; y <expertEvaluationVoList.length; y++) {
                        if (expertEvaluationVoList[y].id === two.id) {
                            expertEvaluationVoList[y].progressScore = e
                            console.log('eeeeeeeeeeeeeeeeeeeeee 99', e)
                            console.log('expertEvaluationVoList[y].horizontalScore', expertEvaluationVoList[y].horizontalScore)
                            if (e !== null && expertEvaluationVoList[y].horizontalScore !== null && r.test(expertEvaluationVoList[y].horizontalScore) && r.test(e)) {
                                console.log('第一个判断 99')
                                expertEvaluationVoList[y].totalScore = parseFloat((Number(e) + Number(expertEvaluationVoList[y].horizontalScore)).toFixed(2))
                            }
                                // else if(e && r.test(e)){
                                //     expertEvaluationVoList[y].totalScore = Number(e)
                            // }
                            else if(!e && expertEvaluationVoList[y].horizontalScore !== null && r.test(expertEvaluationVoList[y].horizontalScore)){
                                console.log('第二个判断 99')
                                expertEvaluationVoList[y].totalScore = parseFloat(Number(expertEvaluationVoList[y].horizontalScore)).toFixed(2)
                            } else if(e !== null && r.test(e) && expertEvaluationVoList[y].horizontalScore === null){
                                expertEvaluationVoList[y].totalScore = Number(e)
                            } else if(!e && expertEvaluationVoList[y].horizontalScore === null){
                                console.log('是不是走这里了 99', e)
                                expertEvaluationVoList[y].totalScore = 0
                            }
                        }
                    }
                    // leftList[i].totalScore = (expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0))
                    leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
                }
            }
            console.log('leftList--99', leftList)
            this.setState({
                leftList
            })
            this.props.saveLeftList(leftList)
        }

        // } else {
        //     console.log('twotwotwo 999',two)
        //     const {leftList} = this.state
        //     let r = /^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/;
        //     for (let i = 0; i <leftList.length; i++) {
        //         if (leftList[i].id === item.id) {
        //             let expertEvaluationVoList = leftList[i].expertEvaluationVoList
        //             for (let y = 0; y <expertEvaluationVoList.length; y++) {
        //                 if (expertEvaluationVoList[y].id === two.id) {
        //                     expertEvaluationVoList[y].progressScore = e
        //                     console.log('eeeeeeeeeeeeeeeeeeeeee', e)
        //                     console.log('expertEvaluationVoList[y].horizontalScore', expertEvaluationVoList[y].horizontalScore)
        //                     if (e !== null && expertEvaluationVoList[y].horizontalScore !== null && r.test(expertEvaluationVoList[y].horizontalScore) && r.test(e)) {
        //                         console.log('第一个判断')
        //                         expertEvaluationVoList[y].totalScore = parseFloat((Number(e) + Number(expertEvaluationVoList[y].horizontalScore)).toFixed(2))
        //                     }
        //                         // else if(e && r.test(e)){
        //                         //     expertEvaluationVoList[y].totalScore = Number(e)
        //                     // }
        //                     else if(!e && expertEvaluationVoList[y].horizontalScore !== null && r.test(expertEvaluationVoList[y].horizontalScore)){
        //                         console.log('第二个判断')
        //                         expertEvaluationVoList[y].totalScore = parseFloat(Number(expertEvaluationVoList[y].horizontalScore)).toFixed(2)
        //                     } else if(e !== null && r.test(e) && expertEvaluationVoList[y].horizontalScore === null){
        //                         expertEvaluationVoList[y].totalScore = Number(e)
        //                     } else if(!e && expertEvaluationVoList[y].horizontalScore === null){
        //                         console.log('是不是走这里了', e)
        //                         expertEvaluationVoList[y].totalScore = 0
        //                     }
        //                 }
        //             }
        //             // leftList[i].totalScore = (expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0))
        //             leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
        //         }
        //     }
        //     this.setState({
        //         leftList
        //     })
        //     this.props.saveLeftList(leftList)
        // }
    }
    horizontalScoreChange = (e, item, two) => {
        console.log('horizontalScoreChange 999',two)
        const {leftList} = this.state
        // if(this.state.userMark !== 7){

        console.log('twotwotwo 999',two)
        let max = two.indicatorLevelId === '2.5.1.1' || two.indicatorLevelId === '3.5.1.1' || two.indicatorLevelId === '4.5.1.1' ? 12  :
            two.indicatorLevelId === '2.5.2.1' || two.indicatorLevelId === '3.5.2.1' || two.indicatorLevelId === '4.5.2.1' ? 4.5 :
                two.indicatorLevelId === '2.5.2.2' || two.indicatorLevelId === '3.5.2.2' || two.indicatorLevelId === '4.5.2.2' ? 4.5 :
                    two.indicatorLevelId === '2.5.3.1' || two.indicatorLevelId === '3.5.3.1' || two.indicatorLevelId === '4.5.3.1' ? 3  :
                        two.indicatorLevelId === '2.5.4.1' || two.indicatorLevelId === '2.5.4.2' ||
                        two.indicatorLevelId === '3.5.4.1' || two.indicatorLevelId === '3.5.4.2' ||
                        two.indicatorLevelId === '4.5.4.1' || two.indicatorLevelId === '4.5.4.2' ? 5 : ''
        console.log('max 99', max)
        if(e < 0 || e > max){

            console.log('this.props.form 99 ', this.props.form)
            this.forceUpdate(() =>{
                this.props.form.setFieldsValue({
                    horizontalScore: null
                })
                for (let i = 0; i <leftList.length; i++) {
                    if (leftList[i].id === item.id) {
                        let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                        for (let y = 0; y <expertEvaluationVoList.length; y++) {
                            if (expertEvaluationVoList[y].id === two.id) {
                                expertEvaluationVoList[y].horizontalScore = null
                                expertEvaluationVoList[y].totalScore = parseFloat((Number(expertEvaluationVoList[y].progressScore)).toFixed(2))
                            }
                        }
                        leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
                    }
                }
            });
            let _this = this
            _this.setState({
                leftList
            })
            _this.props.saveLeftList(leftList)
        } else {
            let r = /^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/;
            for (let i = 0; i <leftList.length; i++) {
                if (leftList[i].id === item.id) {
                    let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                    for (let y = 0; y <expertEvaluationVoList.length; y++) {
                        if (expertEvaluationVoList[y].id === two.id) {
                            expertEvaluationVoList[y].horizontalScore = e
                            // expertEvaluationVoList[y].totalScore = (Number(e) + Number(expertEvaluationVoList[y].progressScore)).toFixed(2)
                            if (e !== null && expertEvaluationVoList[y].progressScore !== null && r.test(expertEvaluationVoList[y].progressScore) && r.test(e)) {
                                expertEvaluationVoList[y].totalScore = parseFloat((Number(e) + Number(expertEvaluationVoList[y].progressScore)).toFixed(2))
                            }
                                // else if(e && r.test(e)){
                                //     expertEvaluationVoList[y].totalScore = Number(e)
                            // }
                            else if(!e && expertEvaluationVoList[y].progressScore !== null && r.test(expertEvaluationVoList[y].progressScore)){
                                expertEvaluationVoList[y].totalScore = parseFloat(Number(expertEvaluationVoList[y].progressScore)).toFixed(2)
                            } else if(e !== null && r.test(e) && expertEvaluationVoList[y].progressScore === null){
                                expertEvaluationVoList[y].totalScore = Number(e)
                            } else {
                                expertEvaluationVoList[y].totalScore = 0
                            }
                        }
                    }
                    leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
                }
            }
            this.setState({
                leftList
            })
            this.props.saveLeftList(leftList)
        }

        // } else {
        //     let r = /^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/;
        //     for (let i = 0; i <leftList.length; i++) {
        //         if (leftList[i].id === item.id) {
        //             let expertEvaluationVoList = leftList[i].expertEvaluationVoList
        //             for (let y = 0; y <expertEvaluationVoList.length; y++) {
        //                 if (expertEvaluationVoList[y].id === two.id) {
        //                     expertEvaluationVoList[y].horizontalScore = e
        //                     // expertEvaluationVoList[y].totalScore = (Number(e) + Number(expertEvaluationVoList[y].progressScore)).toFixed(2)
        //                     if (e !== null && expertEvaluationVoList[y].progressScore !== null && r.test(expertEvaluationVoList[y].progressScore) && r.test(e)) {
        //                         expertEvaluationVoList[y].totalScore = parseFloat((Number(e) + Number(expertEvaluationVoList[y].progressScore)).toFixed(2))
        //                     }
        //                         // else if(e && r.test(e)){
        //                         //     expertEvaluationVoList[y].totalScore = Number(e)
        //                     // }
        //                     else if(!e && expertEvaluationVoList[y].progressScore !== null && r.test(expertEvaluationVoList[y].progressScore)){
        //                         expertEvaluationVoList[y].totalScore = parseFloat(Number(expertEvaluationVoList[y].progressScore)).toFixed(2)
        //                     } else if(e !== null && r.test(e) && expertEvaluationVoList[y].progressScore === null){
        //                         expertEvaluationVoList[y].totalScore = Number(e)
        //                     } else {
        //                         expertEvaluationVoList[y].totalScore = 0
        //                     }
        //                 }
        //             }
        //             leftList[i].totalScore = parseFloat((expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2))
        //         }
        //     }
        //     this.setState({
        //         leftList
        //     })
        //     this.props.saveLeftList(leftList)
        // }

    }
    twoLevel =(i, item)=>{
        this.props.twoLevel(i, item)
    }
    saveTwoLevelIndicator =(scoreType,expertsScore) => {
        console.log('baocun 99 expertsScore', expertsScore)
        /* scoreType = 1 是完成分  = 2 是水平分*/
        let {saveStatus, data} = this.state
        this.props.form.validateFields((err, values) => {
            if (saveStatus) {
                // if(this.state.userMark !== 7){
                //     if(values.progressScore){
                console.log('走保存这个了吗 progressScore 88')
                let progressScoreMax = data.indicatorLevelId === '2.5.1.1' || data.indicatorLevelId === '3.5.1.1' || data.indicatorLevelId === '4.5.1.1' ? 28 :
                    data.indicatorLevelId === '2.5.1.3' || data.indicatorLevelId === '3.5.1.3' || data.indicatorLevelId === '4.5.1.3' ? 10 :
                        data.indicatorLevelId === '2.5.2.1' || data.indicatorLevelId === '3.5.2.1' || data.indicatorLevelId === '4.5.2.1' ? 10.5 :
                            data.indicatorLevelId === '2.5.2.2' || data.indicatorLevelId === '3.5.2.2' || data.indicatorLevelId === '4.5.2.2' ? 10.5 :
                                data.indicatorLevelId === '2.5.3.1' || data.indicatorLevelId === '3.5.3.1' || data.indicatorLevelId === '4.5.3.1' ? 7 :''
                if(values.progressScore < 0 || values.progressScore > progressScoreMax){
                    values.progressScore = null
                }
                // }
                // if(values.horizontalScore) {
                console.log('走保存这个了吗 horizontalScore 88')
                let horizontalScoreMax = data.indicatorLevelId === '2.5.1.1' || data.indicatorLevelId === '3.5.1.1' || data.indicatorLevelId === '4.5.1.1' ? 12 :
                    data.indicatorLevelId === '2.5.2.1' || data.indicatorLevelId === '3.5.2.1' || data.indicatorLevelId === '4.5.2.1' ? 4.5 :
                        data.indicatorLevelId === '2.5.2.2' || data.indicatorLevelId === '3.5.2.2' || data.indicatorLevelId === '4.5.2.2' ? 4.5 :
                            data.indicatorLevelId === '2.5.3.1' || data.indicatorLevelId === '3.5.3.1' || data.indicatorLevelId === '4.5.3.1' ? 3 :
                                data.indicatorLevelId === '2.5.4.1' || data.indicatorLevelId === '2.5.4.2' ||
                                data.indicatorLevelId === '3.5.4.1' || data.indicatorLevelId === '3.5.4.2' ||
                                data.indicatorLevelId === '4.5.4.1' || data.indicatorLevelId === '4.5.4.2' ? 5 : ''
                if(values.horizontalScore < 0 || values.horizontalScore > horizontalScoreMax){
                    values.horizontalScore = null
                }
                // }
                // }
                console.log('values.progressScore 88', values.progressScore)
                console.log('values.horizontalScore 88', values.horizontalScore)
                this.setState({saveStatus: false})
                // let data = {id:values.id, horizontalScore:values.horizontalScore, progressScore:values.progressScore}
                data.horizontalScore = values.horizontalScore
                data.progressScore = values.progressScore
                data.scoreType = scoreType
                data.expertsScore = expertsScore
                console.log('datadatadatadata 88', data)
                saveTwoLevelIndicator(data).then(res =>{
                    if(res){
                        this.setState({
                            saveStatus: true
                        },() =>{
                            let _this = this
                            _this.props.getExpertRatingDirectory2();
                        })
                    }
                })
            }
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        const {} = this.state
        let {i,index,k,item,onId,isResult,userMark,expertsScore} = this.props
        // console.log('iiiiiiiiiiiii 99',i)
        return (
            <div className={'div'}>
                <div className={i.indicatorLevelName === '数量指标'?'li li_oli':i.indicatorLevelName === '质量指标'?'li li_lione':'li'}>
                    {
                        userMark === 7?<span className={onId === i.indicatorLevelId ? 'span_first active' : 'span_first'} style={{cursor:'no-drop'}}>
                            <i >{index+1}.{k+1}{i.indicatorLevelName}</i>
                    </span>:
                            <span className={onId === i.indicatorLevelId ? 'span_first active' : 'span_first'} onClick={() => this.twoLevel(i,item)}>
                            <i >{index+1}.{k+1}{i.indicatorLevelName}</i>
                    </span>
                    }

                    {
                        i.indicatorLevelName === '数量指标'?(
                            <div className={'font_li font_li_on'}>
                                <div className={'form_li form_li_on'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.mixScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li form_li_on">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('progressScore', {*/}
                                {/*            initialValue: i.progressScore,*/}
                                {/*            // rules: [*/}
                                {/*            //     {*/}
                                {/*            //         required: i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            //                   i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            //                   i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ? false : true,*/}
                                {/*            //         pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*            //         message: '只可填数字,保留2位小数'*/}
                                {/*            //     }*/}
                                {/*            // ]*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                // <InputNumber onBlur={()=>{this.saveTwoLevelIndicator(1,expertsScore)}} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                //              parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0}*/}
                                {/*                //              max={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? 28 : ''}*/}
                                {/*                //              onChange={(e)=>this.progressScoreChange(e, item, i)}*/}
                                {/*                // />*/}
                                {/*                '':*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(1,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? 28 : ''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? "0-28" : ''}*/}
                                {/*                             title={i.progressScore?"":'请打分'}*/}
                                {/*                             onChange={(e)=>this.progressScoreChange(e, item, i)}*/}
                                {/*                />*/}

                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}
                            </div>
                        ):i.indicatorLevelName === '质量指标'?<div className={'font_li'}><Form.Item className="form_li"></Form.Item></div>:(
                            <div className={'font_li'}>
                                <div className={'form_li'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.mixScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('progressScore', {*/}
                                {/*            initialValue: i.progressScore,*/}
                                {/*            // rules: [*/}
                                {/*            //     {*/}
                                {/*            //         required: i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            //                   i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            //                   i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ? false : true,*/}
                                {/*            //         pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*            //         message: '只可填数字,保留2位小数'*/}
                                {/*            //     }*/}
                                {/*            // ]*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                <InputNumber onBlur={()=>{this.saveTwoLevelIndicator(1,expertsScore)}} className={'form_li_des'} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={100}*/}
                                {/*                             onChange={(e)=>this.progressScoreChange(e, item, i)}/> :*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(1,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ? 10 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? 10.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? 10.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? 7 :''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ? "0-10" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? "0-10.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? "0-10.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? "0-7" :''}*/}
                                {/*                             title={i.progressScore?"":'请打分'} onChange={(e)=>this.progressScoreChange(e, item, i)}/>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}
                            </div>
                        )
                    }
                    {
                        i.indicatorLevelName === '数量指标'?(
                            <div className={'font_li font_li_on'}>
                                <div className={'form_li form_li_on'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.minScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li form_li_on">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('horizontalScore', {*/}
                                {/*            initialValue: i.horizontalScore,*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    // required: i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3'  || i.indicatorLevelId === '4.5.1.3' ? false : true,*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ?*/}
                                {/*            <InputNumber onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                         parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                         // min={1}*/}
                                {/*                         // max={100}*/}
                                {/*                         onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*            i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false}  onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={5}*/}
                                {/*                             placeholder={'0-5'} title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? 12 : ''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? "0-12" : ''}*/}
                                {/*                             title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}

                            </div>
                        ):i.indicatorLevelName === '质量指标'?<div className={'font_li'}><Form.Item className="form_li"></Form.Item></div>:(
                            <div className={'font_li'}>
                                <div className={'form_li'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.minScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('horizontalScore', {*/}
                                {/*            initialValue: i.horizontalScore,*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    // required: i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3'  || i.indicatorLevelId === '4.5.1.3' ? false : true,*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ?*/}
                                {/*            <InputNumber className={'form_li_des'} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                         parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                         // min={1} max={100}*/}
                                {/*                         onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*            i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false}  onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0} max={5}*/}
                                {/*                             placeholder={'0-5'} title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? 4.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? 4.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? 3 :''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? "0-4.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? "0-4.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? "0-3" :''}*/}
                                {/*                             title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}

                            </div>
                        )
                    }
                    {
                        i.indicatorLevelName === '数量指标'?(
                            <div className={'font_li font_li_on'}>
                                <div className={'form_li form_li_on'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.medianScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li form_li_on">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('horizontalScore', {*/}
                                {/*            initialValue: i.horizontalScore,*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    // required: i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3'  || i.indicatorLevelId === '4.5.1.3' ? false : true,*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ?*/}
                                {/*            <InputNumber onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                         parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                         // min={1}*/}
                                {/*                         // max={100}*/}
                                {/*                         onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*            i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false}  onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={5}*/}
                                {/*                             placeholder={'0-5'} title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? 12 : ''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.1.1' || i.indicatorLevelId === '3.5.1.1' || i.indicatorLevelId === '4.5.1.1' ? "0-12" : ''}*/}
                                {/*                             title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}

                            </div>
                        ):i.indicatorLevelName === '质量指标'?<div className={'font_li'}><Form.Item className="form_li"></Form.Item></div>:(
                            <div className={'font_li'}>
                                <div className={'form_li'}>
                                    <span className={'ant-input-number'}>
                                        <i className={'ant-input-number-input'}>{i.medianScore}</i>
                                    </span>
                                </div>
                                {/*<Form.Item className="form_li">*/}
                                {/*    {*/}
                                {/*        getFieldDecorator('horizontalScore', {*/}
                                {/*            initialValue: i.horizontalScore,*/}
                                {/*            rules: [*/}
                                {/*                {*/}
                                {/*                    // required: i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3'  || i.indicatorLevelId === '4.5.1.3' ? false : true,*/}
                                {/*                    required: false,*/}
                                {/*                    pattern:new RegExp(/^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/,"g"),*/}
                                {/*                    message: '只可填数字,保留2位小数'*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        })(i.indicatorLevelId === '2.5.1.3' || i.indicatorLevelId === '3.5.1.3' || i.indicatorLevelId === '4.5.1.3' ?*/}
                                {/*            <InputNumber className={'form_li_des'} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} disabled={true} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                         parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                         // min={1} max={100}*/}
                                {/*                         onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*            i.indicatorLevelId === '2.5.4.1' || i.indicatorLevelId === '2.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '3.5.4.1' || i.indicatorLevelId === '3.5.4.2' ||*/}
                                {/*            i.indicatorLevelId === '4.5.4.1' || i.indicatorLevelId === '4.5.4.2' ?*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false}  onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0} max={5}*/}
                                {/*                             placeholder={'0-5'} title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/> :*/}
                                {/*                <InputNumber disabled={isResult === 1?true:false} onBlur={()=>{this.saveTwoLevelIndicator(2,expertsScore)}} step={0.1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*                             parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                                {/*                             // min={0}*/}
                                {/*                             // max={i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? 4.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? 4.5 :*/}
                                {/*                             //      i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? 3 :''}*/}
                                {/*                             placeholder={i.indicatorLevelId === '2.5.2.1' || i.indicatorLevelId === '3.5.2.1' || i.indicatorLevelId === '4.5.2.1' ? "0-4.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.2.2' || i.indicatorLevelId === '3.5.2.2' || i.indicatorLevelId === '4.5.2.2' ? "0-4.5" :*/}
                                {/*                                          i.indicatorLevelId === '2.5.3.1' || i.indicatorLevelId === '3.5.3.1' || i.indicatorLevelId === '4.5.3.1' ? "0-3" :''}*/}
                                {/*                             title={i.horizontalScore?"":'请打分'} onChange={(e)=>this.horizontalScoreChange(e, item, i)}/>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Form.Item>*/}

                            </div>
                        )
                    }
                    {
                        i.indicatorLevelName === '数量指标'?(
                            <span className={'span_inputSize font_li_on'}>
                                <i>{i.schoolScore}</i>
                            </span>
                        ):i.indicatorLevelName === '质量指标'? <span className={'span_inputSize'}></span>:(
                            <span className={'span_inputSize'}>
                                <i>{i.schoolScore}</i>
                            </span>
                        )
                    }
                </div>
            </div>
        );
    }
}

ComponForm = Form.create()(ComponForm);
export default ComponForm;
