/*资金总体情况*/
import React from 'react'
import ReactEcharts from 'echarts-for-react';
import {getOverallFundSituation} from "../../../../api/doublehigh/index"

class GeneralTwo extends React.Component {
    state = {
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('执行率 888', nextProps)
        this.setState({
            ratePerformance: nextProps.overallFundSituationInfo.ratePerformance
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    generalTwo = () => { // 饼状图
        const {} = this.state
        return {
            tooltip: {
                formatter: "{a} <br/>{b} : {c}"
            },
            series: [{
                //类型
                type: 'gauge',
                //半径
                //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
                startAngle: 180,
                //结束角度。
                endAngle: 0,
                center: ["50%", "70%"],
                radius: '110%',
                //仪表盘轴线相关配置。
                axisLine: {
                    show: true,
                    // 属性lineStyle控制线条样式
                    lineStyle: {
                        width: 50,
                        color: [
                            [0.23, 'rgba(88, 139, 247, 1)'],
                            [1, 'rgba(147, 189, 237, 1)']
                        ]
                    }
                },
                //分隔线样式。
                splitLine: {
                    show: false,
                },
                //刻度样式。
                axisTick: {
                    show: false,
                },
                //刻度标签。
                axisLabel: {
                    show: false,
                },
                //仪表盘指针。
                pointer: {
                    //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
                    show: false,
                    //指针长度
                    length: '90%',
                    width: 0,
                },
                //仪表盘标题。
                title: {
                    show: true,
                    offsetCenter: [0, '0%'], // x, y，单位px
                    textStyle: {
                        color: 'rgba(51, 51, 51, 1)',
                    }
                },

                //仪表盘详情，用于显示数据。
                detail: {
                    show: true,
                    offsetCenter: [0, '-25%'],
                    textStyle: {
                        fontSize:40,
                    },
                    formatter: '{value}'+'%',
                },
                data: [{
                    value: this.state.ratePerformance,
                    name: '累计执行率',
                },
                ]
            }]
        };

    }

    render() {
        return (
            <div className={'Pies'} style={{width:'100%'}}>
                <ReactEcharts option={this.generalTwo()} style={{height:'300px'}}/>
            </div>
        )
    }

}
export default GeneralTwo;
