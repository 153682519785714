// 资金管理
import React, {Component} from "react";
import {Form, Tabs} from "antd";
import {withRouter} from "react-router";
import Moneyoverview from './components/moneyoverview'
import Capitalcost from './components/capitalcost'
import Budgetsetting from './components/budgetsetting'
import style from './index.module.scss'
const { TabPane } = Tabs;
@withRouter
class Fundsmanagement extends Component {
    state = {
        activeKey: this.props.location.returnFlag?this.props.location.returnFlag:'1',
    }

    componentDidMount() {
    }
    tabClickHandler = (params) =>{
        this.setState({activeKey: params})
    }
    render() {
        const {activeKey} = this.state
        return (
            <div className={style.fundsmanagementwrap}>
                <div className={'topcont'}>
                    <div className={'toptitle'}>资金管理</div>
                    <Tabs activeKey={activeKey} onTabClick={(params)=>this.tabClickHandler(params)}>
                        <TabPane tab={'资金总览'}
                                 key="1">
                            {activeKey === "1"?<Moneyoverview></Moneyoverview>:null}
                        </TabPane>

                        <TabPane tab={'资金支出'}
                                 key="2">
                            {activeKey === "2"?<Capitalcost></Capitalcost>:null}
                        </TabPane>

                        <TabPane tab={'预算设置'}
                                 key="3">
                            {activeKey === "3"?<Budgetsetting></Budgetsetting>:null}
                        </TabPane>
                    </Tabs>
                </div>
            </div>

        );
    }
}

Fundsmanagement = Form.create()(Fundsmanagement);
export default Fundsmanagement;
