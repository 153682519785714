// 资金管理
import React, {Component} from "react";
import {Form, Tabs, Table, Modal, Input, InputNumber, Tooltip, Progress} from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss'
import {
    selectExpendInvestList, setProjectInvest, selectProjectInvestList,
} from '../../../../api/fund/';
import {queryCatgoryById} from "../../../../api/synchrodatment";
import Readdetailist from "../../../readdetails";
import {projectSave} from "../../../../api/project";
import Cookies from "js-cookie";
import RenderButton from "../../../../components/renderButton";

const {TabPane} = Tabs;

function callback(key) {
    console.log(key);
}

@withRouter
class Budgetsetting extends Component {
    state = {
        columns: [
            {
                title: '建设任务', dataIndex: 'projectName', key: 'projectName', align: 'center', width: '200',
                render: (text, record) => {
                    return (record.projectName && record.projectName.length > 10 ?
                        <Tooltip placement="bottomLeft" title={record.projectName} overlayClassName='TitleTooltip'
                        ><span>{record.projectName}</span></Tooltip> : <span>{record.projectName}</span>)
                }
            },
            {
                title: '小计(元)', dataIndex: 'total', key: 'total', align: 'center', width: '200',
                render: (text, record) => {
                    return (<div>{<span>{record.total ? record.total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '中央财政投入资金(元)', dataIndex: 'centerInvest', key: 'centerInvest', align: 'center', width: '200',
                render: (text, record) => {
                    return (<div>{
                        <span>{record.centerInvest ? record.centerInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            : 0} </span>}</div>)
                }
            },
            {
                title: '地方各级财政投入资金(元)', dataIndex: 'localInvest', key: 'localInvest', align: 'center', width: '200',
                render: (text, record) => {
                    return (<div>{
                        <span>{record.localInvest ? record.localInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            : 0} </span>}</div>)
                }
            },
            {
                title: '举办方投入资金(元)', dataIndex: 'holderInvest', key: 'holderInvest', align: 'center', width: '200',
                render: (text, record) => {
                    return (<div>{
                        <span>{record.holderInvest ? record.holderInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            : 0} </span>}</div>)
                }
            },
            {
                title: '行业企业支持资金(元)',
                dataIndex: 'enterpriseInvest',
                key: 'enterpriseInvest',
                align: 'center',
                width: '200',
                render: (text, record) => {
                    return (<div>{
                        <span>{record.enterpriseInvest ? record.enterpriseInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            : 0} </span>}</div>)
                }
            },
            {
                title: '学校自筹资金(元)', dataIndex: 'schoolInvest', key: 'schoolInvest', align: 'center', width: '200',
                render: (text, record) => {
                    return (<div>{
                        <span>{record.schoolInvest ? record.schoolInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            : 0} </span>}</div>)
                }
            },
            {
                title: '操作', dataIndex: '', key: '', align: "center", width: '200', fixed: "right",
                render: (text, record) =>
                    <div className={'caozuo'}>
                        <span onClick={() => this.chakan(record.id)}>查看</span>
                        <span onClick={() => this.budgetSet(
                            record.id,
                            record.isNew,
                            record.projectName,
                            record.projectType)}>设置</span>
                    </div>
            },
        ],

        assignmentOneList: [
            {
                id: 1,
                name: '学校建设任务'
            },
            {
                id: 2,
                name: '专业群一建设任务'
            },
            {
                id: 3,
                name: '专业群二建设任务'
            },
            {
                id: 4,
                name: '其他特色建设任务'
            },
        ],
        assignmentTwoList: [
            {
                id: 1,
                name: '学校建设任务'
            },
            {
                id: 2,
                name: '专业群建设任务'
            },
            {
                id: 4,
                name: '其他特色建设任务'
            },
        ],
        yearList: [
            {
                id: 2019,
                year: '2019年'
            },
            {
                id: 2020,
                year: '2020年'
            },
            {
                id: 2021,
                year: '2021年'
            },
            {
                id: 2022,
                year: '2022年'
            },
            {
                id: 2023,
                year: '2023年'
            },
        ],
        assignmentName: '学校建设任务',
        assignmenId: 1,
        yearName: '',
        yearId: '',
        pageNum: 1,
        pageSize: 20,
        total: 0,
        budgetVisible: false,
        budgetsettingLeveltwo: true,
        selectExpendInvestList: [],
        parentId: 0,
        statusButten: true,
        projectId: '',
        projectName: '',
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        queryCatgory: [],
        projectType: '',
        selectProjectInvestList: [],
        chaochutip: 0,
        summationQuanbu: 0,
        summation1: 0,
        summation2: 0,
        summation3: 0,
        summation4: 0,
        summation5: 0,
        sumExpendInvest:[],

    }

    componentDidMount() {
        this.queryCatgoryById()
        this.selectExpendInvestList().then(res=>{
            this.addheji()
        })
    }

    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }

    //查询设置建设任务查询
    selectExpendInvestList = async () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            year: this.state.yearID,
            projectType: this.state.assignmenId,
        }
        await selectExpendInvestList(data).then(res => {
            this.setState({
                selectExpendInvestList: res.pageData.data,
                sumExpendInvest:res.sumExpendInvest
            })
        })
    }



    //设置建设任务反显查询
    selectProjectInvestList = async (id) => {
        let data = {
            projectId: id
        }
        await selectProjectInvestList(data).then(res => {
            if (res.length > 0) {
                res.map(e => {
                    console.log('res', e)
                    if (e.investYear === '2019') {
                        console.log('2019', e.investYear)
                        this.props.form.setFieldsValue({
                            centerMoney1: e.centerInvest ? e.centerInvest : 0,
                            localMoney1: e.localInvest ? e.localInvest : 0,
                            organizersMoney1: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoney1: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoney1: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summation1 = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summation1})
                    }
                    if (e.investYear === '2020') {
                        this.props.form.setFieldsValue({
                            centerMoney2: e.centerInvest ? e.centerInvest : 0,
                            localMoney2: e.localInvest ? e.localInvest : 0,
                            organizersMoney2: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoney2: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoney2: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summation2 = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summation2})
                    }
                    if (e.investYear === '2021') {
                        this.props.form.setFieldsValue({
                            centerMoney3: e.centerInvest ? e.centerInvest : 0,
                            localMoney3: e.localInvest ? e.localInvest : 0,
                            organizersMoney3: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoney3: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoney3: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summation3 = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summation3})
                    }
                    if (e.investYear === '2022') {
                        this.props.form.setFieldsValue({
                            centerMoney4: e.centerInvest ? e.centerInvest : 0,
                            localMoney4: e.localInvest ? e.localInvest : 0,
                            organizersMoney4: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoney4: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoney4: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summation4 = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summation4})
                    }
                    if (e.investYear === '2023') {
                        this.props.form.setFieldsValue({
                            centerMoney5: e.centerInvest ? e.centerInvest : 0,
                            localMoney5: e.localInvest ? e.localInvest : 0,
                            organizersMoney5: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoney5: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoney5: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summation5 = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summation5})
                    }
                    if (e.investYear === null) {
                        this.props.form.setFieldsValue({
                            centerMoneysum: e.centerInvest ? e.centerInvest : 0,
                            localMoneysum: e.localInvest ? e.localInvest : 0,
                            organizersMoneysum: e.holderInvest ? e.holderInvest : 0,
                            enterpriseMoneysum: e.enterpriseInvest ? e.enterpriseInvest : 0,
                            schoolMoneysum: e.schoolInvest ? e.schoolInvest : 0,
                        })
                        let summationQuanbu = parseInt(e.centerInvest ? e.centerInvest : 0) + parseInt(e.localInvest ? e.localInvest : 0) + parseInt(e.holderInvest ? e.holderInvest : 0) + parseInt(e.enterpriseInvest ? e.enterpriseInvest : 0) + parseInt(e.schoolInvest ? e.schoolInvest : 0)
                        this.setState({summationQuanbu})
                    }

                })
            } else {
                this.props.form.setFieldsValue({
                    //2019
                    centerMoney1: 0,
                    localMoney1: 0,
                    organizersMoney1: 0,
                    enterpriseMoney1: 0,
                    schoolMoney1: 0,
                    //2020
                    centerMoney2: 0,
                    localMoney2: 0,
                    organizersMoney2: 0,
                    enterpriseMoney2: 0,
                    schoolMoney2: 0,
                    //2021
                    centerMoney3: 0,
                    localMoney3: 0,
                    organizersMoney3: 0,
                    enterpriseMoney3: 0,
                    schoolMoney3: 0,
                    //2022
                    centerMoney4: 0,
                    localMoney4: 0,
                    organizersMoney4: 0,
                    enterpriseMoney4: 0,
                    schoolMoney4: 0,
                    //2023
                    centerMoney5: 0,
                    localMoney5: 0,
                    organizersMoney5: 0,
                    enterpriseMoney5: 0,
                    schoolMoney5: 0,
                    //全部
                    centerMoneysum: 0,
                    localMoneysum: 0,
                    organizersMoneysum: 0,
                    enterpriseMoneysum: 0,
                    schoolMoneysum: 0,
                })
                //合计
                this.setState({
                    summationQuanbu: 0,
                    summation1: 0,
                    summation2: 0,
                    summation3: 0,
                    summation4: 0,
                    summation5: 0
                })
            }

            this.setState({
                //设置反显数据
                selectProjectInvestList: res,
            })
        })
    }

    chakan = (id) => {
        this.props.history.push({
            pathname: '/main/doublehigh/budgetsettingLeveltwo/' + id + '/' + this.state.parentId,
        })
    }
    //预算设置
    budgetSet = (id, isNew, projectName, projectType) => {
        this.props.form.setFieldsValue({
            //2019
            centerMoney1: 0,
            localMoney1: 0,
            organizersMoney1: 0,
            enterpriseMoney1: 0,
            schoolMoney1: 0,
            //2020
            centerMoney2: 0,
            localMoney2: 0,
            organizersMoney2: 0,
            enterpriseMoney2: 0,
            schoolMoney2: 0,
            //2021
            centerMoney3: 0,
            localMoney3: 0,
            organizersMoney3: 0,
            enterpriseMoney3: 0,
            schoolMoney3: 0,
            //2022
            centerMoney4: 0,
            localMoney4: 0,
            organizersMoney4: 0,
            enterpriseMoney4: 0,
            schoolMoney4: 0,
            //2023
            centerMoney5: 0,
            localMoney5: 0,
            organizersMoney5: 0,
            enterpriseMoney5: 0,
            schoolMoney5: 0,
        })
        this.setState({
            budgetVisible: true,
            projectId: id,
            isNew: isNew,
            projectName: projectName,
            projectType: projectType,

        })
        this.selectProjectInvestList(id)
    }
    // 建设任务层面切换
    changeAssignmen = (id, name, isAll) => {
        this.setState({
            assignmenId: id,
            assignmentName: name,
            pageNum: 1,

        }, () => {
            this.selectExpendInvestList().then(res=>{
                if(document.getElementsByClassName('addnewrighttr').length > 0){
                    document.getElementsByClassName('addnewrighttr')[0].remove()
                    this.addheji()
                }
            })
        })

    }
    // 年份切换
    changeYear = (id, name, isAll) => {
        if (isAll) {
            this.setState({
                yearID: "",
                yearName: "",
                pageNum: 1,
            }, () => {
                this.selectExpendInvestList().then(res=>{
                    if(document.getElementsByClassName('addnewrighttr').length > 0){
                        document.getElementsByClassName('addnewrighttr')[0].remove()
                        this.addheji()
                    }
                })
            })
        } else {
            this.setState({
                yearID: id,
                yearName: name,
                pageNum: 1,
            }, () => {
                this.selectExpendInvestList().then(res=>{
                    if(document.getElementsByClassName('addnewrighttr').length > 0){
                        document.getElementsByClassName('addnewrighttr')[0].remove()
                        this.addheji()
                    }
                })
            })
        }

    }
    budgetHandleOk = () => {
        if (this.state.chaochutip == 1) {
            return this.mesError("分年度预算超出全部预算，请核实后再继续")
        }
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let formValue = this.props.form.getFieldsValue()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let data = {
                        projectId: this.state.projectId,
                        isNew: this.state.isNew,
                        yearProjectInvestQoList: [
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoney1,
                                //地方财政投入资金
                                localInvest: formValue.localMoney1,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoney1,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoney1,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoney1,
                                investType: this.state.projectType,
                                investYear: 2019,
                                isAll: 0,
                                isNew: this.state.isNew,
                            },
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoney2,
                                //地方财政投入资金
                                localInvest: formValue.localMoney2,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoney2,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoney2,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoney2,
                                investType: this.state.projectType,
                                investYear: 2020,
                                isAll: 0,
                                isNew: this.state.isNew,
                            },
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoney3,
                                //地方财政投入资金
                                localInvest: formValue.localMoney3,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoney3,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoney3,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoney3,
                                investType: this.state.projectType,
                                investYear: 2021,
                                isAll: 0,
                                isNew: this.state.isNew,
                            },
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoney4,
                                //地方财政投入资金
                                localInvest: formValue.localMoney4,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoney4,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoney4,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoney4,
                                investType: this.state.projectType,
                                investYear: 2022,
                                isAll: 0,
                                isNew: this.state.isNew,
                            },
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoney5,
                                //地方财政投入资金
                                localInvest: formValue.localMoney5,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoney5,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoney5,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoney5,
                                investType: this.state.projectType,
                                investYear: 2023,
                                isAll: 0,
                                isNew: this.state.isNew,
                            },
                            {
                                schoolId: this.state.schoolId,
                                projectName: this.state.projectName,
                                //中央财政投入资金
                                centerInvest: formValue.centerMoneysum,
                                //地方财政投入资金
                                localInvest: formValue.localMoneysum,
                                //举办方投入资金
                                holderInvest: formValue.organizersMoneysum,
                                //行业企业支持资金
                                enterpriseInvest: formValue.enterpriseMoneysum,
                                //学校自筹资金
                                schoolInvest: formValue.schoolMoneysum,
                                investType: this.state.projectType,
                                // investYear:2019,
                                isAll: 1,
                                isNew: this.state.isNew,
                            },

                        ]
                    };
                    setProjectInvest(data).then(res => {
                        this.mesSuccess('操作成功');
                        this.props.form.resetFields()
                        this.setState({
                            budgetVisible: false,
                        });
                    }).then((res) => {
                        this.selectExpendInvestList().then(res=>{
                            if(document.getElementsByClassName('addnewrighttr').length > 0){
                                document.getElementsByClassName('addnewrighttr')[0].remove()
                                this.addheji()
                            }
                        });
                    })
                }
            })

        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);


    }
    budgetHandleCancel = () => {
        this.props.form.resetFields()
        this.setState({
            budgetVisible: false,
            summationQuanbu: 0,
            summation1: 0,
            summation2: 0,
            summation3: 0,
            summation4: 0,
            summation5: 0,
            //2019
            centerMoney1: 0,
            localMoney1: 0,
            organizersMoney1: 0,
            enterpriseMoney1: 0,
            schoolMoney1: 0,
            //2020
            centerMoney2: 0,
            localMoney2: 0,
            organizersMoney2: 0,
            enterpriseMoney2: 0,
            schoolMoney2: 0,
            //2021
            centerMoney3: 0,
            localMoney3: 0,
            organizersMoney3: 0,
            enterpriseMoney3: 0,
            schoolMoney3: 0,
            //2022
            centerMoney4: 0,
            localMoney4: 0,
            organizersMoney4: 0,
            enterpriseMoney4: 0,
            schoolMoney4: 0,
            //2023
            centerMoney5: 0,
            localMoney5: 0,
            organizersMoney5: 0,
            enterpriseMoney5: 0,
            schoolMoney5: 0,
            chaochutip: 0
        })
    }
    handleChange = (e) => {
        let centerMoneysumm = 0;
        let localMoneysumm = 0;
        let organizersMoneysumm = 0;
        let enterpriseMoneysumm = 0;
        let schoolMoneysumm = 0;
        let summationQuanbu = 0;
        let summation1 = 0;
        let summation2 = 0;
        let summation3 = 0;
        let summation4 = 0;
        let summation5 = 0;
        // e.persist()
        setTimeout(() => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                centerMoneysumm = parseFloat(values.centerMoney1) + parseFloat(values.centerMoney2) + parseFloat(values.centerMoney3) + parseFloat(values.centerMoney4) + parseFloat(values.centerMoney5)
                localMoneysumm = parseFloat(values.localMoney1) + parseFloat(values.localMoney2) + parseFloat(values.localMoney3) + parseFloat(values.localMoney4) + parseFloat(values.localMoney5)
                organizersMoneysumm = parseFloat(values.organizersMoney1) + parseFloat(values.organizersMoney2) + parseFloat(values.organizersMoney3) + parseFloat(values.organizersMoney4) + parseFloat(values.organizersMoney5)
                enterpriseMoneysumm = parseFloat(values.enterpriseMoney1) + parseFloat(values.enterpriseMoney2) + parseFloat(values.enterpriseMoney3) + parseFloat(values.enterpriseMoney4) + parseFloat(values.enterpriseMoney5)
                schoolMoneysumm = parseFloat(values.schoolMoney1) + parseFloat(values.schoolMoney2) + parseFloat(values.schoolMoney3) + parseFloat(values.schoolMoney4) + parseFloat(values.schoolMoney5)
                summationQuanbu = parseFloat(values.centerMoneysum) + parseFloat(values.localMoneysum) + parseFloat(values.organizersMoneysum) + parseFloat(values.enterpriseMoneysum) + parseFloat(values.schoolMoneysum)
                summation1 = parseFloat(values.centerMoney1) + parseFloat(values.localMoney1) + parseFloat(values.organizersMoney1) + parseFloat(values.enterpriseMoney1) + parseFloat(values.schoolMoney1)
                summation2 = parseFloat(values.centerMoney2) + parseFloat(values.localMoney2) + parseFloat(values.organizersMoney2) + parseFloat(values.enterpriseMoney2) + parseFloat(values.schoolMoney2)
                summation3 = parseFloat(values.centerMoney3) + parseFloat(values.localMoney3) + parseFloat(values.organizersMoney3) + parseFloat(values.enterpriseMoney3) + parseFloat(values.schoolMoney3)
                summation4 = parseFloat(values.centerMoney4) + parseFloat(values.localMoney4) + parseFloat(values.organizersMoney4) + parseFloat(values.enterpriseMoney4) + parseFloat(values.schoolMoney4)
                summation5 = parseFloat(values.centerMoney5) + parseFloat(values.localMoney5) + parseFloat(values.organizersMoney5) + parseFloat(values.enterpriseMoney5) + parseFloat(values.schoolMoney5)
                isNaN(summationQuanbu) ? summationQuanbu = 0 : summationQuanbu = summationQuanbu.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                isNaN(summation1) ? summation1 = 0 : summation1 = summation1.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                isNaN(summation2) ? summation2 = 0 : summation2 = summation2.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                isNaN(summation3) ? summation3 = 0 : summation3 = summation3.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                isNaN(summation4) ? summation4 = 0 : summation4 = summation4.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                isNaN(summation5) ? summation5 = 0 : summation5 = summation5.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                this.setState({summationQuanbu, summation1, summation2, summation3, summation4, summation5})
                if (centerMoneysumm > values.centerMoneysum || localMoneysumm > values.localMoneysum || organizersMoneysumm > values.organizersMoneysum || enterpriseMoneysumm > values.enterpriseMoneysum || schoolMoneysumm > values.schoolMoneysum) {
                    this.setState({chaochutip: 1})
                } else {
                    this.setState({chaochutip: 0})
                }
            });
        }, 0);
    }

    changePage = (paqination) => {
        this.setState({
            pageNum: paqination.current,
            pageSize: paqination.pageSize,
        }, () => {
            //列表方法
            this.selectExpendInvestList().then(res=>{
                if(document.getElementsByClassName('addnewrighttr').length > 0){
                    document.getElementsByClassName('addnewrighttr')[0].remove()
                    this.addheji()
                }
            })
        })
    }
    addheji = () =>{
        let hejitr = document.getElementsByClassName('addnewtr')[0]
        let NewElement = document.createElement("tr")
        NewElement.setAttribute('class','addnewrighttr ant-table-row ant-table-row-level-0');
        NewElement.innerHTML = '<td class="ant-table-row-cell-break-word"></td>'
        document.getElementsByClassName('ant-table-tbody')[0].appendChild(hejitr)
        document.getElementsByClassName('ant-table-tbody')[1].appendChild(NewElement)
    }
    render() {
        const {assignmentOneList, assignmentTwoList, queryCatgory, yearList, columns, projectName,sumExpendInvest, dataSource, pageSize, total, pageNum, budgetVisible, selectExpendInvestList, chaochutip, summationQuanbu, summation1, summation2, summation3, summation4, summation5,} = this.state;
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            // onShowSizeChange: this.showSizeChange
        }
        const NewTr = <tr className={'addnewtr'}>
            <td className='ant-table-row-cell-break-word'  style={{textAlign: "center"}}>合计金额</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.total?sumExpendInvest.total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.centerInvest?sumExpendInvest.centerInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.localInvest?sumExpendInvest.localInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.holderInvest?sumExpendInvest.holderInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.enterpriseInvest?sumExpendInvest.enterpriseInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{sumExpendInvest.schoolInvest?sumExpendInvest.schoolInvest.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}</td>
            {/*<td className={'ant-table-fixed-columns-in-body ant-table-row-cell-break-word'}></td>*/}
        </tr>
        return (
            <div className={style.budgetsetting}>
                <div className={'assignmen'}>
                    <div className={'name'}>建设任务层面：</div>
                    <ul className={'assignmenul'}>
                        {
                            queryCatgory === 1 ? assignmentOneList.map((item, index) => {
                                return <li onClick={() => this.changeAssignmen(item.id, item.name, false)}
                                           className={this.state.assignmentName === item.name ? "assignmen_active" : ""}>{item.name}</li>
                            }) : assignmentTwoList.map((item, index) => {
                                return <li onClick={() => this.changeAssignmen(item.id, item.name, false)}
                                           className={this.state.assignmentName === item.name ? "assignmen_active" : ""}>{item.name}</li>
                            })
                        }
                    </ul>
                </div>
                <div className={'year'}>
                    <div className={'name'}>年份：</div>
                    <ul className={'yearul'}>
                        <li onClick={() => this.changeYear("", "", true)}
                            className={this.state.yearName === '' ? "year_active" : ""}>全部
                        </li>
                        {
                            yearList ? yearList.map((item, index) => {
                                return <li onClick={() => this.changeYear(item.id, item.year, false)}
                                           className={this.state.yearName === item.year ? "year_active" : ""}>{item.year}</li>
                            }) : ''
                        }
                    </ul>
                </div>
                <div className={'table_wrap'}>
                    <Table className="main-box-header table-maina rolementStyle" cellspacing={20} columns={columns}
                           dataSource={selectExpendInvestList}
                           pagination={pagination}
                           onChange={this.changePage}
                           scroll={{x: 1500, y: 450}}
                           locale={{
                               emptyText: <>
                                   <div className="Empty-conet-ss">
                                       <div className="img">
                                           <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div>
                               </>
                           }}
                    />
                    {NewTr}
                </div>
                <Modal
                    title="预算设置"
                    visible={budgetVisible}
                    onOk={this.budgetHandleOk}
                    onCancel={this.budgetHandleCancel}
                    width={1000}
                    closable={false}
                    className={style.budgetsettingModal}
                    maskClosable={false}
                    centered={true}
                >
                    <div>
                        <div className={'title'}>
                            {projectName}
                        </div>
                        <Form>
                            <ul className={'header'}>
                                <li>资金类型/分年度</li>
                                <li>全部</li>
                                <li>2019</li>
                                <li>2020</li>
                                <li>2021</li>
                                <li>2022</li>
                                <li>2023</li>
                            </ul>
                            <ul className={'bodyulone'}>
                                <li>中央财政投入资金（元）</li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoneysum', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      disabled={this.state.isNew == 0}
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2')
                                                                                                  .replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}

                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoney1', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoney2', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoney3', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoney4', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('centerMoney5', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                            </ul>
                            <ul className={'bodyulone'}>
                                <li>地方各级财政投入资金（元）</li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoneysum', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      disabled={this.state.isNew == 0}
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoney1', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoney2', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoney3', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoney4', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('localMoney5', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                            </ul>
                            <ul className={'bodyulone'}>
                                <li>举办方投入资金（元）</li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoneysum', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      disabled={this.state.isNew == 0}
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoney1', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoney2', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoney3', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoney4', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('organizersMoney5', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                            </ul>
                            <ul className={'bodyulone'}>
                                <li>行业企业支持资金（元）</li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoneysum', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      disabled={this.state.isNew == 0}
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoney1', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoney2', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoney3', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoney4', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('enterpriseMoney5', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                            </ul>
                            <ul className={'bodyulone'}>
                                <li>学校自筹资金（元）</li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoneysum', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      disabled={this.state.isNew == 0}
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoney1', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoney2', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoney3', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoney4', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item className={'formgroup'}>
                                        {getFieldDecorator('schoolMoney5', {})
                                        (<InputNumber className={'input'}
                                                      autocomplete="off"
                                                      onChange={this.handleChange}
                                                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      parser={value => value.replace(/[^\d.]/g, "").replace(/(\d)\.(\d\d).*$/, '$1.$2').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace(/^\d{10,}$/, 999999999.00)}
                                        />)}
                                    </Form.Item>
                                </li>
                            </ul>
                            <ul className={'bodyulone heji'}>
                                <li>合计（元）</li>
                                <li>{summationQuanbu}</li>
                                <li>{summation1}</li>
                                <li>{summation2}</li>
                                <li>{summation3}</li>
                                <li>{summation4}</li>
                                <li>{summation5}</li>
                            </ul>
                            {chaochutip === 1 ? <span className={'tipzi'}>分年度预算超出全部预算，请核实后再继续</span> : ""}
                        </Form>
                    </div>
                </Modal>
            </div>

        );
    }
}

Budgetsetting = Form.create()(Budgetsetting);
export default Budgetsetting;
