// 绩效指标达标情况
import React, {Component} from "react";
import {Form, Table, Tabs,Modal,Button} from "antd";
import {withRouter} from "react-router";
import { Readsign, Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../taskstatEditor";
import ScreeningCondition from "../screeningCondition";
const { TabPane } = Tabs;
@withRouter
class Taskachievementofindicators extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '绩效指标',
                dataIndex: 'performanceIndicators',
                width: 200,
                align: "center",
                key: 'performanceIndicators',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'level',
                key: 'level',

            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'indicatorsFirst',
                key: 'indicatorsFirst',

            },
            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'indicatorsSecond',
                key: 'indicatorsSecond',

            }, {
                title: '完成度',
                align: "center",
                width: 200,
                dataIndex: 'completeness',
                key: 'completeness',

            },


        ],
        pageDataList: [
            {
                id: 1,
                performanceIndicators: '提升国际化水平',
                level:'学校',
                indicatorsFirst:'产出指标',
                indicatorsSecond:'数量指标',
                completeness:'98%'


            },



        ],



    }
    componentDidMount() {
        this.echarts()
    }
    echarts = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('meetPerformanceIndicators')){
            let data1 = ['46','89','90','60','59','48','37']
            let data2 = ['89','57','78','56','34','65','74']
            let data3 = ['65','256','36','86','48','26','76']
            let myChart = echarts.init(document.getElementById('meetPerformanceIndicators'));
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#3E78ED','#23B899','#FFEE7E'],
                legend: {
                    itemHeight: 10,
                    itemWidth: 10,
                    data: [{name: '指标数', icon: 'circle'}, {name: '未完成指标数', icon: 'circle'}, {name: '超出预期完成数', icon: 'circle'}],
                    bottom: 15,
                    left: 40,
                },
                grid: {
                    left: '5%',
                    top: 10
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['数量','质量','时效','成本','社会效益','可持续影响','满意度'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        type: 'value',
                        // axisLabel: {
                        //     formatter: ''
                        // }
                    },
                ],
                series: [
                    {
                        name: '指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1,
                        barWidth: 10
                    },
                    {
                        name: '未完成指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2,
                        barWidth: 10
                    },
                    {
                        name: '超出预期完成数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data3,
                        barWidth: 10
                    },

                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }

    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.taskachievementofindicators}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>绩效指标达标情况</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'}>
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <Tabs className='all' defaultActiveKey="" onChange={this.onChangeTab}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane  tab={'其他'} key="3">
                                </TabPane>
                            </Tabs>
                            <div id={'meetPerformanceIndicators'} className={'echarts'} style={{width: '100%', height: '370px',marginTop:'20px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>绩效指标达标情况</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 900,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition/>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>

                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>

                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

Taskachievementofindicators = Form.create()(Taskachievementofindicators);
export default Taskachievementofindicators;
