/*关联文档*/
import React, {Component} from "react";
import {
    TreeSelect , Tree ,Button, Table, Row, Input, Col, Select, Modal, Form, message, Spin, Icon, Tabs, Checkbox, DatePicker
} from "antd";
import "./associatedDocumentation.scss";
// import "../../../styles/global.less"
import {withRouter} from "react-router";
import {getOneLevelDocumentList,getNextLevelDocumentList,addTaskDocumentList} from "../../../../../../api/renwu"
// import Cookies from "js-cookie";
const {Option} = Select;

const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { TreeNode } = Tree;

const { SHOW_PARENT } = TreeSelect;
// import { getList, pass } from '@/api/check/index'

@withRouter
class AssociatedDocumentation extends Component {
    state = {
        expandedKeys: ['0-0-0', '0-0-1'],
        autoExpandParent: true,
        checkedKeys: ['0-0-0'],
        selectedKeys: [],
        projectId:this.props.projectId,
        taskId:this.props.taskId,
        selectedValue: [],
        treeData:[],
        openVal:false
    };

    componentDidMount() {
        this.props.onRef(this)
        this.getOneLevelDocumentList()
        setTimeout(()=>{
            this.setState({
                openVal:true
            })
        },300)
    }

    getOneLevelDocumentList = async ()=>{
        await getOneLevelDocumentList({projectId:this.state.projectId,taskId:this.state.taskId}).then(res=>{
            let treeData = []
            if(res){
                for (let i = 0; i < res.length; i++) {
                    let item = res[i]
                    treeData.push({
                        id:item.id,
                        pId:item.parentId,
                        value:item.id,
                        // title:item.resourceName,
                        // title:<div className='treeSelectedD'><span>{this.getIcon(item.isDir,item.fileType)}</span>{item.resourceName}</div>,
                        title:<div className='treeSelectedD'>{this.getIcon(item.isDir,item.fileType)} {item.resourceName}</div>,
                        isLeaf:item.isDir !== 1,
                        disabled: item.isDir === 1,
                    })
                }
            }
            this.setState({treeData:treeData})
        })
    }

    getNextLevelDocumentList = async (id)=>{
        await getNextLevelDocumentList({id:id,taskId:this.state.taskId}).then(res=>{
            let treeData = []
            if(res){
                for (let i = 0; i < res.length; i++) {
                    let item = res[i]
                    treeData.push({
                        id:item.id,
                        pId:item.parentId,
                        value:item.id,
                        // title:item.resourceName,
                        title:<div className='treeSelectedD'>{this.getIcon(item.isDir,item.fileType)} {item.resourceName}</div>,
                        isLeaf:item.isDir !== 1,
                        disabled: item.isDir === 1
                    })
                }
            }
            this.setState({treeData:this.state.treeData.concat(treeData)})

        })
    }

    getIcon = (isDir,fileType)=>{
        if(isDir === 1){
            return <img src={require('@/assets/imgnew/wenjianjia.png')}/>
        }
        else if(fileType === 1){
            return <img src={require('@/assets/imgnew/word.png')}/>
        }
        else if(fileType === 2){
            return <img src={require('@/assets/imgnew/ppt.png')}/>
        }
        else if(fileType === 3){
            return <img src={require('@/assets/imgnew/excel.png')}/>
        }
        else if(fileType === 4){
            return <img src={require('@/assets/imgnew/shipin.png')}/>
        }
        else if(fileType === 5){
            return <img src={require('@/assets/imgnew/yinpin.png')}/>
        }
        else if(fileType === 6){
            return <img src={require('@/assets/imgnew/image.png')}/>
        }
        else if(fileType === 7){
            return <img src={require('@/assets/imgnew/pdf.png')}/>
        }
        else {
            return <img src={require('@/assets/imgnew/weizhi.png')}/>
        }
    }

    getValues = () => {
        return this.state.selectedValue;
    };

    onExpand = expandedKeys => {
        console.log('onExpand', expandedKeys);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    // onCheck = checkedKeys => {
    //     console.log('onCheck', checkedKeys);
    //     this.setState({ checkedKeys });
    // };
    //
    // onSelect = (selectedKeys, info) => {
    //     console.log('onSelect', info);
    //     this.setState({ selectedKeys });
    // };
    //
    onLoadData = treeNode =>

        new Promise(resolve =>
        {
            console.log('treeNode',treeNode)
            const { id } = treeNode.props;
            setTimeout(() => {
                console.log('this.state.treeData',this.state.treeData)
                this.getNextLevelDocumentList(id)
                resolve();
            }, 300);
        });

    onChange = value => {
        this.setState({ selectedValue:value });
        let height = 290 - document.getElementsByClassName('addglwdtree')[0].clientHeight+'px'
        document.getElementsByClassName('TreeSelect_warp')[0].setAttribute('style', 'max-height: '+height+' !important');
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        let {} = this.state;

        // const tProps = {
        //     treeData,
        //     value: this.state.value,
        //     onChange: this.onChange,
        //     treeCheckable: true,
        //     showCheckedStrategy: SHOW_PARENT,
        //     searchPlaceholder: 'Please select',
        //     style: {
        //         width: '100%',
        //     },
        // };

        return (
            <div className='AssDocumentation_center' style={{'height':'523px'}}>
                <TreeSelect
                    treeDataSimpleMode
                    dropdownClassName="TreeSelect_warp"
                    className={'addglwdtree'}
                     treeData={this.state.treeData}
                     // value= {this.state.defaultValue}
                     onChange={this.onChange}
                     treeCheckable={true}
                     // showCheckedStrategy={SHOW_PARENT}
                     searchPlaceholder={'请添加关联文档'}
                     style= {{width:'100%'}}
                     loadData={this.onLoadData}
                   /*  treeDefaultExpandAll={true}*/
                    open={this.state.openVal}

                />

                {/*<Row className='treeListR'>*/}
                {/*    <Col span={8} className='treeListRC treeListRC1'>*/}
                {/*        /!*<Tree*!/*/}
                {/*        /!*    checkable*!/*/}
                {/*        /!*    showIcon*!/*/}
                {/*        /!*    onExpand={this.onExpand}*!/*/}
                {/*        /!*    expandedKeys={this.state.expandedKeys}*!/*/}
                {/*        /!*    autoExpandParent={this.state.autoExpandParent}*!/*/}
                {/*        /!*    onCheck={this.onCheck}*!/*/}
                {/*        /!*    checkedKeys={this.state.checkedKeys}*!/*/}
                {/*        /!*    onSelect={this.onSelect}*!/*/}
                {/*        /!*    selectedKeys={this.state.selectedKeys}*!/*/}
                {/*        /!*>*!/*/}
                {/*        /!*    {this.renderTreeNodes(treeData)}*!/*/}
                {/*        /!*</Tree>*!/*/}

                {/*        <TreeSelect {...tProps} />*/}

                {/*    </Col>*/}

            </div>

        );
    }
}

AssociatedDocumentation = Form.create()(AssociatedDocumentation);
export default AssociatedDocumentation;
