/*建设任务管理--概况--建设任务设置--建设任务信息*/
import React, {Component} from "react";
import {
    Form, Input, Select, Button, Modal
} from "antd";
import {deleteProject, updateProjectOperation} from '../../../../../../api/project/';
import Cookies from "js-cookie";
import "./index.scss";
import {withRouter} from "react-router";
import S from 'gongyongicon'

@withRouter
class Xiangmucaozuo extends Component {
    state = {
        gdvisible: false,
        gdztms: '',//归档状态描述
        jsvisible: false,
        jsdelete: '',
        infoProject:this.props.infoProject,
        id:this.props.infoProject.id,
        status:'',
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        projectStatus:this.props.infoProject.projectStatus,//原来的建设任务状态
        isNew: this.props.infoProject.isNew,
        projectName: this.props.infoProject.projectName,
        statusButten: true,
        gqvisible: false,
        qxgqvisible: false,
        qxgdvisible: false
    }

    componentDidMount() {
    }
    //归档建设任务

    gdxm = async () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
        let projectObj = {
            id:this.state.id,
            editorId:this.state.userId,
            editorName:this.state.humanName,
            statusDescribe:this.state.gdztms,
            projectStatus:this.state.status,
        }
        await updateProjectOperation(projectObj).then(res => {
            this.props.onClose(false);
            this.props.infoProjects();
            this.props.infoProjectPanel();
            this.mesSuccess('操作成功');

        }).then(res => {
            this.setState({gdvisible: false})

        })}
        setTimeout(() => {   // 设置延迟事件,3// 秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }
    onChangezt = ({ target: { value } }) => {
        this.setState({gdztms: value})
    };
    onChangegqzt = ({ target: { value } }) => {
        this.setState({gqztms: value})
    };
    //解散建设任务
    jsxm = async () => {
        let projectObj = {
            id:this.state.id,
            delete:this.state.jsdelete,
        }
        await deleteProject(projectObj).then(res => {
            this.mesSuccess('操作成功');
            this.chakan()
        })
    }
    chakan = () => {
        this.props.history.push({
            pathname: '/main/doublehigh/projectlist',
        })
    }
    onChangejs = ({ target: { value } }) => {
        this.setState({jsdelete: value})
    };
    render() {
        const {projectStatus,isNew,projectName} = this.state
        const {gdztms} = this.state
        return (
            <div className='xiangmucaozuo'>
                <div className={'xmguidang'}>
                    <span className={'xmtitle'}>归档建设任务</span>
                    <span className={'gdms'}><img src={require('@/assets/imgnew/tip.png')}/><span className={'ms'}>提示：建设任务归档后，状态变为“已归档”，并且不可编辑。取消归档后， 建设任务可以恢复正常使用。</span></span>

                    {projectStatus===4?<Button className={'btn'} onClick={()=>{this.setState({qxgdvisible: true,status: 7})}}>取消归档</Button>:
                        <Button className={'btn'} onClick={()=>{this.setState({gdvisible: true,status: 4})}}>归档建设任务</Button>}
                </div>
                <div className={'xmguidang'}>
                    <span className={'xmtitle'}>挂起建设任务</span>
                    <span className={'gdms'}><img src={require('@/assets/imgnew/tip.png')}/><span className={'ms'}>提示：建设任务挂起后，状态变为"已挂起"。取消挂起后，建设任务重新进入已
                                                                                                                      启动状态；</span></span>
                    {projectStatus===3?<Button onClick={()=>{this.setState({qxgqvisible: true,status: 7})}} className={'btn gq-btn'}>取消挂起</Button>
                        :projectStatus===4?<Button  disabled={true} className={'btn gq-btn disabled-gqbtn'}>挂起建设任务</Button>:<Button  className={'btn gq-btn'} onClick={()=>{this.setState({gqvisible: true,status: 3})}}>挂起建设任务</Button>}
                </div>
                <div className={'xmguidang'}>
                    <span className={'xmtitle'}  >解散建设任务</span>
                    <span className={'gdms'}><img src={require('@/assets/imgnew/tip.png')}/><span className={'ms'}>提示：解散建设任务后，建设任务内容将不可恢复，请谨慎操作。</span></span>
                    {isNew===0? <Button className={'btn js-btn disabled-jsbtn'} disabled={true} onClick={()=>{this.setState({jsvisible: true})}}>解散建设任务</Button>
                        :<Button className={'btn js-btn'} onClick={()=>{this.setState({jsvisible: true})}}>解散建设任务</Button>}

                </div>
                <Modal
                    visible={this.state.gdvisible}
                    onOk={()=>this.gdxm()}
                    closable={false}
                    onCancel={()=>{this.setState({gdvisible: false})}}
                    className={'guidangxiangmu'}
                >
                    <div>
                        <div className={'mWarningCircle mtop2WarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <span className={'tishititle'}>确定归档建设任务吗？</span>
                        <span className={'tishims'}>提示：建设任务归档后，状态变为“已归档”，并且不可编辑。取消归档后，建设任务可以恢复正常使用。</span>
                        <Input.TextArea rows={6} placeholder={'请输入状态描述'} onChange={this.onChangezt}
                                        maxLength={200} className={'ztms'} />
                                        <span className={'length'}>{gdztms.length}/200</span>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.gqvisible}
                    onOk={()=>this.gdxm()}
                    closable={false}
                    onCancel={()=>{this.setState({gqvisible: false})}}
                    className={'guidangxiangmu'}
                >
                    <div>
                        <div className={'mWarningCircle mtop2WarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <span className={'tishititle'}>确定挂起建设任务吗？</span>
                        <span className={'tishims'}>提示：建设任务挂起后，状态变为"已挂起"。取消挂起后，建设任务重新进入已启动状态。</span>
                        <Input.TextArea rows={6} placeholder={'请输入状态描述'} onChange={this.onChangezt}
                                        maxLength={200} className={'ztms'} />
                        <span className={'length'}>{gdztms.length}/200</span>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.qxgqvisible}
                    onOk={()=>this.gdxm()}
                    closable={false}
                    onCancel={()=>{this.setState({qxgqvisible: false})}}
                    className={'guidangxiangmu'}
                >
                    <div>
                        <div className={'mWarningCircle mtop2WarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <span className={'tishititle'}>确定取消挂起建设任务吗？</span>
                        <span className={'tishims'}>提示：建设任务挂起后，状态变为"已挂起"。取消挂起后，建设任务重新进入已启动状态。</span>
                        <Input.TextArea rows={6} placeholder={'请输入状态描述'} onChange={this.onChangezt}
                                        maxLength={200} className={'ztms'} />
                        <span className={'length'}>{gdztms.length}/200</span>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.qxgdvisible}
                    onOk={()=>this.gdxm()}
                    closable={false}
                    onCancel={()=>{this.setState({qxgdvisible: false})}}
                    className={'guidangxiangmu'}
                >
                    <div>
                        <div className={'mWarningCircle mtop2WarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <span className={'tishititle'}>确定取消归档建设任务吗？</span>
                        <span className={'tishims'}>提示：建设任务归档后，状态变为“已归档”，并且不可编辑。取消归档后，建设任务可以恢复正常使用。</span>
                        <Input.TextArea rows={6} placeholder={'请输入状态描述'} onChange={this.onChangezt}
                                        maxLength={200} className={'ztms'} />
                        <span className={'length'}>{gdztms.length}/200</span>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.jsvisible}
                    onOk={()=>this.jsxm()}
                    closable={false}
                    onCancel={()=>{this.setState({jsvisible: false})}}
                    className={'guidangxiangmu'}
                >
                    <div>
                        <div className={'mWarningCircle mtop2WarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <span className={'tishititle'}>确认解散建设任务吗？</span>
                        <span className={'tishims'}>正在解散“{projectName}”建设任务 <br></br><br></br>解散建设任务后，建设任务内容将不可恢复，请谨慎操作？</span>
                        <span className={'srdelete'}>请输入“<span className={'del'}>DELETE</span>”以确认</span>
                        <Input placeholder={'DELETE'} autocomplete="off" onChange={this.onChangejs} className={'ztms inputdel'}/>
                    </div>
                </Modal>
            </div>

        );
    }
}

Xiangmucaozuo = Form.create()(Xiangmucaozuo);
export default Xiangmucaozuo;
