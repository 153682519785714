/*资金总体情况*/
import React, {Component} from "react";
import {
    Form,Select,Tabs,Button
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import GeneralOne from './map1'
import GeneralTwo from './map2'
import OverviewTwo from './map4'
import EchartsMapOne from './echartsMap/map1'
import EchartsMapTwo from './echartsMap/map2'
import EchartsMapThree from './echartsMap/map3'
import EchartsMapFour from './echartsMap/map4'

const {Option} = Select;
const { TabPane } = Tabs;

@withRouter
class SituationFunds extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        const {} = this.state
        const {getFieldDecorator} = this.props.form;
        const {overallFundSituationInfo, schoolMoneyOverview} = this.props;
        console.log('schoolMoneyOverview 大大大 888', schoolMoneyOverview)
        return (
            <div className={'SituationFunds'}>
                <div className="main-cont">
                    <div className={'Assignment-cont'}>
                        <div className={'top_Generalreception'}>
                            <h3>资金总体情况</h3>
                            <div className="General_cont">
                                <div className="General_le">
                                    <GeneralOne overallFundSituationInfo = {overallFundSituationInfo}/>
                                </div>
                                <div className="General_ri">
                                    <GeneralTwo overallFundSituationInfo = {overallFundSituationInfo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'Assignment-cont'}>
                    <div className={'top_Overview'}>
                        <h3>院校资金情况总览</h3>
                        <div className="Overview_cont">
                            <OverviewTwo schoolMoneyOverview = {schoolMoneyOverview}/>
                        </div>
                    </div>
                    </div>
                    <div className={'Assignment-cont'}>
                    <div className="top_Radarimaging">
                        <div className={'left_Radarim'}>
                            <h5>年度（含累计）预算安排情况</h5>
                            <EchartsMapOne schoolMoneyOverview = {schoolMoneyOverview}/>
                        </div>
                        <div className={'left_Radarim'}>
                            <h5>年度（含累计）预算到位情况</h5>
                            <EchartsMapTwo schoolMoneyOverview = {schoolMoneyOverview}/>
                        </div>
                        <div className={'left_Radarim'}>
                            <h5>年度（含累计）资金使用情况</h5>
                            <EchartsMapThree schoolMoneyOverview = {schoolMoneyOverview}/>
                        </div>
                        <div className={'left_Radarim'}>
                            <h5>年度（含累计）资金执行率</h5>
                            <EchartsMapFour schoolMoneyOverview = {schoolMoneyOverview}/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

SituationFunds = Form.create()(SituationFunds);
export default SituationFunds;
