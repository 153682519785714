/*学校自评报告*/
import React, {Component} from 'react';
import {Button, Form,Tabs } from 'antd';
import './index.scss';
import Iframe from "./indexIframe";
const { TabPane } = Tabs;
class EvaluationReportRightShuju extends React.Component {
    state = {
        visible: false,
    };
    componentDidMount() {
        // var id111 = document.getElementById("ifm");
        // console.log("id111",id111)
         document.oncontextmenu=new Function("event.returnValue=false"); //禁止右键功能
    //      alert("右键1")
    //     document.addEventListener('click', this._handleClick);
    //     document.addEventListener('contextmenu', this._handleContextMenu);
    //     //document.addEventListener('scroll', this._handleScroll);
    //     // window.open ('.html','newwindow','width=960,toolbar=no, menubar=no,scrollbars=yes,resizable=yes,location=no,status=no')
   }

   //   componentDidMount() {
   //       document.addEventListener('contextmenu', this._handleContextMenu);
   //  //     document.addEventListener('click', this._handleClick);
   //  //     document.addEventListener('scroll', this._handleScroll);
   // };

    // componentWillUnmount() {
      //document.removeEventListener('contextmenu', this._handleContextMenu);
    //     document.removeEventListener('click', this._handleClick);
    //     document.removeEventListener('scroll', this._handleScroll);
    // }
    _handleContextMenu = (event) => {

        // event.returnValue=false;

    };


    render() {
        const {getFieldDecorator }=this.props.form
        const {iframeSrc} =this.props
        const {visible } = this.state
        console.log('iframeSrc  预览的地址：：', iframeSrc)
        return (
            <div id={'timeId'} className="Comment_EvaluationReportRightshuju">
                {/*#toolbar=0&navpanes=0&scrollbar=0&menubar=no&scrollbars=yes&resizable=yes&location=no&status=no*/}
                {/*<embed src={`${iframeSrc}#toolbar=0`} type="application/pdf" height={660} width="100%" />*/}
                {
                    iframeSrc?<div className={'text_iframe'}>
                            <iframe width="100%" height="460px" id="ifm" src={`${iframeSrc}`}  frameBorder="0" ></iframe>
                    </div> :
                        <div className="Empty-content">
                            <div className="text">
                                <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                <span>暂无PDF数据</span>
                            </div>
                        </div>
                }

            </div>
        );
    }
}

EvaluationReportRightShuju = Form.create()(EvaluationReportRightShuju);
export default EvaluationReportRightShuju;
