/*自定义图表模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Tabs} from "antd";
import * as echarts from 'echarts';
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import {queryAllCustomChart,} from "../../../../api/chart/index"
import Cookies from "js-cookie";
const { TabPane } = Tabs;
@withRouter
class CustomChart extends Component {
    state={
        initialValueOfChartShape:this.props.chartData.shape,
        name:this.props.chartData.name,
        id:this.props.chartData.id,
        chartData:this.props.chartData.dataList,
    }
    componentDidMount() {
        this.customChart()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    customChart = ()=>{
        // 绘制图表
        if(document.getElementById('CustomChart'+ this.state.id)) {
            let chartData = this.state.chartData //动态数据
            if(!chartData){
                chartData = []
            }
            let myChart = echarts.init(document.getElementById('CustomChart'+ this.state.id));
            let echartsType = this.state.initialValueOfChartShape
            // let xdata = ['1月', '2月', '3月', '4月']
            let xdata = []
            let ydata = []
            for (let i = 0; i < chartData.length; i++) {
                xdata.push(chartData[i].xdata)
                ydata.push(chartData[i].ydata)
            }

            let series = [{
                // data: ['10', '20', '30', '40'],
                data: ydata,
                type: echartsType==1?'bar':echartsType==2?'pie':'line',
                barWidth: 20,
                showBackground: true,
            }]
            let xAxis = {
                type: 'category',
                data: xdata,
                show: true
            }
            let yAxis = {
                type: 'value',
                minInterval: 1,
                show: true
            }
            let color='#588BF7'
            let tooltipBackgroundColor = 'rgba(51, 51, 51, 0.8)'

            let pieData = []
            let legendData = []
            for (let i = 0; i < chartData.length; i++) {
                pieData.push({ value: chartData[i].ydata, name: chartData[i].xdata })
                legendData.push({ name: chartData[i].xdata, icon: 'circle' })
            }
            //环状图
            if(echartsType === 2){
                xAxis ={show:false}
                yAxis = {show:false}
                series = [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        center: ['25%','50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: true,
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'bold',
                                formatter: function({ data }) {
                                    // let html = `{b| 实际使用额度} \n {c|¥${data.value}}`;
                                    let html = `{b| 数量} \n {c|${data.value}}`;
                                    return html;
                                },
                                rich: {
                                    b: {
                                        //name 文字样式
                                        lineHeight: 20,
                                        fontSize: 14,
                                        textAlign: "center",
                                        color: "#333333"
                                    },
                                    c: {
                                        //value 文字样式
                                        lineHeight: 29,
                                        color: "#333333",
                                        fontSize: 20,
                                        fontWeight: 550
                                    },
                                }
                            },
                        },
                        labelLine: {
                            show: false
                        },
                        // data: [
                        //     { value: 1048, name: '提升国际化水平' },
                        //     { value: 735, name: '提升信息化水平' },
                        //     { value: 580, name: '提升学校治理水平' },
                        //     { value: 484, name: '提升服务发展水平' },
                        //     { value: 300, name: '提升校企合作水平' },
                        //     { value: 300, name: '打造高水平专业群' }
                        // ]
                        data: pieData
                    }
                ]
                color = ['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58']
            }
            else if(echartsType === 3){
                color = '#FF7214'
                tooltipBackgroundColor = '#FF7214'
            }
            let option = {
                tooltip: {
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: tooltipBackgroundColor,
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                },
                xAxis: xAxis,
                legend : {
                    type: "scroll",
                    top: '100',
                    left: '50%',
                    orient: 'vertical',
                    itemWidth: 8, // 设置宽度
                    itemHeight: 8, // 设置高度
                    data:legendData,
                    formatter: (name) => {
                        let amount = 0
                        pieData.map(item=>{
                            if(name === item.name){
                                amount = item.value
                            }
                        })
                        return name + '{a|' + '：  '+ amount + '}' + '{b|' + '}';
                    },
                    tooltip: {
                        show: true//这个为true的话悬停到legend上会出现tooltip
                    },
                    textStyle: {
                        fontSize: 14,
                        //在rich中给formatter添加个别字体颜色
                        rich: {
                            a:{
                                width: 120,
                                fontSize: 14,
                                color: '#333333',
                                align: 'left'
                            },
                            b: {
                                color: '#888888',
                                padding: [0, 10],
                                fontSize: 14,
                                width: 30,
                                align: 'left'
                            },
                            c: {
                                color: '##40a9ff',
                                padding: [0, 10],
                                fontSize: 14,
                                align: 'left'
                            },
                        }
                    }
                },
                color: color,
                grid: {
                    top: 20,
                    left: 20,
                    right: 0,
                    bottom: 20
                },
                yAxis: yAxis,
                series: series
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        const {queryCatgory} = this.state
        return (
            <div className={'renwutonhji width50'}>
                <div className={'title title100'}>
                    {this.state.name}
                    <div className={'caozuo'} >
                        <div onClick={()=>this.bianji(this.state.name,'/main/doublehigh/chart/'+this.state.id)}><Task/></div>
                        <ThreeDian/>
                    </div>
                </div>
                <div className={'tempalte3-echarts'} id={'CustomChart'+ this.state.id}></div>
            </div>

        );
    }
}

CustomChart = Form.create()(CustomChart);
export default CustomChart;
