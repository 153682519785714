// 任务与绩效进展走势
import React, {Component} from "react";
import {Button, Form,  Modal,  Table, Tabs,} from "antd";
import {withRouter} from "react-router";
import { Readsign, Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../taskstatEditor";
import ScreeningCondition from "../screeningCondition";
const { TabPane } = Tabs;
@withRouter
class Performanceindicator extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '绩效指标',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'investAmount',
                key: 'investAmount',

            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'expendAmount',
                key: 'expendAmount',

            },
            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'expendAmountt',
                key: 'expendAmount',

            },
            {
                title: '完成度',
                align: "center",
                width: 200,
                dataIndex: 'Principal',
                key: 'Principal',

            },


            ],
        pageDataList: [
            {
                id: 1,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 2,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 3,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 4,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 5,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 6,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 7,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 8,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 9,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },

        ],
        dataSetting: []
    }
    componentDidMount() {
        this.echarts()
    }

    echarts = ()=>{
        if(document.getElementById('performanceIndicator')){
            let data = ['120','50','90']
            let myChart = echarts.init(document.getElementById('performanceIndicator'));
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        indicator: [
                            { text: '产出指标',min:0,max:150},
                            { text: '满意度指标',min:0,max:150},
                            { text: '效益指标',min:0,max:150},
                        ],
                        center: ['50%', '50%'],
                        radius: 85,
                        // startAngle: 90,
                        splitNumber: 5,
                        name: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#333333'
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['rgba(255,255,255, 0.2)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204,0.3)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204, 1)'
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: '雷达图',
                        type: 'radar',
                        symbol: 'none',
                        symbolSize: 0,
                        areaStyle: {
                            normal: {
                                shadowBlur: 13,
                                shadowColor: 'rgba(0,0,0,.2)',
                                shadowOffsetX: 0,
                                shadowOffsetY: 10,
                                opacity: 1
                            }
                        },
                        data: [
                            {
                                value: data,
                                name: ' 一级绩效指标',
                                tooltip: {
                                    trigger: 'item'
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [{
                                                offset: 0,
                                                color: 'rgba(253, 149, 136, 0.4)'
                                            },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(253, 149, 136, 0.4)'
                                                }
                                            ],
                                            false
                                        ),
                                        opacity: 1 // 区域透明度
                                    },
                                },
                                label: {
                                    show: true,
                                    color:'rgba(0,0,0,0)',
                                    formatter: function(params) {
                                        return params.value;
                                    }
                                },
                                lineStyle:{
                                    color:'#FD9588'
                                },

                            }
                        ]
                    },
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }
    dimensionality = (value) =>{
        console.log('value',value)
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.performanceindicator}>
                <div className={'performanceindicator_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>绩效指标完成度</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'}>
                            <span>完成</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>
                    </div>
                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <Tabs className='all' defaultActiveKey="" onChange={this.onChangeTab}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane  tab={'其他'} key="3">
                                </TabPane>
                            </Tabs>
                            <div id={'performanceIndicator'} className={'echarts'} style={{width: '100%', height: '400px',marginTop: '40px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>任务进展走势</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 900,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition/>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span onClick={this.handLook} className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Performanceindicator = Form.create()(Performanceindicator);
export default Performanceindicator;
