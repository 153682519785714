
import React, {Component} from 'react';
import echarts from 'echarts';
import { withRouter} from "react-router-dom";
@withRouter
class MapaList extends Component {

    componentWillReceiveProps(nextProps, nextContext){
        let finishDegreeList2020 = [];
        let finishDegreeList2021 = [];
        let finishDegreeListTotal = [];
        console.log('nextProps', nextProps)
        let indicatorsNumberInfo = nextProps.indicatorsNumberInfo
        finishDegreeListTotal.push(indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsHigh : 0)
        finishDegreeListTotal.push(indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsGeneral : 0)
        finishDegreeListTotal.push(indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsWeak : 0)

        finishDegreeList2020.push(indicatorsNumberInfo ? indicatorsNumberInfo.resultsHigh : 0)
        finishDegreeList2020.push(indicatorsNumberInfo ? indicatorsNumberInfo.resultsGeneral : 0)
        finishDegreeList2020.push(indicatorsNumberInfo ? indicatorsNumberInfo.resultsWeak : 0)

        finishDegreeList2021.push(indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveHigh : 0)
        finishDegreeList2021.push(indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveGeneral : 0)
        finishDegreeList2021.push(indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveWeak : 0)

        let myChart = echarts.init(document.getElementById('MapaList'));
        let option = {
            // title: {
            //     text: '完成度'
            // },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                icon:'circle',
                itemWidth: 10,
                itemHeight: 10,
                left: 'center',
                bottom:'0',
                // data: [
                //     {
                //         name: '19-20',
                //     },
                //     {
                //         name: '2021',
                //     }
                // ],
            },
            radar: [
                {
                    indicator: [
                        {text: "支撑度较高",min:0,axisLabel: {
                                show: true,
                                fontSize: 12,
                                color: '#838D9E',
                                zIndex:9999999,
                                showMaxLabel: true, //不显示最大值，即外圈不显示数字30
                                showMinLabel: true, //显示最小数字，即中心点显示0
                            },},
                        {text: "支撑度较弱",min:0},
                        {text: "支撑度一般",min:0}
                    ],
                    // axisLabel: {
                    //     show: true,
                    //     fontSize: 12,
                    //     color: '#838D9E',
                    //     zIndex:9999999,
                    //     showMaxLabel: true, //不显示最大值，即外圈不显示数字30
                    //     showMinLabel: true, //显示最小数字，即中心点显示0
                    // },
                    center: ['50%', '50%'],
                    radius: 120,
                    // startAngle: 100,
                    splitNumber: 5,
                    shape: 'polygon',
                    name: {
                        formatter: '{value}',
                        textStyle: {
                            color: '#333333',
                        }
                    },
                    splitArea: {
                        areaStyle: {
                            color: ['rgba(255,255,255, 0.2)',
                                'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)',
                                'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
                            shadowColor: 'rgba(0, 0, 0, 1)',
                            shadowBlur: 10,
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(235, 235, 235,1)'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(235, 235, 235, 1)'
                        }
                    }
                },
            ],
            series: [
                {
                    name: '雷达图',
                    type: 'radar',
                    emphasis: {
                        lineStyle: {
                            width:4
                        }
                    },
                    data: [
                        {
                            value: finishDegreeListTotal,
                            name: '绩效指标',
                            symbol: 'circle',
                            symbolSize: 5,
                            lineStyle: {
                                color:'rgba(39,205,162,1)'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            label: {
                                show: true,
                                color:'rgba(0,0,0,0)',
                                formatter: function(params) {
                                    return params.value;
                                }
                            },
                            itemStyle:{
                                color:'#27CDA2'
                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [{
                                            offset: 0,
                                            color:'rgba(39,205,162,0.4)'
                                        },
                                            {
                                                offset: 1,
                                                color:'rgba(39,205,162,0.4)'
                                            }
                                        ],
                                        false
                                    ),
                                    opacity: 1 // 区域透明度
                                },
                            },
                        },
                        {
                            value: finishDegreeList2020,
                            name: '标志性成果',
                            symbol: 'circle',
                            symbolSize: 5,
                            lineStyle: {
                                color:'rgba(250,173,20,1)'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            label: {
                                show: true,
                                color:'rgba(0,0,0,0)',
                                formatter: function(params) {
                                    return params.value;
                                }
                            },
                            itemStyle:{
                                color:'#FAAD14'
                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                      0,
                                      0,
                                      0,
                                      1,
                                      [
                                          {
                                              color: 'rgba(250,173,20,0.4)',
                                              offset: 0
                                          },
                                          {
                                              color: 'rgba(250,173,20,0.4)',
                                              offset: 1
                                          }
                                      ],
                                      false
                                    ),
                                    opacity: 1 // 区域透明度
                                },
                            },
                        },
                        {
                            value: finishDegreeList2021,
                            name: '视角',
                            symbol: 'circle',
                            symbolSize: 5,
                            lineStyle: {
                                color:'rgba(0,160,233,1)'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            label: {
                                show: true,
                                color:'rgba(0,0,0,0)',
                                formatter: function(params) {
                                    return params.value;
                                }
                            },
                            itemStyle:{
                                color:'#00A0E9'
                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [
                                            {
                                                color: 'rgba(0,160,233,0.4)',
                                                offset: 0
                                            },
                                            {
                                                color:'rgba(0,160,233,0.4)',
                                                offset: 1
                                            }
                                        ],
                                        false
                                    ),
                                    opacity: 1 // 区域透明度
                                },
                            },
                        }
                    ]
                },
            ]
        };
        myChart.setOption(option);
    }
    render() {

        return (
            <div><div id="MapaList" style={{width: '100%', height: '350px'}}></div></div>
        );
    }
}

export default MapaList;
