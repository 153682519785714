/*数据大屏*/
import React, {Component} from "react";
import {
    Form, Radio, Tabs, Button, Modal, Input, Table,message
} from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss';
import {EwmIcon} from '../../assets/svg'
import PasswordCont from "./components/passwordCont";
import ReportEditor from "./components/reportEditor";
import {queryScreenInfo,setScreenLevel,setScreenLevelPwd,setChartDisplay,setAccessLevel} from "../../api/chart/index";
import {userLogin} from "../../api/login";
import Cookies from "js-cookie";
const { TabPane } = Tabs;

@withRouter
class DataScreenPage extends Component {
    state = {
        isPassword:false,  //设置密码
        isReportEditor:false,  //新建报表
        modelType: 'add',// 新增还是编辑类型
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        screenInfo: {
            id:"",
            accessUrl:"",
            auth:"",
        },
        formValue: {
            partLevelCode: ""
        },
        editInfo: {
            id:"",
            isShow:""
        },
        auth:"",
        accessUrl:"",
        id:"",
        chartList: [],
        columns:[
            {
                title: '序号',
                dataIndex: 'id',
                key: 'key',
                align:'center',
                width: 100,
                render:(text,record,index)=>`${index+1}`
            },
            {
                title: '内容',
                dataIndex: 'chartName',
                key: 'cont',
                width: 100,
                align:'center',
                render:(text, record) => (
                    <div className='title'>
                        {text}
                    </div>
                )
            },
            {
                title: '状态',
                dataIndex: 'isShow',
                key: 'address',
                width: 100,
                align:'center',
                render: (e) =>  <div>{e === 1 ? "已显示":e === 0 ? "已隐藏":""}</div>
            },
            {
                title: '操作',
                dataIndex: 'oper',
                key: 'oper',
                width: 100,
                align:'center',
                render:(text, record) => (
                    <div className='operating'>
                        {/*<span onClick={() => this.setState({isReportEditor:true, editInfo:record})}>编辑</span>*/}
                        <span onClick={() => {this.editChartStatus(record)}}>编辑</span>
                    </div>
                )
            },
        ],
        dataSource:[
            {
                id:'1',
                cont:'专业群绩效指标专业群绩效指标总体进度总体进度',
                address:1,
            }
        ]
    }

    componentDidMount() {
        this.queryScreenInfo();
    }
    editChartStatus = (record) =>{
        this.setState({
            isReportEditor: true,
            isShow:record.isShow,
            // size:record.size,{/*暂时去除大小尺寸功能6*/}
            id:record.id
        });

    };
    // 设置密码
    setPwd = () =>{
        let formValue = this.formRef.getFormValues();
        let data = {
            schoolId: this.state.schoolId,
            id: this.state.screenInfo.id,
            pwd: formValue.pwd
        };
        setScreenLevelPwd(data).then(res => {
            if (res) {
            message.success("设置成功")
            }
            this.setState({
                isPassword:false
            })
        })
    };
    // 设置学校公开级别
    setAccessLevel = () =>{
        let data = {
            schoolId: this.state.schoolId,
            auth: this.props.form.getFieldValue('auth'),
        };
        setAccessLevel(data).then(res => {
            if (res) {
            this.queryScreenInfo();
            message.success("设置成功")
            }
            this.setState({
                isPassword:false
            })
        })

    };
    // 报表编辑
    editOk = () =>{
        let formValues = this.reportEditor.getFormValues();
        let data = {
            schoolId: this.state.schoolId,
            id: formValues.id,
            isShow: formValues.isShow,
            // size: formValues.size {/*暂时去除大小尺寸功能4*/}
        };
        setChartDisplay(data).then(res => {
            if (res) {
            message.success("编辑成功");
                this.setState({
                    isReportEditor: false
                })
                this.queryScreenInfo();
            }
        })
    };
    queryScreenInfo = () =>{
        let data = {
            schoolId: this.state.schoolId,
        };
        queryScreenInfo(data).then(res => {
            if (res) {
                this.props.form.setFieldsValue({auth:res.auth});
                this.props.form.setFieldsValue({accessUrl:res.accessUrl});
                this.setState({
                    screenInfo: res,
                    chartList:res.chartList
                });
            }
        })
    };
    accessChartScreen = () => {
        window.open(this.state.screenInfo.accessUrl)
    };
    //一键复制功能
    copyAccessKey = () => {
        const copyEle = document.querySelector('.contentText') // 获取要复制的节点
        const range = document.createRange(); // 创造range
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        range.selectNode(copyEle); // 选中需要复制的节点
        window.getSelection().addRange(range); // 执行选中元素
        const copyStatus = document.execCommand("Copy"); // 执行copy操作
        // 对成功与否定进行提示
        if (copyStatus) {
            this.mesSuccess('复制成功');
        } else {
            message.fail('复制失败');
        }
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
    }
    addReport = () =>{
        this.props.history.push({pathname:'/main/doublehigh/dataStatisticsShow',query: {name: '数据大屏新建'}})
    }
    render() {
        const {isPassword,isReportEditor,columns,dataSource,modelType,screenInfo,chartList,accessUrl,auth} =this.state;
        let {getFieldDecorator}=this.props.form;
        return (
            <div className={style.DataScreenPagecont}>
                <div className={'top-title'}>
                    <div className={'name'}>数据大屏</div>
                </div>
                <div className={'DataScreen_top warpheight'}>
                    <h3>基本数据</h3>
                    <div className="DataScreen_form">
                        <Form>
                            <Form.Item className="form_li">
                                <span className="span_firs">大屏地址</span>
                                <i className="i_em"><EwmIcon/></i>
                                {
                                    getFieldDecorator('accessUrl', {
                                    })(<Input className="contentText" value={screenInfo.accessUrl} placeholder="请输入大屏地址" />)
                                }
                                <div className="but_right">
                                    <Button type="primary" className="but_pri" onClick={this.copyAccessKey }>复制链接</Button>
                                    <Button className="but" onClick={this.accessChartScreen}>访 问</Button>
                                </div>
                            </Form.Item>
                            <Form.Item className="form_litwo">
                                <span className="span_firs">公开级别</span>
                                {getFieldDecorator('auth', {

                                })(<Radio.Group  className="Radio_select">
                                        <Radio value={"1"}>不公开（仅限登录后查看）</Radio>
                                        <Radio value={"2"}>部分公开（输入密码后查看，最新提交的数据会有5分钟左右延时）</Radio>
                                        <Radio value={"3"}>完全公开（获取链接即可访问，最新提交的数据会有10分钟左右延时）</Radio>
                                </Radio.Group>
                                )}
                                <i className="button_i" onClick={() => this.setState({isPassword: true})}>设置密码</i>
                            </Form.Item>
                        </Form>
                        <Button type={'primary'} className={'save'} onClick={this.setAccessLevel}>保存</Button>
                    </div>
                </div>
                {/*<div className={'DataScreen_bottom'}>*/}
                {/*    <div className="top_con">*/}
                {/*        <h3>报表管理</h3>*/}
                {/*        <Button type="primary" onClick={() => {*/}
                {/*            this.addReport()*/}
                {/*        }}>新建报表</Button>*/}
                {/*    </div>*/}
                {/*    <div className="bottom_table">*/}
                {/*        <Table*/}
                {/*            columns={columns} dataSource={chartList}*/}
                {/*            pagination={false}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Modal
                    title={'设置密码'}
                    visible={isPassword}
                    width={660}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    wrapClassName={style.Modal_Password}
                    footer={
                        <div className='modal_btn_ja'>
                            <Button onClick={() => this.setState({isPassword: false})}>取 消</Button>
                            <Button onClick={this.setPwd} type='primary'>确 定</Button>
                        </div>
                    }>
                    <PasswordCont formRef={(form) => this.formRef = form}/>
                </Modal>
                <Modal
                    title={modelType === 'add' ? '新建报表' : '报表编辑'}
                    visible={isReportEditor}
                    width={660}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    wrapClassName={style.Modal_Password}
                    footer={
                        <div className='modal_btn_ja'>
                            <Button onClick={() => this.setState({isReportEditor: false})}>取 消</Button>
                            <Button onClick={() => this.editOk()} type='primary'>确 定</Button>
                        </div>
                    }>
                    {/*<ReportEditor reportEditor ={(form)=>this.reportEditor=form} isShow={this.state.isShow} size={this.state.size} id={this.state.id}/>*/} {/*暂时去除大小尺寸功能5*/}
                    <ReportEditor reportEditor ={(form)=>this.reportEditor=form} isShow={this.state.isShow} id={this.state.id}/>

                </Modal>
            </div>

        );
    }
}

DataScreenPage = Form.create()(DataScreenPage);
export default DataScreenPage;
