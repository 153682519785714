/*文档管理*/
import React, {Component} from "react";
import {
    Button, Drawer, Dropdown,
    Form, Icon, Menu, Select, Table, Tabs, Tooltip
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Readdetailist from "../readdetails";
const {TabPane} = Tabs;

@withRouter
class DocumentManagementa extends Component {
    state = {
        yearIs:undefined,
        onPush:false,  //查看详情
        previewFileRes: [],//预览信息
        meritTypes: [
            {'year': '2019', 'value': 1},
            {'year': '2020', 'value': 2},
            {'year': '2021', 'value': 3},
            {'year': ' 2022', 'value': 4}
        ],
        meritOneIndicatorId:[],//指标层级
        meritOneIndicatorList:[
            {'indicatorName': '一级', 'id': 1},
            {'indicatorName': '二级', 'id': 2},
            {'indicatorName': '三级', 'id': 3},
        ],
        meritList:[
            {
                id:'1'
            }
        ],
        pageNum: 1,//当前页数
        pageSize: 20,//每页数据数量
        total: 0,//总条数
    }

    componentDidMount() {

    }
    //查询年度
    onChangeMeritType = (value) => {
        this.setState({
            meritType: value,
            meritOneIndicatorId:undefined,
        })
    }
    //查询指标层级
    onChangeOne = (value) => {
        this.setState({
            meritOneIndicatorId:value,
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            // this.getSchoolCase()
            // this.getNewSchoolTaskStatisticalSchool()
        });
    }
    //分页条数切换
    onShowSizeChange = (current, pageSize) => {
        this.setState({ pageSize,pageNum:1 }, () => {
            // this.getSchoolCase()
            // this.getNewSchoolTaskStatisticalSchool()
        });
    }
    onClose = () =>{
        this.setState({
            onPush:false,
            previewFileRes: []
        })
    }
    handPush = () =>{
        this.setState({
            onPush:true,
        })
    }
    onRef = (ref) => {
        this.child = ref
    }
    render() {
        const {yearIs,meritTypes,meritOneIndicatorId,meritOneIndicatorList,meritList,pageNum,pageSize,total,onPush} = this.state
        const {getFieldDecorator} = this.props.form;
        const columns =  [
            {
                title: '序号',
                dataIndex: 'id',
                width: 80,
                align: "center",
                key: 'key',
                render:(text,record,index)=>`${index+1}`
            },
            {
                title: '文件名',
                align: "center",
                dataIndex: 'meritType',
                key: 'meritType',
            },
            {
                title: '指标名称',
                align: "center",
                dataIndex: 'meritName',
                key: 'meritName',

            },
            {
                title: '级别',
                align: "center",
                dataIndex: 'oneIndicatorName',
                key: 'oneIndicatorName',
            },
            {
                title: '上传人',
                align: "center",
                dataIndex: 'twoIndicatorName',
                key: 'twoIndicatorName',

            },
            {
                title: '上传时间',
                dataIndex: 'threeIndicatorName',
                align: "center",
                key: 'threeIndicatorName',

            },
            {
                title: '关联层面',
                align: "center",
                dataIndex: 'directorName',
                key: 'directorName',

            },
            {
                title: '关联部门',
                dataIndex: 'endTime',
                key: 'endTime',
                align: 'center',
            },
            {
                title: '操作',
                align: "center",
                dataIndex: 'completeRate',
                key: 'completeRate',
                fixed: "right",
                width: 200,
                render:(text, record) => (
                    <div className='operating'>
                        <span onClick={()=>this.handPush(record)}>查看</span>
                        <span>下载</span>
                    </div>
                )
            }]
        return (
            <div className='DocumentManagement'>
                <div className='Document'>
                    <div className='Document-title'>
                        <p></p>
                        <span>文档管理</span>
                    </div>
                </div>
                <div className='list-itema'>
                    <Tabs className='all' defaultActiveKey="1" onChange={this.onChangeTaba}>
                        <TabPane tab="学校层面" key="1">
                        </TabPane>
                        <TabPane tab="专业群一层面" key="2">
                        </TabPane>
                        <TabPane tab="专业群二层面" key="3">
                        </TabPane>
                    </Tabs>
                </div>
                <div>
                    <div className='top_search'>
                        <div className="Learnform Learnform1">
                        <span className="li-span">
                            <i className="i">年  度</i>
                          <Select
                              style={{width: 230}}
                              placeholder="年  度"
                              allowClear={true}
                              value={yearIs}
                              onChange={this.onChangeMeritType}
                          >
                              {
                                   meritTypes && meritTypes.map(item =>
                                      <Select.Option value={item.value} >{item.year}</Select.Option>
                                  )
                              }
                            </Select>
                        </span>
                            <span className="li-span">
                            <i className="i">指标层级</i>
                            <Select
                                placeholder="请选择指标层级"
                                allowClear={true}
                                value={meritOneIndicatorId}
                                onChange={this.onChangeOne}
                                style={{width: 230}}
                            >
                                {meritOneIndicatorList&&meritOneIndicatorList.map(item=>(
                                    <Select.Option value={item.id} >{item.indicatorName}</Select.Option>
                                ))}
                            </Select>
                        </span>

                        </div>
                        <div>
                            <div className={'Learnform Learnform2'}>
                            <span className="li-span1">
                                <Button className={'resize'}>下 载</Button>
                                <Button className='chaxun' >打 包</Button>
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className='top_searchh'>
                        <div className="tabborder">
                            <div className={'tabl'}>
                                <Table cellspacing={20} columns={columns}
                                       className='tableUserStyle'
                                       scroll={{ x: 1500}}
                                       dataSource={meritList}
                                       locale={{emptyText:<><div className="Empty-conet-ss">
                                               <div className="img">
                                                   <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div></>}}
                                       pagination={
                                           {
                                               total: total,
                                               pageSize: pageSize,
                                               pageNum: pageNum,
                                               current: pageNum,
                                               onChange: this.pageChange,
                                               onShowSizeChange: this.onShowSizeChange,
                                               pageSizeOptions: ['10', '20', '30', '40'],
                                               showSizeChanger: true,
                                               // showTotal: ((total) => {
                                               //     return `共 ${total} 条`;
                                               // }),
                                           }
                                       }
                                />
                            </div>
                        </div>


                    </div>
                </div>
                {/*查看详情*/}
                <Drawer
                    title={
                        <div className='left-return'>
                            <div className= 'left-retu' onClick={this.onClose}>
                                <img className='return' src={require('../../assets/img/fanhui.png')} alt={'返回'}/>
                                <span>返回</span>
                            </div>
                            {/*<span className='center'>{previewFileRes.resourceName}</span>*/}
                            {/*<div className='right-culars'>*/}
                            {/*    <div className='particulars'>*/}
                            {/*        <img src={require('../../assets/img/yanjing.png')} alt=""/>*/}
                            {/*        <span onClick={()=>this.getChildMethod()}>详情</span>*/}
                            {/*        <div className='download'>*/}
                            {/*            <img src={require('../../assets/img/xiazai.png')} alt=""/>*/}
                            {/*            /!*<span>下载</span>*!/*/}
                            {/*            <a href={window.$$fujinDownload(yulanrecord.uploadFileId+"/"+yulanrecord.resourceName)}><span className='downloadColor'>下载</span></a>*/}
                            {/*            {userInfoId == yulanrecord.createrId || userInfoisAdmin === 1 ?*/}
                            {/*                <Dropdown overlay={<Menu>*/}
                            {/*                    <Menu.Item><span onClick={()=>this.remove(yulanrecord)} style={{color: '#40a9ff'}}>移动文件</span></Menu.Item>*/}
                            {/*                    <Menu.Item><span style={{color: '#40a9ff'}} onClick={()=>{this.setState({deleteFile: true, record: yulanrecord})}}>删除文件</span></Menu.Item>*/}
                            {/*                </Menu>}>*/}
                            {/*                    <Button className='more' type="primary" >*/}
                            {/*                        <span className='more-m'> 更多</span>*/}
                            {/*                        <Icon type="down" />*/}
                            {/*                    </Button>*/}
                            {/*                </Dropdown> : ''*/}
                            {/*            }*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    }
                    placement="right"
                    className="particulars_cont"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPush}
                >
                    <Readdetailist onRef={this.onRef}/>
                </Drawer>
            </div>

        );
    }
}

DocumentManagementa = Form.create()(DocumentManagementa);
export default DocumentManagementa;
