/*数据大屏资金使用额度*/
import React, {Component} from "react";
import {
    Form
} from "antd";
import {withRouter} from "react-router";
import * as echarts from "echarts";

@withRouter
class fundUsageLimit extends Component {
    state = {
        month: [],
    }

    componentDidMount() {
        this.props.formRef(this)
    }

    fundUsageLimit = () => {
        let date = new Date();
        let month1 = date.getMonth() + 1;
        let month2 = date.getMonth();
        let month3 = date.getMonth() - 1;
        let month4 = date.getMonth() - 2;
        let month5 = date.getMonth() - 3;
        this.state.month.push(month5 + '月')
        this.state.month.push(month4 + '月')
        this.state.month.push(month3 + '月')
        this.state.month.push(month2 + '月')
        this.state.month.push(month1 + '月')

        let data1 = [this.props.surveyExpendQuery.fourUseQuota,this.props.surveyExpendQuery.threeUseQuota, this.props.surveyExpendQuery.twoUseQuota,this.props.surveyExpendQuery.oneUseQuota,
            this.props.surveyExpendQuery.currentMonth]
        if(document.getElementById('fundUsageLimit')) {
            let myChart = echarts.init(document.getElementById('fundUsageLimit'));
            let option = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.state.month
                    // axisLine:{
                    //     show:false //y轴线消失
                    // },
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    axisTick:{
                        show: false
                    }
                },
                grid: {
                    left: '5%',
                    top: 0,
                    bottom: 0
                },
                series: [
                    {
                        data: data1,
                        type: 'line',
                        smooth: true,
                        symbol: "none",
                        areaStyle: {
                            normal: {
                                color: 'rgba(254, 85, 120, 1)' //改变区域颜色
                            }
                        },
                        itemStyle : {
                            normal: {
                                color: 'rgba(8, 26, 48, 0.8)', //改变折线点的颜色
                                lineStyle: {
                                    color: 'rgba(8, 26, 48, 0.8)' //改变折线颜色
                                }
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        return (
            <div id={'fundUsageLimit'} className={'echarts'} style={{width: '100%', height: '100%'}}></div>

        );
    }
}

fundUsageLimit = Form.create()(fundUsageLimit);
export default fundUsageLimit;
