// 各建设任务支出占比——详情
import React, {Component} from "react";
import {Form, Input, Select, Steps, Table, Tabs, Modal, Button, Menu, Dropdown} from "antd";
import {withRouter} from "react-router";
import {Rededitor,Readsign,Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import {queryProjectTaskExpendRateDetail,editChartConfition,editChartConfitionValues,clearChartFilterValues} from "../../../../../api/chart/index"
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import Cookies from "js-cookie";
import ScreeningCondition from "../../newReportFile/screeningCondition";
const {TextArea} = Input;
const {Step} = Steps;
const { TabPane } = Tabs;
@withRouter
class Edittaskspendingpercent extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 5,
        total:0,
        columns: [
            {
                title: '建设任务名称',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'type',
                key: 'type',
                render: (text, record) =>
                    this.state.queryCatgory===1?
                    <div>
                        <span>{text==1?'学校':text==2?'专业群一':text==3?'专业群二':'其他'}</span>
                    </div>:
                        <div>
                            <span>{text==1?'学校':text==2?'专业群':'其他'}</span>
                        </div>

            },
            {
                title: '年份',
                align: "center",
                width: 200,
                dataIndex: 'year',
                key: 'year',

            },
            {
                title: '实际使用金额',
                align: "center",
                width: 200,
                dataIndex: 'amount',
                key: 'amount',
                render: (text, record) =>
                    <div>
                        <span>{parseFloat(text).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','')}</span>
                    </div>

            },


            ],
        pageDataList: [
            {
                id: 1,
                constructionTaskName: '提升国际化水平',
                level:'王志伟',
                year:'2021',
                ActualAmountUsed:'4,555',


            },

        ],
        cengMian:1,//1学校2专业群一3专业群二4其他
        expendAmountSum:0,
        data1:[],
        data2:[],
        queryCatgory:0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        datanull: false,
        chartId:'',
        chartType:'',
        allConditionList:[],
        addConditionList:[],
        noAddConditionList:[],
        allConfitionValues:[],
        //筛选
        useChildFlag:false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey:0,//screening子组件控制加载
        previewFlag:0,//0:不是预览，1：是预览
    }
    componentDidMount() {
        this.queryProjectTaskExpendRateDetail()
        this.queryCatgoryById()
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }

    //查询详情列表
    queryProjectTaskExpendRateDetail = async () => {
        let data = {
            cengMian: this.state.cengMian,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
        }
        await queryProjectTaskExpendRateDetail(data).then(res => {
            if(res){
                let upChartProjectTaskExpendVo = res.upChartProjectTaskExpendVo
                let expendAmountSum = 0
                let data1 = []
                let data2 = []
                let before5Rate = 0
                let after5Amount = 0
                for (let i = 0; i < upChartProjectTaskExpendVo.length; i++) {
                    expendAmountSum += upChartProjectTaskExpendVo[i].amount
                    before5Rate += upChartProjectTaskExpendVo[i].rate
                    if(i<5){
                        data1.push({name: upChartProjectTaskExpendVo[i].projectName, icon: 'circle',amount:upChartProjectTaskExpendVo[i].amount,rate:upChartProjectTaskExpendVo[i].rate})
                        data2.push({value: upChartProjectTaskExpendVo[i].amount, name: upChartProjectTaskExpendVo[i].projectName})
                    }
                    else{
                        after5Amount += upChartProjectTaskExpendVo[i].amount
                    }
                }
                if(after5Amount>0){
                    data1.push({name: '其他', icon: 'circle',amount:after5Amount,rate:1-before5Rate})
                    data2.push({value: after5Amount, name: '其他'})
                }

                this.setState({
                    expendAmountSum:expendAmountSum,
                    data1:data1,
                    data2:data2,
                    pageDataList:res.pageData.data,
                    pageNum:res.pageData.pageNum,
                    pageSize:res.pageData.pageSize,
                    total:res.pageData.total,
                    chartId:res.chartId,
                    chartType:res.chartType,
                    useChildFlag:true,//首次进入，子组件不调用接口，赋值后再调用
                    screeningKey:this.state.screeningKey+1,//screening子组件控制加载
                    previewFlag:0,//不是预览

                },()=>{
                    if(data2.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.setState({datanull: false})
                        this.taskSpending()
                    }

                })

            }
        })
    }
    //图
    taskSpending = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('edittaskSpending')){
            let myChart = echarts.init(document.getElementById('edittaskSpending'));
            // 绘制图表
            let
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: (params) => {
                            console.log('params', params);
                            return <div><span>实际使用金额</span><span>{params.value}</span></div>;
                        },
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 550
                        }
                    },
                    color:['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58'],
                    grid: {
                        top: '15%',
                        left: '5%',
                        right: '1%',
                        bottom: 5,
                    },
                    legend: {
                        type: "scroll",
                        top: '70',
                        left: '50%',
                        orient: 'vertical',
                        selectedMode:false,
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        itemGap: 20,
                        data:this.state.data1,
                        formatter: (name) => {
                            let data1 = this.state.data1
                            let rate = 0
                            let amount = 0
                            data1.map(item=>{
                                if(name === item.name){
                                    rate = item.rate
                                    amount = item.amount
                                }
                            })
                            rate = parseFloat(rate * 100).toFixed(2).replace(".00","")
                            let namenew = name
                            if(name.length > 7){
                                namenew = name.substring(0,7) + '...'
                            }
                            return [
                                "{a|" +namenew+
                                "}",
                                "{b|" + rate + "%}",
                                "{c|￥" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','') + "}"  //a、b、x、跟下面的rich对应
                            ].join(" ");
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            color:'rgba(227, 73, 73, 1)',
                            //在rich中给formatter添加个别字体颜色
                            rich: {
                                a:{
                                    width: 120,
                                    fontSize: 14,
                                    color: '#333333',
                                    align: 'left'
                                },
                                b: {
                                    color: '#888888',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '##40a9ff',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            center: ['20%','50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        console.log('data',data.value)
                                        let html = `{b| 实际使用额度} \n {c|¥${data.value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','')}}`;
                                        return html;
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.state.data2
                        }
                    ]
                };
            myChart.setOption(option)
            myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
            myChart.on('mouseover', (v) => { //清空第一次选中
                if (v.dataIndex != 0) {
                    myChart.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: 0
                    });
                }
            });
            myChart.on('mouseout', (v) => { //鼠标离开圆环默认选第一次
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
            });
        }
    }
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }
    //层面切换
    onChangeTab = (e) =>{
        this.setState({
            cengMian:e
        },()=>{
            this.queryProjectTaskExpendRateDetail()
        })
    }
    //翻页
    onChangePage = (pageNum, filters, sorter, extra)=>{
        //预览
        if(this.state.previewFlag == 1){
            this.previewModelChart(pageNum)
        }
        //非预览
        if (this.state.previewFlag == 0){
            this.setState({
                pageNum:pageNum,
            },()=>{
                this.queryProjectTaskExpendRateDetail()
            })
        }
    }
    //切页
    sizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.queryProjectTaskExpendRateDetail()
        })
    }

    //————————筛选操作——————————
    //编辑图表筛选条件api
    editChartConfition  =  async (data) => {
        await editChartConfition(data)
    }
    //编辑图表筛选条件的值api
    editChartConfitionValues =  async (data) => {
        await editChartConfitionValues(data).then(res=>{})
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        await clearChartFilterValues({chartId:this.state.chartId})
    }
    //刷新数据
    refreshPageData = ()=>{
        this.queryProjectTaskExpendRateDetail()
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res=>{
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension:'1'},()=>{
                this.refreshPageData()
            })

        })
    }
    //按钮保存(编辑筛选条件值、保存图表)
    saveOk = ()=>{
        let conditionValues = this.editCondition.getConditionValueChange()
        let dataPost = {
            chartId:this.state.chartId,
            conditionValuesQo:conditionValues
        }
        this.editChartConfitionValues(dataPost).then(res=>{
            this.mesSuccess("操作成功")
            this.refreshPageData()
        })
    }
    //编辑筛选条件
    editConditionOk = ()=>{
        if(!this.state.chartId){
            this.mesWarning('请先保存图表')
            return
        }
        let conditions = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < conditions.length; i++) {
            data.push({conditionId:conditions[i]})
        }
        this.editChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                this.mesSuccess("操作成功")
                this.refreshPageData()
            })
        })

    }
    //导出任务图表
    exportExcel = () => {
            window.location.href = "http://" + window.location.hostname + ":3000/api/ss/api/v1/chart/exportExcel?" +
                "chartId=" + this.state.chartId + "&schoolId=" + this.state.schoolId + "&chartType=" + 7 + "&cengMian=" + this.state.cengMian + ""
    }
    //预览图表
    previewModelChart = async(pageNum) => {

        let chartConditionValuesQoList = this.editCondition.getConditionValueChange()
        let data = {
            pageNum:pageNum,
            pageSize:this.state.pageSize,
            cengMian: this.state.cengMian,
            chartConditionValuesQoList:chartConditionValuesQoList,
            code:'1',//预览
        }
        await queryProjectTaskExpendRateDetail(data).then(res => {
            if(res){
                let upChartProjectTaskExpendVo = res.upChartProjectTaskExpendVo
                let expendAmountSum = 0
                let data1 = []
                let data2 = []
                let before5Rate = 0
                let after5Amount = 0
                for (let i = 0; i < upChartProjectTaskExpendVo.length; i++) {
                    expendAmountSum += upChartProjectTaskExpendVo[i].amount
                    before5Rate += upChartProjectTaskExpendVo[i].rate
                    if(i<5){
                        data1.push({name: upChartProjectTaskExpendVo[i].projectName, icon: 'circle',amount:upChartProjectTaskExpendVo[i].amount,rate:upChartProjectTaskExpendVo[i].rate})
                        data2.push({value: upChartProjectTaskExpendVo[i].amount, name: upChartProjectTaskExpendVo[i].projectName})
                    }
                    else{
                        after5Amount += upChartProjectTaskExpendVo[i].amount
                    }
                }
                if(after5Amount>0){
                    data1.push({name: '其他', icon: 'circle',amount:after5Amount,rate:1-before5Rate})
                    data2.push({value: after5Amount, name: '其他'})
                }

                this.setState({
                    expendAmountSum:expendAmountSum,
                    data1:data1,
                    data2:data2,
                    pageDataList:res.pageData.data,
                    pageNum:res.pageData.pageNum,
                    pageSize:res.pageData.pageSize,
                    total:res.pageData.total,
                    chartId:res.chartId,
                    chartType:res.chartType,
                    previewFlag:1,//是预览

                },()=>{
                    if(data2.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.setState({datanull: false})
                        this.taskSpending()
                    }

                })

            }
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor,queryCatgory} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['20', '40', '60', '80'],
            onChange: this.onChangePage,
            onShowSizeChange: this.sizeChange
        }
        return (
            <div className={style.edittaskspendingpercent}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>各建设任务支出占比</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'} onClick={()=>this.exportExcel()} >
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'} onClick={()=>this.saveOk()}>
                            <span>保存</span>
                        </div>
                        <Dropdown overlay={<Menu>
                            <Menu.Item><span>订阅设置</span></Menu.Item>
                        </Menu>}>
                            <div className={'button_topG'}>
                                <span>更多</span>
                            </div>
                        </Dropdown>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(e)=>this.onChangeTab(e)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群一'} key="2">
                                </TabPane>
                                <TabPane  tab={'专业群二'} key="3">
                                </TabPane>
                                <TabPane  tab={'其他'} key="4">
                                </TabPane>
                            </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(e)=>this.onChangeTab(e)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane  tab={'其他'} key="4">
                                </TabPane>
                            </Tabs>}
                            {
                                this.state.datanull ?
                                    <div className="Empty-conet-ss Empty-conet-ss-echarts">
                                        <div className="img">
                                            <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            <span style={{textAlign:'center'}}>暂无数据</span>
                                        </div>
                                    </div> :
                                    <div id={'edittaskSpending'} className={'echarts'} style={{width: '100%', height: '320px'}}></div>
                            }
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>各建设任务支出占比</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 700 , y:290}}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>建设任务</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>实际使用金额</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.screeningKey}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                editCondition ={(child)=>this.editCondition=child}
                            />
                            <p className={'shaixuank'} onClick={this.handLook}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span className={'span_add'} disabled={true}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.chartType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>

                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span onClick={()=>this.clearCondition()}>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span onClick={()=>this.previewModelChart()}>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

Edittaskspendingpercent = Form.create()(Edittaskspendingpercent);
export default Edittaskspendingpercent;
