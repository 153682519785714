/*标志性成果情况*/
import React, {Component} from 'react';
import {Row, Col, Button, Modal,Input, message, Form,InputNumber} from 'antd';
import './index.scss';
import {
    getExpertResultsSummary
} from '../../../../api/doublehigh';


const {TextArea} = Input;
class AchievementsShuju extends React.Component {
    state = {
        // schoolEduCode: this.props.schoolEduCode,
        // summaryInfo: this.props.summaryInfo,
    };
    componentDidMount() {
        // this.getExpertResultsSummary()
    }

    // getExpertResultsSummary =()=>{
    //     let data = {
    //         schoolEduCode: this.state.schoolEduCode
    //     }
    //     getExpertResultsSummary(data).then(res =>{
    //         console.log('res 111', res)
    //         if(res){
    //             this.setState({
    //                 summaryInfo: res
    //             })
    //         }
    //     })
    // }



    componentWillMount() {

    }

    render() {
        const {summaryInfo} = this.props;
        const {} = this.props.form;
        return (
            <div className="Achievementsshuju_cont">
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_first li_redone'}>
                            <div className={'div'}>
                                <h5>高校“双带头人”教师党支部书记工作室</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redone'}>
                            <div className={'div'}>
                                <h5>全国党建工作示范高校、标杆院系、样板支部培育创建单位（第一批验收、第二批立项）</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redone'}>
                            <div className={'div'}>
                                <h5>课程思政示范课程、教学名师和团队、课程思政教学研究示范中心</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redone'}>
                            <div className={'div'}>
                                <h5>高校思想政治工作精品项目</h5>
                            </div>
                        </li>
                        <li className={'li_con li_redone'}>
                            <div className={'div_top'}>
                                <h5>全国高校思想政治理论课教学展示活动获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>特等奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                            </div>
                        </li>
                        <li className={'li_first li_redone'}>
                            <div className={'div'}>
                                <h5>“全国优秀共青团员”“全国优秀共青团干部 ”“全国五四红旗团委 (团支部)”</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleB : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleC : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleD : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleE : ''}</span>
                        </li>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleF : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleG : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleH : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleI : ''}</span>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        {/*<li className={'li_first'}>*/}
                        {/*    <div className={'div'}>*/}
                        {/*        <h5>高校原创文化精品推广行动计划”名单</h5>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>“十三五”职业教育国家规划教材书目教材</h5>
                            </div>
                        </li>
                        <li className={'li_four li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>全国教材建设奖（第一主持）</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>特等奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>先进个人奖励、先进集体</span>
                            </div>
                        </li>
                        <li className={'li_con li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>全国职业院校技能大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>全国职业院校技能大赛拟设赛项承办校</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleJ : ''}</span>
                        </li>
                        <li className={'li_four'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleK : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleL : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleM : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleN : ''}</span>
                            </div>
                        </li>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleO : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleP : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleQ : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleR : ''}</span>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_four li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>全国首届职业技能大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>金奖</span>
                                <span>银奖</span>
                                <span>铜奖</span>
                                <span>优胜奖</span>
                            </div>
                        </li>
                        <li className={'li_con li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>世界技能大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>金奖</span>
                            </div>
                        </li>
                        <li className={'li_four li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>中国“互联网+”大学生创新创业大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>最具人气奖</span>
                                <span>金奖</span>
                                <span>银奖</span>
                                <span>铜奖</span>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_four'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleS : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleT : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleU : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleV : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'} style={{width:'400px'}}>
                            <span>{summaryInfo ? summaryInfo.titleW : ''}</span>
                        </li>
                        <li className={'li_four'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleX : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleY : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleZ : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAa : ''}</span>
                            </div>
                        </li>

                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_con li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>“挑战杯”全国大学生课外学术科技作品竞赛获奖（第16届）</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                            </div>
                        </li>
                        <li className={'li_three li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>全国第六届大学生艺术展演获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>校长风采奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                                <span>优秀节目创作奖</span>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleAb : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAc : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAd : ''}</span>
                            </div>
                        </li>
                        <li className={'li_three'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleAe : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAf : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAg : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAh : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAi : ''}</span>
                            </div>
                        </li>

                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_con li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>全国大学生电子设计竞赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>IT杯奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>全国创新创业典型经验高校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>青年文明号</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>国防教育特色学校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>示范性职业教育集团（联盟）培育单位</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>国家备案众创空间</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>全国普通高校中华优秀传统文化传承基地</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleAj : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAk : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAl : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAm : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAn : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAo : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAp : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAq : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAr : ''}</span>
                        </li>

                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>

                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>“互联网+中国制造2025”产教融合促进计划建设院校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>全国普通高校毕业生就业创业工作典型案例</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>“助力乡村振兴”全国大学生暑期社会实践志愿服务活动入选团队</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redtwo'}>
                            <div className={'div'}>
                                <h5>“推普脱贫攻坚”全国大学生暑期社会实践专项活动入围团队</h5>
                            </div>
                        </li>
                        <li className={'li_three li_redtwo'}>
                            <div className={'div_top'}>
                                <h5>“全国大学生网络文化节 ”“全国高校网络教育优秀作品推选展示活动”获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>网络人气奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                                <span>优秀奖</span>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAs : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAt : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAu : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleAv : ''}</span>
                        </li>
                        <li className={'li_three'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleAw : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAx : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAy : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleAz : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleBa : ''}</span>
                            </div>
                        </li>

                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>国家“万人计划”入选人员</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国优秀教师、全国优秀教育工作者</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国教书育人楷模</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国教育系统先进集体、全国模范教师、全国教育系统先进工作者</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国五一劳动奖、工人先锋号</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国技术能手</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国青年岗位能手</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>国家级职业教育教师教学创新团队</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国高校黄大年式教师团队</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBb : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBc : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBd : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBe : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBf : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBg : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBh : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBi : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBj : ''}</span>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_con li_redthree'}>
                            <div className={'div_top'}>
                                <h5>"全国职业院校技能大赛教学能力比赛获奖（第一单位）"</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>高校辅导员年度人物</h5>
                            </div>
                        </li>
                        <li className={'li_con li_redthree'}>
                            <div className={'div_top'}>
                                <h5>全国辅导员素质能力大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>全国行业职业教育教学指导委员会、教育部职业院校教学（教育）指导委员会委员数量</h5>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <div className={'div'}>
                                <h5>全国脱贫攻坚先进集体和先进个人</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleBk : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleBl : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleBm : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBn : ''}</span>
                        </li>
                        <li className={'li_con'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleBo : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleBp : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleBq : ''}</span>
                            </div>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBr : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBs : ''}</span>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>首批高等职业学校“双师型”教师队伍建设典型案例、首批高等职业学校“双师型”教师个人专业发展典型案例</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>国家级职业教育教师教学创新团队课题研究项目</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>教育部首批职业院校校长培训基地</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redthree'}>
                            <div className={'div'}>
                                <h5>国家级职业教育教师教学创新团队培训基地</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>乡村振兴人才培养优质校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>国家自然科学、社会科学基金项目</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>全国教育科学“十三五”规划课题立项</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>国家艺术基金资助项目立项</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>高等学校科学研究优秀成果奖（科学技术）</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>国家级专业技术人员继续教育基地</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfour'}>
                            <div className={'div'}>
                                <h5>教育部人文社会科学研究专项任务项目立项</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBt : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBu : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBv : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBw : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBx : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBy : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleBz : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCa : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCb : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCc : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCd : ''}</span>
                        </li>

                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_first li_redfive'}>
                            <div className={'div'}>
                                <h5>第二届全国文明校园</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfive'}>
                            <div className={'div'}>
                                <h5>公共机构水效领跑者(2021-2023年）</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfive'}>
                            <div className={'div'}>
                                <h5>节约型公共机构示范单位、公共机构能效领跑者</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redfive'}>
                            <div className={'div'}>
                                <h5>全国急救教育试点学校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>全国职业院校数字校园建设样板校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>国家精品在线开放课程（高职）</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>职业教育专业教学资源库立项建设项目（含升级改造）</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>网络学习空间应用普及活动优秀学校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>职业教育示范性虚拟仿真实训基地培育项目</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redsex'}>
                            <div className={'div'}>
                                <h5>教育信息化教学应用实践共同体项目</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCe : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCf : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCg : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCh : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCi : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCj : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCk : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCl : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCm : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCn : ''}</span>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_sex li_redeven'}>
                            <div className={'div_top'}>
                                <h5>“一带一路”暨金砖国家技能发展与技术创新大赛获奖</h5>
                            </div>
                            <div className={'div_bottom'}>
                                <span>巾帼英才奖</span>
                                <span>特等奖</span>
                                <span>一等奖</span>
                                <span>二等奖</span>
                                <span>三等奖</span>
                                <span>优秀奖</span>
                                <span>团体金奖</span>
                                <span>团体银奖</span>
                                <span>团体铜奖</span>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_sex'}>
                            <div className={'cont_ta'}>
                                <span>{summaryInfo ? summaryInfo.titleCo : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCp : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCq : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCr : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCs : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCt : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCu : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCv : ''}</span>
                                <span>{summaryInfo ? summaryInfo.titleCw : ''}</span>
                            </div>
                        </li>
                    </div>
                </div>
                <div className={'ul_table'}>
                    <div className={'ul_div'}>
                        <li className={'li_first li_redeven'}>
                            <div className={'div'}>
                                <h5>“人文交流经世项目”首批“经世国际学院”入选院校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redeven'}>
                            <div className={'div'}>
                                <h5>职业教育“走出去”试点项目学校</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redeven'}>
                            <div className={'div'}>
                                <h5>"中文工坊"项目</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redeven'}>
                            <div className={'div'}>
                                <h5>“中文+职业技能”教学资源建设项目</h5>
                            </div>
                        </li>
                        <li className={'li_first li_redeven'}>
                            <div className={'div'}>
                                <h5>“中文+职业技能”线上团组交流项目</h5>
                            </div>
                        </li>
                    </div>
                    <div className={'ul_divbottom'}>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCx : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCy : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleCz : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleDa : ''}</span>
                        </li>
                        <li className={'li_first'}>
                            <span>{summaryInfo ? summaryInfo.titleDb : ''}</span>
                        </li>
                    </div>
                </div>
            </div>
        );
    }
}

AchievementsShuju = Form.create()(AchievementsShuju);
export default AchievementsShuju;
