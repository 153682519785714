/*数据统计展示*/
import React, {Component} from "react";
import {
    Form, Progress, Tabs, Button, Drawer
} from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss';
import {Goup,Task,ThreeDian} from '../../assets/svg'
import FundUsageLimit from './components/fundUsageLimit' //资金使用额度
import TaskPerformance from './components/taskPerformance' //资金与绩效柱形图
import TaskSpending from './components/taskSpending'  //任务支出饼图
import PerformanceIndicator from './components/performanceIndicator' //绩效指标完成度雷达图
import MeetPerformanceIndicators from './components/meetPerformanceIndicators' //绩效指标达标情况柱状图
import NewReport from './components/newReport/index'
import Cookies from "js-cookie";
import {surveyProjectQuery,surveyMeritQuery,surveyExpendQuery,meritIndicatorsQuery,queryTaskChart,queryTaskMeritCompByDirector,queryTaskMeritTrend,queryAllCustomChart} from "../../api/chart"
import CompleteModal from "../performancelist/components/performanceDetails/components/completeModal";
import {queryCatgoryById} from "../../api/synchrodatment";
import Template1 from "./components/template/template1";
import Template2 from "./components/template/template2";
import Template3 from "./components/template/template3";
import Template4 from "./components/template/template4";
import Template5 from "./components/template/template5";
import Template6 from "./components/template/template6";
import CustomChart from "./components/template/customChart";
const { TabPane } = Tabs;

@withRouter
class DataStatisticsShow extends Component {
    state = {
        typeList: [
            {
                id: 0,
                name: '学校'
            },
            {
                id: 1,
                name: '专业群'
            },
            {
                id: 2,
                name: '其他'
            }
        ],
        typeName: '学校',
        typeList1: [
            {
                id: 0,
                name: '学校'
            },
            {
                id: 1,
                name: '专业群'
            },
            {
                id: 2,
                name: '其他'
            }
        ],
        typeName1: '学校',
        typeList2: [
            {
                id: 0,
                name: '学校'
            },
            {
                id: 1,
                name: '专业群'
            },
            {
                id: 2,
                name: '其他'
            }
        ],
        typeName2: '学校',
        newReportModal: false,
        surveyProjectQueryList: [],
        surveyProjectQuery: [],
        surveyMeritQuery: [],
        surveyMeritQueryList: [],
        surveyExpendQuery: [],
        surveyExpendQueryList: [],
        queryTaskChartList:[],
        meritIndicatorsQueryList:[],
        trendType:1,//1任务2绩效
        personOfCompletedVos:[],//任务绩效负责人完成数量
        cengMian:1,//1学校，2群一，3群二，4其他
        cengMianRate:1,//1学校，2群一，3群二，4其他
        cengMianMeritCompleteDu:1,//1学校，2群一，3群二，4其他
        dateType:3,//1七日内 2一月内 3全年
        type:1,
        queryCatgory:0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        code:0,
        chartProjectId:1,
        chartProjectType:1,
        chartMeritId:2,
        chartMeritType:2,
        chartExpendId:3,
        chartExpendType:3,
        defaultType:1,
        chartTaskId:6,
        chartTaskType:6,
        chartMeritCompletionId:9,
        chartMeritCompletionType:9,
        customChartDataList:[],
        taskPerformanceKey:0,//
    }
    componentDidMount() {
        this.surveyProjectQuery()
        this.surveyMeritQuery()
        this.surveyExpendQuery()
        this.queryTaskChart()
        this.queryTaskMeritTrend()
        this.queryCatgoryById()
        this.queryAllCustomChart()
        if(this.props.location.query && this.props.location.query.name === '数据大屏新建'){
            this.setState({newReportModal: true})
        }

    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    queryTaskMeritTrend = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType,
            schoolId:this.state.schoolId,
        }
        await queryTaskMeritTrend(data).then(res => {
            if(res){
                this.setState({personOfCompletedVos:res.personOfCompletedVos})

            }

        })
    }

    //概况图表查建设任务进度
    surveyProjectQuery = async () => {
        let data={
            code:0,
            chartId:this.state.chartProjectId,
            chartType:this.state.chartProjectType,
            defaultType:this.state.defaultType,
            schoolId:this.state.schoolId
        }
        await surveyProjectQuery(data).then(e => {
            this.setState({
                surveyProjectQuery:e.surveyVo,
            })
        })
    }
    //概况图表绩效进度
    surveyMeritQuery = async () => {
        let data={
            code:0,
            chartId:this.state.chartMeritId,
            chartType:this.state.chartMeritType,
            defaultType:this.state.defaultType,
            schoolId:this.state.schoolId
        }
        await surveyMeritQuery(data).then(e => {
            this.setState({
                surveyMeritQuery:e.surveyVo
            })
        })
    }

    //概况图资金使用情况
    surveyExpendQuery = async () => {
        let data={
            code:0,
            chartId:this.state.chartExpendId,
            chartType:this.state.chartExpendType,
            defaultType:this.state.defaultType,
            schoolId:this.state.schoolId
        }
        await surveyExpendQuery(data).then(e => {
            this.setState({
                surveyExpendQuery:e.surveyVo,
            },()=>{
                this.formRef.fundUsageLimit()
            })
        })
    }
    //任务图
    queryTaskChart = async () => {
        let data ={
            code:0,
            chartId:this.state.chartTaskId,
            chartType:this.state.chartTaskType,
            defaultType:this.state.defaultType,
            schoolId:this.state.schoolId
        }
        await queryTaskChart(data).then(e => {
            this.setState({
                queryTaskChartList:e,
            })
        })
    }
    //自定义图
    queryAllCustomChart = async () => {
        let data ={

        }
        await queryAllCustomChart(data).then(res => {
            if(res){
                let customChartDataList = []
                for (let i = 0; i < res.length; i++) {
                    customChartDataList.push({
                        dataList:res[i].customChartImgDataVoList,
                        shape:res[i].shape,
                        id:res[i].chartId,
                        name:res[i].chartName,
                    })
                }
                this.setState({
                    customChartDataList:customChartDataList
                })
            }
        })
    }
    changeType = (id, name) =>{
        this.setState({typeName: name})
    }
    changeType1 = (id, name) =>{
        this.setState({typeName1: name})
    }
    changeType2 = (id, name) =>{
        this.setState({typeName2: name})
    }
    onChangeTabofTrend = (item)=>{
        this.setState({trendType:item,taskPerformanceKey:this.state.taskPerformanceKey+1},()=>{
            this.queryTaskMeritTrend()
        })
    }
    onChangeTabOfcengMianRate = (item)=>{
        this.setState({cengMianRate:item})
    }
    onChangeTabOfMeritCompleteDu = (item)=>{
        this.setState({cengMianMeritCompleteDu:item})
    }
    onChangeTabMeetPerformanceIndicators = (item)=>{
        this.setState({type:item})
    }
    //新建报表
    newAdd = ()=>{
        // this.setState({newReportModal: true})
        this.bianji('自定义报表','/main/doublehigh/unnamedBlocks')
    }
    //1七日内/30日/全年切换
    dateConversion = (dateType)=>{
        this.setState({dateType,taskPerformanceKey:this.state.taskPerformanceKey+1},()=>{
            this.queryTaskMeritTrend()
        })
    }

    onClose = () => {
        this.setState({
            newReportModal: false,
        });
    };
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    render() {
        const {typeList,typeList1,typeList2,newReportModal,surveyProjectQuery,
            surveyProjectQueryList,surveyMeritQuery,surveyMeritQueryList,surveyExpendQuery,surveyExpendQueryList,
            queryTaskChartList,meritIndicatorsQueryList,queryCatgory,dateType,customChartDataList} =this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'top-title'}>
                    <div className={'name'}>数据统计</div>
                    <div className={'top-caozuo'}>
                        {/*<Button type={'primary'}>图标排序</Button>*/}
                        {/*<Button onClick={()=>this.newAdd()}>新建报表</Button>*/}
                        <Button onClick={()=>{this.props.history.push('/main/doublehigh/unnamedBlocks')}}>新建报表</Button>

                    </div>
                </div>
                <div className={'charts-size-wrap'}>
                    <div className={'overview-wrap'}>
                        <div className={'title'}>
                            概览
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('概览','/main/doublehigh/editconstructionscheduleview')}><Task/></div>
                                <ThreeDian/>

                        </div>
                    </div>
                    <ul className={'top-wrap'}>
                        <li>
                            <div className={'text'}>建设任务进度</div>
                            <div className={'percentage'}>{surveyProjectQuery.projectProgress}%</div>
                            <Progress percent={surveyProjectQuery.projectProgress} className={'progress'} strokeWidth={'10px'} strokeColor={'#FF7214'} showInfo={false}/>
                            <div className={'texttwo'}>
                                <span>近30日增长率：{surveyProjectQuery.growthRate}%</span>
                                <Goup/>
                            </div>
                        </li>
                        <li>
                            <div className={'text'}>绩效完成进度</div>
                            <div className={'percentage'}>{surveyMeritQuery.meritProgress}%</div>
                            <Progress percent={surveyMeritQuery.meritProgress} className={'progress'} strokeWidth={'10px'} strokeColor={'#33C6C3'} showInfo={false}/>
                            <div className={'texttwo'}>
                                <span>平均完成度：{surveyMeritQuery.meritAverageProgress}%</span>
                            </div>
                        </li>
                        <li>
                            <div className={'text'}>资金使用额度</div>
                            <div className={'percentage'}>¥{surveyExpendQuery.expendUseQuota?surveyExpendQuery.expendUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0}元</div>
                            <FundUsageLimit  surveyExpendQuery={this.state.surveyExpendQuery} formRef={(form) => this.formRef = form}/>
                            <div className={'texttwo'}>
                                <span>近5月月均使用额：¥{surveyExpendQuery.monthlyUseQuota?surveyExpendQuery.monthlyUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={'task-performance width100'}>
                    <div className={'title'}>
                        任务与绩效进展走势
                        <div className={'caozuo'}>
                            <div onClick={()=>this.bianji('任务与绩效进展走势','/main/doublehigh/editorUnnameds')}><Task/></div>
                            <ThreeDian/>
                        </div>
                    </div>
                    <div className='tab-wrap'>
                        <Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabofTrend(item)}>
                            <TabPane tab={'任务'} key="1">
                            </TabPane>
                            <TabPane  tab={'绩效'} key="2">
                            </TabPane>
                        </Tabs>
                        <div className={'riqi'}>
                            <span className={dateType == 1 ? 'active' : ''} onClick={()=>{this.dateConversion(1)}}>7日</span>
                            <span className={dateType == 2 ? 'active' : ''} onClick={()=>{this.dateConversion(2)}}>30日</span>
                            <span className={dateType == 3 ? 'active' : ''} onClick={()=>{this.dateConversion(3)}}>全年</span>
                        </div>
                        <div className={'task-performance-cont'}>
                            <div className={'cont-left-echarts'}>
                                <span className={'name'}>完成数量</span>
                                <TaskPerformance key={this.state.taskPerformanceKey} trendType={this.state.trendType} dateType={this.state.dateType}/>
                            </div>
                            <div className={'cont-right-ranking'}>
                                <span className={'name'}>负责人完成情况排名</span>
                                <div className={'center-wrap'}>
                                {
                                    this.state.personOfCompletedVos && this.state.personOfCompletedVos.map((item,index)=>(

                                                <div className={'center'}>
                                                    <div className={'center-left'}>
                                                        <span className={'top'}>{index+1}</span>
                                                        <span className={'span-name'}>{item.completePerson}</span>
                                                    </div>
                                                    <span className={'center-right'}>{item.completeCount}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'jiansherenwu width50'}>
                        <div className={'title'}>
                            各建设任务支出占比
                            <div className={'caozuo'} >
                                <div onClick={()=>this.bianji('各建设任务支出占比','/main/doublehigh/edittaskspendingpercent')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane>
                            <TabPane  tab={'专业群二'} key="3">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <TaskSpending key={this.state.cengMianRate} cengMian={this.state.cengMianRate}/>
                    </div>
                    <div className={'renwutonhji width50'}>
                        <div className={'title title100'}>
                            任务统计
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('任务统计','/main/doublehigh/editortaskStatistical')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        <ul className={'renwutonhji-ul'}>
                            <li>
                                <span>总任务数</span>
                                <span className={'number'} style={{color: '#3E78ED'}}>{queryTaskChartList.totalTask?queryTaskChartList.totalTask:0}</span>
                                <Progress percent={queryTaskChartList.totalTask?queryTaskChartList.totalTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#3E78ED'} showInfo={false}/>
                            </li>
                            <li>
                                <span>已完成</span>
                                <span className={'number'} style={{color: '#F4DF58'}}>{queryTaskChartList.doneTask?queryTaskChartList.doneTask:0}</span>
                                <Progress percent={queryTaskChartList.doneTask?queryTaskChartList.doneTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#F4DF58'} showInfo={false}/>
                            </li>
                            <li>
                                <span>已逾期</span>
                                <span className={'number'} style={{color: '#23B899'}}>{queryTaskChartList.expiredTask?queryTaskChartList.expiredTask:0}</span>
                                <Progress percent={queryTaskChartList.expiredTask?queryTaskChartList.expiredTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#23B899'} showInfo={false}/>
                            </li>
                            <li>
                                <span>未完成</span>
                                <span className={'number'} style={{color: '#F07661'}}>{queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask:0}</span>
                                <Progress percent={queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#F07661'} showInfo={false}/>
                            </li>
                            <li>
                                <span>待认领</span>
                                <span className={'number'} style={{color: '#966CFC'}}>{queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask:0}</span>
                                <Progress percent={queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#966CFC'} showInfo={false}/>
                            </li>
                            <li>
                                <span>时间待定</span>
                                <span className={'number'} style={{color: '#7A79FF'}}>{queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask:0}</span>
                                <Progress percent={queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#7A79FF'} showInfo={false}/>
                            </li>
                        </ul>
                    </div>
                    <div className={'jiduzhibiao width50'}>
                        <div className={'title'}>
                            绩效指标完成度
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('绩效指标完成度','/main/doublehigh/editorPerformanceindicator')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane>
                            <TabPane  tab={'专业群二'} key="3">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <PerformanceIndicator key={this.state.cengMianMeritCompleteDu} cengMian={this.state.cengMianMeritCompleteDu}/>
                    </div>
                    <div className={'jixiaozhibiao width50'}>
                        <div className={'title'}>
                            绩效指标达标情况
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('绩效指标达标情况','/main/doublehigh/edittaskachievementofindicators')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane><TabPane  tab={'专业群二'} key="3">
                        </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <MeetPerformanceIndicators   key={this.state.type} type={this.state.type}  meritIndicatorsQueryList={meritIndicatorsQueryList}/>
                    </div>

                    {/*<Template1/>*/}
                    {/*<Template2/>*/}
                    {/*<Template6/>*/}
                    {
                        customChartDataList && customChartDataList.map((item,index)=>(
                                <CustomChart key={item.id} chartData={item}/>
                        ))
                    }

                </div>
                <Drawer title={false}
                        placement="right"
                        width={740}
                        closable={false}
                        // maskClosable={true}
                        onClose={this.onClose}
                        visible={newReportModal}
                        className={'newReportDrawer'}>
                    <NewReport/>
                    <div className={"footer"}>
                        <Button onClick={()=>{this.setState({newReportModal:false})}}>关闭</Button>
                    </div>
                </Drawer>
            </div>

        );
    }
}

DataStatisticsShow = Form.create()(DataStatisticsShow);
export default DataStatisticsShow;
