/*任务支出饼图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';
import {queryProjectTaskExpendRate} from "../../../api/chart/index"

@withRouter
class TaskSpending extends Component {
    state = {
        cengMian:this.props.cengMian,
        expendAmountSum:0,
        data1:[],
        data2:[],
        datanull: false

    }

    componentDidMount() {
        this.queryProjectTaskExpendRate()
    }
    queryProjectTaskExpendRate = async () => {
        let data = {
            cengMian: this.state.cengMian
        }
        await queryProjectTaskExpendRate(data).then(res => {
            if(res){
                let upChartProjectTaskExpendVo = res.upChartProjectTaskExpendVo
                let expendAmountSum = 0
                let data1 = []
                let data2 = []
                let before5Rate = 0
                let after5Amount = 0
                for (let i = 0; i < upChartProjectTaskExpendVo.length; i++) {
                    expendAmountSum += upChartProjectTaskExpendVo[i].amount
                    before5Rate += upChartProjectTaskExpendVo[i].rate
                    if(i<5){
                        data1.push({name: upChartProjectTaskExpendVo[i].projectName, icon: 'circle',amount:upChartProjectTaskExpendVo[i].amount,rate:upChartProjectTaskExpendVo[i].rate})
                        data2.push({value: upChartProjectTaskExpendVo[i].amount, name: upChartProjectTaskExpendVo[i].projectName})
                    }
                    else{
                        after5Amount += upChartProjectTaskExpendVo[i].amount
                    }
                }
                if(after5Amount>0){
                    data1.push({name: '其他', icon: 'circle',amount:after5Amount,rate:1-before5Rate})
                    data2.push({value: after5Amount, name: '其他'})
                }
                this.setState({
                    expendAmountSum:expendAmountSum,
                    data1:data1,
                    data2:data2
                },()=>{
                    if(data2.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.taskSpending()
                        this.setState({datanull: false})
                    }
                })
            }
        })
    }
    taskSpending = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('taskSpending')){
            let myChart = echarts.init(document.getElementById('taskSpending'));
            // 绘制图表
            let
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: (params) => {
                            console.log('params', params);
                            return <div><span>实际使用金额</span><span>{this.state.expendAmountSum}</span></div>;
                        },
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 550
                        }
                    },
                    color:['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58'],
                    grid: {
                        top: '15%',
                        left: '0%',
                        right: '1%',
                        bottom: 5,
                    },
                    legend: {
                        type: "scroll",
                        top: '35',
                        right: 0,
                        orient: 'vertical',
                        selectedMode:false,
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        itemGap: 20,
                        data:this.state.data1,
                        formatter: (name) => {
                            let data1 = this.state.data1
                            let rate = 0
                            let amount = 0
                            data1.map(item=>{
                                if(name === item.name){
                                    rate = item.rate
                                    amount = item.amount
                                }
                            })
                            rate = parseFloat(rate * 100).toFixed(2).replace(".00","")
                            let namenew = name
                            if(name.length > 7){
                                namenew = name.substring(0,7) + '...'
                            }
                            return [
                                "{a|" +namenew+
                                "}",
                                "{b|" + rate + "%}",
                                "{c|￥" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','') + "}"  //a、b、x、跟下面的rich对应
                            ].join(" ");
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            color:'rgba(227, 73, 73, 1)',
                            //在rich中给formatter添加edittaskspendingpercent个别字体颜色
                            rich: {
                                a:{
                                    width: 110,
                                    fontSize: 14,
                                    color: '#333333',
                                    align: 'left'
                                },
                                b: {
                                    color: '#888888',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '##40a9ff',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            center: ['25%','50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        let html = `{b| 实际使用额度} \n {c|¥${data.value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','')}}`;
                                        return html;
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.state.data2
                        }
                    ]
                };
            myChart.setOption(option)
            myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
            myChart.on('mouseover', (v) => { //清空第一次选中
                if (v.dataIndex !== 0) {
                    myChart.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: 0
                    });
                }
            });
            myChart.on('mouseout', (v) => { //鼠标离开圆环默认选第一次
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
            });
        }
    }
    render() {
        return (
            <div style={{height: '260px', float: 'left',width: '100%',marginTop:'50px',padding: '0 30px'}}>
                {
                    this.state.datanull ?
                        <div className="Empty-conet-ss Empty-conet-ss-echarts">
                            <div className="img">
                                <img src={require("../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                <span>暂无数据</span>
                            </div>
                        </div> :
                        <div id={'taskSpending'} className={'echarts'} style={{width: '100%', height: '260px'}}></div>
                }
            </div>

        );
    }
}

TaskSpending = Form.create()(TaskSpending);
export default TaskSpending;
