/*栏目管理*/
import React, {Component} from "react";
import {
    Input, Select, Modal, Form, Tabs
} from "antd";
import "../../personalCenter/index.scss";
import "../../../styles/global.less"
import {withRouter} from "react-router";
import Cookies from "js-cookie";
@withRouter
class PersonalPassword extends Component {
    state = {
        userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo : "",

    };

    componentDidMount() {
        this.props.onRef(this)
    }


    getValues = () => {
        let valus= this.props.form.getFieldsValue();
        this.props.form.validateFields((err, values) =>{
            if(err){
                return valus=undefined
            }
        });
        return valus;

    };


    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='quanxiansz_center'>

                <Form onSubmit={this.updatePassword}  labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} colon={false}>

                    <Form.Item label="当前密码">
                        {
                            getFieldDecorator('oldPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '当前密码不能为空'
                                    },

                                ]
                            })(<Input.Password placeholder="请输入当前密码" autocomplete="off"/>)
                        }
                    </Form.Item>
                    <Form.Item label="新密码">
                        {
                            getFieldDecorator('newPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '新密码不能为空',
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                        max: 10,
                                        pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$',
                                        message: '密码为6-10位英文+数字组合!',
                                    },
                                ]
                            })(<Input.Password placeholder="请输入新密码" autocomplete="off"/>)
                        }
                    </Form.Item>
                    <Form.Item label="重复新密码">
                        {
                            getFieldDecorator('againPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '重复新密码不能为空',

                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                        max: 10,
                                        pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$',
                                        message: '密码为6-10位英文+数字组合!',
                                    },
                                ]
                            })(<Input.Password placeholder="请重复输入新密码" autocomplete="off"/>)
                        }
                    </Form.Item>
                </Form>
            </div>

        );
    }
}

PersonalPassword = Form.create()(PersonalPassword);
export default PersonalPassword;
