/*绩效指标达标情况模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Tabs} from "antd";
import * as echarts from 'echarts';
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";
import MeetPerformanceIndicators from "../meetPerformanceIndicators";
const { TabPane } = Tabs;
@withRouter
class Template6 extends Component {
    state={
        queryCatgory:0,
        name: ['数量','质量','时效','成本','社会效益','可持续性影响','服务对象满意度']
    }
    componentDidMount() {
        this.myecharts6()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    myecharts6 = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('myEcharts6')){
            let data1 = [10,23,45,15,43,23,64]
            let data2 = [34,55,23,54,65,23,12]
            let data3 = [23,34,12,45,56,34,23]
            let myChart = echarts.init(document.getElementById('myEcharts6'));
            const rich = {

                b: {

                    fontSize: 18,

                    fontWeight: 'bold'

                },

                n: {

                    fontSize: 14,

                    lineHeight: 20

                }

            }
            const makeMultiLine = (str, charQty) => {

                const strs = str.split('')

                const len = strs.length

                if (len < charQty + 1) {

                    return str

                }

                let result = ''

                strs.forEach((_, index) => {

                    result += _

                    if ((index + 1) % charQty === 0 && index < len - 1) {

                        result += '\n'

                    }

                })

                return result

            }
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#3E78ED','#23B899','#FFEE7E'],
                legend: {
                    itemHeight: 10,
                    itemWidth: 10,
                    data: [{name: '指标数', icon: 'circle'}, {name: '未完成指标数', icon: 'circle'}, {name: '超出预期完成数', icon: 'circle'}],
                    bottom: 0,
                    left: '20%',
                },
                grid: {
                    left: '10%',
                    top: 10,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.state.name,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {

                            formatter: function (val) {

                                let str = makeMultiLine(val, 5) // 每 5 个字符一行

                                return `{n|${str}}` // 使用 rich 中的 n 来设置样式

                            },
                            rich,
                            interval: 0 // 显示所有的 x 轴上的文字不隐藏
                        }
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        type: 'value',
                    },
                ],
                series: [
                    {
                        name: '指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1,
                        barWidth: 10
                    },
                    {
                        name: '未完成指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2,
                        barWidth: 10
                    },
                    {
                        name: '超出预期完成数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data3,
                        barWidth: 10
                    },

                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    render() {
        const {queryCatgory} = this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'jixiaozhibiao width50'}>
                        <div className={'title'}>
                            绩效指标达标情况
                            <div className={'caozuo'} onClick={()=>this.bianji('绩效指标达标情况','/main/doublehigh/edittaskachievementofindicators')}>
                                <Task/>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane><TabPane  tab={'专业群二'} key="3">
                        </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey=""  onChange={(item)=>this.onChangeTabMeetPerformanceIndicators(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <div className={'tempalte6-echarts'} id={'myEcharts6'}></div>
                    </div>
                </div>
            </div>

        );
    }
}

Template6 = Form.create()(Template6);
export default Template6;
