/*各建设任务支出占比模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Tabs} from "antd";
import * as echarts from 'echarts';
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";
import TaskSpending from "../taskSpending";
const { TabPane } = Tabs;
@withRouter
class Template3 extends Component {
    state={
        dateType: 3,
        data1:[
            {
                name: '111',
                amount: 1,
                icon: 'circle',
                rate: 0.1,

            },
            {
                name: '22',
                amount: 9,
                icon: 'circle',
                rate: 0.9
            }
        ],
        data2:[
            {
                name: '111',
                value: 1,
            },
            {
                name: '22',
                value: 9,
            }
        ]
    }
    componentDidMount() {
        this.myecharts3()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    myecharts3 = ()=>{
        // 绘制图表
        if(document.getElementById('myEcharts3')){
            let myChart = echarts.init(document.getElementById('myEcharts3'));
            // 绘制图表
            let
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: (params) => {
                            console.log('params', params);
                            return <div><span>实际使用金额</span><span>{this.state.expendAmountSum}</span></div>;
                        },
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 550
                        }
                    },
                    color:['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58'],
                    grid: {
                        top: '15%',
                        left: '0%',
                        right: '1%',
                        bottom: 5,
                    },
                    legend: {
                        type: "scroll",
                        top: '35',
                        right: 0,
                        orient: 'vertical',
                        selectedMode:false,
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        itemGap: 20,
                        data:this.state.data1,
                        formatter: (name) => {
                            let data1 = this.state.data1
                            let rate = 0
                            let amount = 0
                            data1.map(item=>{
                                if(name === item.name){
                                    rate = item.rate
                                    amount = item.amount
                                }
                            })
                            rate = parseFloat(rate * 100).toFixed(2).replace(".00","")
                            let namenew = name
                            if(name.length > 7){
                                namenew = name.substring(0,7) + '...'
                            }
                            return [
                                "{a|" +namenew+
                                "}",
                                "{b|" + rate + "%}",
                                "{c|￥" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','') + "}"  //a、b、x、跟下面的rich对应
                            ].join(" ");
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            color:'rgba(227, 73, 73, 1)',
                            //在rich中给formatter添加edittaskspendingpercent个别字体颜色
                            rich: {
                                a:{
                                    width: 110,
                                    fontSize: 14,
                                    color: '#333333',
                                    align: 'left'
                                },
                                b: {
                                    color: '#888888',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '##40a9ff',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            center: ['20%','50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        console.log('data',data.value)
                                        let html = `{b| 实际使用额度} \n {c|¥${data.value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00','')}}`;
                                        return html;
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.state.data2
                        }
                    ]
                };
            myChart.setOption(option)
            myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
            window.addEventListener("resize", function () {
                myChart.resize();
            })
            myChart.on('mouseover', (v) => { //清空第一次选中
                if (v.dataIndex != 0) {
                    myChart.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: 0
                    });
                }
            });
            myChart.on('mouseout', (v) => { //鼠标离开圆环默认选第一次
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
            });
        }
    }
    onChangeTabOfcengMianRate = (item)=>{
        console.log('item',item)
        this.setState({cengMianRate:item})
    }
    render() {
        const {queryCatgory} = this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'jiansherenwu width50'}>
                        <div className={'title'}>
                            各建设任务支出占比
                            <div className={'caozuo'} >
                                <div onClick={()=>this.bianji('各建设任务支出占比','/main/doublehigh/edittaskspendingpercent')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane>
                            <TabPane  tab={'专业群二'} key="3">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfcengMianRate(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <div className={'tempalte3-echarts'} id={'myEcharts3'}></div>
                    </div>
                </div>
            </div>

        );
    }
}

Template3 = Form.create()(Template3);
export default Template3;
