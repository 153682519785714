import reducers from './reducers'
import Cookies from "js-cookie";

let defaultState = {
    activeKey: sessionStorage.getItem('header_menu_key') ? sessionStorage.getItem('header_menu_key') : '0',
    headerMenuList: [{
        path: "/main/resource",
        key: "0",
        name: "资源"
    }, /*{
        path: "/main/livebroadcast",
        key: "1",
        name: "直播"
    }, {
        path: "/main/enterprise",
        key: "2",
        name: "企业空间后台"
    }*/
    ],

    buttonRole: Cookies.get('buttonPerms')?  JSON.parse(Cookies.get('buttonPerms')):[],
        /*[/!*"SS-01-B01-b_btnAddHierarchy",
            'SS-01-B01-b_btnEditHierarchy',
         'SS-01-B01-b_btnDelHierarchy',
            'SS-01-B02-b_btnAddUser',
            'SS-01-B02-b_btnEditUser',
            'SS-01-B02-b_btnResetPassword',
            'SS-01-B02-b_btnStopUsing-Recovery',
            'SS-01-B02-b_btnAddRole',
            'SS-01-B02-b_btnAssignUsers',
            'SS-01-B02-b_btnSetAccess',
            'SS-01-B02-b_btnEditRole',
            'SS-01-B02-b_btnStopUsing',
            'SS-01-B02-b_btnDelRole',
            'SS-01-B02-b_btnViewUser',
            'SS-01-B08-c_btnViewMissionStatement',
            'SS-01-B08-c_btnViewMS-Annex',
            'SS-01-B08-c_btnDownMS-Annex',
            'SS-01-B08-c_btnViewConstructionPlan',
            'SS-01-B08-c_btnDownConstructionPlan',
            'SS-01-B08-c_btnViewPerformance',
            'SS-01-B08-c_btnDownPerformance',
            'SS-01-B08-c_btnSyncData',
            'SS-01-B02-SS-01-B02-b_btnEditRoleclassification',
            'SS-01-B07-b_btnViewSchool',
            'SS-01-B07-b_btnViewProfessionalCourseA',
            'SS-01-B07-b_btnViewProfessionalCourseB'*!/


        ],*/

    menuList: []
}
const Main = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Main
