/*学校自评报告*/
import React, {Component} from 'react';
import {Row, Col, Button, Modal,Input, message, Form,InputNumber} from 'antd';
import './index.scss';
import '../../../../styles/global.less';

import {getFourLevelIndicatorYearValue,insertFourLevelIndicatorYearValue,getFourLevelIndicatorYearDrtailList,getFourLevelIndicatorYearDetail,getThreeLevelPerformanceFileList,insertSchoolInvestDetailYearDetail,
    getSchoolInvestDetailYearDetail} from "../../../../api/doublehigh/index"
import {previewUrl,fillInYear} from "../../../../config/secret";
import OSS from "ali-oss";
// import { getOssToken } from '@/api/ali/index.js';
const {TextArea} = Input;
class ProjectFundShuju extends React.Component {
    state = {
        islead:false,
        columns: [
            {
                title: '具体事项/分年度数', dataIndex: 'specific', key: '', width: 200, align: 'center'
            },
            {
                title: '2019', dataIndex: 'year1', key: 'year1', align: 'center',width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <Input/>
                        </div>
                    )
                }
            },
            {
                title: '2020', dataIndex: 'year2', key: 'year2', align: 'center',width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <Input/>
                        </div>
                    )
                }
            },
            {
                title: '2021', dataIndex: 'year3', key: 'year3', align: 'center',width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <Input/>
                        </div>
                    )
                }
            },
            {
                title: '2022', dataIndex: 'year4', key: 'year4', align: 'center',width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <Input/>
                        </div>
                    )
                }
            },
            {
                title: '2023', dataIndex: 'year5', key: 'year5', align: 'center',width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <Input/>
                        </div>
                    )
                }
            },

        ],
        dataSource: [
            {
                specific:'中央财政投入资金（元）',
            },
            {
                specific:'地方各级财政投入资金（元）',
            },
            {
                specific:'举办方投入资金（元）',
            },
            {
                specific:'行业企业支持资金（元）',
            },
            {
                specific:'学校自筹资金（元）',
            },
            {
                specific:'合计（元）',
            },
        ],
        schoolInfo:this.props.schoolInfo,
        schoolEduCode:this.props.schoolEduCode,
        investYear: fillInYear,
        schoolInvestYearList:[],
        schoolInvestYearListTwo:[],
        schoolInvestYearListThree:[],
        tableSchoolInvestYearVoList:[],
    };
    componentDidMount() {
        // const data = this.props.location.search; //地址栏截取
        // const param = data.split("?")[1];
        // const codeParam = param.includes("%") ? decodeURI(param) : param;
        // const jsonparam = JSON.parse(codeParam);
        // this.setState({
        //     schoolEduCode:jsonparam.schoolEduCode,
        //     year:jsonparam.year
        // },()=>{
        //     this.getOssToken()
        //     this.getFourLevelIndicatorYearValue();
        //     this.getFourLevelIndicatorYearValueTwo();
        //     this.getFourLevelIndicatorYearDetail();
        //     this.getThreeLevelPerformanceFileList()
        // })
        // this.getOssToken()
        this.getFourLevelIndicatorYearValue();
        this.getFourLevelIndicatorYearValueTwo();
        // this.getFourLevelIndicatorYearDetail();
        // this.getThreeLevelPerformanceFileList()
    }


    getThreeLevelPerformanceFileList =()=>{
        let data = {
            // "midType": 0,
            "schoolEduCode": this.state.schoolEduCode,
            // "seniorId": this.state.threeLevelId,
            "uploadLocation": 1,
            // "upseniorId": this.state.twoLevelId,
            "year": fillInYear
        }
        getThreeLevelPerformanceFileList(data).then(res =>{
            if(res){
                this.setState({
                    fileDetail: res
                })
            }
        })
    }

    getFourLevelIndicatorYearValue=()=>{
        const pream = {
            schoolEduCode:this.state.schoolEduCode,
        }
        /*        20180: "111"
                20181: ""
                20182: ""
                20183: ""
                20184: ""
                20190: ""
                20191: ""
                20192: ""
                20193: ""
                20194: ""*/
        let year=['2019','2020','2021','2022','2023']
        let data=[ ]
        let child={
            investInMoney:'',
            investName:'',
            investUseMoney:'',
            investYear:'',
        }
        year.map((item,index)=>{
            let parEle= {investYear:item,schoolInvestYearVoList:[]}
            data.investYear=item
            for(let i=0; i<5; i++){
                parEle.schoolInvestYearVoList.push(child)
            }
            data.push(parEle)
        })
        getFourLevelIndicatorYearValue(pream).then (res => {
            this.setState({
                schoolInvestYearList:res.length>0?res:data,
            },()=>{
                console.log("schoolInvestYearList",this.state.schoolInvestYearList)

            })
        })
    }
    getFourLevelIndicatorYearValueTwo=()=>{
        const pream = {
            schoolEduCode:this.state.schoolEduCode,
        }
        let year=[{investYear:'项目预算总额',actualYear:0},{investYear:'2019-2020年度',actualYear:2020},{investYear:'2021年度',actualYear:2021},{investYear:'2022年度',actualYear:2022},{investYear:'2023年度',actualYear:2023}]
        let data=[]
        let child={
            investSchoolMoney:'',
            investName:'',
            investGroupOneMoney:'',
            investGroupTwoMoney:'',
            investYear:'',
            actualYear:'',
        }

        let dataNew = []
        let childNew = {
            investSchoolMoney:'',
            investName:'',
            investGroupOneMoney:'',
            investGroupTwoMoney:'',
            investYear:'',
            actualYear:'',
        }

        year.map((item,index)=>{
            let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
            data.investYear=item.investYear
            data.actualYear=item.actualYear
            for(let i=0; i<10; i++){
                parEle.schoolInvestYearVoList.push(child)
            }
            data.push(parEle)
        })
        year.map((item,index)=>{
            let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
            dataNew.investYear=item.investYear
            dataNew.actualYear=item.actualYear
            for(let i=0; i<10; i++){
                parEle.schoolInvestYearVoList.push(childNew)
            }
            dataNew.push(parEle)
        })

        getSchoolInvestDetailYearDetail(pream).then (res => {
            let schoolList = []
            let groupList = []
            let yearNew = [{investYear:'2022年度',actualYear:2022},{investYear:'2023年度',actualYear:2023}]

            if(res && res.length > 0){
                res.map((item,index)=>{


                    if(item.actualYear !== 2022 && item.actualYear !== 2023){
                        let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
                        item.schoolInvestYearVoList.forEach(e => {
                            if(e.investSchoolMoney === null && item.actualYear !== 2022 && item.actualYear !== 2023){
                                parEle.schoolInvestYearVoList.push(e)
                            }
                        })
                        // investGroupOneMoney
                        // investGroupTwoMoney
                        groupList.investYear=item.investYear
                        groupList.actualYear=item.actualYear
                        groupList.push(parEle)
                    }
                })
                yearNew.map((item,index)=>{
                    let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
                    groupList.investYear=item.investYear
                    groupList.actualYear=item.actualYear
                    for(let i=0; i<10; i++){
                        parEle.schoolInvestYearVoList.push(groupList)
                    }
                    groupList.push(parEle)
                })

                res.map((item,index)=>{

                    if(item.actualYear !== 2022 && item.actualYear !== 2023){
                        let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
                        item.schoolInvestYearVoList.forEach(e => {
                            if(e.investGroupOneMoney === null && e.investGroupTwoMoney === null && item.actualYear !== 2022 && item.actualYear !== 2023){
                                parEle.schoolInvestYearVoList.push(e)
                            }
                        })
                        schoolList.investYear=item.investYear
                        schoolList.actualYear=item.actualYear
                        schoolList.push(parEle)
                    }
                })
                yearNew.map((item,index)=>{
                    let parEle= {investYear:item.investYear,actualYear:item.actualYear,schoolInvestYearVoList:[]}
                    schoolList.investYear=item.investYear
                    schoolList.actualYear=item.actualYear
                    for(let i=0; i<10; i++){
                        parEle.schoolInvestYearVoList.push(schoolList)
                    }
                    schoolList.push(parEle)
                })
            }
            console.log('res 33', res)
            console.log('schoolRes 33', schoolList)
            console.log('groupRes 33', groupList)
            this.setState({
                schoolInvestYearListTwo: schoolList && schoolList.length > 0 ? schoolList:data,
                schoolInvestYearListThree: groupList && groupList.length > 0 ? groupList:dataNew,
            },()=>{
                console.log("schoolInvestYearListTwo",this.state.schoolInvestYearListTwo)

            })
        })
    }
    getFourLevelIndicatorYearDetail=()=>{
        const pream = {
            schoolEduCode:this.state.schoolEduCode,
        }
        getFourLevelIndicatorYearDetail(pream).then (res => {
            this.setState({
                schoolInvestYearDetail:res?res:{},
            },()=>{
                this.props.form.setFieldsValue({
                    transferExplain: this.state.schoolInvestYearDetail.transferExplain,
                    transferSituation: this.state.schoolInvestYearDetail.transferSituation,
                })
            })
        })
    }
    //保存
    save=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            let parmes=[]
            if (!err) {
                console.log('Received values of form: ', values);
                this.state.schoolInvestYearList.map((item,index)=>{
                    item.schoolInvestYearVoList.map((todo,k)=>{
                        let data=   {
                            "sort":k,
                            "investYear": item.investYear,
                            "investInMoney":values[`investInMoney${item.investYear}${k}`],
                            "investName": k==0?'中央财政投入资金':k==1?"地方各级财政投入资金":k==2?"举办方投入资金":k==3?"行业企业支持资金":"学校自筹资金",
                            "investUseMoney": values[`investUseMoney${item.investYear}-${k}`],
                        }
                        parmes.push(data)
                    })

                })
                let investInYearQos = []
                console.log("parmes",parmes)
                insertFourLevelIndicatorYearValue({	"investYear": this.state.investYear,
                    "schoolEduCode": this.state.schoolEduCode,investInYearQos:parmes}).then(res =>{
                    console.log("res==========",res)
                    message.success("保存成功")
                })
            }else{
                window.scrollTo({
                    top: 390,
                    behavior: 'smooth',
                });
            }
        })
    }

    record=()=>{
        this.setState({islead:true})
        getFourLevelIndicatorYearDrtailList({schoolEduCode: this.state.schoolEduCode}).then(res =>{
            this.setState({
                tableSchoolInvestYearVoList:res
            })
            // res.map((item, index) => {
            //     this.setState({
            //         tableSchoolInvestYearVoList: item.schoolInvestYearNameListVos
            //     })
            // })
        })
    }

    componentWillMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        window.addEventListener('scroll', this.bindHandleScroll)
    }
    //合计
    handleTotal=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investInMoney${item.investYear}${k}`)?Number(this.props.form.getFieldValue(`investInMoney${item.investYear}${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    //合计
    handleTotalList=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investInMoney1${item.investYear}${k}`)?Number(this.props.form.getFieldValue(`investInMoney1${item.investYear}${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalSecond=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investUseMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investUseMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalThree=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investPlaceMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investPlaceMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalFour=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investBalanceMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investBalanceMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalFive=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investTransferMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investTransferMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalSix=(item,name)=>{
        let total=0
        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investSchoolMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investSchoolMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalSeven=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investGroupOneMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investGroupOneMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    handleTotalEight=(item,name)=>{
        let total=0

        item.schoolInvestYearVoList.map((todo,k)=>{
            let value=this.props.form.getFieldValue(`investGroupTwoMoney${item.investYear}-${k}`)?Number(this.props.form.getFieldValue(`investGroupTwoMoney${item.investYear}-${k}`)):0
            total=total+value
        })
        return total.toLocaleString()
    }
    //销毁监听事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.bindHandleScroll);
    }

    downloadThreeLevelPerformanceFile =(item)=>{
        // if(this.state.fileDetail && this.state.fileDetail.fileId){
        const response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(item.fileName)}`
        }
        // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
        window.open(this.client.signatureUrl(item.fileId, { response }))
        // } else {
        //     message.warning("暂未上传佐证材料！")
        // }
    }
    render() {
        const {schoolInfo,fileDetail,columns,dataSource,islead,schoolInvestYearList,tableSchoolInvestYearVoList,schoolInvestYearListTwo,schoolInvestYearListThree} = this.state;
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="Budget-ProjectFundshuju">
                <Form>
                    <ul>
                        <div className="h3-top">
                            <h3>项目预算</h3>
                            {/*<span className="on" ><img src={require('../../../assets/images/icon-37.png')}/>查看预算填写记录</span>*/}
                            {/*<span onClick={this.record}><img src={require('../../../assets/images/icon-37.png')}/>查看预算填写记录</span>*/}
                            {/*<em>（没有资金可填0，2022年度预算必填）</em>*/}
                        </div>
                        <div className="table-bottom">
                            <div className="table-con">
                                <ul className="top-ul">
                                    <li>具体事项/分年度数</li>
                                    <li>中央财政投入资金<i className="red">（元）</i></li>
                                    <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                    <li>举办方投入资金<i className="red">（元）</i></li>
                                    <li>行业企业支持资金<i className="red">（元）</i></li>
                                    <li>学校自筹资金<i className="red">（元）</i></li>
                                    <li className="red">合计<i className="red">（元）</i></li>
                                </ul>
                                <div className="bottom-div">
                                    {
                                        schoolInvestYearList.map((item,index)=>{
                                            return(
                                                <ul className={(item.investYear === 2020 || item.investYear === 2021) &&this.state.schoolEduCode === '4132011052'|| (item.investYear === 2020||item.investYear === 2021) &&this.state.schoolEduCode === '4132012686'?'class_ul':''}>
                                                    <li>{item.investYear}</li>
                                                    {item.schoolInvestYearVoList.map((cur,m)=>{
                                                        return(
                                                            <li>
                                                                <Form.Item className="ro-cont">
                                                                    {getFieldDecorator(`investInMoney${item.investYear}${m}`, {
                                                                        initialValue:cur.investInMoney,
                                                                        rules: [
                                                                            {
                                                                                required: item.investYear==2019||item.investYear==2020||item.investYear==2021?true:false,
                                                                                pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                message: `请输入0或正整数(元)`,
                                                                            },
                                                                        ],
                                                                    })
                                                                    (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                  parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={999999999} disabled/>)
                                                                        // (<Input className="input"/> )
                                                                    }
                                                                </Form.Item>
                                                            </li>

                                                        )
                                                    })

                                                    }
                                                    <li className="last-li last-li-on">
                                                        <span>{this.handleTotal(item)}</span>
                                                    </li>

                                                </ul>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {/*<div className="beiz_span">*/}
                            {/*    <b>表注：</b>*/}
                            {/*    <div>*/}
                            {/*        /!*<span>1. 表中数据自动保留两位小数。</span>*!/*/}
                            {/*        <span>1. 根据《任务书》（含本次调整或修订）据实填写。</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="h3-top">
                            <h3>项目资金到位</h3>
                            {/*<em>（没有资金可填0，2019-2021年度资金到位数必填）</em>*/}
                        </div>
                        <div className="table-bottom">
                            <div className="table-con">
                                <ul className="top-ul">
                                    <li>具体事项/分年度数</li>
                                    <li>中央财政投入资金<i className="red">（元）</i></li>
                                    <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                    <li>举办方投入资金<i className="red">（元）</i></li>
                                    <li>行业企业支持资金<i className="red">（元）</i></li>
                                    <li>学校自筹资金<i className="red">（元）</i></li>
                                    <li className="red">合计<i className="red">（元）</i></li>
                                </ul>
                                <div className="bottom-div">
                                    {
                                        schoolInvestYearList.map((item,index)=>{
                                            return(
                                                <ul className={(item.investYear == "2020"||item.investYear == "2021") &&this.state.schoolEduCode === '4132011052'||(item.investYear == "2020"||item.investYear == "2021")&&this.state.schoolEduCode === '4132012686'?'class_ul':''}
                                                    style={item.investYear === 2022||item.investYear === 2023?{display:'none'}: {display:'block'}}>
                                                    <li>{item.investYear}</li>
                                                    {
                                                        item.schoolInvestYearVoList.map((cur,m)=>{
                                                            return(
                                                                <li>
                                                                    <Form.Item className="ro-cont">
                                                                        {getFieldDecorator(`investPlaceMoney${item.investYear}-${m}`, {
                                                                            initialValue:cur.investPlaceMoney,
                                                                            rules: [
                                                                                {
                                                                                    required: item.investYear <=this.state.investYear? true :false,
                                                                                    pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                    message: `请输入0或正整数(元)`,
                                                                                },
                                                                            ],
                                                                        })
                                                                        (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                      parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={999999999} disabled/>)
                                                                            // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                        }
                                                                    </Form.Item>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        // item.investYear > this.state.investYear?(
                                                        //     <li className="last-li last-li-on">
                                                        //         <span></span>
                                                        //     </li>
                                                        // ):(
                                                        <li className="last-li last-li-on">
                                                            <span>{this.handleTotalThree(item)}</span>
                                                        </li>
                                                        // )
                                                    }

                                                </ul>

                                            )
                                        })

                                    }
                                </div>
                            </div>
                            {/*<div className="beiz_span">*/}
                            {/*    <b>表注：</b>*/}
                            {/*    <div>*/}
                            {/*        <span>1. 按资金来源据实填写。</span>*/}
                            {/*        <span>2. 2019、2020年度能分别填列的，尽量分别填列；不能分别填列的，将合计数填写在2020年度，其他年份须分年度填写。</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="h3-top">
                            <h3>项目资金使用</h3>
                            {/*<em>表注：按资金来源据实填写</em>*/}
                        </div>
                        <div className="table-bottom">
                            <div className="table-con">
                                <ul className="top-ul">
                                    <li>具体事项/分年度数</li>
                                    <li>中央财政投入资金<i className="red">（元）</i></li>
                                    <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                    <li>举办方投入资金<i className="red">（元）</i></li>
                                    <li>行业企业支持资金<i className="red">（元）</i></li>
                                    <li>学校自筹资金<i className="red">（元）</i></li>
                                    <li className="red">合计<i className="red">（元）</i></li>
                                </ul>
                                <div className="bottom-div">
                                    {
                                        schoolInvestYearList.map((item,index)=>{
                                            return(
                                                <ul style={item.investYear === 2022||item.investYear === 2023?{display:'none'}: {display:'block'}}>
                                                    <li>{item.investYear}</li>
                                                    {
                                                        item.schoolInvestYearVoList.map((cur,m)=>{
                                                            return(
                                                                <li>
                                                                    <Form.Item className="ro-cont">
                                                                        {getFieldDecorator(`investUseMoney${item.investYear}-${m}`, {
                                                                            initialValue:cur.investUseMoney,
                                                                            rules: [
                                                                                {
                                                                                    required: item.investYear <=this.state.investYear? true :false,
                                                                                    pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                    message: `请输入0或正整数(元)`,
                                                                                },
                                                                            ],
                                                                        })
                                                                        (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                      parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={999999999} disabled/>)
                                                                            // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                        }
                                                                    </Form.Item>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        item.investYear > this.state.investYear?(
                                                            <li className="last-li last-li-on">
                                                                <span></span>
                                                            </li>
                                                        ):(
                                                            <li className="last-li last-li-on">
                                                                <span>{this.handleTotalSecond(item)}</span>
                                                            </li>
                                                        )
                                                    }

                                                </ul>

                                            )
                                        })

                                    }
                                </div>
                            </div>
                            {/*<div className="beiz_span">*/}
                            {/*    <b>表注：</b>*/}
                            {/*    <div>*/}
                            {/*        <span>1. 按资金来源据实填写。</span>*/}
                            {/*        /!*<span>2. 2019、2020年度能分别填列的，尽量分别填列，不行的，填写在2020年度，其他年份须分年度填写。</span>*!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>


                        <div className="h3-top">
                            <h3>项目支出明细（学校）</h3>
                        </div>
                        <div className="table-bottom table-bottomCombine">
                            <div className="table-con">
                                <ul className="top-ul">
                                    <li className="li_fistre"></li>
                                    <li>打造技术技能人才培养高地<i className="red">（元）</i></li>
                                    <li>打造技术技能创新服务平台<i className="red">（元）</i></li>
                                    <li>打造高水平专业群<i className="red">（元）</i></li>
                                    <li>打造高水平双师队伍<i className="red">（元）</i></li>
                                    <li>提升校企合作水平<i className="red">（元）</i></li>
                                    <li>提升服务发展水平<i className="red">（元）</i></li>
                                    <li>提升学校治理水平<i className="red">（元）</i></li>
                                    <li>提升信息化水平<i className="red">（元）</i></li>
                                    <li>提升国际化水平<i className="red">（元）</i></li>
                                    <li>其他<i className="red">（元）</i></li>
                                    <li className="red">合计<i className="red">（元）</i></li>
                                </ul>
                                <div className="bottom-div">
                                    {
                                        schoolInvestYearListTwo.map((item,index)=>{
                                            return(
                                                <>
                                                    <div className="span_tit">支出数</div>
                                                    <ul style={item.investYear === '2022年度'||item.investYear === '2023年度'?{display:'none'}: {display:'block'}}>
                                                        <li className="li_fistre">{item.investYear}</li>
                                                        {
                                                            item.schoolInvestYearVoList.map((cur,m)=>{
                                                                return(
                                                                    <li>
                                                                        <Form.Item className="ro-cont">
                                                                            {getFieldDecorator(`investSchoolMoney${item.investYear}-${m}`, {
                                                                                initialValue:cur.investSchoolMoney,
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        // required: item.investYear <=this.state.investYear? true :false,
                                                                                        pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                        message: `请输入0或正整数(元)`,
                                                                                    },
                                                                                ],
                                                                            })
                                                                            (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                          parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={9999999999.99} disabled={true}/>)
                                                                                // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                            }
                                                                        </Form.Item>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            <li className="last-li last-li-on">
                                                                <span>{this.handleTotalSix(item)}</span>
                                                            </li>
                                                        }

                                                    </ul>
                                                </>

                                            )
                                        })

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="h3-top">
                            <h3>{schoolInfo && schoolInfo.catgory === 1? "项目支出明细（专业群一）" : "项目支出明细（专业群）"}</h3>
                        </div>
                        <div className="table-bottom table-bottomCombine">
                            <div className="table-con">
                                <ul className="top-ul">
                                    <li className="li_fistre"></li>
                                    <li>人才培养模式创新<i className="red">（元）</i></li>
                                    <li>课程教学资源建设<i className="red">（元）</i></li>
                                    <li>教材与教法改革<i className="red">（元）</i></li>
                                    <li>教师教学创新团队<i className="red">（元）</i></li>
                                    <li>实践教学基地<i className="red">（元）</i></li>
                                    <li>技术技能平台<i className="red">（元）</i></li>
                                    <li>社会服务<i className="red">（元）</i></li>
                                    <li>国际交流与合作<i className="red">（元）</i></li>
                                    <li>可持续发展保障机制<i className="red">（元）</i></li>
                                    <li>其他<i className="red">（元）</i></li>
                                    <li className="red">合计<i className="red">（元）</i></li>
                                </ul>
                                <div className="bottom-div" >
                                    {
                                        schoolInvestYearListThree.map((item,index)=>{
                                            return(
                                                <>
                                                    <div className="span_tit">支出数</div>
                                                    <ul style={item.investYear === '2022年度'||item.investYear === '2023年度'?{display:'none'}: {display:'block'}}>
                                                        <li className="li_fistre">{item.investYear}</li>
                                                        {
                                                            item.schoolInvestYearVoList.map((cur,m)=>{
                                                                return(
                                                                    <li>
                                                                        <Form.Item className="ro-cont">
                                                                            {getFieldDecorator(`investGroupOneMoney${item.investYear}-${m}`, {
                                                                                initialValue:cur.investGroupOneMoney,
                                                                                rules: [
                                                                                    {
                                                                                        // required: item.investYear <=this.state.investYear? true :false,
                                                                                        required: true,
                                                                                        pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                        message: `请输入0或正整数(元)`,
                                                                                    },
                                                                                ],
                                                                            })
                                                                            (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                          parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={9999999999.99} disabled={true}/>)
                                                                                // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                            }
                                                                        </Form.Item>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            <li className="last-li last-li-on">
                                                                <span>{this.handleTotalSeven(item)}</span>
                                                            </li>
                                                        }

                                                    </ul>
                                                </>

                                            )
                                        })

                                    }
                                </div>
                            </div>
                        </div>
                        {schoolInfo && schoolInfo.catgory === 1 ?
                            <div>
                                <div className="h3-top">
                                    <h3>项目支出明细（专业群二）</h3>
                                </div>
                                <div className="table-bottom table-bottomCombine">
                                    <div className="table-con">
                                        <ul className="top-ul">
                                            <li className="li_fistre"></li>
                                            <li>人才培养模式创新<i className="red">（元）</i></li>
                                            <li>课程教学资源建设<i className="red">（元）</i></li>
                                            <li>教材与教法改革<i className="red">（元）</i></li>
                                            <li>教师教学创新团队<i className="red">（元）</i></li>
                                            <li>实践教学基地<i className="red">（元）</i></li>
                                            <li>技术技能平台<i className="red">（元）</i></li>
                                            <li>社会服务<i className="red">（元）</i></li>
                                            <li>国际交流与合作<i className="red">（元）</i></li>
                                            <li>可持续发展保障机制<i className="red">（元）</i></li>
                                            <li>其他<i className="red">（元）</i></li>
                                            <li className="red">合计<i className="red">（元）</i></li>
                                        </ul>
                                        <div className="bottom-div">
                                            {
                                                schoolInvestYearListThree.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <div className="span_tit">支出数</div>
                                                            <ul style={item.investYear === '2022年度'||item.investYear === '2023年度'?{display:'none'}: {display:'block'}}>
                                                                <li className="li_fistre">{item.investYear}</li>
                                                                {
                                                                    item.schoolInvestYearVoList.map((cur,m)=>{
                                                                        return(
                                                                            <li>
                                                                                <Form.Item className="ro-cont">
                                                                                    {getFieldDecorator(`investGroupTwoMoney${item.investYear}-${m}`, {
                                                                                        initialValue:cur.investGroupTwoMoney,
                                                                                        rules: [
                                                                                            {
                                                                                                // required: item.investYear <=this.state.investYear? true :false,
                                                                                                required: true,
                                                                                                pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                                message: `请输入0或正整数(元)`,
                                                                                            },
                                                                                        ],
                                                                                    })
                                                                                    (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                                  parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={9999999999.99} disabled={true}/>)
                                                                                        // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                                    }
                                                                                </Form.Item>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    <li className="last-li last-li-on">
                                                                        <span>{this.handleTotalEight(item)}</span>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }

                        <div style={{display:'none'}}>
                            <div className="h3-top">
                                <h3>结余资金情况</h3>
                            </div>
                            <div className="table-bottom">
                                <div className="table-con">
                                    <ul className="top-ul">
                                        <li>具体事项/分年度数</li>
                                        <li>中央财政投入资金<i className="red">（元）</i></li>
                                        <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                        <li>举办方投入资金<i className="red">（元）</i></li>
                                        <li>行业企业支持资金<i className="red">（元）</i></li>
                                        <li>学校自筹资金<i className="red">（元）</i></li>
                                        <li className="red">合计<i className="red">（元）</i></li>
                                    </ul>
                                    <div className="bottom-div">
                                        {
                                            schoolInvestYearList.map((item,index)=>{
                                                return(
                                                    <ul style={item.investYear === 2022||item.investYear === 2023?{display:'none'}: {display:'block'}}>
                                                        <li>{item.investYear}</li>
                                                        {
                                                            item.schoolInvestYearVoList.map((cur,m)=>{
                                                                return(
                                                                    <li>
                                                                        <Form.Item className="ro-cont">
                                                                            {getFieldDecorator(`investBalanceMoney${item.investYear}-${m}`, {
                                                                                initialValue:cur.investBalanceMoney,
                                                                                rules: [
                                                                                    {
                                                                                        required: item.investYear <=this.state.investYear? true :false,
                                                                                        pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                        message: `请输入0或正整数(元)`,
                                                                                    },
                                                                                ],
                                                                            })
                                                                            (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                          parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={999999999} disabled/>)
                                                                                // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                            }
                                                                        </Form.Item>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            item.investYear > this.state.investYear?(
                                                                <li className="last-li last-li-on">
                                                                    <span></span>
                                                                </li>
                                                            ):(
                                                                <li className="last-li last-li-on">
                                                                    <span>{this.handleTotalFour(item)}</span>
                                                                </li>
                                                            )
                                                        }

                                                    </ul>

                                                )
                                            })

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="h3-top">
                                <h3>结转资金情况</h3>
                            </div>
                            <div className="table-bottom">
                                <div className="table-con">
                                    <ul className="top-ul">
                                        <li>具体事项/分年度数</li>
                                        <li>中央财政投入资金<i className="red">（元）</i></li>
                                        <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                        <li>举办方投入资金<i className="red">（元）</i></li>
                                        <li>行业企业支持资金<i className="red">（元）</i></li>
                                        <li>学校自筹资金<i className="red">（元）</i></li>
                                        <li className="red">合计<i className="red">（元）</i></li>
                                    </ul>
                                    <div className="bottom-div">
                                        {
                                            schoolInvestYearList.map((item,index)=>{
                                                return(
                                                    <ul style={item.investYear === 2022||item.investYear === 2023?{display:'none'}: {display:'block'}}>
                                                        <li>{item.investYear}</li>
                                                        {
                                                            item.schoolInvestYearVoList.map((cur,m)=>{
                                                                return(
                                                                    <li>
                                                                        <Form.Item className="ro-cont">
                                                                            {getFieldDecorator(`investTransferMoney${item.investYear}-${m}`, {
                                                                                initialValue:cur.investTransferMoney,
                                                                                rules: [
                                                                                    {
                                                                                        required: item.investYear <=this.state.investYear? true :false,
                                                                                        pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                                        message: `请输入0或正整数(元)`,
                                                                                    },
                                                                                ],
                                                                            })
                                                                            (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                          parser={value => value.replace(/\$\s?|(,*)/g, '')} min={0} max={999999999} disabled/>)
                                                                                // (<Input className="input" disabled={item.investYear >= this.state.investYear?true:false}/> )
                                                                            }
                                                                        </Form.Item>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            item.investYear > this.state.investYear?(
                                                                <li className="last-li last-li-on">
                                                                    <span></span>
                                                                </li>
                                                            ):(
                                                                <li className="last-li last-li-on">
                                                                    <span>{this.handleTotalFive(item)}</span>
                                                                </li>
                                                            )
                                                        }

                                                    </ul>

                                                )
                                            })

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="turnoverCapital">
                                <div className="Capitalli">
                                    <Form.Item>
                                        <span className="title">若有结余资金，请分年度说明情况：</span>
                                        {getFieldDecorator(`transferExplain`, {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: false,
                                                    message: '请输入'
                                                }
                                            ],
                                        })(<TextArea className="TextArea-pic" rows={3} disabled/> )}
                                    </Form.Item>
                                </div>
                                <div className="Capitalli">
                                    <Form.Item>
                                        <span className="title">若有结转资金，请分年度说明其使用情况：</span>
                                        {getFieldDecorator(`transferSituation`, {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: false,
                                                    message: '请输入'
                                                }
                                            ],
                                        })(<TextArea className="TextArea-pic" rows={3} disabled/> )}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </ul>
                </Form>
                <Modal
                    wrapClassName='Modal-Acquisitiontable'
                    title={
                        <div>
                            预算填写记录
                        </div>
                    }
                    centered={true}
                    visible={islead}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button style={{display:'none'}}>关闭</Button>
                            <Button onClick={() => this.setState({islead:false})} type='primary'>关闭</Button>
                        </div>
                    }>

                    <div className="contacts-form">
                        {tableSchoolInvestYearVoList.length>0?tableSchoolInvestYearVoList.map((a,num)=>{
                            return(
                                <div key={num}>
                                    <div className="h3-top">
                                        <h3>{a.loopName === 2021?"2019-2020":a.loopName}阶段第{a.loopNum}次填报</h3>
                                    </div>
                                    <div className="table-bottom-list">
                                        <div >
                                            <div>
                                                <div className="h3-top">
                                                    <h3>项目预算</h3>
                                                </div>
                                                <div className="table-con">
                                                    <ul className="top-ul">
                                                        <li>具体事项/分年度数</li>
                                                        <li>中央财政投入资金<i className="red">（元）</i></li>
                                                        <li>地方各级财政投入资金<i className="red">（元）</i></li>
                                                        <li>举办方投入资金<i className="red">（元）</i></li>
                                                        <li>行业企业支持资金<i className="red">（元）</i></li>
                                                        <li>学校自筹资金<i className="red">（元）</i></li>
                                                        <li className="red">合计<i className="red">（元）</i></li>
                                                    </ul>
                                                    <div className="bottom-div">
                                                        {
                                                            a.schoolInvestYearNameListVos.map((item,index)=>{
                                                                return(
                                                                    <ul key={index}>
                                                                        <li>{item.investYear}</li>
                                                                        {item.schoolInvestYearVoList.map((cur,m)=>{
                                                                            return(
                                                                                <li key={m}>
                                                                                    <Form.Item className="ro-cont">
                                                                                        {getFieldDecorator(`investInMoney1${item.investYear}${m}`, {
                                                                                            initialValue:cur.investInMoney,
                                                                                            rules: [
                                                                                                {
                                                                                                    required: false,
                                                                                                    message: `请输入0或正整数(元)`,
                                                                                                },
                                                                                            ],
                                                                                        })(<Input className="input" disabled={true}/> )}
                                                                                    </Form.Item>
                                                                                </li>

                                                                            )
                                                                        })

                                                                        }
                                                                        <li className="last-li">
                                                                            {this.handleTotalList(item)}
                                                                        </li>

                                                                    </ul>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        }):(
                            <div className="Empty-content">
                                <div className="text">
                                    <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}

ProjectFundShuju = Form.create()(ProjectFundShuju);
export default ProjectFundShuju;
