/*角色权限管理*/
import React, {Component} from "react";
import {
    Form, Col, Row, Input, Select, Button, Table, Modal, Spin,Tooltip
} from "antd";
import "./index.scss";
import {
    deleteRole,
    disableRole,
    enableRole,
    getPermissionButton,
    getRoleById,
    getPageRoleList,
    saveRole,
    updateRoleById,
    getRoleTypelist
} from "../../api/role"
import {withRouter} from "react-router";
import RenderButton from '../../components/renderButton/index'
import EditableTable from '../rolement/addrolement/index'
import Cookies from "js-cookie";
import S from "gongyongicon";

const {Option} = Select;

@withRouter
class Rolement extends Component {
    state = {
        tableLoading: false,
        dataSource: [],
        columns: [
            {title: '角色名称', dataIndex: 'roleName', key: 'roleName', align: 'center', width: '200'},
            {title: '角色分类', dataIndex: 'roleTypeName', key: 'roleTypeName', align: 'center', width: '200'},
            {title: '人数', dataIndex: 'personCount', key: 'personCount', align: 'center', width: '150'},
            {
                title: '状态', dataIndex: 'isValid', key: 'isValid', align: 'center', width: '150',
                render: (text, record) => {
                    return text === 1 ? <li className='zhengchang'>正常</li> : <li className='tingyong'>停用</li>
                }
            },
            {
                title: '备注', dataIndex: 'remark', key: 'remark', align: 'center', width: '300',
                render: (text, record) =>
            <span>
                     {text?text.length>10?<Tooltip  placement="bottom" title={text} overlayClassName='TitleTooltip'><span>{text}</span></Tooltip>
                         :<span>{text}</span>:''}
                </span>
            },
            {
                title: '操作', dataIndex: '', key: '', align: 'center',  width: 300, fixed:"right",
                render: (text, record) => {
                    return <div className={'oper'}>
                        {record.isValid === 1 ? <RenderButton id='SS-01-B02-b_btnAssignUsers'>
                            {record.isAdminRole==0 || record.isAdminRole==2 || record.isAdminRole==3 || record.isAdminRole==4 ?<span className='styleA' onClick={() => this.fenpeiUser(record.id)}>分配用户</span>:<span className='lines'>分配用户</span>}
                                <div className='line'></div>
                            </RenderButton> :
                            <RenderButton id='SS-01-B02-b_btnAssignUsers'>
                                <span className='styleA' className='lines' enabled={false}>分配用户</span>
                                <div className='line'></div>
                            </RenderButton>}
                        <RenderButton id='SS-01-B02-b_btnSetAccess'>
                            {record.isAdminRole==0 ?<span className='styleA' onClick={() => this.shezhi(record.id)}>设置</span>:<span className='lines'>设置</span>}
                            <div className='line'></div>
                        </RenderButton>
                        <RenderButton id='SS-01-B02-b_btnEditRole'>
                            {record.isAdminRole==0?<span className='styleA' onClick={() => this.showAddUser(record)}>编辑</span>:<span className='lines'>编辑</span>}
                            <div className='line'></div>
                        </RenderButton>

                        <RenderButton id='SS-01-B02-b_btnStopUsing-RoleRecovery'>
                            {
                                record.isAdminRole==1 || record.isAdminRole==2 || record.isAdminRole==3 || record.isAdminRole==4 ?<span className='lines'>停用</span>:
                                record.isValid == 1 ?<span className='styleA' onClick={() => this.rolelStop(record.id)}>停用</span> :<span className='styleA' onClick={() => this.roleReturn(record.id)}>恢复</span>
                            }
                            <div className='line'></div>
                        </RenderButton>
                        <RenderButton id='SS-01-B02-b_btnDelRole'>
                            {
                                record.isAdminRole==0?<span className='styleA' onClick={() => this.roleDelete(record.id)}>删除</span>:<span className='lines'>删除</span>
                            }

                        </RenderButton>
                    </div>
                }
            },
        ],
        stopVisible: false, //停用窗口是否显示
        startVisible: false, //启用窗口是否显示
        deleteVisible: false, //删除弹窗
        addUserVisible: false,//新建角色
        edituser: false,//编辑角色
        pageNum: 1,
        pageSize: 20,
        total: 0,
        roleTypeOptions: [],//角色类型
        roleName: '',//角色名字查询
        roleType: '',//分类查询
        deleteRoleId: "",
        editROleId: "",
        stopRoleId: "",
        startRoleId: "",
        addRoleVisible: false,
        statusButten: true,
    }

    componentDidMount() {
        this.getPageRoleList();
        this.getRoleTypelist();
    }

    showRolement = () => {
        this.setState({addRoleVisible: true})
    }
    bindRef = ref => {
        this.child = ref
    }
    addRoleHandleOk = () => {
        this.setState({addRoleVisible: false})
        this.child.chongzhi()
    }
    // region接口调用
    //删除
    deleteRole = async () => {
        let data = {id: this.state.deleteRoleId}
        await deleteRole(data).then(res => {
            this.mesSuccess("角色删除成功")
        })
    }
    //禁用
    disableRole = async () => {
        let data = {id: this.state.stopRoleId}
        await disableRole(data).then(res => {
            this.mesSuccess("角色停用成功")
        })
    }
    //启用
    enableRole = async () => {
        let data = {id: this.state.startRoleId}
        await enableRole(data).then(res => {
            this.mesSuccess("角色恢复成功")
        })
    }
    //获取权限按钮列表
    getPermissionButton = async () => {
        await getPermissionButton().then(res => {
        })
    }
    //信息byid
    getRoleById = async () => {
        await getRoleById().then(res => {
        })
    }
    //分页列表
    getPageRoleList = async () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            roleName: this.state.roleName,
            roleType: this.state.roleType
        }
        await getPageRoleList(data).then(res => {
            if (res) {
                this.setState({dataSource: res.data, pageNum: res.pageNum, total: res.total})
            }

        })
    }
    //新增
    saveRole = async (data) => {
        await saveRole(data).then(res => {
            this.mesSuccess("角色新增成功")

        })
    }
    //修改
    updateRoleById = async (data) => {
        await updateRoleById(data).then(res => {
            this.mesSuccess("角色编辑成功")
        })
    }
    //角色类型列表
    getRoleTypelist = async () => {
        await getRoleTypelist().then(res => {
            if (res) {
                this.setState({"roleTypeOptions": res})
            }
        })
    }
    // endregion

    // region 停用、禁用
    //停用——提示
    rolelStop = (id) => {
        this.setState({stopVisible: true, stopRoleId: id})
    }
    //停用——取消
    stopHandleCancel = () => {
        this.setState({stopVisible: false, stopRoleId: ""})
    }
    //停用——确认
    stopHandleOk = () => {
        this.disableRole().then(() => {
            this.setState({stopVisible: false, stopRoleId: ""})
            this.getPageRoleList()
        })
    }
    //恢复——提示
    roleReturn = (id) => {
        this.setState({startVisible: true, startRoleId: id})
    }
    //恢复——取消
    startHandleCancel = () => {
        this.setState({startVisible: false, startRoleId: ""})
    }
    //恢复——确认
    startHandleOk = (id) => {
        this.enableRole().then(() => {
            this.setState({startVisible: false, startRoleId: ""})
            this.getPageRoleList()
        })

    }
    // endregion

    // region 删除
    //提示删除
    roleDelete = (id) => {
        this.setState({deleteVisible: true, deleteRoleId: id})
    }
    //删除——取消
    deleteHandleCancel = () => {
        this.setState({deleteVisible: false, deleteRoleId: ""})
    }
    //删除——确认
    deleteHandleOk = () => {
        this.deleteRole().then((res) => {
            this.setState({deleteVisible: false, deleteRoleId: "",pageNum:1},()=>{
                this.getPageRoleList()
            })

        })

    }
    // endregion

    // region 新增/编辑
    //新建角色——提示
    showAddUser = (record) => {
        this.props.form.resetFields();
        this.setState({addUserVisible: true})
        //编辑角色
        if (record) {
            this.setState({edituser: true, editROleId: record.id})
            this.props.form.setFieldsValue({
                roleName: record.roleName,
                roleType: record.roleType,
                remark: record.remark
            })
        }
        //新增角色
        else {
            this.setState({edituser: false})
        }
    }
    // 添加/编辑——取消
    addHandleCancel = () => {
        this.setState({addUserVisible: false})
    }
    // 添加/编辑——确认
    addHandleOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let data = {
                        id: this.state.editROleId,
                        roleName: this.props.form.getFieldValue('roleName'), //角色名字
                        roleType: this.props.form.getFieldValue('roleType'), //角色分类
                        remark: this.props.form.getFieldValue('remark'), //描述
                    }
                    //编辑
                    if (this.state.edituser && this.state.editROleId != "") {
                        this.updateRoleById(data).then(() => {
                            this.setState({addUserVisible: false, editROleId: ""})
                            this.getPageRoleList()
                        })
                    }
                    //新增
                    else {
                        this.saveRole(data).then(() => {
                            this.setState({addUserVisible: false, editROleId: ""})
                            this.getPageRoleList()
                        })
                    }

                }
            });
        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);

    }
    // 分配用户跳转
    fenpeiUser = (roleId) => {
        const {list = []} = this.props;
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/distributionuser/') {
                    breadcrumb.splice(index, 1)
                } else if (item.menuUrl == '/main/doublehigh/roleauthorization/') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        breadcrumb.push({"id": '1', "menuName": '双高院校配置管理', "menuUrl": '/main/doublehigh/rolement'}
            , {"id": '22', "menuName": '角色权限管理', "menuUrl": '/main/doublehigh/rolement',"color": '#40a9ff'}
            , {"id": '221', "menuName": '分配用户'})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: '/main/doublehigh/distributionuser/' + roleId,
        })
    }
    //设置跳转
    shezhi = (roleId) => {
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/roleauthorization/') {
                    breadcrumb.splice(index, 1)
                } else if (item.menuUrl == '/main/doublehigh/distributionuser/') {
                    breadcrumb.splice(index, 1)
                }
            }


        })
        breadcrumb.push({"id": '1', "menuName": '双高院校配置管理', "menuUrl": '/main/doublehigh/rolement'}
            , {"id": '22', "menuName": '角色权限管理', "menuUrl": '/main/doublehigh/rolement',"color": '#40a9ff'}
            , {"id": '221', "menuName": '设置'})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: '/main/doublehigh/roleauthorization/' + roleId,
        })
    }
    //角色名字change
    getRoleName = (e) => {
        this.setState({roleName: e.target.value}, () => {
        })
    }
    //角色类型change
    getRoleTypeId = (e) => {
        this.setState({roleType: e}, () => {
        })
    }
    //查询按钮
    selectlabel = () => {
        this.getPageRoleList();

    }
    //重置
    handleReset = () => {
        this.setState({roleName: '', roleType: ''}, () => {
        })
    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
            //列表方法
            this.selectlabel()
        })
    }
    showSizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.selectlabel()
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource, columns, tableLoading, stopVisible, startVisible, deleteVisible, addUserVisible, edituser, pageNum, pageSize, total, roleTypeOptions, addRoleVisible} = this.state
        const {TextArea} = Input
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            onShowSizeChange: this.showSizeChange
        }

        return (
            <div className='rolement_center'>
                <div className='top_search'>
                    <div className="Learnform">
                        <div>
                            <span className="li-span">
                            <i className="i">分类</i>
                              <Select defaultValue="" style={{width: 230}} value={this.state.roleType}
                                      onChange={this.getRoleTypeId}>
                                  <Option value="">全部</Option>
                                  {roleTypeOptions ? roleTypeOptions.map((item, index) => {
                                      return <Option key={item.id} value={item.id}>{item.roleTypeName}</Option>
                                  }) : ""}
                              </Select>
                        </span>
                            <span className="li-span">
                                <i className="i">角色名称</i>
                                <Input autocomplete="off" placeholder="请输入角色名称" className='input' value={this.state.roleName}
                                       onChange={this.getRoleName}/>
                            </span>
                        </div>
                        <span className="li-span1">
                            <Button className={'resize'} onClick={this.handleReset}>重 置</Button>
                            <Button className='chaxun' onClick={() => this.selectlabel()}>查 询</Button>
                        </span>
                    </div>
                </div>
                <div className='content'>
                    <div className='cont-top'>
                        <div className='title'>
                            <p></p>
                            <div>角色权限管理</div>
                        </div>
                        <RenderButton id='SS-01-B02-SS-01-B02-b_btnEditRoleclassification'>
                            <Button className='add-user rolement' onClick={() => this.showRolement()}>角色分类管理</Button>
                        </RenderButton>
                        <RenderButton id='SS-01-B02-b_btnAddRole'>
                            <Button className='add-user' onClick={() => this.showAddUser()}>新建角色</Button>
                        </RenderButton>
                    </div>
                    <div className="tabl">
                        {
                            tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                                <Table className="main-box-header table-maina rolementStyle" cellspacing={20} columns={columns}
                                       dataSource={dataSource}
                                       pagination={pagination}
                                       scroll={{x: 1500, y: 600}}
                                       locale={{emptyText:<><div className="Empty-conet-ss">
                                               <div className="img">
                                                   <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div></>}}
                                />
                            )}
                    </div>
                </div>
                {/*停用弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={stopVisible}
                    onOk={this.stopHandleOk}
                    onCancel={this.stopHandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>停用后该角色下所有用户将失去相应的权限，是否继续？</p>
                </Modal>
                {/*恢复弹窗*/}

                <Modal
                    title="温馨提示"
                    visible={startVisible}
                    onOk={this.startHandleOk}
                    onCancel={this.startHandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>恢复后该角色下所有用户将获取相应的权限，是否继续？</p>
                </Modal>
                {/*删除弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={deleteVisible}
                    onOk={this.deleteHandleOk}
                    onCancel={this.deleteHandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>删除该角色会将角色下用户一并移除，且不可恢复！是否继续？</p>
                </Modal>
                {/*新建角色*/}
                <Modal
                    title={edituser ? '编辑角色' : '新建角色'}
                    visible={addUserVisible}
                    onOk={this.addHandleOk}
                    onCancel={this.addHandleCancel}
                    width={660}
                    closable={false}
                    className={'addRole'}
                    maskClosable={false}
                    centered={true}
                >
                    <Form>
                        <Form.Item>
                            <Row gutter={1}>
                                <Col span={3}><span><span className='red'>*</span>角色分类</span></Col>
                                <Col span={14}>
                                    {getFieldDecorator('roleType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '角色分类不能为空!',
                                            },
                                        ],
                                    })(<Select
                                        showSearch
                                        placeholder="请选择角色分类"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {roleTypeOptions.length ? roleTypeOptions.map((item, index) => {
                                            return <Option key={item.id} value={item.id}>{item.roleTypeName}</Option>
                                        }) : ""}
                                    </Select>)}
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item>
                            <Row gutter={1}>
                                <Col span={3}><span><span className='red'>*</span>角色名称</span></Col>
                                <Col span={14}>
                                    {getFieldDecorator('roleName', {
                                        rules: [
                                            {
                                                required: true,
                                                type: 'string',
                                                min: 2,
                                                max: 10,
                                                message: '不能小于2或大于10字符',
                                            },

                                        ],
                                    })(<Input autocomplete="off" placeholder={'请输入角色名称'}
                                    />)}
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item>
                            <Row gutter={1}>
                                <Col span={3}><span>角色描述</span></Col>
                                <Col span={14}>
                                    {getFieldDecorator('remark', {})(<Input.TextArea
                                                                                     autoSize={{ minRows: 3, maxRows: 3 }}
                                                                                     placeholder={'请输入角色描述'}
                                                                                     maxLength={50}
                                                                                     suffix={<span className='len'></span>}
                                    />)
                                    }
                                </Col>
                                <Col span={2}>
                                    <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`remark`) ? this.props.form.getFieldValue(`remark`).length : 0}/50
                                    </span>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
                {/*新建角色管理*/}
                <Modal
                    title="角色分类管理"
                    visible={addRoleVisible}
                    onOk={this.addRoleHandleOk}
                    onCancel={this.addRoleHandleOk}
                    width={660}
                    className={'addRoleType'}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                >
                    <div className='alert1'>
                        <span>·</span>
                        <p>分类最多创建20个</p>
                    </div>
                    <EditableTable triggerRef={this.bindRef}
                                   getRoleTypelist={this.getRoleTypelist.bind(this)}></EditableTable>
                </Modal>
            </div>

        );
    }
}

Rolement = Form.create()(Rolement);
export default Rolement;
