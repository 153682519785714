/*绩效管理详情*/
import React, {Component} from "react";
import {Form, Input, Select,} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {
    meritGetInfoById
} from '../../../../../../api/merit/';
const {TextArea} = Input;

@withRouter
class CompleteModal extends Component {
    state = {
        id:this.props.meritList.id,
        meritList: [],
        levelValue:'',
        completeValue:'',
        completeRate:'',
    }

    // 初始化
    componentDidMount() {
        this.props.formRef(this)
        this.meritGetInfoById()

    }

    //设置表单值
    setFormValue = () => {
        this.props.form.setFieldsValue({
            completeValue: this.state.completeValue,
            completeRate: this.state.completeRate,
        })
    }
    getFormValues = () => {
        let data = {
            id: this.state.meritList.id,
            completeValue: this.props.form.getFieldValue('completeValue'),
            completeRate: this.props.form.getFieldValue('completeRate'),
        }
        this.props.form.validateFields((err, datas) => {
            if (err) {
                return data = undefined
            }
        })
        return data;
    }
    meritGetInfoById = async () => {
        let data = {
            id:this.state.id,
        }
        await meritGetInfoById(data).then(res => {
            this.setState({
                meritList: res,
                completeValue:res.completeValue,
                completeRate:res.completeRate,
                levelValue: res.meritType,
            })
        }).then(()=>{
            this.setFormValue()}
        )
    }

    render() {
        const {meritList} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='complete-modal'>
                <div className='list-a'>
                    <Form labelCol={{span: 6}} wrapperCol={{span: 14}} colon={false}>
                        <Form.Item label="目标值">
                            <div className={'danwei'}>{meritList.targetValue}</div>
                        </Form.Item>
                        <Form.Item label="统计单位">
                            <div className={'danwei'}>{meritList.indicatorMeasure}</div>
                        </Form.Item>
                        <Form.Item label="实现值">
                            {
                                getFieldDecorator('completeValue', {
                                    rules: [
                                        {
                                            type: 'string',
                                            min: 1,
                                            max: 200,
                                            message: '实现值不能大于200字符!',
                                        },
                                        {
                                            required: true,
                                            message: '实现值不能为空!'
                                        },
                                    ]
                                })(<Input autocomplete="off" placeholder="请输入实现值"/>)
                            }
                        </Form.Item>
                        <Form.Item label="完成度">
                            {
                                getFieldDecorator('completeRate', {
                                    rules: [
                                        {
                                            required: true,
                                            pattern: new RegExp('^(\\d|[1-9]\\d|1\\d{2}|200)(\\.(\\d){0,2})?$'),
                                            message: '完成度不能为空,只可填数字,保留2位小数,最大到200!'
                                        },
                                    ]
                                })(<Input autocomplete="off" placeholder="请输入完成度" className={'completeness'}/>)
                            }
                            <div className={'danwei'}>%</div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

CompleteModal = Form.create()(CompleteModal);
export default CompleteModal;
