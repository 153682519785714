/*评分情况明细-互评*/
import React, {Component} from "react";
import {
    Form,Select,Table,Tooltip
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {getGroupUserSchool,getExpertRatingDirectory,getSchoolInfoByEduCode} from "../../../api/doublehigh/index"
import Cookies from "js-cookie";
const {Option} = Select;

@withRouter
class DetailsPeer extends Component {
    state = {
        expertsOne:[],
        schoolList:[],
        groupOneList:[],
        groupTwoList:[],
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
        userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo : "",
    }

    componentDidMount() {
        const data = this.props.location.search;  //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log('jsonparam 111', jsonparam)
        if(jsonparam.pingInfo){
            this.setState({
                pingInfo: jsonparam.pingInfo
            }, () =>{
                this.getSchoolInfoByEduCode()
                this.getGroupUserSchool()
                this.getExpertRatingDirectory()
            })
        }
        if(jsonparam.groupIdInfo){
            this.setState({
                groupIdInfo: jsonparam.groupIdInfo
            })
        }
    }

    getGroupUserSchool =()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode
        }
        getGroupUserSchool(data).then(res =>{
            if(res){
                console.log('111111111111', res)
                let userTotalScoreList = res.groupUserVoList.filter( e => e.isEffective === 1)
                console.log('userTotalScoreList 111111', userTotalScoreList)
                let result = 0
                for (let i = 0; i < userTotalScoreList.length; i++){

                    result += Number(userTotalScoreList[i].totalNum)
                }
                console.log('result 111', result)
                let aaa = (result / (userTotalScoreList.length)).toFixed(2)
                console.log('aaaaa 1111111111', aaa)


                let param = []
                for (let i = 0; i < res.groupUserVoList.length; i++){
                    let data = res.groupUserVoList[i];
                    data.expertsName = "互评账号" + [i]
                    param.push(data)
                }
                res.groupUserVoList = param
                this.setState({
                    leftUserInfo: res,
                    sum: aaa
                })
            }
        })
    }

    getExpertRatingDirectory =()=>{
        const {schoolEduCode, schoolInfo, userInfo,groupIdInfo} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            groupId: groupIdInfo.pingGroupId
        }
        getExpertRatingDirectory(data).then(res =>{
            if(res){
                let schoolList = res.filter(e => e.seniorType === 1);
                let groupOneList = res.filter(e => e.seniorType === 2);
                let groupTwoList = res.filter(e => e.seniorType === 3);
                this.setState({
                    leftList: res,
                    saveLeftList: res,
                    schoolList: schoolList,
                    groupOneList: groupOneList,
                    groupTwoList: groupTwoList,
                }, () => {
                    // this.insertUpdateSubmitCurrent()
                })
            }

        })
    }
    getSchoolInfoByEduCode = async () => {
        let { userInfo,schoolEduCode} = this.state;
        let schoolInfo = await getSchoolInfoByEduCode({schoolEduCode: schoolEduCode,userId: userInfo.id});
        this.setState({
            schoolInfo: schoolInfo
        })
    }

    getRecord = (item) =>{
        console.log('itemitem', item)
        let expertsOne = []
        let data = {
            expertsName:item.expertsName,
            selfGrade: this.state.schoolInfo.selfGrade,
            provinceGrade: this.state.schoolInfo.provinceGrade,
            expertsScore:item.totalNum,
            isEffective: item.isEffective
        }
        expertsOne.push(data)
        this.setState({
            expertsOne: expertsOne
        })
    }



    goBack = () => this.props.history.go(-1)

    render() {
        const {schoolInfo,pingInfo,leftUserInfo,schoolList,expertsOne,groupOneList,groupTwoList } = this.state
        const {getFieldDecorator} = this.props.form;
        const columnsOneLeader = [
            {
                title: '专家账号',
                dataIndex: 'expertsName',
                key: 'expertsName',
                align: 'center',
            },
            // {
            //     title: '省份',
            //     dataIndex: 'province',
            //     key: 'province',
            //     align: 'center',
            // },
            {
                title: '学校自评分',
                dataIndex: 'selfGrade',
                key: 'selfGrade',
                align: 'center',
            },
            {
                title: '省级评价分',
                dataIndex: 'provinceGrade',
                key: 'provinceGrade',
                align: 'center',
            },
            {
                title: '专家评价分',
                dataIndex: 'expertsScore',
                key: 'expertsScore',
                align: 'center',
            },
            // {
            //     title: '总分',
            //     dataIndex: 'totalScore',
            //     key: 'totalScore',
            //     align: 'center',
            // },
            {
                title: '说明',
                dataIndex: 'isEffective',
                key: 'isEffective',
                align: 'center',
                render: (e) =>  <div>{e === 0 ? "无效" : e === 1 ? "有效" : e === 2 ? "未打分" : ""}</div>
            },
        ]
        return (
            <div className='DetailsPeer'>
                <div className={'title_liwrp'}>
                    <div className= 'left-retu'onClick={this.goBack}>
                        <span>返回</span>
                    </div>
                </div>
                <div className={'ExpertConsensus_bottom'}>
                    <h3>“双高计划”项目建设互评情况</h3>
                    <div className={'cont_Consensus'}>
                        <div className={'bot_left'}>
                            <div className={'top_leta'}>
                                <span>年度综合评级得分</span>
                                <div className={'bg_icon'}>
                                    {this.state.sum}分
                                </div>
                            </div>
                            <div className={'bot_let'}>
                                <span>共{leftUserInfo ? leftUserInfo.scoreNum : ''}位互评打分</span>
                                <div className={'ul_bottom'}>
                                    <div className={'list'}>
                                        <i>匿名姓名</i>
                                        <i>总分</i>
                                        <i>说明</i>
                                    </div>
                                    <div className={'bot_list'} >
                                    {leftUserInfo && leftUserInfo.groupUserVoList.length > 0 ?
                                        leftUserInfo.groupUserVoList.map((item, index) =>{
                                            return (
                                                <Tooltip  title={item.expertsName} overlayClassName={'Tooltip_contopebn'} placement="topLeft">
                                                    {/*onClick={() => this.getRecord(item)}*/}
                                                    <div className={'li_bot'}>
                                                        <i>{item.expertsName}</i>
                                                        <i>{item.totalNum}</i>
                                                        <i>{item.isEffective === 0 ? "无效" : item.isEffective === 1 ? "有效" : item.isEffective === 2 ? "未打分" : ""}</i>
                                                    </div>
                                                </Tooltip>
                                                )

                                        }) : ''

                                    }
                                    </div>

                                </div>
                            </div>
                            <div className={'top_let'}>
                                <span>年度综合评级</span>
                                <div className={'bg_icon'}>
                                    <i className={'icon'}><img src={require('../../../assets/imgschool/icon_x.png')}/></i>
                                    <b>{pingInfo && pingInfo.reviewGrade === 1 ? "优" :
                                          pingInfo && pingInfo.reviewGrade === 2 ? "良" :
                                            pingInfo && pingInfo.reviewGrade === 3 ? "中" :
                                                pingInfo && pingInfo.reviewGrade === 4 ? "差" : ''}</b>
                                </div>
                            </div>
                        </div>
                        <div className={'bot_right'}>
                            <div className={'top_Chart'}>
                                <div className={'left_Chart'}>
                                    <div className={'let_li'}>
                                        <div className={'leftl'}>
                                            <span className={'icon'}>
                                                <img src={require('../../../assets/imgschool/icon_c.png')}/>
                                            </span>
                                            <i>最高分</i>
                                        </div>
                                        <div className={'rightt'}>
                                            <span className={'h3'}>{pingInfo ? pingInfo.mixScore : ''}分</span>
                                            <span className={'icon'}>
                                                 <img src={require('../../../assets/imgschool/icon_f.png')}/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={'let_li'}>
                                        <div className={'leftl'}>
                                            <span className={'icon'}>
                                                <img src={require('../../../assets/imgschool/icon_d.png')}/>
                                            </span>
                                            <i>最低分</i>
                                        </div>
                                        <div className={'rightt'}>
                                            <span className={'h3'}>{pingInfo ? pingInfo.minScore : ''}分</span>
                                            <span className={'icon'}>
                                                 <img src={require('../../../assets/imgschool/icon_g.png')}/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={'let_li'}>
                                        <div className={'leftl'}>
                                            <span className={'icon'}>
                                                <img src={require('../../../assets/imgschool/icon_e.png')}/>
                                            </span>
                                            <i>中位数</i>
                                        </div>
                                        <div className={'rightt'}>
                                            <span className={'h3'}>{pingInfo ? pingInfo.median : ''}分</span>
                                            <span className={'icon'}>
                                                 <img src={require('../../../assets/imgschool/icon_h.png')}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'right_Chart'} >
                                    <div className={'li_chart li_chart_on'} style={{margin:'0'}}>
                                        <div className={'span'}>
                                            <span>学校自评分</span>
                                        </div>
                                        <div className={'h4_bot'}>
                                            <h4>{schoolInfo ? schoolInfo.selfGrade : 0}</h4>
                                        </div>
                                    </div>
                                    <div className={'li_chart li_chart_on'}>
                                        <div className={'span'}>
                                            <span>省级评价分</span>
                                        </div>
                                        <div className={'h4_bot'}>
                                            <h4>{schoolInfo ? schoolInfo.provinceGrade : 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'table_list'} style={{display:'none'}}>
                                <Table columns={columnsOneLeader} dataSource={expertsOne}
                                       scroll={{x: 300}}
                                       locale={{
                                           emptyText: <>
                                               <div className="Empty-content">
                                                   <div className="text">
                                                       <img src={require('../../../assets/img/synchrodatment/loagin.png')}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div>
                                           </>
                                       }}
                                       pagination={
                                           false
                                       }
                                />
                            </div>
                            <div className={'bottom_tablelist'}>
                                <div className={'top_lista'}>
                                    <span>评价方面</span>
                                    <span>指标名称</span>
                                    <span>最高分</span>
                                    <span>最低分</span>
                                    <span>中位数</span>
                                </div>
                                {/*{*/}
                                {/*    tableList&&tableList.map((item,index)=>{*/}
                                {/*        return(*/}
                                <div className={'bottom_listb'}>
                                    {schoolList && schoolList.length > 0 ?
                                        <div className={'deduction_top'}>
                                            <span>{"学校层面"}</span>
                                            <span>满分{100}分</span>
                                            <span></span>
                                            <span></span>
                                            {/*<span>{this.state.scoreVo.schoolScore}</span>*/}
                                            {/*<span>{this.state.scoreVo.schoolScore}</span>*/}
                                        </div> : ''
                                    }
                                    {
                                        schoolList && schoolList.length > 0 ? schoolList.map((i,key)=>{
                                            return(
                                                <div>

                                                    <div className={'deduction_topa'}>
                                                        <span>一级指标：{i.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span>{i.totalScore ? i.totalScore : 0}</span>
                                                    </div>
                                                    <div className={'deduction_topOne'}>
                                                        <div className={'title'}>
                                                            <span>二级指标</span>
                                                        </div>
                                                        <div className={'right_lis'}>
                                                            {
                                                                i.expertEvaluationVoList&&i.expertEvaluationVoList.map((e,num)=>{
                                                                    return(

                                                                        <div className={e.indicatorLevelName==='数量指标'?'list_one list_one_on':'list_one'}>
                                                                            <span>{e.indicatorLevelName}</span>
                                                                            {
                                                                                e.indicatorLevelName==='质量指标'?<span></span>:<span>{e.mixScore ? e.mixScore : ''}</span>
                                                                            }

                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.minScore ? e.minScore : ''}</span>
                                                                            }
                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.medianScore ? e.medianScore : ''}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                    {groupOneList && groupOneList.length > 0 ?
                                        <div className={'deduction_top'}>
                                            <span>{"专业群一层面"}</span>
                                            <span>满分{100}分</span>
                                            <span></span>
                                            <span></span>
                                            {/*<span>{this.state.scoreVo.groupOneScore}</span>*/}
                                        </div> : ''
                                    }
                                    {
                                        groupOneList && groupOneList.length > 0 ? groupOneList.map((i,key)=>{
                                            return(
                                                <div>

                                                    <div className={'deduction_topa'}>
                                                        <span>一级指标：{i.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span>{i.totalScore}</span>
                                                    </div>
                                                    <div className={'deduction_topOne'}>
                                                        <div className={'title'}>
                                                            <span>二级指标</span>
                                                        </div>
                                                        <div className={'right_lis'}>
                                                            {
                                                                i.expertEvaluationVoList&&i.expertEvaluationVoList.map((e,num)=>{
                                                                    return(

                                                                        <div className={e.indicatorLevelName==='数量指标'?'list_one list_one_on':'list_one'}>
                                                                            <span>{e.indicatorLevelName}</span>
                                                                            {
                                                                                e.indicatorLevelName==='质量指标'?<span></span>:<span>{e.mixScore ? e.mixScore : ''}</span>
                                                                            }

                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.minScore ? e.minScore : ''}</span>
                                                                            }
                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.medianScore ? e.medianScore : ''}</span>
                                                                            }



                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                    {
                                        groupTwoList && groupTwoList.length > 0 ?
                                            <div className={'deduction_top'}>
                                                <span>{"专业群二层面"}</span>
                                                <span>满分{100}分</span>
                                                <span></span>
                                                <span></span>
                                                {/*<span>{this.state.scoreVo.groupTwoScore}</span>*/}
                                            </div> : ''
                                    }
                                    {
                                        groupTwoList && groupTwoList.length > 0 ? groupTwoList.map((i,key)=>{
                                            return(
                                                <div>

                                                    <div className={'deduction_topa'}>
                                                        <span>一级指标：{i.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span>{i.totalScore}</span>
                                                    </div>
                                                    <div className={'deduction_topOne'}>
                                                        <div className={'title'}>
                                                            <span>二级指标</span>
                                                        </div>
                                                        <div className={'right_lis'}>
                                                            {
                                                                i.expertEvaluationVoList&&i.expertEvaluationVoList.map((e,num)=>{
                                                                    return(
                                                                        <div className={e.indicatorLevelName==='数量指标'?'list_one list_one_on':'list_one'}>
                                                                            <span>{e.indicatorLevelName}</span>
                                                                            {
                                                                                e.indicatorLevelName==='质量指标'?<span></span>:<span>{e.mixScore ? e.mixScore : ''}</span>
                                                                            }

                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.minScore ? e.minScore : ''}</span>
                                                                            }
                                                                            {
                                                                                e.indicatorLevelName === '质量指标' ? <span></span> :
                                                                                    <span>{e.medianScore ? e.medianScore : ''}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }

                                </div>
                                {/*)*/}
                                {/*    })*/}
                                {/*}*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

DetailsPeer = Form.create()(DetailsPeer);
export default DetailsPeer;
