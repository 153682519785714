/*数据大屏任务与绩效进展走势*/
import React, {Component} from "react";
import {
    Form
} from "antd";

import {withRouter} from "react-router";
import {queryTaskMeritTrend} from "../../../api/chart"
import * as echarts from "echarts";

@withRouter
class screenTaskPerformance extends Component {
    state = {
        trendType:this.props.trendType,
        xData:[],
        yData:[],
        datanull: false,
        dateType:this.props.dateType,
    }

    componentDidMount() {
        this.queryTaskMeritTrend()
    }
    //任务与绩效进展走势接口
    queryTaskMeritTrend = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType
        }
        await queryTaskMeritTrend(data).then(res => {
            if(res){
                let trendDatas = res.countOfCompletedVos
                let xData = []//x轴数据
                let yData = []//y轴数据
                let months = []//前推12个月数据
                // for (let i = 0; i < 12; i++) {
                //     let date = new Date()
                //     date.setMonth(date.getMonth()-i)
                //     months.push(this.dateFormat("YYYY-mm",date))
                // }
                // months.reverse()
                // console.log('months',months)
                // if(trendDatas){
                //     let dataMonths = []
                //     let dataCounts = []
                //     for (let i = 0; i < trendDatas.length; i++) {
                //         dataMonths.push(trendDatas[i].completeMonth)
                //         dataCounts.push(trendDatas[i].completeCount)
                //     }
                //     for (let i = 0; i < months.length; i++) {
                //         xData.push(months[i].split('-')[1]+'月')
                //         if(dataMonths.includes(months[i])){
                //             console.log('months[i]',months[i])
                //             yData.push(dataCounts[dataMonths.indexOf(months[i])])
                //         }else{
                //             yData.push(0)//没有数据情况
                //         }
                //     }
                // }
                console.log('this.state.dateType',this.state.dateType)
                //12个月
                if(this.state.dateType == 3){
                    for (let i = 0; i < 12; i++) {
                        let date = new Date()
                        date.setMonth(date.getMonth()-i)
                        months.push(this.dateFormat("YYYY-mm",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'月')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //7日
                else if(this.state.dateType ==1){
                    for (let i = 0; i < 7; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    months.reverse()
                    console.log('months888',months)
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            console.log('dataMonths',dataMonths)
                            console.log('months[i]',months[i])
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //30日
                else if(this.state.dateType ==2){
                    for (let i = 0; i < 30; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }

                this.setState({xData:xData,yData:yData},()=>{
                    if(yData.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.screenTaskPerformance()
                        this.setState({datanull: false})
                    }
                })

            }

        })
    }
    dateFormat = (fmt, date) => {
        console.log('date',typeof(date))
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    }
    screenTaskPerformance = () => {
        console.log('xdata',this.state.xData)
        console.log('ydata',this.state.yData)
        if(document.getElementById('screenTaskPerformance')) {
            let myChart = echarts.init(document.getElementById('screenTaskPerformance'));
            let option = {
                tooltip: {
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>'+params.name+'</div>' +
                            '<div style="text-align: center">'+params.value
                            + '个</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: '5%',
                    top: 20,
                    bottom: 30
                },
                xAxis: {
                    type: 'category',
                    data: this.state.xData,
                    axisLine:{

                        show:false //y轴线消失

                    },
                    axisLabel:{
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: '.14rem'
                        }
                    },
                },
                color: '#588BF7',
                yAxis: {
                    type: 'value',
                    axisLine:{

                        show:false //y轴线消失

                    },
                    axisLabel:{
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: '.14rem'
                        }
                    },
                    axisTick: {
                        show:true, //y轴坐标点消失,
                    },
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type: 'dashed',
                            color: 'rgba(57, 69, 106, 0.3)'
                        }
                    }
                },
                series: [
                    {
                        // data: [120, 200, 150, 80, 70, 110, 130,120, 200, 150, 80, 70],
                        data: this.state.yData,
                        type: 'bar',
                        barWidth: 20,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(238, 238, 238, 0.3)'
                        },
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    let colorList = ['#1B85F2','#28DCD3','#1B85F2','#28DCD3','#1B85F2','#28DCD3','#1B85F2','#28DCD3','#1B85F2','#28DCD3','#1B85F2','#28DCD3']
                                    return colorList[params.dataIndex]
                                }
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        return (
            <div id={'screenTaskPerformance'} className={'echarts'} style={{width: '100%', height: '100%'}}></div>

        );
    }
}

screenTaskPerformance = Form.create()(screenTaskPerformance);
export default screenTaskPerformance;
