/*eslint eqeqeq: ["off", "smart"]*/
// 第四页2020年度预算
import React, {Component} from 'react';
import {Row, Col, Button, Table, Form} from 'antd';
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';

import { itemSumBudget} from '@/api/synchrodatment';
import SchoolItemSumBudgetStat from "./schoolItemSumBudgetStat2020";
class Fourbudget extends Component {
    state = {
        columns: [
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'xjMoney',
                key: 'xjMoney'

            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'xjScale',
                key: 'xjScale'
            },
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'zyMoney',
                key: 'zyMoney'
            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'zyScale',
                key: 'zyScale'
            },
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'dfMoney',
                key: 'dfMoney'
            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'dfScale',
                key: 'dfScale'
            },
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'jbfMoney',
                key: 'jbfMoney'
            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'jbfScale',
                key: 'jbfScale'
            },
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'hyMoney',
                key: 'hyMoney'
            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'hyScale',
                key: 'hyScale'
            },
            {
                title: (
                    <div className='text-span'>
                        <i>金额</i>
                        <i>（万元）</i>
                    </div>
                ),
                dataIndex: 'xxMoney',
                key: 'xxMoney'
            },
            {
                title: (
                    <div className="text-em">
                        <i>比例</i>
                        <i>（%）</i>
                    </div>
                ),
                dataIndex: 'xxScale',
                key: 'xxScale'
            },
        ],
        dataSource: [
            {
                xjMoney:'1',xjScale:'1',
                zyMoney:'1',zyScale:'1',
                dfMoney:'1',dfScale:'1',
                jbfMoney:'1',jbfScale:'1',
                hyMoney:'1',hyScale:'1',
                xxMoney:'1',xxScale:'1',
            },
        ],
        leftCatalogList: [],
        taskId:'',
        schoolId: "",
        category: 0,
        upSeniorId: "2.4"
    };

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            taskId: jsonparam.taskId,
            schoolId: jsonparam.schoolId,
            category: jsonparam.category
        },() =>{
            this.itemSumBudget();
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    itemSumBudget = async () => {
        let {taskId, upSeniorId} = this.state;
        let leftCatalogListObj = await itemSumBudget({taskId: taskId, upSeniorId: upSeniorId});
        this.setState({leftCatalogList: leftCatalogListObj.leftCatalogList})
    }

    // 预览第5页
    fifthPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId,category:category};
        this.props.history.push({
            pathname: '/addschool',
            search: JSON.stringify(param)
        })
    }

    render() {
        const {columns, dataSource, leftCatalogList, taskId, schoolId,category,upSeniorId} = this.state;
        const list =  leftCatalogList.map((item, i) => (
            <li key={i}>
                <h3>{item.seniorName}</h3>
                {item.status === 2 ? item.subTaskList.map((subTask, j) => (
                    <div key={j}>
                        <p>{subTask.subtaskName}</p>
                        <div className="table-ul">
                            <div className="table-th">
                                <ul>
                                    <li>小计</li>
                                    {subTask.investDetails.map((investDetail, k) => (
                                        <li key={k}>{investDetail.investName}</li>
                                    ))}
                                </ul>
                                <Table className="main-box-header table-maina" cellspacing={20} columns={columns}
                                       dataSource={subTask.itemSumBudgetVo} pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                )):<div className="Empty-content">
                    <div className="text">
                        <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                        <span>暂无数据</span>
                    </div>
                </div>}
            </li>
        ))
        return (
            <div className="Comment-TwoBudget main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>
                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} upSeniorId={upSeniorId} category={category}/>}
                                </Col>
                                <Col span={16} className="right">
                                    <div className="project-budget">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>4</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <h2>1-4 学校项目2020年度预算</h2>
                                        <ul>
                                            {list}
                                        </ul>
                                        <SchoolItemSumBudgetStat></SchoolItemSumBudgetStat>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" onClick={()=> this.fifthPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

Fourbudget = Form.create()(Fourbudget);
export default Fourbudget;