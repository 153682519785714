// 资金明细管理(建设任务)
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Form, Select, Table, Pagination, Progress, DatePicker, Tooltip, Modal,} from "antd";
import style from './index.module.scss'
import RenderButton from "../../../../components/renderButton";
import * as echarts from "echarts";
import moment from "moment";
import {getTaskSelectByProjectId, getExpendListByProjectId, getExpendStatisticByProjectId,goToAddExpendPrepare,} from "../../../../api/fund"
import Cookies from "js-cookie";
import S from "gongyongicon";
const {RangePicker} = DatePicker;

@withRouter
class Financialdetails extends Component {
    state = {
        columns: [
            {
                title: '单号',
                dataIndex: 'expendCode',
                width: 140,
                align: "center",
                key: 'expendCode',

            },
            {
                title: '任务',
                align: "center",
                width: 250,
                dataIndex: 'taskName',
                key: 'taskName',
                render: (text,record) => {
                    return (text&&text.length>10?
                        <Tooltip  placement="bottomLeft" title={text} overlayClassName='TitleTooltip'
                        ><span>{text}</span></Tooltip>:<span>{text}</span>)
                }
            },
            {
                title: '填报人',
                align: "center",
                width: 200,
                dataIndex: 'fillUserName',
                key: 'fillUserName',
            },
            {
                title: '填报时间',
                align: "center",
                width: 160,
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text) => {
                    return text.replace('T', ' ')
                }

            },
            {
                title: '类型  ',
                align: "center",
                width: 150,
                dataIndex: 'expendType',
                key: 'expendType',
                render: (text, record) => {
                    return (
                        <div>
                            {text === 1 ? <span>采购费</span> : text === 2 ? <span>差旅费</span> : text === 3 ?
                                <span>培训费</span> : <span>其他</span>}
                        </div>
                    )
                }
            },
            {
                title: '总金额  ',
                align: "center",
                width: 160,
                dataIndex: 'expendSum',
                key: 'expendSum',
                render: (text, record) => {
                    return (
                        <div>
                            <span>{text.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                        </div>
                    )
                }
            },
            {
                title: '状  态',
                dataIndex: 'approvalStatus',
                width: 80,
                align: "center",
                key: 'approvalStatus',
                render: (text, record) => {
                    return (
                        <div>
                            {text === 1 ? <span>审批中</span> : text === 2 ? <span>已通过</span> : text === 3 ?
                                <span>已拒绝</span> : <span>已撤销</span>}
                        </div>
                    )
                }
            },
            {
                title: '操 作',
                align: "center",
                width: 200,
                fixed: "right",
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className={'oper'}>
                        <RenderButton id='SS-01-B02-b_btnEditUser'>
                            <span onClick={() => this.goToExpendDetail(record)}>详情</span>
                        </RenderButton>

                    </div>
                }
            }],
        tableList: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        projectId: this.props.match.params.id,
        currentTab:0,
        auditStatus:1,
        investStatistic: {
            amountSum: 0,
            centerAmount: 0,
            localAmount: 0,
            holderAmount: 0,
            enterpriseAmount: 0,
            schoolAmount: 0,
        },// 预算统计
        expendStatistic: {
            amountSum: 0,
            centerAmount: 0,
            localAmount: 0,
            holderAmount: 0,
            enterpriseAmount: 0,
            schoolAmount: 0,
        }, // 支出统计
        remainStatistic: {
            amountSum: 0,
            centerAmount: 0,
            localAmount: 0,
            holderAmount: 0,
            enterpriseAmount: 0,
            schoolAmount: 0,
        }, // 剩余统计
        useRate: 0, // 使用率
        taskSelectList: [],//任务下拉数据
        search: {
            startDate: "",//开始日期
            endDate: "",//结束日期
            taskId: "",//任务ID
            expendType: "",//支出类型
            approvalStatus: "",//审批状态
        },
        expendDataAmountSum: 0,//列表支出合计
        selectOfTaskInit: undefined,//任务下拉框初始值
        selectOfTypeInit: undefined,//类型下拉框初始值
        selectOfStatusInit: undefined,//状态下拉框初始值
        datepickerInit: [],//状态下拉框初始值
        isShowTip:false,
        yearList: [
            {
                id: '',
                year: '全部'
            },
            {
                id: '2019',
                year: '2019年'
            },
            {
                id: '2020',
                year: '2020年'
            },
            {
                id: '2021',
                year: '2021年'
            },
            {
                id: '2022',
                year: '2022年'
            },
            {
                id: '2023',
                year: '2023年'
            },
        ],
        year:'',
    }

    componentDidMount() {
        this.getExpendStatisticByProjectId()//统计
        this.getTaskSelectByProjectId()//任务下拉框
        this.getExpendListByProjectId()//支出列表

    }

    //region api接口
    //支出统计
    getExpendStatisticByProjectId = async () => {
        let data = {
            projectId: this.state.projectId,
            year:this.state.year
        }
        await getExpendStatisticByProjectId(data).then(res => {
            if (res) {
                this.setState({
                    //预算统计
                    investStatistic: {
                        amountSum: res.investFundTypeVo.amountSum,
                        centerAmount: res.investFundTypeVo.centerAmount,
                        localAmount: res.investFundTypeVo.localAmount,
                        holderAmount: res.investFundTypeVo.holderAmount,
                        enterpriseAmount: res.investFundTypeVo.enterpriseAmount,
                        schoolAmount: res.investFundTypeVo.schoolAmount,
                    },
                    //支出统计
                    expendStatistic: {
                        amountSum: res.expendFundTypeVo.amountSum,
                        centerAmount: res.expendFundTypeVo.centerAmount,
                        localAmount: res.expendFundTypeVo.localAmount,
                        holderAmount: res.expendFundTypeVo.holderAmount,
                        enterpriseAmount: res.expendFundTypeVo.enterpriseAmount,
                        schoolAmount: res.expendFundTypeVo.schoolAmount,
                    },
                    //剩余统计
                    remainStatistic: {
                        amountSum: res.remainFundTypeVo.amountSum,
                        centerAmount: res.remainFundTypeVo.centerAmount,
                        localAmount: res.remainFundTypeVo.localAmount,
                        holderAmount: res.remainFundTypeVo.holderAmount,
                        enterpriseAmount: res.remainFundTypeVo.enterpriseAmount,
                        schoolAmount: res.remainFundTypeVo.schoolAmount,
                    },
                    useRate: res.useRate
                }, () => {
                    this.estimatedAmount()//预算统计
                    this.actualAmountUsed()//支出统计
                    this.balance()//剩余统计

                })
            }

        })
    }
    //查询任务下拉框，通过建设任务ID
    getTaskSelectByProjectId = async () => {
        let data = {
            projectId: this.state.projectId
        }
        await getTaskSelectByProjectId(data).then(res => {
            if (res) {
                this.setState({
                    taskSelectList: res,
                })
            }

        })
    }
    //项目资金支出列表，通过建设任务ID
    getExpendListByProjectId = async () => {
        let data = {
            year:this.state.year,
            projectId: this.state.projectId,
            startDate: this.state.search.startDate,
            endDate: this.state.search.endDate,
            taskId: this.state.search.taskId,
            expendType: this.state.search.expendType,
            approvalStatus: this.state.search.approvalStatus,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,

        }
        await getExpendListByProjectId(data).then(res => {
            if (res) {
                this.setState({
                    tableList: res.expendData.data,
                    expendDataAmountSum: res.expendDataAmountSum,
                    total: res.expendData.total,
                    pageSize: res.expendData.pageSize,
                    pageNum: res.expendData.pageNum,
                })
            }
        })
    }
    //跳转添加支出 预处理
    goToAddExpendPrepare = async () => {
        let data = {
            projectId: this.state.projectId,
        }
        await goToAddExpendPrepare(data).then(res => {
            console.log('res.isSetInvest',res.isSetInvest)
            console.log('res.isSetInvest',res.isSetInvest === 0)
            if(res.isSetInvest === 0){
                this.setState({isShowTip:true})
                return false
            }else{
                this.props.history.push({
                    pathname: '/main/doublehigh/project/' + this.state.projectId + '/spendingallowed',
                    // jumpSource: 'project',
                })
            }
        })
    }
    //endregion
    //预计金额柱状图
    estimatedAmount = () => {
        let data = [
            this.state.investStatistic.centerAmount,
            this.state.investStatistic.localAmount,
            this.state.investStatistic.holderAmount,
            this.state.investStatistic.enterpriseAmount,
            this.state.investStatistic.schoolAmount,
        ]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('estimatedAmount')) {
            let myChart = echarts.init(document.getElementById('estimatedAmount'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: ['中央财政投入资金', '地方各级财政投入资金', '举办方投入资金', '行业企业支持资金', '学校自筹资金'],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //实际使用金额柱状图
    actualAmountUsed = () => {
        let data = [
            this.state.expendStatistic.centerAmount,
            this.state.expendStatistic.localAmount,
            this.state.expendStatistic.holderAmount,
            this.state.expendStatistic.enterpriseAmount,
            this.state.expendStatistic.schoolAmount,
        ]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('actualAmountUsed')) {
            let myChart = echarts.init(document.getElementById('actualAmountUsed'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: ['中央财政投入资金', '地方各级财政投入资金', '举办方投入资金', '行业企业支持资金', '学校自筹资金'],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //剩余金额柱状图
    balance = () => {
        let data = [
            this.state.remainStatistic.centerAmount,
            this.state.remainStatistic.localAmount,
            this.state.remainStatistic.holderAmount,
            this.state.remainStatistic.enterpriseAmount,
            this.state.remainStatistic.schoolAmount,
        ]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('balance')) {
            let myChart = echarts.init(document.getElementById('balance'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: ['中央财政投入资金', '地方各级财政投入资金', '举办方投入资金', '行业企业支持资金', '学校自筹资金'],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            },
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //任务下拉
    selectOnChangeOfTask = (e) => {
        this.state.search.taskId = e
        this.setState({selectOfTaskInit: e})
    }
    //类型下拉
    selectOnChangeOfType = (e) => {
        this.state.search.expendType = e
        this.setState({selectOfTypeInit: e})
    }
    //状态下拉
    selectOnChangeOfStatus = (e) => {
        this.state.search.approvalStatus = e
        this.setState({selectOfStatusInit: e})
    }
    //填报日期
    dateOnChangeOfTianbao = (value, dateString) => {
        this.state.search.startDate = dateString[0]
        this.state.search.endDate = dateString[1]
        this.setState({datepickerInit: value})
    }
    // 支出填报
    zhichutb = () => {
        this.goToAddExpendPrepare()
        // Cookies.set('jumpSource', {'addExpend':'project'});
    }
    // 查看详情
    goToExpendDetail = (record) => {
        this.props.history.push({
            pathname: '/main/doublehigh/capitalexpenditurereview/' + record.id,
        })
        Cookies.set('jumpSource', {'expendDetail':'project'});
    }
    //查询重置
    searchReset = () => {
        this.setState({
            selectOfTaskInit: undefined,//任务下拉框初始值
            selectOfTypeInit: undefined,//类型下拉框初始值
            selectOfStatusInit: undefined,//状态下拉框初始值
            datepickerInit: [],
            search: {
                taskId: "",//搜索——任务
                expendType: "",//搜索——类型
                approvalStatus: "",//搜索——状态
                startDate: "",
                endDate: ""
            }
            // pageNum:1,

        })
    }
    //条件查询
    searchCondition = () => {
        this.getExpendListByProjectId()
    }
    changePage = (paqination) => {
        console.log('paqination',paqination)
        this.setState({
            pageNum: paqination,
        }, () => {
            //列表方法
            this.getExpendListByProjectId()
        })
    }
    showSizeChange = (current, size) => {
        this.setState({pageSize: size},() => {
            this.getExpendListByProjectId()
        })
    }
    // 年份切换
    changeYear =(item,index)=>{
        console.log('year',item.id)
        this.setState({
            year:item.id,
            currentTab:index,
            search:{
                startDate: '',
                endDate: '',
                taskId: '',
                expendType: '',
                approvalStatus: '',
            },
            selectOfTaskInit:'',
            selectOfTypeInit:'',
            selectOfStatusInit:'',
            datepickerInit:[],
        },()=>{
            this.searchCondition()
            this.getExpendStatisticByProjectId()
        })


    }

    render() {
        const {
            columns, tableList, pageSize, total, pageNum, investStatistic, expendStatistic, remainStatistic, useRate, taskSelectList,
            expendDataAmountSum, selectOfTaskInit, selectOfTypeInit, selectOfStatusInit, datepickerInit,yearList,currentTab
        } = this.state;
        let pagination = {
            pageSize: pageSize,
            total: total,
            pageNum: pageNum,
            current: pageNum,
            showSizeChanger: true,
            onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            onShowSizeChange: this.showSizeChange
        }
        return (
            <div className={style.financial_details}>
                <div className={'financial_year'}>
                    <div className={'name'}>年份：</div>
                    <ul className={'yearul'}>
                        {
                            yearList.map((item,index)=>{
                                return <li className={`${index===currentTab?"year_active":null}`} onClick={()=>this.changeYear(item,index)}>{item.year}</li>
                            })
                        }


                    </ul>

                </div>
                <ul className={'echartswrap'}>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>预算金额(元)</span>
                            <span className={'textone'}>￥{investStatistic.amountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                            <div id={'estimatedAmount'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>实际使用金额(元)</span>
                            <span className={'textone'}>￥{expendStatistic.amountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                            <div id={'actualAmountUsed'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>剩余金额(元)</span>
                            <span className={'textone'}>￥{remainStatistic.amountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                            <div id={'balance'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>资金执行率</span>
                            <span className={'textone textred'}>{useRate}%</span>
                            <Progress percent={useRate} className={'progress'} strokeWidth={'10px'} strokeColor={'#40a9ff'}
                                      showInfo={false}/>
                        </div>
                    </li>
                </ul>
                <div className={'financial_details_box'}>
                    <div className={'financial_details_input'}>
                        <span className={'span_riqi'}><label>填报日期</label>
                       <RangePicker
                           style={{width: 230}}
                           disabledDate={this.disabledStartDate}
                           placeholder="请选择填报时间"
                           value={datepickerInit}
                           onChange={this.dateOnChangeOfTianbao}
                           onOpenChange={this.handleStartOpenChange}
                           className={'time'}
                           getCalendarContainer={triggerNode => triggerNode.parentNode}
                       />
                        </span>
                        <span className={'span_riqi'}><label>任务</label>
                        <Select className={'select_in'}
                                style={{width: 230}}
                                placeholder=" 全部"
                                value={selectOfTaskInit}
                                allowClear={true}
                                onChange={this.selectOnChangeOfTask}
                        >
                            {taskSelectList && taskSelectList.map(item => (
                                <Select.Option value={item.id} title={item.taskName}>{item.taskName}</Select.Option>
                            ))}
                            </Select>
                        </span>
                        <span className={'span_riqi'}><label>类型</label>
                        <Select className={'select_in'}
                                style={{width: 230}}
                                placeholder="全部 "
                                value={selectOfTypeInit}
                                allowClear={true}
                                onChange={this.selectOnChangeOfType}
                        >
                                <Select.Option value=''>全部</Select.Option>
                                <Select.Option value='1'>采购费</Select.Option>
                                <Select.Option value='2'>差旅费</Select.Option>
                                <Select.Option value='3'>培训费</Select.Option>
                                <Select.Option value='4'>其他</Select.Option>
                            </Select>
                        </span>
                        <span className={'span_riqi'}><label>状态</label>
                        <Select className={'select_in'}
                                style={{width: 230}}
                                placeholder="全部 "
                                value={selectOfStatusInit}
                                allowClear={true}
                                onChange={this.selectOnChangeOfStatus}
                        >
                                <Select.Option value='1'>审批中</Select.Option>
                                <Select.Option value='2'>已通过</Select.Option>
                                <Select.Option value='3'>已拒绝</Select.Option>
                                <Select.Option value='4'>已撤销</Select.Option>
                            </Select>
                        </span>
                    </div>
                    <div className={'financial_button'}>
                        <span className={'span_buttom'}>
                             <Button className={'button_resize'} onClick={() => this.searchReset()}>重 置</Button>
                             <Button className={'button_chaxun'} onClick={() => this.searchCondition()}>查 询</Button>
                        </span>
                    </div>
                </div>
                <div className={'financial_list_box'}>
                    <div className={'span_list'}>
                        <div className={'button_span_zhichu'} onClick={() => this.zhichutb()}>
                            支出填报
                        </div>
                        <span>共找到{total}条数据，合计金额为</span>
                        <i>{expendDataAmountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')} 元</i>
                    </div>
                    <div className={'table_t'}>
                        <Table cellspacing={20} columns={columns}
                               className={'tableUserStyle'}
                               dataSource={tableList}
                               scroll={{x: 1500, y: 260}}
                               pagination={pagination}
                               rowKey={(r, i) => (r.id)}
                               locale={{emptyText:<><div className="Empty-conet-ss">
                                       <div className="img">
                                           <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div></>}}
                        />
                    </div>


                </div>

                {/*未设置预算提示*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.isShowTip}
                    onOk={()=>this.setState({isShowTip:false})}
                    onCancel={()=>this.setState({isShowTip:false})}
                    width={500}
                    closable={false}
                    className={style.aacapitalviewmodaltishi}
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                        <span className={'tip'}>支出填报提示</span>
                    </div>
                    <div className={'group'}><span className={'redzi'}>该建设任务预算为0，无法添加支出。如有问题请联系资金主管。</span></div>
                </Modal>

            </div>


        );
    }
}

Financialdetails = Form.create()(Financialdetails);
export default Financialdetails;
