// 绩效指标完成度——详情
import React, {Component} from "react";
import {Button, Dropdown, Form, Menu, Modal, Table, Tabs,} from "antd";
import {withRouter} from "react-router";
import {Readsign,Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import {queryMeritCompleteDuDetail,queryConfitionByCTAndDT,queryConfitionValues,addChartConfition,editChartConfition} from "../../../../../api/chart/index"
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import Cookies from "js-cookie";
import ScreeningCondition from "../../newReportFile/screeningCondition";
const { TabPane } = Tabs;
@withRouter
class Editorperformanceindicator extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        total:0,
        columns: [
            {
                title: '绩效指标',
                dataIndex: 'meritName',
                width: 200,
                align: "center",
                key: 'meritName',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'meritType',
                key: 'meritType',
                render: (text) => {
                    return (
                        this.state.queryCatgory===1?
                            <div>
                                <span>{text==1?'学校':text==2?'专业群一':text==3?'专业群二':'其他'}</span>
                            </div>:
                            <div>
                                <span>{text==1?'学校':text==2?'专业群':'其他'}</span>
                            </div>
                    )
                }

            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'oneIndicatorName',
                key: 'oneIndicatorName',

            },
            // {
            //     title: '二级指标',
            //     align: "center",
            //     width: 200,
            //     dataIndex: 'expendAmountt',
            //     key: 'expendAmount',
            //
            // },
            {
                title: '完成度',
                align: "center",
                width: 200,
                dataIndex: 'completeRate',
                key: 'completeRate',

            },


            ],
        pageDataList: [],
        cengMian:1,//1学校2专业全3其他
        outputMeritIndicatorCompleteDu:'',//产出指标
        benefitMeritIndicatorCompleteDu:'',//效益指标
        satisfactionIndicatorCompleteDu:'',//满意度指标
        queryCatgory:0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        chartId:'',
        chartType:'',
        allConditionList:[],
        addConditionList:[],
        noAddConditionList:[],
        allConfitionValues:[],
        clearCondition:false//清除筛选
    }


    componentDidMount() {
        this.queryMeritCompleteDuDetail()
        this.queryCatgoryById()
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    queryMeritCompleteDuDetail = async () => {
        let data = {
            cengMian: this.state.cengMian,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
        }
        await queryMeritCompleteDuDetail(data).then(res => {
            if(res){
                this.setState({
                    outputMeritIndicatorCompleteDu:parseFloat(res.outputMeritIndicatorCompleteDu).toFixed(2),
                    benefitMeritIndicatorCompleteDu:parseFloat(res.benefitMeritIndicatorCompleteDu).toFixed(2),
                    satisfactionIndicatorCompleteDu:parseFloat(res.satisfactionIndicatorCompleteDu).toFixed(2),
                    pageNum: res.pageData.pageNum,
                    pageSize: res.pageData.pageSize,
                    pageDataList: res.pageData.data,
                    total:res.pageData.total,
                    chartId:res.chartId,
                    chartType:res.chartType,

                },()=>{
                    this.echarts()
                    this.queryConfitionValues()
                    this.queryConfitionByCTAndDT()
                })
            }
        })
    }
    //获取筛选条件，通过图表类型和维度类型
    queryConfitionByCTAndDT = async () => {
        let data = {
            chartId: this.state.chartId,
            chartType:this.state.chartType,
        }
        await queryConfitionByCTAndDT(data).then(res => {
            if(res){
                // let addConditionList = addConditionList
                // for (let i = 0; i < res.addConditionVoList.length; i++) {
                //     addConditionList.push({id:res.addConditionVoList[i].id,})
                // }
                this.setState({allConditionList:res.allConditionVoList,addConditionList:res.addConditionVoList,noAddConditionList:res.noAddConditionVoList,})
            }

        })

    }
    //获取筛选条件的值，通过图表ID或（图表类型和维度类型）
    queryConfitionValues = async () => {
        let data = {
            chartType:this.state.chartType,
        }
        await queryConfitionValues(data).then(res => {
            if(res){
                for (let i = 0; i < res.length; i++) {
                    res[i]['treeData'] = []
                    let typeValues = res[i].typeValues
                    for (let j = 0; j < typeValues.length; j++) {
                        res[i].treeData.push({
                            id:typeValues[j].type,
                            value: typeValues[j].type,
                            title: typeValues[j].label
                        })
                    }
                }
                console.log('res',res)
                this.setState({allConfitionValues:res})

            }

        }).then(res=>{
            console.log('allConfitionValues',this.state.allConfitionValues)
        })
    }
    //添加筛选条件
    addChartConfition  = async (data) => {
        await addChartConfition(data).then(res => {
            if(res){
                this.mesSuccess("添加成功")
            }

        })
    }
    //编辑筛选条件
    editChartConfition = async (data) => {
        await editChartConfition(data).then(res => {
            if(res){

            }

        })
    }

    echarts = ()=>{
        if(document.getElementById('performanceIndicator')){
            let data = [this.state.outputMeritIndicatorCompleteDu,this.state.satisfactionIndicatorCompleteDu,this.state.benefitMeritIndicatorCompleteDu]
            let myChart = echarts.init(document.getElementById('performanceIndicator'));
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        indicator: [
                            { text: '产出指标',min:0,max:150},
                            { text: '满意度指标',min:0,max:150},
                            { text: '效益指标',min:0,max:150},
                        ],
                        center: ['50%', '50%'],
                        radius: 85,
                        // startAngle: 90,
                        splitNumber: 5,
                        name: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#333333'
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['rgba(255,255,255, 0.2)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204,0.3)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204, 1)'
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: '雷达图',
                        type: 'radar',
                        symbol: 'none',
                        symbolSize: 0,
                        areaStyle: {
                            normal: {
                                shadowBlur: 13,
                                shadowColor: 'rgba(0,0,0,.2)',
                                shadowOffsetX: 0,
                                shadowOffsetY: 10,
                                opacity: 1
                            }
                        },
                        data: [
                            {
                                value: data,
                                name: '一级绩效指标',//一级绩效指标
                                tooltip: {
                                    trigger: 'item'
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [{
                                                offset: 0,
                                                color: 'rgba(253, 149, 136, 0.4)'
                                            },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(253, 149, 136, 0.4)'
                                                }
                                            ],
                                            false
                                        ),
                                        opacity: 1 // 区域透明度
                                    },
                                },
                                label: {
                                    show: true,
                                    color:'rgba(0,0,0,0)',
                                    formatter: function(params) {
                                        return params.value;
                                    }
                                },
                                lineStyle:{
                                    color:'#FD9588'
                                },

                            }
                        ]
                    },
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    // 编辑
    handLook = () =>{
        this.mesWarning('暂无可用筛选条件')
        // return
        // this.setState({
        //     isreportEditor:true
        // })
    }

    onChangeTab = (e) =>{
        this.setState({
            cengMian:e
        },()=>{
            this.queryMeritCompleteDuDetail()
        })
    }
    //翻页
    onChangePage = (pageNum, filters, sorter, extra)=>{
        this.setState({
            pageNum:pageNum,
        },()=>{
            this.queryMeritCompleteDuDetail()
        })

    }
    //切页
    sizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.queryMeritCompleteDuDetail()
        })
    }
    //添加筛选条件
    addConditionOk = ()=>{
        console.log('1')
        let checkValues = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < checkValues.length; i++) {
            data.push({conditionId:checkValues[i]})
        }
        this.addChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.setState({isreportEditor: false})
            //刷新页面
            this.queryMeritCompleteDuDetail()
        })

    }
    //按钮保存(编辑筛选条件)
    saveOk = ()=>{
        let data = this.editCondition.getConditionValueChange()
        console.log('data1',data)
        this.editChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.mesSuccess("保存成功")
            //刷新页面
            this.queryMeritCompleteDuDetail()
        })
    }
    //清除筛选
    clearCondition = ()=>{
        this.mesWarning('暂无清除筛选')
    }

    //导出任务图表
    exportExcel= ()=>{
        // window.location.href = "http://localhost:30001/api/v1/chart/exportExcel"
        window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?chartId="+this.state.chartId+"&schoolId="+this.state.schoolId+"&chartType="+9+"&cengMian="+this.state.cengMian+""
        // exportExcel();
    }
    previewModelChart = ()=>{
        this.mesWarning('暂无预览')
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor,queryCatgory} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            onChange: this.onChangePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            onShowSizeChange: this.sizeChange
        }
        return (
            <div className={style.editorperformanceindicator}>
                <div className={'performanceindicator_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>绩效指标完成度</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        {/* todo 图表详情页无用图标暂时注掉 end*/}
                        {/*<div className={'button_topR'} onClick={()=>this.exportExcel()}>*/}
                        {/*    <span>导出</span>*/}
                        {/*</div>*/}
                        {/*<div className={'button_topRB'} onClick={()=>this.saveOk()}>*/}
                        {/*    <span>保存</span>*/}
                        {/*</div>*/}
                        {/* todo 图表详情页无用图标暂时注掉 end*/}

                        {/*<Dropdown overlay={<Menu>*/}
                        {/*    <Menu.Item><span>订阅设置</span></Menu.Item>*/}
                        {/*</Menu>}>*/}
                        {/*    <div className={'button_topG'}>*/}
                        {/*        <span>更多</span>*/}
                        {/*    </div>*/}
                        {/*</Dropdown>*/}
                        {/*<div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>*/}
                        <div className={'button_topgb'} onClick={()=>{this.props.history.goBack()}}>
                            <span>返回</span>
                        </div>
                    </div>
                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(e)=>this.onChangeTab(e)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群一'} key="2">
                                </TabPane>
                                <TabPane  tab={'专业群二'} key="3">
                                </TabPane>
                                <TabPane  tab={'其他'} key="4">
                                </TabPane>
                            </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(e)=>this.onChangeTab(e)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane  tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane  tab={'其他'} key="4">
                                </TabPane>
                            </Tabs>}
                            <div id={'performanceIndicator'} className={'echarts'} style={{width: '100%', height: '240px',marginTop: '40px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>绩效指标完成度</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 900,y:290 }}
                                           // onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>

                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'taskprogress_right_listbox'}>*/}
                        {/*    <p></p>*/}
                        {/*    <span className={'span_licon'}>筛选</span>*/}
                        {/*</div>*/}
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.trendType}
                                addConditionList={this.state.addConditionList}
                                allConfitionValues={this.state.allConfitionValues}
                                chartId={this.state.chartId}
                                editCondition ={(child)=>this.editCondition=child}
                            />
                            {/*<p className={'shaixuank'} onClick={this.handLook}>*/}
                            {/*    <span className={'Readsign_svg'}><Readsign></Readsign></span>*/}
                            {/*    <span className={'span_add'}>添加筛选条件</span>*/}
                            {/*</p>*/}
                        </div>
                        {/* todo 图表详情页无用图标暂时注掉 end*/}
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.addConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.trendType}
                                noAddConditionList={this.state.noAddConditionList}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>
                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'button_button'}>*/}
                        {/*    <div className={'button_away'}>*/}
                        {/*        <span onClick={()=>this.clearCondition()}>清除筛选</span>*/}
                        {/*    </div>*/}
                        {/*    <div className={'button_chart'}>*/}
                        {/*        <span onClick={()=>this.previewModelChart()}>预览图表</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* todo 图表详情页无用图标暂时注掉 end*/}
                    </div>
                </div>
            </div>
        );
    }
}

Editorperformanceindicator = Form.create()(Editorperformanceindicator);
export default Editorperformanceindicator;
