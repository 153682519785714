import {get, post } from '../../until/axios_instans';
//首页根据用户id查询学校任务书
// export function getSchoolTaskBook(data) {
//     console.log("6666")
//     console.log(data)
//     return get(`/api/dh/api/v1/dh/schoolDoubleHigh/getSchoolTaskBook?taskId=${data}`, data);
// }
//学校联系人及联系方式
// export function schoolContact(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/schoolContact', data);
// }
//任务书总进度
// export function taskBookTotalProgress(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/taskBookTotalProgress', data);
// }
//首页根据用户id查询学校任务书
export function getSchoolTaskBook(data) {
    return get(`/api/ss/api/v1/school/getSchoolTaskBook?taskId=${data}`, data);
}
//部级-学校联系人
export function schoolContact(data) {
    return post('/api/ss/api/v1/school/schoolContact', data);
}
//部级-任务书总进度
export function taskBookTotalProgress(data) {
    return post('/api/ss/api/v1/school/taskBookTotalProgress', data);
}
//任务书 封皮 基本信息
export function coverBasicInfo(data) {
    return post('/api/dh/api/v1/dh/schoolDoubleHigh/coverBasicInfo?schoolId='+data.schoolId+'&taskId='+data.taskId);
}
// // 预览查看左侧所有菜单
// export function lookTaskProcessList(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/lookTaskProcessList?taskId='+data.taskId);
// }
// 预览查看左侧所有菜单
export function lookTaskProcessList(data) {
    return post('/api/ss/api/v1/school/lookTaskProcessList?taskId='+data.taskId);
}
// //学校建设任务与进度
// export function schoolTaskAndProcess(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/schoolTaskAndProcess?upSeniorId='+data.upSeniorId+'&taskId='+data.taskId);
// }
// //学校项目总预算 和 学校项目2020年度预算
// export function itemSumBudget(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/itemSumBudget?upSeniorId='+data.upSeniorId+'&taskId='+data.taskId);
// }
// // 结算 统计
// export function investStat(data) {
//     console.log("结算")
//     console.log(data)
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/investStat?taskId='+data.taskId + "&upSeniorId=" + data.upSeniorId);
// }
//学校建设任务与进度
export function schoolTaskAndProcess(data) {
    return post('/api/ss/api/v1/school/schoolTaskAndProcess?upSeniorId='+data.upSeniorId+'&taskId='+data.taskId);
}
//学校项目总预算 和 学校项目2020年度预算
export function itemSumBudget(data) {
    return post('/api/ss/api/v1/school/itemSumBudget?upSeniorId='+data.upSeniorId+'&taskId='+data.taskId);
}
// 结算 统计
export function investStat(data) {
    console.log("结算")
    console.log(data)
    return post('/api/ss/api/v1/school/investStat?taskId='+data.taskId + "&upSeniorId=" + data.upSeniorId);
}
// 新增/修改指标修改申请
export function insertOrUpdateIndicatorUpdateApply(data) {
    return post('/api/dh/api/v1/dh/schoolDoubleHigh/insertOrUpdateIndicatorUpdateApply', data);
}
// // 查询申请说明
// export function queryIndicatorUpdateApply(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/queryIndicatorUpdateApply?indicatorId='+data.indicatorId);
// }
// //项目支出绩效目标-目录树
// export function indicatorAllLevelCatalogTree(data) {
//     return post('/api/dh/api/v1/dh/schoolDoubleHigh/indicatorAllLevelCatalogTree?processCode='+data.processCode+'&reqType='+data.reqType+'&taskId='+data.taskId);
// }
// 查询申请说明
export function queryIndicatorUpdateApply(data) {
    return post('/api/ss/api/v1/school/queryIndicatorUpdateApply?indicatorId='+data.indicatorId);
}
//项目支出绩效目标-目录树
export function indicatorAllLevelCatalogTree(data) {
    return post('/api/ss/api/v1/school/indicatorAllLevelCatalogTree?processCode='+data.processCode+'&reqType='+data.reqType+'&taskId='+data.taskId);
}

// //查询专业群
// export function getSpecialtyGroup(data) {
//     return get('/api/dh/api/v1/dh/schoolDoubleHigh/getSpecialtyGroup', data);
// }
//查询专业群
export function getSpecialtyGroup(data) {
    return get("/api/ss/api/v1/school/getSpecialtyGroup?specialtyType=" + data.specialtyType + "&taskId=" + data.taskId);
}

// //根据任务书id查询联系人信息
// export function queryContact(data) {
//     return get("/api/dh/api/v1/dh/schoolDoubleHigh/queryContact", data);
// }
//根据任务书id查询联系人信息
export function queryContact(data) {
    return get("/api/ss/api/v1/school/queryContact", data);
}
//workflow初始化
export function workFlowInit(data) {
    return post('/api/ss/api/v1/school/workFlowInit', data);
}
//学校项目与任务同步
export function projectAndTaskSyn(data) {
    return post('/api/ss/api/v1/school/projectAndTaskSyn', data);
}
//学校用户信息同步
export function schoolInfoSyn(data) {
    return post('/api/ss/api/v1/school/schoolInfoSyn', data);
}
//学校建设绩效与指标同步
export function meritAndIndicatorSyn(data) {
    return post('/api/ss/api/v1/school/meritAndIndicatorSyn', data);
}
//预算同步
export function investSyn(data) {
    return post('/api/ss/api/v1/school/investSyn', data);
}
// //获取双高所需所有字典信息
// export function getDhAllCommonDic() {
//     return get("/api/dh/api/v1/dh/schoolDoubleHigh/getDhAllCommonDic");
// }
//获取双高所需所有字典信息
export function getDhAllCommonDic() {
    return get("/api/ss/api/v1/school/getDhAllCommonDic");
}
//根据ID查询学校概况
// export function getSchoolProfile(data) {
//     return get("/api/dh/api/v1/dh/schoolDoubleHigh/getSchoolProfile",data);
// }
//根据ID查询学校概况
export function getSchoolProfile(data) {
    return get("/api/ss/api/v1/school/getSchoolProfile?schoolId=" + data.schoolId + "&taskId=" + data.taskId);
}
//学校类型查询
export function queryCatgoryById(data) {
    return post('/api/ss/api/v1/school/queryCatgoryById', data);
}

//学校信息查询
export function getSchoolNewInfo(data) {
    return post('/api/ss/api/v1/school/getSchoolNewInfo', data);
}
//任务书/建设任务书查看/下载
export function taskOrCaseDown(data) {
    return post('/api/ss/api/v1/school/taskOrCaseDown', data);
}
//年度绩效查看/下载
export function yearMeritDown(data) {
    return post('/api/ss/api/v1/school/yearMeritDown', data);
}
//数据统计初始化
export function initDefaultChart(data) {
    return post('/api/ss/api/v1/chart/initDefaultChart', data);
}

