// 资金明细管理
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form, Button, Select, Table, DatePicker, Input,Tooltip} from "antd";
import {
    selectExpendTaskPageList,getTaskSelectByProjectId,getExpendTypeSelect
} from '../../../../../api/fund/';
import {Backtrack,} from '../../../../../assets/svg'
import style from './index.module.scss'

import RenderButton from "../../../../../components/renderButton";
import Cookies from "js-cookie";
const {RangePicker } = DatePicker;
@withRouter
class Financialdetailsones extends Component {
    state = {
        columns: [
            {
                title: '单号',
                dataIndex: 'expendCode',
                width:140,
                align:"center",
                key: 'expendCode',

            },
            {
                title: '任务',
                align:"center",
                width:250,
                dataIndex: 'taskName',
                key: 'taskName',
                render: (text,record) => {
                    return (record.taskName&&record.taskName.length>10?
                        <Tooltip  placement="bottomLeft" title={record.taskName} overlayClassName='TitleTooltip'
                        ><span>{record.taskName}</span></Tooltip>:<span>{record.taskName}</span>)
                }
            },
            {
                title: '填报人',
                align:"center",
                width:200,
                dataIndex: 'fillUserName',
                key: 'fillUserName',
            },
            {
                title: '填报时间',
                align:"center",
                width:160,
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '类型  ',
                align:"center",
                width:150,
                dataIndex: 'expendType',
                key: 'expendType',
                render: (key) => <div>
                    {/*1、采购费 2、差旅费 3、培训费 4、其他*/}
                    <span>
                        {key == 1 ?'采购费' : key == 2 ?'差旅费' : key ==3 ? '培训费' : '其他'}
                    </span>
                </div>
            },
            {
                title: '总金额  ',
                align:"center",
                width:160,
                dataIndex: 'expendSum',
                key: 'expendSum',
                render: (text,record) => {
                    return (<div>{<span>{record.expendSum ? record.expendSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '状  态',
                dataIndex: 'approvalStatus',
                width:80,
                align:"center",
                key: 'approvalStatus',
                render: (key) => <div>
                    {/*1、审批中2、已通过3、已拒绝  4、已撤销*/}
                    <span className={key === '1' ?'shenpizhong' : key === '2' ?'yitongguo' : key === '3' ? 'yijujue' : 'yishexiao'}>
                        {key === '1' ?'审批中' : key === '2' ?'已通过' : key === '3' ? '已拒绝' : '已撤销'}
                    </span>
                </div>
            },
            {
                title: '操 作',
                align:"center",
                width:200,
                fixed:"right",
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className={'oper'}>
                        <RenderButton id='SS-01-B02-b_btnEditUser'>
                            <span onClick={() => this.showxq(record)}>详情</span>
                        </RenderButton>

                    </div>
                }
            }],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        projectId: this.props.match.params.id,
        projectName:  this.props.match.params.projectName,
        pageData:'',
        expendTotal:'',
        takeNameList:[],
        expendTypeList: [
            {'label': '采购费', 'value': '1'},
            {'label': '差旅费', 'value': '2'},
            {'label': '培训费', 'value': '3'},
            {'label': '其他', 'value': '4'},

        ],
        statusList: [
            {'statusLabel': '审批中', 'statusValue': '1'},
            {'statusLabel': '已通过', 'statusValue': '2'},
            {'statusLabel': '已拒绝', 'statusValue': '3'},
            {'statusLabel': '已撤销', 'statusValue': '4'},
        ],
        taskId:'',
        expendType:'',
        startDate:'',
        endDate:'',
        approvalStatus:'',
        datepickerInit:[],

    }
    componentDidMount() {
        this.selectExpendTaskPageList()
        this.getTaskSelectByProjectId()

    }
    //查询任务资金列表
    selectExpendTaskPageList = async () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            projectId:this.state.projectId,
            taskId:this.state.taskId,
            startDate:this.state.startDate,
            endDate:this.state.endDate,
            expendType:this.state.expendType,
            approvalStatus:this.state.approvalStatus,
        }
        await selectExpendTaskPageList(data).then(res => {
            this.setState({
                pageData: res.pageData.data,
                expendTotal: res.expendTotal,
                total: res.pageData.total,
            })

        })

    }

    //查询按钮
    selectlabel = () => {
        this.state.pageNum=1
        this.selectExpendTaskPageList()

    }
    handleReset=()=>{
        this.props.form.resetFields();
        this.setState({
            startDate:'',
            endDate:'',
            datepickerInit:[],
            taskId:'',
            expendType:'',
            approvalStatus:'',
        })

    }
    //任务下拉
    getTaskSelectByProjectId= async () => {
        let data = {
            projectId:this.state.projectId,
        }
        await getTaskSelectByProjectId(data).then(res => {
            this.setState({
                takeNameList:res,
            })
        })

    }

    //查看详情
    showxq = (record)=>{
        // if(1==1){
        //     //待审批详情页
        //     this.props.history.push({
        //         pathname: '/main/doublehigh/detailspendingapproval',
        //     })
        // }else{
        //     //已审批详情页
        //     this.props.history.push({
        //         pathname: '/main/doublehigh/approvedDetails',
        //     })
        // }
        // 查看详情

            this.props.history.push({
                pathname: '/main/doublehigh/capitalexpenditurereview/'+record.id,
            })
        Cookies.set('jumpSource', {'expendDetail':'invest'});
    }
    //返回上一页
    goBack = () =>{
        this.props.history.push({
            pathname: '/main/doublehigh/fundsmanagement',
            returnFlag:'2',
        })
    }
    changePage = (paqination) => {
        this.setState({
            pageNum: paqination.current,
            pageSize: paqination.pageSize,
        }, () => {
            //列表方法
            this.selectExpendTaskPageList()
        })
    }

    onChangeTaskName = (value) => {
        this.setState({taskId: value});
    }
    onChangeExpendType = (value) => {
        this.setState({expendType: value});
    }

    onChangeType = (value) => {
        this.setState({approvalStatus: value});
    }

    //填报日期
    dateOnChangeOfTianbao = (value, dateString) => {
        if (dateString[0]===''){
            this.state.startDate = dateString[0]
        }
        else {
            this.state.startDate = dateString[0]+' 00:00:00'
        }
        if (dateString[1]===''){
            this.state.endDate = dateString[1]
        }
        else {
            this.state.endDate = dateString[1]+' 23:59:59'
        }
        this.setState({datepickerInit:value})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const{columns,pageData,pageSize,total,pageNum,expendTotal,takeNameList,expendTypeList,statusList,datepickerInit,projectName} = this.state;
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
            // onShowSizeChange: this.showSizeChange
        }
        return (
            <div className={style.doubleteam}>
                <div className={'financial_details_box'}>
                    <div className={'backtrackleft'} onClick={()=>this.goBack()}>
                        <Backtrack/>返回
                    </div>
                    {projectName && projectName.length > 50 ? <Tooltip title={projectName}><div className={'toptitle'}>{projectName.substring(0,50)}</div></Tooltip> : <div className={'toptitle'}>{projectName}</div>}

                    <div className={'financial_details_input'}>
                        <Form>
                            <Form.Item className={'span_riqi'}>
                                <label>填报日期</label>
                                {getFieldDecorator('startDate', {
                                })(<RangePicker
                                    value={datepickerInit}
                                    style={{width: 230}}
                                    disabledDate={this.disabledStartDate}
                                    onChange={this.dateOnChangeOfTianbao}
                                    onOpenChange={this.handleStartOpenChange}
                                    className={'time'}
                                    getCalendarContainer={triggerNode => triggerNode.parentNode}
                                />)}
                            </Form.Item>
                            <Form.Item className={'span_riqi'}>
                                <label>任务</label>
                                 {getFieldDecorator('taskName', {
                                })(<Select className={'select_in'}
                                           style={{width: 230}}
                                           placeholder=" 全部"
                                           allowClear={true}
                                           onChange={this.onChangeTaskName}
                                >{
                                    takeNameList&&takeNameList.map(item =>
                                         <Select.Option
                                             value={item.id}
                                             title={item.taskName}>{item.taskName}</Select.Option>
                                     )
                                 }</Select>)}
                            </Form.Item>
                            <Form.Item className={'span_riqi'}>
                                <label>类型</label>
                                {getFieldDecorator('label', {
                                })(
                                    // <Select defaultValue="" style={{width: 230}} value={this.state.roleType}
                                    //         onChange={this.onChangeExpendType}>
                                    //     <Option value="">全部</Option>
                                    //     {expendTypeList ? expendTypeList.map((item, index) => {
                                    //         return <Option key={item.value} value={item.label}>{item.roleTypeName}</Option>
                                    //     }) : ""}
                                    // </Select>

                                    <Select className={'select_in'}
                                           style={{width: 230}}
                                           allowClear={true}
                                            placeholder={'全部'}
                                           onChange={this.onChangeExpendType}
                                ><Select.Option value=''>全部</Select.Option>
                                    {
                                    expendTypeList&&expendTypeList.map(item =>
                                        <Select.Option
                                            value={item.value}>{item.label}</Select.Option>
                                    )
                                }</Select>
                                )}
                            </Form.Item>
                            <Form.Item className={'span_riqi'}>
                                <label>状态</label>
                                {getFieldDecorator('statusLabel', {
                                })( <Select className={'select_in'}
                                            style={{width: 230}}
                                            placeholder="全部 "
                                            allowClear={true}
                                            onChange={this.onChangeType}
                                >
                                    {
                                        statusList&&statusList.map(item =>
                                            <Select.Option
                                                value={item.statusValue} >{item.statusLabel}</Select.Option>
                                        )
                                    }
                                </Select>)}
                            </Form.Item>
                        </Form>

                    </div>
                    <div className={'financial_button'}>
                        <span className={'span_buttom'}>
                             <Button className={'button_resize'} onClick={() => this.handleReset()}>重 置</Button>
                             <Button className={'button_chaxun'} onClick={() => this.selectlabel()} >查 询</Button>
                        </span>
                    </div>
                </div>
                <div className={'financial_list_box'}>
                    <div className={'financial_list_button'}>
                        {/*<div className={'button_span_zhichu'} onClick={()=>this.zhichutb()}>*/}
                        {/*    <span>支出填报</span>*/}
                        {/*</div>*/}
                        <div className={'span_list'}>
                            <span>共找到{total}条数据，合计金额为</span>
                            <i>{expendTotal?expendTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):expendTotal} 元</i>
                        </div>
                    </div>

                    <div  className={'table_t'}>
                        <Table cellspacing={20} columns={columns}
                               className={'tableUserStyle'}
                               dataSource={pageData}
                               onChange={this.changePage}
                                pagination={pagination}
                               rowKey={(r, i) => (r.id)}
                        />
                    </div>


                </div>
            </div>
        );
    }
}

Financialdetailsones = Form.create()(Financialdetailsones);
export default Financialdetailsones;
