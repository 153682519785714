//概况-详情
import React, {Component} from "react";
import {Button, Dropdown, Form, Input, Menu, Modal, Progress, Steps, Table, TreeSelect,} from "antd";
import {withRouter} from "react-router";
import { Readsign, Redcheck, Goup} from '../../../../../assets/svg';
import style from './index.module.scss';
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import * as echarts from "echarts";
import {surveyProjectQuery, surveyMeritQuery, surveyExpendQuery,queryDefaultIconId,queryConfitionValues,editChartConfition,editChartConfitionValues,clearChartFilterValues} from "../../../../../api/chart";
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import ScreeningCondition from '../../newReportFile/screeningCondition' //筛选条件
import Cookies from "js-cookie";


@withRouter
class Editconstructionscheduleview extends Component {
    state = {
        titleDivLD: '建设任务进度',
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '建设任务名称',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '层面',
                align: "center",
                width: 100,
                dataIndex: 'projectType',
                key: 'projectType',
                render: (text) => {
                    return (<div>{this.state.queryCatgory === 1 ? this.state.projectTypes.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    }) : this.state.projectTypess.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    })}</div>)
                }

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'projectStatus',
                key: 'projectStatus',
                render: (text) => {
                    return (<div>{this.state.status.map((item, index) => {
                        if (text == item.value) {
                            return <div>
                                {item.value === 1 ?
                                    <span key={index}>{item.label}</span> : item.value === 2 ?
                                        <span key={index}>{item.label}</span> : item.value === 3 ?
                                            <span key={index}>{item.label}</span> : item.value === 4 ?
                                                <span key={index}>{item.label}</span> : ''}
                            </div>
                        }
                    })}</div>)
                }

            },
            {
                title: '进度',
                align: "center",
                width: 200,
                dataIndex: 'completionRate',
                key: 'completionRate',
                render: (text) => {
                    return <span >{text}%</span>}
            },


        ],
        columns2: [
            {
                title: '绩效名称',
                dataIndex: 'meritName',
                width: 200,
                align: "center",
                key: 'meritName',


            },
            {
                title: '层面',
                align: "center",
                width: 100,
                dataIndex: 'meritType',
                key: 'meritType',
                render: (text) => {
                    return (<div>{this.state.queryCatgory === 1 ? this.state.projectTypes.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    }) : this.state.projectTypess.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    })}</div>)
                }

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'meritStatus',
                key: 'meritStatus',
                render: (text) => {
                    return <div>
                        {text=== '1' ?
                            <span >已完成</span>
                            :    <span >未完成</span> }
                    </div>
                }


            },
            {
                title: '进度',
                align: "center",
                width: 200,
                dataIndex: 'completeRate',
                key: 'completeRate',
                render: (text) => {
                    return <div>{text?<span >{text}%</span>:<span >--</span>}</div>}

            },


        ],
        columns3: [
            {
                title: '建设任务名称',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '层面',
                align: "center",
                width: 100,
                dataIndex: 'projectType',
                key: 'projectType',
                render: (text) => {
                    return (<div>{this.state.queryCatgory === 1 ? this.state.projectTypes.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    }) : this.state.projectTypess.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    })}</div>)
                }

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'projectStatus',
                key: 'projectStatus',
                render: (text) => {
                    return (<div>{this.state.status.map((item, index) => {
                        if (text == item.value) {
                            return <div>
                                {item.value === 1 ?
                                    <span key={index}>{item.label}</span> : item.value === 2 ?
                                        <span key={index}>{item.label}</span> : item.value === 3 ?
                                            <span key={index}>{item.label}</span> : item.value === 4 ?
                                                <span key={index}>{item.label}</span> : ''}
                            </div>
                        }
                    })}</div>)
                }


            },
            {
                title: '资金使用额度',
                align: "center",
                width: 200,
                dataIndex: 'expendSum',
                key: 'expendSum',
                render: (text,record) => {
                    return (<div>{<span>{record.expendSum ? parseFloat(record.expendSum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                },
            }


        ],
        projectTypes: [
            {'label': '学校', 'value': 1},
            {'label': '专业群一', 'value': 2},
            {'label': '专业群二', 'value': 3},
            {'label': ' 其他', 'value': 4}

        ],
        projectTypess: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群', 'value': '2'},
            {'label': ' 其他', 'value': '4'}

        ],
        status: [
            {'label': '已启动', 'value': 1},
            {'label': '已逾期', 'value': 2},
            {'label': '已挂起', 'value': 3},
            {'label': '已归档', 'value': 4}
        ],
        isreportEditor: false,
        surveyProjectQuery: [],
        surveyProjectQueryList: [],
        surveyMeritQuery: [],
        surveyMeritQueryList: [],
        surveyExpendQuery: [],
        surveyExpendQueryList: [],
        month: [],
        queryCatgory:0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        chartMeritId:0,
        chartMeritType:2,
        chartProjectId:0,
        chartProjectType:1,
        chartExpendId:0,
        chartExpendType:3,
        allConditionList:[],
        addConditionList:[],
        noAddConditionList:[],
        allConfitionValues:[],
        clearCondition:false,//清除筛选
        switchType:1,
        defaultType:1,
        //筛选
        useChildFlag:false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey:0,//screening子组件控制加载
        switchNumber:1,//概览当前选中样式区分
    }

    componentDidMount() {
        this.queryCatgoryById()
        this.surveyMeritQuery()
        this.surveyExpendQuery()
        this.surveyProjectQuery()
    }



    preview = ()=>{
        if (this.state.switchType==1){
            this.state.defaultType=2
            this.surveyProjectQuery()
        }
        if (this.state.switchType==2){
            //刷新页面
            this.state.defaultType=2
            this.surveyMeritQuery()
        }
        if (this.state.switchType==3){
            //刷新页面
            this.state.defaultType=2
            this.surveyExpendQuery()
        }

    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    //概况图表查建设任务进度
    surveyProjectQuery = async () => {
            let dataType = {
                schoolId: this.state.schoolId,
                chartType: this.state.chartProjectType
            };
            await queryDefaultIconId(dataType).then(e => {
                this.setState({
                    chartProjectId:e.id
                })
            }).then( s=> {
                let datas = this.editCondition.getConditionValueChange()
                let data = {
                    code:1,
                    pageNum: this.state.pageNum,
                    pageSize: this.state.pageSize,
                    screenType:datas,
                    defaultType:this.state.defaultType,
                    chartId: this.state.chartProjectId,
                    chartType:this.state.chartProjectType,
                    schoolId:this.state.schoolId
                }
                if (this.state.defaultType==1){
                     surveyProjectQuery(data).then(e => {
                        this.setState({
                            surveyProjectQuery: e.surveyVo,
                            surveyProjectQueryList: e.pageData.data,
                            total:e.pageData.total,
                            useChildFlag:true,
                            screeningKey:this.state.screeningKey+1
                        })
                    })
                }else {
                    surveyProjectQuery(data).then(e => {
                        this.setState({
                            surveyProjectQuery: e.surveyVo,
                            surveyProjectQueryList: e.pageData.data,
                            total:e.pageData.total,
                        })
                    })
                }

            })

    }
    //概况图表绩效进度
    surveyMeritQuery = async () => {
        let dataType = {
            schoolId: this.state.schoolId,
            chartType: this.state.chartMeritType
        };
        await queryDefaultIconId(dataType).then(e => {
            this.setState({
                chartMeritId:e.id
            })
        }).then( s=> {
            let datas = this.editCondition.getConditionValueChange()
            let data = {
                code:1,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                screenType:datas,
                defaultType:this.state.defaultType,
                chartId: this.state.chartMeritId,
                chartType:this.state.chartMeritType,
                schoolId:this.state.schoolId,
            }
            if (this.state.defaultType==1){
                surveyMeritQuery(data).then(e => {
                    this.setState({
                        surveyMeritQuery: e.surveyVo,
                        surveyMeritQueryList: e.pageData.data,
                        total:e.pageData.total,
                        useChildFlag:true,//首次进入，子组件不调用接口，赋值后再调用
                        screeningKey:this.state.screeningKey+1,//screening子组件控制加载
                    })
                })
            }else {
                surveyMeritQuery(data).then(e => {
                    this.setState({
                        surveyMeritQuery: e.surveyVo,
                        surveyMeritQueryList: e.pageData.data,
                        total:e.pageData.total,
                    })
                })
            }
        })


    }
    //概况图资金使用情况
    surveyExpendQuery = async () => {
        let dataType = {
            schoolId: this.state.schoolId,
            chartType: this.state.chartExpendType
        };
        await queryDefaultIconId(dataType).then(e => {
            this.setState({
                chartExpendId:e.id
            })
        }).then( s=> {
            let datas = this.editCondition.getConditionValueChange()
            let data = {
                code:1,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                screenType:datas,
                defaultType:this.state.defaultType,
                chartId: this.state.chartExpendId,
                chartType:this.state.chartExpendType,
                schoolId:this.state.schoolId,
            }
            if (this.state.defaultType==1){
                surveyExpendQuery(data).then(e => {
                    this.setState({
                        surveyExpendQuery: e.surveyVo,
                        surveyExpendQueryList: e.pageData.data,
                        total:e.pageData.total,
                        useChildFlag:true,//首次进入，子组件不调用接口，赋值后再调用
                        screeningKey:this.state.screeningKey+1,//screening子组件控制加载
                    }, () => {
                        this.fundUsageLimit()
                    })
                })
            }else {
                surveyExpendQuery(data).then(e => {
                    this.setState({
                        surveyExpendQuery: e.surveyVo,
                        surveyExpendQueryList: e.pageData.data,
                        total:e.pageData.total,
                    }, () => {
                        this.fundUsageLimit()
                    })
                })
            }

        })

    }
    changeTable = (titleDivLD,number) => {
        this.setState({
            titleDivLD: titleDivLD,
            switchNumber:number
        },()=>{
            if (this.state.switchType!=1) {
                if (titleDivLD === "建设任务进度") {
                    this.state.pageNum = 1
                    this.state.switchType = 1
                    this.surveyProjectQuery()
                }
            }
            if (this.state.switchType!=2) {
                if (titleDivLD === "绩效完成进度") {
                    this.state.pageNum = 1
                    this.state.switchType = 2
                    this.surveyMeritQuery()
                }
            }
            if (this.state.switchType!=3){
            if (titleDivLD==="资金使用额度"){
                this.state.pageNum=1
                this.state.switchType=3
                this.surveyExpendQuery()
            }
            }
        })
    }
    // 编辑
    handLook = () => {
        this.setState({
            isreportEditor: true,
        })
    }
    fundUsageLimit = () => {
        this.setState({month: []})
        let date = new Date();
        let month1 = date.getMonth() + 1;
        let month2 = date.getMonth();
        let month3 = date.getMonth() - 1;
        let month4 = date.getMonth() - 2;
        let month5 = date.getMonth() - 3;
        this.state.month.push(month5 + '月')
        this.state.month.push(month4 + '月')
        this.state.month.push(month3 + '月')
        this.state.month.push(month2 + '月')
        this.state.month.push(month1 + '月')
        let data = [this.state.surveyExpendQuery.fourUseQuota,this.state.surveyExpendQuery.threeUseQuota, this.state.surveyExpendQuery.twoUseQuota,this.state.surveyExpendQuery.oneUseQuota,
            this.state.surveyExpendQuery.currentMonth]
        let data1 = [this.state.surveyExpendQuery.fourUseQuota,this.state.surveyExpendQuery.threeUseQuota, this.state.surveyExpendQuery.twoUseQuota,this.state.surveyExpendQuery.oneUseQuota,
            this.state.surveyExpendQuery.currentMonth]
        let showBackground = false
        if(!data1.some(item=>item !== 0)){
            showBackground = true
        }
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('fundUsageLimit')) {
            let myChart = echarts.init(document.getElementById('fundUsageLimit'));
            // 绘制图表
            let option = {
                color: ["#3E78ED"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00","")
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: this.state.month,
                    show: true,
                    position: 'bottom',
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    showBackground: showBackground,
                    itemStyle: {
                        normal: {
                            color: function (farams) {
                                if (data[farams.dataIndex] > 0) {
                                    return '#3E78ED'
                                } else {
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum.current,
            pageSize: pageNum.pageSize
        }, () => {
            //列表方法
            this.surveyProjectQuery()
        })
    }
    changePage1 = (pageNum) => {
        this.setState({
            pageNum: pageNum.current,
            pageSize: pageNum.pageSize
        }, () => {
            //列表方法
            this.surveyMeritQuery()
        })
    }
    changePage2 = (pageNum) => {
        this.setState({
            pageNum: pageNum.current,
            pageSize: pageNum.pageSize
        }, () => {
            //列表方法
            this.surveyExpendQuery()
        })
    }

    //————————筛选操作——————————
    //编辑图表筛选条件api
    editChartConfition  =  async (data) => {
        await editChartConfition(data)
    }
    //编辑图表筛选条件的值api
    editChartConfitionValues =  async (data) => {
        await editChartConfitionValues(data).then(res=>{})
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        this.state.defaultType=1
        if (this.state.switchType==1){
            await clearChartFilterValues({chartId:this.state.chartProjectId})
            this.surveyProjectQuery()
        }
        if (this.state.switchType==2){
            await clearChartFilterValues({chartId:this.state.chartMeritId})
            this.surveyMeritQuery()
        }
        if (this.state.switchType==3){
            await clearChartFilterValues({chartId:this.state.chartExpendId})
            this.surveyExpendQuery()
        }

    }
    //刷新数据
    refreshPageData = ()=>{
        if (this.state.switchType==1){
            this.surveyProjectQuery()
        }
        if (this.state.switchType==2){
            //刷新页面
            this.surveyMeritQuery()
        }
        if (this.state.switchType==3){
            //刷新页面
            this.surveyExpendQuery()
        }
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res=>{
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension:'1'},()=>{
                this.refreshPageData()
            })

        })
    }
    //按钮保存(编辑筛选条件)
    saveOk = ()=>{
        let data = this.editCondition.getConditionValueChange()
        if (this.state.switchType==1){
            this.editChartConfitionValues({chartId:this.state.chartProjectId,conditionValuesQo:data}).then(res=>{

                this.mesSuccess("保存成功")
                //刷新页面
                this.surveyProjectQuery()
            })

        }
        if (this.state.switchType==2){
            this.editChartConfitionValues({chartId:this.state.chartMeritId,conditionValuesQo:data}).then(res=>{
                this.mesSuccess("保存成功")
                //刷新页面
                this.surveyMeritQuery()
            })

        }
        if (this.state.switchType==3){
            this.editChartConfitionValues({chartId:this.state.chartExpendId,conditionValuesQo:data}).then(res=>{
                this.mesSuccess("保存成功")
                //刷新页面
                this.surveyExpendQuery()
            })

        }


    }
    //编辑筛选条件
    editConditionOk = ()=>{
        if (this.state.switchType==1){
            if(!this.state.chartProjectId){
                this.mesWarning('请先保存图表')
                return
            }
            let conditions = this.addCondition.getCheckValues()
            let data = []
            for (let i = 0; i < conditions.length; i++) {
                data.push({conditionId:conditions[i]})
            }
            this.editChartConfition({chartId:this.state.chartProjectId,conditionValuesQo:data}).then(res=>{
                this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                    this.mesSuccess("操作成功")
                    this.refreshPageData()
                })
            })
        }
        if (this.state.switchType==2){
            if(!this.state.chartMeritId){
                this.mesWarning('请先保存图表')
                return
            }
            let conditions = this.addCondition.getCheckValues()
            let data = []
            for (let i = 0; i < conditions.length; i++) {
                data.push({conditionId:conditions[i]})
            }
            this.editChartConfition({chartId:this.state.chartMeritId,conditionValuesQo:data}).then(res=>{
                this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                    this.mesSuccess("操作成功")
                    this.refreshPageData()
                })
            })
        }
        if (this.state.switchType==3){
            if(!this.state.chartExpendId){
                this.mesWarning('请先保存图表')
                return
            }
            let conditions = this.addCondition.getCheckValues()
            let data = []
            for (let i = 0; i < conditions.length; i++) {
                data.push({conditionId:conditions[i]})
            }
            this.editChartConfition({chartId:this.state.chartExpendId,conditionValuesQo:data}).then(res=>{
                this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                    this.mesSuccess("操作成功")
                    this.refreshPageData()
                })
            })
        }


    }

    //导出任务图表
    exportExcel= ()=>{
        if (this.state.switchType==1){
            window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?" +
                "chartId="+this.state.chartProjectId+"&schoolId="+this.state.schoolId+"&chartType="+this.state.chartProjectType+""
        }
        if (this.state.switchType==2){
            window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?" +
                "chartId="+this.state.chartMeritId+"&schoolId="+this.state.schoolId+"&chartType="+this.state.chartMeritType+""
        }
        if (this.state.switchType==3){
            window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?" +
                "chartId="+this.state.chartExpendId+"&schoolId="+this.state.schoolId+"&chartType="+this.state.chartExpendType+""
        }
    }

    render() {
        const {
            columns, pageSize, total, pageNum,
            titleDivLD, pageDataListA, pageDataListB, pageDataListC, isreportEditor, surveyProjectQuery,
            surveyProjectQueryList, surveyMeritQuery, surveyMeritQueryList, surveyExpendQuery, surveyExpendQueryList, columns2, columns3,switchNumber
        } = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.changePage,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.constructionscheduleview}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>概览</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'} onClick={()=>this.exportExcel()}>
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'} onClick={()=>this.saveOk()}>
                            <span>保存</span>
                        </div>
                        <Dropdown overlay={<Menu>
                            <Menu.Item><span>订阅设置</span></Menu.Item>
                        </Menu>}>
                            <div className={'button_topG'}>
                                <span>更多</span>
                            </div>
                        </Dropdown>
                        <div className={'button_topgb'} onClick={() => {
                            this.props.history.push('/main/doublehigh/dataStatisticsShow')
                        }}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <ul className={'top-wrap'}>
                                <li onClick={() => this.changeTable('建设任务进度',1)} className={switchNumber === 1 ? 'active':''}>
                                    <div className={'text'}>建设任务进度</div>
                                    <div className={'percentage'}>{surveyProjectQuery.projectProgress}%</div>
                                    <Progress percent={surveyProjectQuery.projectProgress} className={'progress'} strokeWidth={'10px'}
                                              strokeColor={'#FF7214'} showInfo={false}/>
                                    <div className={'texttwo'}>
                                        <span>近30日增长率：{surveyProjectQuery.growthRate}%</span>
                                        <Goup/>
                                    </div>
                                </li>
                                <li onClick={() => this.changeTable('绩效完成进度',2)} className={switchNumber === 2 ? 'active':''}>
                                    <div className={'text'}>绩效完成进度</div>
                                    <div className={'percentage'}>{surveyMeritQuery.meritProgress}%</div>
                                    <Progress percent={surveyMeritQuery.meritProgress} className={'progress'} strokeWidth={'10px'}
                                              strokeColor={'#33C6C3'} showInfo={false}/>
                                    <div className={'texttwo'}>
                                        <span>平均完成度：{surveyMeritQuery.meritAverageProgress}%</span>
                                    </div>
                                </li>
                                <li onClick={() => this.changeTable('资金使用额度',3)} className={switchNumber === 3 ? 'active':''}>
                                    <div className={'text'}>资金使用额度</div>
                                    <div className={'percentage'}>¥{surveyExpendQuery.expendUseQuota?surveyExpendQuery.expendUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0}元</div>
                                    <div id={'fundUsageLimit'} className={'echarts'}
                                         style={{width: '100%', height: '40px', marginTop: '10px'}}></div>
                                    <div className={'texttwo'}>
                                        <span>近5月月均使用额：¥{surveyExpendQuery.monthlyUseQuota?surveyExpendQuery.monthlyUseQuota.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00",""):0}</span>
                                    </div>
                                </li>
                            </ul>


                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>{titleDivLD}</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    {
                                        titleDivLD === '建设任务进度' ?
                                            <Table cellspacing={20} columns={columns}
                                                   className={'tableUserStyle'}
                                                   dataSource={surveyProjectQueryList}
                                                   scroll={{x: 700,y:290}}
                                                   onChange={this.changePage}
                                                   pagination={pagination}
                                                   rowKey={0}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            /> : titleDivLD === '绩效完成进度' ?
                                            <Table cellspacing={20} columns={columns2}
                                                   className={'tableUserStyle'}
                                                   dataSource={surveyMeritQueryList}
                                                   scroll={{x: 700,y:290}}
                                                   onChange={this.changePage1}
                                                   pagination={pagination}
                                                   rowKey={1}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            /> :
                                            <Table cellspacing={20} columns={columns3}
                                                   className={'tableUserStyle'}
                                                   dataSource={surveyExpendQueryList}
                                                   scroll={{x: 700,y:290}}
                                                   onChange={this.changePage2}
                                                   pagination={pagination}
                                                   rowKey={2}
                                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                                           <div className="img">
                                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            />
                                    }


                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度：</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>绩效</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>资金</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.screeningKey}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.switchType==1?this.state.chartProjectId:this.state.switchType==2?this.state.chartMeritId:this.state.chartExpendId}
                                chartType={this.state.switchType==1?this.state.chartProjectType:this.state.switchType==2?this.state.chartMeritType:this.state.chartExpendType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                editCondition ={(child)=>this.editCondition=child}
                            />
                            <p className={'shaixuank'} onClick={this.handLook}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.switchType==1?this.state.chartProjectType:this.state.switchType==2?this.state.chartMeritType:this.state.chartExpendType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.switchType==1?this.state.chartProjectId:this.state.switchType==2?this.state.chartMeritId:this.state.chartExpendId}
                                chartType={this.state.switchType==1?this.state.chartProjectType:this.state.switchType==2?this.state.chartMeritType:this.state.chartExpendType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span  onClick={()=>this.clearChartFilterValues()} >清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span onClick={()=>this.preview()} >预览图表</span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

Editconstructionscheduleview = Form.create()(Editconstructionscheduleview);
export default Editconstructionscheduleview;
