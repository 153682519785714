import { post } from '../../until/axios_instans';

//校级组织管理

//组织列表
export function getOrganizationTreeList(data){
    return post('/api/ss/api/v1/sysorganize/getOrganizationTreeList',data);
}

//查询组织列表-下拉专用
export function orgTreeNodeList(data){
    return post('/api/ss/api/v1/sysorganize/orgTreeNodeList',data);
}

//组织分类
export function getOrganizationTypeList(data){
    return post('/api/ss/api/v1/sysorganize/getOrganizationTypeList',data);
}

//组织删除
export function deleteOrganization(data){
    return post('/api/ss/api/v1/sysorganize/deleteOrganization',data);
}

//组织信息byId
export function getOrganizationById(data){
    return post('/api/ss/api/v1/sysorganize/getOrganizationById',data);
}
//组织新增
export function addOrganization(data){
    return post('/api/ss/api/v1/sysorganize/addOrganization',data);
}
//组织编辑
export function editOrganization(data){
    return post('/api/ss/api/v1/sysorganize/editOrganization',data);
}

//用户
export function getUserList(){
    return post('/api/ss/api/v1/sysorganize/userList');
}





