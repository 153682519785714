// 资金管理
import React, {Component} from "react";
import {Form, Table, Tabs, Progress, Tooltip} from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss'
import {
    selectExpendProjectPageList,

} from '../../../../api/fund/';

import {queryCatgoryById} from "../../../../api/synchrodatment";
import RenderButton from "../../../../components/renderButton";
import Cookies from "js-cookie";

const {TabPane} = Tabs;

@withRouter
class Capitalcost extends Component {
    state = {
        activeKey: 1,
        columns: [
            {
                title: '建设任务',
                dataIndex: 'projectName',
                width: 140,
                align: "center",
                key: 'projectName',
                render: (text,record) => {
                    return (record.projectName&&record.projectName.length>10?
                        <Tooltip  placement="bottomLeft" title={record.projectName} overlayClassName='TitleTooltip'
                        ><span>{record.projectName}</span></Tooltip>:<span>{record.projectName}</span>)
                }

            },
            {
                title: '预算金额(元)',
                align: "center",
                width: 250,
                dataIndex: 'investAmount',
                key: 'investAmount',
                render: (text,record) => {
                    return (<div>{<span>{record.investAmount ? record.investAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '实际使用金额(元)',
                align: "center",
                width: 200,
                dataIndex: 'expendAmount',
                key: 'expendAmount',
                render: (text,record) => {
                    return (<div>{<span>{record.expendAmount ? record.expendAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '剩余金额(元) ',
                align: "center",
                width: 160,
                dataIndex: 'remainingAmount',
                key: 'remainingAmount',
                render: (text,record) => {
                    return (<div>{<span>{record.remainingAmount ? record.remainingAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '执行率',
                align: "center",
                width: 150,
                dataIndex: 'implementationRate',
                key: 'implementationRate',
                render: (text, record) => {
                    return <div className={'progress_bar'}>
                        <RenderButton id='SS-01-B02-b_btnEditUser'>

                            <Progress className={'progress_bar_bar'}
                                      status="active"
                                      strokeColor={this.getProgessColor(record.implementationRate)}
                                      percent={record.implementationRate?record.implementationRate.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0}
                            />

                        </RenderButton>

                    </div>
                }
            },

            {
                title: '操 作',
                align: "center",
                width: 100,
                fixed: "right",
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className={'oper'}>
                        <RenderButton id='SS-01-B02-b_btnEditUser'>
                            <span onClick={() => this.chakan(record.id,record.projectName)}>查看</span>
                        </RenderButton>

                    </div>
                }
            }],
        userList: [
            {
                id: 1,
                remark: '88888',
                humanName: '学校建设',
                phone: '60.000',
                orgName: '60.000',
                position: '60.000',
                roleList: '60.000',

            },

        ],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        pageDataList: [],
        expendAll: "",
        projectType: '',
        year: '',
        assignmentOneList: [
            {
                id: 1,
                name: '学校建设任务'
            },
            {
                id: 2,
                name: '专业群一建设任务'
            },
            {
                id: 3,
                name: '专业群二建设任务'
            },
            {
                id: 4,
                name: '其他特色建设任务'
            },
        ],
        assignmentTwoList: [
            {
                id: 1,
                name: '学校建设任务'
            },
            {
                id: 2,
                name: '专业群建设任务'
            },
            {
                id: 4,
                name: '其他特色建设任务'
            },
        ],
        yearList: [
            {
                id: 2019,
                year: '2019年'
            },
            {
                id: 2020,
                year: '2020年'
            },
            {
                id: 2021,
                year: '2021年'
            },
            {
                id: 2022,
                year: '2022年'
            },
            {
                id: 2023,
                year: '2023年'
            },
        ],
        assignmentName: '学校建设任务',
        assignmenId: 1,
        yearName: '',
        yearId: '',
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        queryCatgory:[],
    }
    getProgessColor = (successProgess) =>{
        console.log(successProgess)
        let color = ''
        if(successProgess < 100){
            color = {from: "rgb(121 202 241)", to: "rgb(92 194 243)",}
        }else if(successProgess > 100){
            console.log('000')
            color = {from: "rgb(147 198 239)", to: "rgb(45 156 247)",}
        }else if(successProgess = 100){
            console.log('1111')
            color = '#27CDA2'
        }
        console.log('color',color)
        return color
    }
    addheji = () =>{
        let hejitr = document.getElementsByClassName('addnewtr')[0]
        let NewElement = document.createElement("tr")
        NewElement.setAttribute('class','addnewrighttr ant-table-row ant-table-row-level-0');
        NewElement.innerHTML = '<td class="ant-table-row-cell-break-word"></td>'
        document.getElementsByClassName('ant-table-tbody')[0].appendChild(hejitr)
        document.getElementsByClassName('ant-table-tbody')[1].appendChild(NewElement)
    }
    componentDidMount() {
        this.queryCatgoryById()
        this.selectExpendProjectPageList().then(res=>{
            this.addheji()
        })
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id:this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory:queryCatgory
        })
    }
    //查看
    chakan = (id,projectName) => {
        this.props.history.push({
            pathname: '/main/doublehigh/doubleteam/'+id+'/'+projectName ,
        })
    }
    //查询列表
    selectExpendProjectPageList = async () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            year: this.state.yearID,
            projectType: this.state.assignmenId,
        }
        await selectExpendProjectPageList(data).then(res => {
            this.setState({
                pageDataList: res.pageData.data,
                expendAll: res.expendAll,
                total: res.pageData.total,
            })
        })


    }

    changePage = (paqination) => {
        this.setState({
            pageNum: paqination.current,
            pageSize: paqination.pageSize,
        }, () => {
            //列表方法
            this.selectExpendProjectPageList().then(res=>{
                if(document.getElementsByClassName('addnewrighttr').length > 0){
                    document.getElementsByClassName('addnewrighttr')[0].remove()
                    this.addheji()
                }
            })
        })
    }
    onChange = (e) =>{
        this.selectExpendProjectPageList().then(res=>{
            if(document.getElementsByClassName('addnewrighttr').length > 0){
                document.getElementsByClassName('addnewrighttr')[0].remove()
                this.addheji()
            }
        })
    }
    // 建设任务层面切换
    changeAssignmen =(id,name,isAll)=>{
        this.setState({
            assignmenId:id,
            assignmentName: name,
            pageNum: 1
        },()=>{
            //列表方法
            this.selectExpendProjectPageList().then(res=>{
                if(document.getElementsByClassName('addnewrighttr').length > 0){
                    document.getElementsByClassName('addnewrighttr')[0].remove()
                    this.addheji()
                }
            })
        })

    }
    // 年份切换
    changeYear =(id,name,isAll)=>{
        if(isAll){
            this.setState({
                yearID:"",
                yearName: "",
                pageNum: 1
            },()=>{
                this.selectExpendProjectPageList().then(res=>{
                    if(document.getElementsByClassName('addnewrighttr').length > 0){
                        document.getElementsByClassName('addnewrighttr')[0].remove()
                        this.addheji()
                    }
                })
            })
        }else {
            this.setState({
                yearID:id,
                yearName: name,
                pageNum: 1
            },()=>{
                this.selectExpendProjectPageList().then(res=>{
                    if(document.getElementsByClassName('addnewrighttr').length > 0){
                        document.getElementsByClassName('addnewrighttr')[0].remove()
                        this.addheji()
                    }
                })
            })
        }

    }
    render() {
        const {columns, pageDataList, pageSize, total, pageNum, userList, queryCatgory,assignmentOneList,assignmentTwoList,assignmenId,assignmentName,yearList,yearName,yearId,expendAll} = this.state;
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        const NewTr = <tr className={'addnewtr'}>
            <td className='ant-table-row-cell-break-word'  style={{textAlign: "center"}}>合计金额</td>
                <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{this.state.expendAll.investAmount?this.state.expendAll.investAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):this.state.expendAll.investAmount}</td>
                <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{this.state.expendAll.expendAmount?this.state.expendAll.expendAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):this.state.expendAll.expendAmount}</td>
                <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>{this.state.expendAll.remainingAmount?this.state.expendAll.remainingAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):this.state.expendAll.remainingAmount}</td>
                <td className='ant-table-row-cell-break-word' style={{textAlign: "center"}}>
                <div className="progress_bar"><RenderButton id="SS-01-B02-b_btnEditUser"><Progress className="progress_bar_bar" status="active" strokeColor={this.getProgessColor(expendAll.remainingAmount)} percent={expendAll.implementationRate} /></RenderButton></div>
            </td>
    <td></td>
        </tr>

        return (
            <div className={style.capitalcost}>
                <div className={'capitalcost_box'}>
                    <div className={'assignmen'}>
                        <div className={'name'}>建设任务层面：</div>
                        <ul className={'assignmenul'}>
                            {
                                queryCatgory===1?assignmentOneList.map((item,index)=>{
                                    return <li onClick={()=>this.changeAssignmen(item.id,item.name,false)} className={this.state.assignmentName === item.name ? "assignmen_active" : ""}>{item.name}</li>
                                }):assignmentTwoList.map((item,index)=>{
                                    return <li onClick={()=>this.changeAssignmen(item.id,item.name,false)} className={this.state.assignmentName === item.name ? "assignmen_active" : ""}>{item.name}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={'year'}>
                        <div className={'name'}>年份：</div>
                        <ul className={'yearul'}>
                            <li onClick={()=>this.changeYear("","",true)} className={this.state.yearName === '' ? "year_active" : ""}>全部</li>
                            {
                                yearList?yearList.map((item,index)=>{
                                    return <li onClick={()=>this.changeYear(item.id,item.year,false)} className={this.state.yearName === item.year ? "year_active" : ""}>{item.year}</li>
                                }):''
                            }
                        </ul>
                    </div>
                    <div className={'table_t'}>
                        <Table cellspacing={20} columns={columns}
                               className={'tableUserStyle'}
                               scroll={{x: 1500, y: 450}}
                               dataSource={pageDataList}
                               onChange={this.changePage}
                               pagination={pagination}
                               rowKey={(r, i) => (r.id)}
                        />
                        {NewTr}
                    </div>


                </div>
            </div>

        );
    }
}

Capitalcost = Form.create()(Capitalcost);
export default Capitalcost;
