import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import "./index.scss";
import Cookies from "js-cookie";

export default class OBreadcrumb extends Component {
    jump = (path) => {
        if(path === ''){
            this.props.history.push('/main/doublehigh/dataStatisticsShow')
        }else if(path === this.props.location.pathname){
            return;
        }else {
            this.props.history.push(path)
        }
    }
    render() {
        const { list=[] } = this.props;
        const list1 = list.slice(-(list.length - 1))
        let  list3 = []
        if (list1[0]) {
            Cookies.set('list2', list1);
            list3 = list1
            let breadcrumb =JSON.parse(Cookies.get('list2'))
        } else {
            // list1 = [{
            //     menuName: '1'
            // }]
            list3 = Cookies.get('list2')&&JSON.parse(Cookies.get('list2'))
        }
        // let listss = [{
        //     menuName: '1'
        // }]
        console.log("list3",list3)
        return (
            <div className={'Breadcrumbcont'} style={{ padding: '16px 30px 16px 30px',background:"#fff" }}>
                { Cookies.get('list2')&&<Breadcrumb separator="/" >
                    {
                        list3.length && list3.map((v, i) => {
                            if (v) {
                                return <Breadcrumb.Item key={i} className={v.color ? 'pointer spanhover' : v.menuUrl ? 'pointer' : 'default'}  onClick={() => this.jump(v.menuUrl)}>
                                    {v.menuName==="默认"?"   ":v.menuName}
                                </Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>}

            </div>
        )
    }
}
