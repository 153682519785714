/*eslint eqeqeq: ["off", "smart"]*/
// 第五页 项目支出绩效目标
import React, {Component} from 'react';
import {Button, Col, Form, Input, Modal, Row} from 'antd';
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';
import {insertOrUpdateIndicatorUpdateApply, queryIndicatorUpdateApply, indicatorAllLevelCatalogTree} from '@/api/synchrodatment/';

const { TextArea } = Input;
class Specialtygroupaddschooltwo extends Component {
    state = {
        allMenu:[],
        taskId:"",
        schoolId:"",
        category:0,
        upSeniorId:"4.5", // 任务流程二级id 取值学校任务2.5/专一3.5/专二4.5
        visible:false,
        textValue:"",
        indicatorId:"",
        isReporting: 1,
        userMark: 0
    };

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            taskId: jsonparam.taskId,
            schoolId: jsonparam.schoolId,
            category: jsonparam.category
        },() =>{
            this.indicatorAllLevelCatalogTree();
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    //左侧树
    async indicatorAllLevelCatalogTree(){
        const {taskId,upSeniorId}=this.state
        const  allMenu= await indicatorAllLevelCatalogTree({processCode:upSeniorId,reqType:2,taskId:taskId})
        this.setState({
            allMenu
        })
    }

    // 预览第6页
    sixthPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId,category:category};
        this.props.history.push({
            pathname: '/localment',
            search: JSON.stringify(param)
        })
    }

    // 新增/修改指标修改申请
    async insertOrUpdateIndicatorUpdateApply(){
        let data = {
            indicatorId: this.state.indicatorId,
            updateApply: this.state.textValue,
            taskId: this.state.taskId,
        }
        await insertOrUpdateIndicatorUpdateApply(data)
    }

    // 查询申请说明
    async queryIndicatorUpdateApply(indicatorId){
        let data = {
            indicatorId: indicatorId
        }
        let updateApplyObj = await queryIndicatorUpdateApply(data);
        this.props.form.setFieldsValue({"constructionObjectives":updateApplyObj.updateApply})
        this.setState({
            textValue: updateApplyObj.updateApply
        })
    }

    handOn = (indicatorId) =>{
        this.queryIndicatorUpdateApply(indicatorId)
        this.setState({
            visible: true,
            indicatorId: indicatorId
        });
    }
    handleCancel =() =>{
        this.setState({
            visible: false,
            textValue: ''
        });
    }
    handleOk = () =>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.insertOrUpdateIndicatorUpdateApply()
                this.indicatorAllLevelCatalogTree();
                this.setState({
                    visible: false,
                    textValue: '',
                    isSelect: 1
                });
            }else {
                return
            }
        })
    }
    //输入框剩余字数联动
    changeChat2 = (e) =>{
        this.setState({textValue:e.target.value})
    }

    render() {
        const {allMenu , taskId, schoolId,category,upSeniorId,visible,textValue,userMark,isReporting,isSelect} = this.state;
        const { getFieldDecorator} = this.props.form;
        const list = allMenu.map((item, i) => (
            <li key={i}>
                <h3>{item.oneLevelName}</h3>
                {item.indicatorCatalogList.map((twoItem, j) => (
                    <div key={j}>
                        {
                            twoItem.twoLevelName?<h4>{twoItem.twoLevelName}</h4>:""
                        }

                        {twoItem.indicatorThreeLevelCatalogList && twoItem.indicatorThreeLevelCatalogList.length > 0 ?twoItem.indicatorThreeLevelCatalogList.map((threeItem, k) => (
                            <Row key={k} style={{marginTop:"10px"}}>
                                <Col span={24} className="col-li">
                                    {
                                        threeItem.threeLevelName?
                                          <div className="span-tit">
                                              <span className="span">{threeItem.threeLevelName}</span>
                                          </div>:""
                                    }
                                    {threeItem.selectIndicatorList&&threeItem.selectIndicatorList.length>0?threeItem.selectIndicatorList.map((fourItem, t) => (
                                        <div className="li-box" key={t}>
                                            <div className="title-h4">
                                                <h5>{fourItem.indicatorName}</h5>
                                                <span>{fourItem.targetValue} {fourItem.indicatorMeasure === '无单位' ? '' : fourItem.indicatorMeasure}</span>
                                                {
                                                    userMark === 2 ?
                                                        (isReporting === 0 ? <Button className="antd-but" onClick={()=> this.handOn(fourItem.id)}>申请修改</Button>
                                                            :<Button className="antd-but antd-disabled" disabled={true}>申请修改</Button> )
                                                        : ''
                                                }

                                            </div>
                                        </div>
                                    )):(
                                        <div className="Empty-content">
                                            <div className="text">
                                                <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                <span>暂无数据</span>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        )):(
                            <div className="Empty-content">
                                <div className="text">
                                    <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </li>
        ));

        return (
            <div className="Comment-SchoolBudget main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>
                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} upSeniorId={upSeniorId} category={category} isSelect={isSelect}/>}
                                </Col>
                                <Col span={16} className="right">
                                    <div className="project-budget">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>15</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <div className="list-ul">
                                            <h2>3-5 专业群二项目支出绩效目标</h2>
                                            <ul>
                                                {list}
                                            </ul>
                                        </div>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" onClick={()=> this.sixthPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
                <Modal
                    wrapClassName='Modal-mainpic-TextArea'
                    title={
                        <div>
                            申请修改
                        </div>
                    }
                    visible={visible}
                    closable={false}
                    centered={true}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({visible: false})}>取 消</Button>
                            <Button onClick={this.handleOk} type='primary'>提交</Button>
                        </div>
                    }>

                    <div className="text-conta">
                        <Form>
                            <Form.Item>
                                <Row>
                                    <Col span={24}>
                                        {getFieldDecorator('constructionObjectives', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `申请内容不能为空`,
                                                },
                                                {
                                                    max:1500,
                                                    message: "申请内容不能超过1500字"
                                                },
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<TextArea rows={3} className="TextArea-pic" onChange={(e)=>this.changeChat2(e)} value={textValue}/>)}
                                        <span className="zs"><span>{textValue&&textValue.length>0 ?textValue.length :0}/1500</span></span>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

Specialtygroupaddschooltwo = Form.create()(Specialtygroupaddschooltwo);
export default Specialtygroupaddschooltwo;

