/*绩效管理*/
import React, {Component} from "react";
import {Button, Cascader, DatePicker, Form, Input, Modal, Select, Table, message} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {meritPageList, meritSave, meritIndicatorInfo,meritOneIndicatorInfo,meritTwoIndicatorInfo,getCommonDic} from '../../api/merit/';
import { getDhAllCommonDic } from '../../api/synchrodatment'
import {queryCatgoryById} from "../../api/synchrodatment";
import {selectUserList} from '../../api/project/';
import RenderButton from "../../components/renderButton";
import Cookies from "js-cookie";
import BulkImport from "../project/components/renwu/bulkImport";
import moment from "moment";

const {Option} = Select;
const {TextArea} = Input;
const {RangePicker} = DatePicker;

@withRouter
class PerformancelistLook extends Component {
    state = {
        roleName: '',
        roleType: '',
        columns: [
            // {
            //     title: '绩效指标名称',
            //     align: "center",
            //     width: 350,
            //     dataIndex: 'meritName',
            //     key: 'meritName',
            //     sorter: true,
            //     render: (text, record) =>
            //         <div className={'meritname'}>
            //             {
            //
            //                 record.parentId == '0'? record.meritName&&record.meritName.length>15?
            //                         <Tooltip placement="bottomLeft" title={record.meritName} overlayClassName='TitleTooltip'> <span className={'meritName'} onClick={() => this.gotoDetails(record)}>{text}</span></Tooltip>
            //                         :<span className={'meritName'} onClick={() => this.gotoDetails(record)}>{text}</span>
            //                     : record.meritName&&record.meritName.length>15?
            //                         <Tooltip placement="bottomLeft" title={record.meritName} overlayClassName='TitleTooltip'><span className={'meritName'} onClick={() => this.gotoDetails(record)}><span className={'ziMeritName'}>子</span>{text}</span></Tooltip>
            //                         :  <span className={'meritName'} onClick={() => this.gotoDetails(record)}><span className={'ziMeritName'}>子</span>{text}</span>
            //
            //             }
            //         </div>
            //     // <span className={'meritName'} onClick={() => this.gotoDetails(record)}>{text}</span>
            //
            // },
            {
                title: '一级指标',
                align: "center",
                width: 120,
                dataIndex: 'oneIndicatorName',
                key: 'oneIndicatorName',
            },
            {
                title: '二级指标',
                align: "center",
                width: 150,
                dataIndex: 'twoIndicatorName',
                key: 'twoIndicatorName',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.twoIndicatorName : "--"} </span>}</div>)
                }

            },
            {
                title: '三级指标',
                dataIndex: 'threeIndicatorName',
                width: 300,
                align: "center",
                key: 'threeIndicatorName',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.threeIndicatorName : "--"} </span>}</div>)
                }

            },
            {
                title: '四级指标',
                dataIndex: 'meritName',
                width: 300,
                align: "center",
                key: 'meritName',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.meritName : "--"} </span>}</div>)
                }

            },
            {
                title: '单位',
                dataIndex: 'indicatorMeasure',
                width: 300,
                align: "center",
                key: 'indicatorMeasure',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.indicatorMeasure : "--"} </span>}</div>)
                }

            },
            {
                title: '目标值',
                dataIndex: 'targetValue',
                width: 300,
                align: "center",
                key: 'targetValue',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.targetValue : "--"} </span>}</div>)
                }
            },
            {
                title: '实现值',
                dataIndex: 'completeValue',
                width: 300,
                align: "center",
                key: 'completeValue',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.completeValue : "--"} </span>}</div>)
                }

            },
            {
                title: '完成度',
                align: "center",
                dataIndex: 'completeRate',
                key: 'completeRate',
                sorter: true,
                width: 100,
                fixed: "right",
                render: (text, record) => {
                    return <div className='oper'>
                        {record.completeRate ? <span>{record.completeRate}%</span> : <span>--</span>}
                    </div>
                }
            },
            {
                title: '提交人',
                align: "center",
                width: 100,
                dataIndex: 'directorName',
                key: 'directorName',
                render: (text, record) => {
                    return (<div>{<span>{text ? record.directorName : "--"} </span>}</div>)
                }

            },
            {
                title: '提交时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                align: 'center',
                width: 220,
                sorter: true,
                render: (text) => {
                    return (<div>{<span>{text ? text: "--"} </span>}</div>)
                }
            },
            {
                title: '关联任务',
                align: "center",
                width: 80,
                dataIndex: 'getTaskMerit',
                key: 'getTaskMerit',

            },
            {
                title: '操作',
                fixed: 'right',
                align: 'center',
                key: 'right',
                width: 110,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={() => this.gotoDetails(record)}>查看</span>
                    </div>
                )
            },

        ],
        userList: [{
            id: 1,
            remark: '88888',
            humanName: '89757',
            phone: '学校建设',
            orgName: '加强项目计划',
            position: '加强项目计划',
            roleList: '加强项目计划',
            isDisable: '加强项目计划',
            remarkk: '李晓霞',

        }],
        meritTypes: [
            {'label': '学校', 'value': 1},
            {'label': '专业群一', 'value': 2},
            {'label': '专业群二', 'value': 3},
            {'label': ' 其他', 'value': 4}

        ],
        meritTypess: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群', 'value': '2'},
            {'label': ' 其他', 'value': '4'}

        ],
        taskId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId,//用户ID
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        isAdmin: JSON.parse(Cookies.get('ssInfo')).userInfo.isAdmin,
        //添加页面数据
        meritList: [],
        pageNum: 1,//当前页数
        pageSize: 20,//每页数据数量
        total: 0,//总条数
        ModalVisible: false,
        indexOptions: [],//指标层级数据
        statusButten: true,
        levelValue: 1,//新增绩效层面当前选择值
        userOptions:[],
        oneIndicatorName:'',
        twoIndicatorName: '',
        threeIndicatorName: '',
        meritType:undefined,
        getTaskMerit:undefined,
        getDhAllCommonDic:[],
        projectId:this.props.projectId?this.props.projectId:'',
        meritOneIndicatorList:[],//一级下拉
        meritTwoIndicatorList:[],//二级下拉
        meritThreeIndicatorList:[],//三级下拉
        meritOneIndicatorId:[],//一级下拉id
        meritTwoIndicatorId:[],//二级下拉id
        condition:'',
        startTime:undefined,
        endTime:undefined,
        endValue:undefined,
        startValue:undefined,
        endOpen: false,
        datepickerInit:[],
        time:[],
        startDate:undefined,
        endDate:undefined,
        bulkImport: false,
        code:1,
        queryCatgory:0,
    }

    componentDidMount() {
        this.meritPageList();
        this.queryCatgoryById();
        Cookies.set('merit', {projectId:this.state.projectId});
    }

    //重置
    handleReset = () => {
        this.props.form.resetFields()
        this.setState({
            meritType:undefined,
            oneIndicatorName:undefined,
            twoIndicatorName: undefined,
            threeIndicatorName:undefined,
            getTaskMerit:undefined,
            meritOneIndicatorId:undefined,
            meritTwoIndicatorId:undefined,
            meritThreeIndicatorId:undefined,
            pageNum:1,
            startTime:undefined,
            endTime:undefined,
            datepickerInit:[],
            startDate:undefined,
            endDate:undefined,

        }, () => {
            this.meritPageList()
        })
    }
    //查询按钮
    selectlabel = () => {
        this.state.pageNum=1
        this.meritPageList()

    }

    //一级下拉
    async meritOneIndicatorInfo() {
        let data = {
            schoolId: this.state.schoolId,
            meritTypeId:this.state.meritType
        };
        let meritOneIndicatorList = await meritOneIndicatorInfo(data)
        this.setState({
            meritOneIndicatorList: meritOneIndicatorList,

        })
    }
    //二级下拉
    async meritTwoIndicatorInfo() {
        let data = {
            id:this.state.meritOneIndicatorId,
            schoolId: this.state.schoolId,
            meritTypeId:this.state.meritType
        };
        let meritTwoIndicatorList = await meritTwoIndicatorInfo(data)
        this.setState({
                meritTwoIndicatorList: meritTwoIndicatorList,
            }
        )
    }

    //三级级下拉
    async meritThreeIndicatorInfo() {
        let data = {
            id:this.state.meritTwoIndicatorId,
            schoolId: this.state.schoolId,
            meritTypeId:this.state.meritType
        };
        let meritThreeIndicatorList = await meritTwoIndicatorInfo(data)
        this.setState({
            meritThreeIndicatorList: meritThreeIndicatorList
        })
    }
    //查询专业群
    async queryCatgoryById() {
        let data={
            id: this.state.schoolId
        };
        //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }

    async meritPageList() {

        let {pageNum, pageSize} = this.state;
        let params={
            projectId:this.state.projectId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolId: this.state.schoolId,
            oneIndicatorName:this.state.oneIndicatorName,
            twoIndicatorName: this.state.twoIndicatorName,
            threeIndicatorName:this.state.threeIndicatorName,
            meritType:this.state.meritType,
            getTaskMerit:this.state.getTaskMerit,
            condition:this.state.condition,
            startTime:this.state.startTime,
            endTime:this.state.endTime
        };
        const page = await meritPageList(params);
        if (page) {
            this.setState({
                meritList: page.data,
                total: page.total
            });
        }
    }

    saveList = () => {
        this.setState(
            {
                ModalVisible: true,
                datepickerInit:[],
                startTime:undefined,
                endTime:undefined,
                startDate:undefined,
                endDate:undefined,
                endValue:undefined,
                startValue:undefined,
                endOpen:false,
            })
        this.selectUserList()
        this.getCommonDic()
        // this.meritIndicatorInfo()

    }

    //负责人下拉
    async selectUserList() {
        let params = {
            schoolId: this.state.schoolId,
        };
        const page = await selectUserList(params);
        if (page) {
            this.setState({
                userInfoList: page,
            });
        }
    }
    //统计单位
    async getCommonDic() {
        let date={
            groupName:'unit',
        }
        const unit = await getCommonDic(date);
        if (unit) {
            this.setState({
                getDhAllCommonDic: unit,
            });
        }
    }

    //指标下拉
    async meritIndicatorInfo() {
        let params = {
            schoolId: this.state.schoolId,
            meritTypeId: this.state.levelValue
        };
        const date = await meritIndicatorInfo(params);
        if (date) {
            this.setState({
                indexOptions: date,
            });
        }
    }

    //分页切换
    // pageChange = (pageNum) => {
    //     this.setState({pageNum}, () => this.meritPageList());
    // };

    //分页条数切换
    // onShowSizeChange = (pageNum, pageSize) => {
    //     this.setState({pageSize: pageSize, pageNum: 1}, () => {
    //         this.meritPageList()
    //     });
    // };
    //选择负责人
    onUserChanges = (e) => {
        let users = this.state.userOptions
        for (let i = 0; i < users.length; i++) {
            if (e === users[i].id) {
                this.props.form.setFieldsValue({userPhone: users[i].phone})
                break;
            }
        }
    }
    //添加绩效
    meritSave = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            //开始时间和结束时间处理
            if (this.state.startValue && !this.state.endValue) {
                this.mesWarning("请填入结束时间")
                return undefined
            }
            if (!this.state.startValue && this.state.endValue) {
                this.mesWarning("请填入开始时间")
                return undefined
            }
            let formValue = this.props.form.getFieldsValue()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let meritObj = {
                        projectId:this.state.projectId,
                        //自定义的
                        parentId: 0,
                        //表单里的
                        meritName: formValue.meritName,
                        directorId: formValue.directorId,
                        meritType: formValue.meritType,
                        oneIndicatorName: this.state.oneIndicatorName,
                        twoIndicatorName: this.state.twoIndicatorName,
                        threeIndicatorName: this.state.threeIndicatorName,
                        threeIndicatorId: this.state.meritThreeIndicatorId,
                        targetValue: formValue.targetValue,
                        indicatorMeasure:formValue.indicatorMeasure,
                        meritDescribe: formValue.meritDescribe,
                        //Cookies 里的
                        schoolId: this.state.schoolId,
                        createrId: this.state.userId,
                        beginTime:this.state.startDate,
                        endTime:this.state.endDate
                    };

                    meritSave(meritObj).then(res => {
                        this.mesSuccess('添加成功');
                        this.props.form.resetFields()
                        this.setState({
                            ModalVisible:false,
                            parentId: '',
                            meritName: '',
                            directorId: '',
                            meritType: '',
                            oneIndicatorName: '',
                            twoIndicatorName: '',
                            threeIndicatorName: '',
                            targetValue: '',
                            indicatorMeasure: '',
                            meritDescribe: '',
                            datepickerInit:[],
                            startTime:undefined,
                            endTime:undefined,
                            startDate:undefined,
                            endDate:undefined,
                        });
                    }).then((res) => {
                        this.meritPageList();
                    })
                }
            })

        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }

    gotoDetails = (record) => {
        this.props.history.push({pathname: '/main/doublehigh/performanceDetailsLook/' + record.id})
    }
    handleCancel = () => {
        this.props.form.resetFields()
        this.setState({
            ModalVisible:false,
            parentId: undefined,
            meritName: undefined,
            directorId: undefined,
            meritType: undefined,
            oneIndicatorName: undefined,
            twoIndicatorName: undefined,
            threeIndicatorName: undefined,
            targetValue: undefined,
            indicatorMeasure: undefined,
            meritDescribe: undefined,
        })
    }

    // Cascader显示最后一级
    displayRender = (label) => {
        if (label.length==1){
            this.state.oneIndicatorName=label[label.length - 1]
            this.state.twoIndicatorName=undefined
            this.state.threeIndicatorName=undefined
        }
        if (label.length==2){
            this.state.oneIndicatorName=label[label.length - 2]
            this.state.twoIndicatorName=label[label.length - 1]
            this.state.threeIndicatorName=undefined
        }
        if (label.length==3){
            this.state.oneIndicatorName=label[label.length - 3]
            this.state.twoIndicatorName=label[label.length - 2]
            this.state.threeIndicatorName=label[label.length - 1]
        }
        return label[label.length - 1];
    }

    //新增绩效层面选择回调
    levelonChange = (value) => {
        this.setState({levelValue: value}, () => {
            this.meritIndicatorInfo()
        })

    }
    //查询层面选择回调
    onChangeMeritType = (value) => {
        this.setState({
            meritType: value,
            meritOneIndicatorId:undefined,
            meritTwoIndicatorId:undefined,
            meritThreeIndicatorId:undefined

        }, () => {
            this.meritOneIndicatorInfo()
        })
    }

    //查询一级回调
    onChangeOne = (value) => {
        let obj = {};
        obj = this.state.meritOneIndicatorList.find((item)=>{//meritOneIndicatorList
            return item.id === value;//筛选出匹配数据
        });

        this.setState({
            meritOneIndicatorId:value,
            meritTwoIndicatorId:undefined,
            meritThreeIndicatorId:undefined,
            oneIndicatorName: obj?obj.indicatorName:'',
            twoIndicatorName: undefined,
            threeIndicatorName: undefined,
        }, () => {
            this.meritTwoIndicatorInfo()
        })
    }
    //查询二级回调
    onChangeTwo = (value) => {
        let obj = {};
        obj = this.state.meritTwoIndicatorList.find((item)=>{//meritOneIndicatorList
            return item.id === value;//筛选出匹配数据
        });
        this.setState({
            meritTwoIndicatorId:value,
            meritThreeIndicatorId:undefined,
            twoIndicatorName:obj?obj.indicatorName:'',
            threeIndicatorName: undefined,
        }, () => {
            this.meritThreeIndicatorInfo()
        })
    }
    //查询三级级回调
    onChangeThree = (value) => {
        let obj = {};
        obj = this.state.meritThreeIndicatorList.find((item)=>{//meritOneIndicatorList
            return item.id === value;//筛选出匹配数据
        });
        this.setState({
            meritThreeIndicatorId:obj?obj.indicatorName:'',
            threeIndicatorId:obj?obj.id:'',

        }, () => {
        })
    }
    //是否
    onNo = (value) => {
        this.setState({
            getTaskMerit:value,
        }, () => {
        })
    }
    //排序
    handlechilks = (paqination, filters, sorter, extra) => {
        console.log('sorter',sorter)
        let condition = ''
        if (sorter) {
            //建设任务ID升序
            if (sorter.order) {
                if (sorter.column.title === '绩效ID' && sorter.order === 'ascend') {
                    condition = 1
                } else if (sorter.column.title === '绩效ID' && sorter.order === 'descend') {
                    condition = 2
                } else if (sorter.column.title === '绩效指标名称' && sorter.order === 'ascend') {
                    condition = 3
                } else if (sorter.column.title === '绩效指标名称' && sorter.order === 'descend') {
                    condition = 4
                } else if (sorter.column.title === '完成度' && sorter.order === 'ascend') {
                    condition = 5
                } else if (sorter.column.title === '完成度' && sorter.order === 'descend') {
                    condition = 6
                } else if (sorter.column.title === '截止日期' && sorter.order === 'ascend') {
                    condition = 7
                } else if (sorter.column.title === '截止日期' && sorter.order === 'descend') {
                    condition = 8
                }
            } else {
                condition = ''
            }
            this.state.condition = condition
            this.setState({
                pageNum: paqination.current,
                pageSize: paqination.pageSize
            }, () => this.meritPageList());
        }
    }
    startOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    //日期
    dateOnChangeOf = (value, dateString) => {
        console.log('Formatted Selected Time: ', dateString);
        console.log('value: ', value);
        this.state.startTime = dateString[0]
        this.state.endTime = dateString[1]
        this.setState({datepickerInit: value})
    }


    bulkImportCancel = ()=>{
        this.setState({bulkImport: false})
    }

    //日期框
    disabledEndDate = (endValue) => {
        const {startValue} = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    disabledStartTime = beginTime => {
        const { endTime } = this.state;
        if (!beginTime || !endTime) {
            return false;
        }
        return beginTime.valueOf() > endTime.valueOf();
    };
    disabledStartDate = (startValue) => {
        const {endValue} = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };
    onChange = (field, value) => {
        this.setState({[field]: value})
    };
    onStartChange = (value) => {
        this.onChange("startValue", value);
        this.state.startDate = value?value.format('yyyy-MM-DD 00:00:00'):undefined
    };

    onEndChange = (value) => {
        this.onChange("endValue", value);
        this.state.endDate = value?value.format('yyyy-MM-DD 00:00:00'):undefined
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({endOpen: open});
    };
    render() {
        let {
            columns, total, pageNum, pageSize, meritList,
            ModalVisible, indexOptions, queryCatgory, meritTypes, meritType, getTaskMerit,
            meritTypess, userInfoList, getDhAllCommonDic
            , meritOneIndicatorList, meritTwoIndicatorList, meritThreeIndicatorList, meritOneIndicatorId,
            meritTwoIndicatorId, meritThreeIndicatorId, datepickerInit, bulkImport, endOpen, code,endValue,startValue
        } = this.state
        let pagination = {
            total: total,
            pageNum: pageNum,
            current: pageNum,//重置页码
            pageSize: pageSize,
            onChange: this.changePage
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='Performancelist'>
                <div className='project'>
                    <div className='project-title'>
                        <p></p>
                        <span>绩效管理</span>
                    </div>
                </div>
                <div className='top_search'>
                    <div className="Learnform Learnform1">
                        <span className="li-span">
                            <i className="i">层  面</i>
                          <Select
                              style={{width: 230}}
                              placeholder="层  面"
                              allowClear={true}
                              value={meritType}
                              onChange={this.onChangeMeritType}
                          >
                              {
                                  queryCatgory === 1 ? meritTypes && meritTypes.map(item =>
                                      <Select.Option
                                          value={item.value} >{item.label}</Select.Option>
                                  ) : meritTypess && meritTypess.map(item =>
                                      <Select.Option
                                          value={item.value} >{item.label}</Select.Option>
                                  )
                              }
                            </Select>
                        </span>
                        <span className="li-span">
                            <i className="i">一级指标</i>
                            <Select
                                placeholder="请选择一级指标"
                                allowClear={true}
                                value={meritOneIndicatorId}
                                onChange={this.onChangeOne}
                                style={{width: 230}}
                            >
                                {meritOneIndicatorList&&meritOneIndicatorList.map(item=>(
                                    <Select.Option value={item.id} >{item.indicatorName}</Select.Option>
                                ))}
                            </Select>
                        </span>
                        <span className="li-span">
                            <i className="i">二级指标</i>
                            <Select
                                placeholder="请选择二级指标"
                                allowClear={true}
                                onChange={this.onChangeTwo}
                                value={meritTwoIndicatorId}
                                style={{width: 230}}>
                              {meritTwoIndicatorList&&meritTwoIndicatorList.map((item, index)=>(
                                  <Select.Option value={item.id}  key={index}>{item.indicatorName}</Select.Option>
                              ))}
                            </Select>

                        </span>
                        <span className="li-span">
                            <i className="i">三级指标</i>
                             <Select
                                 placeholder="请选择三级指标"
                                 allowClear={true}
                                 value={meritThreeIndicatorId}
                                 onChange={this.onChangeThree}
                                 style={{width: 230}}>
                              {meritThreeIndicatorList&&meritThreeIndicatorList.map((item, index)=>(
                                  <Select.Option value={item.id} key={index}>{item.indicatorName}</Select.Option>
                              ))}
                            </Select>
                        </span>
                        <span className="li-span">
                            <i className="i">关联任务</i>
                            <Select
                                placeholder="请选择是否关联任务"
                                allowClear={true}
                                value={getTaskMerit}
                                onChange={this.onNo}
                                style={{width: 230}}>
                              <Option value="是">是</Option>
                                <Option value="否">否</Option>
                            </Select>
                        </span>
                        {/*<span className="li-span">*/}
                        {/*    <i className="i">规划日期</i>*/}
                        {/*     <RangePicker*/}
                        {/*         style={{width: 230}}*/}
                        {/*         disabledDate={this.disabledStartTime}*/}
                        {/*         placeholder="请选择填报时间"*/}
                        {/*         value={datepickerInit}*/}
                        {/*         onChange={this.dateOnChangeOf}*/}
                        {/*         onOpenChange={this.startOpenChange}*/}
                        {/*         className={'time'}*/}
                        {/*         getCalendarContainer={triggerNode => triggerNode.parentNode}*/}
                        {/*     />*/}
                        {/*</span>*/}

                    </div>
                    <div>
                        <div className={'Learnform Learnform2'}>
                            <span className="li-span1">
                                <Button className={'resize'}onClick={() => this.handleReset()}>重 置</Button>
                                <Button className='chaxun' onClick={() => this.selectlabel()} >查 询</Button>
                            </span>
                            <span className="li-spantp">
                                <Button className='piliang' onClick={()=>{this.setState({bulkImport:true})}}>批量导入</Button>
                            </span>
                            <span className="li-spantj">
                                <Button className='tianjia' onClick={() => this.saveList()}>添加绩效</Button>
                            </span>



                        </div>
                    </div>
                </div>
                <div className='top_searchh'>
                    <div className="tabborder">
                        <div className={'tabl'}>
                            <Table cellspacing={20} columns={columns}
                                   className='tableUserStyle'
                                   dataSource={meritList}
                                   pagination={pagination}
                                   scroll={{ x: 1500, y: 545 }}
                                   onChange={this.handlechilks}
                                   rowKey={(r, i) => (r.id)}
                                   locale={{emptyText:<><div className="Empty-conet-ss">
                                           <div className="img">
                                               <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                               <span>暂无数据</span>
                                           </div>
                                       </div></>}}
                                   pagination={
                                       {
                                           current: pageNum,
                                           total: total,
                                           pageSize: pageSize,
                                           // onChange: this.handlechilks,
                                           // onShowSizeChange: this.onShowSizeChange,
                                           pageSizeOptions: ['20', '40', '60', '80'],
                                           showSizeChanger: true,
                                       }
                                   }
                            />
                        </div>
                    </div>


                </div>
                <Modal className='projectTable-list' title="新建绩效" width={680} maskClosable={false} visible={ModalVisible} closable={false} onOk={() => this.meritSave()}
                       onCancel={() => this.handleCancel()}>
                    <div className='list-a'>
                        <Form labelCol={{span: 6}} wrapperCol={{span: 14}} colon={false}>
                            <Form.Item label="绩效名称" className={'height40'}>
                                {
                                    getFieldDecorator('meritName', {
                                        rules: [
                                            {
                                                type: 'string',
                                                min: 2,
                                                max: 200,
                                                message: '绩效名称不能小于2或大于200字符!',
                                            },
                                            {
                                                required: true,
                                                message: '绩效名称不能为空!'
                                            },
                                        ]
                                    })(<Input autocomplete="off" placeholder="请输入绩效名称"/>)
                                }
                            </Form.Item>
                            <Form.Item label="层面" className={'height40'}>
                                {
                                    getFieldDecorator('meritType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '层面不能为空!'
                                            }
                                        ]
                                    })(<Select defaultValue="lucyA" placeholder="请选择层面" allowClear={true}
                                               onChange={this.levelonChange}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {
                                            queryCatgory === 1 ? meritTypes && meritTypes.map(item =>
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            ) : meritTypess && meritTypess.map(item =>
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            )
                                        }

                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="指标层级" className={'height40'}>
                                {
                                    getFieldDecorator('indicatorName', {
                                        rules: [{
                                            required: true,
                                            message: '指标层级不能为空'
                                        }]
                                    })

                                    (<Cascader fieldNames={{label: 'indicatorName', value: 'id', children: 'children'}}
                                               options={indexOptions}
                                               popupPlacement='bottomLeft'
                                               changeOnSelect
                                               displayRender={this.displayRender}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                               placeholder="请选择指标层级"/>)

                                }
                            </Form.Item>
                            <Form.Item label="负责人" className={'height40'}>
                                {
                                    getFieldDecorator('directorId', {})(<Select
                                        showSearch="true"
                                        optionFilterProp="children"
                                        defaultValue=""
                                        placeholder="请选择负责人"
                                        allowClear={true}
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        onChange={(e) => this.onUserChanges(e)}>
                                        {
                                            userInfoList && userInfoList.map(item =>
                                                <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                            )
                                        }

                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="目标值" className={'height40'}>
                                {
                                    getFieldDecorator('targetValue', {
                                        rules: [
                                            {
                                                type: 'string',
                                                min: 1,
                                                max: 200,
                                                message: '目标值不能大于200字符!',
                                            },
                                            {
                                                required: true,
                                                message: '目标值不能为空!'
                                            },
                                        ]
                                    })(<Input autocomplete="off" placeholder="请输入目标值"/>)
                                }
                            </Form.Item>
                            <Form.Item label="统计单位" className={'height40'}>
                                {
                                    getFieldDecorator('indicatorMeasure', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '统计单位不能为空!'
                                            }
                                        ]
                                    })(<Select defaultValue="" placeholder="请选择统计单位" allowClear={true}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {
                                            getDhAllCommonDic && getDhAllCommonDic.map(item =>
                                                <Select.Option value={item.dicValue}>{item.dicValue}</Select.Option>
                                            )
                                        }

                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="规划日期" className={'height40 rangePicker'}>
                                {
                                    getFieldDecorator('time')(
                                        <div>
                                            <DatePicker
                                                style={{'display': 'inline-block'}}
                                                disabledDate={this.disabledStartDate}
                                                format="YYYY-MM-DD"
                                                value={startValue ? moment(startValue) : undefined}
                                                placeholder="请选择日期"
                                                onChange={this.onStartChange}
                                                onOpenChange={this.handleStartOpenChange}
                                                getCalendarContainer={triggerNode => triggerNode.parentNode}
                                            /><span style={{
                                            'display': 'inline-block',
                                            'width': '32px',
                                            'textAlign': 'center',
                                            'float': 'left'
                                        }}>至</span>
                                            <DatePicker
                                                style={{'display': 'inline-block'}}
                                                disabledDate={this.disabledEndDate}
                                                format="YYYY-MM-DD"
                                                value={endValue ? moment(endValue) : undefined}
                                                placeholder="请选择日期"
                                                onChange={this.onEndChange}
                                                open={endOpen}
                                                onOpenChange={this.handleEndOpenChange}
                                                getCalendarContainer={triggerNode => triggerNode.parentNode}
                                            />
                                        </div>
                                    )
                                }

                            </Form.Item>
                            <Form.Item label="绩效描述">
                                <div className={'textare-wrap'}>
                                    {
                                        getFieldDecorator('meritDescribe')(
                                            <TextArea maxLength={500}
                                                      placeholder="请输入绩效描述"
                                                      style={{width: '100%', resize: 'none'}}
                                                      rows={5}
                                                      defaultValue={''}
                                            />
                                        )
                                    }
                                    <span className='textarea_len'>
                                    {this.props.form.getFieldValue(`meritDescribe`) ? this.props.form.getFieldValue(`meritDescribe`).length : 0}/500
                                        </span>
                                </div>
                            </Form.Item>


                        </Form>
                    </div>
                </Modal>
                {/*批量导入*/}
                <Modal
                    title="批量导入"
                    visible={bulkImport}
                    width={660}
                    closable={false}
                    className={'bulkImportmodalwrap'}
                    maskClosable={false}
                    centered={true}
                    footer={null}
                >
                    <BulkImport code={code} bulkImportCancel={this.bulkImportCancel.bind(this)} meritPageList={this.meritPageList.bind(this)} queryCatgoryById={this.queryCatgoryById.bind(this)} />
                </Modal>
            </div>
        );
    }
}

PerformancelistLook = Form.create()(PerformancelistLook);
export default PerformancelistLook;
