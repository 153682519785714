/*学校概况与专业技能管理*/
import React, {Component} from "react";
import {
    Input, Select, Modal, Form,Tabs, Tooltip
} from "antd";
import "./index.scss";
import "../../styles/global.less"
import {withRouter} from "react-router";
import Cookies from "js-cookie";
import RenderButton from "../../components/renderButton/index.js";
import {getSchoolProfile, getSpecialtyGroup,queryCatgoryById} from "../../api/synchrodatment"

@withRouter
class ProfessionGroup extends Component {
    state = {
        activeKey: '1',
        total: 0,
        pageNum: 1,
        pageSize: 10,
        searchValue: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            pageSizeOptions: ["10", "20", "50", "100"]
        },
        count: 0, // 机构名称
        dataSource: {},//学校信息返回值
        SpecialtyGroup: {},//专业群返回值
        specialtyType: 1,//查询专业群时的参数
        activeIndex: 1,//初始化时默认显示第一个li
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id,
        taskId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId,
        options: [
            {value: 1, label: "公办"},
            {value: 2, label: "民办"},
            {value: 3, label: "合作办学"},
        ],
        queryCatgory:{}
    }

    componentDidMount() {
        //查询学校概况
        console.log("学校")
        this.getSchoolProfileByschoolId();
        this.queryCatgoryById();

    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {};  //区分是专业群1或2、3，3为新建学校
        data.id=this.state.schoolId
        let queryCatgory = await queryCatgoryById(data)
            this.setState({
                queryCatgory:queryCatgory
            })
    }


    //查询学校概况
    async getSchoolProfileByschoolId() {
        console.log("学校概况")
        let data = {};
        data.schoolId = this.state.schoolId
        data.taskId = this.state.taskId
        let dataSource = await getSchoolProfile(data)
        this.setState({
            dataSource
        })
        console.log("参数", data)
        console.log("返回值", dataSource)
    }


    //查询专业群
    async getSpecialtyGroupByspecialtyType(index) {
        console.log("专业群")
        let data = {};
        //区分是专业群1或2
        index === 2 ? data.specialtyType = '1' : data.specialtyType = '2'
        data.taskId = this.state.taskId

        let SpecialtyGroup = await getSpecialtyGroup(data)
        this.setState({
            SpecialtyGroup
        })
        console.log("专业群1参数", data)
        console.log("专业群1返回值", SpecialtyGroup)
    }

    //切换标签
    handleTab = (index) => {
        this.setState({activeIndex: index})

        //查询专业群
        this.getSpecialtyGroupByspecialtyType(index)
    }


    render() {
        let {activeIndex, dataSource, SpecialtyGroup,queryCatgory} = this.state;
        return (
            <div className='quanxiansz_center'>
                <div className="content">
                    {queryCatgory===1? <ul className='TitleUl'>
                        <RenderButton id="SS-01-B07-b_btnViewSchool">
                            <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                                this.handleTab(1)
                            }}>学校概况
                            </li>
                        </RenderButton>
                        <RenderButton id="SS-01-B07-b_btnViewProfessionalCourse">
                            <li className={activeIndex === 2 ? "actived actived2" : ""} onClick={() => {
                                this.handleTab(2)
                            }}>专业群一概况
                            </li>
                        </RenderButton>

                        <RenderButton id="SS-01-B07-b_btnViewProfessionalCourse">
                            <li className={activeIndex === 3 ? "actived actived2" : ""} onClick={() => {
                                this.handleTab(3)
                            }}>专业群二概况
                            </li>
                        </RenderButton>
                    </ul>:queryCatgory===2?
                        <ul className='TitleUl'>
                            <RenderButton id="SS-01-B07-b_btnViewSchool">
                                <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                                    this.handleTab(1)
                                }}>学校概况
                                </li>
                            </RenderButton>
                            <RenderButton id="SS-01-B07-b_btnViewProfessionalCourse">
                                <li className={activeIndex === 2 ? "actived actived1" : ""} onClick={() => {
                                    this.handleTab(2)
                                }}>专业群概况
                                </li>
                            </RenderButton>
                        </ul>: queryCatgory===3?
                        <ul className='TitleUl'>
                        <RenderButton id="SS-01-B07-b_btnViewSchool">
                            <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                                this.handleTab(1)
                                }}>学校概况
                            </li>
                        </RenderButton>
                        </ul>: null
                    }


                    {activeIndex === 1 ?
                        <RenderButton id="SS-01-B07-b_btnViewSchool">
                            <div>
                                <div className='caozuogroup'>
                                    <div className='yuanxi'>

                                        <div className='title' style={{'marginLeft': '0px'}}>
                                            <div></div>
                                            <span>项目学校概况</span>
                                        </div>
                                        <ul className="ul-on">
                                            <li>
                                                <h4>申报学校：
                                                    <span>{dataSource ? dataSource.schoolVo?.schoolName : ""}</span>
                                                </h4>
                                                <h4>所在地区：
                                                    <span>{dataSource ? dataSource.schoolVo?.province : ""}</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>建校时间：
                                                    <span>{dataSource ? dataSource.schoolVo?.createSchoolTime : ""}</span>
                                                </h4>
                                                <h4>院校性质：
                                                    <span>{
                                                        dataSource ?
                                                            dataSource.schoolVo?.schoolNature===1 ? "公办" :
                                                                dataSource.schoolVo?.schoolNature===2 ? "民办" : dataSource.schoolVo?.schoolNature === 2 ? "合作办学":""
                                                            :""
                                                    }</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>举办单位类型：
                                                    <span>{dataSource ? dataSource.schoolVo?.organizerType : ""}</span>
                                                </h4>
                                                <h4>邮编：
                                                    <span>{dataSource ? dataSource.schoolVo?.postCode : ""}</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>学校网址：
                                                    <span>{dataSource ? dataSource.schoolVo?.schoolWebsite : ""}</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>通讯地址：
                                                    <span>{dataSource ? dataSource.schoolVo?.address : ""}</span>
                                                </h4>
                                            </li>
                                        </ul>
                                        <div className='title' style={{'marginLeft': '0px'}}>
                                            <div></div>
                                            <span>法人代表信息</span>
                                        </div>
                                        <ul className="ul-on">
                                            <li>
                                                <h4>姓名：
                                                    <span>{dataSource ? dataSource.legalPersonVo?.userName : ""}</span>
                                                </h4>
                                                <h4>职务：
                                                    <span>{dataSource ? dataSource.legalPersonVo?.userJob : ""}</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>传真：
                                                    <span>{dataSource ? dataSource.legalPersonVo?.userFax : ""}</span>
                                                </h4>
                                                <h4>手机：
                                                    <span>{dataSource ? dataSource.legalPersonVo?.phone : ""}</span>
                                                </h4>
                                            </li>
                                        </ul>
                                        <div className='title' style={{'marginLeft': '0px'}}>
                                            <div></div>
                                            <span>项目联系人</span>
                                        </div>
                                        <ul className="ul-on">
                                            <li>
                                                <h4>姓名：
                                                    <span>{dataSource ? dataSource.projectPersonVo?.userName : ""}</span>
                                                </h4>
                                                <h4>职务：
                                                    <span>{dataSource ? dataSource.projectPersonVo?.userJob : ""}</span>
                                                </h4>
                                            </li>
                                            <li>

                                                <h4>传真：
                                                    <span>{dataSource ? dataSource.projectPersonVo?.userFax : ""}</span>
                                                </h4>
                                                <h4>手机：
                                                    <span>{dataSource ? dataSource.projectPersonVo?.phone : ""}</span>
                                                </h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </RenderButton> : activeIndex === 2 ? <div>
                            <div className='caozuogroup'>
                                <div className='yuanxi'>
                                    <div className='title' style={{'marginLeft': '0px'}}>
                                        <div></div>
                                        <span>专业群一基本概况</span>
                                    </div>
                                    <ul className="ul-on">
                                        <li>
                                            <h4>专业群名称：
                                                <span>{SpecialtyGroup ? SpecialtyGroup.specialtyName : ""}</span>
                                            </h4>
                                            <h4>主要面向产业：
                                                <span>{SpecialtyGroup ? SpecialtyGroup.facingIndustry : ""}</span>
                                            </h4>
                                        </li>
                                        <li>

                                            <h4>面向职业岗位群：
                                                {
                                                    SpecialtyGroup?
                                                        <Tooltip title={SpecialtyGroup.postGroup}>
                                                            <span>{SpecialtyGroup ? SpecialtyGroup.postGroup : ""}</span>
                                                        </Tooltip>:
                                                        <span>{SpecialtyGroup ? SpecialtyGroup.postGroup : ""}</span>
                                                }

                                            </h4>
                                            <h4>院校性质：
                                                <span>{
                                                    dataSource ?
                                                        dataSource.schoolVo?.schoolNature===1 ? "公办" :
                                                            dataSource.schoolVo?.schoolNature===2 ? "民办" : dataSource.schoolVo?.schoolNature === 2 ? "合作办学":""
                                                        :""
                                                }</span>
                                        </h4>
                                    </li>
                                </ul>
                                <div className='title' style={{'marginLeft': '0px'}}>
                                    <div></div>
                                    <span>专业群一包含专业</span>
                                </div>
                                <ul className="ul-on">
                                    {SpecialtyGroup ? SpecialtyGroup.addSpecialtyGroupDetailVos ? SpecialtyGroup.addSpecialtyGroupDetailVos.map(item =>

                                        <li>
                                            <h4>专业代码：
                                                <span>{item.professionCode}</span>
                                            </h4>
                                            <h4>专业名称：
                                                <span>{item.professionName}</span>
                                            </h4>
                                            <h4>所在院系：
                                                <span>{item.department}</span>
                                            </h4>
                                            <h4>所属专业大类：
                                                <span>{item.bigProfession}</span>
                                            </h4>
                                        </li>
                                    ) : "" : ''}
                                </ul>
                                <div className='title' style={{'marginLeft': '0px'}}>
                                    <div></div>
                                    <span>专业群一建设负责人</span>
                                </div>
                                <ul className="ul-on">
                                    <li>
                                        <h4>姓名：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.userName : ""}</span>
                                        </h4>
                                        <h4>性别：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.sex==1?"男" :SpecialtyGroup.sex==2?"女" :"": ""}</span>
                                        </h4>
                                        <h4>出生日期：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.birthday : ""}</span>
                                        </h4>

                                    </li>
                                    <li>
                                        <h4>学历：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.education : ""}</span>
                                        </h4>
                                        <h4>学位：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.academicDegree==1?"学士":SpecialtyGroup.academicDegree==2?"硕士"
                                                :SpecialtyGroup.academicDegree==3?"博士":SpecialtyGroup.academicDegree==2?"其它":"": ""}</span>
                                        </h4>
                                        <h4>行政职务：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.administrativePosition : ""}</span>
                                        </h4>

                                    </li>
                                    <li>
                                        <h4>联系电话：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.phone : ""}</span>
                                        </h4>
                                        <h4>专业技术职务：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.professionalTechnology : ""}</span>
                                        </h4>
                                        <h4>办公电话：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.workPhone : ""}</span>
                                        </h4>
                                    </li>
                                    <li>
                                        <h4>手机号码：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.phone : ""}</span>

                                        </h4>
                                        <h4>职业技能技术：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.occupationSkill : ""}</span>
                                        </h4>
                                        <h4>电子邮箱：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.email : ""}</span>
                                        </h4>
                                    </li>
                                </ul>
                            </div>
                        </div> </div>:activeIndex===3?<div className='caozuogroup'>
                            <div className='yuanxi'>
                                <div className='title' style={{'marginLeft': '0px'}}>
                                    <div></div>
                                    <span>专业群二基本概况</span>
                                </div>
                                <ul className="ul-on">
                                    <li>
                                        <h4>专业群名称：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.specialtyName : ""}</span>
                                        </h4>
                                        <h4>主要面向产业：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.facingIndustry : ""}</span>
                                        </h4>
                                    </li>
                                    <li>
                                        <h4>面向职业岗位群：
                                            {
                                                SpecialtyGroup.postGroup ?
                                                    <Tooltip title={SpecialtyGroup.postGroup}>
                                                        <span>{SpecialtyGroup ? SpecialtyGroup.postGroup : ""}</span>
                                                    </Tooltip>:
                                                    <span>{SpecialtyGroup ? SpecialtyGroup.postGroup : ""}</span>
                                            }

                                        </h4>
                                        <h4>院校性质：

                                            <span>{
                                                dataSource ?
                                                    dataSource.schoolVo?.schoolNature===1 ? "公办" :
                                                        dataSource.schoolVo?.schoolNature===2 ? "民办" : dataSource.schoolVo?.schoolNature === 2 ? "合作办学":""
                                                    :""
                                            }</span>


                                        </h4>
                                    </li>
                                </ul>
                                <div className='title' style={{'marginLeft': '0px'}}>
                                    <div></div>
                                    <span>专业群二包含专业</span>
                                </div>
                                <ul className="ul-on">
                                    {SpecialtyGroup && SpecialtyGroup.addSpecialtyGroupDetailVos && SpecialtyGroup.addSpecialtyGroupDetailVos.map(item =>

                                        <li>
                                            <h4>专业代码：
                                                <span>{item.professionCode}</span>
                                            </h4>
                                            <h4>专业名称：
                                                <span>{item.professionName}</span>
                                            </h4>
                                            <h4>所在院系：
                                                <span>{item.department}</span>
                                            </h4>
                                            <h4>所属专业大类：
                                                <span>{item.bigProfession}</span>
                                            </h4>
                                        </li>
                                    )}
                                </ul>
                                <div className='title' style={{'marginLeft': '0px'}}>
                                    <div></div>
                                    <span>专业群二建设负责人</span>
                                </div>
                                <ul className="ul-on">
                                    <li>
                                        <h4>姓名：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.userName : ""}</span>
                                        </h4>
                                        <h4>性别：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.sex==1?"男" :SpecialtyGroup.sex==2?"女" :"": ""}</span>
                                        </h4>
                                        <h4>出生日期：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.birthday : ""}</span>
                                        </h4>

                                    </li>
                                    <li>
                                        <h4>学历：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.education : ""}</span>
                                        </h4>
                                        <h4>学位：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.academicDegree==1?"学士":SpecialtyGroup.academicDegree==2?"硕士"
                                                :SpecialtyGroup.academicDegree==3?"博士":SpecialtyGroup.academicDegree==2?"其它":"": ""}</span>
                                        </h4>
                                        <h4>行政职务：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.administrativePosition : ""}</span>
                                        </h4>

                                    </li>
                                    <li>
                                        <h4>联系电话：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.phone : ""}</span>
                                        </h4>
                                        <h4>专业技术职务：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.professionalTechnology : ""}</span>
                                        </h4>
                                        <h4>办公电话：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.workPhone : ""}</span>
                                        </h4>
                                    </li>
                                    <li>
                                        <h4>手机号码：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.phone : ""}</span>

                                        </h4>
                                        <h4>职业技能技术：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.occupationSkill : ""}</span>
                                        </h4>
                                        <h4>电子邮箱：
                                            <span>{SpecialtyGroup ? SpecialtyGroup.email : ""}</span>
                                        </h4>
                                    </li>
                                </ul>
                            </div>
                            </div>:''}
                        </div>
                        <div className="Studen-bottom">

                        </div>
                        </div>

                        );
                        }
                        }

                        ProfessionGroup = Form.create()(ProfessionGroup);
                        export default ProfessionGroup;
