/*角色权限设置*/
import React, {Component} from "react";
import {
    Form, Select, Tabs, Table, Modal
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Dnamicmap from "./map1";
import Dnamicmapa from "./map2";
import S from "gongyongicon";
import SchoolRatingCheck from "../school_Ratingshuju";
import {getSeniorBrace,getIndicatorBrace,getEffectDetail} from "../../../../api/doublehigh/index"
import Cookies from "js-cookie";
const {Option} = Select;
const { TabPane } = Tabs;

@withRouter
class StatisticsOfoneshuju extends Component {
    state = {
        schoolIndicatorOneLevel:'',
        isTertiary:false, //三级指标详情
        isSignature:false,  //学校专业群
        isAngleType: "0",
        // seniorBrace:[
        //     {
        //         id:'0',
        //     }
        // ],
        // seniorBraceone:[
        //     {
        //         id:'0',
        //         seniorName:'合计'
        //     }
        // ],
        schoolList:[],
        groupList:[],
        placeList:[],
        columns: [
            {

                title: '三级指标名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width:'30%',
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '指标总数（个）',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度较高（个)',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度一般（个)',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度较弱（个)',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width:'14%',
            },
            {
                title: '佐证材料',
                align: 'center',
                width:'14%',
                // dataIndex: 'taskBookFile.fileFormat',
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetail(record,0)}>查看</span>
                    </div>
                )
            },
        ],
        columnsone: [
            {

                title: '三级指标名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width:'30%',
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '指标总数（个）',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度较高（个)',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度一般（个)',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width:'14%',
            },
            {
                title: '支撑度较弱（个)',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width:'14%',
            },
            {
                title: '佐证材料',
                align: 'center',
                width:'14%',
                // dataIndex: 'taskBookFile.fileFormat',
            },
        ],
        columnslist: [
            {

                title: '维度名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width: 200,
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '标志性成果总数',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width: 130,
            },
            {
                title: '支撑度较高（个）',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度一般（个）',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度较弱（个）',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width: 160,
            },
            {
                title: '操作',
                // dataIndex: 'taskBookFile.fileFormat',
                key: 'right',
                align: 'center',
                width: 160,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetailOne(record,1)}>查看详情</span>
                    </div>
                )
            },
        ],
        grouplist: [
            {

                title: '维度名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width: 200,
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '视角总数',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width: 130,
            },
            {
                title: '支撑度较高（个）',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度一般（个）',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度较弱（个）',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width: 160,
            },
            {
                title: '操作',
                // dataIndex: 'taskBookFile.fileFormat',
                key: 'right',
                align: 'center',
                width: 160,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetailTwo(record,2)}>查看详情</span>
                    </div>
                )
            },
        ],
        indicatorLevelId: "2.5.1.1",
        mid: "2.5.2.1",
        fuwu: "2.5.3.1",
        seniorType: 1,
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
    }

    componentDidMount() {
        this.getSeniorBrace()
        this.getMid()
        this.getFuWu()
    }

    //获取三级指标支撑情况
    getSeniorBrace =()=>{
        let data = {
            upseniorId: this.state.indicatorLevelId,
            schoolEduCode: this.state.schoolEduCode,
            seniorType: this.state.seniorType
        }
        getSeniorBrace(data).then(res =>{
            let schoolList = []
            let groupList = []
            let placeList = []
            if(res){
                if(res.effectBraceNumVos){
                    let effectBrace = res.effectBraceNumVos
                    schoolList = effectBrace.filter(e => e.upseniorName === "双高绩效目标实现贡献度")
                    groupList = effectBrace.filter(e => e.upseniorName === "高水平学校和专业群社会认可度")
                    placeList = effectBrace.filter(e => e.upseniorName === "地方政府（含举办方）重视程度")
                    // console.log('schoolList',schoolList)
                }
                this.setState({
                    effectBrace: res.effectBraceNumVos,
                    seniorBrace: res.indicatorBraceNumVos,
                    schoolList: schoolList,
                    groupList: groupList,
                    placeList: placeList,
                })
            }
        })
    }

    getFuWu =()=>{
        let data = {
            upseniorId: this.state.fuwu,
            schoolEduCode: this.state.schoolEduCode,
            seniorType: this.state.seniorType
        }
        getSeniorBrace(data).then(res =>{
            let schoolList = []
            let groupList = []
            let placeList = []
            if(res){
                if(res.effectBraceNumVos){
                    let effectBrace = res.effectBraceNumVos
                    schoolList = effectBrace.filter(e => e.upseniorName === "双高绩效目标实现贡献度")
                    groupList = effectBrace.filter(e => e.upseniorName === "高水平学校和专业群社会认可度")
                    placeList = effectBrace.filter(e => e.upseniorName === "地方政府（含举办方）重视程度")
                    // console.log('schoolList',schoolList)
                }
                this.setState({
                    fuwuList: res.indicatorBraceNumVos,
                })
            }
        })
    }

    getMid =()=>{
        let data = {
            upseniorId: this.state.mid,
            schoolEduCode: this.state.schoolEduCode,
            seniorType: this.state.seniorType
        }
        getSeniorBrace(data).then(res =>{
            let schoolList = []
            let groupList = []
            let placeList = []
            if(res){
                if(res.effectBraceNumVos){
                    let effectBrace = res.effectBraceNumVos
                    schoolList = effectBrace.filter(e => e.upseniorName === "双高绩效目标实现贡献度")
                    groupList = effectBrace.filter(e => e.upseniorName === "高水平学校和专业群社会认可度")
                    placeList = effectBrace.filter(e => e.upseniorName === "地方政府（含举办方）重视程度")
                    // console.log('schoolList',schoolList)
                }
                this.setState({
                    effectBrace: res.effectBraceNumVos,
                    // seniorBrace: res.indicatorBraceNumVos,
                    schoolList: schoolList,
                    groupList: groupList,
                    placeList: placeList,
                })
            }
        })
    }

    //获取四级指标支撑情况
    getIndicatorBrace =()=>{
        let data = {
            upseniorId: this.state.indicatorLevelId,
            schoolEduCode: this.state.schoolEduCode,
            seniorId: this.state.seniorId
        }
        getIndicatorBrace(data).then(res =>{
            if(res){
                this.setState({
                    indicatorBrace: res
                })
            }
        })
    }

    showDetailEffect =()=>{
        let data = {
            reviewId: this.state.record ? this.state.record.seniorId : ''
        }
        getEffectDetail(data).then(res =>{
            if(res){
                this.setState({
                    effectDetailList: res,
                })
            }
        })

    }


    showDetail = (record,type) =>{
        console.log('record 999', record)
        this.setState({
            isAngleType:0,
            record: record,
            seniorId: record.seniorId,
            isTertiary:true,
        }, () => {
            this.getIndicatorBrace()

        })
        console.log("111-----",this.state.isAngleType)
    }
    showDetailOne = (record,type) =>{
        console.log('record 999', record)
        this.setState({
            isAngleType:1,
            record: record,
            seniorId: record.seniorId,
            isTertiary:true,
            isSignature:true
        }, () => {
            this.showDetailEffect()

        })
        console.log("111-----",this.state.isAngleType)
    }
    showDetailTwo = (record,type) =>{
        console.log('record 999', record)
        this.setState({
            isAngleType:2,
            record: record,
            seniorId: record.seniorId,
            isTertiary:true,
            isSignature:true
        }, () => {
            this.showDetailEffect()

        })
        console.log("111-----",this.state.isAngleType)
    }

    HandleOkguan = () =>{
        this.setState({
            isTertiary:false
        })
    }
    HandleCancel = () =>{
        this.setState({
            isTertiary:false,

        })
    }
    render() {
        const {schoolIndicatorOneLevel,columns,seniorBrace,columnslist,grouplist,seniorBraceone,columnsone,isTertiary,isSignature} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className={'StatisticsOfoneshuju_zuofei'}>
                <div className={'StatisticsOfone_top'}>
                    <h3>指标完成情况概览</h3>
                    <div className={'ul'}>
                        <div className="graph-li" >
                            <h4>指标、成果支撑度数量情况（四级指标）</h4>
                            <div className="graph-dynamic">
                                <Dnamicmap indicatorOneLevel={schoolIndicatorOneLevel}/>
                            </div>
                        </div>
                        <div className="graph-li">
                            <h4>指标、成果支撑度占比情况（四级指标）</h4>
                            <div className="graph-dynamic">
                                <Dnamicmapa indicatorOneLevel={schoolIndicatorOneLevel}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'StatisticsOfone_cen'}>
                    <div className={'indicators_list'}>
                        <h3>产出指标>数量指标</h3>
                        <div className={'table_one'}>
                            <Table columns={columnsone} dataSource={seniorBraceone} showHeader={false} bordered
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }/>
                        </div>
                        <div className={'table_list'}>
                            <Table columns={columns} dataSource={seniorBrace} bordered
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }/>
                        </div>
                    </div>
                    <div className={'bottom_Lul'}>
                        <h3>1-1学校基于双高绩效目标实现贡献度</h3>
                        <div className={'table_list'}>
                            <Table columns={columnslist} dataSource={this.state.schoolList} bordered
                                   scroll={{x: 300}}
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }
                            />
                        </div>
                        <h3>1-2高水平学校和专业群社会认可度</h3>
                        <div className={'table_list'}>
                            <Table columns={grouplist} dataSource={this.state.groupList} bordered
                                   scroll={{x: 300}}
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }
                            />
                        </div>
                        <h3>1-3地方政府（含举办方）重视程度</h3>
                        <div className={'table_list'}>
                            <Table columns={grouplist} dataSource={this.state.placeList} bordered
                                   scroll={{x: 300}}
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }
                            />
                        </div>

                    </div>
                    <div className={'indicators_list'}>
                        <h3>满意度指标 > 服务对象满意度指标</h3>
                        <div className={'table_one table_list'}>
                            <Table columns={columnsone} dataSource={this.state.fuwuList} bordered
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       false
                                   }/>
                        </div>

                    </div>
                </div>
                {/*指标详情*/}
                <Modal
                    visible={isTertiary}
                    onOk={this.HandleOkguan}
                    onCancel={this.HandleCancel}
                    width={1000}
                    closable={true}
                    className='tertiary_Details'
                    maskClosable={false}
                    centered={true}
                    footer={false}
                >
                    <div><SchoolRatingCheck effectDetailList = {this.state.effectDetailList} record = {this.state.record} HandleOkguan={this.HandleOkguan} indicatorBrace = {this.state.indicatorBrace} isAngleType={this.state.isAngleType} isSignature={isSignature}/></div>
                </Modal>
            </div>
        );
    }
}

StatisticsOfoneshuju = Form.create()(StatisticsOfoneshuju);
export default StatisticsOfoneshuju;
