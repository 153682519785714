/*eslint eqeqeq: ["off", "smart"]*/
// 最后一页地方政府联系人
import React, {Component} from 'react';
import {Row, Col, Form,} from 'antd';
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';
import {queryContact} from '@/api/synchrodatment/'

class Localment extends Component {
    state = {
        taskId:'',
        schoolId: "",
        category: 0,
        administration:{},
        finance:{},
        upSeniorId:'地方政府联系人'

    };

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            taskId: jsonparam.taskId,
            schoolId: jsonparam.schoolId,
            category: jsonparam.category
        },() =>{
            this.queryContact();
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    queryContact = () =>{
        let taskId = {taskId : this.state.taskId}
        queryContact(taskId).then(res =>{
            this.setState({
                administration : res.administration,
                finance : res.finance,
            })
        })
    }

    // 预览第7页
    seventhPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId, category};
        this.props.history.push({
            pathname: '/constructioncase',
            search: JSON.stringify(param)
        })
    }

    render() {
        const {administration, finance, taskId, schoolId,category,upSeniorId} = this.state;
        return (
            <div className="Comment-LocalMent main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>
                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} upSeniorId={upSeniorId} category={category}/>}
                                </Col>
                                <Col span={16} className="right">
                                    <div className="content">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>{category==="1"?16:11}</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <div className="bottom">
                                            <h2>{category === "1" ? 4 : 3}.地方政府联系人</h2>
                                            <div className="list">
                                                <h5>省级教育行政部门</h5>
                                                <ul>
                                                    <li>
                                                        <h4>姓名：<span>{administration?administration.userName:""}</span></h4>
                                                        <h4>职务：<span>{administration?administration.userJob:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>办公电话：<span>{administration?administration.workPhone:""}</span></h4>
                                                        <h4>传真：<span>{administration?administration.userFax:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>手机：<span>{administration?administration.phone:""}</span></h4>
                                                        <h4>电子邮箱：<span>{administration?administration.email:""}</span></h4>
                                                    </li>
                                                </ul>
                                                <h5>省级财政部门</h5>
                                                <ul>
                                                    <li>
                                                        <h4>姓名：<span>{finance?finance.userName:""}</span></h4>
                                                        <h4>职务：<span>{finance?finance.userJob:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>办公电话：<span>{finance?finance.workPhone:""}</span></h4>
                                                        <h4>传真：<span>{finance?finance.userFax:""}</span></h4>
                                                    </li>
                                                    <li>
                                                        <h4>手机：<span>{finance?finance.phone:""}</span></h4>
                                                        <h4>电子邮箱：<span>{finance?finance.email:""}</span></h4>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

Localment = Form.create()(Localment);
export default Localment;

