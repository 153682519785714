/*栏目管理*/
import React, {Component} from "react";
import {
    Input, Select, Modal, Form, Tabs, Checkbox
} from "antd";
import "../../personalCenter/index.scss";
import './personalJurisdiction.scss'
import "../../../styles/global.less"
import {withRouter} from "react-router";
import {getPermissionButtonByUser} from "../../../api/user";
@withRouter
class PersonalJurisdiction extends Component {
    state = {
        //院系管理
        yxhavecheckedList: [],//已有的权限
        yxcheckAll: false,///院系全选
        yxplainOptions: [],
        yxhaveMenuChecked:false,//院系菜单是否被选中

        //学校与专业群管理
        xxhavecheckedList: [],//
        xxcheckAll: false,//
        xxplainOptions: [],
        xxhaveMenuChecked:false,//院系菜单是否被选中

        //用户管理
        yhhavecheckedList: [],//
        yhcheckAll: false,//
        yhplainOptions: [],
        yhhaveMenuChecked:false,//院系菜单是否被选中

        //角色权限管理
        jshavecheckedList: [],//
        jscheckAll: false,//
        jsplainOptions: [],
        jshaveMenuChecked:false,//院系菜单是否被选中

        //数据同步管理
        sjhavecheckedList: [],//
        sjcheckAll: false,//
        sjplainOptions: [],
        sjhaveMenuChecked:false,//院系菜单是否被选中

        //项目管理
        xmhavecheckedList: [],//
        xmcheckAll: false,//
        xmplainOptions: [],
        xmhaveMenuChecked:false,//院系菜单是否被选中
    }

    componentDidMount() {
        getPermissionButtonByUser().then(res=>{
            let menus = res
            for (let i = 0; i < menus.length; i++) {
                let children = menus[i].list
                if(menus[i].menuName == "院系管理"){
                    let yxplainOptions = []
                    let yxhavecheckedList = []
                    let yxcheckAll= true
                    if(menus[i].permission != null){
                        this.setState({yxhaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        yxplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            yxhavecheckedList.push(children[j].id)
                        }else{
                            yxcheckAll= false
                        }
                    }
                    this.setState({yxplainOptions:yxplainOptions,yxhavecheckedList:yxhavecheckedList,yxcheckAll:yxcheckAll})
                }
                if(menus[i].menuName == "学校与专业群管理"){
                    let xxplainOptions = []
                    let xxhavecheckedList = []
                    let xxcheckAll= true
                    if(menus[i].permission != null){
                        this.setState({xxhaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        xxplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            xxhavecheckedList.push(children[j].id)
                        }else{
                            xxcheckAll= false
                        }
                    }
                    this.setState({xxplainOptions:xxplainOptions,xxhavecheckedList:xxhavecheckedList,xxcheckAll:xxcheckAll})
                }
                if(menus[i].menuName == "用户管理"){
                    let yhplainOptions = []
                    let yhhavecheckedList = []
                    let yhcheckAll= true
                    if(menus[i].permission != null){
                        this.setState({yhhaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        yhplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            yhhavecheckedList.push(children[j].id)
                        }else{
                            yhcheckAll = false
                        }
                    }
                    this.setState({yhplainOptions:yhplainOptions,yhhavecheckedList:yhhavecheckedList,yhcheckAll:yhcheckAll})
                }
                if(menus[i].menuName == "角色权限管理"){
                    let jsplainOptions = []
                    let jshavecheckedList = []
                    let jscheckAll= true
                    if(menus[i].permission != null){
                        this.setState({jshaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        jsplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            jshavecheckedList.push(children[j].id)
                        }else{
                            jscheckAll = false
                        }
                    }
                    this.setState({jsplainOptions:jsplainOptions,jshavecheckedList:jshavecheckedList,jscheckAll:jscheckAll})
                }
                if(menus[i].menuName == "数据同步管理"){
                    let sjplainOptions = []
                    let sjhavecheckedList = []
                    let sjcheckAll= true
                    if(menus[i].permission != null){
                        this.setState({sjhaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        sjplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            sjhavecheckedList.push(children[j].id)
                        }else{
                            sjcheckAll = false
                        }
                    }
                    this.setState({sjplainOptions:sjplainOptions,sjhavecheckedList:sjhavecheckedList,sjcheckAll:sjcheckAll})
                }

                if(menus[i].menuName == "建设任务管理"){
                    let xmplainOptions = []
                    let xmhavecheckedList = []
                    let xmcheckAll= true
                    if(menus[i].permission != null){
                        this.setState({xmhaveMenuChecked:true})
                    }
                    for (let j = 0; j < children.length; j++) {
                        xmplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            xmhavecheckedList.push(children[j].id)
                        }else{
                            xmcheckAll = false
                        }
                    }
                    this.setState({xmplainOptions:xmplainOptions,xmhavecheckedList:xmhavecheckedList,xmcheckAll:xmcheckAll})
                }

            }
        })
    }
//院系全选
    yxonCheckAllChange = e => {
        this.setState({
            yxcheckedList: e.target.checked ? this.state.yxplainOptions : [],
            yxcheckAll: e.target.checked,
        });
    };
    //院系点击事件
    yxonChange = (checkedList, value) => {
        this.setState({
            yxcheckedList: checkedList,
        },() => {
            this.setState({yxcheckAll: this.state.yxcheckedList.length === this.state.yxplainOptions.length})
        });
        if((checkedList.includes('新建组织') || checkedList.includes('编辑') || checkedList.includes('删除')) && !checkedList.includes('查看列表页面')){
            checkedList.push('查看列表页面')
        }
    };
    //用户管理全选
    yhonCheckAllChange = e => {
        this.setState({
            yhcheckedList: e.target.checked ? this.state.yhplainOptions : [],
            yhcheckAll: e.target.checked,
        });
    };
    //用户管理点击事件
    yhonChange = (checkedList, value) => {
        this.setState({
            yhcheckedList: checkedList,
        },() => {
            this.setState({yhcheckAll: this.state.yhcheckedList.length === this.state.yhplainOptions.length})
        });
        if((checkedList.includes('新建用户') || checkedList.includes('编辑') || checkedList.includes('重置密码') || checkedList.includes('停用/恢复')) && !checkedList.includes('查看列表页面')){
            checkedList.push('查看列表页面')
        }

    };


    render() {
        const CheckboxGroup = Checkbox.Group
        const {yxplainOptions, yhplainOptions,yxhavecheckedList,yhhavecheckedList} = this.state



        return (
            <div className='quanxiansz_center grxxquanxiansz_center'>

                <div className='content' >

                    <div className='caozuogroup'>
                        <div className='yuanxi' >
                            <div className='title'>
                                {/*<div></div>*/}
                                <Checkbox className='checkboxx' checked={this.state.yxhaveMenuChecked} disabled={true}></Checkbox>
                                <span>院系管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={yxplainOptions}
                                    value={yxhavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='yuanxi' >
                            <div className='title'>
                                <Checkbox className='checkboxx' checked={this.state.xxhaveMenuChecked} disabled={true}></Checkbox>
                                <span>学校与专业群管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={this.state.xxplainOptions}
                                    value={this.state.xxhavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox className='checkboxx' checked={this.state.yhhaveMenuChecked} disabled={true}></Checkbox>
                                <span>用户管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={yhplainOptions}
                                    value={yhhavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox className='checkboxx' checked={this.state.jshaveMenuChecked} disabled={true}></Checkbox>
                                <span>角色权限管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={this.state.jsplainOptions}
                                    value={this.state.jshavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox className='checkboxx' checked={this.state.sjhaveMenuChecked} disabled={true}></Checkbox>
                                <span>数据同步管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={this.state.sjplainOptions}
                                    value={this.state.sjhavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox className='checkboxx' checked={this.state.xmhaveMenuChecked} disabled={true}></Checkbox>
                                <span>建设任务管理</span>
                            </div>
                            <div className='checkgroup'>
                                <CheckboxGroup
                                    options={this.state.xmplainOptions}
                                    value={this.state.xmhavecheckedList}
                                    disabled={true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

PersonalJurisdiction = Form.create()(PersonalJurisdiction);
export default PersonalJurisdiction;
