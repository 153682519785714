/*角色权限设置*/
import React, {Component} from "react";
import {
    Form,Select
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import FinancialAsset from "./components/financialassetshuju";
import CollegesUniversities from "./components/collegesUniversitiesshuju";
const {Option} = Select;

@withRouter
class ConstructionShuju extends Component {
    state = {
        activeIndex: 1,//初始化时默认显示第一个li
    }

    componentDidMount() {

    }
    //切换标签
    handleTab = (index) => {
        this.setState({activeIndex: index})
        //查询专业群
        // this.getSpecialtyGroupByspecialtyType(index)
    }
    render() {
        const {activeIndex} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='ConstructionShuju_cont'>
                <ul className='TitleUl'>
                    <li className={activeIndex === 1 ? "actived" : ""} onClick={() => {
                        this.handleTab(1)
                    }}>双高年度情况汇总
                    </li>
                    <li className={activeIndex === 2 ? "actived" : ""} onClick={() => {
                        this.handleTab(2)
                    }}>院校双高计划建设情况
                    </li>
                </ul>
                {activeIndex === 1 ?<FinancialAsset/> :<CollegesUniversities/>}

            </div>

        );
    }
}

ConstructionShuju = Form.create()(ConstructionShuju);
export default ConstructionShuju;
