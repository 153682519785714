/*建设任务管理--概况*/
import React, {Component} from "react";
import {
    Form, Input, Button, Table, Modal, Icon, Drawer, Menu,  Cascader, Tooltip
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {
    infoProjectById,
    getProjectPanelAndTaskDynamic,
    selectProjectListHistory,
    updateStatusDescribe,
    groupSaveProject,
} from '../../../../api/project/';
import {queryCatgoryById} from "../../../../api/synchrodatment";
import {orgTreeNodeList} from '../../../../api/organizationManage/';
import {selectUserPage} from "../../../../api/user";
import Xiangmuxinxi from "./components/xiangmuxinxi";
import Xiangmucaozuo from "./components/xiangmucaozuo";
import Cookies from "js-cookie";
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';

@withRouter
class Gaikuang extends Component {
    state = {
        xmtype: 'yiguidang1',
        xmtyyiqidong1: 'yiqidong1', //  已启动 ‘yiqidong1’
        xmtypeyiguaqi1: 'yiguaqi1',// 已挂起 ‘’
        xmtypeyiyuqi1: 'yiyuqi1',//已逾期 ‘yiyuqi1’
        xmtypeyiguidang1: 'yiguidang1',//已归档 ‘yiguidang1’
        bgyiqidong: 'yiqidong hegezt', //已启动 ‘yiqidong’
        bgyiguaqi: 'yiguaqi hegezt',// 已挂起 ‘yiguaqi’
        bgyiyuqi: 'yiyuqi hegezt',// 已逾期 ‘yiyuqi’
        bgyiguidang: 'yiguidang hegezt',//已归档 ‘yiguidang’    hegezt一定要有
        wordcoloryiqidong: 'type-left yiqidongcolor',  //已启动 ‘yiqidongcolor’
        wordcoloryiguaqi: 'type-left yiguaqicolor',     //已挂起 ‘yiguaqicolor’
        wordcoloryiyuqi: 'type-left yiyuqicolor',       //已逾期 ‘yiyuqicolor’
        wordcoloryiguidang: 'type-left yiguidangcolor', //已归档 ‘yiguidangcolor’
        wanchenglv: 0.7,//完成率
        yuqilv: 0.3,//预期率
        showLsModel: false,//历史记录
        showGlcyModel: false,//管理成员
        zuzhi: '',
        chengyuan: '',
        zuzhiOptions: [{id: 1, Name: '111'}, {id: 2, Name: '222'},],
        columnsTable: [
            {
                title: '用户姓名',
                dataIndex: 'humanName',
                key: 'humanName',
                align: 'center',
                // render: text => <a>{text}</a>,
            },
            {
                title: '手机号码',
                key: 'phone',
                dataIndex: 'phone',
                align: 'center',
            },
            {
                title: '所属组织',
                key: 'orgName',
                dataIndex: 'orgName',
                align: 'center',
            },

        ],
        dataModel: [
            {
                id: 1,
                humanName: '1111',
                phone: '1234567890',
                orgName: 'lalalal'
            },
            {
                id: 2,
                humanName: '1111',
                phone: '1234567890',
                orgName: 'lalalal'
            },
        ],
        projectId: this.props.match.params.id,
        selectedUsers: [],//弹窗选中的数据
        selectedUserRow: [],//弹窗选中的数据
        yxtotal: 1,//成员管理弹窗选中成员数
        infoProject: {},//建设任务信息
        infoProjectPanel: {},//建设任务面板，动态查询
        completedCountRate: 0,//完成率
        expectedRate: 0,//预期率
        oneLevelTaskDynamicVoListKey: [],//动态表key
        oneLevelTaskDynamicVoKeyList: [],//动态表key里的list
        projectHistory: [],//建设任务历史表
        shezhiVisible: false,//建设任务设置
        xmszDq: '建设任务信息',//建设任务设置左侧当前
        showhide: '查看更多',//查看更多收起备注
        gxztvisible: false,
        showhideclass: 'cneter-cont',
        gxztms: '',//更新状态描述
        editProjectId: '',//编辑建设任务id
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        orgTreeNodeList: [],//组织机构下拉
        userList: [],//管理人员返回数据
        total: '',//返回总数
        userListAll: [],
        personnelCount: 0,
        statusButten: true,
        projectUserList:[],
        selectProjectUserList:[],
        projectUserListALL: [],
        queryCatgory:{}
    }

    componentDidMount() {
        this.infoProject()
        this.infoProjectPanel()
        this.queryCatgoryById()
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id:this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory:queryCatgory
        })
    }
    infoProject = async () => {
        let data = {
            id: this.state.projectId,
            userId:this.state.userId,
        }
        await infoProjectById(data).then(res => {
            this.setState({infoProject: res})
        })
    }

    infoProjectPanel = async () => {
        let data = {
            projectId: this.state.projectId,
        }
        await getProjectPanelAndTaskDynamic(data).then(res => {
            let completedCountRate;
            let expectedRate;
            let keys = [];
            let keyList = [];
            if (res.allCount === 0) {
                completedCountRate = 0
                expectedRate = 0
            }
            if (res.allCount > 0 && res.completedCount === 0) {
                completedCountRate = 0
            }
            if (res.allCount > 0 && res.completedCount > 0) {
                completedCountRate = ((res.completedCount / res.allCount) * 100).toFixed(0)
            }
            if (res.allCount > 0 && res.expiredCount === 0) {
                expectedRate = 0
            }
            if (res.allCount > 0 && res.expiredCount > 0) {
                expectedRate = ((res.expiredCount / res.allCount) * 100).toFixed(0)
            }
            this.setState({
                infoProjectPanel: res,
                completedCountRate: completedCountRate,
                expectedRate: expectedRate,
                oneLevelTaskDynamicVoKeyList: res.oneLevelTaskDynamicVoList,
            })
        })
        this.wanchenglv()
        this.yuqilv()
    }
    //完成率饼图
    wanchenglv = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('wanchenglv')){
            let myChart = echarts.init(document.getElementById('wanchenglv'));
            // 绘制图表
            let option = {
                title: {
                    text: '完成率',//主标题文本
                    subtext: this.state.completedCountRate + '%',//副标题文本
                    left: 'center',
                    top: '27%',
                    textStyle: {
                        fontSize: 14,
                        color: '#888888',
                        align: 'center'
                    },
                    subtextStyle: {
                        fontSize: 24,
                        color: '#333333',
                        align: 'center'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['75%', '100%'],
                        avoidLabelOverlap: false,
                        color: ['#27CDA2', '#F3F3F3',],
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        data: [
                            {
                                value: this.state.completedCountRate/100,
                                itemStyle:{
                                    normal:{color:'#27CDA2'},
                                    emphasis:{color:'#27CDA2'}
                                }
                            },
                            {
                                value: 1 - this.state.completedCountRate/100,
                                itemStyle:{
                                    normal:{color:'#F3F3F3'},
                                    emphasis:{color:'#F3F3F3'}
                                }
                            }
                        ]
                    }
                ]
            };
        myChart.setOption(option)
        }
    }
    //预期率饼图
    yuqilv = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('yuqilv')) {
            let myChart = echarts.init(document.getElementById('yuqilv'));
            // 绘制图表
            let option = {
                title: {
                    text: '逾期率',//主标题文本
                    subtext: this.state.expectedRate + '%',//副标题文本
                    left: 'center',
                    top: '27%',
                    textStyle: {
                        fontSize: 14,
                        color: '#888888',
                        align: 'center'
                    },
                    subtextStyle: {
                        fontSize: 24,
                        color: '#333333',
                        align: 'center'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['75%', '100%'],
                        avoidLabelOverlap: false,
                        color: ['#CD143F', '#F3F3F3',],
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        data: [
                            {
                                value: this.state.expectedRate / 100,
                                itemStyle: {
                                    normal: {color: '#CD143F'},
                                    emphasis: {color: '#CD143F'}
                                }
                            },
                            {
                                value: 1 - this.state.expectedRate / 100,
                                itemStyle: {
                                    normal: {color: '#F3F3F3'},
                                    emphasis: {color: '#F3F3F3'}
                                }
                            }
                        ]
                    }
                ]
            };
            myChart.setOption(option)
        }
    }
    //调建设任务历史接口
    selectProjectListHistory = async () => {
        let data = {
            projectId: this.state.projectId,
        }
        await selectProjectListHistory(data).then(res => {
            this.setState({
                showLsModel: true,
                projectHistory: res,
            })
        })
    }
    showLsModel = () => {
        this.selectProjectListHistory()
    }
    //添加成员
    tianjiaCy = (item) => {
        let selectedUsers = this.state.selectedUsers //已选
        let selectedUserRow = this.state.selectedUserRow//已选
        let count = 0;
        if (item != null) {
            let ids = item.userId //已选
            let val;
            let arr;
            if (item.userId===this.state.infoProject.directorId){
               return this.mesError('负责人不可以删除！');
            }

            for (let i = 0; i < selectedUsers.length; i++) {
                if (selectedUsers[i] === ids) {
                    val = selectedUsers[i];
                }
            }
            let index = selectedUsers.indexOf(val);
            if (index > -1) {
                selectedUsers.splice(index, 1);
            }
            for (let i = 0; i < selectedUserRow.length; i++) {
                if (selectedUserRow[i] === item) {
                    arr = selectedUserRow[i];
                }
            }
            let indexs = selectedUserRow.indexOf(arr);
            if (indexs > -1) {
                selectedUserRow.splice(indexs, 1);
            }
            count = selectedUsers.length;
        }
        if ( this.state.selectProjectUserList.includes(item.userId)){
            let arrs;
            for (let i = 0; i < this.state.selectProjectUserList.length; i++) {
                if (this.state.selectProjectUserList[i] === item.userId) {
                    arrs = this.state.selectProjectUserList[i];
                }
            }
            let indexs = this.state.selectProjectUserList.indexOf(arrs);
            if (indexs > -1) {
                this.state.selectProjectUserList.splice(indexs, 1);
            }
            let user =  this.state.projectUserListAll.filter(i =>(! this.state.selectProjectUserList.includes(i.id)));
            this.setState({
                projectUserList:user
            })
        }
        this.setState({
            selectedUsers: selectedUsers,
            selectedUserRow: selectedUserRow,
            personnelCount: count,
        })
    }

    onClose = () => {
        this.setState({shezhiVisible: false, xmszDq: '建设任务信息'})
    }
    qiehuanNav = ({item, key, keyPath, domEvent}) => {
        this.setState({xmszDq: key})
    }
    //查看更多收起
    showmore = () => {
        if (this.state.showhide === '查看更多') {
            this.setState({showhide: '收起', showhideclass: 'cneter-cont1'})
        } else {
            this.setState({showhide: '查看更多', showhideclass: 'cneter-cont'})
        }
    }
    //更新状态描述
    updateZt = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
        let data = {
            id: this.state.infoProject.id,
            editorId: this.state.userId,
            humanName: this.state.humanName,
            statusDescribe: this.state.gxztms,
            projectStatus: this.state.infoProject.projectStatus,
        }
        updateStatusDescribe(data).then(res => {

            this.infoProject()
            this.infoProjectPanel()
            this.mesSuccess('操作成功');
        }).then(res => {
            this.setState({gxztvisible: false, statusDescribe: ''})
        })}
        setTimeout(() => {   // 设置延迟事件,3// 秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }
    onChangeztms = ({target: {value}}) => {
        this.setState({gxztms: value})
    };
    shezhi = () => {
        if(this.xiangmuxinxi){
            this.xiangmuxinxi.infoProjectById()
        }
        this.setState({shezhiVisible: true})
    };
    member = async () => {
        let data = {
            schoolId: this.state.schoolId,
        }
        orgTreeNodeList(data).then(res => {
            this.setState({
                orgTreeNodeList: res,
                showGlcyModel: true
            })
        })
        if (this.state.infoProject.userProjectVo != null) {
            this.setState({
                personnelCount: this.state.infoProject.userProjectVo.length = 0 ? 0 : this.state.infoProject.userProjectVo.length
            })
            let memberId = this.state.infoProject.userProjectVo;
            for (let i = 0; i < memberId.length; i++) {
                this.state.selectedUsers.push(memberId[i].userId)
                // 为了刚进入列表不显示建的数组
                this.state.selectProjectUserList.push(memberId[i].userId)

            }
            for (let i = 0; i < this.state.infoProject.userProjectVo.length; i++) {
                this.state.selectedUserRow.push(this.state.infoProject.userProjectVo[i])
            }
        }
        this.selectInfoAll();
    };
    //全部成员列表查询
    selectInfoAll = async () => {
        let organizationIds = this.props.form.getFieldValue('zuzhi')
        if (organizationIds != null) {
            this.state.orgTreeNodeList.orgId = organizationIds[organizationIds.length - 1]
        }
        let chengyuan = this.props.form.getFieldValue('chengyuan')
        let params = {
            pageNum: 1,
            pageSize: 100000,
            orgId: this.state.orgTreeNodeList.orgId,
            humanName: chengyuan,
            isDisable: 0,
            schoolId:this.state.schoolId
            // genOrgId:this.state.genOrgId
        };
        const page = await selectUserPage(params);

        if (page) {
            let user =  page.data.filter(i =>(!this.state.selectProjectUserList.includes(i.id)));
            this.setState({
                userList: page.data,
                total: page.total,
                userListAll: page.data,
                projectUserList:user,
                projectUserListAll:page.data,

            });
        }

    }
    //全部成员列表查询
    selectInfo = async () => {
        let organizationIds = this.props.form.getFieldValue('zuzhi')
        if (organizationIds != null) {
            this.state.orgTreeNodeList.orgId = organizationIds[organizationIds.length - 1]
        }
        let chengyuan = this.props.form.getFieldValue('chengyuan')
        let params = {
            pageNum: 1,
            pageSize: 100000,
            orgId: this.state.orgTreeNodeList.orgId,
            humanName: chengyuan,
            isDisable: 0,
            // genOrgId:this.state.genOrgId
        };
        const page = await selectUserPage(params);

        if (page) {
            let user =  page.data.filter(i =>(!this.state.selectProjectUserList.includes(i.id)));
            this.setState({
                userList: page.data,
                total: page.total,
                // userListAll: page.data,
                projectUserList:user,
                projectUserListAll:page.data,
            });
        }
    }
    //成员添加
    saveChengyuan = async () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
        let data = {
            projectId: this.state.projectId,
            userId: this.state.selectedUsers
        }
        groupSaveProject(data).then(res => {
            this.setState({
                showGlcyModel: false,
                selectedUsers: [],
                selectedUserRow: [],
                personnelCount: 0,
            })
            this.infoProject();
            this.mesSuccess('操作成功');

        })}
        setTimeout(() => {   // 设置延迟事件,3// 秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }
    //重置
    res = async () => {
        this.props.form.resetFields()
    }
    // 跳转文档对应列表
    tiaozhuanrwlist = (e) =>{
        this.props.tiaozhuan(e)
    }
    // Cascader显示最后一级
    displayRender=(label) => {
        return label[label.length - 1];
    }
    // 更新描述
    gengxinmiaoshu = async () => {
        let data = {
            id: this.state.projectId,
            userId:this.state.userId,
        }
        await infoProjectById(data).then(res => {
            this.setState({
                gxztvisible: true,
                gxztms: res.statusDescribe
            })
        })

    }
    render() {
        const {
            showLsModel, projectHistory, showGlcyModel, oneLevelTaskDynamicVoKeyList, orgTreeNodeList, userListAll, personnelCount,
            infoProject, infoProjectPanel, xmszDq, showhide, showhideclass, gxztms, bgyiqidong, bgyiyuqi, bgyiguidang, selectedUserRow,
            wordcoloryiqidong, wordcoloryiguaqi, wordcoloryiyuqi, wordcoloryiguidang, bgyiguaqi,projectUserList,queryCatgory
        } = this.state
        const {getFieldDecorator} = this.props.form;
        // let projectCode;
        let wordcolor;
        let bg;
        if (infoProject.projectStatus == 1) {
            wordcolor = wordcoloryiqidong
            bg = bgyiqidong
        }
        if (infoProject.projectStatus == 2) {
            wordcolor = wordcoloryiyuqi
            bg = bgyiyuqi
        }
        if (infoProject.projectStatus == 3) {
            wordcolor = wordcoloryiguaqi
            bg = bgyiguaqi
        }
        if (infoProject.projectStatus == 4) {
            wordcolor = wordcoloryiguidang
            bg = bgyiguidang
        }
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.state.selectedUsers = selectedRowKeys;
                let selected = userListAll.filter(i => selectedRowKeys.includes(i.id));
                this.state.selectedUserRow = selected;
                let count = selected.length;
                this.setState({
                    selectedUsers: selectedRowKeys,
                    selectedUserRow:this.state.selectedUserRow,
                    personnelCount: count,
                })
            },

        };


        return (
            <div className='xmgl-gaikuang'>
                <div className={'left'}>
                    <div className={'left-xmzt'} style={{height: infoProject.projectStatus==4 ? '200px' : '170px'}}>
                        {infoProject.projectStatus==4 ?
                            <div className={'guidangtip'}>
                                <Icon type="exclamation-circle" theme="filled" />
                                <span>{infoProject.humanName}归档了该建设任务，当前状态下不可编辑，如需编辑请激活该建设任务</span>
                            </div> : ''
                        }

                        <div className={'xmgl-title'}>建设任务状态
                            <div className={'rightshezhi'}>
                                <span onClick={() => this.showLsModel()}>查看历史</span>
                                <div className={'line'}></div>
                                {infoProject.projectStatus==4 ?<span disabled={true} style={{cursor: 'not-allowed'}}>更新状态描述</span>:<span onClick={() => {
                                   this.gengxinmiaoshu();
                                }}>更新状态描述</span>}

                            </div>
                        </div>
                        <div className={bg}>
                            <span className={wordcolor}>
                                {infoProject.projectStatus ?
                                    infoProject.projectStatus == 1 ? "已启动"
                                        : infoProject.projectStatus == 2 ? "已逾期"
                                        : infoProject.projectStatus == 3 ? "已挂起"
                                            : infoProject.projectStatus == 4 ? "已归档"
                                                : ""
                                    : ""}</span>
                            <div className={'line'}></div>
                            <ul className={'hegeul'}>
                                {infoProject.statusDescribe?infoProject.statusDescribe.length>49?<Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={infoProject.statusDescribe ? infoProject.statusDescribe : '定期更新建设任务状态，增进成员对建设任务的了解'}><li>{infoProject.statusDescribe ? infoProject.statusDescribe : '定期更新建设任务状态，增进成员对建设任务的了解'}</li></Tooltip>
                               :<li>{infoProject.statusDescribe}</li>:<li>定期更新建设任务状态，增进成员对建设任务的了解</li>}
                                <li>
                                    <span className={'name'}>{infoProject.humanName}</span>
                                    <span>{infoProject.statusDescribeTime}</span>
                                </li>
                            </ul>
                            {infoProject.projectStatus ?
                                infoProject.projectStatus == 1 ?
                                    <img src={require(`@/assets/imgnew/yiqidong1.png`)} className='xmtype'/>
                                    : infoProject.projectStatus == 2 ?
                                    <img src={require(`@/assets/imgnew/yiyuqi1.png`)} className='xmtype'/>
                                    : infoProject.projectStatus == 3 ?
                                        <img src={require(`@/assets/imgnew/yiguaqi1.png`)} className='xmtype'/>
                                        : infoProject.projectStatus == 4 ?
                                            <img src={require(`@/assets/imgnew/yiguidang1.png`)} className='xmtype'/>
                                            : ""
                                : ""}
                        </div>
                    </div>
                    <div className={'left-xmzt left-xmkb'}>
                        <div className={'xmgl-title'}>建设任务看板</div>
                        <div className={'tubiao'}>
                            <div className={'echarts'}>
                                <div id={'wanchenglv'} className={'div'}></div>
                            </div>
                            <div className={'echarts'}>
                                <div id={'yuqilv'} className={'div'}></div>
                            </div>
                        </div>
                        <ul className={'tongji'}>
                            <li>
                                <div className={'clickcont'} onClick={()=>this.tiaozhuanrwlist(0)}>
                                    <div className={'iconwarp'}>
                                        <img src={require('@/assets/imgnew/quanbu.png')}/>
                                    </div>
                                    <div className={'tjright'}>
                                        <span>全部</span>
                                        <span className={'number'}>{infoProjectPanel.allCount}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={'clickcont'} onClick={()=>this.tiaozhuanrwlist(1)}>
                                    <div className={'iconwarp'}>
                                        <img src={require('@/assets/imgnew/weiwancheng.png')}/>
                                    </div>
                                    <div className={'tjright'}>
                                        <span>未完成</span>
                                        <span className={'number'}>{infoProjectPanel.processingCount}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={'clickcont'} onClick={()=>this.tiaozhuanrwlist(3)}>
                                    <div className={'iconwarp'}>
                                        <img src={require('@/assets/imgnew/yiwancheng.png')}/>
                                    </div>
                                    <div className={'tjright'}>
                                        <span>已完成</span>
                                        <span className={'number'}>{infoProjectPanel.completedCount}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={'clickcont'} onClick={()=>this.tiaozhuanrwlist(4)}>
                                    <div className={'iconwarp'}>
                                        <img src={require('@/assets/imgnew/yiyuqi.png')}/>
                                    </div>
                                    <div className={'tjright'}>
                                        <span>已逾期</span>
                                        <span className={'number'}>{infoProjectPanel.expiredCount}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={'left-xmzt left-dongtai'}>
                        <div className={'xmgl-title'}>建设任务动态</div>
                        <ul className={'dtlist'}>
                            {
                                oneLevelTaskDynamicVoKeyList && oneLevelTaskDynamicVoKeyList.map((item, index) => (
                                    <li>
                                        <span className={'left-time'}>{item.date}</span>
                                        <span className={'yuan'}>
                                    <i></i>
                                </span>
                                        <ul className={'right-sj'}>
                                            {item.taskDynamicVoList && item.taskDynamicVoList.map((item2, index) => (
                                                <li>
                                                    <span className={'right-name'}>{item2.editorName}</span>
                                                    <span className={'right-action'}>{item2.action}</span>
                                                    {item2.describe?(item2.describe+item2.describeDetail).length>40?<Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={item2.describe+(item2.describeDetail?item2.describeDetail:'')}>
                                                        <span className={'right-message'}>{item2.describe}{item2.describeDetail}</span></Tooltip>:<span className={'right-message'}>{item2.describe}{item2.describeDetail}</span>:""}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>

                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className={'right'}>
                    <div className={'right-xmxx'}>
                        <div className={'xmgl-title'}>建设任务信息
                            <div className={'rightshezhi'} onClick={() => {
                                this.shezhi()
                            }}>
                                <img src={require('@/assets/imgnew/shezhi.png')} className={'img-shezhi'}/>
                                <span>建设任务设置</span>
                            </div>
                        </div>
                        <div className={'xmxx-cont'}>
                            <div className={'xmxx-top'}>
                                <span className={'num'}>{infoProject.projectCode}</span>
                                {infoProject.projectName?infoProject.projectName.length>20?
                                <Tooltip overlayClassName={'gaikuang-tooltip'} placement="bottomLeft" title={infoProject.projectName}><span className={'htitle'}>{infoProject.projectName}</span></Tooltip>
                                    :<span className={'htitle'}>{infoProject.projectName}</span>:""}
                            </div>
                            {queryCatgory===1?<span className={'tip'}>{infoProject.projectType ?
                                infoProject.projectType === '1' ? "学校" : infoProject.projectType === '2' ? "专业群一" :infoProject.projectType === '3' ? "专业群二" : infoProject.projectType === '4' ? "其他" : "" : ""

                            }</span>:<span className={'tip'}>{infoProject.projectType ?
                                infoProject.projectType === '1' ? "学校" : infoProject.projectType === '2' ? "专业群" : infoProject.projectType === '4' ? "其他" : "" : ""
                            }</span>}
                            <div className={showhideclass}>
                                {infoProject.projectDescribe?infoProject.projectDescribe:'待添加'}
                            </div>
                            <span>{infoProject.projectDescribe?infoProject.projectDescribe.length > 220?
                                <span className={'showmore'} onClick={() => this.showmore()}>{showhide}</span>:
                                "":""}</span>
                            <div className={'rcgh'}>
                                <div className={'rctitle'}>
                                    <span></span>
                                    日程规划
                                </div>
                                <ul className={'rcghtime'}>
                                    {infoProject.beginTime==null? <li>
                                            <span>开始时间：--</span>
                                            <span></span>
                                        </li>:
                                    <li>
                                        <span>开始时间：{infoProject.beginTime}</span>
                                        <span></span>
                                    </li>}
                                    {infoProject.endTime==null?
                                    <li>
                                        <span>结束时间：</span>
                                        <span>--</span>
                                    </li> :
                                        <li>
                                            <span>结束时间：</span>
                                            <span>{infoProject.endTime}</span>
                                        </li>}
                                </ul>
                            </div>
                            <div className={'rcgh'}>
                                <div className={'rctitle'}>
                                    <span></span>
                                    建设任务负责人
                                </div>
                                <ul className={'fzrlist'}>
                                    {infoProject.portraitId==null?<li>
                                        <span className={'fzrname'}>待认领</span>
                                    </li>:
                                        <li><img className='usericon' src={`${window.$$imgSrc(infoProject.portraitId)}`}  className={'touxiang'}/>
                                        <span className={'fzrname'}>{infoProject.directorName}</span>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'right-xmxx'}>
                        <div className={'xmgl-title'}>建设任务组成员
                            <div className={'rightshezhi'} onClick={() => this.member()}
                            >
                                <img src={require('@/assets/imgnew/shezhi.png')} className={'img-shezhi'}/>
                                <span>管理成员</span>
                            </div>
                        </div>
                        <ul className={'chengyuanlist'}>
                            {infoProject.userProjectVo && infoProject.userProjectVo.map((item, index) => (
                                <li>
                                    <img src={`${window.$$imgSrc(item.portraitId)}`}  className={'touxiang'}/>
                                    <span className={'cyname'}>{item.humanName}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/*查看历史弹窗*/}
                <Modal
                    title={'历史建设任务状态'}
                    visible={showLsModel}
                    width={660}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    className={'lsztModal'}
                    footer={
                        <div className='modal_btn_j'>
                            <Button type='primary' onClick={() => this.setState({showLsModel: false})}>关闭</Button>
                        </div>
                    }
                >
                    <ul className={'slztlist'}>
                        {projectHistory.length > 0 ? projectHistory.map((item) => (
                                item.projectStatus ?
                                    item.projectStatus == 1 ?
                                     <li className={'yiqidong'}>
                                        <img className={'yuan'} src={require('@/assets/imgnew/yiqidongyuan.png')}/>
                                        <div className={'ztright'}>
                                            <span className={'type'}>已启动</span>
                                            <span className={'miaoshu'}>{item.projectStatusDescribe}</span>
                                            <span className={'tjtime'}>{item.editorName}  {item.createTime}</span>
                                        </div>
                                     </li>
                                        : item.projectStatus == 2 ?
                                     <li className={'yiyuqi'}>
                                        <img className={'yuan'} src={require('@/assets/imgnew/yiyuqiyuan.png')}/>
                                        <div className={'ztright'}>
                                            <span className={'type'}>已逾期</span>
                                            <span className={'miaoshu'}>{item.projectStatusDescribe}</span>
                                            <span className={'tjtime'}>{item.editorName}  {item.createTime}</span>
                                        </div>
                                     </li>
                                        : item.projectStatus == 3 ?
                                    <li className={'yiguaqi'}>
                                        <img className={'yuan'} src={require('@/assets/imgnew/yiguaqiyuan.png')}/>
                                        <div className={'ztright'}>
                                            <span className={'type'}>已挂起</span>
                                            <span className={'miaoshu'}>{item.projectStatusDescribe}</span>
                                            <span className={'tjtime'}>{item.editorName}  {item.createTime}</span>
                                        </div>
                                    </li>
                                            : item.projectStatus == 4 ?
                                                <li className={'yiguidang'}>
                                                    <img className={'yuan'} src={require('@/assets/imgnew/yiguidangyuan.png')}/>
                                                    <div className={'ztright'}>
                                                        <span className={'type'}>已归档</span>
                                                        <span className={'miaoshu'}>{item.projectStatusDescribe}</span>
                                                        <span className={'tjtime'}>{item.editorName}  {item.createTime}</span>
                                                    </div>
                                                </li>  : ''
                                    : ""


                        ))
                        :
                            <div className={'empty-wrap'}>
                            <div className={'empty-img'}>
                                <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                            </div>
                            <div className={'empty-text'}>暂无数据</div>
                            </div>
                        }
                    </ul>
                </Modal>
                {/*管理成员*/}
                <Modal
                    title={'添加成员'}
                    visible={showGlcyModel}
                    width={1000}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    className={'glcyModal'}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({
                                showGlcyModel: false,
                                selectedUsers: [],
                                selectedUserRow: [],
                                personnelCount: 0,
                                orgTreeNodeList:[],
                                userList:[],
                                projectUserList:[],
                                selectProjectUserList:[],
                                projectUserListALL: [],
                            })}>取消</Button>
                            {infoProject.projectStatus==4 ?  <Button type='primary'disabled={true} className={'disabled-glcy-btn'} onClick={() => this.saveChengyuan()}>确定</Button>:
                                <Button type='primary' onClick={() => this.saveChengyuan()}>确定</Button>}

                        </div>
                    }
                >
                    <div>
                        <div className={'cyleft'}>
                            <div className={'search'}>
                                <Form>
                                    <Form.Item className={'label'} label="组织">
                                        {
                                            getFieldDecorator('zuzhi', {
                                                initialValue: ''
                                            })(
                                                <Cascader style={{width: '170px'}}
                                                          className='label'
                                                          fieldNames={{
                                                              label: 'orgName',
                                                              value: 'id',
                                                              children: 'children'
                                                          }}
                                                          options={orgTreeNodeList}
                                                          changeOnSelect
                                                          displayRender ={this.displayRender}
                                                          getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                                          placeholder="请选择组织"/>
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item>
                                        <label className={'label'}>成员</label>
                                        {getFieldDecorator('chengyuan', {})(
                                            <Input placeholder="请输入成员名称" autocomplete="off" className={'ss'} prefix={<Icon type="search"/>}/>)}
                                    </Form.Item>
                                </Form>
                                <div className={'btn-group'}>
                                    <Button className='replacement' type="" onClick={() => this.res()}>重置</Button>
                                    <Button className='about' type="danger" onClick={() => this.selectInfo()}>查询</Button>
                                </div>
                            </div>
                            <div style={{float: "left"}}>
                                <Table className='table-cy'
                                       rowSelection={{
                                           type: "checkbox",
                                           selectedRowKeys: this.state.selectedUsers,
                                           ...rowSelection,
                                       }}
                                       columns={this.state.columnsTable}
                                       dataSource={projectUserList}
                                       scroll={{y: 343}}
                                       rowKey='id'
                                       pagination={false}
                                       selectedRows={this.onChangeAll}
                                       locale={{emptyText:<><div className="Empty-conet-ss">
                                               <div className="img">
                                                   <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div></>}}
                                />
                            </div>
                        </div>
                        <div className={'cyright'}>
                            <div className={'rightTop'}>
                                已选（{personnelCount}）
                            </div>
                            <ul className={'yxcylist'}>
                                {selectedUserRow && selectedUserRow.map((item) => (
                                    <li>
                                        <img className='usericon' src={`${window.$$imgSrc(item.portraitId)}`}  className={'touxiang'}/>
                                        <span className={'mingzi'}>{item.humanName}</span>
                                        <img onClick={() => this.tianjiaCy(item)}
                                             src={require('@/assets/imgnew/cha.png')} className={'cha'}/></li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                </Modal>

                <Drawer
                    title="设置"
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    width={740}
                    className={'szDrawer'}
                    visible={this.state.shezhiVisible}

                >
                    <div className={'leftnav'}>
                        <Menu onClick={this.qiehuanNav} mode="inline" selectedKeys={[xmszDq]}>
                            <Menu.Item onSelect={this.qiehuanNav} key="建设任务信息">建设任务信息</Menu.Item>
                            <Menu.Item onSelect={this.qiehuanNav} key="建设任务操作">建设任务操作</Menu.Item>
                            <Menu.Item key="任务设置" disabled>任务设置</Menu.Item>
                            <Menu.Item key="字段时间" disabled>字段时间</Menu.Item>
                        </Menu>
                    </div>
                    <div className={'rightcont'}>
                        {xmszDq === '建设任务信息' ? <
                            Xiangmuxinxi
                                onRef ={(form)=>this.xiangmuxinxi=form}
                                onClose={this.onClose}
                                infoProjects={this.infoProject}
                                infoProjectPanel={this.infoProjectPanel}
                                infoProject={infoProject}
                                childClick={this.onClose}
                            ></Xiangmuxinxi>
                            : xmszDq === '建设任务操作' ? <Xiangmucaozuo onClose={this.onClose} infoProjects={this.infoProject}
                                                                 infoProjectPanel={this.infoProjectPanel}
                                                                 infoProject={infoProject}></Xiangmucaozuo> : ''}
                    </div>
                </Drawer>
                <Modal
                    title="更新状态描述"
                    visible={this.state.gxztvisible}
                    onOk={() => this.updateZt()}
                    centered={true}
                    onCancel={() => {
                        this.setState({gxztvisible: false,
                        })
                    }}
                    className={'gxztms'}
                >
                    <div>
                        <Input.TextArea rows={6} placeholder={'请输入状态描述'} onChange={this.onChangeztms}
                                        value={this.state.gxztms}
                                        maxLength={200} className={'ztms'}/>
                        <span className={'length'}>{gxztms?gxztms.length:0}/200</span>
                    </div>
                </Modal>
            </div>

        );
    }
}

Gaikuang = Form.create()(Gaikuang);
export default Gaikuang;
