/*专家评审查看打分*/
import React, {Component} from 'react';
import {Button, Form,Tabs,InputNumber,Popover} from 'antd';
import './index.scss';
import {
    getExpertRatingDirectory
} from '../../../../api/doublehigh';
import ComponForm from '../compon_Formshuju'
const { TabPane } = Tabs;
function getNumber(a, b) {
    var val = Number(a) + Number(b);

    if(!isNaN(parseFloat(val))) {
        val = val.toFixed(2);
    }
    return  val;
}
class LeftComponShuju extends React.Component {
    state = {
        leftList: this.props.leftList,
        onId:this.props.onId,
    };
    componentDidMount() {
        let chartBottom = document.getElementById('chart_Bottom')
        let chartBottomWith=  chartBottom&&chartBottom.offsetWidth
        let windowWidth=document.documentElement.clientWidth
        let chartRight=document.getElementById('chartRight')
        if(chartRight){
            chartRight.style.left= ( windowWidth-chartBottomWith)/2
        }
        // 左侧跟随屏幕
        // document.body.addEventListener('scroll', this.bindHandleScroll)
        window.addEventListener('scroll', this.bindHandleScroll)
        // 左侧跟随屏幕
    }
    // 左侧跟随屏幕
    bindHandleScroll = (event) => {
        let scrollTop =event.srcElement ? event.srcElement.documentElement.scrollTop : false|| window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)
        if (scrollTop > 200) {
            document.getElementById("chartRight") && document.getElementById("chartRight").setAttribute("style", "top:70px;position:fixed;left:calc(100% - 625px);");
        } else {
            document.getElementById("chartRight") && document.getElementById("chartRight").setAttribute("style", "top:0;position:relative;");
        }
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('leftSaveList3',prevProps)
    //     if ((prevProps.leftList !== this.state.leftSaveList) || prevProps.leftList.length > 0) {
    //         this.setState({
    //             leftSaveList: prevProps.leftList
    //         })
    //     }
    // }
    // componentWillMount() {
    //     this.setState({
    //         leftSaveList: this.props.leftList
    //     })
    // }
    //计算合计
    countTotal(arr, keyName) {
        let $total = 0;
        $total = arr.reduce(function (total, currentValue, currentIndex, arr){
            return currentValue[keyName] ? (total + currentValue[keyName]) : total;
        }, 0);
        return $total;
    }
    progressScoreChange = (e, item, two) => {
        const {leftList} = this.state
        for (let i = 0; i <leftList.length; i++) {
            if (leftList[i].id === item.id) {
                let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                for (let y = 0; y <expertEvaluationVoList.length; y++) {
                    if (expertEvaluationVoList[y].id === two.id) {
                        expertEvaluationVoList[y].progressScore = e
                        expertEvaluationVoList[y].totalScore = e + expertEvaluationVoList[y].horizontalScore
                    }
                }
                leftList[i].totalScore = this.countTotal(expertEvaluationVoList, 'totalScore')
            }
        }
        this.setState({
            leftList
        })
        this.props.saveLeftList(leftList)
    }
    horizontalScoreChange = (e, item, two) => {
        const {leftList} = this.state
        for (let i = 0; i <leftList.length; i++) {
            if (leftList[i].id === item.id) {
                let expertEvaluationVoList = leftList[i].expertEvaluationVoList
                for (let y = 0; y <expertEvaluationVoList.length; y++) {
                    if (expertEvaluationVoList[y].id === two.id) {
                        expertEvaluationVoList[y].horizontalScore = e
                        // expertEvaluationVoList[y].totalScore = (Number(e) + Number(expertEvaluationVoList[y].progressScore)).toFixed(2)
                        let r = /^(([1-9][0-9]+|0)\.([0-9]{1,2})$)|^(([1-9][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/;
                        if (e && expertEvaluationVoList[y].progressScore && r.test(e) && r.test(expertEvaluationVoList[y].progressScore) && r.test(e)) {
                            expertEvaluationVoList[y].totalScore = Number(e) + Number(expertEvaluationVoList[y].progressScore)
                        }
                    }
                }
                leftList[i].totalScore = (expertEvaluationVoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0))
            }
        }
        this.setState({
            leftList
        })
        this.props.saveLeftList(leftList)
    }
    twoLevelValue =(i, item)=>{
        this.setState({
            onId: i.indicatorLevelId
        })
        this.props.twoLevel(i, item)
    }
    saveLeftList=(value)=>{
        this.setState({
            leftList: value
        })
        this.props.saveLeftList(value)
    }

    getExpertRatingDirectory1 =() =>{
        this.props.getExpertRatingDirectory()
    }

    render() {
        const {getFieldDecorator }=this.props.form
        const {leftList,onId} = this.state
        let {isResult,userMark,expertsScore} = this.props
        console.log(leftList)
        let schoolList = null;
        let groupOneList = null;
        let groupTwoList = null;
        if (leftList !== null && leftList !== undefined) {
            let school = leftList.filter(e => e.seniorType === 1);
            let groupOne = leftList.filter(e => e.seniorType === 2);
            let groupTwo = leftList.filter(e => e.seniorType === 3);
            if (school !== null && school.length > 0) {
                schoolList = school
            }
            if (groupOne !== null && groupOne.length > 0) {
                groupOneList = groupOne
            }
            if (groupTwo !== null && groupTwo.length > 0) {
                groupTwoList = groupTwo
            }
        }
        console.log('schoolList 99',schoolList)
        return (
            <div className={'Comment_Leftwarpshuju'}>
                <div className={'list_title'}>
                    <span>指标层级</span>
                    <span>最高分</span>
                    <span>最低分</span>
                    <span>中位数</span>
                    <span>本校得分</span>
                    {/*<span>操作</span>*/}
                </div>
                <div className="Comment_LeftCompon">
                    <Form>
                        <div className={'ul_Compon'}>
                            <div className={'top_Title'}>
                                <h6>评价方面：学校层面评分（100分）</h6>
                                {/*<span>{parseFloat(schoolList ? (schoolList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2) : 0)}</span>*/}
                            </div>
                            {/*<div className={'list_title'}>*/}
                            {/*    <span>指标层级</span>*/}
                            {/*    <span>完成分</span>*/}
                            {/*    <span>水平分</span>*/}
                            {/*    <span>小计</span>*/}
                            {/*    /!*<span>操作</span>*!/*/}
                            {/*</div>*/}
                            <div className={'bor_warp'}>
                                <div className={'bottom_border'}>
                                    {
                                        schoolList&&schoolList.map((item,index)=>{
                                            return(
                                                <div className={'bottom_ul'} key={index}>
                                                    <div className={'header_top'}>
                                                        <span className={'span_first'}>{index+1}. {item.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        {/*<span className={'span_last'}>{parseFloat((item.totalScore).toFixed(2))}</span>*/}
                                                        {/*<span></span>*/}
                                                    </div>
                                                    <div className={'ul_table'}>
                                                        {
                                                            item.expertEvaluationVoList&&item.expertEvaluationVoList.map((i,k)=>{
                                                                return(<ComponForm getExpertRatingDirectory2 = {this.getExpertRatingDirectory1} expertsScore = {expertsScore} userMark={userMark} i={i} onId={onId} isResult={isResult} index={index} k={k} item={item} leftList={leftList} twoLevel={this.twoLevelValue} saveLeftList={this.saveLeftList}/>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                        </div>
                        <div className={'ul_Compon'}>
                            <div className={'top_Title'}>
                                <h6>评价方面：{groupTwoList && groupTwoList.length > 0 ? '专业群一' : '专业群'}层面评分（100分）</h6>
                                {/*<span>{parseFloat(groupOneList ? (groupOneList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2) : 0)}</span>*/}
                            </div>
                            {/*<div className={'list_title'}>*/}
                            {/*    <span>指标层次</span>*/}
                            {/*    <span>完成分</span>*/}
                            {/*    <span>水平分</span>*/}
                            {/*    <span>小计</span>*/}
                            {/*    /!*<span>操作</span>*!/*/}
                            {/*</div>*/}
                            <div className={'bor_warp'}>
                                <div className={'bottom_border'}>
                                    {
                                        groupOneList&&groupOneList.map((item,index)=>{
                                            return(
                                                <div className={'bottom_ul'} key={index}>
                                                    <div className={'header_top'}>
                                                        <span className={'span_first'}>{index+1}. {item.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        {/*<span className={'span_last'}>{parseFloat((item.totalScore).toFixed(2))}</span>*/}
                                                        {/*<span></span>*/}
                                                    </div>
                                                    <div className={'ul_table'}>
                                                        {
                                                            item.expertEvaluationVoList&&item.expertEvaluationVoList.map((i,k)=>{
                                                                return(<ComponForm getExpertRatingDirectory2 = {this.getExpertRatingDirectory1} expertsScore = {expertsScore} userMark={userMark} i={i} onId={onId} isResult={isResult}  index={index} k={k} item={item} leftList={leftList} twoLevel={this.twoLevelValue} saveLeftList={this.saveLeftList}/>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                        </div>
                        {groupTwoList && groupTwoList.length > 0 ? <div className={'ul_Compon'}>
                            <div className={'top_Title'}>
                                <h6>评价方面：专业群二层面建设评分（100分）</h6>
                                {/*<span>{parseFloat(groupTwoList ? (groupTwoList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0)).toFixed(2) : 0)}</span>*/}
                            </div>
                            {/*<div className={'list_title'}>*/}
                            {/*    <span>指标层次</span>*/}
                            {/*    <span>完成分</span>*/}
                            {/*    <span>水平分</span>*/}
                            {/*    <span>小计</span>*/}
                            {/*    /!*<span>操作</span>*!/*/}
                            {/*</div>*/}
                            <div className={'bor_warp'}>
                                <div className={'bottom_border'}>
                                    {
                                        groupTwoList&&groupTwoList.map((item,index)=>{
                                            return(
                                                <div className={'bottom_ul'} key={index}>
                                                    <div className={'header_top'}>
                                                        <span className={'span_first'}>{index+1}. {item.indicatorLevelName}</span>
                                                        <span></span>
                                                        <span></span>
                                                        {/*<span className={'span_last'}>{parseFloat((item.totalScore).toFixed(2))}</span>*/}
                                                        {/*<span></span>*/}
                                                    </div>
                                                    <div className={'ul_table'}>
                                                        {
                                                            item.expertEvaluationVoList&&item.expertEvaluationVoList.map((i,k)=>{
                                                                return(<ComponForm getExpertRatingDirectory2 = {this.getExpertRatingDirectory1} expertsScore = {expertsScore} userMark={userMark} i={i} onId={onId} isResult={isResult}  index={index} k={k} item={item} leftList={leftList} twoLevel={this.twoLevelValue} saveLeftList={this.saveLeftList}/>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                        </div> : ''}
                    </Form>
                </div>
            </div>
        );
    }
}

LeftComponShuju = Form.create()(LeftComponShuju);
export default LeftComponShuju;
