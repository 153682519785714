import { post } from '../../until/axios_instans';

//任务管理

//激活任务
export function activeTaskById(data){
    return post('/api/ss/api/v1/task/activeTaskById',data);
}
    //新增任务
export function addTask(data) {
    return post('/api/ss/api/v1/task/addTask', data);
}
    //添加 任务与文档关联
export function addTaskDocumentList(data) {
    return post('/api/ss/api/v1/task/addTaskDocumentList', data);
}
    //取消 任务与文档关联
export function cancelTaskDocumentList(data) {
    return post('/api/ss/api/v1/task/cancelTaskDocumentList', data);
}
    //删除任务
export function deleteTaskById(data) {
    return post('/api/ss/api/v1/task/deleteTaskById', data);
}

    //完成任务
export function finishTaskById(data) {
    return post('/api/ss/api/v1/task/finishTaskById', data);
}
    //开始任务
export function startTaskById(data) {
    return post('/api/ss/api/v1/task/startTaskById', data);
}
    //催办任务
export function urgeTaskById(data) {
    return post('/api/ss/api/v1/task/urgeTaskById', data);
}
    //查询建设任务面板和任务列表
export function getProjectPanelAndTaskDynamic(data) {
    return post('/api/ss/api/v1/task/getProjectPanelAndTaskDynamic', data);
}
    //查询建设任务成员列表
export function getProjectUserList(data) {
    return post('/api/ss/api/v1/task/getProjectUserList', data);
}
    //查询任务
export function getTaskById(data) {
    return post('/api/ss/api/v1/task/getTaskById', data);
}

//查询任务(简单)
export function getTaskSimpleById(data) {
    return post('/api/ss/api/v1/task/getTaskSimpleById', data);
}

    //关联文档列表
export function getTaskDocumentList(data) {
    return post('/api/ss/api/v1/task/getTaskDocumentList', data);
}
    //分页查询任务列表
export function getTaskPageList(data) {
    return post('/api/ss/api/v1/task/getTaskPageList', data);
}

//查询任务下一级列表
export function getTaskOneLevelList(data) {
    return post('/api/ss/api/v1/task/getTaskOneLevelList', data);
}

    //查询任务关联建设任务的成员列表
export function getTaskProjectMemberList(data) {
    return post('/api/ss/api/v1/task/getTaskProjectMemberList', data);
}
    //查询任务动态列表
export function getTaskzDynamicList(data) {
    return post('/api/ss/api/v1/task/getTaskzDynamicList', data);
}
    //暂停任务
export function stopTaskById(data) {
    return post('/api/ss/api/v1/task/stopTaskById', data);
}
    //修改任务
export function updateTaskById(data) {
    return post('/api/ss/api/v1/task/updateTaskById', data);
}
    //修改任务描述
export function updateTaskDescribeById(data) {
    return post('/api/ss/api/v1/task/updateTaskDescribeById', data);
}
    //修改任务负责人
export function updateTaskDirectorById(data) {
    return post('/api/ss/api/v1/task/updateTaskDirectorById', data);
}
    //修改任务优先级
export function updateTaskPriorityById(data) {
    return post('/api/ss/api/v1/task/updateTaskPriorityById', data);
}

//变更为独立任务
export function modifyChildTaskToSingleTask(data) {
    return post('/api/ss/api/v1/task/modifyChildTaskToSingleTask', data);
}
//查询任务列表（不分页、非树结构）
export function getTaskAllListByProject(data) {
    return post('/api/ss/api/v1/task/getTaskAllListByProject', data);
}

//文档列表
export function getOneLevelDocumentList(data) {
    return post('/api/ss/api/v1/task/getOneLevelDocumentList', data);
}

//转化任务
export function taskTransfer(data) {
    return post('/api/ss/api/v1/task/taskTransfer', data);
}

//下一级文档列表
export function getNextLevelDocumentList(data) {
    return post('/api/ss/api/v1/task/getNextLevelDocumentList', data);
}

//添加 任务与绩效关联
export function addTaskMeritList(data) {
    return post('/api/ss/api/v1/task/addTaskMeritList', data);
}

//取消 任务与绩效关联
export function cancleTaskMerit(data) {
    return post('/api/ss/api/v1/task/cancleTaskMerit', data);
}

//查询 任务与绩效关联列表
export function seclectTaskMeritList(data) {
    return post('/api/ss/api/v1/task/seclectTaskMeritList', data);
}

//查询 任务与绩效关联列表，排除已经关联的
export function seclectTaskMeritListExcludeExist(data) {
    return post('/api/ss/api/v1/task/seclectTaskMeritListExcludeExist', data);
}

//添加 任务参与人
export function addTaskUserList(data) {
    return post('/api/ss/api/v1/task/addTaskUserList', data);
}

//移除 任务参与人
export function cancleTaskUser(data) {
    return post('/api/ss/api/v1/task/cancleTaskUser', data);
}

//查询 任务参与人
export function selectTaskUserList(data) {
    return post('/api/ss/api/v1/task/selectTaskUserList', data);
}

//查询 任务参与人，排除已经关联的
export function selectTaskUserListExcludeExist(data) {
    return post('/api/ss/api/v1/task/selectTaskUserListExcludeExist', data);
}


//任务详情-查询全部评论
export function queryTaskComments(data) {
    return post('/api/ss/api/v1/comment/queryTaskComments', data);
}

//新增评论
export function addComment(data) {
    return post('/api/ss/api/v1/comment/addComment', data);
}

//删除评论
export function deleteComment(data) {
    return post('/api/ss/api/v1/comment/deleteComment', data);
}

//获取层面和指标
export function getAspectAndIndicator() {
    return post('/api/ss/api/v1/task/getAspectAndIndicator');
}

//获取任务预算和支出
export function getTaskInvestAndExpendByTaskId(data) {
    return post('/api/ss/api/v1/task/getTaskInvestAndExpendByTaskId', data);
}

//获取任务预算和支出
export function queryTaskCommentImages(data) {
    return post('/api/ss/api/v1/comment/queryTaskCommentImages', data);
}

//任务导入数据检查
export function taskExcelImportCheck(projectId,data) {
    return post('/api/ss/api/v1/excel/taskExcelImportCheck/'+projectId, data);
}

//任务导入
export function taskExcelImport(projectId,data) {
    return post('/api/ss/api/v1/excel/taskExcelImport/'+projectId, data);
}
//查询评论byid
export function queryComment(data) {
    return post('/api/ss/api/v1/comment/queryComment/', data);
}











