/*数据大屏任务与绩效进展走势*/
import React, {Component} from "react";
import {
    Form
} from "antd";
import {withRouter} from "react-router";
import * as echarts from "echarts";
import {meritIndicatorsQuery} from "../../../api/chart";

@withRouter
class CompliancePerformanceIndicators extends Component {
    state = {
        meritIndicatorsQueryList:[],
        name:[],
        indicatorsNum:[],
        notIndicatorsNum:[],
        exceedIndicatorsNum:[],
        schoolId: this.props.match.params.schoolId,
    }

    componentDidMount() {
        this.props.fromMerit(this)
        // this.onChangeMeritTab()

    }
    onChangeMeritTab = async () => {
        let data ={
            type:this.props.type,
            code:0,
            chartId:this.props.chartMeritComplianceId,
            chartType:this.props.chartMeritComplianceType,
            schoolId: this.state.schoolId,
            defaultType:1
        }
        await meritIndicatorsQuery(data).then(e => {
            let name=[]//名称
            let indicatorsNum=[]//指标数
            let notIndicatorsNum=[]//未完成指标数
            let exceedIndicatorsNum=[]//超预期指标数
            e.expendList.map(item=>{
                name.push(item.name.slice(0,-2))
                indicatorsNum.push(item.indicatorsNum)
                notIndicatorsNum.push(item.notIndicatorsNum)
                exceedIndicatorsNum.push(item.exceedIndicatorsNum)
            })
            this.setState({
                name:name,
                indicatorsNum:indicatorsNum,
                notIndicatorsNum:notIndicatorsNum,
                exceedIndicatorsNum:exceedIndicatorsNum,
            },()=>{
                this.compliancePerformanceIndicators()
            })


        })


    }
    compliancePerformanceIndicators = () => {
        if(document.getElementById('compliancePerformanceIndicators')) {
            let data1 = this.state.indicatorsNum
            let data2 = this.state.notIndicatorsNum
            let data3 = this.state.exceedIndicatorsNum
            let myChart = echarts.init(document.getElementById('compliancePerformanceIndicators'));
            const rich = {

                b: {

                    fontSize: 18,

                    fontWeight: 'bold'

                },

                n: {

                    fontSize: 14,

                    lineHeight: 20

                }

            }
            const makeMultiLine = (str, charQty) => {

                const strs = str.split('')

                const len = strs.length

                if (len < charQty + 1) {

                    return str

                }

                let result = ''

                strs.forEach((_, index) => {

                    result += _

                    if ((index + 1) % charQty === 0 && index < len - 1) {

                        result += '\n'

                    }

                })

                return result

            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#FE5578','#1CD1A1','#59BCFB'],
                grid: {
                    left: '10%',
                    top: 20,
                    bottom: 40
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['数量','质量','时效','成本','社会效益','可持续影响','满意度'],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisTick: {
                                show: false
                        },
                        axisLabel: {

                            formatter: function (val) {

                                let str = makeMultiLine(val, 5) // 每 5 个字符一行

                                return `{n|${str}}` // 使用 rich 中的 n 来设置样式

                            },
                            rich,
                            interval: 0, // 显示所有的 x 轴上的文字不隐藏
                            inside: false,
                            textStyle: {
                                color: '#FFFFFF',
                                fontSize:'.14rem',

                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        type: 'value',
                        axisLabel: {
                            inside: false,
                            textStyle: {
                                color: '#FFFFFF',
                                fontSize:'.14rem',

                            }
                        },
                        axisLine: {
                            show: true
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine:{
                            show:false
                        }
                    },
                ],
                series: [
                    {
                        name: '指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1,
                        barWidth: 9
                    },
                    {
                        name: '未完成指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2,
                        barWidth: 9
                    },
                    {
                        name: '超出预期完成数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data:data3,
                        barWidth: 9
                    },

                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        return (
            <div id={'compliancePerformanceIndicators'} className={'echarts'} style={{width: '100%', height: '100%'}}></div>

        );
    }
}

CompliancePerformanceIndicators = Form.create()(CompliancePerformanceIndicators);
export default CompliancePerformanceIndicators;
