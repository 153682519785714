/*eslint eqeqeq: ["off", "smart"]*/
// 第二页2020年度预算
import React, {Component} from 'react';
import {Row, Col, Button, Form} from 'antd';
import './index.scss';
import '../styles/global.scss';
import Footer from '../components/footer';
import LeftMeinlist from '../components/Left-mein';
import { schoolTaskAndProcess} from '@/api/synchrodatment/';
class Specialtygroupscheduletwo extends Component {
    state = {
        leftCatalogList: [],
        taskId:'',
        schoolId: "",
        category: 0,
        upSeniorId: "4.2"
    };

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            taskId: jsonparam.taskId,
            schoolId: jsonparam.schoolId,
            category: jsonparam.category
        },() =>{
            this.schoolTaskAndProcess();
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    schoolTaskAndProcess = async () => {
        let {taskId, upSeniorId} = this.state;
        let leftCatalogListObj = await schoolTaskAndProcess({taskId: taskId, upSeniorId: upSeniorId});
        this.setState({leftCatalogList: leftCatalogListObj.leftCatalogList})
    }

    // 预览第3页
    thirdPreviewPage = () => {
        let {taskId, schoolId,category} = this.state;
        let param = {taskId: taskId, schoolId: schoolId,category:category};
        this.props.history.push({
            pathname: '/SpecialtyGroupThreebudgetTwo',
            search: JSON.stringify(param)
        })
    }

    render() {
        const {leftCatalogList, taskId, schoolId,category,upSeniorId} = this.state;
        const list = leftCatalogList.map((item, i) => (
          <li key={i}>
              <h3>{item.seniorName}</h3>
              {item.status === 2 ? item.subTaskList.map((subTask, j) => (
                <div key={j}>
                    <p>{subTask.subtaskName}</p>
                    <div className="text">
                        {subTask.saveSubTaskYearVoList.map((subTaskYear, k) => (
                          <div className="cont" key={k}>
                              <h4>{subTaskYear.yearName}</h4>
                              <div className="p">{subTaskYear.itemDescription}</div>
                          </div>
                        ))}
                    </div>
                </div>
              )):<div className="Empty-content">
                  <div className="text">
                      <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                      <span>暂无数据</span>
                  </div>
              </div>}
          </li>
        ));
        return (
            <div className="Comment-SchoolSchedule main-bg" style={{minHeight:"1200px"}}>
                {/*顶部star*/}
                {/*<Header/>*/}
                <div className="Task-bottom clearfix">
                    <div className="main-cont" id="main-cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={7} className="left" id="leftmain" style={{"top":'100px'}}>
                                    {taskId && <LeftMeinlist taskId={taskId} schoolId={schoolId} upSeniorId={upSeniorId} category={category}/>}
                                </Col>
                                <Col span={16} className="right">
                                    <div className="project-budget">
                                        <div className="top-right">
                                            <h6>任务书预览页</h6>
                                            <span><i><b>12</b>/{category==="1"?16:11}</i></span>
                                        </div>
                                        <h2>3-2 专业群二建设任务与进度</h2>
                                        <ul>
                                            {list}
                                        </ul>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" onClick={()=> this.thirdPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

Specialtygroupscheduletwo = Form.create()(Specialtygroupscheduletwo);
export default Specialtygroupscheduletwo;

