// 资金管理
import React, {Component} from "react";
import {Form, Tabs,Radio,Table,Progress } from "antd";
import {withRouter} from "react-router";
import style from './index.module.scss'
import * as echarts from 'echarts';
import {
    selectExpendAllList,

} from '../../../../api/fund/';
import {infoProjectById} from "../../../../api/project";
import {Xiazai} from "../../../../assets/svg";
import RenderButton from "../../../../components/renderButton";
import Cookies from "js-cookie";
const { TabPane } = Tabs;
@withRouter
class Moneyoverview extends Component {
    state = {
        activeKey: 1,
        columns:[
            {
                title: '经费类型',
                dataIndex: 'capitalType',
                key: 'capitalType',
                align: 'center',
                width: 200,
            },
            {
                title: '预算金额(元)',
                dataIndex: 'investAmount',
                key: 'investAmount',
                align: 'center',
                width: 200,
                render: (text,record) => {
                    return (<div>{<span>{record.investAmount ? record.investAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }

            },
            {
                title: '到位金额(元)',
                dataIndex: 'investAmount',
                key: 'investAmount',
                align: 'center',
                width: 200,
                render: (text,record) => {
                    return (<div>{<span>{record.investAmount ? record.investAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }

            },
            {
                title:'实际使用金额(元）',
                dataIndex: 'expendAmount',
                key: 'expendAmount',
                align: 'center',
                width: 200,
                render: (text,record) => {
                    return (<div>{<span>{record.expendAmount ? record.expendAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                        : 0} </span>}</div>)
                }
            },
            {
                title: '比例',
                dataIndex: 'proportion',
                key: 'proportion',
                align: 'center',
                width: 200,
                render: (text,record) => {
                    return (<div>{<span>{text}%</span>}</div>)
                }

            },
            {
                title: '资金执行率',
                dataIndex: 'implementationRate',
                key: 'implementationRate',
                align: 'center',
                width: 200,
                render: (text,record) => {
                    return (<div>{<span>{text}%</span>}</div>)
                }
            },
        ],
        dataSource:[
            {
                payName:'中央财政投入资金（元）',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },
            {
                payName:'地方各级财政投入资金（元）',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },
            {
                payName:'举办方投入资金（元）',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },
            {
                payName:'行业企业支持资金（元）',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },
            {
                payName:'学校自筹资金（元）',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },
            {
                payName:'总计',
                payType:'645.547',
                payMoeny:'645.547',
                payDate:"56%",
                payAmount:'56%',
            },

        ],
        selectExpendAllList:[],
        capitalTypeList:[],
        investAmount:[],//预算资金
        investAmountAll:[],
        expendAmount:[],//实际金额
        proportion:[],//比例
        implementationRate:[],//资金执行率
        remainingAmount:[],//剩余金额

        year:'',
    }

    componentDidMount() {
        this.selectExpendAllList()

    }

    //查询资金总览
    selectExpendAllList = async () => {
        let data = {
            year:this.state.year,
        }
        await selectExpendAllList(data).then(res => {
            this.setState({
                selectExpendAllList: res,
                //预算金额
                investAmount: res.map(i=>i.investAmount),
                expendAmount:res.map(i=>i.expendAmount),
                proportion:res.map(i=>i.proportion),
                implementationRate:res.map(i=>i.implementationRate),
                remainingAmount:res.map(i=>i.remainingAmount),
            })
        })
        this.estimatedAmount()
        this.actualAmountUsed()
        this.balance()

    }
    tabClickHandler = (params) =>{

    }

    onChange = (e) => {
        this.state.year=e.target.value
        this.selectExpendAllList()
        console.log(`radio checked:${e.target.value}`);
    }
    //预计金额柱状图
    estimatedAmount = () => {
        let data = [this.state.investAmount[0],this.state.investAmount[1],this.state.investAmount[2],this.state.investAmount[3],this.state.investAmount[4]]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('estimatedAmount')){
           let myChart = echarts.init(document.getElementById('estimatedAmount'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data:["中央财政投入资金", "地方各级财政投入资金", "举办方投入资金", "行业企业支持资金", "学校自筹资金"],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //实际使用金额柱状图
    actualAmountUsed = () => {
        let data = [this.state.expendAmount[0],this.state.expendAmount[1],this.state.expendAmount[2],this.state.expendAmount[3],this.state.expendAmount[4]]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('actualAmountUsed')){
            let myChart = echarts.init(document.getElementById('actualAmountUsed'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data:["中央财政投入资金", "地方各级财政投入资金", "举办方投入资金", "行业企业支持资金", "学校自筹资金"],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //剩余金额柱状图
    balance = () => {
        let data = [this.state.remainingAmount[0],this.state.remainingAmount[1],this.state.remainingAmount[2],this.state.remainingAmount[3],this.state.remainingAmount[4]]
        let data1 = []
        data.map(item=>{
            let newdata=0
            if(item < 0){
                newdata = -item
            }else{
                newdata = item
            }
            data1.push(newdata)
        })
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('balance')){
            let myChart = echarts.init(document.getElementById('balance'));
            // 绘制图表
            let option = {
                color: ["#40a9ff"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data:["中央财政投入资金", "地方各级财政投入资金", "举办方投入资金", "行业企业支持资金", "学校自筹资金"],
                    show: false,
                    position: 'bottom',
                    offset: 0
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (farams){
                                if(data[farams.dataIndex] > 0){
                                    return '#40a9ff'
                                }else{
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    //资金总览Excel导出
    fundsOverview=()=>{
        console.log('导出年度绩效成果指标pdf')
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const url = window.location.href
        let baseUrl = url.split("/#")[0];
        window.open(baseUrl+'/api/ss/api/v1/excel/fundsOverviewExcel/'+schoolInfo.schoolId)
    }

    render() {
        const {columns,dataSource,activeKey,selectExpendAllList,} =this.state;

        return (
            <div className={style.Moneyoverview}>


                <ul className={'echartswrap'}>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>预算金额(元)</span>
                            <span className={'textone'}>￥{this.state.investAmount[5]?this.state.investAmount[5].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0
                            }</span>
                            <div id={'estimatedAmount'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>实际使用金额(元)</span>
                            <span className={'textone'}>￥{this.state.expendAmount[5]?this.state.expendAmount[5].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'):0
                            }</span>
                            <div id={'actualAmountUsed'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>剩余金额(元)</span>
                            <span className={'textone'}>￥{this.state.remainingAmount[5]?this.state.remainingAmount[5].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            :0}</span>
                            <div id={'balance'} className={'echarts'}></div>
                        </div>
                    </li>
                    <li>
                        <div className={'licont'}>
                            <span className={'text'}>资金执行率</span>
                            <span className={'textone textred'}>{this.state.implementationRate[5]}%</span>
                            <Progress percent={this.state.implementationRate[5]} className={'progress'} strokeWidth={'10px'} strokeColor={'#40a9ff'} showInfo={false}/>
                        </div>
                    </li>
                </ul>


                <div className={'mv_arrangeUse_content'}>
                    <div className={'mac_top'}>
                        <div className={'mac_title'}>
                            <p></p><h3>预算安排及使用情况</h3>
                        </div>
                        <RenderButton id='SS-01-B08-c_btnDownPerformance'>
                            <div className={'mac_title_right'} onClick={() => this.fundsOverview()}>
                                <span style={{float:'left'}}><Xiazai/></span>
                                <p>资金总览</p>
                            </div></RenderButton>
                    </div>
                    <div className={'mac_tabs'}>
                        <Radio.Group onChange={this.onChange} defaultValue="a">
                            <Radio.Button value=""><span className={'tabs_style'}>总计</span></Radio.Button>
                            <Radio.Button value="2019"><span className={'tabs_style'}>2019年</span></Radio.Button>
                            <Radio.Button value="2020"><span className={'tabs_style'}>2020年</span></Radio.Button>
                            <Radio.Button value="2021"><span className={'tabs_style'}>2021年</span></Radio.Button>
                            <Radio.Button value="2022"><span className={'tabs_style'}>2022年</span></Radio.Button>
                            <Radio.Button value="2023"><span className={'tabs_style'}>2023年</span></Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className={'mac_table'}>
                        <Table
                            columns={columns} dataSource={selectExpendAllList}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

Moneyoverview = Form.create()(Moneyoverview);
export default Moneyoverview;
