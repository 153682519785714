/*关联文档*/
import React, {Component} from "react";
import {
    Tree ,Button, Table, Row, Input, Col, Select, Modal, Form, message, Spin, Icon, Tabs, Checkbox, DatePicker,Radio
} from "antd";
import "./changeChildTask.scss";
// import "../../../styles/global.less"
import {withRouter} from "react-router";
import {getTaskAllListByProject} from "../../../../../../api/renwu"
import Cookies from "js-cookie";
const {Option} = Select;

const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { Search } = Input;
// import { getList, pass } from '@/api/check/index'

@withRouter
class ChangeChildTask extends Component {
    state = {
        projectId:this.props.projectId,
        taskId:this.props.taskId,
        taskName:'',
        selectedTaskId:'',
        taskTransferType:this.props.taskTransferType,//9:转化为子任务,10:变更父任务
        // dataSource: [
        //     {
        //         key: '1',
        //         taskName: 'John Brown',
        //         directorId: 32,
        //         address: 'New York No. 1 Lake Park',
        //         tags: ['nice', 'developer'],
        //     },
        //     {
        //         key: '2',
        //         taskName: 'Jim Green',
        //         directorId: 42,
        //         address: 'London No. 1 Lake Park',
        //         tags: ['loser'],
        //     },
        //     {
        //         key: '3',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     }
        //     , {
        //         key: '4',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     },
        //     {
        //         key: '5',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     },
        //     {
        //         key: '6',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     },
        //     {
        //         key: '7',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     },
        //     {
        //         key: '8',
        //         taskName: 'Joe Black',
        //         directorId: 32,
        //         address: 'Sidney No. 1 Lake Park',
        //         tags: ['cool', 'teacher'],
        //     },
        // ],
        dataSource:[],
        columns : [

            {
                className:'columnsName',
                title: '任务名称',
                dataIndex: 'taskName',
                key: 'taskName',
                align: 'center',
                width:200
                // render: text => <a>{text}</a>,
            },
            {
                title: '负责人',
                dataIndex: 'humanName',
                key: 'humanName',
                align: 'center',
                width:200
                // render: text => <a>{text}</a>,
            }]




    }
    componentDidMount() {
        this.props.onRef(this)
        this.getTaskAllListByProject()
    }

    getTaskAllListByProject = async ()=>{
        let data = {
            id:this.state.taskId,
            projectId:this.state.projectId,
            taskName:this.state.taskName,
            type:this.state.taskTransferType
        }
        await getTaskAllListByProject(data).then(res=>{
            this.setState({dataSource:res})
        })
    }
    searchTask = (taskName)=>{
        console.log('taskName',taskName)
        // this.setState({taskName:taskName})
        this.state.taskName = taskName
        this.getTaskAllListByProject()
    }
    getValues = () => {
        if(this.state.selectedTaskId === ''){
            this.mesWarning("请选中父任务")
            return ''
        }
        return this.state.selectedTaskId;

    };







    render() {
        const {getFieldDecorator} = this.props.form;
        let {} = this.state;

        const rowSelection = {
            type:'radio',
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows,'id',selectedRows[0].id);
                this.setState({selectedTaskId:selectedRows[0].id})
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };
        return (
            <div className='changeChildTask_center' >


              <div className='contentTitle'>
                  <span>任务名称</span>
                  <Search
                      className='contentTitleR'
                      placeholder="请输入任务名称"
                      onSearch={value => this.searchTask(value)}
                      style={{ width: 200 }}
                  />
              </div>
                <div className='contentTable' >
                    <Table
                        rowClassName='TableRow'
                        rowSelection={rowSelection}
                        scroll={{ y: 220 }}
                        columns={this.state.columns}
                        dataSource={this.state.dataSource}
                        pagination={false}
                    />
                </div>



            </div>

        );
    }
}


ChangeChildTask = Form.create()(ChangeChildTask);
export default ChangeChildTask;
