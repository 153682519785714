/*学校自评报告*/
import React, {Component} from 'react';
import {Card, Row, Col, Button, Modal, Collapse, Input, message, Table, Form,InputNumber} from 'antd';
import './index.scss';
// import '../../../../styles/global.less';
import {saveBatchSchoolBuild, listSchoolBuild} from "../../../../api/doublehigh";
import {previewUrl,fillInYear} from "../../../../config/secret";
const {TextArea} =Input
class BuildingMechanism extends React.Component {
    state = {
        buildNameList:["加强党的建设","打造技术技能人才培养高地","打造技术技能创新服务平台","打造高水平专业群","打造高水平双师队伍","提升校企合作水平","提升服务发展水平",
            "提升学校治理水平","提升信息化水平","提升国际化水平"],
        buildNameGroupList:["人才培养模式创新","课程教学资源建设","教材与教法改革","教师教学创新团队","实践教学基地","技术技能平台","社会服务",
            "国际交流与合作","可持续发展保障机制"],
        schoolBuildList:[],
        // statYear:new Date().getFullYear()-1,
        statYear:2021,
        schoolEduCode:this.props.schoolEduCode,
        year: 2021,
        uplevelCatgory:this.props.seniorType,
        taskInfo: JSON.parse(localStorage.getItem("taskInfo")),
        characteristicTask: ''
    };
    componentDidMount() {
        // const data = this.props.location.search; //地址栏截取
        // const param = data.split("?")[1];
        // const codeParam = param.includes("%") ? decodeURI(param) : param;
        // const jsonparam = JSON.parse(codeParam);
        // this.setState({
        //     schoolEduCode:jsonparam.schoolEduCode,
        //     year:jsonparam.year
        // },()=>{
        //     this.listSchoolBuild();
        // })
        this.listSchoolBuild();
    }
    listSchoolBuild = () => {
        let pream = {schoolEduCode:this.state.schoolEduCode,investYear:this.state.statYear,uplevelCatgory:this.state.uplevelCatgory}
        listSchoolBuild(pream).then(res => {
            this.setState({
                schoolBuildList: res.schoolBuildVoList,
                characteristicTask: res.characteristicTask,
            })
        })
    }
    //绩效填报下载
    indicatorPdfDownload=()=>{
        const {taskInfo}=this.state
        if(taskInfo.taskId !== null && taskInfo.schoolId !== null){
            if(this.state.year!==2020){
                let iframeSrc = `${previewUrl}/api/dh/api/vi/dh/pdf/newPdfExportTaskIndicatorPreview?taskId=${taskInfo.taskId}&schoolId=${taskInfo.schoolId}&year=${this.state.year}`;
                window.open(iframeSrc)
            }else{
                let iframeSrc = `${previewUrl}/api/dh/api/vi/dh/pdf/exportTaskIndicatorPdfForManage?taskId=${taskInfo.taskId}&schoolId=${taskInfo.schoolId}`;
                window.open(iframeSrc)
            }
        }else{
            message.destroy()
            message.warning("下载失败")
        }
    }
//保存
    saveBatchSchoolBuild=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            let parmes=[]
            if (!err) {
                let investInYearQos = []
                console.log("values",values)
                this.state.buildNameList.map((item,index)=>{
                    let data=   {
                        buildName: item,
                        buildContent:values[`finishDegree${index}`],
                        buildSort:index
                    }
                    parmes.push(data);
                })
                saveBatchSchoolBuild({schoolEduCode:this.state.schoolEduCode,statYear:this.state.statYear,uplevelCatgory:this.state.uplevelCatgory,schoolBuildListSortQos:parmes}).then(res =>{
                    console.log("res==========",res)
                    message.success("保存成功")
                })
            }else{
                window.scrollTo({
                    top: 390,
                    behavior: 'smooth',
                });
            }
        })
    }
    render() {
        const {characteristicTask,buildNameList,buildNameGroupList,schoolBuildList,uplevelCatgory} = this.state;
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="Construction-BuildingMechanism">
                <Form>
                    <Row className="li-toptitle">
                        <Col span={8}>
                            <h3>学校十大建设任务</h3>
                        </Col>
                        <Col span={16}>
                            <h3>建设机制与具体措施（限制200字）</h3>
                        </Col>
                    </Row>
                    <div className="bottom-ul">
                        {/*schoolBuildList?*/}
                        {uplevelCatgory === 1 ?
                            buildNameList.map((item, index) => (
                                <Form.Item className="ro-cont">
                                    <Row className="row-li">
                                        <Col span={8} className="li-col">
                                            <div className="title"><span className='red'>*</span>{index+1}.{buildNameList[index]}</div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="text-dov">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent : '' :""}</div>
                                            <span className="span-sum">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent.length : 0 :0}/200</span>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            )) :
                            buildNameGroupList.map((item, index) => (
                                <Form.Item className="ro-cont">
                                    <Row className="row-li">
                                        <Col span={8} className="li-col">
                                            <div className="title"><span className='red'>*</span>{index+1}.{buildNameGroupList[index]}</div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="text-dov">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent : '' :""}</div>
                                            <span className="span-sum">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent.length : 0 :0}/200</span>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            ))
                        }
                        {/*{buildNameList.map((item, index) => (*/}
                        {/*    <Form.Item className="ro-cont">*/}
                        {/*        <Row className="row-li">*/}
                        {/*            <Col span={8} className="li-col">*/}
                        {/*                <div className="title"><span className='red'>*</span>{index+1}.{buildNameList[index]}</div>*/}
                        {/*            </Col>*/}
                        {/*            <Col span={16}>*/}
                        {/*                <div className="text-dov">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent : '' :""}</div>*/}
                        {/*                <span className="span-sum">{schoolBuildList.length>0?schoolBuildList[index] ? schoolBuildList[index].buildContent.length : 0 :0}/200</span>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*    </Form.Item>*/}
                        {/*))}*/}
                        <div className="bottom-text">
                            <Form.Item>
                                <Row>
                                    <Col span={24} className="li-col">
                                        <div className="title">特色任务的建设机制与具体措施(非必填项)</div>
                                    </Col>
                                    <Col span={24}>
                                        {getFieldDecorator(`characteristicTask`,{
                                            initialValue:characteristicTask,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `特色任务的建设机制与具体措施`,
                                                },
                                            ],
                                        })(<TextArea maxLength={500} className="TextArea-pic" rows={4} disabled/>)
                                        }
                                        <span className="span-sum">{characteristicTask?characteristicTask.replace(/(<\/?[^>]*>)/g, "").replace(/&nbsp;/g, '').replace(/\s+/g,"").length:0}/500</span>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

BuildingMechanism = Form.create()(BuildingMechanism);
export default BuildingMechanism;
