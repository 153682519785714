/*院校管理*/
import React, {Component} from "react";
import {
    Button, Table, Input, Select, Modal, Form, Icon, Tabs, DatePicker, Tooltip, Progress
} from "antd";
import "./index.scss";
import "../../../../styles/global.less"
import {withRouter} from "react-router";
import {activeTaskById,addTask,addTaskDocumentList,cancelTaskDocumentList,deleteTaskById,finishTaskById,getProjectPanelAndTaskDynamic,getProjectUserList,
    getTaskById,getTaskDocumentList,getTaskPageList,getTaskProjectMemberList,getTaskzDynamicList,stopTaskById,updateTaskById,updateTaskDescribeById,updateTaskDirectorById,
    updateTaskPriorityById} from "../../../../api/renwu"
import {infoProjectById} from '../../../../api/project/';
import Cookies from "js-cookie";
import RenderButton from "../../../../components/renderButton";
import BulkImport from './bulkImport'
import S from "gongyongicon";
const {Option} = Select;
const {TabPane} = Tabs;
const { TextArea } = Input;
const {RangePicker} = DatePicker;
@withRouter
class RenWu extends Component {
    state = {
        finishVisible:false,
        statYear:new Date().getFullYear(),
        stateYear:new Date().getFullYear(),
        taskId:"",
        yearName:undefined,
        beginTime: null,
        endTime: null,
        endOpen: false,
        textAreaValue:"",
        total: 0,
        pageNum: 1,
        pageSize: 20,
        projectId:this.props.match.params.id,
        projectName:'',
        projectInfo:'',
        count:0,
        projectMemberList:[],
        userId:JSON.parse(Cookies.get('ssInfo')).userInfo.id ? JSON.parse(Cookies.get('ssInfo')).userInfo.id: "",
        search:{//给接口的参数（对象）
            taskStatus:-1,//任务状态
            orderName:"",//排序名称————taskCode、taskName、endTime
            orderType:"descend"//排序类型————asc、desc
        },
        dataSource:[],//列表
        tableLoading: false,
        defaultExpandAllRows: true,
        taskPriorityOptions:[
            {'label':'较低','value':1},
            {'label':'普通','value':2},
            {'label':'紧急','value':3},
            {'label':'非常紧急','value':4},
        ],
        taskPriorityDefault:'',
        taskStatusOptions:[
            {'label':'进行中','value':1},
            {'label':'已暂停','value':2},
            {'label':'已完成','value':3},
            {'label':'已逾期','value':4},
            {'label':'未开始','value':5},
        ],
        addVisable:false,
        rwactiveKey: '0',
        statusButten:true,
        datepickerInit: [],//规划日期
        bulkImport: false,//批量导入
        yearList:[
            {
                id:1,
                yearName:'2020（含2019）',
                statYear:2020
            },
            {
                id:2,
                yearName:'2021',
                statYear:2021
            },{
                id:3,
                yearName:'2022',
                statYear:2022
            },
            {
                id:4,
                yearName:'2023',
                statYear:2023
            },
            {
                id:5,
                yearName:'2024',
                statYear:2024
            },
            // {
            //     id:6,
            //     yearName:'2025',
            //     statYear:2025
            // }
            // {
            //     id:7,
            //     yearName:'2026,
            //     statYear:2026
            // }
            // {
            //     id:8,
            //     yearName:'2027',
            //     statYear:2027
            // }
            // {
            //     id:9,
            //     yearName:'2028',
            //     statYear:2028
            // }
        ],  //年度
    }
    // 初始化
    componentDidMount() {
        //获取及设置当前年度
        let taskYear = Cookies.get('taskStateYear');
        if (taskYear === undefined || taskYear === "" || taskYear === null){
            let date = new Date();
            let fullYear = date.getFullYear();
            this.setState({statYear:fullYear,stateYear:taskYear});
        }else {
            this.setState({statYear:taskYear, stateYear:taskYear});
            Cookies.remove('taskStateYear');
        }
        this.infoProjectById()
        this.opendefaultExpandAllRows()//展开所有行数据
        if(this.props.rwtaskStatus){
            this.tabClickHandler(this.props.rwtaskStatus)
        }else{
            this.tabClickHandler(0)
        }
    }
    componentWillReceiveProps(props) {
        if(props.rwtaskStatus){
            this.tabClickHandler(props.rwtaskStatus)
        }else{
            this.tabClickHandler(0)
        }

    }
    //展开所有行数据
    opendefaultExpandAllRows=()=>{
        this.setState({defaultExpandAllRows:true})
    }
    //年度
    onChangeYear = (value) => {
        this.setState({
            stateYear: value,
            statYear: value
        },()=>this.getTaskPageList());
    };

    // region api

    infoProjectById = async ()=>{
        await infoProjectById({id:this.state.projectId,userId:this.state.userId,}).then(res=>{
            this.setState({projectName:res.projectName,projectInfo:res})
        })
    }
    //
    getTaskPageList = async ()=>{
        let data = {
            projectId:this.state.projectId,
            taskStatus:this.state.search.taskStatus,
            orderName:this.state.search.orderName,
            orderType:this.state.search.orderType,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            startDate:this.state.datepickerInit[0]?this.state.datepickerInit[0].format('YYYY-MM-DD 00:00:01'):'',
            endDate:this.state.datepickerInit[1]?this.state.datepickerInit[1].format('YYYY-MM-DD 23:59:59'):'',
            statYear:this.state.statYear
        }
        console.log("请求参数",JSON.stringify(data));
        this.setState({tableLoading:true})
        await getTaskPageList(data).then(res=>{
            this.setState({dataSource:res,tableLoading:false,defaultExpandAllRows:true,})
            // this.setState({dataSource:res.data,total:res.total,tableLoading:false})
        })

    }
    //新增任务
    addTask = async (data)=>{
        await addTask(data).then(res=>{
            this.mesSuccess("新建任务成功")
        })
    }
    //完成任务
    finishTaskById = async (data)=>{
        console.log('finishTaskById')
        await finishTaskById(data).then(res=>{
            this.mesSuccess("完成任务成功")
            this.getTaskPageList()
        })
    }

    // 查询建设任务成员列表
    getTaskProjectMemberList = async ()=>{
        let data = {
            projectId:this.state.projectId,
        }
        await getTaskProjectMemberList(data).then(res=>{
            this.setState({projectMemberList:res})
        })
    }
    // endregion

    //跳转任务详情页
    goToTaskPage = (record)=>{
        this.props.history.push({ pathname: '/main/doublehigh/operation/'+this.state.projectId+"/"+record.id })
    }
    // 完成任务弹窗
    finishTask = (record)=>{
        this.setState({finishVisible:true,taskId:record.id})
    }

    // 完成任务确认
    finishConfirm = (record)=>{
        let data = {
            id:this.state.taskId,
            projectId:this.state.projectId
        }
        this.setState({finishVisible:false,taskId:""})
        this.finishTaskById(data).then(res=>{})

    }
    // 完成任务取消
    finishCancel = (record)=>{
        this.setState({finishVisible:false,taskId:""})
    }


    saveLastFourWei(text){
        return text.slice(1,4)
    }
    //新建or编辑组织——提示
    addtask=(record)=>{
        this.getTaskProjectMemberList()
        this.setState({addVisable:true,count:0})

    }
    //新建/编辑————取消按钮
    addHandleCancel = () =>{
        this.setState({addVisable: false,count:0})
    }
    //新建/编辑————确认按钮
    addHandleOk = () =>{
        console.log('addHandleOk')
        if (this.state.statusButten) {
            this.setState({statusButten: false})
        let taskName = this.props.form.getFieldValue('taskName')
        let taskPriority = this.props.form.getFieldValue('taskPriority')
        let taskDescribe = this.props.form.getFieldValue('taskDescribe')
        let directorId = this.props.form.getFieldValue('directorId')

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data = {
                    projectId:this.state.projectId,
                    taskName : taskName,
                    taskPriority : taskPriority,
                    beginTime : this.state.beginTime?this.state.beginTime.format('YYYY-MM-DD 00:00:01'):"",
                    endTime : this.state.endTime?this.state.endTime.format('YYYY-MM-DD 23:59:59'):"",
                    directorId : directorId,
                    taskDescribe: taskDescribe,
                    statYear: this.state.statYear,
                }
                this.addTask(data).then(()=>{
                    this.setState({addVisable:false,beginTime: null,endTime: null,})
                    this.addHandleCancel()
                    this.getTaskPageList()
                })

            }

        });}
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }

    //获取文本款输入的值e是文本框，值需要再取value
    inputClick(e){
        this.state.search.orgName=e.target.value
    }


    disabledStartDate = beginTime => {
        const { endTime } = this.state;
        if (!beginTime || !endTime) {
            return false;
        }
        return beginTime.valueOf() > endTime.valueOf();
    };

    disabledEndDate = endTime => {
        const { beginTime } = this.state;
        if (!endTime || !beginTime) {
            return false;
        }
        return endTime.valueOf() <= beginTime.valueOf();
    };
    // 日期赋值
    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };
    // 开始日期
    onStartChange = value => {
        this.onChange('beginTime', value);
        let date = this.props.form.getFieldValue('date')
    };
    //结束日期
    onEndChange = value => {
        this.onChange('endTime', value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };

    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
            //列表方法
            this.selectlabel()
        })
    }
    showSizeChange = (current, size) => {
        this.setState({pageSize: size},() => {
            this.selectlabel()
        })
    }
    //查询按钮
    selectlabel = () =>{
        console.log('selectlabel')
        this.getTaskPageList();//主页数据查询

    }

    marksClick(e){
        this.setState({
            count:e.target.value.length
        })
    }

    tabClickHandler = (params) => {
        console.log('params',params)
        console.log('tabClickHandler')
        if(params === this.state.search.taskStatus){
            return false
        }
        this.state.search.taskStatus = params
        this.setState({
            rwactiveKey:params.toString(),
            pageNum: 1,
            datepickerInit:[],
        },()=>{
            this.getTaskPageList()
        })

    }
    //条件搜索
    onChangeConditionTable = (pagination, filters, sorter, extra)=>{
        console.log('onChangeConditionTable')
        if(sorter.order){
            this.setState({
                search:{
                    taskStatus:this.state.search.taskStatus,
                    orderName:sorter.field,
                    orderType:sorter.order,
                    pageNum:pagination.current,
                    pageSize:pagination.pageSize,

                },
                pageNum:pagination.current,
                pageSize:pagination.pageSize,
            },()=>{
                this.getTaskPageList()
            })
        }
        else{
            this.setState({
                search:{
                    taskStatus:this.state.search.taskStatus,//任务状态
                    orderName:"",//排序名称————taskCode、taskName、endTime
                    orderType:"descend",//排序类型————asc、desc
                    pageNum:pagination.current,
                    pageSize:pagination.pageSize
                },
                pageNum:pagination.current,
                pageSize:pagination.pageSize,
            },()=>{
                this.getTaskPageList()
            })
        }
    }
    //规划日期
    dateOnChangeOfPlan = (value, dateString) => {
        this.setState({datepickerInit: value})
    }
    //取消批量导入
    bulkImportCancel = ()=>{
        this.setState({bulkImport: false})
    }
    //规划日期条件查询查询
    searchTaskList = ()=>{
        console.log('searchTaskList')
        this.getTaskPageList()
    }
    // 重置
    reset = () => {
        this.setState({
            stateYear:new Date().getFullYear(),
            statYear:new Date().getFullYear(),
            yearName:'',
            pageNum: 1,
        },() => {
            this.getTaskPageList()
        })
        console.log("li111",this.state.stateYear)
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const style={position: "absolute",
            bottom: "-5px",
            right: "9px"}
        let {dataSource,columnslist,
            addVisable,count,finishVisible,
            textAreaValue,defaultExpandAllRows,taskPriorityOptions
        ,beginTime,endTime,endOpen, rwactiveKey,stateYear, bulkImport,yearList} = this.state;
        let pagination = {
            pageSizeOptions: ['20', '40', '60','80'],
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            total: this.state.total,
            current: this.state.pageNum,
            showSizeChanger: true,
        }
        const columns = [
            {
                title: '任务名称及层级', dataIndex: 'taskName', key: 'taskName', align: 'left',width: 600,
                render: (text, record) =>
                    <div className={'taskNameDiv'}>
                        {
                            record.taskName && record.taskName.length>20 ?
                                <Tooltip placement="bottomLeft" title={record.taskName} overlayClassName='TitleTooltip'>
                                    <a className={'taskName'} onClick={() => this.goToTaskPage(record)}>
                                        {
                                             record.taskLevel <= 1 ?
                                                <>
                                                    <span className="childrenStyle">年</span>{record.taskName}
                                                </> :
                                                <>
                                                    <span class="childrenStyle">子</span>{record.taskName}
                                                </>
                                        }
                                    </a>
                                </Tooltip>:
                                <a className={'taskName'} onClick={() => this.goToTaskPage(record)}>
                                    {
                                        record.taskLevel <= 1 ?
                                            <>
                                                <span className="childrenStyle">年</span>{record.taskName}
                                            </> :
                                            <>
                                                <span className="childrenStyle">子</span>{record.taskName}
                                            </>
                                    }
                                </a>
                        }
                    </div>
            },
                {
                    title: '任务ID', dataIndex: 'taskCode', key: 'taskCode',align: 'center',width: 70,
                    render: (text, record) =>{
                        return (<div>{<span>{text?text:""} </span>}</div>)
                    }

                },
                {
                    title: '优先级', dataIndex: 'taskPriority', key: 'taskPriority', align: 'center',width: 100,
                    render: (text, record) => {
                        return (<div>{this.state.taskPriorityOptions.map((item, index) => {
                            if(text === item.value){
                                return item.value==1?<span key={index} className='jiaodi'>{item.label}</span>:
                                    item.value==2?<span key={index} className='putong'>{item.label}</span>:
                                    item.value==3?<span key={index} className='jinji'>{item.label}</span>:
                                    item.value==4?<span key={index} className='feichangjinji'>{item.label}</span>:
                                        <span key={index}>{item.label}</span>
                            }})}</div>)}
                },
                {
                    title: '负责人', dataIndex: 'humanName', key: 'humanName', align: 'center',width: 130,
                    render: (text, record) =>{
                        return (<div>{ <span>{text?text:'- -'} </span>}</div>)
                    }
                },
                {
                    title: '资金预算(元)',  dataIndex: 'investAmount', key: 'investAmount', align: 'center',width: 130,
                },
                {
                    title: '任务天数',  dataIndex: 'taskDays', key: 'taskDays', align: 'center',width: 100,
                },
                {
                    title: '状态', dataIndex: 'taskStatus', key: 'taskStatus', align: 'center',width: 100,
                    render: (text, record) => {
                        return (<div>{this.state.taskStatusOptions.map((item, index) => {
                            if(text === item.value){
                                return item.value==3 ? <span key={index} className='yiwancheng'>{item.label}</span>:
                                    item.value==4 ? <span key={index} className='yiyuqi'>{item.label}</span>:
                                        <span key={index}>{item.label}</span>
                            }})}</div>)}
                },
            //20240617将年份去除掉
            //     {
            //         title: <span>{this.state.stateYear}年01月</span>, dataIndex: 'janStatus', key: 'janStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            // {
            //     title:<span>{this.state.stateYear}年02月</span>, dataIndex: 'febStatus', key: 'febStatus',align: 'center',width: 100,sorter:true,
            //     render: (text, record) =>{
            //         return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //     }
            //
            // },
            //     {
            //         title: <span>{this.state.stateYear}年03月</span>, dataIndex: 'marchStatus', key: 'marchStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title: <span>{this.state.stateYear}年04月</span>, dataIndex: 'aprilStatus', key: 'aprilStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年05月</span>, dataIndex: 'mayStatus', key: 'mayStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },{
            //         title:<span>{this.state.stateYear}年06月</span>, dataIndex: 'juneStatus', key: 'juneStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年07月</span>, dataIndex: 'julyStatus', key: 'julyStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年08月</span>, dataIndex: 'augStatus', key: 'augStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年09月</span>, dataIndex: 'sepStatus', key: 'sepStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年10月</span>, dataIndex: 'octStatus', key: 'octStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            //     {
            //         title:<span>{this.state.stateYear}年11月</span>, dataIndex: 'novStatus', key: 'novStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },{
            //         title:<span>{this.state.stateYear}年12月</span>, dataIndex: 'decStatus', key: 'decStatus',align: 'center',width: 100,sorter:true,
            //         render: (text, record) =>{
            //             return (<div>{<span>{text?text==1?"进行中":text==2?"已暂停":text == 3 ?"已完成":text == 4 ?"已逾期":text == 5 ?"未开始":text == 6 ?"已超期":"--":"--"} </span>}</div>)
            //         }
            //
            //     },
            ]
        return (
            <div className='cloumn_center'
                 style={{'background': '#f0f2f5'}}
            >

                <div className="content">
                    {
                        this.state.projectInfo.projectStatus == 4 ?
                            <div className={'guidangtip'}>
                                <Icon type="exclamation-circle" theme="filled" />
                                <span>{this.state.projectInfo.humanName} 归档了该建设任务，当前状态下不可编辑，如需编辑请激活该建设任务</span>
                            </div>: ""
                    }
                    <div className="cont-top list">
                        <div className="title">
                            <p></p>
                            <div>任务列表</div>

                        </div>
                    </div>
                    <div className="cont-top cont-top-cz" style={{display:'none'}}>
                        <div className='tabsStyle'>

                            <Tabs activeKey={rwactiveKey}  onTabClick={(params)=>this.tabClickHandler(params)}>
                                <TabPane tab="所  有" key="0">

                                </TabPane>
                                <TabPane tab="未完成" key="1">

                                </TabPane>
                                <TabPane tab="已逾期" key="4">

                                </TabPane>

                                <TabPane tab="已暂停" key="2">

                                </TabPane>
                                <TabPane tab="已完成" key="3">

                                </TabPane>
                            </Tabs>
                        </div>

                    </div>

                    <div className="cont-top cont-top-btnlist">
                        <div className={'financial_details_input'}>
                                <span className={'span_riqi'}>
                                    <label>年度</label>
                                    <Select className={'select_in'}
                                            style={{width: 230}}
                                            placeholder={new Date().getFullYear()}
                                            allowClear={true}
                                            onChange={this.onChangeYear}
                                            value={stateYear}
                                    >{
                                        yearList&&yearList.map(item =>
                                            <Option
                                                value={item.statYear}
                                                title={item.yearName}>{item.yearName}</Option>
                                        )
                                    }</Select>
                                </span>
                            <Button className={'chongzhi-btn'} onClick={() => this.reset()}>重置</Button>
                            {/*<Button type={'primary'} onClick={()=>this.searchTaskList()}>查询</Button>*/}
                        </div>
                        <RenderButton id = "SS-01-B01-b_btnAddHierarchy">
                            {
                                this.state.projectInfo.projectStatus != 4 ?
                                    <div className={'right-bn'}>
                                        {/*<Button type={'primary'} onClick={()=>this.setState({bulkImport: true})}>批量导入</Button>*/}
                                        <Button onClick={()=>{this.addtask('')}} className={'add-renwu-btn'}>新建任务</Button>
                                    </div>:
                                    <div className={'right-bn'}>
                                        {/*<Button className="add-user-disable" type={'primary'} disabled={true} style={{color:'rgba(0,0,0,0.6)'}}> 批量导入</Button>*/}
                                        <Button className="add-user-disable" disabled={true}  className={'add-renwu-btn'} style={{color:'rgba(0,0,0,0.6)'}}>新建任务</Button>
                                    </div>
                            }
                        </RenderButton>
                    </div>
                    <div className="tablRW">
                        {
                            dataSource&&dataSource.length === 1 && dataSource[0]=== null ? '' : dataSource && dataSource.length ?
                                <Table columns={columns}
                                       defaultExpandAllRows={defaultExpandAllRows}
                                       className={'renwuTable'}
                                       scroll={{ x: 1500, y: 600 }}
                                       dataSource={dataSource}
                                       pagination={false}
                                       rowKey={(r, i) => (r&&r.id)}
                                       locale={{emptyText:<><div className="Empty-conet-ss">
                                               <div className="img">
                                                   <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div></>}}
                                /> : <div className="Empty-content">
                                    <div className="text">
                                        <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                        <span>暂无数据</span>
                                    </div>
                                </div>
                        }
                    </div>
                    {/*<div className="tablRW">*/}
                    {/*        {*/}
                    {/*                <Table columns={columns}*/}
                    {/*                       className={'renwuTable'}*/}
                    {/*                       defaultExpandAllRows={defaultExpandAllRows}*/}
                    {/*                       dataSource={dataSource}*/}
                    {/*                       pagination={pagination}*/}
                    {/*                       scroll={{ x: 1500, y: 550 }}*/}
                    {/*                       rowKey={(r, i) => (r&&r.taskCode)}*/}
                    {/*                       onChange={this.onChangeConditionTable}*/}
                    {/*                       locale={{emptyText:<><div className="Empty-conet-ss">*/}
                    {/*                               <div className="img">*/}
                    {/*                                   <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>*/}
                    {/*                                   <span>暂无数据</span>*/}
                    {/*                               </div>*/}
                    {/*                           </div></>}}*/}
                    {/*                />*/}
                    {/*        }*/}

                    {/*</div>*/}
                </div>


                {/*新建任务*/}
                <Modal
                    width={680}
                    title={'新建任务'}

                    className='histy_modal_con xinjianrenwu_modal'
                    visible={addVisable}
                    destroyOnClose={true}
                    closable={false}
                    onOk={this.addHandleOk}
                    onCancel={this.addHandleCancel}
                    maskClosable={false}
                    centered={true}
                >
                    <Form labelCol={{ span: 4 }} wrapperCol={{ span: 17 }} colon={false}>
                        <Form.Item label="建设任务名称">
                            <span>{this.state.projectName}</span>
                        </Form.Item>

                        <Form.Item label="任务名称">
                            {
                                getFieldDecorator('taskName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '任务名称不能为空'
                                        },
                                        {
                                            type: 'string',
                                            min: 2,
                                            max: 200,
                                            message: '任务名称不能小于2或大于200字符!',
                                        },
                                    ]
                                })(<Input
                                    placeholder="请输入任务名称"
                                    autocomplete="off"
                                />)
                            }
                        </Form.Item>

                        <Form.Item label="优先级">
                            {
                                getFieldDecorator('taskPriority', {
                                })(<Select
                                    placeholder="请选择优先级"
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {
                                        taskPriorityOptions&&taskPriorityOptions.map(item=>

                                            <Select.Option value={item.value}>{item.label}</Select.Option>

                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label="日程规划">
                            {
                                getFieldDecorator('date', {

                                })(<div >
                                    <DatePicker
                                        style={{'display':'inline-block','width':'197px','min-width': '197px'}}
                                        disabledDate={this.disabledStartDate}
                                        format="YYYY-MM-DD"
                                        value={beginTime}
                                        placeholder="请选择日期"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                    /><span style={{'display':'inline-block','width':'38px',   'textAlign':'center'}}>至</span>
                                    <DatePicker
                                        style={{'display':'inline-block','width':'197px','min-width': '197px'}}
                                        disabledDate={this.disabledEndDate}
                                        format="YYYY-MM-DD"
                                        value={endTime}
                                        placeholder="请选择日期"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                    />
                                </div>)
                            }
                        </Form.Item>

                        <Form.Item label="负责人">
                            {
                                getFieldDecorator('directorId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '负责人不能为空'
                                        },
                                    ]

                                })(<Select
                                    defaultValue=""
                                    placeholder="请选择负责人"
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                    {
                                        this.state.projectMemberList && this.state.projectMemberList.map(item=>
                                            <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>



                        <Form.Item label="描述">
                            {
                                getFieldDecorator('taskDescribe')(
                                    <div className={'textare-wrap'}>
                                        <TextArea maxLength={500}
                                                  placeholder="请输入任务描述"
                                                  style={{resize: 'none',height:'158px'}}
                                                  onChange={(e)=>this.marksClick(e)}
                                                  rows={5}
                                                  defaultValue={textAreaValue}
                                        />
                                        <div style={style} className="user-management-count">{count}/500</div>
                                    </div>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>


                {/*完成弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={finishVisible}
                    onOk={this.finishConfirm}
                    onCancel={this.finishCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认完成该任务？</p>
                </Modal>
                {/*批量导入*/}
                <Modal
                    title="批量导入"
                    visible={bulkImport}
                    width={660}
                    closable={false}
                    className={style.bulkImportmodalwrap}
                    maskClosable={false}
                    centered={true}
                    footer={null}
                >
                    <BulkImport
                        bulkImportCancel={this.bulkImportCancel}
                        code={2}
                        projectId={this.state.projectId}
                        infoProjectById = {this.infoProjectById}
                        opendefaultExpandAllRows = {this.opendefaultExpandAllRows}
                        getTaskPageList = {this.getTaskPageList}
                    />
                </Modal>
            </div>

        );
    }
}

RenWu = Form.create()(RenWu);
export default RenWu;
