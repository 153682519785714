/*绩效指标完成度模版*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form,Tabs} from "antd";
import * as echarts from 'echarts';
import {Task,ThreeDian} from '../../../../assets/svg'
import style from '../../index.module.scss';
import Cookies from "js-cookie";
const { TabPane } = Tabs;
@withRouter
class Template5 extends Component {
    state={
        queryCatgory:0
    }
    componentDidMount() {
        this.myecharts5()
    }
    // 编辑
    bianji = (name,link) =>{
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/dataStatisticsShow') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow'}
            , {"id": '18', "menuName": '数据统计', "menuUrl": '/main/doublehigh/dataStatisticsShow',"color": '#40a9ff'}
            , {"id": '19', "menuName": name})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: link,
        })
    }
    myecharts5 = () => {
        // 基于准备好的dom，初始化echarts实例
        if(document.getElementById('myEcharts5')){
            let data1 = 12
            let data2 = 33
            let data3 = 34
            let myChart = echarts.init(document.getElementById('myEcharts5'));
            const rich = {

                b: {

                    fontSize: 18,

                    fontWeight: 'bold'

                },

                n: {

                    fontSize: 14,

                    lineHeight: 20

                }

            }
            const makeMultiLine = (str, charQty) => {

                const strs = str.split('')

                const len = strs.length

                if (len < charQty + 1) {

                    return str

                }

                let result = ''

                strs.forEach((_, index) => {

                    result += _

                    if ((index + 1) % charQty === 0 && index < len - 1) {

                        result += '\n'

                    }

                })

                return result

            }
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        indicator: [
                            { text: '产出指标',min:0,max:150},
                            { text: '满意度指标',min:0,max:150},
                            { text: '效益指标',min:0,max:150},
                        ],
                        center: ['center', 'center'],
                        radius: 100,
                        // startAngle: 90,
                        splitNumber: 5,
                        name: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#333333'
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['rgba(255,255,255, 0.2)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)',
                                    'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)'],
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204,0.3)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(204, 204, 204, 1)'
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: '雷达图',
                        type: 'radar',
                        symbol: 'none',
                        symbolSize: 0,
                        areaStyle: {
                            normal: {
                                shadowBlur: 13,
                                shadowColor: 'rgba(0,0,0,.2)',
                                shadowOffsetX: 0,
                                shadowOffsetY: 10,
                                opacity: 1
                            }
                        },
                        data: [
                            {
                                value: ['10','20','16'],
                                name: ' 一级绩效指标',
                                tooltip: {
                                    trigger: 'item'
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [{
                                                offset: 0,
                                                color: 'rgba(253, 149, 136, 0.4)'
                                            },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(253, 149, 136, 0.4)'
                                                }
                                            ],
                                            false
                                        ),
                                        opacity: 1 // 区域透明度
                                    },
                                },
                                label: {
                                    show: true,
                                    color:'rgba(0,0,0,0)',
                                    formatter: function(params) {
                                        return params.value;
                                    }
                                },
                                lineStyle:{
                                    color:'#FD9588'
                                },

                            }
                        ]
                    },
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    render() {
        const {queryCatgory} = this.state
        return (
            <div className={style.dataStatisticsShow}>
                <div className={'charts-size-wrap'}>
                    <div className={'jiduzhibiao width50'}>
                        <div className={'title'}>
                            绩效指标完成度
                            <div className={'caozuo'}>
                                <div onClick={()=>this.bianji('绩效指标完成度','/main/doublehigh/editorPerformanceindicator')}><Task/></div>
                                <ThreeDian/>
                            </div>
                        </div>
                        {queryCatgory===1?<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群一'} key="2">
                            </TabPane>
                            <TabPane  tab={'专业群二'} key="3">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>:<Tabs className='all' defaultActiveKey="" onChange={(item)=>this.onChangeTabOfMeritCompleteDu(item)}>
                            <TabPane tab={'学校'} key="1">
                            </TabPane>
                            <TabPane  tab={'专业群'} key="2">
                            </TabPane>
                            <TabPane  tab={'其他'} key="4">
                            </TabPane>
                        </Tabs>}
                        <div className={'tempalte5-echarts'} id={'myEcharts5'}></div>
                    </div>
                </div>
            </div>

        );
    }
}

Template5 = Form.create()(Template5);
export default Template5;
