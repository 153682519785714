/*eslint eqeqeq: ["off", "smart"]*/
// 预览左侧
 import React, {Component} from 'react';
 import './index.scss';
 import {withRouter} from "react-router-dom";
 import '../../styles/global.scss';
 import {lookTaskProcessList} from '../../../../../api/synchrodatment/index';
import {Form} from "antd";

@withRouter
class LeftMein extends Component {
  state = {
    lookTaskProcessObj: {},
    oneList: [],
    taskId: '',
    schoolId: '',
    ontime: "",
    category: 0,
    pathName:''
  };

  componentDidMount() {
    let mainCont = document.getElementById('main-cont')
    let mainContWith=  mainCont&&mainCont.offsetWidth
    let windowWidth=document.documentElement.clientWidth
    let leftMain=document.getElementById('leftmain')
    if(leftMain){
      leftMain.style.left= ( windowWidth-mainContWith)/2
    }

    // 左侧跟随屏幕
    document.body.addEventListener('scroll', this.bindHandleScroll)
    // 左侧跟随屏幕
    const data = this.props.location.search; //地址栏截取
    if (data) {
      const param = data.split("?")[1];
      const codeParam = param.includes("%") ? decodeURI(param) : param;
      const jsonparam = JSON.parse(codeParam);
      this.setState({
        ontime: jsonparam.processCode ? jsonparam.processCode : ''
      })

    }
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (nextProps.taskId !== this.state.taskId&&this.state.oneList.length===0) {
      this.setState({
        taskId: nextProps.taskId,
        schoolId: nextProps.schoolId,
        category: nextProps.category
      }, () => {

        this.lookTaskProcessList();
      })
      return
    }
  }

  // 左侧跟随屏幕
  bindHandleScroll = (event) => {
  }

  lookTaskProcessList = async () => {
    let {taskId} = this.state;
    let lookTaskProcessObj = await lookTaskProcessList({taskId: taskId});
    this.setState(
        {oneList: lookTaskProcessObj.oneList, lookTaskProcessObj},
        () => {
          localStorage.setItem("lookTaskProcessObj-taskId", taskId)
          localStorage.setItem("lookTaskProcessObj", JSON.stringify(lookTaskProcessObj))
        })
    if(this.state.ontime===''){
      this.setState({
        ontime:lookTaskProcessObj.oneList[0].twoList[0].processCode
      })
    }
    if(this.props.upSeniorId){
      this.setState({
        ontime:this.props.upSeniorId
      })
    }
  }

  // 预览第4页
  menuClick = (processCode) => {
    let {taskId, schoolId,category} = this.state;
    let param = {processCode: processCode, taskId: taskId, schoolId: schoolId,category:category};
    this.setState({
      ontime: processCode
    })
    // 根据 processCode 判端跳转到的位置
    // 默认如果没有对应code的话，跳转到预览第一页situationcontent
    let jumpPageRouter = "/situationcontent";
    if ("2.1" === processCode) {
      jumpPageRouter = "/situationcontent"
    } else if ("2.2" === processCode) {
      jumpPageRouter = "/schoolschedule"
    } else if ("2.3" === processCode) {
      jumpPageRouter = "/threebudget"
    } else if ("2.4" === processCode) {
      jumpPageRouter = "/fourbudget"
    } else if ("2.5" === processCode) {
      jumpPageRouter = "/addschool"
    } else if ("3.1" === processCode) {
      jumpPageRouter = "/groupcontent"
    } else if ("3.2" === processCode) {
      jumpPageRouter = "/SpecialtyGroupScheduleOne"
    } else if ("3.3" === processCode) {
      jumpPageRouter = "/SpecialtyGroupThreebudgetOne"
    } else if ("3.4" === processCode) {
      jumpPageRouter = "/SpecialtyGroupFourbudgetOne"
    } else if ("3.5" === processCode) {
      jumpPageRouter = "/SpecialtyGroupAddschoolOne"
    } else if ("4.1" === processCode) {
      jumpPageRouter = "/GroupContentTwo"
    } else if ("4.2" === processCode) {
      jumpPageRouter = "/SpecialtyGroupScheduleTwo"
    } else if ("4.3" === processCode) {
      jumpPageRouter = "/SpecialtyGroupThreebudgetTwo"
    } else if ("4.4" === processCode) {
      jumpPageRouter = "/SpecialtyGroupFourbudgetTwo"
    } else if ("4.5" === processCode) {
      jumpPageRouter = "/SpecialtyGroupAddschoolTwo"
    } else if ("地方政府联系人" === processCode) {
      jumpPageRouter = "/localment"
    } else if ("建设任务案例" === processCode) {
      jumpPageRouter = "/constructioncase"
    }
    this.props.history.push({
      pathname: jumpPageRouter,
      search: JSON.stringify(param)
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    let {oneList, lookTaskProcessObj, ontime} = this.state;
    let {isSelect} = this.props;
    if (isSelect === 1) {
      if (ontime === '2.5') {
        oneList[0].applyStatus = 1
        oneList[0].twoList[oneList[0].twoList.length - 1].applyStatus = 1
      }
      if (ontime === '3.5') {
        oneList[1].applyStatus = 1
        oneList[1].twoList[oneList[1].twoList.length - 1].applyStatus = 1
      }
      if (ontime === '4.5') {
        oneList[2].applyStatus = 1
        oneList[2].twoList[oneList[2].twoList.length - 1].applyStatus = 1
      }
    }
    return (
        <div className="Double-LeftMein">
          <div className="title-h5">
            <h5>{lookTaskProcessObj.schoolName}-任务书</h5>
          </div>
          <div className="table">
            {oneList.map((item, i) => (
                <ul key={i}>
                  <h6  className={ontime === item.processName ? 'active' : ''} onClick={() =>{item.processName!=="专业群一建设任务书"&&item.processName!=="专业群二建设任务书"&&item.processName!=="专业群建设任务书"&&item.processName!=="项目学校建设任务书" ?this.menuClick(item.processName):this.menuClick(item.twoList[0].processCode)} }>{i + 1}、{item.processName}</h6>
                  {item.twoList ? item.twoList.map((twoItem, j) => (
                      <li className={ontime === twoItem.processCode ? 'active' : ''} key={j}
                          onClick={() => this.menuClick(twoItem.processCode)}>{i + 1}-{j + 1} {twoItem.processName}</li>
                  )) : ""}
                </ul>
            ))}
          </div>
        </div>
    );
  }
}

LeftMein = Form.create()(LeftMein);
export default LeftMein;
