
import React from 'react'
import ReactEcharts from 'echarts-for-react';

class OverviewTwo extends React.Component {
    state = {
        dataX: ['中央财政投入资金预算','地方各级财政投入','举办方投入','行业企业支持','学校自筹'],
        colorList:['#CE5E5E','#63CBBA','#FFBB3C','#B66DF7','#548B3B','#F07661','#588BF7','#63CBBA','#FFBB3C','#B66DF7','#548B3B','#F07661','#588BF7'],
        dataListOne:[], // 累计预算安排
        dataListTwo:[],
        dataListThree:[],
        dataListFour:[],
        dataListOne1:[],
        dataListOne2:[],
        dataListOne3:[],
        dataListOne4:[],
        dataListOne5:[],
        dataListOne6:[],
        dataListOne7:[],
        dataListOne8:[],
        dataListOne9:[],
        dataListOne10:[],
        dataListOne11:[],
        dataListOne12:[],
        textList:['累计预算安排','累计预算到位','累计资金使用','2019年预算安排','2019年预算到位','2019年资金使用','2020年预算安排','2020年预算到位','2020年资金使用',
            '2021年预算安排','2021年预算到位','2021年资金使用','累计执行率','2019资金执行率','2020资金执行率','2021资金执行率'],
    }

    componentDidMount() {
        console.log('888大大大大 diddid', this.props.schoolMoneyOverview)
        let schoolMoneyOverview = this.props.schoolMoneyOverview
        let centralVo = schoolMoneyOverview.centralVo
        let placeVo = schoolMoneyOverview.placeVo
        let hostVo = schoolMoneyOverview.hostVo
        let industryVo = schoolMoneyOverview.industryVo
        let schoolVo = schoolMoneyOverview.schoolVo
        // textList:['累计预算安排','累计预算到位','累计资金使用','2019年预算安排','2019年预算到位','2019年资金使用','2020年预算安排','2020年预算到位','2020年资金使用',
        //     '2021年预算安排','2021年预算到位','2021年资金使用','累计执行率','2019资金执行率','2020资金执行率','2021资金执行率'],
        let dataListOne = []
        dataListOne.push(centralVo.totalInvestInMoney)
        dataListOne.push(placeVo.totalInvestInMoney)
        dataListOne.push(hostVo.totalInvestInMoney)
        dataListOne.push(industryVo.totalInvestInMoney)
        dataListOne.push(schoolVo.totalInvestInMoney)
        let dataListTwo = []
        dataListTwo.push(centralVo.totalInvestPlaceMoney)
        dataListTwo.push(placeVo.totalInvestPlaceMoney)
        dataListTwo.push(hostVo.totalInvestPlaceMoney)
        dataListTwo.push(industryVo.totalInvestPlaceMoney)
        dataListTwo.push(schoolVo.totalInvestPlaceMoney)
        let dataListThree = []
        dataListThree.push(centralVo.totalInvestUseMoney)
        dataListThree.push(placeVo.totalInvestUseMoney)
        dataListThree.push(hostVo.totalInvestUseMoney)
        dataListThree.push(industryVo.totalInvestUseMoney)
        dataListThree.push(schoolVo.totalInvestUseMoney)
        let dataListFour = []
        dataListFour.push(centralVo.nineteenInvestInMoney)
        dataListFour.push(placeVo.nineteenInvestInMoney)
        dataListFour.push(hostVo.nineteenInvestInMoney)
        dataListFour.push(industryVo.nineteenInvestInMoney)
        dataListFour.push(schoolVo.nineteenInvestInMoney)
        let dataListOne1 = []
        dataListOne1.push(centralVo.nineteenInvestPlaceMoney)
        dataListOne1.push(placeVo.nineteenInvestPlaceMoney)
        dataListOne1.push(hostVo.nineteenInvestPlaceMoney)
        dataListOne1.push(industryVo.nineteenInvestPlaceMoney)
        dataListOne1.push(schoolVo.nineteenInvestPlaceMoney)
        let dataListOne2 = []
        dataListOne2.push(centralVo.nineteenInvestUseMoney)
        dataListOne2.push(placeVo.nineteenInvestUseMoney)
        dataListOne2.push(hostVo.nineteenInvestUseMoney)
        dataListOne2.push(industryVo.nineteenInvestUseMoney)
        dataListOne2.push(schoolVo.nineteenInvestUseMoney)
        let dataListOne3 = []
        dataListOne3.push(centralVo.twentyInvestInMoney)
        dataListOne3.push(placeVo.twentyInvestInMoney)
        dataListOne3.push(hostVo.twentyInvestInMoney)
        dataListOne3.push(industryVo.twentyInvestInMoney)
        dataListOne3.push(schoolVo.twentyInvestInMoney)
        let dataListOne4 = []
        dataListOne4.push(centralVo.twentyInvestPlaceMoney)
        dataListOne4.push(placeVo.twentyInvestPlaceMoney)
        dataListOne4.push(hostVo.twentyInvestPlaceMoney)
        dataListOne4.push(industryVo.twentyInvestPlaceMoney)
        dataListOne4.push(schoolVo.twentyInvestPlaceMoney)
        let dataListOne5 = []
        dataListOne5.push(centralVo.twentyInvestUseMoney)
        dataListOne5.push(placeVo.twentyInvestUseMoney)
        dataListOne5.push(hostVo.twentyInvestUseMoney)
        dataListOne5.push(industryVo.twentyInvestUseMoney)
        dataListOne5.push(schoolVo.twentyInvestUseMoney)
        let dataListOne6 = []
        dataListOne6.push(centralVo.twentyOneInvestInMoney)
        dataListOne6.push(placeVo.twentyOneInvestInMoney)
        dataListOne6.push(hostVo.twentyOneInvestInMoney)
        dataListOne6.push(industryVo.twentyOneInvestInMoney)
        dataListOne6.push(schoolVo.twentyOneInvestInMoney)
        let dataListOne7 = []
        dataListOne7.push(centralVo.twentyOneInvestPlaceMoney)
        dataListOne7.push(placeVo.twentyOneInvestPlaceMoney)
        dataListOne7.push(hostVo.twentyOneInvestPlaceMoney)
        dataListOne7.push(industryVo.twentyOneInvestPlaceMoney)
        dataListOne7.push(schoolVo.twentyOneInvestPlaceMoney)
        let dataListOne8 = []
        dataListOne8.push(centralVo.twentyOneInvestUseMoney)
        dataListOne8.push(placeVo.twentyOneInvestUseMoney)
        dataListOne8.push(hostVo.twentyOneInvestUseMoney)
        dataListOne8.push(industryVo.twentyOneInvestUseMoney)
        dataListOne8.push(schoolVo.twentyOneInvestUseMoney)
        let dataListOne9 = []
        dataListOne9.push((centralVo.totalEnforced) * 100)
        dataListOne9.push((placeVo.totalEnforced) * 100)
        dataListOne9.push((hostVo.totalEnforced) * 100)
        dataListOne9.push((industryVo.totalEnforced) * 100)
        dataListOne9.push((schoolVo.totalEnforced) * 100)
        let dataListOne10 = []
        dataListOne10.push((centralVo.nineteenEnforced) * 100)
        dataListOne10.push((placeVo.nineteenEnforced) * 100)
        dataListOne10.push((hostVo.nineteenEnforced) * 100)
        dataListOne10.push((industryVo.nineteenEnforced) * 100)
        dataListOne10.push((schoolVo.nineteenEnforced) * 100)
        let dataListOne11 = []
        dataListOne11.push((centralVo.twentyEnforced) * 100)
        dataListOne11.push((placeVo.twentyEnforced) * 100)
        dataListOne11.push((hostVo.twentyEnforced) * 100)
        dataListOne11.push((industryVo.twentyEnforced) * 100)
        dataListOne11.push((schoolVo.twentyEnforced) * 100)
        let dataListOne12 = []
        dataListOne12.push((centralVo.twentyOneEnforced) * 100)
        dataListOne12.push((placeVo.twentyOneEnforced) * 100)
        dataListOne12.push((hostVo.twentyOneEnforced) * 100)
        dataListOne12.push((industryVo.twentyOneEnforced) * 100)
        dataListOne12.push((schoolVo.twentyOneEnforced) * 100)

        dataListOne.push()
        this.setState({
            dataListOne: dataListOne,  //累计预算安排
            dataListTwo: dataListTwo,  //累计预算到位
            dataListThree: dataListThree,  //累计资金使用
            dataListFour: dataListFour,   //2019年预算安排
            dataListOne1: dataListOne1,   //2019年预算到位
            dataListOne2: dataListOne2,   //2019年资金使用
            dataListOne3: dataListOne3,   //2020年预算安排
            dataListOne4: dataListOne4,   //2020年预算到位
            dataListOne5: dataListOne5,   //2020年资金使用
            dataListOne6: dataListOne6,   //2021年预算安排
            dataListOne7: dataListOne7,   //2021年预算到位
            dataListOne8: dataListOne8,   //2021年资金使用
            dataListOne9: dataListOne9,   //累计执行率
            dataListOne10: dataListOne10,   //2019资金执行率
            dataListOne11: dataListOne11,   //2020资金执行率
            dataListOne12: dataListOne12,   //2021资金执行率
        })
        console.log("lf",dataListOne11)

    }

    optionPie = () => { // 柱状图
        const {dataX,colorList,dataListOne,dataListTwo,dataListThree,dataListFour,textList,
            dataListOne1,dataListOne2,dataListOne3,dataListOne4,dataListOne5,dataListOne6,dataListOne7,dataListOne8,dataListOne9,
            dataListOne10,dataListOne11,dataListOne12} = this.state
        return {
            grid: {
                top: '22%',
                "left": 60,
                "right": 60,
                containLabel: true,
            },
            legend: {
                icon: 'circle',
                orient: 'horizontal',
                itemWidth: 15,
                itemHeight: 15,
                left:'8%',
                right:'8%',
                textStyle: {
                    verticalAlign: "middle",
                    fontWeight: 'normal',
                    color: '#333333',
                    fontSize:14,
                    padding: [4, 10, 0, 0],
                },
                data: textList,
                top:0,
            },
            color:colorList,
            xAxis: {
                type: 'category',
                data:dataX,
                boundaryGap: true, // 不留白，从原点开始
                axisLine: {
                    //设置坐标轴线
                    lineStyle: {
                        color: '#E5E3F6' //改轴颜色
                    }
                },
                axisTick: { show: true }, //设置刻度线颜色
                axisLabel: {
                    //坐标轴刻度标签的相关设置。
                    interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
                    //	margin:15,
                    textStyle: {
                        color: '#333333',
                        fontSize: 14
                    }
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: '金额(万元)',
                    min: 0,
                    max: 5000,
                    interval: 500,
                    color: '#333333',
                    nameTextStyle: {
                        color: '#888888',
                        fontSize: 10
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#6C6F79' //改轴颜色
                        }
                    },
                    axisLabel: {
                        formatter: '{value}万元',
                        textStyle: {
                            color: '#A2A5AA', //改轴数值颜色
                            fontSize: 10
                        }
                    },
                },
                {
                    type: 'value',
                    name: '执行率%',
                    min: 0,
                    color: '#333333',
                    nameTextStyle: {
                        color: '#888888',
                        fontSize: 10
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)' //改轴颜色
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#6C6F79' //改轴颜色
                        }
                    },
                    axisLabel: {
                        formatter: '{value}%',
                        textStyle: {
                            color: '#A2A5AA', //改轴数值颜色
                            fontSize: 10
                        }
                    },
                    // data:textList.slice(6, 10)
                }

            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: {
                    color: '#fff',
                    align: 'left',
                    fontSize: 14
                },
                axisLine: {//x坐标轴轴线
                    show: true,
                    lineStyle: {//x坐标轴轴线样式
                        color: '#000',//'#ccc' | 'rgb(128, 128, 128)' | 'rgba(128, 128, 128, 0.5)'，设置标签颜色
                    }
                },
                backgroundColor: 'rgba(51,51,51,0.8)',
                formatter: function (params) {
                    console.log(params);
                    let str = params[0].name + '<br />';
                    console.log(str);
                    params.forEach((item) => {
                        console.log(item.seriesName);
                        if (item.value) {
                            if (item.seriesName.indexOf('率') != -1) {
                                str += `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color: ${item.color}
                                    "></span>
                                         ${item.seriesName}
                                         : ${item.value/100}%
                                        <br/>`;
                            }else{
                                str += `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color: ${item.color}
                                    "></span>
                                        ${item.seriesName}
                                        :
                                      ${item.value}万元
                                        <br/>`;
                            }
                        }
                    });
                    return str;
                },
                data:['累计预算安排','累计预算到位','累计资金使用','2019年预算安排','2019年预算到位','2019年资金使用','2020年预算安排','2020年预算到位','2020年资金使用',
                    '2021年预算安排','2021年预算到位','2021年资金使用','累计执行率','2019资金执行率','2020资金执行率','2021资金执行率']
            },

            series: [
                {
                    name: textList[0],
                    data: dataListOne,  //累计预算安排
                    stack: 'AA',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[3],
                    data: dataListFour,  //2019年预算安排
                    stack: 'AA',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[6],
                    data: dataListOne3,   //2020年预算安排
                    stack: 'AA',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[9],
                    data: dataListOne6,   //2021年预算安排
                    stack: 'AA',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[1],
                    data: dataListTwo,   //累计预算到位
                    stack: 'BB',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[4],
                    data: dataListOne1,   //2019年预算到位
                    stack: 'BB',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[7],
                    data: dataListOne4,   //2020年预算到位
                    stack: 'BB',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[10],
                    data: dataListOne7,   //2021年预算到位
                    stack: 'BB',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[2],
                    data: dataListThree,   //累计资金使用
                    stack: 'CC',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[5],
                    data: dataListOne2,   //2019年资金使用
                    stack: 'CC',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[8],
                    data: dataListOne5,   //2020年资金使用
                    stack: 'CC',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[11],
                    data: dataListOne8,   //2021年资金使用
                    stack: 'CC',
                    type: 'bar',
                    barWidth :10, //柱图宽度
                    z: 1,
                },
                {
                    name: textList[12],    //累计执行率
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                width: 2,
                                type: 'solid' //'dotted'虚线 'solid'实线
                            }
                        }
                    },
                    data: dataListOne9
                },
                {
                    name: textList[13],   //2019资金执行率
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                width: 2,
                                type: 'solid' //'dotted'虚线 'solid'实线
                            }
                        }
                    },
                    data: dataListOne10,
                },
                {
                    name: textList[14],   //2020资金执行率
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                width: 2,
                                type: 'solid' //'dotted'虚线 'solid'实线
                            }
                        }
                    },
                    data: dataListOne11,
                },
                {
                    name: textList[15],   //2021资金执行率
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                width: 2,
                                type: 'solid' //'dotted'虚线 'solid'实线
                            }
                        }
                    },
                    data: dataListOne12,
                },

            ]
        };
    }

    render() {
        return (
            <div className={'OverviewTwo'} style={{width:'100%'}}>
                <ReactEcharts option={this.optionPie()} style={{height:'540px'}}/>
            </div>
        )
    }

}
export default OverviewTwo;
