/*资金总体情况*/
import React from 'react'
import ReactEcharts from 'echarts-for-react';

class GeneralFunds extends React.Component {
    state = {
        echartstable111 : {
            xdata: ['累计到位金额','项目累计总额预算',],
            data: [],
            data1: [],
            data2: [],
            data3: [],
            data4: [],
            lengendata:['中央财政投入','地方各级财政投入','举办方投入','行业企业投入','学校自筹资金'],
            overallFundSituationInfo: this.props.overallFundSituationInfo
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext){
        console.log('nextProps 888', nextProps)
        console.log('nextProps.totalProjectBudgetList 888', nextProps.overallFundSituationInfo.totalProjectBudgetList)
        let echartstable111 = this.state.echartstable111
        let data = []
        data.push(nextProps.overallFundSituationInfo.totalProjectBudgetList[0])
        data.push(nextProps.overallFundSituationInfo.inAmountList[0])
        let data1 = []
        data1.push(nextProps.overallFundSituationInfo.totalProjectBudgetList[1])
        data1.push(nextProps.overallFundSituationInfo.inAmountList[1])
        let data2 = []
        data2.push(nextProps.overallFundSituationInfo.totalProjectBudgetList[2])
        data2.push(nextProps.overallFundSituationInfo.inAmountList[2])
        let data3 = []
        data3.push(nextProps.overallFundSituationInfo.totalProjectBudgetList[3])
        data3.push(nextProps.overallFundSituationInfo.inAmountList[3])
        let data4 = []
        data4.push(nextProps.overallFundSituationInfo.totalProjectBudgetList[4])
        data4.push(nextProps.overallFundSituationInfo.inAmountList[4])
        echartstable111.data = data
        echartstable111.data1 = data1
        echartstable111.data2 = data2
        echartstable111.data3 = data3
        echartstable111.data4 = data4
        console.log('echartstable111 888', echartstable111)

        this.setState({
            echartstable111: echartstable111
        })
    }
    optionPie = () => { // 饼状图
        const {echartstable111} = this.state
        return {
            color: ['#63CBBA','#588BF7','#5B6E96','#A8E0FB','#FFDB4A'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                },
            },
            title: {
                text: '单位：万元',
                textStyle: {
                    color: "#333333",
                    fontSize:14,
                    fontWeight:400,
                },
                top: '3%',
                left: '4%',
            },
            legend: {
                show: true,
                top:0,
                itemGap: 10,
                icon: "rect",
                itemWidth: 18, // 图例组件的宽度
                itemHeight: 14, // 图例组件的高度
                right:'5%',
                textStyle: {
                    verticalAlign: "middle",
                    color: '#333333',
                    fontSize:14,
                    lineHeight:30
                },
                data:echartstable111.lengendata
            },
            grid: {
                left: '15%',
                top: '20%'
            },

            xAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type:'dashed',
                            color:'#E5E3F6'
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#D4D4D4',
                        fontSize: 14
                    },

                },
            ],
            yAxis: [
                {
                    nameLocation: 'end',
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            show: false,
                            color: '#E5E3F6', // 颜色
                            fontSize:14,
                        }
                    },
                    axisLabel: {
                        color: '#888888', // 颜色
                        fontSize:14,
                    },
                    data: echartstable111.xdata,
                }
            ],
            series: [
                {
                    type: 'bar',
                    stack: '总量',
                    name:'中央财政投入',
                    barWidth: 22,
                    barCategoryGap:'0',
                    label: {
                        show: false
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: echartstable111.data,
                },
                {
                    type: 'bar',
                    stack: '总量',
                    name:'地方各级财政投入',
                    barWidth: 22,
                    barCategoryGap:'0',
                    label: {
                        show: false,
                        position: 'left'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: echartstable111.data1,
                },
                {
                    type: 'bar',
                    stack: '总量',
                    name:'举办方投入',
                    barWidth: 22,
                    barCategoryGap:'0',
                    label: {
                        show: false,
                        position: 'left'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: echartstable111.data2,
                },
                {
                    type: 'bar',
                    stack: '总量',
                    name:'行业企业投入',
                    barWidth: 22,
                    barCategoryGap:'0',
                    label: {
                        show: false,
                        position: 'left'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: echartstable111.data3,
                },
                {
                    type: 'bar',
                    stack: '总量',
                    name:'学校自筹资金',
                    barWidth: 22,
                    barCategoryGap:'0',
                    label: {
                        show: false,
                        position: 'left'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: echartstable111.data4,
                }
            ]
        };
    }

    render() {
        return (
            <div className={'Pies'} style={{width:'100%'}}>
                <ReactEcharts option={this.optionPie()} style={{height:'300px'}}/>
            </div>
        )
    }

}
export default GeneralFunds;
