/*专家评审查看打分*/
import React, {Component} from 'react';
import {Button, Form, Tabs, Row, Col, Table, Modal, message, Spin} from 'antd';
import './index.scss';
import EvaluationReportRight from "../evaluationReport_right";
import {Link} from "react-router-dom";
import {
    schoolSelfReport,
    getSchoolOtherExplain,
    getExpertRatingDirectory,
    addSchoolAdditional,
    getSeniorBrace,
    getIndicatorBrace,
    saveThreeLevelIndicator,
    selectTaskFileList,
    getYearReport,
    getEffectDetail,
    submitScore,
    getSchoolInfoByEduCode, userIsSign,
    insertUpdateSubmitCurrent
} from '../../../../api/doublehigh/index';
import {fillInYear} from "../../../../config/secret";
import OSS from "ali-oss";
import {commonalityUrl, path} from "../../../../config/secret";
import userInfo from "../../../../store/reducers/userInfo";
import Cookies from "js-cookie";
const { TabPane } = Tabs;
class schoolRating extends React.Component {
    state = {
        isConfirm:false, //确认提交
        visible:false,//弹框
        fiveShow:false,//是否显示详情
        showDetail:false,//是否显示详情
        showPage:false,//社会可持续影响社会效益是否显示详情
        showListe:false,
        isSignature:false,
        isLandmark:false,
        indicators:false,   //四级指标查看
        seeTime:false,   //四级指标查看时效
        isSpin:false,  //提交打分加载中
        isLook:false,
        istabActive:0,
        tabActiveb:0,
        tabActive:1,
        tabActivea:0,
        activeKey: "1",
        angleType: "1",
        tabActiveis:1,
        onId:"",
        isResult:"",
        tableScrollY:600,
        breadcrumbList: [
            {
                name: "对学校层面建设任务评分",
                path: "",
            },
            {
                name: "产出指标",
                path: "",
            },
            {
                name: "数量指标",
                path: "",
            },
        ], //面包屑
        columns4: [
            {

                title: '层面',
                dataIndex: 'uplevelCatgory',
                key: 'uplevelCatgory',
                align: 'center',
                width: 130,
                render: (e) =>  <div className={'span'}>{e === 1 ? "学校":e === 2 ? "专业群一":e === 3 ? "专业群二":""}</div>
            },
            {
                title: '其他情况说明',
                dataIndex: 'otherExplain',
                key: 'otherExplain',
                align: 'center',
                render: (text) => (
                    <span className={'text'}>{text}</span>
                )
            },
            {
                title: '操作',
                fixed: 'right',
                align: 'center',
                key: 'right',
                width: 110,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showList(record)}>查看详情</span>
                    </div>
                )
            },

        ],//情况说明
        columns5: [
            {

                title: '任务序号',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 130,
                render: (e,index) =>  <div>{index}</div>
            },
            {
                title: '任务名称',
                dataIndex: 'otherExplain',
                key: 'otherExplain',
                align: 'center',
            },
            {
                title: '具体的建设机制与具体措施',
                dataIndex: 'otherExplain',
                key: 'otherExplain',
                align: 'center',
            },


        ],//情况说明
        columns: [
            {

                title: '三级指标名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width: 300,
                fixed: 'left',
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '指标总数（个）',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width: 130,
            },
            {
                title: '支撑度较高（个)',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width: 140,
            },
            {
                title: '支撑度一般（个)',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width: 140,
            },
            {
                title: '支撑度较弱（个)',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width: 140,
            },
            {
                title: '操作',
                fixed: 'right',
                align: 'center',
                // dataIndex: 'taskBookFile.fileFormat',
                key: 'right',
                width: 160,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetail(record)}>查看详情</span>
                    </div>
                )
            },
        ],
        columnslist: [
            {

                title: '维度名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width: 200,
                fixed: 'left',
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '标志性成果总数',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width: 130,
            },
            {
                title: '支撑度较高（个）',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度一般（个）',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度较弱（个）',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width: 160,
            },
            {
                title: '操作',
                fixed: 'right',
                // dataIndex: 'taskBookFile.fileFormat',
                key: 'right',
                align: 'center',
                width: 160,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetailEffect(record,'1')}>查看详情</span>
                    </div>
                )
            },
        ],
        grouplist: [
            {

                title: '维度名称',
                dataIndex: 'seniorName',
                key: 'seniorName',
                align: 'center',
                width: 200,
                fixed: 'left',
                render: (text) => (
                    <div className='text_title'>
                        {text}
                    </div>
                )
            },
            {
                title: '视角总数',
                dataIndex: 'indicatorTotal',
                key: 'indicatorTotal',
                align: 'center',
                width: 130,
            },
            {
                title: '支撑度较高（个）',
                dataIndex: 'braceHigh',
                key: 'braceHigh',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度一般（个）',
                dataIndex: 'braceIn',
                key: 'braceIn',
                align: 'center',
                width: 160,
            },
            {
                title: '支撑度较弱（个）',
                dataIndex: 'braceLow',
                key: 'braceLow',
                align: 'center',
                width: 160,
            },
            {
                title: '操作',
                fixed: 'right',
                // dataIndex: 'taskBookFile.fileFormat',
                key: 'right',
                align: 'center',
                width: 160,
                render: (text, record) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.showDetailEffect(record,'2')}>查看详情</span>
                    </div>
                )
            },
        ],
        detailColumns1: [
            {

                title: '四级绩效指标',
                dataIndex: 'indicatorName',
                key: 'indicatorName',
                align: 'center',
                fixed: 'left',
                // ellipsis: true,
                width:300,
                render: (text) => (
                    <div className='text_title' style={this.state.seeTime?{textAlign:'center'}:{textAlign:'left'}}>
                        {text}
                    </div>
                )
            },
            {
                title: '实施期满目标值',
                dataIndex: 'targetValue',
                key: 'targetValue',
                align: 'left',
                width: 70,
            },
            {
                title: '指标单位',
                dataIndex: 'indicatorMeasure',
                key: 'indicatorMeasure',
                align: 'center',
                width: 140,
            },
            {
                title: '累计目标值',
                dataIndex: 'cumulativeTargetValue',
                key: 'cumulativeTargetValue',
                align: 'left',
                width: 140,
            },
            {
                title: '累计实现值',
                dataIndex: 'cumulativeRealValue',
                key: 'cumulativeRealValue',
                align: 'left',
                width: 300,
            },
            {
                title: '中期目标完成度',
                dataIndex: 'cumulativeMetaphaseValue',
                key: 'cumulativeMetaphaseValue',
                align: 'center',
                width: 140,
            },  {
                title: '终期累计完成度',
                dataIndex: 'cumulativeCompleteValue',
                key: 'cumulativeCompleteValue',
                align: 'center',
                width: 140,
            },
            {
                title: '支撑度',
                dataIndex: 'supportCondition',
                key: 'supportCondition',
                align: 'center',
                fixed: 'right',
                width: 140,
                render: (text, record,index) => (
                    <div className=''>
                        {record.supportCondition===1?<span style={{color:'rgba(67, 207, 124, 1)'}}>支撑</span>:
                            record.supportCondition===2?<span style={{color:'rgba(215,87,70, 1)'}}>不能支撑</span>:
                                record.supportCondition===3? <span style={{color:'rgba(255, 141, 26, 1)'}}>存疑</span>:""}

                    </div>
                )
            },

        ],

        dataSource: [1, 2],
        investYear: fillInYear,
        threeIndicatorList: [],
        leftList: [],
        saveStatus: true,
        saveLeftList: [],
        indicatorLevelId: "2.5.1.1",
        fileSuffix: "pdf",
        uploadLocation: 4,
        seniorType: 1,
        userInfo: localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")) : '',
        loading: false,
        provinceScore: 0,
        record: this.props.record,
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
        effectDetailList:[
            {
                id:'1'
            }
        ]
    };

    componentDidMount() {
        // this.getSchoolInfoByEduCode()
        this.schoolSelfReport()
        // 计算表格的高度
        this.setState({
            tableScrollY: document.body.clientHeight - 440
        })
        console.log("ll--",this.props.angleType)

    }
    // _handleClick = (event) => {
    //     // const { visible } = this.state;
    //     // const wasOutside = !(event.target.contains === this.root);
    //     //
    //     // if (wasOutside && visible) this.setState({ visible: false, });
    //
    //     alert("左键");
    //     var bodyid = document.getElementById('tabsId1');
    //     console.log("id", bodyid)
    // };
    getExpertRatingDirectory =()=>{
        const {schoolEduCode, schoolInfo, userInfo} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            catgory: schoolInfo.catgory,
            userId: userInfo.id
        }
        getExpertRatingDirectory(data).then(res =>{
            if(res){
                this.setState({
                    leftList: res,
                    saveLeftList: res
                }, () => {
                    this.insertUpdateSubmitCurrent()
                })
            }

        })
    }

    insertUpdateSubmitCurrent =()=>{
        let {leftList, schoolInfo} = this.state
        let schoolScoreList = null;
        let groupOneScoreList = null;
        let groupTwoScoreList = null;
        if (leftList !== null && leftList !== undefined) {
            let seniorTypeSchool = leftList.filter(e => e.seniorType === 1);
            let seniorTypeGroupOne = leftList.filter(e => e.seniorType === 2);
            let seniorTypeGroupTwo = leftList.filter(e => e.seniorType === 3);
            if (seniorTypeSchool !== null && seniorTypeSchool.length > 0) {
                schoolScoreList = seniorTypeSchool
            }
            if (seniorTypeGroupOne !== null && seniorTypeGroupOne.length > 0) {
                groupOneScoreList = seniorTypeGroupOne
            }
            if (seniorTypeGroupTwo !== null && seniorTypeGroupTwo.length > 0) {
                groupTwoScoreList = seniorTypeGroupTwo
            }
        }
        let weishu = 2
        let digit = 3
        let  schoolNumOld = schoolScoreList ? schoolScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupOneNumOld = groupOneScoreList ? groupOneScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupTwoNumOld = groupTwoScoreList ? groupTwoScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let schoolNum = parseFloat((Math.round((schoolNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupOneNum = parseFloat((Math.round((groupOneNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupTwoNum = parseFloat((Math.round((groupTwoNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let expertsScore = 0;
        if(schoolInfo && schoolInfo.catgory === 1){
            let num = (schoolNum * 0.5 + (groupOneNum + groupTwoNum) * 0.25)
            num = parseFloat(num);
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        } else {
            let numOld = (schoolNum * 0.3) + (groupOneNum * 0.7)
            // console.log('catgory === 2 numOld 777-->', numOld)
            let num = parseFloat((Math.round((numOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
            // console.log('catgory === 2 里面的num 777-->', num)
            num = parseFloat(num);
            // console.log('catgory === 2 parseFloat(num) 777-->', parseFloat(num))
            // console.log('catgory === 2 结果的上一步 777-->', parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(digit)))
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
            // console.log('catgory === 2 最后的结果  777-->', expertsScore)
        }
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            userId: this.state.userInfo.id,
            expertsScore : expertsScore
        }
        // console.log('data 接口入参 777-->', data)
        insertUpdateSubmitCurrent(data).then(res =>{
            if(res){

            }
        })
    }

    getSchoolInfoByEduCode = async () => {
        let { userInfo,schoolEduCode} = this.state;
        let schoolInfo = await getSchoolInfoByEduCode({schoolEduCode: schoolEduCode,userId: userInfo.id});
        // console.log("schoolInfo.schoolInfo:", schoolInfo)
        this.setState({schoolInfo: schoolInfo,isResult: schoolInfo.isSubmit},()=>{
            this.getExpertRatingDirectory()
        })
    }




    //获取学校文件-共用接口
    schoolSelfReport =()=>{
        let data = {
            schoolEduCode: this.state.uploadLocation === 6 ? this.state.provinceCode :this.state.schoolEduCode,
            uploadLocation: this.state.uploadLocation,
            fileSuffix: this.state.fileSuffix,
            investYear: this.state.uploadLocation === 4 || this.state.uploadLocation === 6 ? 0 : this.state.investYear,
            midType: this.state.uploadLocation === 2 || this.state.uploadLocation === 3 || this.state.uploadLocation === 7?this.state.midType:null
        }
        schoolSelfReport(data).then(res =>{
            if(res){
                let fileId = ''
                if(this.state.uploadLocation === 7){
                    console.log('this.state.uploadLocation === 7', res)
                    if(res.length > 0){
                        this.setState({
                            fileList: res,
                            iframeSrc: `${commonalityUrl}${res[0].fileId}`
                        })
                    } else {
                        this.setState({
                            iframeSrc: "",
                            fileList: [],
                        })
                    }
                } else {
                    fileId = res[0] && res[0].fileId ? res[0].fileId :'';
                    // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
                    if(fileId){
                        let iframeSrc = `${commonalityUrl}${fileId}`;
                        this.setState({
                            iframeSrc: iframeSrc
                        })
                    } else {
                        this.setState({
                            iframeSrc: null
                        })
                    }
                }


            }
        })
    }

    schoolSelfReportFive =()=>{
        this.setState({
            tabActive: 2,
            istabActive: 0,
            showDetail:true,
        })
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            uploadLocation: 3,
            fileSuffix: this.state.fileSuffix,
            investYear: this.state.investYear,
            upseniorId: this.state.indicatorLevelId,
            seniorId: this.state.record.seniorId
        }
        schoolSelfReport(data).then(res => {
            if (res) {
                this.setState({
                    fiveFileList: res ? res : '',
                    iframeSrc: res[0] && res[0].fileId ? `${commonalityUrl}${res[0].fileId}` : ''
                })
            }
        })
    }

    schoolSelfReportSix =(key)=>{
        this.setState({
            tabActive: 2,
        })
        console.log("lf111",key)
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            uploadLocation: 1,
            fileSuffix: this.state.fileSuffix,
            investYear: this.state.investYear,
        }
        schoolSelfReport(data).then(res => {
            if (res) {
                this.setState({
                    sixFileList: res,
                    iframeSrc: `${commonalityUrl}${res[0].fileId}`
                })
            }
        })
    }
    schoolSelfReportSixa =(key)=>{
        let schoolInfo = this.state.schoolInfo
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        let title  = catgory + gare

        let ossFileIdList = this.state.ossFileIdList
        let titleFileList = ossFileIdList.filter(e => e.groupName === title)
        let url = titleFileList[0].list[0]
        let data = {
            fileName: "表1：履约与执行力（" + catgory + gare + "）.pdf"
        }
        let sixFileList = []
        sixFileList.push(data)
        this.setState({
            tabActive: 3,
            iframeSrc: `${commonalityUrl}${url}`,
            sixFileList: sixFileList,

        })

        // let schoolInfo = this.state.schoolInfo
        // let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        // let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        // let title  = catgory + gare
        // let url = ''
        // if(title === "专业群B"){
        //     url = "capitalMaterials/" + catgory + gare + "/表1：履约与执行力-（" + catgory + gare + "）.pdf"
        // } else {
        //     url = "capitalMaterials/" + catgory + gare + "/表1：履约与执行力（" + catgory + gare + "）.pdf"
        // }
        // let sixFileList = []
        // let data = {
        //     fileName: "表1：履约与执行力（" + catgory + gare + "）.pdf"
        // }
        // console.log('履约与执行力', `${commonalityUrl}${path}${url}`)
        // sixFileList.push(data)
        // this.setState({
        //     tabActive: 3,
        //     iframeSrc: `${commonalityUrl}${path}${url}`,
        //     sixFileList: sixFileList,
        //
        // })
    }
    schoolSelfReportSixb =()=>{

        let schoolInfo = this.state.schoolInfo
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        let title  = catgory + gare

        let ossFileIdList = this.state.ossFileIdList
        let titleFileList = ossFileIdList.filter(e => e.groupName === title)
        let url = titleFileList[0].list[1]
        let data = {
            fileName: "表2-1：信息校验后“双高计划”中期未足额到位资金情况一览（" + catgory + gare + "）.pdf"
        }
        let sixFileList = []
        sixFileList.push(data)
        this.setState({
            tabActive: 4,
            iframeSrc: `${commonalityUrl}${url}`,
            sixFileList: sixFileList,

        })


        // let schoolInfo = this.state.schoolInfo
        // let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        // let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        // let url = "capitalMaterials/" + catgory + gare + "/表2-1：信息校验后“双高计划”中期未足额到位资金情况一览（" + catgory + gare + "）.pdf"
        // let sixFileList = []
        // let data = {
        //     fileName: "表2-1：信息校验后“双高计划”中期未足额到位资金情况一览（" + catgory + gare + "）.pdf"
        // }
        // sixFileList.push(data)
        // this.setState({
        //     tabActive: 4,
        //     iframeSrc: `${commonalityUrl}${path}${url}`,
        //     sixFileList: sixFileList
        // })
    }
    schoolSelfReportSixc =(key)=>{

        let schoolInfo = this.state.schoolInfo
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        let title  = catgory + gare

        let ossFileIdList = this.state.ossFileIdList
        let titleFileList = ossFileIdList.filter(e => e.groupName === title)
        let url = titleFileList[0].list[2]
        let data = {
            fileName: "表2-2：项目资金到位及其支撑度（" + catgory + gare + "）.pdf"
        }
        let sixFileList = []
        sixFileList.push(data)
        this.setState({
            tabActive: 5,
            iframeSrc: `${commonalityUrl}${url}`,
            sixFileList: sixFileList,

        })

        // let schoolInfo = this.state.schoolInfo
        // let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        // let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        // let url = "capitalMaterials/" + catgory + gare + "/表2-2：项目资金到位及其支撑度（" + catgory + gare + "）.pdf"
        // let sixFileList = []
        // let data = {
        //     fileName: "表2-2：项目资金到位及其支撑度（" + catgory + gare + "）.pdf"
        // }
        // sixFileList.push(data)
        // this.setState({
        //     tabActive: 5,
        //     iframeSrc: `${commonalityUrl}${path}${url}`,
        //     sixFileList: sixFileList
        // })
    }
    schoolSelfReportSixd =(key)=>{

        let schoolInfo = this.state.schoolInfo
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        let title  = catgory + gare

        let ossFileIdList = this.state.ossFileIdList
        let titleFileList = ossFileIdList.filter(e => e.groupName === title)
        let url = titleFileList[0].list[3]
        let data = {
            fileName: "表3：项目资金使用可信度与管理规范支撑度（" + catgory + gare + "）.pdf"
        }
        let sixFileList = []
        sixFileList.push(data)
        this.setState({
            tabActive: 6,
            iframeSrc: `${commonalityUrl}${url}`,
            sixFileList: sixFileList,

        })


        // let schoolInfo = this.state.schoolInfo
        // let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        // let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        // let url = "capitalMaterials/" + catgory + gare + "/表3：项目资金使用可信度与管理规范支撑度（" + catgory + gare + "）.pdf"
        // let sixFileList = []
        // let data = {
        //     fileName: "表3：项目资金使用可信度与管理规范支撑度（" + catgory + gare + "）.pdf"
        // }
        // sixFileList.push(data)
        // this.setState({
        //     tabActive: 6,
        //     iframeSrc: `${commonalityUrl}${path}${url}`,
        //     sixFileList: sixFileList
        // })
    }
    schoolSelfReportSixe =(key)=>{

        let schoolInfo = this.state.schoolInfo
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        let title  = catgory + gare

        let ossFileIdList = this.state.ossFileIdList
        let titleFileList = ossFileIdList.filter(e => e.groupName === title)
        let url = titleFileList[0].list[4]
        let data = {
            fileName: "表4：项目资金使用有效性及其支撑度（" + catgory + gare + "）.pdf"
        }
        let sixFileList = []
        sixFileList.push(data)
        this.setState({
            tabActive: 7,
            iframeSrc: `${commonalityUrl}${url}`,
            sixFileList: sixFileList,

        })

        // let schoolInfo = this.state.schoolInfo
        // let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        // let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
        // let url = "capitalMaterials/" + catgory + gare + "/表4：项目资金使用有效性及其支撑度（" + catgory + gare + "）.pdf"
        // let sixFileList = []
        // let data = {
        //     fileName: "表4：项目资金使用有效性及其支撑度（" + catgory + gare + "）.pdf"
        // }
        // sixFileList.push(data)
        // this.setState({
        //     tabActive: 7,
        //     iframeSrc: `${commonalityUrl}${path}${url}`,
        //     sixFileList: sixFileList
        // })
    }
    schoolSelfReportSeven =(key)=>{
        this.setState({
            tabActive: 1,
        })
    }


    //获取学校其他说明
    getSchoolOtherExplain =()=>{
        let data = {
            "investYear": this.state.investYear,
            "schoolEduCode": this.state.schoolEduCode,
        }
        getSchoolOtherExplain(data).then(res =>{
            if(res){
                this.setState({
                    schoolOtherExplain: res
                })
            }
        })
    }

    //获取三级指标支撑情况
    getSeniorBrace =()=>{
        let data = {
            upseniorId: this.state.indicatorLevelId,
            schoolEduCode: this.state.schoolEduCode,
            seniorType: this.state.seniorType
        }
        getSeniorBrace(data).then(res =>{
            let schoolList = []
            let groupList = []
            let placeList = []
            if(res){
                if(res.effectBraceNumVos){
                    let effectBrace = res.effectBraceNumVos
                    schoolList = effectBrace.filter(e => e.upseniorName === "双高绩效目标实现贡献度")
                    groupList = effectBrace.filter(e => e.upseniorName === "高水平学校和专业群社会认可度")
                    placeList = effectBrace.filter(e => e.upseniorName === "地方政府（含举办方）重视程度")
                    // console.log('schoolList',schoolList)
                }
                this.setState({
                    effectBrace: res.effectBraceNumVos,
                    seniorBrace: res.indicatorBraceNumVos,
                    schoolList: schoolList,
                    groupList: groupList,
                    placeList: placeList,
                })
            }
        })
    }
    //获取四级指标支撑情况
    getIndicatorBrace =()=>{
        let data = {
            upseniorId: this.state.indicatorLevelId,
            schoolEduCode: this.state.schoolEduCode,
            seniorId: this.state.threeTable.seniorId
        }
        getIndicatorBrace(data).then(res =>{
            if(res){
                this.setState({
                    indicatorBrace: res
                })
            }
        })
    }


    callback = async (key) => {
        let that = this
        await this.setState({
            loading: true,
            tabActive:1,
            activeKey: key,
            uploadLocation: key === "1" ? 4 : key === "2" ? 6 : null,
        },() =>{
            if(key === "6"&&(this.state.schoolEduCode === '4132011052'||this.state.schoolEduCode === '4132012686')){
                Modal.warning({
                    title:'2020、2021年中央资金预算、到位数填报异常，请查看资金核验情况' ,
                    // footer:null,
                    centered:true,
                    mask:true,
                    closable:true,
                    className:'warning_list',
                    zIndex:1,
                });
            }
            if(key === "8"){
                this.setState({
                    showListe:false
                })
                this.getSchoolOtherExplain()
            }
            if(key === "1" || key === "2"){
                this.setState({
                    midType: null
                }, () =>{
                    this.schoolSelfReport()
                })
            }
            if (key === "5") {
                this.setState({
                    indicatorLevelId: "2.5.1.1",
                    seniorType: 1,
                    // twoLevelTable: value,
                    breadcrumbList: [
                        {
                            name: "对学校层面建设任务评分",
                            path: "",
                        },
                        {
                            name: "产出指标",
                            path: "",
                        },
                        {
                            name: "数量指标",
                            path: "",
                        },
                    ],
                    showDetail:true,
                    showPage:false,
                    fiveShow: false
                    // activeKey:type
                }, () =>{
                    this.getSeniorBrace()
                    // this.schoolSelfReport()
                })
            }
            if(key === "3"){
                this.getYearReport(1)
            }
            if(key === "7"){
                this.selectTaskFileList()
            }
            setTimeout(function () {
                that.setState({
                    loading: false,
                })
            }, 1000);
        })
    };
    callbackTwo = (key) => {
        this.setState({
            uploadLocation: key === "1" ? 4 : key === "2" ? 6 : null,
            tabActive:1,
            iframeSrc: ''
        },() =>{
            if(key === "1" || key === "2"){
                // this.schoolSelfReport()
            }
        })
    };
    callbackList = (key,item) => {
        console.log('item 999', item)
        console.log('item 999', item.fileId)
        this.setState({
            showDetail:true,
            isSignature:false,
            tabActivea:key,
            iframeSrc: `${commonalityUrl}${item.fileId}`
        },() =>{

        })
    };
    callbackListOne = (key) => {
        this.setState({
            showDetail:true,
            isSignature:false,
            isLook:false,
            tabActivea:2,
            iframeSrc: ''
        },() =>{

        })
    };
    callbackListTwo = (key) => {
        this.setState({
            showDetail:true,
            isSignature:true,
            isLook:true,
            tabActiveb:key,
            iframeSrc: ''
        },() =>{

        })
        console.log("llf",this.state.isSignature)
    };
    getYearReport =(value)=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            investYear: value === 1 ? fillInYear : null,
            fileLocation: value === 1 ? 1 : value
        }
        this.setState({
            tabActive:value
        },()=>{
            getYearReport(data).then(res =>{
                if(res){
                    let fileId = res.fileId
                    if(fileId){
                        this.setState({
                            iframeSrc: `${commonalityUrl}${fileId}`
                        })
                    } else {
                        this.setState({
                            iframeSrc: null
                        })
                    }
                }
            })
        })

    }
    selectTaskFileList =()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode
        }
        selectTaskFileList(data).then(res =>{
            if(res){
                let file = res.filter(e => e.fileType === 1);
                let fileId = ''
                if (file && file.length > 0) {
                    fileId = file[0].fileUploadDate + '/' + file[0].fileId
                }
                this.setState({
                    taskFileLis: res,
                    iframeSrc: `${commonalityUrl}${path}${fileId}`
                })
            }
        })
    }
    tabSignature = (key) =>{
        this.setState({
            tabActive:key
        })
    }
    tabClickone = (key) =>{
        console.log('0000----------', key)
        let url = ''
        if(key === 2){

            let schoolInfo = this.state.schoolInfo
            let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
            let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
            let title  = catgory + gare

            let ossOtherFileIdList = this.state.ossOtherFileIdList
            let titleFileList = ossOtherFileIdList.filter(e => e.groupName === title)
            url = titleFileList[0].list[0]
        }
        this.setState({
            iframeSrc: `${commonalityUrl}${url}`,
            tabActive:key
        })
    }

    tabClick =  (key) => {
        let {taskFileLis} = this.state;
        this.setState({
            tabActive:key
        },() =>{
            if (key === 1) {
                let file = taskFileLis.filter(e => e.fileType === 1);
                let fileId = ''
                if (file && file.length > 0) {
                    fileId = file[0].fileUploadDate + '/' + file[0].fileId
                }
                this.setState({
                    iframeSrc: `${commonalityUrl}${path}${fileId}`
                })
            } else if (key === 2){
                let file = taskFileLis.filter(e => e.fileType === 2);
                let fileId = ''
                if (file && file.length > 0) {
                    fileId = file[0].fileUploadDate + '/' + file[0].fileId
                }
                this.setState({
                    iframeSrc: `${commonalityUrl}${path}${fileId}`
                })
            } else {
                let url = ''
                let schoolInfo = this.state.schoolInfo
                let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
                let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""
                let title  = catgory + gare

                let ossMatchFileIdList = this.state.ossMatchFileIdList
                let titleFileList = ossMatchFileIdList.filter(e => e.groupName === title)
                url = titleFileList[0].list[0]
                this.setState({
                    iframeSrc: `${commonalityUrl}${url}`,
                })
            }
        })
    };
    tabClickList = (key) =>{
        let fileList = this.state.fileList
        console.log('fileList 00', fileList)
        let file = fileList[key]
        let fileId = file && file.fileId ? file.fileId :'';
        // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
        // console.log('youemiyou fileId', fileId)
        if(fileId){
            let iframeSrc = `${commonalityUrl}${fileId}`;
            this.setState({
                iframeSrc: iframeSrc,
                istabActive:key
            })
        }
    }
    tabClickTable =  (key) => {
        let {taskFileLis} = this.state;
        this.setState({
            tabActive:key
        },() =>{
            if (key === 1) {
                let file = taskFileLis.filter(e => e.fileType === 1);
                let fileId = ''
                if (file && file.length > 0) {
                    fileId = file[0].fileUploadDate + '/' + file[0].fileId
                }
                this.setState({
                    iframeSrc: `${commonalityUrl}${path}${fileId}`
                })
            } else {
                let file = taskFileLis.filter(e => e.fileType === 2);
                let fileId = ''
                if (file && file.length > 0) {
                    fileId = file[0].fileUploadDate + '/' + file[0].fileId
                }
                this.setState({
                    iframeSrc: `${commonalityUrl}${path}${fileId}`
                })
            }
        })
    };
    tabClickListFive = (key) =>{
        let fileList = this.state.fiveFileList
        let file = fileList[key]
        let fileId = file && file.fileId ? file.fileId :'';
        // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
        // console.log('youemiyou fileId', fileId)
        if(fileId){
            let iframeSrc = `${commonalityUrl}${fileId}`;
            this.setState({
                iframeSrc: iframeSrc,
                istabActive:key,
            })
        }
    }
    tabClickListFiveOne = (key) =>{
        let fileList = this.state.fiveFileList
        let file = fileList[key]
        let fileId = file && file.fileId ? file.fileId :'';
        // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
        // console.log('youemiyou fileId', fileId)
        if(fileId){
            let iframeSrc = `${commonalityUrl}${fileId}`;
            this.setState({
                iframeSrc: iframeSrc,
                istabActive:key,
            })
        }
    }

    tabClickListEffect = (key) =>{
        let fileList = this.state.effectFileList
        let file = fileList[key]
        let fileId = file && file.fileId ? file.fileId :'';
        // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
        // console.log('youemiyou fileId', fileId)
        if(fileId){
            let iframeSrc = `${commonalityUrl}${fileId}`;
            this.setState({
                iframeSrc: iframeSrc,
                istabActive:key
            })
        }
    }

    tabClickListSix = (key) =>{
        let fileList = this.state.sixFileList
        let file = fileList[key]
        let fileId = file && file.fileId ? file.fileId :'';
        // let fileUploadDate = res && res.fileUploadDate ? res.fileUploadDate :'';
        // console.log('youemiyou fileId', fileId)
        if(fileId){
            let iframeSrc = `${commonalityUrl}${fileId}`;
            this.setState({
                iframeSrc: iframeSrc
            })
        }
    }
    showDetail =  (record) => {
        // console.log('查看详情：：：', record)
        let {breadcrumbList, seniorBrace,indicatorLevelId} = this.state;
        let threeIndicatorList = []
        threeIndicatorList.push(record)

        let breadcrumbListThree = [];
        breadcrumbListThree.push({name: breadcrumbList[1].name, path: ''})
        breadcrumbListThree.push({name: breadcrumbList[2].name, path: ''})
        breadcrumbListThree.push({name: record.seniorName, path: ''})
        if(indicatorLevelId==='2.5.1.2' || indicatorLevelId==='2.5.2.2'
            || indicatorLevelId==='2.5.2.1'|| indicatorLevelId==='3.5.1.2'
            || indicatorLevelId==='3.5.2.1'|| indicatorLevelId==='3.5.2.2'
            || indicatorLevelId==='4.5.1.2' || indicatorLevelId==='4.5.2.1'|| indicatorLevelId==='4.5.2.2'){
            this.setState({
                indicators:true
            })
        }else {
            this.setState({
                indicators:false
            })
        }
        if(indicatorLevelId==='2.5.1.3' || indicatorLevelId==='2.5.3.1'
            || indicatorLevelId==='3.5.3.1'|| indicatorLevelId==='3.5.1.3'
            || indicatorLevelId==='4.5.1.3'|| indicatorLevelId==='4.5.3.1'){
            this.setState({
                seeTime:true
            })
        }else {
            this.setState({
                seeTime:false
            })
        }

        // console.log("lf1111",indicatorLevelId)
        this.setState({
            threeIndicatorList: threeIndicatorList,
            threeTable: record,
            isSignature: false,
            showDetail:true,
            breadcrumbListThree: breadcrumbListThree,
            showPage: false,
            tabActive: 1,
            istabActive: 0
            // isSignature:false
        }, () =>{
            this.getIndicatorBrace()
        })

    };


    showDetailEffect =(record,type)=>{
        let {effectBrace} = this.state;
        let list = effectBrace.filter(e => e.upseniorName === record.upseniorName)
        let data = {
            reviewId: record.seniorId
        }
        let breadcrumbListThree = [];
        breadcrumbListThree.push({name: record.upseniorName, path: ''})
        breadcrumbListThree.push({name: record.seniorName, path: ''})
        // console.log('breadcrumbListThree',breadcrumbListThree)
        getEffectDetail(data).then(res =>{
            if(res){
                this.setState({
                    effectBraceList: list,
                    breadcrumbListThree: breadcrumbListThree,
                    selectEffect: record,
                    effectDetailList: res,
                    tabActive:1,
                    showDetail:true,
                    showPage: false,
                    isSignature: true,
                    angleType:type

                })
            }
        })

    }

    showList = (record) =>{
        // console.log('001',record)
        this.setState({
            showListe:true,
            uploadLocation: 7,
            midType: record.uplevelCatgory
        }, () =>{
            this.schoolSelfReport()
        })
    }
    goBacklist = () =>{
        this.setState({
            showListe:false
        })
    }
    goBack =  () => {
        let {indicatorLevelId} = this.state
        // console.log("oenoneone", indicatorLevelId)
        let showPage = false;
        let isSignature = true;
        let isLandmark = false;
        if(indicatorLevelId==='2.5.2.1' || indicatorLevelId==='2.5.2.2' ||
            indicatorLevelId==='3.5.2.1' || indicatorLevelId==='3.5.2.2' ||
            // indicatorLevelId==='3.5.3.1' || indicatorLevelId==='2.5.3.1' ||
            indicatorLevelId==='4.5.2.1' || indicatorLevelId==='4.5.2.2'){
            showPage = true
            isSignature = true
            isLandmark = false
        }
        this.setState({
            showDetail:false,
            isLandmark,
            showPage,
            isSignature
        })
        // console.log("+++")
    };
    goBackTwo =  () => {
        // console.log("towtowtowtow")
        this.setState({
            isLandmark: false,
            showDetail:true,
            showPage: false,
            isSignature: true,
        })
    };
    handleOk = async () => {
        this.setState({
            visible: false,
        });
    };
    handleCancel = async () => {
        this.setState({
            visible: false,
        });
    };
    numChange = async () => {

    };

    saveLeftList=(value)=>{
        this.setState({
            saveLeftList: value
        })
    }

    twoLevelValue=(value, item)=>{
        //资金管理指标
        // console.log('typetypetypetype',value.indicatorLevelName)
        let {schoolInfo} = this.state
        let breadcrumbList = [];
        if (schoolInfo.catgory === 1) {
            breadcrumbList.push({name: item.seniorType === 1? '对学校层面建设任务评分' : item.seniorType === 2? '对专业群一层面建设任务评分' : item.seniorType === 3? '对专业群二层面建设任务评分':'', path: ''})
        } else {
            breadcrumbList.push({name: item.seniorType === 1? '对学校层面建设任务评分' : item.seniorType === 2? '对专业群层面建设任务评分':'', path: ''})
        }
        breadcrumbList.push({name: item.indicatorLevelName, path: ''})
        breadcrumbList.push({name: value.indicatorLevelName, path: ''})
        let fiveShow = false;
        let showPage = false;
        let isSignature = false;
        if(value.indicatorLevelId==='2.5.4.2' || value.indicatorLevelId==='3.5.4.2' || value.indicatorLevelId==='4.5.4.2'){
            fiveShow = true
        }
        if(value.indicatorLevelId==='2.5.2.1' || value.indicatorLevelId==='2.5.2.2' ||
            value.indicatorLevelId==='3.5.2.1' || value.indicatorLevelId==='3.5.2.2' ||
            value.indicatorLevelId==='4.5.2.1' || value.indicatorLevelId==='4.5.2.2'){
            showPage = true
            isSignature = true
        }
        let activeKey = '5'
        if (value.indicatorLevelId==='2.5.4.1' || value.indicatorLevelId==='3.5.4.1' || value.indicatorLevelId==='4.5.4.1') {
            activeKey = '6'
            this.setState({
                tabActive: 1,
                activeKey: activeKey,
            }, ()=>{
                this.callbackTwo("1")
            })
        } else {
            this.setState({
                tabActive: 1,
                activeKey: activeKey,
                indicatorLevelId: value.indicatorLevelId,
                seniorType: value.seniorType,
                twoLevelTable: value,
                breadcrumbList,
                showDetail:false,
                showPage:showPage,
                fiveShow: fiveShow,
                isLandmark: false,
                isSignature:isSignature,
                // activeKey:type
                onId:value.indicatorLevelId,
            }, () =>{
                this.getSeniorBrace()
                this.callbackTwo("1")
                // this.schoolSelfReport()
            })
        }

    }

    // submitGrade =() => {
    //     let {saveLeftList, saveStatus} = this.state
    //     this.LeftCompon.props.form.validateFields((err, values) => {
    //         if (!err) {
    //             if (saveStatus) {
    //                 this.setState({saveStatus: false})
    //                 saveThreeLevelIndicator(saveLeftList).then(res =>{
    //                     if(res){
    //                         this.submitScore()
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }

    submitScore=()=>{
        let {saveLeftList,leftList,schoolInfo} = this.state
        this.setState({
            isSpin:true,
            // isConfirm:false
        })
        let schoolScoreList = null;
        let groupOneScoreList = null;
        let groupTwoScoreList = null;
        if (leftList !== null && leftList !== undefined) {
            let seniorTypeSchool = leftList.filter(e => e.seniorType === 1);
            let seniorTypeGroupOne = leftList.filter(e => e.seniorType === 2);
            let seniorTypeGroupTwo = leftList.filter(e => e.seniorType === 3);
            if (seniorTypeSchool !== null && seniorTypeSchool.length > 0) {
                schoolScoreList = seniorTypeSchool
            }
            if (seniorTypeGroupOne !== null && seniorTypeGroupOne.length > 0) {
                groupOneScoreList = seniorTypeGroupOne
            }
            if (seniorTypeGroupTwo !== null && seniorTypeGroupTwo.length > 0) {
                groupTwoScoreList = seniorTypeGroupTwo
            }
        }
        let digit = 3
        let weishu = 2
        let  schoolNumOld = schoolScoreList ? schoolScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupOneNumOld = groupOneScoreList ? groupOneScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupTwoNumOld = groupTwoScoreList ? groupTwoScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let schoolNum = parseFloat((Math.round((schoolNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupOneNum = parseFloat((Math.round((groupOneNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupTwoNum = parseFloat((Math.round((groupTwoNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let expertsScore = 0;
        if(schoolInfo && schoolInfo.catgory === 1){
            let num = (schoolNum * 0.5 + (groupOneNum + groupTwoNum) * 0.25)
            num = parseFloat(num);
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        } else {
            let numOld = ((schoolNum * 0.3) + (groupOneNum * 0.7))
            let num = parseFloat((Math.round((numOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
            // let digit = 2
            num = parseFloat(num);
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        }
        let totalPoints = parseFloat(((schoolInfo ? schoolInfo.provinceScore : 0) * 0.5) + (expertsScore * 0.5))
        // expertsScore = parseFloat(((schoolNum * 0.3) + (groupOneNum * 0.7)).toFixed(2))
        let num = (((schoolInfo ? schoolInfo.provinceGrade : 0) * 0.5) + (expertsScore * 0.5))
        // let digit = 2
        num = parseFloat(num);
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            userId: this.state.userInfo.id,
            // totalScore: parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(digit)),
            totalScore: expertsScore,
            catgory: schoolInfo.catgory
        }
        console.log('data 999', data)
        setTimeout(() => {
            submitScore(data).then(res =>{
                console.log('600110600110600110', res)
                if(res){
                    if(res.result && res.result.length > 0){
                        message.destroy()
                        this.mesWarning(res.result[0],5000)
                        this.setState({
                            isSpin:false,
                            isConfirm:false
                        })
                        // message.warning(res.result[0])
                    } else {
                        this.setState({
                            isConfirm:false
                        })
                        message.destroy()
                        this.mesSuccess('提交成功');
                        this.setState({saveStatus: true,isSpin:false,isResult:res.result,})
                        this.props.history.push('/peerreviewlist')
                    }
                }
            })
        }, 2000)

    }

    handleCancelTwo = () =>{
        this.setState({
            isConfirm:false
        })
    }
    deleteThreeLevelFile = () =>{
        this.setState({
            isConfirm:true
        })
    }
    superior =  (value) => {
        let {seniorBrace} = this.state
        let on = seniorBrace.indexOf(value);
        this.showDetailTwo(seniorBrace[on - 1])
        console.log(seniorBrace[on - 1])
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            uploadLocation: 3,
            fileSuffix: "pdf",
            investYear: 2021,
            upseniorId: this.state.indicatorLevelId,
            seniorId: seniorBrace[on - 1].seniorId
        }
        schoolSelfReport(data).then(res => {
            if (res) {
                this.setState({
                    fiveFileList: res,
                    iframeSrc: res[0] && res[0].fileId ? `${commonalityUrl}${res[0].fileId}` : '',
                    tabActive: 1
                })
            }
        })
    };
    subordinate =  (value) => {
        let {seniorBrace} = this.state
        let down = seniorBrace.indexOf(value);
        this.showDetailTwo(seniorBrace[down + 1])
        console.log(seniorBrace[down + 1])
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            uploadLocation: 3,
            fileSuffix: "pdf",
            investYear: 2021,
            upseniorId: this.state.indicatorLevelId,
            seniorId: seniorBrace[down + 1].seniorId
        }
        schoolSelfReport(data).then(res => {
            if (res) {
                this.setState({
                    fiveFileList: res,
                    iframeSrc: res[0] && res[0].fileId ? `${commonalityUrl}${res[0].fileId}` : '',
                    tabActive: 1
                })
            }
        })
    };
    superiorMetaphase =  (value) => {
        let {schoolList, groupList, placeList} = this.state
        let on = 1;
        if (value.upseniorName === "双高绩效目标实现贡献度") {
            on = schoolList.indexOf(value);
            this.showDetailEffect(schoolList[on - 1])
        }
        if (value.upseniorName === "高水平学校和专业群社会认可度") {
            on = groupList.indexOf(value);
            this.showDetailEffect(groupList[on - 1])
        }
        if (value.upseniorName === "地方政府（含举办方）重视程度") {
            on = placeList.indexOf(value);
            this.showDetailEffect(placeList[on - 1])
        }
    };
    subordinateMetaphase =  (value) => {
        let {schoolList, groupList, placeList} = this.state
        let down = 0;
        if (value.upseniorName === "双高绩效目标实现贡献度") {
            down = schoolList.indexOf(value);
            this.showDetailEffect(schoolList[down + 1])
        }
        if (value.upseniorName === "高水平学校和专业群社会认可度") {
            down = groupList.indexOf(value);
            this.showDetailEffect(groupList[down + 1])
        }
        if (value.upseniorName === "地方政府（含举办方）重视程度") {
            down = placeList.indexOf(value);
            this.showDetailEffect(placeList[down + 1])
        }
    };
    showDetailTwo =  (record) => {
        let {breadcrumbListThree} = this.state;
        let threeIndicatorList = []
        threeIndicatorList.push(record)
        breadcrumbListThree[2] = {name: record.seniorName, path: ''}
        this.setState({
            threeIndicatorList: threeIndicatorList,
            threeTable: record,
            showDetail:true,
            breadcrumbListThree: breadcrumbListThree
        }, () =>{
            this.getIndicatorBrace()
        })
    };
    goBackindex = () =>{
        this.props.history.push('/peerreviewlist')
    }

    charTask=(value)=>{
        this.setState({
            tabActive: value
        })
    }
    Adjustment=(value)=>{
        this.setState({
            tabActive: value,
            iframeSrc: `${commonalityUrl}${path}adjustmentScheme/${this.state.schoolEduCode}/${this.state.schoolInfo ? this.state.schoolInfo.schoolName:""}.pdf`
        })
    }
    lookPagelist = (record) =>{
        // console.log('record...', record)
        this.setState({
            isLook:false
        },()=>{
            let data = {
                schoolEduCode: this.state.schoolEduCode,
                uploadLocation: 2,
                fileSuffix: "pdf",
                investYear: 0,
                seniorId: record.id
            }
            schoolSelfReport(data).then(res => {
                if (res) {
                    this.setState({
                        showDetail:false,
                        isSignature:true,
                        isLook:true,
                        istabActive: 0,
                        isLandmark:true,
                        effectFileList: res ? res : '',
                        iframeSrc: res[0] && res[0].fileId ? `${commonalityUrl}${res[0].fileId}` : ''
                    })
                }
            })
        })

        console.log("lg",this.state.isLook)
    }

    getCaseFile = (index)=>{
        let fiveFileList = this.state.fiveFileList
        console.log('`${commonalityUrl}${fiveFileList[0].fileId}` 99', `${commonalityUrl}${fiveFileList[0].fileId}`)
        this.setState({
            iframeSrc: fiveFileList[index] && fiveFileList[index].fileId ? `${commonalityUrl}${fiveFileList[index].fileId}` : '',
            istabActive: index
        })
    }
    onGuan = () =>{
        this.props.HandleOkguan()
        this.setState({
            isLook:false,
            tabActive:1,
            tabActivea:0,
            tabActiveb:0,
        })
    }

    render() {
        const {leftList,threeIndicatorList,seniorBrace,schoolOtherExplain,schoolInfo,activeKey, columns,
            onId, breadcrumbList,tabActive,showDetail,detailColumns,visible,columns4,showListe,istabActive,fiveShow,columns5,saveLeftList,
            iframeSrc,threeTable,breadcrumbListThree,schoolEduCode,columnslist,showPage,seniorType,isSignature,isLandmark,effectBraceList,selectEffect,loading,grouplist,indicators,
            seeTime,isResult,isConfirm,userMark,tabActivea,angleType,isLook,tabActiveb,tabActiveis} = this.state
        const {indicatorBrace,effectDetailList} = this.props
        console.log('record aaaaa 999', this.state.fiveFileList)
        console.log('iframeSrc aaaaa 999', iframeSrc)
        console.log('effectDetailList 999', effectDetailList)
        // console.log('iframeSrc dizhi', iframeSrc)
        let catgory = schoolInfo ? (schoolInfo.catgory === 1 ? "学校" : "专业群"):""
        let gare = schoolInfo ? (schoolInfo.gear === 1 ? "A" :schoolInfo.gear === 2 ? "B":schoolInfo.gear === 3 ?'C':''):""

        let categoryGear = catgory + gare

        console.log('categoryGear', categoryGear)
        let schoolScoreList = null;
        let groupOneScoreList = null;
        let groupTwoScoreList = null;
        if (leftList !== null && leftList !== undefined) {
            let seniorTypeSchool = leftList.filter(e => e.seniorType === 1);
            let seniorTypeGroupOne = leftList.filter(e => e.seniorType === 2);
            let seniorTypeGroupTwo = leftList.filter(e => e.seniorType === 3);
            if (seniorTypeSchool !== null && seniorTypeSchool.length > 0) {
                schoolScoreList = seniorTypeSchool
            }
            if (seniorTypeGroupOne !== null && seniorTypeGroupOne.length > 0) {
                groupOneScoreList = seniorTypeGroupOne
            }
            if (seniorTypeGroupTwo !== null && seniorTypeGroupTwo.length > 0) {
                groupTwoScoreList = seniorTypeGroupTwo
            }
        }
        let weishu = 2
        let digit = 3
        let  schoolNumOld = schoolScoreList ? schoolScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupOneNumOld = groupOneScoreList ? groupOneScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let  groupTwoNumOld = groupTwoScoreList ? groupTwoScoreList.reduce((sum, e) => sum + Number(e.totalScore || 0), 0) : 0;
        let schoolNum = parseFloat((Math.round((schoolNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupOneNum = parseFloat((Math.round((groupOneNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let groupTwoNum = parseFloat((Math.round((groupTwoNumOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let expertsScore = 0;
        if(schoolInfo && schoolInfo.catgory === 1){
            // expertsScore = parseFloat((schoolNum * 0.5 + (groupOneNum + groupTwoNum) * 0.25).toFixed(2))

            let num = (schoolNum * 0.5 + (groupOneNum + groupTwoNum) * 0.25)
            num = parseFloat(num);
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))

        } else {
            // expertsScore = parseFloat(((schoolNum * 0.3) + (groupOneNum * 0.7)).toFixed(2))
            let numOld = ((schoolNum * 0.3) + (groupOneNum * 0.7))
            let num = parseFloat((Math.round((numOld + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
            num = parseFloat(num);
            expertsScore = parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))

        }
        let totalNum = (((schoolInfo ? schoolInfo.provinceGrade : 0) * 0.5) + (expertsScore * 0.5))
        // let digit = 2
        totalNum = parseFloat(totalNum);
        let totalPoints = parseFloat((Math.round((totalNum + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(weishu))
        let  achievementList= [
            {
                title: <div>{this.props.angleType==="1"?(<div>标志性成果序号</div>):(<div>视角序号</div>)}</div>,
                dataIndex: 'serialNumber',
                key: 'serialNumber',
                align: 'center',
                width: 100,
                // fixed: 'left',
                render: (text, record,index) => (
                    <div className=''>
                        {this.state.effectBraceList?<span>{(this.state.effectBraceList.indexOf(this.state.selectEffect) +1)+"-"+(index+1)}</span>:''}
                    </div>
                )
            },
            {
                title: `${this.props.angleType==="1"?"标志性成果名称":"视角名称"}`,
                dataIndex: 'effectName',
                key: 'effectName',
                align: 'center',
                width: 120,
                fixed: 'left',
                render: (text) => (
                    <div className='text_warptitle'>
                        {text}
                    </div>
                )
            },
            {
                title: `${this.props.angleType==="1"?'标志性成果简介/情况说明':'视角简介/情况说明'}`,
                dataIndex: 'simpleDesc',
                key: 'simpleDesc',
                align: 'center',
                width: 500,
                render: (text) => (
                    <div className='text_warptitle'>
                        {text}
                    </div>
                )
            },
            {
                title: '支撑度',
                dataIndex: 'supportCondition',
                key: 'supportCondition',
                align: 'center',
                width: 120,
                render: (text, record,index) => (
                    <div className=''>
                        {record.supportCondition===1?<span style={{color:'rgba(67, 207, 124, 1)'}}>较高</span>:
                            record.supportCondition===2?<span style={{color:'rgba(215,87,70, 1)'}}>较弱</span>:
                                record.supportCondition===3? <span style={{color:'rgba(255, 141, 26, 1)'}}>一般</span>:""}

                    </div>
                )
            },
            // {
            //     title: '备注',
            //     dataIndex: 'remark',
            //     key: 'remark',
            //     align: 'center',
            //     width: 140,
            //     render: (text, record,index) => (
            //         <div className=''>
            //             <span style={{color:'rgba(67, 207, 124, 1)',fontSize:'14px'}}>文本展示</span>
            //         </div>
            //     )
            // },
            {
                title: '操作',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                width: 120,
                // fixed: 'right',
                render: (text, record,index) => (
                    <div className='seebtn'>
                        <span onClick={()=>this.lookPagelist(record)}>查看详情</span>
                    </div>
                )
            },

        ]
        let  detailColumns1 = [
            {

                title: '四级绩效指标',
                dataIndex: 'indicatorName',
                key: 'indicatorName',
                align: 'center',
                // fixed: 'left',
                // ellipsis: true,
                width:300,
                render: (text) => (
                    <div className='text_title' style={seeTime?{textAlign:'center'}:{textAlign:'left'}}>
                        {text}
                    </div>
                )
            },
            {
                title:<div>实施期满<br/>目标值</div>,
                dataIndex: 'targetValue',
                key: 'targetValue',
                align: 'center',
                width: 100,
                render: (text) => (
                    <span style={indicators?{textAlign:'left',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                            {text}
                        </span>
                )
            },
            {
                title: '指标单位',
                dataIndex: 'indicatorMeasure',
                key: 'indicatorMeasure',
                align: 'center',
                width: 100,
                render: (text) => (
                    <span style={indicators?{textAlign:'center',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                            {text}
                        </span>
                )
            },
            {
                title: indicators?<div>累计<br/>目标值</div>:'累计目标值',
                dataIndex: 'cumulativeTargetValue',
                key: 'cumulativeTargetValue',
                align: 'center',
                width: 100,
                render: (text) => (
                    <span style={indicators?{textAlign:'left',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                            {text}
                        </span>
                )
            },
            {
                title:indicators?<div>累计<br/>实现值</div>: '累计实现值',
                dataIndex: 'cumulativeRealValue',
                key: 'cumulativeRealValue',
                align: 'center',
                width:100,
                render: (text) => (
                    <span style={indicators?{textAlign:'left',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                            {text}
                        </span>
                )
            },
            {
                title: '中期目标完成度',
                dataIndex: 'cumulativeMetaphaseValue',
                key: 'cumulativeMetaphaseValue',
                align: 'center',
                width:100,
                render: (text) => (
                    <span style={indicators?{textAlign:'center',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                            {text}
                        </span>
                )
            },
            {
                title: '终期累计完成度',
                dataIndex: 'cumulativeCompleteValue',
                key: 'cumulativeCompleteValue',
                align: 'center',
                width:100,
                render: (text) => (
                    <span style={indicators?{textAlign:'center',width:'100%',display:'block'}:{textAlign:'center',width:'100%',display:'block'}}>
                                {text}
                            </span>
                )
            },
            {
                title: '支撑度',
                dataIndex: 'supportCondition',
                key: 'supportCondition',
                align: 'center',
                // fixed: 'right',
                width:100,
                render: (text, record,index) => (
                    <div className=''>
                        {record.supportCondition===1?<span style={{color:'rgba(67, 207, 124, 1)'}}>较高</span>:
                            record.supportCondition===2?<span style={{color:'rgba(215,87,70, 1)'}}>较弱</span>:
                                record.supportCondition===3? <span style={{color:'rgba(255, 141, 26, 1)'}}>一般</span>:""}

                    </div>
                )
            },
        ]
        //标志性成果预览表格
        const {getFieldDecorator }=this.props.form
        return (
            <div className="Comment-schoolRating">
                <div className={'onGuan'} onClick={()=>this.onGuan()}>
                    <i>×</i>
                </div>
                <div className='detailTab_top'>
                    {
                        this.props.isAngleType === 0?<div className={'detailTabL'}>
                            <span className={tabActive===1 ? "active" : ''}  onClick={()=>this.callbackTwo(1)}>指标完成概览</span>
                            <span className={tabActive===2 ? "active" : ''} onClick={()=>this.schoolSelfReportFive()}>佐证材料和案例</span>
                        </div>:
                            <div className={'detailTabL'}>
                                <span className={tabActiveis===1 ? "active" : ''}>{this.props.isAngleType === 1?'标志性成果预览':'视角预览'}</span>
                            </div>
                    }

                </div>
                {
                     tabActive === 2?
                        <div className='detailTab_list'>

                                {this.state.fiveFileList && this.state.fiveFileList.length > 0 ? this.state.fiveFileList.map((item, index) =>{
                                    return (
                                        <div className={'detail_lis'}>
                                        <span className={tabActivea=== index ? "active" : ''}  onClick={()=>this.callbackList(index,item)}>{item.fileName}</span>
                                        </div>
                                            )

                                }): ''
                                }
                        </div>:''
                }
                {
                    isLook?<div>
                        {
                            tabActive !== 2 && tabActiveis === 1?
                                <div className='detailTab_list'>
                                    {this.state.effectFileList && this.state.effectFileList.length > 0 ? this.state.effectFileList.map((item, index) =>{
                                        return (
                                            <div className={'detail_lis'}>
                                                <span className={tabActiveb=== index ? "active" : ''}  onClick={()=>this.callbackListTwo(index)}>{item.fileName}</span>
                                                {/*<span className={tabActiveb===2 ? "active" : ''}  onClick={()=>this.callbackListTwo(2)}>指标完成概览</span>*/}
                                            </div>
                                        )
                                    }) : ''
                                    }

                                </div>:''
                        }
                        </div>:''
                }

                <div className={'detail_bottom'}>
                    <div className={'table_list'}>
                            <div>
                                {tabActive===1?<div>
                                    {
                                            this.props.isAngleType !== 0 ?
                                                <div>
                                                    {
                                                        isLook?<div>
                                                            <EvaluationReportRight iframeSrc={iframeSrc}/>
                                                        </div>:<Table columns={achievementList} dataSource={effectDetailList} className={'Table_walist'}
                                                                      scroll={{x: 'max-content',y:400}}
                                                                      locale={{
                                                                          emptyText: <>
                                                                              <div className="Empty-content">
                                                                                  <div className="text">
                                                                                      <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                                                      <span>暂无数据</span>
                                                                                  </div>
                                                                              </div>
                                                                          </>
                                                                      }}
                                                                      pagination={
                                                                          false
                                                                      }
                                                        />
                                                    }
                                                </div>:
                                                    <Table columns={detailColumns1} dataSource={indicatorBrace}
                                                           scroll={{x:'max-content'}}
                                                           locale={{
                                                               emptyText: <>
                                                                   <div className="Empty-content">
                                                                       <div className="text">
                                                                           <img src={require('../../../../assets/img/synchrodatment/loagin.png')}/>
                                                                           <span>暂无数据</span>
                                                                       </div>
                                                                   </div>
                                                               </>
                                                           }}
                                                           pagination={
                                                               false
                                                           }
                                                    />
                                    }

                                </div>:<div>
                                    <EvaluationReportRight iframeSrc={iframeSrc}/>
                                </div>}
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

schoolRating = Form.create()(schoolRating);
export default schoolRating;
