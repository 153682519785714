// 资讯管理父级
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import OBreadcrumb from '../../components/BreadcrumbNews'
import './index.scss'
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';
import {menuListByUserId} from "../../api/menu"
import Cookies from "js-cookie";
const { Sider, Content } = Layout;

const mapStateToProps = ({ DOUBLEHIGH }) => {
    const { newsmanlist } = DOUBLEHIGH;
    return {
        newsmanlist
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}


export default connect(mapStateToProps, mapDispatchToProps)
(class Doublehigh extends React.Component {
    state={
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo.id : "",
        menuList:[]
    }
    // 初始化
    componentDidMount() {
        this.menuListByUserId();

    }
    componentDidUpdate() {
        document.body.scrollTop = -60
    }

    //获取菜单列表
    async menuListByUserId() {
        let userId = this.state.userId
        let menuList= await menuListByUserId(userId)
        this.setState({
            menuList
        })

    }
    render() {

        let path = this.props.location.pathname;
        let {menuList}=this.state
        // let { dataSource } = this.props;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        let two, three;
        one.menuUrl = one.path;
        one.menuName = one.name;

        menuList&&menuList.forEach(v => {
            if( v.children){
                v.children.forEach(k => {
                    console.log("v",v.menuUrl)
                    if (k.menuUrl === path) {
                        two = v;
                        three =!k.isHade?k:'';
                    }
                })
            }else{
                if(v.menuUrl===path){
                    two = v;
                }
                console.log("v.menuUrl1",v.menuUrl)
            }

        })


        let arr = [{ ...one, title: one.name }, two, three];
        return (
            <Layout className='resource_box zx_newscont'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    <Menu {...this.props} menuList={menuList} />
                </Sider>
                <Layout className={'rightcontwrap'}>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content className="warpheight" style={{ margin: '20px 20px', minHeight:' calc(100vh - 141px)',position:'relative',display:'block'}}>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
})
