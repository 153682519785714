import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Form, Input, Button} from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { LOGIN_SUBMITFN } from '../../store/OBS/login/action'
import { changeIndex } from '@store/OBS/main/action'
import { userLogin } from '../../api/login'
import { getDhAllCommonDic } from '../../api/synchrodatment'
import './index.scss'
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {ProjectName,LoginLogo} from '../../assets/svg'
import {AESKey} from '../../config/secret'
class Login extends Component {
    componentDidMount() {
        this.removeCookies();
        this.setRemUnit();
        document.addEventListener("keydown", this.onKeyDown)
        window.addEventListener("resize", this.setRemUnit);
        window.addEventListener("pageshow", function (e) {
            if (e.persisted) {
                this.setRemUnit();
            }
        });
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.onKeyDown)
    }
    onKeyDown = (e) => {
        switch( e.keyCode) {
            case 13: this.denglu()
                break
        }
    }
    removeCookies() {
        Cookies.remove('token');
        Cookies.remove('schoolInfo');
        Cookies.remove('userInfo');
        Cookies.remove('info');
        Cookies.remove('portraitCache');
        Cookies.remove('list2');
        Cookies.remove('buttonPerms');
    }

    denglu = () =>{
        //登录
        this.props.form.validateFields((err, values) => {
            this.removeCookies();
            if (!err) {
                let un = this.props.form.getFieldValue('loginName') //用户
                let password1= this.props.form.getFieldValue('passWord') //获取输入密码

                let key  = CryptoJS.enc.Utf8.parse(AESKey);
                let iv   = CryptoJS.enc.Utf8.parse(AESKey);
                let password2 = CryptoJS.AES.encrypt(password1, key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.Pkcs7});
                let pwd = CryptoJS.enc.Base64.stringify(password2.ciphertext).toString();
                let data = {un, pwd}
                userLogin(data).then(res => {
                    if(res){
                        let info = {token:res.token,schoolInfo:res.schoolInfo,userInfo:res.userInfo};
                        let list2 =  [{"id": '1', "menuName": '默认' ,"menuUrl": ''}]  //面包屑使用
                        Cookies.set('ssInfo', info);
                        Cookies.set('portraitCache', res.userInfo.portraitId);
                        Cookies.set('list2', list2);
                        Cookies.set('buttonPerms', res.buttonPerms);
                        this.props.history.push({
                            pathname: '/main/doublehigh/',
                        })

                    }
                })
                getDhAllCommonDic({}).then(res => {
                    localStorage.setItem("allCommonDic", JSON.stringify(Object.assign({"":""}, res)));
                })
            }
        });

    }
    setRemUnit=()=> {
        let docEl = document.documentElement;
        let minWidth = docEl.clientWidth > 1500 ? docEl.clientWidth : 1500;
        var rem = minWidth / 19.2;  // 这里默认是10等份，手动改为19.2，此时1rem=1920/19.2px即100px。（设计稿是1920px的）
        docEl.style.fontSize = rem + "px";
    }

    // reset rem unit on page resize
    render() {
        const { getFieldDecorator } = this.props.form;
        return (

            <div className="Login">
                <div className={'login-center'}>
                    <div className={'logintop'}>
                        <div className={'logintop-left'}><img src={require('../../assets/img/login-logo.png')}/></div>
                        <div className={'text'}>双高计划任务智能管理平台</div>
                    </div>
                    <div className='center'>
                        <div className={'center-left'}><img src={require('../../assets/img/login-leftbg.png')}/></div>
                        <div className='login-cont'>
                            <div className='title1'>
                                <div className={'weclome'}>欢迎登录</div>
                                {/*<div className='welcome'>遇见未来·咫尺匠心</div>*/}
                            </div>
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('loginName', {
                                        rules: [
                                            {
                                                required: true,
                                                // pattern: new RegExp(/^[1][0-9]{10}$/),
                                                message: '请输入手机号!',

                                            },
                                        ],
                                    })(<Input placeholder={'请输入手机号'} maxLength={11} autocomplete="off" />)}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('passWord', {
                                        rules: [
                                            {
                                                required: true,
                                                /*   pattern:new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/),*/
                                                message: '请输入6-10位字母数字组合密码',
                                            },
                                        ],
                                    })(<Input placeholder={'请输入登录密码'} type="password" maxLength={10} autocomplete="off"/>)}
                                </Form.Item>
                                <Form.Item>
                                    <Button className='Login-btndl'   onClick={() => this.denglu()}>登录</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ LOGIN }) => {
    return {
        userFlag: LOGIN.userFlag,
        uname: LOGIN.uname,
        pwd: LOGIN.pwd
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo(data) {
            dispatch(LOGIN_SUBMITFN(data))
        },
        changeIndex(data) {
            dispatch(changeIndex(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(Login)))
