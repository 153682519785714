/*设置密码*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Form, Input} from "antd";


@withRouter
class PasswordCont extends Component {
    state = {

    }
    componentDidMount() {
        this.props.formRef(this)
    }
    getFormValues = () => {
        let data = {
            pwd: this.props.form.getFieldValue('partLevelCode'),
        };
        this.props.form.validateFields((err, data) => {
            if (err) {
                return data = undefined
            }
        });
        return data;
    };

    render() {
        let {getFieldDecorator}=this.props.form
        return (
           <div>
               <Form>
                   <Form.Item className="form_li">
                       <span className="span_firs">设置密码</span>
                       {getFieldDecorator('partLevelCode', {
                           rules: [
                               {
                                   required: false,
                                   message: `请输入密码`,
                               },
                               {
                                   type: 'string',
                                   required: true,
                                   min: 6,
                                   max: 12,
                                   pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$',
                                   message: '密码为6-12位字母+数字组合!',
                               },
                           ],
                           })(<Input placeholder="请输入密码" />)
                       }
                   </Form.Item>
                   <i className="i_num">6~12位，需包含数字和字母</i>
               </Form>
           </div>
        );
    }
}

PasswordCont = Form.create()(PasswordCont);
export default PasswordCont;
