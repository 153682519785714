/*建设任务管理*/
import React, {Component} from "react";
import {Form, Input, Select, Button, Table, Modal, Icon, message, Menu, Dropdown, Drawer, Tree,} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Cookies from "js-cookie";
import Readdetailist from "../../../readdetails";
import Uploadfile from './components/upload/index'
import {setVisablePerson,queryFileListById, updateFileName, createNewFolder,removeFile, removeFiles, moveFile, moveFiles, exportFiles,downFiles, exportFileFromServer, queryFileTreeQo, previewFile,queryVisablePerson} from "../../../../api/project/index"
import S from "gongyongicon";
const {Option} = Select;
const { TreeNode } = Tree

@withRouter
class Wendang extends Component {
    state = {
        wdlistcolumns: [
            {
                title: '名称',
                dataIndex: 'resourceName',
                sorter: true,
                key: 'resourceName',
                width: '30%',
                render: (text, record) => {
                    return <div>
                        {record.isDir === 1 ? <div onClick={()=>this.queryFileListById(record)} style={{cursor: 'pointer'}}><img src={require('@/assets/imgnew/wenjianjia.png')} style={{width: '22px'}} alt={'文件夹'}/><span style={{marginLeft: '11px'}}>{text}</span></div>:
                            <div onClick={()=>this.handPush(record)} style={{cursor: 'pointer'}}>
                        {/*{record.isDir === 1 ? <div onClick={()=>this.queryFileListById(record)} style={{cursor: 'pointer'}}><img src={require('@/assets/imgnew/wenjianjia.png')} style={{width: '22px'}} alt={'文件夹'}/><span style={{marginLeft: '11px'}}>{text}</span></div>:*/}
                        {/*    <div>*/}
                        {record.fileType === 1 ? <img src={require('@/assets/imgnew/word.png')} alt={'word'}/> :
                            record.fileType === 2 ? <img src={require('@/assets/imgnew/ppt.png')} alt={'ppt'}/> :
                            record.fileType === 3 ? <img src={require('@/assets/imgnew/excel.png')} alt={'excel'}/> :
                            record.fileType === 4 ? <img src={require('@/assets/imgnew/shipin.png')} alt={'shipin'}/> :
                            record.fileType === 5 ? <img src={require('@/assets/imgnew/yinpin.png')} alt={'yinpin'}/> :
                            record.fileType === 6 ? <img src={require('@/assets/imgnew/image.png')} alt={'image'}/> :
                            record.fileType === 7 ? <img src={require('@/assets/imgnew/pdf.png')} alt={'pdf'}/> :
                            <img src={require('@/assets/imgnew/weizhi.png')} alt={'weizhi'}/>}
                            <span style={{marginLeft: '11px'}} className={'resourceName'}>{text}</span>
                            </div>
                        }
                    </div>
                }
            },
            {
                title: '大小',
                dataIndex: 'fileSize',
                sorter: true,
                key: 'fileSize',
                width: '15%',
                align: 'center'
            },
            {
                title: '创建者',
                dataIndex: 'createrName',
                width: '15%',
                key: 'createrName',
                align: 'center'
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                sorter: true,
                width: '20%',
                key: 'updateTime',
                align: 'center',
                render: (text)=>{
                    return text.replace('T',' ')
                }
            },
            {
                title: '操作',
                dataIndex: 'handle',
                width: '20%',
                key: 'handle',
                align: 'center',
                render: (text, record) => {
                    return <div className={'oper'}>
                        {record.isDir === 0 ? <span><a href={window.$$fujinDownload(record.uploadFileId+"/"+record.resourceName)}><span style={{color: '#40a9ff'}}>下载</span></a>
                                <div className='line'></div></span> : ''}
                        <span className='styleA' onClick={()=>this.chongmm(record)}>重命名</span>
                        <div className='line'></div>
                        <Dropdown overlay={<Menu>
                            <Menu.Item><span onClick={()=>this.remove(record)} style={{color: '#40a9ff'}}>{record.isDir === 1 ? '移动文件夹' : '移动文件'}</span></Menu.Item>
                            <Menu.Item><span style={{color: '#40a9ff'}} onClick={()=>{this.setState({deleteFile: true, yulanrecord: record})}}>{record.isDir === 1 ? '删除文件夹' : '删除文件'}</span></Menu.Item>
                            {record.isDir === 1 && record.parentId === '0' ? <Menu.Item><span onClick={()=>this.setFileVisable(record.id)} style={{color: '#40a9ff'}}>可见性设置</span></Menu.Item> : ''}
                        </Menu>}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                更多 <Icon type="down" />
                            </a>
                        </Dropdown>
                    </div>
                }
            },
        ],
        dataSource: [],
        wjname: '',
        fileId: '',
        resourceId:'',
        projectId:this.props.match.params.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id,
        parentId: 0,
        orderMark: 0,
        condition: 0,
        uploading: false,
        onPush:false,
        deleteFile: false,
        deteteFiles: false,
        record: '',//删除文件存值
        removeFileparentId: '',//移动文件选中id
        removeFileid: '',//移动文件id
        moveFilesVisible: false, //批量移动文件弹框·
        backUpParentId: [],
        queryFileTreeQoData: [],//所有文件夹
        previewFileRes: [],//预览信息
        yulanrecord: [],//预览更多用到的数据
        userInfoId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        userInfoisAdmin: JSON.parse(Cookies.get('ssInfo')).userInfo.isAdmin,
        selectedUserIds: [],//弹窗选中的数据ids
        userListAll:[],
        selectedUserRow: [],//弹窗选中的数据
        selectedUserRowLeft: [],//弹窗选中的数据
        selectedUserRowRight: [],//弹窗选中的数据
        selectedUserRowRightInit:[],
        selectedUserRowAll:[],
        personnelCount:0,
        fileAuth:0,//文件夹是否设置可见性，0否1是
        moveFileTitleName:'',
        selectedRows: [],  //文档复选行
        columnsTable: [
            {
                title: '用户姓名',
                dataIndex: 'humanName',
                key: 'humanName',
                align: 'center',
            },
            {
                title: '手机号码',
                key: 'phone',
                dataIndex: 'phone',
                align: 'center',
            },
            {
                title: '所属组织',
                key: 'orgName',
                dataIndex: 'orgName',
                align: 'center',
            },

        ],
        loading: false,
        isDir:0,
        ydwjjVisible: false, //移动文件夹提示弹窗
        tableSelectlength: 0,
        selectedRowKeys: []
    }

    componentDidMount() {
        this.queryFileListById()
    }
    setVisablePerson = async  ()=>{
        let selectedUserIds = []
        this.state.selectedUserRowRight.map(item=>{
            selectedUserIds.push(item.id)
        })
        let data = {
            memberIds:selectedUserIds,
            fileId:this.state.fileId,
            fileAuth:this.state.fileAuth,
        }
        await setVisablePerson(data).then(res=>{
            this.mesSuccess('设置可见性成功')
            this.setState({selectedUserRowLeft:[]})
        })
    }
    queryVisablePerson = async ()=>{
        let data = {
            projectId:this.state.projectId,
            fileId:this.state.fileId,
            schoolId:this.state.schoolId
        }
        await queryVisablePerson(data).then(res=>{
            this.setState({
                fileAuth:res.document.fileAuth,
                userListAll:res.userListAll,
                selectedUserIds:[],
                // selectedUserRow:res.kjxUserList,
                selectedUserRowLeft:res.fkjxUserList,
                selectedUserRowRight:res.kjxUserList,
                selectedUserRowRightInit:res.kjxUserList,
                personnelCount:res.kjxUserList.length
            })
        })
    }
    queryFileListById =async (record, m) =>{
        let parentId = ''
        if(record){
            parentId = record.id
            if(this.state.backUpParentId.indexOf(record.parentId) === -1){
                this.state.backUpParentId.push(record.parentId)
            }

            this.setState({backUpParentId: this.state.backUpParentId, parentId: record.id})

        }else if(m===0){
           parentId = this.state.parentId
        }else{
            parentId = 0
        }
        let data = {
            parentId: parentId,
            projectId: this.state.projectId,
            schoolId: this.state.schoolId,
            orderMark: this.state.orderMark,
            condition: this.state.condition
        }
        await queryFileListById(data).then(res=>{
            if(res){
                this.setState({dataSource: res,selectedRowKeys:[],selectedRows:[]})
            }
        })
    }
    // 返回上一级
    backup = async ()=>{
        let backUpParentId = this.state.backUpParentId
        let parentId = backUpParentId[backUpParentId.length - 1]
        if(parentId.length>0){
            backUpParentId.pop()
        }
        let data = {
            parentId,
            projectId: this.state.projectId,
            schoolId: this.state.schoolId,
            condition: 0,
            orderMark:0
        }
        await queryFileListById(data).then(res=>{
            if(res){
                this.setState({dataSource: res, backUpParentId: backUpParentId, parentId,selectedRowKeys:[]})
                this.setState({tableSelectlength: 0});
                this.setState({selectedRows: []});
                this.setState({selectedRowKeys: []});
            }
        })
    }
    chongmm = (record) =>{
        this.setState({cmmvisible: true, fileId: record.id, wjname: '重命名',isDir:record.isDir,wjTotalName:record.resourceName})
        this.props.form.setFieldsValue({wjname: record.isDir===1?record.resourceName:record.resourceName.substring(0, record.resourceName.lastIndexOf("."))})
    }
    //重命名、新建文件夹
    updateName =  () =>{
        this.props.form.validateFields( async (err, values) => {
            if (!err) {

                let fileName = this.state.isDir===1?values.wjname:values.wjname+this.state.wjTotalName.substring(this.state.wjTotalName.lastIndexOf("."))
                if(this.state.wjname === '重命名'){
                    let fileId = this.state.fileId
                    let parentId = this.state.parentId
                    let schoolId = this.state.schoolId
                    let projectId = this.state.projectId
                    let data = {
                        schoolId,
                        projectId,
                        parentId,
                        fileName,
                        fileId
                    }
                    await updateFileName(data).then(res=>{
                        if(res.message === 'success'){
                            this.queryFileListById('',0)
                            this.setState({cmmvisible: false,})
                            this.props.form.setFieldsValue({wjname: ''})
                        }

                    })
                }else{
                    let parentId = this.state.parentId
                    let createrId = JSON.parse(Cookies.get('ssInfo')).userInfo.id
                    let createrName = JSON.parse(Cookies.get('ssInfo')).userInfo.humanName
                    let folderName = values.wjname
                    let projectId = this.state.projectId
                    let schoolId = this.state.schoolId
                    let data = {
                        parentId,
                        createrId,
                        createrName,
                        folderName,
                        projectId,
                        schoolId
                    }
                    await createNewFolder(data).then(res=>{
                        this.queryFileListById('',0)
                        this.setState({cmmvisible: false})
                        this.props.form.setFieldsValue({wjname: ''})
                    })
                }

            }
        })
    }
    //删除文件
    removeFile = async (record) =>{
        this.setState({deleteFile: true});
        console.log("删除文件：：",JSON.stringify(record));
        let data = {
            fileId: record.id,
            isDir: record.isDir,

        }
        await removeFile(data).then(res=>{
            this.queryFileListById('',0)
            this.setState({deleteFile: false, onPush: false})
            this.mesSuccess('删除成功');
        })
    }



    getkjxOptions = (e) =>{
        this.setState({fileAuth:e})
    }

    persononChange = (checkedList) =>{
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.state.kejianperson.length,
            checkAll: checkedList.length === this.state.kejianperson.length,
        });
    }
    onCheckAllChange = e => {
        let xzoption = []
        this.state.kejianperson.map((item)=>{
            xzoption.push(item.name)
        })
        this.setState({
            checkedList: e.target.checked ? xzoption : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    // 上传视频
    handUpload = ()=>{
        this.setState({
            UploadVideo:true
        })
    }
    handPush = async (record)=>{
        console.log('record',record)
        let fileId = record.id
        const {projectId, schoolId,resourceId} = this.state
        let data = {
            fileId,
            projectId,
            schoolId,
            resourceId,
        }
        await previewFile(data).then(res=>{
            if(res){
                if(res.transStatus === '0'){
                    this.setState({previewFileRes: res,resourceId: res.resourceId},()=>{
                        this.setState({
                            onPush:true,
                            yulanrecord: record,
                        })
                    })
                }else if(res.transStatus === '1'){
                    this.setState({previewFileRes: res,resourceId: res.transId},()=>{
                        this.setState({
                            onPush:true,
                            yulanrecord: record,
                        })
                    })
                }else if(res.transStatus === '2'){
                    message.error('转码失败');
                }
                else if(res.transStatus === '3'){
                    message.warning('转码中');
                }
            }
        })
    }
    onClose = () =>{
        this.setState({
            onPush:false,
        })
    }
    handleChilk = async (pagination, filters, sorter) =>{
        if(sorter){
            if(sorter.order){
                let condition = ''
                let orderMark = ''
                if(sorter.order==='ascend'){
                    orderMark = 0
                }else if(sorter.order==='descend'){
                    orderMark = 1
                }
                if(sorter.column.title === '名称'){
                    condition = 0
                }else if(sorter.column.title === '大小'){
                    condition = 1
                }else if(sorter.column.title === '更新时间'){
                    condition = 2
                }
                this.state.condition=condition
                this.setState({condition:condition, orderMark: orderMark}, () => this.queryFileListById('',0));
            }
        }
    }
    // 递归渲染树形菜单方法
    renderTree = (data) => {
        return data.map(item => {
            if (!item.children) {
                    return(
                        <TreeNode title={item.resourceName} key={item.id} icon={<img src={require('@/assets/imgnew/wenjianjia.png')} alt={'wenjianjia'} style={{width: '22px',marginLeft: '-11px'}}/>}>
                        </TreeNode>
                    )
            } else {
                            if(item.id === this.state.removeFileid){
                                return <TreeNode title={item.resourceName} key={item.id} icon={<img src={require('@/assets/imgnew/wenjianjia.png')} alt={'wenjiajia'} style={{width: '22px',marginLeft: '-11px'}}/>} disabled={true}>
                                    {/*{this.renderTree(item.children)}*/}
                                </TreeNode>
                            }else{
                                return <TreeNode title={item.resourceName} key={item.id} icon={<img src={require('@/assets/imgnew/wenjianjia.png')} alt={'wenjiajia'} style={{width: '22px',marginLeft: '-11px'}}/>}>
                                    {this.renderTree(item.children)}
                                </TreeNode>
                            }

            }
        })
    }
    //移动文件
    remove = async (record) =>{
        this.setState({removevisible: true, removeFileid: record.id,moveFileTitleName: record.isDir === 1 ? '移动文件夹' : '移动文件'})
        let {projectId,schoolId} = this.state
        let data ={
            projectId,
            schoolId
        }
        await queryFileTreeQo(data).then(res=>{
            this.setState({queryFileTreeQoData: res})
        })
    };
    //移动文件
    removes = async () =>{
        this.setState({moveFilesVisible: true, moveFileTitleName: '批量移动文件'});
        let {projectId,schoolId} = this.state;
        let data ={
            projectId,
            schoolId
        };
        await queryFileTreeQo(data).then(res=>{
            this.setState({queryFileTreeQoData: res})
        })
    };
    // 批量移动选中的文件及文件夹
    moveFiles = async () =>{
        let {removeFileid, removeFileparentId} = this.state;
        if(removeFileid !== removeFileparentId){
            let data ={
                schoolId:this.state.schoolId,
                projectId: this.state.projectId,
                documentsList:this.state.selectedRows,
                parentId: removeFileparentId,
                currentParentId: this.state.parentId
            };
            await moveFiles(data).then(res=>{
                if(res){
                    this.setState({moveFilesVisible: false, ydwjjVisible: false});
                    this.queryFileListById('',0);
                    this.setState({tableSelectlength: 0});
                    this.setState({selectedRows: []});
                    this.setState({selectedRowKeys: []});
                }
            }).catch(err=>{
                if(err){
                    this.setState({moveFilesVisible: false, ydwjjVisible: false});
                    this.queryFileListById('',0);
                    this.setState({tableSelectlength: 0});
                    this.setState({selectedRows: []});
                    this.setState({selectedRowKeys: []});
                }
            })
        }else{
            this.mesWarning('不能将文件移动到自身或其子目录下');
            this.setState({ydwjjVisible: false})
        }
    };
    // 移动文件夹选中的文件
    removeFileSelect =(selectedKeys)=>{
        this.setState({removeFileparentId: selectedKeys.toString()})
    }
    moveFile = async () =>{
        let {removeFileid, removeFileparentId} = this.state
        if(removeFileid !== removeFileparentId){
            let data ={
                schoolId:this.state.schoolId,
                projectId: this.state.projectId,
                fileId: removeFileid,
                parentId: removeFileparentId
            }
            await moveFile(data).then(res=>{
                if(res){
                    this.setState({removevisible: false, ydwjjVisible: false})
                    this.queryFileListById('',0)
                }
            }).catch(err=>{
                if(err){
                    this.setState({ydwjjVisible: false})
                }
            })
        }else{
            this.mesWarning('不能将文件移动到自身或其子目录下');
            this.setState({ydwjjVisible: false})
        }
    }
    showCmmvisible = () =>{
        this.setState({cmmvisible: true,wjname: '',isDir:1})
        this.props.form.setFieldsValue({wjname: ''})
    }
    handleUpload = () =>{
        this.child.handlesave()
    }

    onRef = (ref) => {
        this.child = ref
    }
    uploadVideo = () =>{
        this.setState({UploadVideo: false})
    }
    //预览查看详情
    getChildMethod = () =>{
        this.child.handonclick()
    }
    getChildValue=(value)=>{
        this.setState({loading: value})
    }

    setFileVisable =(fileId)=>{
        this.setState({kjxvisible: true,fileId:fileId},()=>{
            this.queryVisablePerson()
        })
    }
    //添加成员
    tianjiaCy = (item) => {
        if(item){

            let selectedUserRowRightInit = this.state.selectedUserRowRightInit
            let selectedUserRowRight = this.state.selectedUserRowRight//已选
            let selectedUserRowLeft = this.state.selectedUserRowLeft//已选
            let selectedUserIds = this.state.selectedUserIds //已选id
            let id = item.id

            // 如果初始化可见性成员包含该用户——》删除，同时右侧也删除该用户——》左侧新增
            selectedUserRowRightInit.map((item1,index)=>{
                if(item1.id === id){
                    selectedUserRowRightInit.splice(index,1)
                    selectedUserRowLeft.push(item)
                }
            })
            selectedUserRowRight.map((item1,index)=>{
                if(item1.id === id){
                    selectedUserRowRight.splice(index,1)
                }
            })
            selectedUserIds.map((item1,index)=>{
                if(item1 === id){
                    selectedUserIds.splice(index,1)
                }
            })

            this.setState({
                selectedUserIds: selectedUserIds,
                selectedUserRowLeft: selectedUserRowLeft,
                selectedUserRowRight: selectedUserRowRight,
                personnelCount: selectedUserRowRight.length
            })
        }
    }
    //批量删除文件
    deleteFiles = ()=>{
        let data = {
            fileIdList:this.state.selectedRows,
        };
        removeFiles(data).then(res=>{
            if(res){
                this.setState({deleteFiles: false});
                this.queryFileListById('',0);
                this.setState({tableSelectlength: 0});
                this.setState({selectedRows: []});
                this.setState({selectedRowKeys: []});
            }
        }).catch(err=>{
            if(err){
                this.setState({deleteFiles: false});
                this.queryFileListById('',0);
                this.setState({tableSelectlength: 0});
                this.setState({selectedRows: []});
                this.setState({selectedRowKeys: []});

            }
        })
    };

    setVisablePersonOk = ()=>{
        this.setVisablePerson()
        this.setState({kjxvisible:false})
    }

    downFiles = async () =>{
        let selectedRows = this.state.selectedRows
        let documentIds = []
        for (let i = 0; i < selectedRows.length; i++) {
            documentIds.push(selectedRows[i].id)
        }
        console.log("schoolId",this.state.schoolId)
        console.log("userInfoId",this.state.userInfoId)
        await downFiles({documentIds:documentIds}).then(res=>{
            window.location.href = `/api/ss/api/v1/document/exportFiles/`+this.state.schoolId+"/"+this.state.userInfoId
        })
    }


    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({tableSelectlength:selectedRows.length, selectedRows:selectedRows,selectedRowKeys})
    }
    render() {
        const {wdlistcolumns, dataSource, wjname, UploadVideo,onPush,backUpParentId, previewFileRes,resourceId, yulanrecord, userInfoId, userInfoisAdmin, loading, ydwjjVisible,tableSelectlength,moveFilesVisible,selectedRowKeys,selectedRows} = this.state
        const {getFieldDecorator} = this.props.form;
        const rowSelection = {
            onChange: (selectedRowKeys) => {
                this.state.selectedUserIds = selectedRowKeys;
                let selectedUserRowLeft = this.state.userListAll.filter(i => selectedRowKeys.includes(i.id));
                let selectedUserRowRight = this.state.selectedUserRowRightInit.concat(selectedUserRowLeft)

                let count = selectedUserRowRight.length;
                this.setState({
                    selectedUserIds: selectedRowKeys,//选中用户列表ids
                    selectedUserRowRight:selectedUserRowRight,//选中用户列表
                    personnelCount: count
                })
            },
        };
        const rowSelectionList = {
            selectedRowKeys,
            selectedRows,
            onChange: this.onSelectChange,
        };
        return (
            <div className='wendang'>
                <div className='cont-top'>
                    <div className='title'>
                        <p></p>
                        <div>文档列表</div>
                        {backUpParentId.length>0 ? <span onClick={() => {
                            this.backup()
                        }} style={{lineHeight: '69px', marginLeft: '15px', cursor: "pointer"}}>返回上一级</span> : '' }

                    </div>
                    <Button className='add-user rolement' onClick={()=>this.showCmmvisible()}>新建文件夹</Button>
                    <Button className='add-user' onClick={this.handUpload}>上传文件</Button>
                </div>
                <div style={{position: 'relative'}}>
                    <Table
                        columns={wdlistcolumns}
                        rowKey={'id'}
                        onChange={this.handleChilk}
                        dataSource={dataSource}
                        className={'wjtable'}
                        pagination={false}
                        scroll={{ y: 685 }}
                        rowClassName={'wjtable'}
                        rowSelection={rowSelectionList}
                        onRow={record => {
                            return {
                                onClick: record => {
                                }, // 点击行
                            };
                        }}
                        locale={{emptyText:<><div className="Empty-conet-ss">
                                <div className="img">
                                    <img src={require("../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                    <span>暂无数据</span>
                                </div>
                            </div></>}}/>
                </div>
                <div className={'footer-caozuo'}>
                    <span className={'yixuan'}>已选{tableSelectlength}个</span>
                    {/*<Button type={'primary'}  onClick={()=>{this.downFiles()}}>下载</Button>*/}
                    {/*<Button type={'primary'}  className={'down-btn'}>下载</Button>*/}
                    <Button className={'ant-btn-noprimary'} onClick={()=>this.removes()}>移动</Button>
                    <Button className={'ant-btn-noprimary'} onClick={()=>this.setState({deleteFiles: true})}>删除</Button>
                </div>
                {/*重命名弹窗*/}
                <Modal
                    visible={this.state.cmmvisible}
                    title={wjname ? '重命名' : '新建文件夹'}
                    onOk={()=>this.updateName()}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    onCancel={()=>{this.setState({cmmvisible: false})}}
                    className={'chongmingming'}
                >
                    <div>

                       <Form>
                           {
                               this.state.isDir === 1 ?
                               <Form.Item>
                                   {getFieldDecorator('wjname', {
                                       rules: [
                                           {
                                               required: true,
                                               message: '文件夹名称不能为空!',
                                           },
                                           {
                                               type: 'string',
                                               min: 2,
                                               max: 30,
                                               message: '文件夹名称不能小于2或大于30字符!',
                                           },
                                       ],
                                   })
                                   (<Input autocomplete="off" maxLength={31} className={'srk'} placeholder={'请填入文件夹名称'}/>)}
                               </Form.Item> :
                               <Form.Item>
                                   {getFieldDecorator('wjname', {
                                       rules: [
                                           {
                                               required: true,
                                               message: '文件名称不能为空!',
                                           },
                                           {
                                               type: 'string',
                                               min: 2,
                                               max: 40,
                                               message: '文件名称不能小于2或大于40字符!',
                                           },
                                       ],
                                   })
                                   (<Input autocomplete="off" maxLength={41} className={'srk'} placeholder={'请填入文件名称'}/>)}
                               </Form.Item>
                           }

                       </Form>
                    </div>
                </Modal>
                {/*可见性设置弹窗*/}
                <Modal
                    visible={this.state.kjxvisible}
                    title={'可见性设置'}
                    width={800}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    onOk={()=>this.setVisablePersonOk()}
                    onCancel={()=>{this.setState({kjxvisible: false})}}
                    className={'kejianxingsz'}
                >
                    <div>
                        <div className={'kjxleft'}>
                            <div className={'search'}>
                                <label>可见成员</label>
                                <Select style={{width: 330}} value={this.state.fileAuth}
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        onChange={this.getkjxOptions}>
                                    <Option value={0}>所有建设任务组成员可见</Option>
                                    <Option value={1}>部分建设任务组成员可见</Option>
                                </Select>
                            </div>
                            {
                                this.state.fileAuth === 1?
                                    <div style={{float: "left"}}>
                                        <Table className='table-cy'
                                               rowSelection={{
                                                   type: "checkbox",
                                                   selectedRowKeys: this.state.selectedUserIds,
                                                   ...rowSelection,
                                               }}
                                               columns={this.state.columnsTable}
                                               dataSource={this.state.selectedUserRowLeft}
                                               scroll={{y: 274}}
                                               rowKey='id'
                                               pagination={false}
                                               selectedRows={this.onChangeAll}
                                        />
                                    </div>
                                    :
                                    <div style={{textAlign: 'center', lineHeight: '328px'}}>
                                        当前状态下建设任务内所有成员可见
                                    </div>
                            }
                        </div>
                        {
                            this.state.fileAuth === 1?
                                <div className={'kjxright'}>
                                    <div className={'kjxr-title'}>
                                        当前可见成员（{this.state.personnelCount}）
                                    </div>
                                    <ul className={'yxlist'}>
                                        {this.state.selectedUserRowRight.length ? this.state.selectedUserRowRight.map((item) => (
                                            <li>
                                                <img className={'usericon touxiang'} src={window.$$uploadRc(item.portraitId)} alt={'头像'}/>
                                                <span className={'mingzi'}>{item.humanName}</span>
                                                <img onClick={() => this.tianjiaCy(item)}
                                                     src={require('../../../../assets/imgnew/cha.png')} className={'cha'} alt={'删除'}/></li>
                                        )) : <li className={'kongli'}><img src={require('../../../../assets/imgnew/zanwuneirong.png')} alt={'暂无内容'}/></li>
                                        }
                                    </ul>
                                </div>:""
                        }

                    </div>
                </Modal>
                {/*上传文件*/}
                <Modal
                    visible={UploadVideo}
                    title={'上传文件'}
                    width={1000}
                    onOk={()=>this.handleUpload()}
                    closable={false}
                    onCancel={()=>this.uploadVideo()}
                    className={'uploadVideo'}
                    maskClosable={false}
                    centered={true}
                    footer={
                        <div>
                            <Button onClick={this.uploadVideo}>取消</Button>
                            <Button onClick={this.handleUpload} type="primary" loading={loading}>确定</Button>
                        </div>
                    }
                >
                    <Uploadfile onRef={this.onRef} parentId={this.state.parentId} uploadVideo={this.uploadVideo} queryFileListById={this.queryFileListById} toFatherValue={this.getChildValue.bind(this)}></Uploadfile>
                </Modal>
                {/*移动文件夹*/}
                <Modal
                    visible={this.state.removevisible}
                    title={this.state.moveFileTitleName}
                    width={700}
                    // onOk={()=>{this.setState({ydwjjVisible: true})}}
                    onOk={()=>{this.state.parentId === '0'?this.setState({ydwjjVisible: true}):this.moveFile()}}
                    closable={false}
                    onCancel={()=>{this.setState({removevisible: false})}}
                    className={'removeModal'}
                    maskClosable={false}
                    centered={true}
                >
                    <div>
                        <Tree
                            showIcon
                            switcherIcon={<Icon type="down" />}
                            onSelect={this.removeFileSelect}
                        >
                            {this.renderTree(this.state.queryFileTreeQoData)}
                        </Tree>
                    </div>
                </Modal>
                {/*移动文件夹弹窗提示*/}
                <Modal
                    title="温馨提示"
                    visible={ydwjjVisible}
                    onOk={()=>this.moveFile()}
                    z-index={99999999}
                    onCancel={()=>{this.setState({ydwjjVisible: false})}}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>移动将会清除该文件夹的可见性设置规则并遵循要移动到的文件夹可见性设置规则，是否继续？</p>
                </Modal>
                {/*批量移动文件夹*/}
                <Modal
                    visible={this.state.moveFilesVisible}
                    title={this.state.moveFileTitleName}
                    width={700}
                    // onOk={()=>{this.setState({ydwjjVisible: true})}}
                    onOk={()=>{this.state.parentId === '0' ? this.setState({ydwjjVisible: true}):this.moveFiles()}}
                    closable={false}
                    onCancel={()=>{this.setState({moveFilesVisible: false})}}
                    className={'removeModal'}
                    maskClosable={false}
                    centered={true}
                >
                    <div>
                        <Tree
                            showIcon
                            switcherIcon={<Icon type="down" />}
                            onSelect={this.removeFileSelect}
                        >
                            {this.renderTree(this.state.queryFileTreeQoData)}
                        </Tree>
                    </div>
                </Modal>
                {/*批量移动文件夹弹窗提示*/}
                <Modal
                    title="温馨提示"
                    visible={ydwjjVisible}
                    onOk={()=>this.moveFiles()}
                    onCancel={()=>{this.setState({ydwjjVisible: false})}}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>移动将会清除该文件夹的可见性设置规则并遵循要移动到的文件夹可见性设置规则，是否继续？</p>
                </Modal>
                {/*查看详情*/}
                <Drawer
                    title={
                        <div className='left-return'>
                            <div className= 'left-retu' onClick={this.onClose}>
                                <img className='return' src={require('../../../../assets/img/fanhui.png')} alt={'返回'}/>
                                <span>返回</span>
                            </div>
                            <span className='center'>{previewFileRes.resourceName}</span>
                            <div className='right-culars'>
                                <div className='particulars'>
                                    <img src={require('../../../../assets/img/yanjinga.png')} alt=""/>
                                    <span onClick={()=>this.getChildMethod()}>详情</span>
                                    <div className='download'>
                                        <img src={require('../../../../assets/img/xiazaia.png')} alt=""/>
                                        {/*<span>下载</span>*/}
                                        <a href={window.$$fujinDownload(yulanrecord.uploadFileId+"/"+yulanrecord.resourceName)}><span className='downloadColor'>下载</span></a>
                                        {userInfoId == yulanrecord.createrId || userInfoisAdmin === 1 ?
                                            <Dropdown overlay={<Menu>
                                                <Menu.Item><span onClick={()=>this.remove(yulanrecord)} style={{color: '#40a9ff'}}>移动文件</span></Menu.Item>
                                                <Menu.Item><span style={{color: '#40a9ff'}} onClick={()=>{this.setState({deleteFile: true, record: yulanrecord})}}>删除文件</span></Menu.Item>
                                            </Menu>}>
                                                <Button className='more' type="primary" >
                                                    <span className='more-m'> 更多</span>
                                                    <Icon type="down" />
                                                </Button>
                                            </Dropdown> : ''
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    placement="right"
                    className="particulars_cont particulars_cont_details"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPush}
                >
                    <Readdetailist previewFileRes={previewFileRes} onRef={this.onRef} resourceId={resourceId}/>
                </Drawer>
                {/*删除文件*/}
                <Modal
                title={'提示'}
                visible={this.state.deleteFile}
                z-index={99999999}
                onOk={()=>this.removeFile(yulanrecord)}
                onCancel={()=>{this.setState({deleteFile: false})}}
                width={450}
                closable={false}
                centered={true}>
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>{this.state.record.isDir===1?"删除将会导致该文件夹下所有内容一并删除,且不可恢复，是否继续？":"删除将会导致该文件彻底删除,且不可恢复，是否继续？"}</p>
                </Modal>
                {/*批量删除*/}
                <Modal
                    title={'提示'}
                    visible={this.state.deleteFiles}
                    onOk={()=>this.deleteFiles()}
                    onCancel={()=>{this.setState({deleteFiles: false})}}
                    width={450}
                    closable={false}
                    centered={true}>
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>{"删除将会导致已选择的所有内容一并删除，是否继续？"}</p>
                </Modal>
            </div>

        );
    }
}

Wendang = Form.create()(Wendang);
export default Wendang;
