// 任务统计
import React, {Component} from "react";
import {Button, Form, Modal, Progress, Table,} from "antd";
import {withRouter} from "react-router";
import {Readsign, Redcheck} from '../../../../../assets/svg'

import style from './index.module.scss'
import TaskstatEditor from "../taskstatEditor";
import ScreeningCondition from "../screeningCondition";

@withRouter
class Taskstatistical extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '任务',
                dataIndex: 'projectName',
                width: 200,
                align: "center",
                key: 'projectName',


            },
            {
                title: '负责人',
                align: "center",
                width: 100  ,
                dataIndex: 'investAmount',
                key: 'investAmount',

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'expendAmount',
                key: 'expendAmount',

            },
            {
                title: '截止日期',
                align: "center",
                width: 200,
                dataIndex: 'Principal',
                key: 'Principal',

            },


            ],
        pageDataList: [
            {
                id: 1,
                projectName: '8-1 突出多元主体协调共治的治理结构',
                investAmount:'学校',
                expendAmount:'进行中',
                Principal:'2020-08-05',


            },
            {
                id: 2,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 3,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 4,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 5,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 6,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 7,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 8,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },
            {
                id: 9,
                projectName: '提升国际化水平',
                investAmount:'学校',
                expendAmount:'产出指标',
                expendAmountt:'数量指标',
                Principal:'98%',


            },


        ],



    }
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }




    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,pageDataList,pageSize, total, pageNum,isreportEditor} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.taskStatistical}>
                <div className={'taskStatistical_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>任务统计</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'}>
                            <span>完成</span>
                        </div>
                        <div className={'button_topRB'}>
                            <span>保存</span>
                        </div>
                        <div className={'button_topG'}>
                            <span>更多</span>
                        </div>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <ul className={'renwutonhji-ul'}>
                                <li>
                                    <span>总任务数</span>
                                    <span className={'number'} style={{color: '#3E78ED'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#3E78ED'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>已完成</span>
                                    <span className={'number'} style={{color: '#F4DF58'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#F4DF58'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>已逾期</span>
                                    <span className={'number'} style={{color: '#23B899'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#23B899'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>未完成</span>
                                    <span className={'number'} style={{color: '#F07661'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#F07661'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>待认领</span>
                                    <span className={'number'} style={{color: '#966CFC'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#966CFC'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>时间待定</span>
                                    <span className={'number'} style={{color: '#7A79FF'}}>3654</span>
                                    <Progress percent={30} className={'progress'} strokeWidth={'10px'} strokeColor={'#7A79FF'} showInfo={false}/>
                                </li>
                            </ul>


                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>任务进展走势</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 700,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition/>
                            <p className={'shaixuank'}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span onClick={this.handLook} className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={this.handleOk} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor/>
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span>清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

Taskstatistical = Form.create()(Taskstatistical);
export default Taskstatistical;
