/*建设任务管理*/
import React, {Component} from "react";
import {Form, Select, Button, Table, Modal, Tabs, Input, Progress, DatePicker, Tooltip,} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {selectProjectPageList, projectSave, selectUserList} from '../../api/project/';
import {queryCatgoryById} from "../../api/synchrodatment";
import Cookies from "js-cookie";
const {TabPane} = Tabs;
const {Option} = Select;
const {TextArea} = Input;


@withRouter
class PadiList extends Component {
    state = {
        activeKey: '1',
        ModalVisible: false,
        endOpen: false,
        columns: [
            {
                title: '建设任务ID', dataIndex: 'projectCode', key: 'projectCode', align: 'center', width: 150
                , sorter: true
            },
            {
                title: '层面', dataIndex: 'projectType', key: 'projectType', align: 'center', width: 150,
                render: (text) => {
                    return (<div>{this.state.queryCatgory===1?this.state.projectTypes.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    }):this.state.projectTypess.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    })}</div>)
                }
            },
            {
                title: '建设任务名称', dataIndex: 'projectName', key: 'projectName', align: 'center', width: 220,
                sorter: true, render: (text, record) =>
                    <a className={'projectListName'} onClick={() => this.chakan(record.id,record.projectName)}>
                        {record.projectName?record.projectName.length>10?
                            <Tooltip  placement="bottomLeft" title={record.projectName} overlayClassName='TitleTooltip'
                        ><span>{record.projectName}</span></Tooltip>:<span>{record.projectName}</span>:''}
                    </a>
            },


            {
                title: '负责人', dataIndex: 'directorName', key: 'directorName', align: 'center', width: 220
                , render: (text, record) => {
                    return (<div>{<span>{text ? record.directorName : "--"} </span>}</div>)
                }
            },

            {
                title: '截止日期', dataIndex: 'endTime', key: 'endTime', align: 'center', width: 220,
                sorter: true,

                render: (text) => {
                    return (<div>{<span>{text ? text.split(' ')[0] : "--"} </span>}</div>)
                }
            },
            {
                title: '状态', dataIndex: 'projectStatus', key: 'projectStatus', align: 'center', width: 220,
                render: (text) => {
                    return (<div>{this.state.status.map((item, index) => {
                        if (text == item.value) {
                            return <div>{item.value === 1 ? <img className={'yuan'}
                                                                 src={require('@/assets/imgnew/yiqidongyuan.png')}/> : item.value === 2 ?
                                <img className={'yuan'}
                                     src={require('@/assets/imgnew/yiyuqiyuan.png')}/> : item.value === 3 ?
                                    <img className={'yuan'}
                                         src={require('@/assets/imgnew/yiguaqiyuan.png')}/> : item.value === 4 ?
                                        <img className={'yuan'}
                                             src={require('@/assets/imgnew/yiguidangyuan.png')}/> : ''}
                                {item.value === 1 ?
                                    <span className='yiqidong' key={index}>{item.label}</span> : item.value === 2 ?
                                        <span className='yiyuqi' key={index}>{item.label}</span> : item.value === 3 ?
                                            <span className='yiguaqi'
                                                  key={index}>{item.label}</span> : item.value === 4 ?
                                                <span className='yiguidang' key={index}>{item.label}</span> : ''}
                            </div>

                        }
                    })}</div>)
                }
            },
            {
                title: '进度', dataIndex: 'completionRate', key: 'completionRate', align: 'center', width: 220,
                render: (text, record) => <p className='render-span vertical_j'>
                    <div>
                        <Progress
                            strokeColor={{
                                '0%': '#FFD426',
                                '100%': '#FFB04C',
                            }}
                            percent={record.completionRate}
                        />
                    </div>
                </p>
            },
            {
                title: '操作', align: 'center', fixed: "right",
                width: 100,
                render: (text, record) => <p className='render-span vertical_j'>
                    <div>
                        <span className='check' onClick={() => this.chakan(record.id,record.projectName)}>查看</span>
                    </div>
                </p>
            },


        ],
        dataSource: [
            {
                projectCode: '',
                projectName: '',
                projectType: '',
                directorName: '',
                endTime: '',
                projectStatus: '',
                completionRate: '',
                directorId: '',
            },
        ],
        status: [
            {'label': '已启动', 'value': 1},
            {'label': '已逾期', 'value': 2},
            {'label': '已挂起', 'value': 3},
            {'label': '已归档', 'value': 4}
        ],
        projectTypes: [
            {'label': '学校', 'value': 1},
            {'label': '专业群一', 'value': 2},
            {'label': '专业群二', 'value': 3},
            {'label': ' 其他', 'value': 4}

        ],
        projectTypess: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群', 'value': '2'},
            {'label': ' 其他', 'value': '4'}

        ],
        taskId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId,//用户ID
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        isAdmin: JSON.parse(Cookies.get('ssInfo')).userInfo.isAdmin,
        optionList: [{}],
        userOptions: [],
        projectList: [],
        //添加页面数据
        userInfoList: [],
        pageNum: 1,//当前页数
        pageSize: 20,//每页数据数量
        total: 0,//总条数
        beginTime: '',
        endTime: '',
        projectDescribe: '',
        condition: '',//排序用的编号
        statusButten: true,
        projectType:1,
    }
    chakan = (id,name) => {
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.menuUrl == '/main/doublehigh/projectlist') {
                    breadcrumb.splice(index, 1)
                }
            }

        })
        if(name.length > 10){
            name = name.substring(0,10)+'...'
        }
        breadcrumb.push({"id": '17', "menuName": '双高任务智能管理', "menuUrl": '/main/doublehigh/projectlist'}
            , {"id": '18', "menuName": '建设任务管理', "menuUrl": '/main/doublehigh/projectlist',"color": '#40a9ff'}
            , {"id": '19', "menuName": name}
            , {"id": '191', "menuName": '概况'})
        let end = breadcrumb.slice(-3);
        Cookies.set('list2', end)
        this.props.history.push({
            pathname: '/main/doublehigh/project/' + id,
        })
    }
    showModal = () => {
        this.selectUserList();
        this.setState({ModalVisible: true});
    };

    async selectProjectPageList() {
        let {pageNum, pageSize, search} = this.state;
        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            schoolId: this.state.schoolId,
            projectStatus: this.state.projectStatus,
            condition: this.state.condition,
            isAdmin: this.state.isAdmin,
            userId: this.state.userId,
            projectType: this.state.projectType,
        };
        const page = await selectProjectPageList(params);
        if (page) {
            this.setState({
                projectList: page.data,
                total: page.total
            });
        }
    }

    async selectUserList() {
        let params = {
            schoolId: this.state.schoolId,
        };
        const page = await selectUserList(params);
        if (page) {
            this.setState({
                userInfoList: page,
            });
        }
    }

    handleOk = () => {
        if (this.state.statusButten) {
            //开始时间和结束时间处理
            if (this.state.beginTime && !this.state.endTime) {
                this.mesWarning("请填入结束时间")
                return undefined
            }
            if (!this.state.beginTime && this.state.endTime) {
                this.mesWarning("请填入开始时间")
                return undefined
            }
            this.setState({statusButten: false})
            let formValue = this.props.form.getFieldsValue()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let projectObj = {
                        //表单里的
                        beginTime: this.state.beginTime ? this.state.beginTime.format('YYYY-MM-DD 00:00:01') : "",
                        endTime: this.state.endTime ? this.state.endTime.format('YYYY-MM-DD 23:59:59') : "",
                        projectDescribe: formValue.projectDescribe,
                        projectName: formValue.projectName,
                        projectType: formValue.projectType,
                        directorId: formValue.directorId,
                        //Cookies 里的
                        schoolId: this.state.schoolId,
                        createrName: this.state.humanName,
                        createrId: this.state.userId,
                        taskId: this.state.taskId,
                    };

                    projectSave(projectObj).then(res => {
                        this.mesSuccess('操作成功');
                        this.props.form.resetFields()
                        this.setState({
                            ModalVisible: false,
                            modalTitle: "",
                            beginTime: "",
                            endTime: "",
                            textAreaDefaultValue: '',
                            projectDescribe: '',
                        });
                    }).then((res) => {
                        this.selectProjectPageList();
                    })
                }
            })

        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }
    handleCancel = () => {
        this.props.form.resetFields()
        this.setState({
            ModalVisible: false,
            modalTitle: "",
            beginTime: "",
            endTime: "",
            textAreaDefaultValue: '',
            projectDescribe: '',
        });
    };
    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id:this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory:queryCatgory
        })
    }
    componentDidMount() {
        this.selectProjectPageList()
        this.queryCatgoryById();
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => this.selectProjectPageList());
    };

    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize: pageSize, pageNum: 1}, () => {
            this.selectProjectPageList()
        });
    };


    disabledStartDate = beginTime => {
        const {endTime} = this.state;
        if (!beginTime || !endTime) {
            return false;
        }
        return beginTime.valueOf() > endTime.valueOf();
    };
    disabledEndDate = endTime => {
        const {beginTime} = this.state;
        if (!endTime || !beginTime) {
            return false;
        }
        return endTime.valueOf() <= beginTime.valueOf();
    };
    // 开始日期
    onStartChange = value => {
        this.onChange('beginTime', value);
    };

    //结束日期
    onEndChange = value => {
        this.onChange('endTime', value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = open => {
        this.setState({endOpen: open});
    };

    onUserChange = (e) => {
        let users = this.state.userOptions
        for (let i = 0; i < users.length; i++) {
            if (e === users[i].id) {
                this.props.form.setFieldsValue({userPhone: users[i].phone})
                break;
            }
        }
    }

    onChangeTab = (e) => {
        this.state.projectStatus = e
        this.state.pageNum = 1
        this.selectProjectPageList()
    }
    onChangeTaba = (e) => {
        this.state.projectType = e
        this.selectProjectPageList()
    }
    handlechilk = (paqination, filters, sorter, extra) => {
        let condition = ''
        if (sorter) {
            //建设任务ID升序
            if (sorter.order) {
                if (sorter.column.title === '建设任务ID' && sorter.order === 'ascend') {
                    condition = 1
                } else if (sorter.column.title === '建设任务ID' && sorter.order === 'descend') {
                    condition = 2
                } else if (sorter.column.title === '建设任务名称' && sorter.order === 'ascend') {
                    condition = 3
                } else if (sorter.column.title === '建设任务名称' && sorter.order === 'descend') {
                    condition = 4
                } else if (sorter.column.title === '截止日期' && sorter.order === 'ascend') {
                    condition = 5
                } else if (sorter.column.title === '截止日期' && sorter.order === 'descend') {
                    condition = 6
                }
            } else {
                condition = ''
            }
            this.state.condition = condition
            this.setState({
                pageNum: paqination.current,
                pageSize: paqination.pageSize
            }, () => this.selectProjectPageList());
        }
    }

    render() {
        const {ModalVisible, projectTypes,queryCatgory,projectTypess, beginTime, endTime, projectDescribe, userInfoList, projectList, pageSize, total, pageNum, columns, endOpen, Option, optionList, userOptions} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='ProjectList'>
                <div className='project'>
                    <div className='project-title'>
                        <p></p>
                        <span>建设任务管理</span>
                    </div>
                    <Button className='add-user' type="primary" onClick={this.showModal}>
                        添加建设任务
                    </Button>
                </div>
                <div className='list-itema'>
                    { queryCatgory===1?<Tabs className='all' defaultActiveKey="1" onChange={this.onChangeTaba}>
                        <TabPane tab="学校建设任务" key="1">
                        </TabPane>
                        <TabPane tab="专业群一建设任务" key="2">
                        </TabPane>
                        <TabPane tab="专业群二建设任务" key="3">
                        </TabPane>
                        <TabPane tab="其他特色建设任务" key="4">
                        </TabPane>

                    </Tabs>:<Tabs className='all' defaultActiveKey="1" onChange={this.onChangeTaba}>

                        <TabPane tab="学校建设任务" key="1">
                        </TabPane>
                        <TabPane tab="专业群建设任务" key="2">
                        </TabPane>
                        <TabPane tab="其他特色建设任务" key="4">
                        </TabPane>
                    </Tabs>}
                </div>

                <div className='list-itema list-itema1'>
                    <Tabs className='all' defaultActiveKey="" onChange={this.onChangeTab}>
                        <TabPane tab="所  有" key="">
                        </TabPane>
                        <TabPane tab="已启动" key="1">
                        </TabPane>
                        <TabPane tab="已逾期" key="2">
                        </TabPane>
                        <TabPane tab="已挂起" key="3">
                        </TabPane>
                        <TabPane tab="已归档" key="4">
                        </TabPane>
                    </Tabs>
                </div>
                <div   className="tabl">
                    <Table columns={columns}
                           dataSource={projectList}
                           onChange={this.handlechilk}
                           scroll={{ x: 1500, y: 550 }}
                           rowKey={(r, i) => (r.id)}
                           className={'projectListTable'}
                           locale={{emptyText:<><div className="Empty-conet-ss">
                                   <div className="img">
                                       <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                       <span>暂无数据</span>
                                   </div>
                               </div></>}}
                           pagination={
                               {
                                   current: pageNum,
                                   total: total,
                                   pageSize: pageSize,
                                   pageSizeOptions: ['20', '40', '60', '80'],
                                   showSizeChanger: true,
                               }
                           }/>
                </div>
                <Modal className='projectTable-list' title="新建建设任务" wrapClassName={'add-construction-task'} visible={ModalVisible} width={660} onOk={() => this.handleOk()}
                       onCancel={() => this.handleCancel()} closable={false}>
                    <div className='list-a'>
                        <Form>
                            <Form.Item label="建设任务名称">
                                {
                                    getFieldDecorator('projectName', {
                                        rules: [
                                            {
                                                type: 'string',
                                                min: 2,
                                                max: 200,
                                                message: '建设任务名称不能小于2或大于200字符!',
                                            },
                                            {
                                                required: true,
                                                message: '建设任务名称不能为空!'
                                            },
                                        ]
                                    })(<Input autocomplete="off" placeholder="请输入建设任务名称"/>)
                                }
                            </Form.Item>
                            <Form.Item label="层面">
                                {
                                    getFieldDecorator('projectType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '层面不能为空!'
                                            }
                                        ]
                                    })(<Select defaultValue="lucyA" placeholder="请选择层面"
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {
                                            queryCatgory===1? projectTypes && projectTypes.map(item =>
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            ):projectTypess && projectTypess.map(item =>
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            )
                                        }

                                    </Select>)
                                }
                            </Form.Item>
                            <Form.Item label="日程规划">
                                {
                                    getFieldDecorator('date', {})(<div>
                                        <DatePicker
                                            style={{'display': 'inline-block'}}
                                            disabledDate={this.disabledStartDate}
                                            format="YYYY-MM-DD"
                                            value={beginTime}
                                            placeholder="请选择日期"
                                            onChange={this.onStartChange}
                                            onOpenChange={this.handleStartOpenChange}
                                            getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        /><span style={{
                                        'display': 'inline-block',
                                        'width': '32px',
                                        'textAlign': 'center'
                                    }}>至</span>
                                        <DatePicker
                                            style={{'display': 'inline-block'}}
                                            disabledDate={this.disabledEndDate}
                                            format="YYYY-MM-DD"
                                            value={endTime}
                                            placeholder="请选择日期"
                                            onChange={this.onEndChange}
                                            open={endOpen}
                                            onOpenChange={this.handleEndOpenChange}
                                            getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        />
                                    </div>)
                                }
                            </Form.Item>
                            <Form.Item label="负责人">
                                {
                                    getFieldDecorator('directorId', {})(<Select
                                        showSearch="true"
                                        optionFilterProp="children"
                                        defaultValue=""
                                        placeholder="请选择负责人"
                                        allowClear={true}
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        onChange={(e) => this.onUserChange(e)}>
                                        {
                                            userInfoList && userInfoList.map(item =>
                                                <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                            )
                                        }

                                    </Select>)
                                }
                            </Form.Item>

                            <Form.Item label="建设任务描述">
                                <div className={'textare-wrap'}>
                                    {
                                        getFieldDecorator('projectDescribe')(
                                            <TextArea maxLength={500}
                                                      placeholder="请输入建设任务描述"
                                                      style={{width: '100%', resize: 'none'}}
                                                      className={'projectDescribe'}
                                                      rows={5}
                                                      defaultValue={projectDescribe}
                                            />
                                        )
                                    }
                                    <span className='textarea_len'>
                                    {this.props.form.getFieldValue(`projectDescribe`) ? this.props.form.getFieldValue(`projectDescribe`).length : 0}/500
                                        </span>
                                </div>
                            </Form.Item>

                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

PadiList = Form.create()(PadiList);
export default PadiList;
