import { post ,get} from '../../until/axios_instans';

//获取按钮权限
export function getButtonRole(data){
    return post('/api/v1/permission/buttons',data);
}

//获取菜单权限
export function getMenu(data){
    return post('/api/v1/permission/routers',data);
}

//删除
export function deleteRole(data){
    return post('/api/ss/api/v1/role/deleteRole',data);
}

//禁用
export function disableRole(data){
    return post('/api/ss/api/v1/role/disableRole',data);
}

//启用
export function enableRole(data){
    return post('/api/ss/api/v1/role/enableRole',data);
}

//获取权限按钮列表
export function getPermissionButton(data){
    return post('/api/ss/api/v1/role/getPermissionButton',data);
}
//信息byid
export function getRoleById(data){
    return post('/api/ss/api/v1/role/getRoleById',data);
}
//分页列表
export function getPageRoleList(data){
    return post('/api/ss/api/v1/role/pageRoleList',data);
}

//新增
export function saveRole(data){
    return post('/api/ss/api/v1/role/saveRole',data);
}
//修改
export function updateRoleById(data){
    return post('/api/ss/api/v1/role/updateRoleById',data);
}
//角色类型列表
export function getRoleTypelist(){
    return post('/api/ss//api/v1/roletype/getRoleTypelist');
}
//查询角色分类
export function queryRoleTypes(data){
    return post('/api/ss/api/v1/sysuserrole/queryRoleTypes',data);
}
//更新角色分类
export function updateRoleTypes(data){
    return post('/api/ss/api/v1/sysuserrole/updateRoleTypes',data);
}
//新增角色分类
export function insertRoleTypes(data){
    return post('/api/ss/api/v1/sysuserrole/insertRoleTypes',data);
}
//刪除角色分类
export function deleteRoleTypes(data){
    return post('/api/ss/api/v1/sysuserrole/deleteRoleTypes',data);
}