/*建设任务管理--概况--建设任务设置--建设任务信息*/
import React, {Component} from "react";
import {
    Form, Input, Select, Button, DatePicker
} from "antd";
import "./index.scss";
import moment from 'moment';
import {withRouter} from "react-router";
import {selectUserList, updateProjectById,updateProjectOperation,infoProjectById} from '../../../../../../api/project/';
import {queryCatgoryById} from "../../../../../../api/synchrodatment";
import Cookies from "js-cookie";

const {Option} = Select;

@withRouter
class Xiangmuxinxi extends Component {
    state = {
        xmTypeOptions: [{id: 1, Name: '1111'}],//建设任务类型
        fzrOptions: [],//负责人
        startValue: undefined,
        endValue:undefined,
        id: this.props.infoProject.id,
        endOpen: false,
        infoProject: this.props.infoProject,
        projectTypes: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群一', 'value': '2'},
            {'label': '专业群二', 'value': '3'},
            {'label': ' 其他', 'value': '4'}

        ], projectTypess: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群', 'value': '2'},
            {'label': ' 其他', 'value': '4'}

        ],
        projectType: this.props.infoProject.projectType,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        userInfoList: [],
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        projectStatus:this.props.infoProject.projectStatus,
        statusButten: true,
        queryCatgory:{}
    }

    infoProjectById = async ()=>{
        let data ={
            id:this.state.id,
            userId:this.state.userId,
        }
        await infoProjectById(data).then(res=>{
            this.setState({
                    projectStatus: res.projectStatus,
                    startValue:res.beginTime?res.beginTime:undefined,
                    endValue:res.endTime?res.endTime:undefined,
                })
            if(res.directorId){
                this.props.form.setFieldsValue({
                    directorId: res.directorId
                })
            }
            this.props.form.setFieldsValue({
                projectName: res.projectName,
                // directorId: res.directorId ? res.directorId:'',
                projectStatus: res.projectStatus,
                projectDescribe: res.projectDescribe,
                projectType: res.projectType,
            })
        })
    }
    //保存
    save = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let valus = this.props.form.getFieldsValue()

            //开始时间和结束时间处理
            if (this.state.startValue && !this.state.endValue) {
                this.mesWarning("请填入结束时间")
                return undefined
            }
            if (!this.state.startValue && this.state.endValue) {
                this.mesWarning("请填入开始时间")
                return undefined
            }
            let endValue = null
            if (this.state.endValue) {
                if (typeof this.state.endValue == "string") {

                    endValue = this.state.endValue + " 23:59:59"
                } else {
                    endValue = this.state.endValue.format('YYYY-MM-DD 23:59:59')
                }
            } else {
                endValue = ""
            }
            let startValue = null
            if (this.state.startValue) {
                if (typeof this.state.startValue == "string") {
                    startValue = this.state.startValue + " 00:00:01"
                } else {
                    startValue = this.state.startValue.format('YYYY-MM-DD 00:00:01')
                }
            } else {
                startValue = ""
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let projectObj = {
                        id: this.state.id,
                        projectName: valus.projectName,
                        directorId: valus.directorId,
                        projectStatus: this.state.projectStatus,
                        projectDescribe: valus.projectDescribe,
                        projectType: valus.projectType,
                        editorId: this.state.userId,
                        editorName: this.state.humanName,
                        beginTime: startValue,
                        endTime: endValue,

                    }
                    updateProjectById(projectObj).then(res => {
                        this.props.infoProjects();
                        this.props.infoProjectPanel();
                        this.props.onClose();
                        this.mesSuccess('操作成功');
                    })
                }
            })
        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }

    componentDidMount() {
        this.props.onRef(this)
        this.infoProjectById()
        this.selectUserList()
        this.queryCatgoryById();
    }
    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id:this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory:queryCatgory
        })
    }

    async selectUserList() {
        let params = {
            schoolId: this.state.schoolId,
        };
        const page = await selectUserList(params);
        if (page) {
            this.setState({
                userInfoList: page,
            });
        }
    }

    //日期框
    disabledStartDate = (startValue) => {
        const {endValue} = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    disabledEndDate = (endValue) => {
        const {startValue} = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    onChange = (field, value) => {
        this.setState({[field]: value})
    };
    onStartChange = (value) => {
        this.onChange("startValue", value);
    };

    onEndChange = (value) => {
        this.onChange("endValue", value);
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({endOpen: open});
    };
    quxiao = () => {
        this.props.infoProjects();
        this.props.infoProjectPanel();
        this.props.onClose();
        this.infoProjectById()

    }

    render() {
        const {endOpen, projectTypes,projectTypess,queryCatgory, userInfoList, projectStatus,startValue, endValue} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='xiangmuxinxi'>
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>建设任务名称</span>
                        {getFieldDecorator('projectName', {
                            rules: [
                                {
                                    type: 'string',
                                    min: 2,
                                    max: 200,
                                    message: '建设任务名称不能小于2或大于200字符!',
                                },
                                {
                                    required: true,
                                    message: '建设任务名称不能为空!'
                                },
                            ],
                        })(<Input placeholder={'请输入建设任务名称'}  disabled={this.props.infoProject.isNew==0} className={'srk'} autocomplete="off"/>)}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>层面</span>
                        {getFieldDecorator('projectType', {
                            rules: [
                                {
                                    required: true,
                                    message: '层面不能为空!',
                                },
                            ],
                        })(<Select
                            disabled={this.props.infoProject.isNew==0}
                            showSearch
                            placeholder="请选择层面"
                            className={'srk'}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                        >
                            {queryCatgory===1?projectTypes && projectTypes.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>
                            }):projectTypess && projectTypess.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>
                            })}
                        </Select>)}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}>日程规划</span>
                        <DatePicker
                            value={startValue ? moment(startValue) : undefined}
                            disabledDate={this.disabledStartDate}
                            placeholder="请选择开始时间"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                            className={'time'}
                            getCalendarContainer={triggerNode => triggerNode.parentNode}
                        />
                        <span style={{margin: '0 8px'}}>至</span>
                        <DatePicker
                            value={endValue ? moment(endValue) : undefined}
                            disabledDate={this.disabledEndDate}
                            placeholder="请选择结束时间"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                            className={'time'}
                            getCalendarContainer={triggerNode => triggerNode.parentNode}
                        />
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}>负责人</span>
                        {getFieldDecorator('directorId', {})(<Select
                            showSearch={true}
                            placeholder="请选择负责人"
                            optionFilterProp="children"
                            className={'srk'}
                            allowClear={true}
                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                        >
                            {userInfoList.length ? userInfoList.map((item, index) => {
                                return <Option value={item.id}>{item.humanName}</Option>
                            }) : ''}
                        </Select>)}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}>建设任务描述</span>
                        <div className={'textare-wrap'}>
                        {getFieldDecorator('projectDescribe', {})(<Input.TextArea rows={6} placeholder={'请输入建设任务描述'}
                                                                                  maxLength={500} className={'srk'}
                                                                                  suffix={
                                                                                      <span className='len'></span>
                                                                                  }/>)}
                        <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`projectDescribe`) ? this.props.form.getFieldValue(`projectDescribe`).length : 0}/500
                                    </span>
                        </div>
                    </Form.Item>
                </Form>
                {projectStatus==4?<Button disabled={true}  type={'primarys'}>确定</Button>:<Button type={'primary'} onClick={() => this.save()}>确定</Button>}
                <Button onClick={() => this.quxiao()}>取消</Button>
            </div>

        );
    }
}

Xiangmuxinxi = Form.create()(Xiangmuxinxi);
export default Xiangmuxinxi;
