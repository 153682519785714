/*角色权限设置*/
import React, {Component} from "react";
import {Button, Divider, Drawer, Form, message, Modal, Steps} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Cookies from "js-cookie";
import {
    getSchoolNewInfo,
    getSchoolTaskBook,
    initDefaultChart,
    investSyn,
    meritAndIndicatorSyn,
    projectAndTaskSyn,
    schoolContact,
    schoolInfoSyn,
    taskBookTotalProgress,
    taskOrCaseDown,
    queryCatgoryById,
    workFlowInit
} from '../../api/synchrodatment/';
import RenderButton from "../../components/renderButton";
import S from "gongyongicon";
import Readdetailist from "../readdetails";
import {Chakan, Xiazai} from "../../assets/svg";
import ReaddetailsPrew from "../readdetailsPrew";

@withRouter
class Synchrodatment extends Component {
    state = {
        scoolmessage:[{schoolName: '', schoolEduCode: '', catgory: '', gear: '', province: ''}],
        synchronTime: '',
        iframeSrc: '',
        filename: '',
        onPreview:false,  //预览pdf详情
        fileId: '',
        contact: [{userName: '', phone: '', workPhone: ''}],//联系人及方式
        stepdata: [
            {
                title: '任务书建设方案填报',
                description: '2021-08-12 13：22'
            },
            {
                title: '年度汇报工作及绩效填报',
                description: '2021-08-30 13:22'
            },
            {
                title: '中期绩效评价',
                description: ''
            },
            {
                title: '实施期结束绩效评价',
                description: ''
            },
        ],
        current: '',//步骤条到底几个
        visible: false,
        updatedata: '',//哪些数据有更新
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.id,
        taskId: JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId,//用户ID
        schoolTaskBookDetail: {},
        schoolDetail: {},
        compare: false,
        onPush:false,
        previewFileRes: "",
        updateChange: false,
        categoryType: {},
    }

    componentDidMount() {
        this.queryCategoryById()
        this.getSchoolTaskBook()
        this.getSchoolContact()//获取联系人及方式
        this.taskBookTotalProgress()//任务书总进度
        this.getSchoolNewInfo()
        this.setState({updatedata: ''})
        window.parent.scrollTo(0, 0)
    }
    //数据统计初始化
    initDefaultChart = ()=>{
        initDefaultChart().then(res => {
        })
    }
    //同步建设任务与任务
    projectAndTaskSyn = () =>{
        console.log("任务开始同步")
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        projectAndTaskSyn(data).then(res => {
            if(res){
                this.mesSuccess("数据同步至最新")
                console.log("任务同步成功")

            }
        })
    };
    // 在同步资金之前，workflow初始化
    workFlowInit = () =>{
        console.log("workflow初始化开始");
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        const data = {
            schoolId: schoolInfo.id,
        };
        workFlowInit(data).then(res => {
            // if(res){
            //     this.mesSuccess("数据同步至最新");
            //     console.log("任务同步成功");
            //     this.meritAndIndicatorSyn();
            //     this.investSyn()
            // }
        })
    };

    // 学校建设绩效与指标同步
    meritAndIndicatorSyn = () =>{
        console.log("绩效开始同步")
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        meritAndIndicatorSyn(data).then(res => {
            if(res){
                // this.mesSuccess("绩效已同步至最新")
                console.log("绩效同步成功")
            }
        })
    };
    // 预算同步
    investSyn = () =>{
        console.log("预算开始同步")
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        investSyn(data).then(res => {
            if(res){
                // this.mesSuccess("绩效已同步至最新")
                console.log("预算同步成功")
            }
        })
    };
    getSchoolNewInfo = () =>{//根据Id获取学校概况
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            id: schoolInfo.id,
        }
        getSchoolNewInfo(data).then(res => {
            this.setState(  { schoolDetail:res,})
        })
    }
    schoolInfoSyn = () =>{//根据Id获取学校概况
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        schoolInfoSyn(data).then(res => {
            this.getSchoolNewInfo()
        })
    }
    onClose = () =>{
        this.setState({
            onPush:false,
            previewFileRes: ""
        })
    };
    getToDocumentInfo = async (record)=>{
        let fileId = record.id;
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        let data = {
            fileId,
            schoolId:schoolInfo.id,
            schoolName: schoolInfo.schoolName,
            fileName: "任务书"
        };
        console.log('data...',data);
        await taskOrCaseDown(data).then(res=>{
            if(res){
                    this.setState({previewFileRes: res},()=>{
                        this.setState({
                            onPush:true
                        })
                    })
            }
        })

    };
    getSchoolContact = () =>{//获取联系人及方式
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        schoolContact(data).then(res => {
            if(res){

                if(this.state.compare){
                    let contact = this.state.contact
                    let updatedata = this.state.updatedata
                    Object.keys(res).forEach(function(key){
                        if(contact[0][key] !== res[key]){
                            let updatekey = (key === 'phone' ? '联系人手机' : key === 'userName' ? '联系人姓名' : key === 'workPhone' ? '联系人办公电话' : '' )
                            updatedata += updatekey+","
                        }

                    });
                    this.setState({updatedata: updatedata},()=>{
                        if(updatedata.length>0){
                            this.setState({updateChange: true})
                        }
                    })
                }
                else{
                    this.setState({contact: [{phone: res.phone, userName: res.userName, workPhone: res.workPhone}]})
                }
            }

        })
    }
    taskBookTotalProgress = () =>{  //任务书总进度
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const data = {
            schoolEduCode: schoolInfo.schoolEduCode,
            schoolId: schoolInfo.id,
            taskId: schoolInfo.taskId,
        }
        taskBookTotalProgress(data).then(res => {
            if (res){
                let current = res.map(i=>i.progressStatus).indexOf(0) -1
                if(this.state.compare){
                    let updatedata = this.state.updatedata
                    if(current !== this.state.current){
                        updatedata+='填报与评价进度,'
                    }
                    this.setState({updatedata: updatedata},()=>{
                        if(updatedata.length>0){
                            this.setState({updateChange: true})
                        }
                    })
                }else{

                    this.setState({stepdata: res, current},()=>{

                    })
                }
            }
        })
    }
    getSchoolTaskBook = () =>{
        let taskId = this.state.taskId
        getSchoolTaskBook(taskId).then(res => {
            if (res) {
                console.log("任务书数据：",JSON.stringify(res));
                this.setState({
                    schoolTaskBookDetail: res,
                    fileId: res.taskBookFile?res.taskBookFile.fileId:'',
                },()=>{
                })
                let reviewStatus = {
                    isReporting: res.isReporting,
                    auditStatus: res.auditStatus,
                    auditReason: res.auditReason,
                }
                localStorage.setItem("reviewStatus",JSON.stringify(reviewStatus))
            }
        })
    }
    onClose = () =>{
        this.setState({
            onPreview:false,
            iframeSrc:'',
            filename:''
        })
    }
    //任务书附件查看
    handleBoox=()=>{
        console.log('data...');
        const {schoolTaskBookDetail}=this.state;
        let fileId = schoolTaskBookDetail.taskBookFile?schoolTaskBookDetail.taskBookFile.fileId:'';
        let fileUploadDate = schoolTaskBookDetail.taskBookFile?schoolTaskBookDetail.taskBookFile.fileUploadDate:'';
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        this.setState({
            onPreview:true,
            iframeSrc:`${window.$$sgPreview(`dh-front-img/dh-doublehigh-front/`+fileUploadDate+"/"+fileId)}`,
            filename:'任务书附件',
        })
    }
    //建设方案查看
    handleCaseFile=()=>{
        const {schoolTaskBookDetail}=this.state;
        let fileId = schoolTaskBookDetail.taskCaseFile?schoolTaskBookDetail.taskCaseFile.fileId:'';
        let fileUploadDate = schoolTaskBookDetail.taskCaseFile?schoolTaskBookDetail.taskCaseFile.fileUploadDate:'';
        this.setState({
            onPreview:true,
            iframeSrc:`${window.$$sgPreview(`dh-front-img/dh-doublehigh-front/`+fileUploadDate+"/"+fileId)}`,
            filename:'建设方案附件',

        })
    };
    //绩效自评表查看
    exportPdfTwo=()=>{
        const {schoolTaskBookDetail}=this.state;
        let fileId = schoolTaskBookDetail.indexSchoolYearReportVos[0]?schoolTaskBookDetail.indexSchoolYearReportVos[0].fileId:'';
        this.setState({
            onPreview:true,
            iframeSrc:`${window.$$sgPreview(fileId)}`,
            filename:'绩效自评表',
        })
    };
    //任务书下载
    handleBooxDownload=()=>{
        const {schoolTaskBookDetail}=this.state
        let fileId = schoolTaskBookDetail.taskBookFile?schoolTaskBookDetail.taskBookFile.fileId:'';
        console.log('任务书下载')
        if(fileId){
            let iframeSrc = window.$$previewUrl(`/api/dh/api/v1/dh/media/download/${fileId}`);
            window.open(iframeSrc)
        }else{
            message.destroy()
            this.mesWarning("未上传建设计划任务书")
        }
    }
    //建设方案下载
    handleCaseFileDownload=()=>{
        const {schoolTaskBookDetail}=this.state
        let fileId = schoolTaskBookDetail.taskCaseFile?schoolTaskBookDetail.taskCaseFile.fileId:'';
        console.log('建设方案下载')
        if(fileId){
            let iframeSrc = window.$$previewUrl(`/api/dh/api/v1/dh/media/download/${fileId}`);
            window.open(iframeSrc)
        }else{
            message.destroy()
            this.mesWarning("未上传建设方案")
        }
    }
    //导出2020年绩效pdf
    export2020Pdf=()=>{ //下载绩效
        console.log('导出2020pdf')
        const {schoolTaskBookDetail}=this.state
        let iframeSrc = window.$$previewUrl(`/api/dh/api/vi/dh/pdf/exportTaskIndicatorPdfNew?taskId=${schoolTaskBookDetail.taskId}&schoolId=${schoolTaskBookDetail.schoolId}`);
        window.open(iframeSrc)
    }
    //导出2021年绩效pdf
    export2021Pdf=()=>{
        console.log('导出2021pdf')
        const {schoolTaskBookDetail}=this.state
        let iframeSrc = window.$$previewUrl(`/api/dh/api/vi/dh/pdf/newPdfExportTaskIndicatorTwentyTwo?taskId=${schoolTaskBookDetail.taskId}&schoolId=${schoolTaskBookDetail.schoolId}&year=2021`);
        window.open(iframeSrc)
    }
    //导出2022年绩效pdf
    export2021Pdf=()=>{
        console.log('导出2022pdf')
        const {schoolTaskBookDetail}=this.state
        let iframeSrc = window.$$previewUrl(`/api/dh/api/vi/dh/pdf/newPdfExportTaskIndicatorTwentyTwo?taskId=${schoolTaskBookDetail.taskId}&schoolId=${schoolTaskBookDetail.schoolId}&year=2022`);
        window.open(iframeSrc)
    }
    //导出2020年绩效成果指标pdf
    exportResultPdf=(e)=>{
        console.log('导出年度绩效成果指标pdf')
        let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo
        const url = window.location.href
        let baseUrl = url.split("/#")[0];
        console.log("window.location.href:",url.split("/#")[0])
        window.open(baseUrl+'/api/ss/api/v1/excel/schoolIndicatorDetailExcel/'+schoolInfo.schoolEduCode+"\/"+e)
    }
    HandleCancel = () =>{ //关闭数据变更提示弹窗
        this.setState({visible: false})

    }
    HandleOk = () =>{ //确定变更数据
        this.setState({visible: false, compare: false}, ()=>{
            this.schoolInfoSyn();//学校信息同步
            this.projectAndTaskSyn();//建设任务贺任务同步
            this.workFlowInit();//工作流同步
            this.meritAndIndicatorSyn()//绩效和绩效指标同步
            this.investSyn()//资金同步
            this.initDefaultChart()//数据统计初始化
        })
    }
    updatedata = () => {
        this.setState({ visible: true, compare: true, updatedata: '', updateChange: false})
    }
    //任务书预览
    tosituationcontent = () =>{
        // let schoolInfo = JSON.parse(Cookies.get('ssInfo')).schoolInfo;
        // let data = {
        //     taskId:schoolInfo.taskId,
        //     schoolId:schoolInfo.id,
        //     schoolName: schoolInfo.schoolName,
        //     fileName: "任务书"
        // };
        // console.log('data...',data);
        // taskOrCaseDown(data).then(res=>{
        //     if(res){
        //         this.setState({previewFileRes: res},()=>{
        //             this.setState({
        //                 onPush:true
        //             })
        //         })
        //     }
        // })
        //查看预览跳转
        let data = {taskId: this.state.schoolTaskBookDetail.taskId, schoolId: this.state.schoolTaskBookDetail.schoolId, category: this.state.schoolTaskBookDetail.category, isReporting: this.state.schoolTaskBookDetail.isReporting}
        window.open('#/situationcontent?' + JSON.stringify(data));
    }
    //查询专业群
    queryCategoryById() {
        let data = {};  //区分是专业群1或2
        data.id=this.state.schoolId
        queryCatgoryById(data).then(res => {
            if(res){
                this.setState({
                    categoryType :res
                })
            }
        })
    }
    render() {
        const {scoolmessage, synchronTime,schoolDetail, contact, stepdata,iframeSrc,onPreview,filename,fileId, current, time, onPush, previewFileRes, visible, updatedata, schoolContactResult, schoolTaskBookDetail, updateChange, categoryType} = this.state
        const { Step} = Steps;
        let d = new Date(synchronTime);//同步时间转换成正常格式时间
        let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
        let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
        let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
        let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
        let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();
        let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
        return (
            <div className='datatb_center'>
                {/*<div className='footer'>*/}
                {/*    <RenderButton id='SS-01-B08-c_btnSyncData'>*/}
                {/*        <Button className='tongbu-btn' onClick={() => this.updatedata()}>同步数据至最新</Button></RenderButton>*/}
                {/*    <p>最近同步时间：{schoolDetail.synchronTime}</p>*/}
                {/*</div>*/}
                <div className='content' id={'contwidth'}>
                    <div className='group'>
                        <div className='title'>
                            <div></div>
                            <span>院校信息</span>
                        </div>
                        <ul>
                            <li>
                                <label>学校名称：</label>
                                <span>{schoolDetail.schoolName}</span>
                            </li>
                            <li>
                                <label>学校编号：</label>
                                <span>{schoolDetail.schoolEduCode}</span>
                            </li>
                            <li>
                                <label>类型：</label>
                                <span>{schoolDetail.catgory == 1 ? '高水平学校' : schoolDetail.catgory == 2 ? '高水平专业群' : ''}</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>档次：</label>
                                <span>{schoolDetail.gear == 1 ? 'A档' : schoolDetail.gear == 2 ? 'B档' : schoolDetail.gear == 3 ? 'C档' : ''}</span>
                            </li>
                            <li>
                                <label>所在省份：</label>
                                <span>{schoolDetail.province}</span>
                            </li>
                        </ul>
                    </div>
                    <Divider/>
                    <div className='group'>
                        <div className='title'>
                            <div></div>
                            <span>联系人及联系方式</span>
                        </div>
                        <ul>
                            <li>
                                <label>联系人姓名：</label>
                                <span>{contact[0].userName}</span>
                            </li>
                            <li>
                                <label>联系人手机：</label>
                                <span>{contact[0].phone}</span>
                            </li>
                            <li>
                                <label>联系人办公电话：</label>
                                <span>{contact[0].workPhone}</span>
                            </li>
                        </ul>
                    </div>
                    <Divider/>
                    <div className='group'>
                        <div className='title'>
                            <div></div>
                            <span>填报与评价进度</span>
                        </div>
                        <Steps size={"small"} current={current}>
                            {
                                stepdata.map((item,index) => {
                                    return <Step title={item.progressName} description={item.progressTime} key={index}/>
                                })
                            }
                        </Steps>
                    </div>
                    <Divider/>
                    {categoryType === 3? null :
                        <div className='group'>
                            <div className='title'>
                                <div></div>
                                <span>任务书文件</span>
                            </div>
                            <ul className='file-list'>
                                <li>
                                    <label>任务书：</label>
                                    <RenderButton id='SS-01-B08-c_btnViewMissionStatement'>
                                        <div onClick={() => this.tosituationcontent()}>
                                            <span style={{float:'left'}}><Chakan/></span>
                                            <p>查看</p>
                                        </div></RenderButton>
                                </li>
                                <li>
                                    <label>任务书附件：</label>
                                    <RenderButton id='SS-01-B08-c_btnViewMS-Annex'>
                                        <div onClick={() => this.handleBoox()}>
                                            <span style={{float:'left'}}><Chakan/></span>
                                            <p>查看</p>
                                        </div></RenderButton>
                                    {/*<RenderButton id='SS-01-B08-c_btnDownMS-Annex'>*/}
                                    {/*    <div onClick={() => this.handleBooxDownload()}>*/}
                                    {/*        <span style={{float:'left'}}><Xiazai/></span>*/}
                                    {/*        <p>下载</p>*/}
                                    {/*    </div></RenderButton>*/}
                                </li>
                                <li>
                                    <label>建设方案附件：</label>
                                    <RenderButton id='SS-01-B08-c_btnViewConstructionPlan'>
                                        <div onClick={() => this.handleCaseFile()}>
                                            <span style={{float:'left'}}><Chakan/></span>
                                            <p>查看</p>
                                        </div></RenderButton>
                                    {/*<RenderButton id='SS-01-B08-c_btnDownConstructionPlan'>*/}
                                    {/*    <div onClick={() => this.handleCaseFileDownload()}>*/}
                                    {/*        <span style={{float:'left'}}><Xiazai/></span>*/}
                                    {/*        <p>下载</p>*/}
                                    {/*    </div></RenderButton>*/}
                                </li>
                            </ul>
                        </div>
                    }
                    {categoryType === 3? null : <Divider/>}
                    {categoryType === 3? null :
                    <div className='group'>
                        <div className='title'>
                            <div></div>
                            <span>年度工作汇报及绩效</span>
                        </div>
                        <ul className='year-filelist'>
                            <li>
                                <div className='filename'>
                                    2019-2020
                                </div>
                                <div className='caozuo'>
                                    <label>绩效自评表：</label>
                                    {/*<RenderButton id='SS-01-B08-c_btnViewPerformance'>*/}
                                    {/*    <div onClick={() => this.exportPdfTwo()}>*/}
                                    {/*        <span style={{float:'left'}}><Chakan/></span>*/}
                                    {/*        <p>查看</p>*/}
                                    {/*    </div></RenderButton>*/}
                                    <RenderButton id='SS-01-B08-c_btnDownPerformance'>
                                        <div onClick={() => this.export2020Pdf()}>
                                            <span style={{float:'left'}}><Xiazai/></span>
                                            <p>下载</p>
                                        </div></RenderButton>
                                    <label>绩效成果汇总：</label>
                                    <RenderButton id='SS-01-B08-c_btnDownPerformance'>
                                        <div onClick={() => this.exportResultPdf(2020)}>
                                            <span style={{float:'left'}}><Xiazai/></span>
                                            <p>下载</p>
                                        </div></RenderButton>
                                </div>
                            </li>
                            <li>
                                <div className='filename'>
                                    2021
                                </div>
                                <div className='caozuo'>
                                    <label>绩效自评表：</label>
                                    <RenderButton id='SS-01-B08-c_btnDownPerformance'>
                                        <div onClick={() => this.export2021Pdf()}>
                                            <span style={{float:'left'}}><Xiazai/></span>
                                            <p>下载</p>
                                        </div></RenderButton>
                                    <label>绩效成果汇总：</label>
                                    <RenderButton id='SS-01-B08-c_btnDownPerformance'>
                                        <div onClick={() => this.exportResultPdf(2021)}>
                                            <span style={{float:'left'}}><Xiazai/></span>
                                            <p>下载</p>
                                        </div></RenderButton>
                                </div>
                            </li>
                        </ul>
                    </div>
                    }
                </div>

                {/*数据变更提示弹窗*/}
                <Modal
                    title="提示"
                    visible={visible}
                    onOk={this.HandleOk}
                    onCancel={this.HandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    {updateChange ?
                        <div><p>检测到双高检测平台数据有更新，是否同步数据至最新？</p><p className='updatedata'>更新位置：{updatedata}</p></div> : <p>确定同步数据至更新？</p> }

                </Modal>

                <Drawer
                    title={
                        <div className='left-return'>
                            <div className= 'left-retu' onClick={this.onClose}>
                                {/*<img className='return' src={require('../../../../assets/img/fanhui.png')} alt={'返回'}/>*/}
                                <span>返回</span>
                            </div>
                            <span className='center'>{previewFileRes.fileName}</span>
                            <div className='right-culars'>
                            </div>
                        </div>
                    }
                    placement="right"
                    className="particulars_cont"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPush}
                >
                    <Readdetailist previewFileRes={previewFileRes}  onRef={(form) => this.readdetailist = form}/>
                </Drawer>

                <Drawer
                    title={
                        <div className='left-return'>
                            <div className= 'left-retu' onClick={this.onClose}>
                                <img className='return' src={require('../../assets/img/fanhui.png')} alt={'返回'}/>
                                <span>返回</span>
                            </div>
                            <span className='center'>{filename}</span>
                        </div>
                    }
                    placement="right"
                    className="particulars_cont"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPreview}
                >
                    <ReaddetailsPrew iframeSrc={iframeSrc}/>
                </Drawer>
            </div>

        );
    }
}

Synchrodatment = Form.create()(Synchrodatment);
export default Synchrodatment;
