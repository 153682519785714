/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';

import './index.scss';
import '../../styles/global.scss';
import {Form} from "antd";
class footer extends Component {
  state = {
  };
  componentDidMount() {

  }

  render() {
    const {} = this.state;
    return (
        <div className="footer-preview">
        </div>
    );
  }
}

footer = Form.create()(footer);
export default footer;

