import { post } from '../../until/axios_instans';
// 用户登录接口
export function login(data) {
    return post('/api/auth/api/v1/user/login', data);
}
// 用户单点登录接口
export function userLogin(data) {
    return post(`/api/ss/api/v1/login/sys/login`, data);
}
export function downExcel(data) {
    return post(`/api/ss/api/v1/login/downExcel`, data);
}
// 根据企业Id查询信息
export function getEnterpriseInformation(data) {
    return post('/api/es/api/v1/manage/esEnterprise/getEnterpriseInformation', data);
}
