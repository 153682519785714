import React, {Component} from "react";
import './index.scss'

export default class MenuListNull extends Component {

    render() {


        return (
            <div className={'Welcome'}>
                <div>
                    <img src={require("../../assets/img/Frame.png")}/>
                    <p>hi，欢迎使用双高管理系统</p>
                </div>
            </div>
        )
    }
}