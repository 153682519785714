// 资金支出查看
import React, {Component} from "react";
import {Form,Table,Steps,Modal,Button ,Tooltip,Select,Input  } from "antd";
import style from './index.module.scss';
import {withRouter} from "react-router";
import {
    BackToLastPage
} from '../../../../../assets/svg'
import S from "gongyongicon";



@withRouter
class Detailspendingapproval extends Component {
    state = {
        columns:[
            {
                title: '支出内容',
                dataIndex: 'payName',
                key: 'payName',
                align: 'center',
                width: 200,
            },
            {
                title: '经费类型',
                dataIndex: 'payType',
                key: 'payType',
                align: 'center',
                width: 200,
            },
            {
                title: '发生日期',
                dataIndex: 'payDate',
                key: 'payDate',
                align: 'center',
                width: 200,
            },
            {
                title: '金额（人民币元）',
                dataIndex: 'payAmount',
                key: 'payAmount',
                align: 'center',
                width: 200,
            },
        ],
        dataSource:[
            {
                payName:'凉茶 300ml 两罐',
                payType:'1 打造技术技能人才培养高地',
                payDate:"2020-12-30",
                payAmount:'￥99.66',
            },
            {
                payName:'凉茶 300ml 两罐',
                payType:'1 打造技术技能人才培养高地',
                payDate:"2020-12-30",
                payAmount:'￥99.66',
            },
            {
                payName:'凉茶 300ml 两罐',
                payType:'1 打造技术技能人才培养高地',
                payDate:"2020-12-30",
                payAmount:'￥99.66',
            },
            {
                payName:'凉茶 300ml 两罐',
                payType:'1 打造技术技能人才培养高地',
                payDate:"2020-12-30",
                payAmount:'￥99.66',
            },
        ],
        viewphotoModal:false,
        stepsList: [
            {
                title: '曲丽丽（发起审批）' ,
                description: '2021-08-12 13:22'
            },
            {
                title: '周毛毛（已通过）' ,
                description: '2021-08-12 13:22',
                subTitle: '这是我的审批意见希望下次流程可以快一些'
            },
            {
                title: '曲丽丽（发起审批）' ,
                description: '2021-08-12 13:22'
            },
        ],
        completeVisable: false,
        completeVisableone: false
    }
    componentDidMount() {

    }

    //返回主页面
    backTask = () => {
        window.history.back()
    };
    viewPhoto = () => {
        console.log('openphoto')
        this.setState({
            viewphotoModal:true

        })
    };
    handleOk = e => {
        console.log(e);
        this.setState({
            viewphotoModal: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            viewphotoModal: false,
            completeVisable: false,
            completeVisableone: false
        });
    };
    undoBack = e => {
        this.setState({completeVisable: true,completeVisableone: true}) //两个弹窗，用哪个显示那个

    };
    tocompleteOk = () =>{
        this.setState({completeVisable: false})
    }
    tocompleteOkOne = () =>{
        this.setState({completeVisableone: false})
    }
    reset  = e => {
        console.log(e);

    };

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    render() {
        const {columns,dataSource,viewphotoModal, stepsList} =this.state;
        const { Step } = Steps;
        const { Option } = Select;
        return (
            <div className={style.AacapitalexpenditurereviewBG}>
                <div className={'CER_boxA'}>
                    {/*<img src={require(`@/assets/imgnew/yiqidong1.png`)} className={'xmtype'}/>*/}
                    <div className={'CER_boxA_backBtn'} onClick={this .backTask}>
                        <BackToLastPage/>
                        <span> 返回</span>
                    </div>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>基础信息</p></div>
                        <div className={'CER_boxA_contentDetails'}>
                            <div className={'DA'}>
                                <p><span>支出单号：1000000000</span></p>
                                <p><span>填报人：admin</span></p>
                            </div>
                            <div className={'DB'}>
                                <p><span>关联任务：9-1建设智慧校园平台，提升学校管理与服务水平</span></p>
                                <p><span>填报时间：2021-12-12  12:12:12</span></p>
                            </div>
                            <div className={'DC'}>
                                <h3>状态</h3>
                                <h4>待审批</h4>
                            </div>
                            <div className={'DD'}>
                                <h3>总计金额</h3>
                                <h4>¥7980.00</h4>
                            </div>
                        </div>


                    </div>

                </div>
                <div className={'CER_boxB'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>支出信息</p></div>
                        <div className={'CER_boxA_contentDetails'} style={{display:'block'}}>
                            <p className={'boxB_p'}>支出年度：2021年</p>
                            <p className={'boxB_p'}>支出类型：采购费</p>
                            <p className={'boxB_p'} style={{marginBottom:'30px'}}>支出事由：加班饿了，大家买点吃的喝的</p>


                        </div>
                    </div>
                </div>
                <div className={'CER_boxC'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle' } style={{height:'26px'}}><span></span><p>支出明细</p></div>
                        <div className={'CER_boxC_table'}>
                            <Table
                                columns={columns} dataSource={dataSource}
                                pagination={false}
                            />

                            <div className={'boxC_table_bottom'}>
                                <div>
                                    <div><h4>中央财政投入资金：￥20000</h4><span></span ><h4>地方各级财政投入资金：￥19600</h4><span></span><h4>行业企业支持资金：￥65146  </h4><span></span><h4>学校自筹资金：￥55746</h4></div>

                                </div>
                                <div><h2>总计</h2><h5>￥1898.89 </h5></div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className={'CER_boxD'}>
                    <div className={'CER_boxA_content'}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>图片</p></div>
                        <div className={'CER_boxA_contentDetails'} >
                            <div>
                                <ul>
                                    <li className={'CER_boxD_photo'} onClick={()=>this.viewPhoto()}>
                                        <img src={require('../../../../../assets/img/capitalexpenditurereviewJay.png')}/>
                                    </li>
                                    <li className={'CER_boxD_photo'} onClick={()=>this.viewPhoto()}>
                                        <img src={require('../../../../../assets/img/capitalexpenditurereviewJay.png')}/>
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
                <div className={'CER_boxE'}>
                    <div className={'CER_boxA_content'} style={{position: 'relative'}}>
                        <div className={'CER_boxA_contentTitle'}><span></span><p>流程</p></div>
                        <div className={'CER_boxA_contentDetails'}>
                            <Steps className='capitalexpenditurereview_steps' current={1} size="small" >
                                {
                                    stepsList ? stepsList.map((item, index)=>{
                                        {
                                            return  item.subTitle ? <Step title = {item.title} description={item.description} subTitle={item.subTitle} className={'subTitle'}/>
                                            : <Step title = {item.title} description={item.description}/>
                                        }
                                    }) : ''
                                }
                            </Steps>

                        </div>
                    </div>

                </div>
                <div className={'CER_footer'}>
                    <div className={'CER_footer_left'}>
                        <div className={'CER_footer_leftL'}>
                            <span className={'star'}>*</span><span className={'star_title'}>审批结果</span>
                            <Select defaultValue="lili" style={{ width: 120 }} onChange={()=>this.handleChange}
                                    placeholder='请选择'>
                                <Option value="lili">请选择</Option>
                                <Option value="jack">通过</Option>
                                <Option value="lucy">不通过</Option>

                            </Select>
                        </div>
                        <div className={'CER_footer_leftR'}>
                            <span className={'star_title'} style={{width:'81px'}}>审批意见</span>
                            <Input placeholder="" className={'footer_input'}/>
                        </div>


                    </div>
                    <div>
                        <div className={'quanxiansz_btnUndoBack'} onClick={() => this.undoBack()}>提交</div>
                        <div className={'quanxiansz_btnReset'} onClick={() => this.reset()}>取 消</div>
                    </div>

                </div>

                <Modal
                    title="查看图片"
                    visible={viewphotoModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div style={{width:'140px',height:'140px',textAlign:'center'}}>
                        <img src={require('../../../../../assets/img/capitalexpenditurereviewJay.png')}/>
                    </div>
                </Modal>
            </div>

        );
    }
}

Detailspendingapproval = Form.create()(Detailspendingapproval);
export default Detailspendingapproval;
