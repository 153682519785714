import React from 'react';
import './index.scss';
import OSS from "ali-oss";
import moment from "moment"
import {connect} from 'react-redux';
import {changeIndex} from '@store/OBS/main/action'
import {Button, Drawer, Dropdown, Menu, message, Modal, Upload, Icon, Tabs, Popover, Tooltip} from 'antd';
import RouterView from '../../router/router_view';
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import PersonalPassword from './components/personalPassword'
import PersonalJurisdiction from './components/personalJurisdiction'
import {getOssToken, updatePassword, uploadFace, savePortraitUrl} from '../../api/user/'
import {Logo} from '../../assets/svg'
import {
    queryAllMessage, queryUnReadMessage, queryBeginMessage, queryReadMessage, unReadMsgCount,
    queryAtMeMessage, deleteAllReadMessage, singleMsgToRead, allMessageToRead, checkTargetIsDeleted
} from '../../api/messageCenter/'
import {uploadFile} from '../../api/file/';
import {AESKey} from '../../config/secret'

import {
    SysMsg,
    UserMsg,
    PopTriangle,
    PopTriangleB,
    PopTriangleC,
    PopTriangleD,
    SysMsgWhite,
    TaskWhite,
    PerformanceWhite,
    ConstructionWhite,
    ProjectName,
    Notification,
    MessageNum
} from '../../assets/svg'
import S from "gongyongicon";

const mapStateToProps = ({MAIN}) => {
    const {activeKey, menuList} = MAIN;
    return {
        activeKey, menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeIndex(data) {
            dispatch(changeIndex(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(class Main extends React.Component {
    state = {
        handleLogoutModal: false,//退出登录modal
        jurisdiction: false,//查看权限
        editPassword: false,//修改密码
        visible: false,//个人中心右侧滑出块
        messageVisible: false,//消息抽屉
        popView: false,//消息通知-操作···
        // innerWebsite: localStorage.getItem("innerWebsite"),
        innerWebsite: '',
        info: Cookies.get('ssInfo') ? JSON.parse(Cookies.get('ssInfo')) : {},
        schoolInfo: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo : "",
        userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo : "",
        portraitCache: Cookies.get('portraitCache') ? Cookies.get('portraitCache') : "",
        yxData: {},//院系权限数据
        yhData: {},//用户权限数据
        portrait: '',
        showTouxiang: false,
        fileListOne: null,
        allMsgCount: 0,
        readMsgCount: 0,//已读数量
        unreadMsgCount: 0,//未读数量
        atMeMsgCount: 0,//@我的数量
        taskNearExpireCount: 0,//即将逾期数量
        allMessage: [],
        readMessage: [],
        unReadMessage: [],
        atMeMessage: [],
        statusButten: true,
        unreadCount: 0


    };

    componentDidMount() {
        this.unReadMsgCount()
        this.props.changeIndex("0");
        setTimeout(() => {
            this.setState({
                innerWebsite: localStorage.getItem("innerWebsite") ? localStorage.getItem("innerWebsite") : "",
            })
            if (this.state.userInfo == '') {
                this.setState({userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo})
            }
        }, 1000)

    }

    change = (item) => {
        // this.props.changeIndex(item.key);
        this.props.history.push(item.menuUrl);
    }

    //退出登录
    handleLogout = () => {
        this.setState({
            handleLogoutModal: true,
        })
    }

    handleLogoutOk = () => {
        this.logout()
        this.setState({
            handleLogoutModal: false,
        })
    }

    handleLogoutCancel = () => {
        this.setState({
            handleLogoutModal: false,
        })
    };

    logout = () => {
        Cookies.remove('token');
        Cookies.remove('schoolInfo');
        Cookies.remove('userInfo');
        Cookies.remove('info');
        Cookies.remove('list2');
        Cookies.remove('buttonPerms');
        Cookies.remove('merit');
        this.props.history.push({
            pathname: '/login',
        })
    }
    onLink = (innerWebsite) => {
        if (innerWebsite === "" || innerWebsite === "undefined" || innerWebsite === null || innerWebsite === undefined) {
            this.mesWarning('该企业域名暂未配置');
            return;
        }
        window.open(innerWebsite);

    }

    checkTargetIsDeleted = async (targetId, modelType) => {
        let data = {
            schoolId: this.state.schoolInfo.id,
            targetId: targetId,
            modelType: modelType,
        }
        await checkTargetIsDeleted(data).then(res => {

        })
    }
    //打开个人中心抽屉
    openUserInfo = () => {
        let data = {
            schoolId: this.state.schoolId,
            shoolInfo: this.state.schoolInfo,
            userInfo: this.state.userInfo
        }
        this.setState({
            visible: true,
        });
    }
    //关闭抽屉
    onClose = () => {
        console.log('关闭抽屉')
        this.unReadMsgCount()
        this.setState({visible: false, messageVisible: false, popView: false})

    }
    //修改密码
    changePassword = () => {
        this.setState({editPassword: true})
    }
    // 修改密码取消按钮
    editHandleCancel = () => {
        this.setState({editPassword: false})
    }
    //初始化信息中心数据
    queryBeginMessage = async () => {
        let data = {
            schoolId: this.state.schoolInfo.id,
            userId: this.state.userInfo.id
        };
        queryBeginMessage(data).then(res => {
            this.setState({
                readMsgCount: res.readMsgCount,
                unreadMsgCount: res.unReadMsgCount,
                atMeMsgCount: res.atMeMsgCount,
                taskNearExpireCount: res.taskNearExpireCount,
            })
        })
    }

    //判断小铃铛已读未读
    unReadMsgCount = async () => {
        let data = {
            schoolId: this.state.schoolInfo.id,
            userId: this.state.userInfo.id
        };
        unReadMsgCount(data).then(res => {
            console.log('res', res)
            this.setState({
                unreadCount: res,
            })
        })
    }

    // 修改密码确认按钮
    editHandleOk = () => {
        // this.props.form.validateFields((err, values) => {
        //     if (!err) {
        //         if (values.newPassword.toLowerCase() !== values.againPassword.toLowerCase()) {
        //             message.destroy();
        //             return message.error('新密码二次输入不一致');
        //         }
        //         let { userInfo } = this.state;
        //         if (userInfo) {
        //             updatePassword(
        //                 {
        //                     id: userInfo.id,
        //                     oldPassword: values.oldPassword.toLowerCase(),
        //                     newPassword: values.newPassword.toLowerCase(),
        //                     againPassword: values.againPassword.toLowerCase()
        //                 }
        //             ).then(res => {
        //                 if (res) {
        //                     message.success('修改成功');
        //                     setTimeout(() => {
        //                         this.props.history.push({
        //                             pathname: '/login',
        //                         })
        //                         // history.replace('/login')
        //                     }, 1000);
        //                     // setTimeout(() => {
        //                     //   this.setState({ispassword: false})
        //                     // }, 1000)
        //                 }
        //             });
        //         }
        //     }
        // })
    }
    //个人中心查看权限
    personalJurisdiction = () => {
        let userInfo = this.state.userInfo;
            if (userInfo.isAdmin === 1) {
                this.mesSuccess("您是超级管理员，拥有全部权限")
                return false
            } else if (userInfo.roleNames === "--") {
                this.mesWarning("请联系管理员添加角色权限")
                return false
            }else {
            this.setState({jurisdiction: true})
        }
    }
    ////权限查看确认btn
    jurisdictionOk = () => {
        this.setState({jurisdiction: false})

    };
    //权限查看取消btn
    jurisdictionCancel = () => {
        this.setState({jurisdiction: false})

    };

    onRef = (ref) => {
        this.child = ref
    };

    updatePassword = async () => {
        let formValue = this.child.getValues();
        if (formValue) {
            if (formValue.newPassword.toLowerCase() !== formValue.againPassword.toLowerCase()) {
                message.destroy();
                return this.mesWarning('新密码二次输入不一致');
            }
            let key = CryptoJS.enc.Utf8.parse(AESKey);
            let iv = CryptoJS.enc.Utf8.parse(AESKey);
            let oldPassword1 = CryptoJS.AES.encrypt(formValue.oldPassword, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            let newPassword1 = CryptoJS.AES.encrypt(formValue.newPassword, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            let againPassword1 = CryptoJS.AES.encrypt(formValue.againPassword, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            let oldPassword = CryptoJS.enc.Base64.stringify(oldPassword1.ciphertext).toString();
            let newPassword = CryptoJS.enc.Base64.stringify(newPassword1.ciphertext).toString();
            let againPassword = CryptoJS.enc.Base64.stringify(againPassword1.ciphertext).toString();
            let param = {
                phone: this.state.userInfo.phone,
                oldPassword,
                newPassword,
                againPassword
            };
            await updatePassword(param).then(res => {
                if (res) {
                    this.mesSuccess('密码修改成功');
                    this.setState({editPassword: false});
                    this.logout()
                }
            });

        }

    };
    showTouxiang = () => {
        this.setState({showTouxiang: true})
    }

    hideTouxiang = () => {
        this.setState({showTouxiang: false})
    }
    //上传图片
    changeLoad = async (info, type) => {
        const fromData = new FormData()
        fromData.append('file', info)
        fromData.append('folder', 'ss')
        const uploadResult = await uploadFace(fromData)
    };

    //上传建设任务书
    onChange = async info => {
        let fileType = ["jpg", "png", "jpeg", "bmp"]
        let fileName = info.file.name
        let index = fileName.lastIndexOf('.');
        let type = fileName.substring(index + 1, fileName.length)
        const isHereOrNot = fileType.includes(type);
        if (!isHereOrNot) {
            message.destroy()
            message.info("上传文件格式不正确");
            return
        }
        if (info.file.status !== 'uploading') {
        }
        let ymd = moment(new Date()).format('YYYY-MM-DD');
        ymd = ymd.replace(/-/g, '');
        let key = Math.floor(Math.random()).toString(8) + new Date().getTime()
        key = `/cvec-front-img/cvec-doublehigh-school-front/${ymd}/${key}`
        let result = this.client.put(key, info.file.originFileObj);
        if (result) {
        }
    };
    //上传头像
    beforeUpload = async (file) => {
        this.setState({loading: true});
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'ss')
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            let file = {fileId: res.fpName, id: this.state.userInfo.id}
            savePortraitUrl(file).then(result => {
                if (result = 1) {
                    this.mesSuccess("更新头像成功")
                    this.setState({portrait: res.fpName})
                    Cookies.set('portraitCache', res.fpName);
                    this.setState({showTouxiang: false})
                }

            })

        }
    }

    callback = (e) => {
        console.log("当前的tab-key", e)
        // message.success(e)
        if (e === '1') {//全部消息
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            queryAllMessage(data).then(res => {
                this.setState({allMessage: res.messageList, allMsgCount: res.count})
            });
            this.setState({
                messageVisible: true
            })

        } else if (e === '2') {//未读
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            queryUnReadMessage(data).then(res => {
                this.setState({unReadMessage: res.messageList, unreadMsgCount: res.count})
            });
            this.setState({
                messageVisible: true
            })

        } else if (e === '3') {//@我的
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            queryAtMeMessage(data).then(res => {
                this.setState({atMeMessage: res.messageList, atMeMsgCount: res.count})
            });
            this.setState({
                messageVisible: true
            })

        } else if (e === '4') {//已读
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            queryReadMessage(data).then(res => {
                this.setState({readMessage: res.messageList, readMsgCount: res.count})
            });
            this.setState({
                messageVisible: true
            })

        }

    };
    handleVisibleChange = popView => {
        if (this.state.popView === true) {
            this.setState({popView: false});
        } else {
            this.setState({popView: true});
        }
    };
    //将所有未读标记为已读
    unReadToRead = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            allMessageToRead(data).then(res => {
                //刷新所有信息
                queryAllMessage(data).then(res => {
                    this.setState({allMessage: res.messageList, allMsgCount: res.count})
                });
                //刷新未读信息
                queryUnReadMessage(data).then(res => {
                    this.setState({unReadMessage: res.messageList, unreadMsgCount: res.count})
                });
                //刷新@我的信息
                queryAtMeMessage(data).then(res => {
                    this.setState({atMeMessage: res.messageList, atMeMsgCount: res.count})
                });
                //刷新已读信息
                queryReadMessage(data).then(res => {
                    this.setState({readMessage: res.messageList, readMsgCount: res.count})
                });
                this.setState({
                    popView: false,
                });
            });
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1500);
    };
    //删除所有已读消息
    deleteAllReadMsg = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let data = {
                schoolId: this.state.schoolInfo.id,
                userId: this.state.userInfo.id,
            };
            //删除所有已读消息
            deleteAllReadMessage(data).then(res => {
                //刷新所有信息
                queryAllMessage(data).then(res => {
                    this.setState({allMessage: res.messageList, allMsgCount: res.count})
                });
                //刷新未读信息
                queryUnReadMessage(data).then(res => {
                    this.setState({unReadMessage: res.messageList, unreadMsgCount: res.count})
                });
                //刷新@我的信息
                queryAtMeMessage(data).then(res => {
                    this.setState({atMeMessage: res.messageList, atMeMsgCount: res.count})
                });
                //刷新已读信息
                queryReadMessage(data).then(res => {
                    this.setState({readMessage: res.messageList, readMsgCount: res.count})
                });
                this.setState({
                    popView: false,
                });
            });

        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1500);
    };
    closePop = () => {
        this.setState({
            popView: false,
        });
    };

    //跳转任务详情页
    goTaskPage = (item) => {

        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let data = {
                schoolId: this.state.schoolInfo.id,
                userMessageId: item.id,
                userId: this.state.userInfo.id
            };
            if (item.isRead === '0') {
                singleMsgToRead(data).then(res => {
                    this.unReadMsgCount()
                });
                // if (this.state.unreadCount == 1) {
                //     // this.setState({unreadCount: 0})
                // }

            }
            this.setState({
                messageVisible: false
            })
            this.checkTargetIsDeleted(item.taskId, item.modelType, item.isRead, item.id).then(res => {
                this.props.history.push({pathname: '/main/doublehigh/operation/' + item.projectId + "/" + item.taskId});
            })
        }


        setTimeout(() => {
            this.setState({statusButten: true})
        }, 1500)
    };

    //跳转建设任务详情页
    goProjectPage = (item) => {
        // this.unReadMsgCount()
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.checkTargetIsDeleted(item.taskId, item.modelType).then(res => {
                this.props.history.push({pathname: '/main/doublehigh/project/' + item.taskId});
                let data = {
                    schoolId: this.state.schoolInfo.id,
                    userMessageId: item.id,
                    userId: this.state.userInfo.id
                };
                if (item.isRead === '0') {
                    singleMsgToRead(data).then(res => {
                        this.unReadMsgCount()
                    });
                    // if (this.state.unreadCount == 1) {
                    //     this.setState({unreadCount: 0})
                    // }
                }
                this.setState({
                    messageVisible: false
                })
            })
        }
        setTimeout(() => {
            this.setState({statusButten: true})
        }, 1500)

    };

    //跳转绩效详情页
    goMeritPage = (item) => {
        // this.unReadMsgCount()
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.checkTargetIsDeleted(item.taskId, item.modelType).then(res => {
                this.props.history.push({pathname: '/main/doublehigh/performancedetails/' + item.taskId});
                let data = {
                    schoolId: this.state.schoolInfo.id,
                    userMessageId: item.id,
                    userId: this.state.userInfo.id
                };
                if (item.isRead === '0') {
                    singleMsgToRead(data).then(res => {
                        this.unReadMsgCount()
                    });
                    // if (this.state.unreadCount == 1) {
                    //     this.setState({unreadCount: 0})
                    // }
                }
                this.setState({
                    messageVisible: false
                })
            })
        }
        setTimeout(() => {
            this.setState({statusButten: true})
        }, 1500)

    };

    openMsg = async () => {
        let data = {
            schoolId: this.state.schoolInfo.id,
            userId: this.state.userInfo.id,
        };
        this.queryBeginMessage();
        await queryAllMessage(data).then(res => {
            this.setState({allMessage: res.messageList, allMsgCount: res.count})
        });
        this.setState({
            messageVisible: true
        })
    };


    render() {
        const {popView, schoolInfo, allMsgCount, readMsgCount, unreadMsgCount, unreadCount, atMeMsgCount, readMessageList, unReadMessageList, atMeMessageList, taskNearExpireCount, userInfo, portraitCache, portrait, editPassword, jurisdiction, showTouxiang} = this.state;
        const {menuList} = this.props;
        const {TabPane} = Tabs;
        const uploadButton = (
            <div>
                <div className="ant-upload-text">
                    <Icon type="edit"/>
                    <span className={'sctx'}>修改头像</span>
                </div>
            </div>
        );
        const menu = (
            <Menu className='menudown'>
                <Menu.Item key="1">
                    <a onClick={this.openUserInfo}>个人信息</a>
                </Menu.Item>
                <Menu.Item key="3" onClick={this.handleLogout}>退出登录</Menu.Item>
            </Menu>
        );
        let pathname = this.props.location.pathname;
        const props = {
            name: 'file',
            fileList: null,
            onChange: this.onChange,
        };

        const numMsg = '326';


        return (
            <div className='main_container'>
                <div className='header_menu'>
                    <div className="menu_left">
                        <span style={{height: "30px", margin: "0 10px", display: "block",marginTop:'-8px'}}>
                                 <Logo/>
                        </span>
                        {/*<img style={{ height: "18px", margin: "0 10px", display: "block" }} src={require('../../assets/img/Titleimg2.png')} alt="" />*/}
                        <ProjectName/>
                        {menuList.map((v, i) => {
                            return <div key={i} className={pathname.includes(v.menuUrl) ? 'active' : ''}
                                        onClick={() => this.change(v)}>{v.menuName}</div>
                        })}
                    </div>
                    {unreadCount && unreadCount > 0 ? <div className={'message'} onClick={this.openMsg}>
                        <Notification></Notification><MessageNum/>
                    </div> : <div className={'message'} onClick={this.openMsg}><Notification></Notification></div>
                    }

                    <Dropdown overlay={menu} className='menu_right' placement="bottomCenter"
                              overlayClassName="Dropdown-ul">
                        <div onClick={e => e.preventDefault()}>

                            <img className='usericon'
                                 src={portrait === '' ? window.$$uploadRc(portraitCache) : window.$$uploadRc (portrait)} alt=""/>
                            <div className="user-right">
                                <h2 className="username">{userInfo.humanName}</h2> {/*角色*/}
                            </div>
                        </div>
                    </Dropdown>
                </div>
                <div className='container'>

                    <RouterView routers={this.props.routers}/>
                </div>

                {/*个人中心-抽屉*/}
                <Drawer
                    title="个人中心"//标题
                    placement="right"//滑出方向
                    closable={false}//右上角关闭按钮
                    onClose={this.onClose}//点遮罩或者×或者取消的时候触发的事件
                    visible={this.state.visible}//Drawer 是否可见
                    width={500}
                    className='drawerS'
                >
                    <div className='gerenzhongxin'>
                        <div className="title">
                            <img className='userImage'
                                 src={portrait === '' ? window.$$uploadRc(portraitCache) : window.$$uploadRc(portrait)} alt=""
                                 onMouseOver={() => this.showTouxiang()}/>
                            {showTouxiang ? <form className={'uploadform'} onMouseOut={() => this.hideTouxiang()}>
                                <Upload
                                    fileList={[]}
                                    multiple={true}
                                    beforeUpload={this.beforeUpload}
                                    listType="picture-card"
                                    showUploadList={false}>
                                    {uploadButton}
                                </Upload>
                            </form> : ''}
                            <div className="img-text">您好，{userInfo.humanName}{this.props.form}</div>
                            <div className={'zhuwu'}>{userInfo.position}</div>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>手机号：</span>
                            <span className='spanRight'>{userInfo.phone}</span>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>所属组织：</span>
                            <span className='spanRight'>{schoolInfo.schoolName}</span>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>职务：</span>
                            <span className='spanRight'>{userInfo.position}</span>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>角色：</span>
                            <span className='spanRight' style={{"white-space": 'pre-line'}}>{userInfo.roleNames}</span>

                            <span className='spanLeft'><img src={require('../../assets/img/look0.png')}
                                                            className={'iconlook'}/></span>
                            <span className='spanRight' style={{'color': '#40a9ff', 'font-size': '14px'}}
                                  onClick={this.personalJurisdiction}>查看权限</span>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>创建时间：</span>
                            <span className='spanRight'>{userInfo.createTime}</span>
                        </div>
                        <div className='fontsizee'><span className='spanLeft'>最后登录时间：</span>
                            <span className='spanRight'>{userInfo.logoutTime}</span>
                        </div>
                        <Button className='btnEditPsd' onClick={this.changePassword}>修改密码
                        </Button>
                    </div>

                </Drawer>

                {/*消息中心-抽屉*/}
                <Drawer
                    title="消息通知"//标题
                    placement="right"//滑出方向
                    closable={false}//右上角关闭按钮
                    onClose={this.onClose}//点遮罩或者×或者取消的时候触发的事件
                    visible={this.state.messageVisible}//Drawer 是否可见
                    width={740}
                    className='drawerMsg'
                    destroyOnClose={true}
                >
                    <div className='messageTabs'>
                        <Tabs defaultActiveKey='1' onChange={this.callback}>
                            <TabPane tab={"消息  (" + allMsgCount + ')'} key="1">
                                {
                                    this.state.allMessage.length >0 ? this.state.allMessage.map((item, index) => (
                                        // 建设任务动态
                                        item.modelType == 1 ?
                                            <div className='allMsg'>
                                                <PopTriangleB/>
                                                <ConstructionWhite/>
                                                <div className='allMsgContent'>
                                                    <p className='msgTitle'
                                                       onClick={() => this.goProjectPage(item)}>{item.editorName} 更新了建设任务【{item.messageName}】</p>
                                                    <div className={'right-r'}>
                                                        {item.portraitId ? <img className='img-portrait'
                                                                                src={window.$$uploadRc(item.portraitId)} alt=""/>
                                                            : <img className='img-portrait'
                                                                   src={require('@/assets/img/Header/mrtx.jpg')}
                                                                   alt=""/>
                                                        }
                                                        {item.isRead ? item.isRead === '1' ?
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsB cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                        }
                                                    </div>
                                                    <p className='msgTime cursorDefault'>{item.createTime}</p>
                                                </div>
                                            </div> :
                                            // 任务动态
                                            item.modelType == 2 ?
                                                <div className='allMsg'>
                                                    <PopTriangleB/>
                                                    <TaskWhite/>
                                                    <div className='allMsgContent'>
                                                        <p className='msgTitle'
                                                           onClick={() => this.goTaskPage(item)}>{item.editorName} 更新了任务【{item.messageName}】</p>
                                                        <div className={'right-r'}>
                                                            {item.portraitId ? <img className='img-portrait'
                                                                                    src={window.$$uploadRc(item.portraitId)}
                                                                                    alt=""/>
                                                                : <img className='img-portrait'
                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                       alt=""/>
                                                            }
                                                            {item.isRead ? item.isRead === '1' ?
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsB cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                            }
                                                        </div>
                                                        <p className='msgTime cursorDefault'>{item.createTime}</p>
                                                    </div>
                                                </div> :
                                                // 绩效动态
                                                item.modelType == 3 ?
                                                    <div className='allMsg'>
                                                        <PopTriangleB/>
                                                        <PerformanceWhite/>
                                                        <div className='allMsgContent'>
                                                            <p className='msgTitle'
                                                               onClick={() => this.goMeritPage(item)}>{item.editorName} 更新了绩效【{item.messageName}】</p>
                                                            <div className={'right-r'}>
                                                                {item.portraitId ? <img className='img-portrait'
                                                                                        src={window.$$uploadRc(item.portraitId)}
                                                                                        alt=""/>
                                                                    : <img className='img-portrait'
                                                                           src={require('@/assets/img/Header/mrtx.jpg')}
                                                                           alt=""/>
                                                                }
                                                                {item.isRead ? item.isRead === '1' ?
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC cursorDefault'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                }
                                                            </div>
                                                            <p className='msgTime cursorDefault'>{item.createTime}</p>
                                                        </div>
                                                    </div> :
                                                    // 评论
                                                    item.modelType == 4 ?
                                                        <div className='allMsg'>
                                                            <PopTriangleB/>
                                                            <TaskWhite/>
                                                            <div className='allMsgContent'>
                                                                <ul className={'dtlist'}>
                                                                    <li>
                                                                        <p className={'msgTitle'}
                                                                           onClick={() => this.goTaskPage(item)}>{item.humanName} 评论了任务【{item.taskName}】
                                                                        </p>
                                                                        <div className={'right-r'}>
                                                                            {item.portraitIds ?
                                                                                <img className='img-portrait'
                                                                                     src={window.$$uploadRc(item.portraitIds)}
                                                                                     alt=""/>
                                                                                : <img className='img-portrait'
                                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                                       alt=""/>
                                                                            }
                                                                            <p className={'msgMsgAndIcon'}><span
                                                                                className='msgTipsB cursorDefault'>
                                                                                <div className={'pinglunlist'}>
                                                                            {item.isRead ? item.isRead === '1' ?
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className={'imgList'}>
                                                                            {item.relatedPic ? item.relatedPic.split(",").map((item, number) => (
                                                                                <img className='img-upload'
                                                                                     src={window.$$uploadRc(item)}
                                                                                     alt=""/>
                                                                            )) : ""}
                                                                        </div>
                                                            </span>
                                                                            </p>
                                                                        </div>
                                                                        <p className='msgTime cursorDefault'>{item.createTime}</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div> :
                                                        // 资金动态
                                                        item.modelType == 5 ?
                                                            <div className='allMsg'>
                                                                <PopTriangleB/>
                                                                <TaskWhite/>
                                                                <div className='allMsgContent'>
                                                                    <p className='msgTitle cursorDefault'>{item.editorName} {item.action} 【{item.taskName}】</p>
                                                                    {/*<p className='msgMsgAndIcon'><span className='msgTipsB'>@xurunzhi 这个任务我做完了，你检查下，没问题的话就点完成任务吧</span></p>*/}
                                                                    <p className='msgTime cursorDefault'>{item.createTime}</p>
                                                                </div>
                                                            </div> :
                                                            ""
                                    )) :
                                        <div className={'empty-wrap'}>
                                            <div className={'empty-img'}>
                                                <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            </div>
                                            <div className={'empty-text'}>暂无数据</div>
                                        </div>

                                }
                            </TabPane>

                            <TabPane tab={"未读 (" + unreadMsgCount + ')'} key="2">
                                {
                                    this.state.unReadMessage.length > 0 ? this.state.unReadMessage.map((item, index) => (
                                        // 建设任务
                                        item.modelType == 1 ?
                                            <div className='allMsg'>
                                                <PopTriangleB/>
                                                <ConstructionWhite/>
                                                <div className='allMsgContent'>
                                                    <p className='msgTitle'
                                                       onClick={() => this.goProjectPage(item)}>{item.editorName} 更新了建设任务【{item.messageName}】</p>
                                                    <div className={'right-r'}>
                                                        {item.portraitId ? <img className='img-portrait'
                                                                                src={window.$$uploadRc(item.portraitId)} alt=""/>
                                                            : <img className='img-portrait'
                                                                   src={require('@/assets/img/Header/mrtx.jpg')}
                                                                   alt=""/>
                                                        }
                                                        {item.isRead ? item.isRead === '1' ?
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                        }
                                                    </div>
                                                    <p className='msgTime'>{item.createTime}</p>
                                                </div>
                                            </div> :
                                            // 任务
                                            item.modelType == 2 ?
                                                <div className='allMsg'>
                                                    <PopTriangleB/>
                                                    <TaskWhite/>
                                                    <div className='allMsgContent'>
                                                        <p className='msgTitle'
                                                           onClick={() => this.goTaskPage(item)}>{item.editorName} 更新了任务【{item.messageName}】</p>
                                                        <div className={'right-r'}>
                                                            {item.portraitId ? <img className='img-portrait'
                                                                                    src={window.$$uploadRc(item.portraitId)}
                                                                                    alt=""/>
                                                                : <img className='img-portrait'
                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                       alt=""/>
                                                            }
                                                            {item.isRead ? item.isRead === '1' ?
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                            }
                                                        </div>
                                                        <p className='msgTime'>{item.createTime}</p>
                                                    </div>
                                                </div> :
                                                // 绩效
                                                item.modelType == 3 ?
                                                    <div className='allMsg'>
                                                        <PopTriangleB/>
                                                        <PerformanceWhite/>
                                                        <div className='allMsgContent'>
                                                            <p className='msgTitle'
                                                               onClick={() => this.goMeritPage(item)}>{item.editorName} 更新了绩效【{item.messageName}】</p>
                                                            <div className={'right-r'}>
                                                                {item.portraitId ? <img className='img-portrait'
                                                                                        src={window.$$uploadRc(item.portraitId)}
                                                                                        alt=""/>
                                                                    : <img className='img-portrait'
                                                                           src={require('@/assets/img/Header/mrtx.jpg')}
                                                                           alt=""/>
                                                                }
                                                                {item.isRead ? item.isRead === '1' ?
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                }
                                                            </div>
                                                            <p className='msgTime'>{item.createTime}</p>
                                                        </div>
                                                    </div> :
                                                    // 评论
                                                    item.modelType == 4 ?
                                                        <div className='allMsg'>
                                                            <PopTriangleB/>
                                                            <TaskWhite/>
                                                            <div className='allMsgContent'>
                                                                <ul className={'dtlist'}>
                                                                    <li>
                                                                        <p className={'msgTitle'}
                                                                           onClick={() => this.goTaskPage(item)}>{item.humanName} 评论了任务【{item.taskName}】
                                                                        </p>
                                                                        <div className={'right-r'}>
                                                                            {item.portraitIds ?
                                                                                <img className='img-portrait'
                                                                                     src={window.$$uploadRc(item.portraitIds)}
                                                                                     alt=""/>
                                                                                : <img className='img-portrait'
                                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                                       alt=""/>
                                                                            }
                                                                            <p className={'msgMsgAndIcon'}><span
                                                                                className='msgTipsB'>
                                                                                <div className={'pinglunlist'}>
                                                                            {item.isRead ? item.isRead === '1' ?
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className={'imgList'}>
                                                                            {item.relatedPic ? item.relatedPic.split(",").map((item, number) => (
                                                                                <img className='img-upload'
                                                                                     src={window.$$uploadRc(item)}
                                                                                     alt=""/>
                                                                            )) : ""}
                                                                        </div>
                                                            </span>
                                                                            </p>
                                                                        </div>
                                                                        <p className='msgTime'>{item.createTime}</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div> :
                                                        // 资金
                                                        item.modelType == 5 ?
                                                            <div className='allMsg'>
                                                                <PopTriangleB/>
                                                                <TaskWhite/>
                                                                <div className='allMsgContent'>
                                                                    <p className='msgTitle'>{item.editorName} {item.action} 【{item.messageName}】</p>
                                                                    {/*<p className='msgMsgAndIcon'><span className='msgTipsB'>@xurunzhi 这个任务我做完了，你检查下，没问题的话就点完成任务吧</span></p>*/}
                                                                    <p className='msgTime'>{item.createTime}</p>
                                                                </div>
                                                            </div> :
                                                            ""
                                    )) :
                                        <div className={'empty-wrap'}>
                                            <div className={'empty-img'}>
                                                <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            </div>
                                            <div className={'empty-text'}>暂无数据</div>
                                        </div>

                                }
                            </TabPane>

                            <TabPane tab={"@我的 (" + atMeMsgCount + ')'} key="3">
                                {
                                    this.state.atMeMessage.length > 0 ? this.state.atMeMessage.map((item, index) => (
                                        // 建设任务
                                        item.modelType == 1 ?
                                            <div className='allMsg'>
                                                <PopTriangleB/>
                                                <ConstructionWhite/>
                                                <div className='allMsgContent'>
                                                    <p className='msgTitle'
                                                       onClick={() => this.goProjectPage(item)}>{item.editorName} 更新了建设任务【{item.messageName}】</p>
                                                    <div className={'right-r'}>
                                                        {item.portraitId ? <img className='img-portrait'
                                                                                src={window.$$uploadRc(item.portraitId)} alt=""/>
                                                            : <img className='img-portrait'
                                                                   src={require('@/assets/img/Header/mrtx.jpg')}
                                                                   alt=""/>
                                                        }
                                                        {item.isRead ? item.isRead === '1' ?
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                        }
                                                    </div>
                                                    <p className='msgTime'>{item.createTime}</p>
                                                </div>
                                            </div> :
                                            // 任务
                                            item.modelType == 2 ?
                                                <div className='allMsg'>
                                                    <PopTriangleB/>
                                                    <TaskWhite/>
                                                    <div className='allMsgContent'>
                                                        <p className='msgTitle'
                                                           onClick={() => this.goTaskPage(item)}>{item.editorName} 更新了任务【{item.messageName}】</p>
                                                        <div className={'right-r'}>
                                                            {item.portraitId ? <img className='img-portrait'
                                                                                    src={window.$$uploadRc(item.portraitId)}
                                                                                    alt=""/>
                                                                : <img className='img-portrait'
                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                       alt=""/>
                                                            }
                                                            {item.isRead ? item.isRead === '1' ?
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                            }
                                                        </div>
                                                        <p className='msgTime'>{item.createTime}</p>
                                                    </div>
                                                </div> :
                                                // 绩效
                                                item.modelType == 3 ?
                                                    <div className='allMsg'>
                                                        <PopTriangleB/>
                                                        <PerformanceWhite/>
                                                        <div className='allMsgContent'>
                                                            <p className='msgTitle'
                                                               onClick={() => this.goMeritPage(item)}>{item.editorName} 更新了绩效【{item.messageName}】</p>
                                                            <div className={'right-r'}>
                                                                {item.portraitId ? <img className='img-portrait'
                                                                                        src={window.$$uploadRc(item.portraitId)}
                                                                                        alt=""/>
                                                                    : <img className='img-portrait'
                                                                           src={require('@/assets/img/Header/mrtx.jpg')}
                                                                           alt=""/>
                                                                }
                                                                {item.isRead ? item.isRead === '1' ?
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                }
                                                            </div>
                                                            <p className='msgTime'>{item.createTime}</p>
                                                        </div>
                                                    </div> :
                                                    // 评论
                                                    item.modelType == 4 ?
                                                        <div className='allMsg'>
                                                            <PopTriangleB/>
                                                            <TaskWhite/>
                                                            <div className='allMsgContent'>
                                                                <ul className={'dtlist'}>
                                                                    <li>
                                                                        <p className={'msgTitle'}
                                                                           onClick={() => this.goTaskPage(item)}>{item.humanName} 评论了任务【{item.taskName}】
                                                                        </p>
                                                                        <div className={'right-r'}>
                                                                            {item.portraitIds ?
                                                                                <img className='img-portrait'
                                                                                     src={window.$$uploadRc(item.portraitIds)}
                                                                                     alt=""/>
                                                                                : <img className='img-portrait'
                                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                                       alt=""/>
                                                                            }
                                                                            <p className={'msgMsgAndIcon'}><span
                                                                                className='msgTipsB'>
                                                                                <div className={'pinglunlist'}>
                                                                            {item.isRead ? item.isRead === '1' ?
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                                :
                                                                                <div className={'deTails'}>
                                                                                    {item.reviewDetail}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className={'imgList'}>
                                                                            {item.relatedPic ? item.relatedPic.split(",").map((item, number) => (
                                                                                <img className='img-upload'
                                                                                     src={window.$$uploadRc(item)}
                                                                                     alt=""/>
                                                                            )) : ""}
                                                                        </div>
                                                                            </span>
                                                                            </p>
                                                                        </div>
                                                                        <p className='msgTime'>{item.createTime}</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div> :
                                                        // 资金
                                                        item.modelType == 5 ?
                                                            <div className='allMsg'>
                                                                <PopTriangleB/>
                                                                <TaskWhite/>
                                                                <div className='allMsgContent'>
                                                                    <p className='msgTitle'>{item.editorName} {item.action} 【{item.messageName}】</p>
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    <p className='msgTime'>{item.createTime}</p>
                                                                </div>
                                                            </div> :
                                                            ""
                                    )) :
                                        <div className={'empty-wrap'}>
                                            <div className={'empty-img'}>
                                                <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            </div>
                                            <div className={'empty-text'}>暂无数据</div>
                                        </div>

                                }
                            </TabPane>
                            <TabPane tab={"已读 (" + readMsgCount + ')'} key="4">
                                {
                                    this.state.readMessage.length > 0 ? this.state.readMessage.map((item, index) => (
                                        // 建设任务
                                        item.modelType == 1 ?
                                            <div className='allMsg'>
                                                <PopTriangleB/>
                                                <ConstructionWhite/>
                                                <div className='allMsgContent'>
                                                    <p className='msgTitle'
                                                       onClick={() => this.goProjectPage(item)}>{item.editorName} 更新了建设任务【{item.messageName}】</p>
                                                    <div className={'right-r'}>
                                                        {item.portraitId ? <img className='img-portrait'
                                                                                src={window.$$uploadRc(item.portraitId)} alt=""/>
                                                            : <img className='img-portrait'
                                                                   src={require('@/assets/img/Header/mrtx.jpg')}
                                                                   alt=""/>
                                                        }
                                                        {item.isRead ? item.isRead === '1' ?
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                            :
                                                            <p className='msgMsgAndIcon'><span
                                                                className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                            </p>
                                                        }
                                                    </div>
                                                    <p className='msgTime'>{item.createTime}</p>
                                                </div>
                                            </div> :
                                            // 任务
                                            item.modelType == 2 ?
                                                <div className='allMsg'>
                                                    <PopTriangleB/>
                                                    <TaskWhite/>
                                                    <div className='allMsgContent'>
                                                        <p className='msgTitle'
                                                           onClick={() => this.goTaskPage(item)}>{item.editorName} 更新了任务【{item.messageName}】</p>
                                                        <div className={'right-r'}>
                                                            {item.portraitId ? <img className='img-portrait'
                                                                                    src={window.$$uploadRc(item.portraitId)}
                                                                                    alt=""/>
                                                                : <img className='img-portrait'
                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                       alt=""/>
                                                            }
                                                            {item.isRead ? item.isRead === '1' ?
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                                :
                                                                <p className='msgMsgAndIcon'><span
                                                                    className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                </p>
                                                            }
                                                        </div>
                                                        <p className='msgTime'>{item.createTime}</p>
                                                    </div>
                                                </div> :
                                                // 绩效
                                                item.modelType == 3 ?
                                                    <div className='allMsg'>
                                                        <PopTriangleB/>
                                                        <PerformanceWhite/>
                                                        <div className='allMsgContent'>
                                                            <p className='msgTitle'
                                                               onClick={() => this.goMeritPage(item)}>{item.editorName} 更新了绩效【{item.messageName}】</p>
                                                            <div className={'right-r'}>
                                                                {item.portraitId ? <img className='img-portrait'
                                                                                        src={window.$$uploadRc(item.portraitId)}
                                                                                        alt=""/>
                                                                    : <img className='img-portrait'
                                                                           src={require('@/assets/img/Header/mrtx.jpg')}
                                                                           alt=""/>
                                                                }
                                                                {item.isRead ? item.isRead === '1' ?
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    :
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsC'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                }
                                                            </div>


                                                            <p className='msgTime'>{item.createTime}</p>

                                                        </div>
                                                    </div> :
                                                    // 评论
                                                    item.modelType == 4 ?
                                                        <div className='allMsg'>
                                                            <PopTriangleB/>
                                                            <TaskWhite/>
                                                            <div className='allMsgContent'>
                                                                <ul className={'dtlist'}>
                                                                    <li>
                                                                        <p className={'msgTitle'}
                                                                           onClick={() => this.goTaskPage(item)}>{item.humanName} 评论了任务【{item.taskName}】
                                                                        </p>
                                                                        <div className={'right-r'}>
                                                                            {item.portraitIds ?
                                                                                <img className='img-portrait'
                                                                                     src={window.$$uploadRc(item.portraitIds)}
                                                                                     alt=""/>
                                                                                : <img className='img-portrait'
                                                                                       src={require('@/assets/img/Header/mrtx.jpg')}
                                                                                       alt=""/>
                                                                            }
                                                                            <p className={'msgMsgAndIcon'}><span
                                                                                className='msgTipsB'>
                                                                                <div className={'pinglunlist'}>
                                                                                    {item.isRead ? item.isRead === '1' ?
                                                                                        <div className={'deTails'}>
                                                                                            {item.reviewDetail}
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            {item.reviewDetail}
                                                                                        </div>
                                                                                        :
                                                                                        <div className={'deTails'}>
                                                                                            {item.reviewDetail}
                                                                                        </div>
                                                                                    }
                                                                        </div>
                                                                        <div className={'imgList'}>
                                                                            {item.relatedPic ? item.relatedPic.split(",").map((item, number) => (
                                                                                <img className='img-upload'
                                                                                     src={window.$$uploadRc(item)}
                                                                                     alt=""/>
                                                                            )) : ""}
                                                                        </div>
                                                            </span>
                                                                            </p>
                                                                        </div>
                                                                        <p className='msgTime'>{item.createTime}</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div> :
                                                        // 资金
                                                        item.modelType == 5 ?
                                                            <div className='allMsg'>
                                                                <PopTriangleB/>
                                                                <TaskWhite/>
                                                                <div className='allMsgContent'>
                                                                    <p className='msgTitle'>{item.editorName} {item.action} 【{item.messageName}】</p>
                                                                    <p className='msgMsgAndIcon'><span
                                                                        className='msgTipsB'>{item.editorName} {item.action} 【{item.messageName}】</span>
                                                                    </p>
                                                                    <p className='msgTime'>{item.createTime}</p>
                                                                </div>
                                                            </div> :
                                                            ""
                                    )) :
                                        <div className={'empty-wrap'}>
                                            <div className={'empty-img'}>
                                                <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                            </div>
                                            <div className={'empty-text'}>暂无数据</div>
                                        </div>

                                }
                            </TabPane>
                        </Tabs>

                        {/*更多操作···*/}
                        <div className='messagePopoverDiv'>
                            <Popover placement="bottomRight"
                                     content={(
                                         <div>
                                             <Button className='popoverBtnChild' onClick={this.unReadToRead}><span
                                                 className='popPoint'></span><span
                                                 className='popTitle'>标记所有为已读</span></Button>
                                             <Button className='popoverBtnChild' onClick={this.deleteAllReadMsg}><span
                                                 className='popPoint'></span><span
                                                 className='popTitle'>删除所有已读</span></Button>

                                         </div>
                                     )}
                                     visible={popView}
                                     onVisibleChange={this.handleVisibleChange}
                                     trigger="click"
                                     getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                            >
                                <Button className='popBtn'>
                                    <Icon type="ellipsis"
                                          style={{fontSize: '27px'}}
                                    />
                                </Button>

                            </Popover>
                        </div>
                    </div>
                </Drawer>


                {/*修改密码*/}
                <Modal
                    width={660}
                    title={'修改密码'}
                    className='histy_modal_con'
                    visible={editPassword}
                    destroyOnClose={true}
                    closable={false}
                    onOk={this.updatePassword}
                    onCancel={this.editHandleCancel}
                    centered={true}
                >
                    <PersonalPassword onRef={this.onRef} wrappedComponentRef={form => {
                        this.formRef = form;
                    }}/>
                </Modal>


                {/*查看权限*/}
                <Modal
                    width={660}
                    height={604}
                    title={'查看权限'}
                    className='histy_modal_con'
                    visible={jurisdiction}
                    destroyOnClose={true}//关闭时销毁 Modal 里的子元素
                    closable={false}
                    maskClosable={false}
                    footer={
                        <div>
                            <Button type='primary' onClick={() => this.jurisdictionOk()}>确定</Button>
                        </div>
                    }
                >
                    <PersonalJurisdiction wrappedComponentRef={(form) => this.formRef = form}/>

                </Modal>


                {/*退出登录弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.handleLogoutModal}
                    onOk={this.handleLogoutOk}
                    onCancel={this.handleLogoutCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}>
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>您即将退出当前用户账号，是否确定退出？</p>
                </Modal>

                {/*查看头像*/}
                {/*<Modal*/}
                {/*    width={327}*/}
                {/*    height={187}*/}
                {/*    className='showtouxiang'*/}
                {/*    visible={showTouxiang}*/}
                {/*    closable={false}*/}
                {/*    maskClosable={true}*/}
                {/*    footer={false}*/}
                {/*>*/}
                {/*    <img className='userImage1' src={(userInfo.portraitId)} alt=""/>*/}
                {/*    <ImgCrop {...imgProps}>*/}
                {/*        <Upload*/}
                {/*            accept='.jpg,.png,.jpeg,.bmp'*/}
                {/*            listType="picture-card"*/}
                {/*            showUploadList={false}*/}
                {/*            beforeUpload={(file) => this.changeLoad(file, 'studioHead')}*/}
                {/*        >*/}
                {/*            <div>*/}
                {/*                <div>*/}
                {/*                    <img src={(userInfo.portraitId)} className={'uploadImg'} alt=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Upload>*/}
                {/*    </ImgCrop>*/}
                {/*</Modal>*/}
            </div>
        )
    }
})
