/**
 * 首页相关接口
 */
import { get,post } from '../../until/axios_instans';

//首页根据用户id查询学校任务书
export function getSchoolTaskBook(data) {
    return get("/api/dh/api/v1/dh/index/getSchoolTaskBook", data);
}
//初始化学校中期评审目录
export function saveDimension(data) {
    return post("/api/dh/api/v1/dh/review/saveDimension", data);
}
//首页填报记录
export function getReportRecord(data) {
    return get("/api/dh/api/v1/dh/index/getReportRecord", data);
}
//查询文件数
export function getFileCaseCount(data) {
    return get("/api/dh/api/v1/dh/index/getFileCaseCount", data);
}

//上报任务书
export function reporting(data) {
    return get("/api/dh/api/v1/dh/index/reporting", data);
}
//批量新增-建设任务案例
export function saveBatchTaskCase(data) {
    return post("/api/dh/api/v1/dh/taskcase/saveBatchTaskCase", data);
}
// 查询任务案例列表
export function listTaskCase(data) {
    return post("/api/dh/api/v1/dh/taskcase/listTaskCase?schoolEduCode="+data.schoolEduCode + "&investYear="+ data.investYear + "&uplevelCatgory="+ data.uplevelCatgory)
}
//修改任务案例列表
export function updateBatchTaskCase(data) {
    return post("/api/dh/api/v1/dh/taskcase/updateBatchTaskCase", data);
}
//新增、修改-任务书附件
export function saveOrUpdateTaskFileList(data) {
    return post("/api/dh/api/v1/dh/taskfile/saveOrUpdateTaskFileList", data);
}
//查询顶部流程数据
export function getTaskProcessList(id) {
    return post("/api/dh/api/v1/dh/taskProcess/getTaskProcessList?taskId="+id);
}

// 下载绩效pdf
export function exportTaskIndicatorPdf(data) {
    return "/api/dh/api/vi/dh/pdf/exportTaskIndicatorPdf?taskId="+data.taskId + "&schoolId=" + data.schoolId
}


//管理者首页列表接口
export function selectTaskBookTable(data) {
    return post("/api/dh/api/v1/dh/index/selectTaskBookTable", data);
}
//管理员，省级管理者首页
export function selectManageTaskBookTable(data) {
    return post("/api/dh/api/v1/dh/index/selectManageTaskBookTable", data);
}

//中心/省级管理员终验任务书
export function finalCheckTaskBook(data) {
    return post("/api/dh/api/v1/dh/taskBook/finalCheckTaskBook", data);
}

//查询学校建设数据年度填报表
export function getFourLevelIndicatorYearValue(data) {
    return post("/api/ss/api/v1/expertRating/getFourLevelIndicatorYearValue?schoolEduCode="+data.schoolEduCode);
}
//查询学校建设数据年度填报表
export function getFourLevelIndicatorYearDetail(data) {
    return post("/api/ss/api/v1/expertRating/getFourLevelIndicatorYearDetail?schoolEduCode="+data.schoolEduCode);
}
//查询学校年度其他情况说明
export function insertFourLevelIndicatorYearValue(data) {
    return post("/api/dh/api/v1/dh/investyear/insertFourLevelIndicatorYearValue",data);
}
//插入和修改学校建设数据年度填报表(先删后插)
export function getSchoolExplainYearValue(data) {
    return post("/api/dh/api/v1/dh/schoolexplainyearmain/getSchoolExplainYearValue",data);
}
//查询学校年度其他情况说明
export function insertSchoolExplainYearValue(data) {
    return post("/api/dh/api/v1/dh/schoolexplainyearmain/insertSchoolExplainYearValue",data);
}
// 查看预算填写记录
export function getFourLevelIndicatorYearDrtailList(data) {
    return post("/api/dh/api/v1/dh/investyear/getFourLevelIndicatorYearDrtailList?schoolEduCode="+ data.schoolEduCode);
}
//批量新增-建设内容(先删后插)
export function saveBatchSchoolBuild(data) {
    return post("/api/dh/api/v1/dh/schoolbuild/saveBatchSchoolBuild",data);
}
// 查询建设内容
export function listSchoolBuild(data) {
    return post("/api/dh/api/v1/dh/schoolbuild/listSchoolBuild?schoolEduCode="+data.schoolEduCode + "&investYear="+ data.investYear+ "&uplevelCatgory="+ data.uplevelCatgory)
}

//上报建设数据采集
export function taskYearReport(data) {
    return post("/api/dh/api/v1/dh/yearReport/taskYearReport",data);
}
//省厅登录统计
export function selectSchoolYearReport(data) {
    return post("/api/dh/api/v1/dh/index/selectSchoolYearReport",data);
}
//省厅/中心审核任务书
export function auditTaskBook(data) {
    return post("/api/dh/api/v1/dh/taskBook/auditTaskBook",data);
}
//新增学校联系人
export function saveUpdateSchoolContact(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/saveUpdateSchoolContact",data);
}
//查询学校联系人
export function selectSchoolContactTable(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/selectSchoolContactTable",data);
}
//专业群建设负责人
export function saveUpdateProfessionalContact(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/saveUpdateProfessionalContact",data);
}
//查询专业群建设负责人
export function selectProfessionalContact(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/selectProfessionalContact",data);
}
//地方政府联系人
export function saveUpdateGovernmentContact(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/saveUpdateGovernmentContact",data);
}
//查询地方政府联系人
export function selectGovernmentContact(data) {
    return post("/api/dh/api/v1/dh/contactUserYear/selectGovernmentContact",data);
}
//上传盖章报告
export function sealReport(data) {
    return post("/api/dh/api/v1/dh/yearReport/sealReport",data);
}
//上传盖章报告
export function updateReview(data) {
    return post("/api/dh/api/v1/dh/yearReport/updateReview",data);
}
//获取学校的请求日志
export function getLog(data) {
    return post("/api/dh/api/v1/dh/index/getLog",data);
}
//查询学校中期评审目录-左侧
export function selectDimension(data) {
    return post("/api/dh/api/v1/dh/review/selectDimension",data);
}
//获取终极评审信息
export function getIndicators(data) {
    return post("/api/dh/api/v1/dh/review/getIndicators",data);
}
//保存三级指标
export function addIndicators(data) {
    return post("/api/dh/api/v1/dh/review/addIndicators",data);
}
//获取指标下拉
export function getDown(data) {
    return post("/api/dh/api/v1/dh/review/getDown",data);
}
//获取指标列表
export function getIndicatorsTable(data) {
    return post("/api/dh/api/v1/dh/review/getIndicatorsTable",data);
}
//中心中期评审列表
export function selectReview(data) {
    return post("/api/dh/api/v1/dh/index/selectReview",data);
}
//省厅/中心审核任务书
export function handleReview(data) {
    return post("/api/dh/api/v1/dh/index/handleReview",data);
}
//上报中期评审
export function reportInformation(data) {
    return post("/api/dh/api/v1/dh/index/reportInformation",data);
}
//删除标志性成果
export function deleteReview(data) {
    return post("/api/dh/api/v1/dh/review/deleteReview",data);
}
//获取省厅总结报告列表
export function getProvinceReport(data) {
    return post("/api/dh/api/v1/dh/provinceReport/getProvinceReport",data);
}
//获取省厅首页
export function getProvinceindex(data) {
    return post("/api/dh/api/v1/dh/provinceReport/getProvinceindex",data);
}
//保存省厅总结报告文件
export function updateProvinceReport(data) {
    return post("/api/dh/api/v1/dh/provinceReport/updateProvinceReport",data);
}
//修改是否上报
export function updateIsReporting(data) {
    return post("/api/dh/api/v1/dh/provinceReport/updateIsReporting",data);
}
// 总结报告重置
export function updateResetReport(data) {
    return post("/api/dh/api/v1/dh/provinceReport/updateResetReport",data);
}
// 保存中期文件
export function getSchoolUser(data) {
    return post("/api/dh/api/v1/dh/provinceReport/getSchoolUser",data);
}
// 查询学校联系人
export function updateProvinceMaterial(data) {
    return post("/api/dh/api/v1/dh/provinceReport/updateProvinceMaterial",data);
}
// 修改中期文件上报状态
export function updateReportingType(data) {
    return post("/api/dh/api/v1/dh/provinceReport/updateReportingType",data);
}
// 获取中期总结报告
export function getMidReport(data) {
    return post("/api/dh/api/v1/dh/provinceReport/getMidReport",data);
}
// 重置中期总结报告
export function resetMidReport(data) {
    return post("/api/dh/api/v1/dh/provinceReport/resetMidReport",data);
}
// 指标填报上传文件
export function saveThreeLevelPerformanceFile(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/saveThreeLevelPerformanceFile",data);
}

// 指标填报上传文件-新的上传需要调用的
export function saveThreeLevelPerformanceFileNew(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/saveThreeLevelPerformanceFileNew",data);
}
// 获取指标填报上传文件
export function getThreeLevelPerformanceFile(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/getThreeLevelPerformanceFile",data);
}
// 移除指标填报上传文件
export function deleteThreeLevelPerformanceFile(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/deleteThreeLevelPerformanceFile",data);
}
// 移除指标填报上传文件
export function getLevelAndName(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/getLevelAndName",data);
}
// 下载文件
export function downloadFileList(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/downloadFileList",data);
}
//初始化统计
export function initializationStatistics(data) {
    return post("/api/dh/api/v1/dh/newStatistical/initializationStatistics", data);
}
//指标填报上传文件-多文件保存
export function saveThreeLevelPerformanceFileList(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/saveThreeLevelPerformanceFileList", data);
}
//获取指标填报上传文件-多文件查询
export function getThreeLevelPerformanceFileList(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/getThreeLevelPerformanceFileList", data);
}
//移除指标填报上传文件删除多个
export function deleteThreeLevelPerformanceFileList(data) {
    return post("/api/dh/api/v1/dh/indicatorDetail/deleteThreeLevelPerformanceFileList", data);
}
//插入资金明细
export function insertSchoolInvestDetailYearDetail(data) {
    return post("/api/dh/api/v1/dh/investyear/insertSchoolInvestDetailYearDetail",data);
}
//查询资金明细
export function getSchoolInvestDetailYearDetail(data) {
    return post("/api/ss/api/v1/expertRating/getSchoolInvestDetailYearDetail?schoolEduCode="+data.schoolEduCode);
}
//获取公示网址
export function getSchoolAdditional(data) {
    return post("/api/dh/api/v1/schoolAdditional/getSchoolAdditional",data);
}
//插入公示网址
export function addSchoolAdditional(data) {
    return post("/api/dh/api/v1/schoolAdditional/addSchoolAdditional",data);
}
//查询当前学校是否有修改资金的权限
export function frontPermissions(data) {
    return post("/api/dh/api/v1/dh/user/frontPermissions",data);
}
//获取学校文件
export function schoolSelfReport(data) {
    return post("/api/ss/api/v1/expertRating/schoolSelfReport",data);
}
//查询专家评审列表
export function queryExpertReviewList(data) {
    return post("/api/ss/api/v1/expertRating/queryExpertReviewList",data);
}
//查询专家评审列表-admin登陆使用
export function queryExpertReviewListAdminNew(data) {
    return post("/api/ss/api/v1/expertRating/queryExpertReviewListAdmin",data);
}
//用户实名
export function updateUserRealname(data) {
    return post("/api/ss/api/v1/expertRating/updateUserRealname",data);
}
//查询专家评审列表
export function selectSchoolList(data) {
    return post("/api/dh/api/v1/schoolAdditional/selectSchoolList",data);
}
//查询打分右侧列表
export function getExpertRatingDirectory(data) {
    return post("/api/ss/api/v1/expertRating/getExpertRatingDirectory",data);
}
//查询打分右侧列表--查看打分使用
export function getSeeExpertRatingDirectory(data) {
    return post("/api/ss/api/v1/expertRating/getSeeExpertRatingDirectory",data);
}
//获取学校其他说明
export function getSchoolOtherExplain(data) {
    return post("/api/ss/api/v1/expertRating/getSchoolOtherExplain",data);
}
//获取三级指标支撑情况
export function getSeniorBrace(data) {
    return post("/api/ss/api/v1/expertRating/getSeniorBrace",data);
}
//获取四级指标支撑情况
export function getIndicatorBrace(data) {
    return post("/api/ss/api/v1/expertRating/getIndicatorBrace",data);
}
//保存学校三级指标分数
export function saveThreeLevelIndicator(data) {
    return post("/api/ss/api/v1/expertRating/saveThreeLevelIndicator",data);
}
//查询任务书和建设方案
export function selectTaskFileList(data) {
    return post("/api/ss/api/v1/expertRating/selectTaskFileList",data);
}
//查询学校的数据采集表
export function getYearReport(data) {
    return post("/api/ss/api/v1/expertRating/getYearReport",data);
}
//查询小组里面的专家
export function getGroupUser(data) {
    return post("/api/ss/api/v1/expertRating/getGroupUser",data);
}
//查询小组里面的学校--学校互评使用
export function getGroupUserSchool(data) {
    return post("/api/ss/api/v1/expertRating/getGroupUserSchool",data);
}
//提交打分修改打分状态
export function submitScore(data) {
    return post("/api/ss/api/v1/expertRating/submitScore",data);
}
//查询中期详情
export function getEffectDetail(data) {
    return post("/api/ss/api/v1/expertRating/getEffectDetail",data);
}
//查询当前小组里面的专家是否都打分
export function getIsScore(data) {
    return post("/api/ss/api/v1/expertRating/getIsScore",data);
}
//查询小组合议列表
export function getGroupDeliberationTable(data) {
    return post("/api/ss/api/v1/expertRating/getGroupDeliberationTable",data);
}
export function getSchoolInfoByEduCode(data) {
    return post('/api/ss/api/v1/expertRating/getSchoolInfoByEduCode?schoolEduCode='+data.schoolEduCode+'&userId='+data.userId);
}
//新增专家评审意见和等级
export function saveOpinionAndGrade(data) {
    return post("/api/dh/api/v1/expertGroup/saveOpinionAndGrade",data);
}
//查询专家评审意见和等级
export function queryOpinionAndGrade(data) {
    return post("/api/dh/api/v1/expertGroup/queryOpinionAndGrade",data);
}
//查看专家合议意见详情
export function queryExpertsDiscusInfo(data) {
    return post("/api/dh/api/v1/expertGroup/queryExpertsDiscusInfo",data);
}
//导出合议文件
export function exportExpertsDiscussPdf(data) {
    return post("/api/dh/api/v1/expertGroup/exportExpertsDiscussPdf",data);
}
//签署保密协议
export function userSign(data) {
    return post("/api/dh/api/v1/expertGroup/userSign",data);
}
//保存学校分数
export function saveTwoLevelIndicator(data) {
    return post("/api/ss/api/v1/expertRating/saveTwoLevelIndicator",data);
}
//保存学校分数
export function userIsSign(data) {
    return post("/api/dh/api/v1/expertGroup/userIsSign",data);
}
//保存评审意见
export function saveReviewOpinions(data) {
    return post("/api/ss/api/v1/expertRating/saveReviewOpinions",data);
}
//提交评审意见
export function submitReviewOpinions(data) {
    return post("/api/ss/api/v1/expertRating/submitReviewOpinions",data);
}
//获取个人评审意见
export function getPersonalReviewOpinions(data) {
    return post("/api/ss/api/v1/expertRating/getPersonalReviewOpinions",data);
}
//获取所有用户评审意见
export function getUserReviewOpinions(data) {
    return post("/api/ss/api/v1/expertRating/getUserReviewOpinions",data);
}
//获取标志性成果情况
export function getExpertResultsSummary(data) {
    return post("/api/ss/api/v1/expertRating/getExpertResultsSummary",data);
}
//获取学校的评审意见
export function getSchoolExpertOpinion(data) {
    return post("/api/ss/api/v1/expertRating/getSchoolExpertOpinion",data);
}
//修改专家评价分
export function insertUpdateSubmitCurrent(data) {
    return post("/api/ss/api/v1/expertRating/insertUpdateSubmitCurrent",data);
}
//查询专家评审列表-学校互评使用
export function queryExpertReviewListPing(data) {
    return post("/api/ss/api/v1/expertRating/queryExpertReviewListPing",data);
}
//获取学校的评估概览
export function getSchoolAssessmentOverview(data) {
    return post("/api/ss/api/v1/expertRating/getSchoolAssessmentOverview",data);
}
//查询学校的小组Id
export function getSchoolGroupId(data) {
    return post("/api/ss/api/v1/expertRating/getSchoolGroupId",data);
}
//资金总体情况
export function getOverallFundSituation(data) {
    return post("/api/ss/api/v1/dh/statistical/getOverallFundSituation",data);
}
//资金总体情况
export function selectSchoolMoneyOverview(data) {
    return post("/api/ss/api/v1/dh/statistical/selectSchoolMoneyOverview",data);
}
//查询学校指标数
export function getIndicatorsNumber(data) {
    return post("/api/ss/api/v1/dh/statistical/getIndicatorsNumber",data);
}