/*栏目管理*/
import React, {Component} from "react";
import {
    Form, Input, Button, Select, Table, Modal, Cascader, Icon, Tooltip
} from "antd";
import {selectUserPage,insertUser, updateUser,resetPassword,updateUserStatus,getUserInfoById} from '../../api/user/';
import {orgTreeNodeList} from '../../api/organizationManage/';
import Cookies from 'js-cookie';
import "./index.scss";
import RenderButton from "../../components/renderButton/index.js";
import ModalUser from './components/modalUser'
import S from "gongyongicon";
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
    colon:false,

};
const { confirm } = Modal;
const { TextArea } = Input;
class UserManagement extends Component {
    state = {
        resetPsd:false,//重置密码modal
        resetId:0,//要重置密码的用户id
        resetName:'',//要重置密码的用户名
        resetPhone:'',//要重置的用户的手机号

        stopUseModal:false,//要停用的modal
        stopUseId:0,//要停用的用户id
        stopUseIsDisable:0,


        optionList:[
            {name:"正常",value:0},
            {name:"停用",value: 1},
        ],
        columns: [
            {
                title: '用户名称',
                dataIndex: 'humanName',
                width:140,
                align:"center",
                key: 'humanName',
                render: (text, record) =>
                    <p className='oper'>
                         <div>
                             <RenderButton id='SS-01-B02-b_btnViewUser'>
                             <span onClick={() =>this.gotoViewUser(record) }>{text}</span></RenderButton>
                         </div>
                     </p>
            },
            {
                title: '手机号',
                align:"center",
                width:150,
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '所属组织',
                align:"center",
                width:200,
                dataIndex: 'orgName',
                key: 'orgName',
            },
            {
                title: '职  务',
                align:"center",
                width:160,
                dataIndex: 'position',
                key: 'position',
            },
            {
                title: '角  色',
                align:"center",
                width:300,
                dataIndex: 'roleList',
                key: 'roleList',
            },
            {
                title: '状  态',
                dataIndex: 'isDisable',
                width:80,
                align:"center",
                key: 'isDisable',
                render: (key) => <div>
                    {key === 1 ?
                        <div className="state-style-stop">
                            <div className="spot"></div>
                            <span>停用</span>
                        </div>
                        : key === 0 ?
                            <div className="state-style-start">
                                <div className="spot"></div>
                                <span>正常</span>
                            </div>
                            : '未知'}
                </div>
            },
            {
                title: '备  注',
                align:"center",
                width:200,
                dataIndex: 'remark',
                key: 'remark',
                render:(text,record)=><span>
                     {text?text.length>10?<Tooltip  placement="bottom" title={text}
                               overlayClassName='TitleTooltip'
                     >
                    <span>{text}</span>
                    </Tooltip>:<span>{text}</span>:''}
                </span>

            },
            {
                title: '操 作',
                align:"center",
                width:200,
                fixed:"right",
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className='oper'>
                        <RenderButton id='SS-01-B02-b_btnEditUser'>
                        <span onClick={() => this.editClick(record)}>编辑</span>
                       <div className="line"></div></RenderButton>
                        <RenderButton id='SS-01-B02-b_btnResetPassword'>
                        <span className="reset-password" onClick={() => this.resetPassword(record)}>重置密码</span>
                        <div className="line"></div></RenderButton>
                        <RenderButton id='SS-01-B02-b_btnStopUsing-UserRecovery'>
                            {
                                record.isAdmin==0 ?
                                <span className="reset-password" onClick={() => this.stopUse(record)}>{record.isDisable==0?"停用":"恢复"}</span>
                                    :<span className="lines">停用</span>
                            }
                        </RenderButton>
                    </div>
                }
            }],
        userList:[],
        pageNum: 1,//当前页数
        pageSize: 20,//每页数据数量
        total: 0,//总条数
        showModel:false,
        modalTitle:"",
        search:{
            orgId: "",//跳转过来，赋默认值
            humanName:"",
            isDisable:""
        },
        userObj: {
            userId: "",
            humanName: "",
            phone: "",
            orgId: "",
            position: "",
            remark: ""
        },
        orgTreeNodeList:[],
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        orgIdDefaultValueArr:[],
        editUserId:"",//编辑用户id
        lookUserId:"",
        genOrgId:this.props.location.query != null ? this.props.location.query.genOrgId:"" //组织结构跳转过来带的id

    };

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.selectUserTable());
    };

    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {

        this.setState({ pageSize:pageSize,pageNum:1 }, () => {
            this.selectUserTable()
        });

    };

    getUserInfoById = async () =>{
        await getUserInfoById({id:this.state.editUserId}).then(res =>{
            this.setState({
                userObj:{
                    userId: res.id,
                    humanName: res.humanName,
                    phone: res.phone,
                    orgId: res.orgId,
                    position: res.position,
                    remark: res.remark,
                }
            })
        })
    }
    componentDidMount() {
        this.selectUserTable();
        this.orgTreeNodeList();
    }

    async selectUserTable() {
        let {pageNum,pageSize,search} = this.state;
        let params = {
            pageNum:pageNum,
            pageSize:pageSize,
            orgId: search.orgId,
            humanName: search.humanName,
            isDisable:search.isDisable,
            genOrgId:this.state.genOrgId
        };
        const page = await selectUserPage(params);
        if (page) {
            this.setState({
                userList:page.data,
                total: page.total
            });
        }
    }
    // 查询
    onSubmit = ()=>{
        this.setState({
            pageNum: 1,
            pageSize: 10,
            humanName:this.state.search.humanName,
            isDisable:this.state.search.isDisable,
            genOrgId:""

        },()=>this.selectUserTable())

    };
    orgTreeNodeListChange = value => {
        let orgIdValue =value.length > 0 ? value[value.length - 1] : ""
        this.setState({
            search:{
                humanName: this.state.search.humanName,
                isDisable:this.state.search.isDisable,
                orgId: orgIdValue
            }
        })
    }
    //状态
    onDisableChange = value => {
        this.setState({
            search:{
                orgId: this.state.search.orgId,
                humanName: this.state.search.humanName,
                isDisable:value
            }
        })
    }
    //学员查询
    inputHumanNameChange = e => {
        this.setState({
            search:{
                orgId: this.state.search.orgId,
                isDisable: this.state.search.isDisable,
                humanName:e.target.value
            }
        })
    }
    //分页
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
        })
    }
    //编辑
    editClick = (record)=>{
        this.setState({
            showModel:true,
            modalTitle:"编辑用户",
            editUserId:record.id,
        })
        this.state.editUserId = record.id
    }

    orgTreeNodeList = async () => {
        let data = {
            schoolId: this.state.schoolId,
        }
        await orgTreeNodeList(data).then(res => {
            this.setState({orgTreeNodeList: res})
        })
    }

    //重置密码
    resetPassword = (record) =>{
        this.setState({
            resetPsd:true,
            resetId:record.id,
            resetName:record.humanName,
            resetPhone:record.phone
        })
    }

    resetPsdCancel= () =>{
        this.setState({
            resetPsd:false,
        })
    }

    resetPsdOk= async () => {
        resetPassword({
            phone: this.state.resetPhone,
            id: this.state.resetId
        }).then((res) => {
            if (res) {
                this.setState({
                    resetPsd:false,
                })

                this.mesSuccess("重置成功")
            }
        });
    }


    //停用
    stopUse = (record) =>{
        this.setState({
            stopUseModal:true,
            stopUseId:record.id,
            stopUseIsDisable:record.isDisable,

        })
    }

    stopUseCancel= () =>{
        this.setState({
            stopUseModal:false,
        })
    }

    stopUseOk= async () => {
        updateUserStatus({
            isDisable: this.state.stopUseIsDisable,
            id: this.state.stopUseId
        }).then((res) => {
            if (res) {
                this.setState({
                    stopUseModal:false,
                })

                this.mesSuccess("用户状态更新成功")
                this.selectUserTable()
            }
        });
    };


    //modal提交数据Button
    saveOrUpdateUser = () =>{
        let formValue=this.formRef.getFormValue()
        let schoolId = this.state.schoolId;
        if(formValue){
            let orgId = ""
            if(formValue.orgId){
                orgId = formValue.orgId.length > 0 ? formValue.orgId[formValue.orgId.length - 1] : "";
            }
            let userObj = {
                humanName:formValue.humanName,
                phone:formValue.phone,
                position:formValue.position,
                orgId:orgId,
                remark:formValue.remark,
                schoolId
            };

            //编辑
            if(this.state.editUserId != ""){
                userObj["id"] = this.state.editUserId
                updateUser(userObj).then(res => {
                    this.mesSuccess('操作成功');
                    this.setState({
                        showModel:false,
                        modalTitle:"",
                        editUserId:"",
                        userObj: {
                            userId: "",
                            humanName: "",
                            phone: "",
                            orgId: "",
                            position: "",
                            remark: ""
                        }
                    });
                }).then((res)=>{
                    this.selectUserTable();
                    this.initdata()
                })
            }
            //添加
            else{
                insertUser(userObj).then(res => {
                    this.mesSuccess('操作成功');
                    this.setState({
                        showModel:false,
                        modalTitle:"",
                        userObj: {
                            userId: "",
                            humanName: "",
                            phone: "",
                            orgId: "",
                            position: "",
                            remark: ""
                        }
                    });
                }).then((res)=>{
                    this.selectUserTable();
                    this.initdata();
                })
            }
        }
    };

    //重置
    formReset=()=>{
        this.props.form.resetFields()
    }
    lookCancle = ()=>{
        this.initdata()
    }
    // Cascader显示最后一级
    displayRender=(label) => {
        return label[label.length - 1];
    }
    //重置
    searchReset(){
        this.setState({
            search:{
                orgId: "",
                humanName: "",
                isDisable: ""
            },
        }, () => this.selectUserTable())
    }

    buildUser = () => {
        this.setState({
            showModel: true,
            modalTitle: "新建用户",
            phone: "",
            position: "",
            orgName: null,
            remark: null,
            editUserId:""
        });
    }

    //查看用户详情
    gotoViewUser=(record)=>{
        this.setState({
            showModel:true,
            modalTitle:"查看用户",
            lookUserId:record.id,
        })
        this.state.lookUserId = record.id
    }
    initdata = ()=>{
        this.setState({editUserId:"",lookUserId:"",showModel:false})
    }
    render() {
        let {optionList,columns, userList, total, pageNum, pageSize,showModel,modalTitle,search, orgTreeNodeList,orgIdDefaultValueArr,userObj}=this.state
        let pagination = {
            total: total,
            pageNum: pageNum,
            current: pageNum,//重置页码
            pageSize: pageSize,
            onChange: this.changePage
        }
        return (
            <div className='user-management'>
                <div className="top-search">
                    <Form
                        {...layout}
                        name="basic"
                    >
                        <div className="search-box">
                            <div className="search-box-left">

                                <div className="in-box">
                                    <Form.Item label={`用户名称`}>
                                            <Input autocomplete="off" style={{width: "230px"}}  placeholder="请输入用户名称" value={search.humanName} onChange={this.inputHumanNameChange} allowClear={true}/>
                                    </Form.Item>
                                </div>
                                <div className="in-box">
                                    <Form.Item label={`状态`}>
                                            <Select
                                                placeholder="状态"
                                                allowClear
                                                style={{width: "110px"}}
                                                onChange={this.onDisableChange}
                                            >
                                                {optionList&&optionList.map((item, index)=>(
                                                    <Select.Option value={item.value} key={index}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                    </Form.Item>
                                </div>
                                <div className="in-box">
                                <Form.Item className='ibName' label="所属组织">
                                    <Cascader
                                        style={{width: "230px"}}
                                        fieldNames={{label: 'orgName', value: 'id', children: 'children'}}
                                        options={orgTreeNodeList}
                                        displayRender ={this.displayRender}
                                        onChange={this.orgTreeNodeListChange}
                                        placeholder="请选择所属组织"
                                        changeOnSelect />
                                </Form.Item>
                            </div>
                            </div>
                            <div className="button-style">
                                <div className="in-box">
                                    <Form.Item >
                                        <Button onClick={() => this.onSubmit()} className="submit-button" htmlType="submit">
                                            查询
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>

                        </div>
                    </Form>
                </div>

                <div className="user-table">
                    <div className="table-title">
                        <div className="table-title-left">
                            <p></p>
                            <span className="title">用户管理</span>
                        </div>

                        <RenderButton id='SS-01-B02-b_btnAddUser'>
                        <Button onClick={()=>this.buildUser()
                        } className="table-button">新建用户</Button></RenderButton>

                    </div>
                    <div  className="tabl">
                        <Table cellspacing={20} columns={columns}
                               className='tableUserStyle'
                           dataSource={userList}
                           pagination={pagination}
                               scroll={{ x: 1500, y: 600 }}
                           rowKey={(r, i) => (r.id)}
                           locale={{emptyText:<><div className="Empty-conet-ss">
                                   <div className="img">
                                       <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                       <span>暂无数据</span>
                                   </div>
                               </div></>}}
                           pagination={
                               {
                                   current:pageNum,
                                   total: total,
                                   pageSize: pageSize,
                                   onChange: this.pageChange,
                                   onShowSizeChange: this.onShowSizeChange,
                                   pageSizeOptions: ['20', '40', '60', '80'],
                                   showSizeChanger: true,
                               }
                           }
                        />
                    </div>
                </div>


                <Modal
                    title={modalTitle}
                    visible={showModel}
                    width={660}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    footer={
                    <div className='modal_btn_j'>
                        {this.state.lookUserId && this.state.lookUserId.length>0 ? "" : <Button onClick={() => this.setState({showModel: false})}>取消</Button> }
                        {this.state.lookUserId && this.state.lookUserId.length>0 ? <Button type='primary' onClick={() => this.lookCancle()}>确定</Button> :<Button type='primary' onClick={() => this.saveOrUpdateUser()}>确定</Button>}
                    </div>
                }
                >
                    <div>
                        <ModalUser
                            orgTreeNodeList={orgTreeNodeList}
                            editUserId={this.state.editUserId}
                            lookUserId={this.state.lookUserId}
                            initdata={this.initdata}
                            wrappedComponentRef={(form) => this.formRef = form}/>
                    </div>
                </Modal>


                {/*重置密码弹窗*/}
                <Modal
                    title="重置密码确认"
                    visible={this.state.resetPsd}
                    onOk={this.resetPsdOk}
                    onCancel={this.resetPsdCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>您确认要将{this.state.resetName}的登录密码重置为CVEC+电话号后四位吗？</p>
                </Modal>


                {/*停用恢复弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.stopUseModal}
                    onOk={this.stopUseOk}
                    onCancel={this.stopUseCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>{this.state.stopUseIsDisable==0?"停用后该用户将无法登录，是否继续？":"启用后该用户将恢复使用，是否继续？"}</p>
                </Modal>


            </div>
        );
    }
}

UserManagement = Form.create()(UserManagement);
export default UserManagement;
