/*项目管理*/
import React, {Component} from "react";
import {
    Form, Button
} from "antd";
import VideoPlayer from '../../components/video/index';
import {withRouter} from "react-router";
import "./index.scss";
import PdfPreview from '../../components/pdf_preview'
import FileViewer from 'react-file-viewer';
let params = {
    zoomVal: 1,
};
@withRouter
class Readdetails extends Component {
    state = {
        activeKey: '1',
        ModalVisible: false,
        columns: [
        ],
        dataSource: [

        ],
        optionList: [{}],
        userOptions:[],
        childrenDrawer:true,
        handis:true,
        previewFileRes: this.props.previewFileRes,
        resourceId:this.props.resourceId,
        previewImgclassName: '',
        full_flag: true,
        ishover: false
    }
    componentDidMount() {
        this.handonclick();
        this.props.onRef(this)
            setTimeout(() => {
                this.setState({
                    previewFileRes:  this.props.previewFileRes,
                    resourceId:this.props.resourceId,
                })
                if(document.getElementById('previewImg')&&document.getElementById('previewImg').offsetWidth >= 1158){
                    this.setState({previewImgclassName: 'previewImg'})
                }else{
                    this.setState({previewImgclassName: ''})
                }
            }, 500)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({previewFileRes: nextProps.previewFileRes,resourceId:nextProps.resourceId})
        setTimeout(()=>{
            if(document.getElementById('previewImg') && nextProps.previewFileRes.length === undefined && document.getElementById('previewImg').offsetWidth >= 1158){
                this.setState({previewImgclassName: 'previewImg'})
            }else{
                    this.setState({previewImgclassName: ''})
            }
        },500)
    }
    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,
        });
    };

    handonclick = ()=>{
        this.setState({
            handis: !this.state.handis
        })
    }
    //图片缩小
    suoxiao= () =>{
        params.zoomVal -= 0.1
        if (params.zoomVal >= 0.2) {
            this.imgDom.style.transform = "scale(" + params.zoomVal + ")";
        } else {
            params.zoomVal = 0.2;
            this.imgDom.style.transform = "scale(" + params.zoomVal + ")";
            return false;
        }
    }
    //图片放大
    fangda = () =>{
        params.zoomVal += 0.1
        if (params.zoomVal >= 0.2) {
            this.imgDom.style.transform = "scale(" + params.zoomVal + ")";
        } else {
            params.zoomVal = 0.2;
            this.imgDom.style.transform = "scale(" + params.zoomVal + ")";
            return false;
        }
    }
    setFullType = (flag) => {
        this.setState({
            full_flag: flag,
            ishover: flag
        }, () => {
            this.child.set_full_flag_child(!flag)
        })
    }
    onRef = (ref) => {
        this.child = ref
    }
    render() {
        const {handis,previewFileRes,resourceId,previewImgclassName} = this.state
        const suffix = previewFileRes.transSuffix // 文件后缀
        const suffixtime = previewFileRes.resourceId.slice(previewFileRes.resourceId.lastIndexOf('.') + 1) // 文件后缀
        console.log("lf11",suffix)
        return (
            <div className='Readdetails'>
                <div className='details'>
                    <div className='left-title' style={handis?{width:'61%'}:{width:'100%'}}>
                        {previewFileRes.fileType === 1 || previewFileRes.fileType === 2 || previewFileRes.fileType === 8?
                            <div>
                                {suffix !== 'pdf' ?
                                    <div>
                                        <FileViewer fileType={suffix}
                                                    filePath={window.$$uploadRc(resourceId)}/>
                                    </div>:
                                    <div>
                                        <PdfPreview
                                            src={window.$$uploadRc(resourceId)}
                                            setFullType={this.setFullType}
                                            onRef={this.onRef}
                                        />
                                    </div>
                                }
                            </div> :
                            previewFileRes.fileType === 3 ?
                                <div>
                                    <FileViewer fileType={suffixtime}
                                                filePath={window.$$uploadRc(resourceId)}/>
                                </div>:
                            // 任务书/建设任务书查看/下载绩效
                            previewFileRes.fileType === 9 ?
                                <iframe style={{width:"100%",marginTop:"20px",height:"720px"}}
                                        src={window.$$Dh(resourceId)}></iframe> :
                                // 年度绩效查看/下载
                                previewFileRes.fileType === 10 ?
                                    <iframe style={{width:"100%",marginTop:"20px",height:"720px"}}
                                            src={window.$$DhYear(previewFileRes.taskId+"&schoolId="+previewFileRes.schoolId)}></iframe> :
                             previewFileRes.fileType === 7 ?  //pdf
                                 <div>
                                     <PdfPreview
                                         src={window.$$uploadRc(resourceId)}
                                         setFullType={this.setFullType}
                                         onRef={this.onRef}
                                     />
                                 </div>:
                                previewFileRes.fileType === 4 ?
                                <div style={{height: "100%"}}>
                                    <VideoPlayer checkDpi={true} permitExpress={true} videoId={'videoId1'} height={'100%'}
                                                 src={window.$$uploadRc(resourceId)} ref={(e) => {
                                        this.childPlay = e
                                    }}/>
                                </div> :
                                previewFileRes.fileType === 5 ?
                                    <div className="c_a_dio">
                                        <audio  id="media" autoPlay="autoPlay"  src={window.$$uploadRc(resourceId)} controls="controls">
                                        </audio>
                                    </div>:
                                    previewFileRes.fileType === 6 ?
                                        <div className="imgModal">
                                            <div className={'suofang'}>
                                                <div className={'sfright'}>
                                                    <div onClick={()=>this.fangda()} className={'fangda'}></div>
                                                    <div className={'split'}></div>
                                                    <div onClick={()=>this.suoxiao()} className={'suoxiao'}></div>
                                                </div>
                                            </div>
                                            <div className={'img'}>
                                                <img src={window.$$uploadRc(resourceId)} id={'previewImg'} className={previewImgclassName} alt="" ref={dom => {
                                                    this.imgDom = dom;
                                                }}/>
                                            </div>
                                        </div>
                                        :
                                        <div className="iframe_preview" >
                                            下载
                                            <iframe width="100%" height="75%" src={window.$$uploadRc(resourceId)} frameBorder="0"></iframe>
                                        </div>

                        }

                    </div>
                    <div className={handis?'right_open':'right_open right_openlist'}>
                        {previewFileRes.fileType === 9 ||  previewFileRes.fileType === 10? "":
                            <Button onClick={this.handonclick}>
                                {handis === true?<img src={require('../../assets/imgnew/open.svg')} alt={'头像'}/>:
                                    <img src={require('../../assets/imgnew/close.svg')} alt={'头像'}/>}
                            </Button>}
                        {
                            handis&&(
                                <div className='right-barrage'>
                                    <div className='information'>
                                        <div className='information-title'>
                                            <p style={{marginTop:'0'}}></p>
                                            <span style={{marginTop:'0'}}>文档信息</span>
                                        </div>
                                    </div>
                                    <img className='img-portrait'  src={window.$$uploadRc(previewFileRes.createrPortraitId)} alt=""/>
                                    <span className='panda'>{previewFileRes.createrName}</span>
                                    <div className='time-t'>
                                        <span className='time'>上传时间：{previewFileRes.createTime ? previewFileRes.createTime.replace('T',' ') : ''}</span>
                                    </div>
                                    <div className='information'>
                                        <div className='information-title'>
                                            <p></p>
                                            <span>关联信息</span>
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <h4>该文件被关联 {previewFileRes.fileRelatedTimes} 次</h4>
                                    </div>
                                    <div className='right-details'>
                                        <div className='right-title'>
                                            <div className='headd'>
                                                <h3>被以下任务关联</h3>
                                            </div>
                                            <div className={'right-wrap'}>
                                                {previewFileRes.relatedTasks ? previewFileRes.relatedTasks.map((item)=>{
                                                   return <div className='right-next'>
                                                        <span className='span-1'>{item.taskName}</span>
                                                        <div className={'right-r'} >
                                                            <span className='span-2'>{item.taskStatus}</span>
                                                            {item.taskPortraitId?<img className='img-portrait' src={window.$$uploadRc(item.taskPortraitId)} alt=""/>
                                                            :<img className='img-portrait' src={require('@/assets/img/Header/mrtx.jpg')} alt=""/>
                                                            }
                                                        </div>
                                                    </div>
                                                }) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        );
    }
}

Readdetails = Form.create()(Readdetails);
export default Readdetails;
