import React, { Component } from 'react';
import { HashRouter, withRouter } from 'react-router-dom'
import RouterView from './router/router_view'
import routers from './router/router_config'
import './reset_style/public.scss';
import resetAntd from './config/antd_global';
import { connect } from 'react-redux';
import { get_button, get_menu } from '@/store/OBS/main/action.js';
window.elGoTop = function (n) {
  document.querySelector(n) && document.querySelector(n).scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

// c.set('info',"{\"userId\":\"02c1a0720cd14b77af98ca5b78aa4c63\",\"mainOrgId\":\"7a895174955749839d962e1344facc38\",\"mainOrgName\":\"培训机构\",\"fullName\":\"培訓教师6\",\"portraitId\":null,\"telNum\":\"158****3338\",\"token\":\"eyJhbGciOiJIUzUxMiJ9.eyJMT0dJTl9UWVBFIjoiUEMiLCJpZGVudGl0eU5hbWUiOiLogIHluIgiLCJ1c2VyX2lkIjoiMDJjMWEwNzIwY2QxNGI3N2FmOThjYTViNzhhYTRjNjMiLCJpZGVudGl0eUlkIjoiNzIiLCJvcmduaXphdGlvbnMiOiI3YTg5NTE3NDk1NTc0OTgzOWQ5NjJlMTM0NGZhY2MzOCIsIlVTRVJfTkFNRSI6IjE1ODMzMzMzMzM4IiwibG9naW5fdXNlcl9rZXkiOiIzZDM0M2I0NC0zMmQ0LTQ0OGYtOGJkMS03ZmRiYWRlOWQ4YmMifQ.m9ext7-8-lhIvDoCW3AAiz73hzbpgR05m2lWmOD61ooFK6Nks-YJYRxov0RL9TP2IrpohUZ-t45iOEpPgWTGAQ\",\"userName\":\"15833333338\",\"userSystemVoList\":[]}")
class App extends Component {
  componentDidMount() {
  }
  render() {
    return ( 
      <HashRouter>
        <RouterView routers={routers}></RouterView>
      </HashRouter>
    )
  }
}
const mapStateToProps = ({ MAIN }) => {
  const { list } = MAIN;
  return {
    list
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
}
App = connect(mapStateToProps, mapDispatchToProps)(App);
App = resetAntd(App)

export default App
