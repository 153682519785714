// 任务统计——详情
import React, {Component} from "react";
import {Button, Dropdown, Form, Menu, Modal, Progress, Table,} from "antd";
import {withRouter} from "react-router";
import {Readsign,Redcheck} from '../../../../../assets/svg'

import style from './index.module.scss'
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import { queryConfitionValues, queryTaskChart,queryConfitionByCTAndDT,editChartConfition,queryDefaultIconId,exportExcel,editChartConfitionValues,clearChartFilterValues} from "../../../../../api/chart";
import ScreeningCondition from "../../newReportFile/screeningCondition";
import Cookies from "js-cookie";

@withRouter
class Editortaskstatistical extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        total:1,
        columns: [
            {
                title: '任务',
                dataIndex: 'taskName',
                width: 200,
                align: "center",
                key: 'taskName',


            },
            {
                title: '负责人',
                align: "center",
                width: 100,
                dataIndex: 'humanName',
                key: 'humanName',
                render: (text, record) =>{
                    return (<div>{ <span>{text?text:'--'} </span>}</div>)
                }

            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'taskStatus',
                key: 'taskStatus',
                render: (text, record) => {
                    return (<div>{this.state.taskStatusOptions.map((item, index) => {
                        if(text === item.value){
                            return item.value==3 ? <span key={index} className='yiwancheng'>{item.label}</span>:
                                item.value==4 ? <span key={index} className='yiyuqi'>{item.label}</span>:
                                    <span key={index}>{item.label}</span>
                        }})}</div>)}

            },
            {
                title: '截止日期',
                align: "center",
                width: 200,
                dataIndex: 'endTime',
                key: 'endTime',
                render: (text, record) =>{
                    return (<div>{ <span>{text?text:'--'} </span>}</div>)
                }


            },


        ],
        queryTaskChartList: [],
        taskStatisticList: [],
        taskStatusOptions:[
            {'label':'进行中','value':1},
            {'label':'已暂停','value':2},
            {'label':'已完成','value':3},
            {'label':'已逾期','value':4},
            {'label':'未开始','value':5},
        ],
        chartId:0,
        chartType:6,
        allConditionList:[],
        addConditionList:[],
        noAddConditionList:[],
        allConfitionValues:[],
        defaultType:1,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        //筛选
        useChildFlag:false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey:0,//screening子组件控制加载

    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum.current,
            pageSize: pageNum.pageSize
        }, () => {
            //列表方法
            this.queryTaskChart()
        })
    }

    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }
    //任务图
    queryTaskChart = async () => {
        let dataType = {
            schoolId: this.state.schoolId,
            chartType: this.state.chartType
        };
        await queryDefaultIconId(dataType).then(e => {
            this.setState({
                chartId:e.id
            })
        }).then( s=> {
            let datas = this.editCondition.getConditionValueChange()
            let data ={
                code:1,
                screenType:datas,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                defaultType:this.state.defaultType,
                chartId:this.state.chartId,
                chartType:this.state.chartType,
                schoolId:this.state.schoolId
            }
            if (this.state.defaultType==1){
                queryTaskChart(data).then(e => {
                    this.setState({
                        queryTaskChartList:e,
                        taskStatisticList:e.taskStatisticList.data,
                        total:e.taskStatisticList.total,
                        useChildFlag:true,//首次进入，子组件不调用接口，赋值后再调用
                        screeningKey:this.state.screeningKey+1,//screening子组件控制加载
                    })
                })
            }else {
                queryTaskChart(data).then(e => {
                    this.setState({
                        queryTaskChartList:e,
                        taskStatisticList:e.taskStatisticList.data,
                        total:e.taskStatisticList.total,
                    })
                })
            }
        })


    }
    componentDidMount() {
        this.queryTaskChart()

    }

    //预览图表
    preview = ()=>{
        this.state.defaultType=2
        this.queryTaskChart()

    }
    //导出任务图表
    exportExcel= ()=>{
        // window.location.href = "http://localhost:30001/api/v1/chart/exportExcel"
        window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?chartId="+this.state.chartId+"&schoolId="+this.state.schoolId+"&chartType="+this.state.chartType+""
        // exportExcel();
    }

    //————————筛选操作——————————
    //编辑图表筛选条件api
    editChartConfition  =  async (data) => {
        await editChartConfition(data)
    }
    //编辑图表筛选条件的值api
    editChartConfitionValues =  async (data) => {
        await editChartConfitionValues(data).then(res=>{})
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        await clearChartFilterValues({chartId:this.state.chartId})
    }
    //刷新数据
    refreshPageData = ()=>{
        this.queryTaskChart()
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res=>{
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension:'1'},()=>{
                this.refreshPageData()
            })

        })
        this.state.defaultType=1
        this.queryTaskChart()
    }
    //按钮保存(编辑筛选条件值、保存图表)
    saveOk = ()=>{
        let conditionValues = this.editCondition.getConditionValueChange()
        let dataPost = {
            chartId:this.state.chartId,
            conditionValuesQo:conditionValues
        }
        this.editChartConfitionValues(dataPost).then(res=>{
            this.mesSuccess("操作成功")
            this.refreshPageData()
        })
    }
    //编辑筛选条件
    editConditionOk = ()=>{
        if(!this.state.chartId){
            this.mesWarning('请先保存图表')
            return
        }
        let conditions = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < conditions.length; i++) {
            data.push({conditionId:conditions[i]})
        }
        this.editChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                this.mesSuccess("操作成功")
                this.refreshPageData()
            })
        })

    }



    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns,taskStatisticList,pageSize, total, pageNum,isreportEditor,queryTaskChartList } = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.editortaskstatistical}>
                <div className={'taskStatistical_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>任务统计</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        <div className={'button_topR'} onClick={()=>this.exportExcel()}>
                            <span>导出</span>
                        </div>
                        <div className={'button_topRB'} onClick={()=>this.saveOk()}>
                            <span>保存</span>
                        </div>
                        <Dropdown overlay={<Menu>
                            <Menu.Item><span>订阅设置</span></Menu.Item>
                        </Menu>}>
                            <div className={'button_topG'}>
                                <span>更多</span>
                            </div>
                        </Dropdown>
                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <ul className={'renwutonhji-ul'}>
                                <li>
                                    <span>总任务数</span>
                                    <span className={'number'} style={{color: '#3E78ED'}}>{queryTaskChartList.totalTask?queryTaskChartList.totalTask:0}</span>
                                    <Progress percent={queryTaskChartList.totalTask?queryTaskChartList.totalTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#3E78ED'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>已完成</span>
                                    <span className={'number'} style={{color: '#F4DF58'}}>{queryTaskChartList.doneTask?queryTaskChartList.doneTask:0}</span>
                                    <Progress percent={queryTaskChartList.doneTask?queryTaskChartList.doneTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#F4DF58'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>已逾期</span>
                                    <span className={'number'} style={{color: '#23B899'}}>{queryTaskChartList.expiredTask?queryTaskChartList.expiredTask:0}</span>
                                    <Progress percent={queryTaskChartList.expiredTask?queryTaskChartList.expiredTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#23B899'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>未完成</span>
                                    <span className={'number'} style={{color: '#F07661'}}>{queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask:0}</span>
                                    <Progress percent={queryTaskChartList.notDoneTask?queryTaskChartList.notDoneTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#F07661'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>待认领</span>
                                    <span className={'number'} style={{color: '#966CFC'}}>{queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask:0}</span>
                                    <Progress percent={queryTaskChartList.unassignedTask?queryTaskChartList.unassignedTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#966CFC'} showInfo={false}/>
                                </li>
                                <li>
                                    <span>时间待定</span>
                                    <span className={'number'} style={{color: '#7A79FF'}}>{queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask:0}</span>
                                    <Progress percent={queryTaskChartList.undeterminedTask?queryTaskChartList.undeterminedTask/10:0} className={'progress'} strokeWidth={'10px'} strokeColor={'#7A79FF'} showInfo={false}/>
                                </li>
                            </ul>


                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>任务统计</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={taskStatisticList}
                                           scroll={{ x: 700,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.screeningKey}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                editCondition ={(child)=>this.editCondition=child}
                            />
                            <p className={'shaixuank'} onClick={this.handLook}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.chartType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span  onClick={()=>this.clearCondition()} >清除筛选</span>
                            </div>
                            <div className={'button_chart'}>
                                <span onClick={()=>this.preview()}>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

Editortaskstatistical = Form.create()(Editortaskstatistical);
export default Editortaskstatistical;
