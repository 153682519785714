import { post } from "../../until/axios_instans";

// oos图片的
export function getImageOssBaseurl(data) {
  return post("/api/gongyong/api/v1/address/type", data);
}
// 获取根路径
export function getBaseUrl() {
  return post('/api/ss/api/v1/login/getBaseUrl');
}
