// 自定义报表——详情
import React, {Component} from "react";
import {Button, Col, Dropdown, Form, Icon, Input, Menu, Modal, Select, Steps, Table,} from "antd";
import {withRouter} from "react-router";
import {Rededitor,Readsign,Ineffectiveness,SaveName} from '../../../../../assets/svg'
import {queryOneDimension,queryTwoDimension,editChartConfition,queryCustomChart,
    saveCustomChart,editCustomChartName,queryCustomChartDetail,clearChartFilterValues,deleteChart} from "../../../../../api/chart/index"
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import style from './index.module.scss'
import TaskstatEditor from "../taskstatEditor";
import * as echarts from "echarts";
import ScreeningCondition from "../screeningCondition";
import RenderButton from "../../../../../components/renderButton";
import Cookies from "js-cookie";
import S from "gongyongicon";


@withRouter
class Unnamedblocks extends Component {
    state = {
        humanName: JSON.parse(Cookies.get('ssInfo')).userInfo.humanName,
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        queryCatgory:1,
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        total:0,
        //建设任务
        columns1: [
            {
                title: '建设任务名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',

            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
                render: (text, record) =>
                    <div>
                        <span>{this.getProjectStatus(text)}</span>
                    </div>
            },

            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
        ],
        //任务
        columns2: [
            {
                title: '任务名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '优先级',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getTaskPriorit(text)}</span>
                    </div>
            },
            {
                title: '状态',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
                render: (text, record) =>
                    <div>
                        <span>{this.getTaskStatus(text)}</span>
                    </div>
            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
        ],
        //绩效指标
        columns3: [
            {
                title: '绩效指标名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',


            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
            },
            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '三级指标',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'data6',
                key: 'data6',
            },
        ],
        //绩效指标-目标值
        columns4: [
            {
                title: '绩效指标名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
            },

            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '三级指标',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'data6',
                key: 'data6',
            },
            {
                title: '目标值',
                align: "center",
                width: 200,
                dataIndex: 'data7',
                key: 'data7',
            },
        ],
        //绩效指标-完成度
        columns5: [
            {
                title: '绩效指标名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',

            },
            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '三级指标',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'data6',
                key: 'data6',
            },
            {
                title: '完成度',
                align: "center",
                width: 200,
                dataIndex: 'data7',
                key: 'data7',
            },


        ],
        //资金-预算金额
        columns6: [
            {
                title: '建设任务名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '经费类型',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
                render: (text, record) =>
                    <div>
                        <span>{this.getInvestType(text)}</span>
                    </div>
            },
            {
                title: '年份',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '预算金额',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
        ],
        //资金-实际使用金额
        columns7: [
            {
                title: '建设任务名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '经费类型',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
                render: (text, record) =>
                    <div>
                        <span>{this.getInvestType(text)}</span>
                    </div>

            },
            {
                title: '年份',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '实际使用金额',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
        ],
        //资金-执行率
        columns8: [
            {
                title: '建设任务名称',
                width: 200,
                align: "center",
                dataIndex: 'data1',
                key: 'data1',
            },
            {
                title: '层面',
                align: "center",
                width: 100  ,
                dataIndex: 'data2',
                key: 'data2',
                render: (text, record) =>
                    <div>
                        <span>{this.getCengMianName(text)}</span>
                    </div>
            },
            {
                title: '经费类型',
                align: "center",
                width: 200,
                dataIndex: 'data3',
                key: 'data3',
                render: (text, record) =>
                    <div>
                        <span>{this.getInvestType(text)}</span>
                    </div>
            },

            {
                title: '年份',
                align: "center",
                width: 200,
                dataIndex: 'data4',
                key: 'data4',
            },
            {
                title: '执行率',
                align: "center",
                width: 200,
                dataIndex: 'data5',
                key: 'data5',
            },
        ],
        pageDataList: [
            {
                id: 1,
                projectName: '提升国际化水平提升国际化水平...',
                investAmount:'学校',
                expendAmount:'进行中',
                Principal:'李锦记',


            },
        ],//详情表数据
        chartData:[],//图表数据
        dataSet: ['负责人','状态'],
        getEcharts: false, //是否预览图表了
        oneDimensionList:[],
        twoDimensionList:[],
        initialValueOfOneDimension:'1',//一级维度初始值
        initialValueOfTwoDimension:'1.1',//二级维度初始值
        initialValueOfChartShape:1,//1柱形图2环状图3折线图
        chartId:this.props.match.params.chartId,//图表ID
        allConditionList:[],//所有条件列表
        addConditionList:[],//已添加条件列表
        noAddConditionList:[],//未添加条件列表
        allConfitionValues:[],//所有条件的值列表
        clearCondition:false,//清除筛选
        editName: '编辑', //名称那下一步是保存还是编辑
        chartType:0,//图表类型 0:自定义
        chartName:'未命名',
        //筛选
        useChildFlag:false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey:0,//screening子组件控制加载
        deleteFile: false,//是否删除弹窗
        previewFlag:0,//0:不是预览，1：是预览
        statusButten:true,//点击按钮
    }
    componentDidMount() {
        if(this.state.chartId){
            this.queryCustomChart()
        }else{
            this.queryOneDimension()
            this.queryTwoDimension()
        }
        this.queryCatgoryById()

    }

    //查询专业群
    async queryCatgoryById() {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }
    //查询一级维度
    queryOneDimension = async () => {
        let data = {
            cengMian: this.state.cengMian,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
        }
        await queryOneDimension(data).then(res => {
            if(res){
                this.setState({oneDimensionList:res},()=>{

                    // this.queryConfitionValues()
                })

            }
        })
    }
    //查询二级维度
    queryTwoDimension = async () => {
        let data = {oneDimensionId:this.state.initialValueOfOneDimension}
        await queryTwoDimension(data).then(res => {
            if(res){
                this.setState({
                    twoDimensionList:res,
                    useChildFlag:true,
                    screeningKey:this.state.screeningKey+1
                })
            }
        })
    }
    //查询自定义图表
    queryCustomChart = async () => {
        await queryCustomChart({chartId:this.state.chartId}).then(res => {
            if(res){
                console.log('res.twoDimensionId',res.twoDimensionId)
                this.setState({
                    initialValueOfOneDimension:res.oneDimensionId,
                    initialValueOfTwoDimension:res.twoDimensionId,
                    initialValueOfChartShape:res.shape,
                    chartName:res.chartName,

                },()=>{
                    this.queryCustomChartDetail({
                        chartId:this.state.chartId,
                        pageNum:this.state.pageNum,
                        pageSize:this.state.pageSize,

                    })
                    this.queryOneDimension()
                    this.queryTwoDimension()
                })
            }
        })
    }
    //查询自定义图表详情
    queryCustomChartDetail = async (data) => {
        await queryCustomChartDetail(data).then(res => {
            if(res){
                this.setState({
                    pageDataList:res.pageData.data,
                    chartData:res.customChartImgDataVoList,
                    pageNum:res.pageData.pageNum,
                    pageSize:res.pageData.pageSize,
                    total:res.pageData.total,
                    // useChildFlag:true,
                    // screeningKey:this.state.screeningKey+1
                },()=>{
                    this.generateEcharts()
                })
            }
        })
    }
    //自定义图表——保存图表
    saveCustomChart = async (dataPost) => {
        await saveCustomChart(dataPost).then(res => {
            if(res){
                this.mesSuccess("保存成功")
                if(this.state.chartId){
                    this.queryCustomChart()
                }else{
                    this.props.history.push('/main/doublehigh/chart/'+res.id)
                }

            }
        })
    }
    //自定义图表——编辑图表名称
    editCustomChartName = async () => {
        let data = {
            chartId:this.state.chartId,
            chartName:this.state.chartName
        }
        await editCustomChartName(data)
    }
    //删除图表
    deleteChart  = async () => {
        let data = {
            chartId:this.state.chartId,
        }
        await deleteChart(data).then(res=>{
            this.mesSuccess("删除成功")
            this.props.history.push('/main/doublehigh/dataStatisticsShow')

        })
    }
    //获取层面名称
    getCengMianName = (value)=>{
        if(this.state.queryCatgory == 1){
            if(value ==1){
                return '学校'
            }
            else if(value ==2){
                return '专业群一'
            }
            else if(value ==3){
                return '专业群二'
            }
            else if(value ==4){
                return '其他'
            }
        }else{
            if(value ==1){
                return '学校'
            }
            else if(value ==2){
                return '专业群'
            }
            else if(value ==4){
                return '其他'
            }
        }

    }
    //获取建设任务状态(项目状态 1：已启动 2：已逾期 3：已挂起 4：已归档 )
    getProjectStatus = (value)=>{
        if(value ==1){
            return '已启动'
        }
        else if(value ==2){
            return '已逾期'
        }
        else if(value ==3){
            return '已挂起'
        }
        else if(value ==4){
            return '已归档'
        }
        else{
            return value
        }
    }
    //获取任务状态(任务状态 1：进行中 2：已暂停 3：已完成 4:已逾期 5：未开始 )
    getTaskStatus = (value)=>{
        if(value ==1){
            return '进行中'
        }
        else if(value ==2){
            return '已暂停'
        }
        else if(value ==3){
            return '已完成'
        }
        else if(value ==4){
            return '已逾期'
        }
        else{
            return value
        }
    }
    //获取任务优先级(优先级 1：较低 2：普通 3：紧急 4:非常紧急 )
    getTaskPriorit = (value)=>{
        if(value ==1){
            return '较低'
        }
        else if(value ==2){
            return '普通'
        }
        else if(value ==3){
            return '紧急'
        }
        else if(value ==4){
            return '非常紧急'
        }
        else{
            return value
        }

    }
    //获取资金预算类型（1、中央财政投入资金 2、地方财政投入资金 3、举办方投入资金 4、行业企业支持资金 5、学校自筹资金）
    getInvestType = (value)=>{
        if(value ==1){
            return '中央财政投入资金'
        }
        else if(value ==2){
            return '地方财政投入资金'
        }
        else if(value ==3){
            return '举办方投入资金'
        }
        else if(value ==4){
            return '行业企业支持资金'
        }
        else if(value ==5){
            return '学校自筹资金'
        }
        else{
            return value
        }
    }
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true,
            screeningKey:this.state.screeningKey + 1
        })
    }
    //一级维度选择
    oneDimensionChange = (value) =>{
        this.setState({initialValueOfOneDimension:value},()=>{
            this.queryTwoDimension().then(res=>{
                this.setState({initialValueOfTwoDimension:this.state.twoDimensionList[0].id})
                this.props.form.setFieldsValue({twoDimensionForm:this.state.twoDimensionList[0].id})
            })
        })
    }
    //二级维度选择
    twoDimensionChange = (value) =>{
        this.setState({initialValueOfTwoDimension:value})
    }
    //图表类型选择
    chartTypeChange = (value) =>{
        this.setState({initialValueOfChartShape:value})
    }
    //生成图表
    generateEcharts = () =>{
        this.setState({getEcharts: true},()=>{
            if(document.getElementById('generateEchart')) {
                let chartData = this.state.chartData //动态数据
                if(!chartData){
                    chartData = []
                }
                let myChart = echarts.init(document.getElementById('generateEchart'));
                // let echartsType = this.props.form.getFieldValue('echartsType')
                let echartsType = this.state.initialValueOfChartShape
                // let xdata = ['1月', '2月', '3月', '4月']
                let xdata = []
                let ydata = []
                for (let i = 0; i < chartData.length; i++) {
                    xdata.push(chartData[i].xdata)
                    ydata.push(chartData[i].ydata?chartData[i].ydata:0)
                }

                let series = [{
                    // data: ['10', '20', '30', '40'],
                    data: ydata,
                    type: echartsType==1?'bar':echartsType==2?'pie':'line',
                    barWidth: 20,
                    showBackground: true,
                }]
                let xAxis = {
                    type: 'category',
                    data: xdata,
                    show: true
                }
                let yAxis = {
                    type: 'value',
                    minInterval: 1,
                    show: true
                }
                let color='#588BF7'
                let tooltipBackgroundColor = 'rgba(51, 51, 51, 0.8)'

                let pieData = []
                let legendData = []
                for (let i = 0; i < chartData.length; i++) {
                    pieData.push({ value: chartData[i].ydata, name: chartData[i].xdata })
                    legendData.push({ name: chartData[i].xdata, icon: 'circle' })
                }
                //环状图
                if(echartsType === 2){
                    xAxis ={show:false}
                    yAxis = {show:false}
                    series = [
                        {
                            type: 'pie',
                            radius: ['40%', '70%'],
                            center: ['25%','50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: true,
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    fontWeight: 'bold',
                                    formatter: function({ data }) {
                                        // let html = `{b| 实际使用额度} \n {c|¥${data.value}}`;
                                        let html = `{b| 数量} \n {c|${data.value}}`;
                                        return html;
                                    },
                                    rich: {
                                        b: {
                                            //name 文字样式
                                            lineHeight: 20,
                                            fontSize: 14,
                                            textAlign: "center",
                                            color: "#333333"
                                        },
                                        c: {
                                            //value 文字样式
                                            lineHeight: 29,
                                            color: "#333333",
                                            fontSize: 20,
                                            fontWeight: 550
                                        },
                                    }
                                },
                            },
                            labelLine: {
                                show: false
                            },
                            // data: [
                            //     { value: 1048, name: '提升国际化水平' },
                            //     { value: 735, name: '提升信息化水平' },
                            //     { value: 580, name: '提升学校治理水平' },
                            //     { value: 484, name: '提升服务发展水平' },
                            //     { value: 300, name: '提升校企合作水平' },
                            //     { value: 300, name: '打造高水平专业群' }
                            // ]
                            data: pieData
                        }
                        ]
                    color = ['#23B899','#F07661','#966CFC','#7A79FF','#3E78ED','#F4DF58']
                }
                else if(echartsType === 3){
                    color = '#FF7214'
                    tooltipBackgroundColor = '#FF7214'
                }
                let option = {
                    tooltip: {
                        axisPointer: {
                            type: "shadow"
                        },
                        backgroundColor: tooltipBackgroundColor,
                        borderWidth: 0,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        },
                    },
                    xAxis: xAxis,
                    legend : {
                        type: "scroll",
                        top: '100',
                        left: '50%',
                        orient: 'vertical',
                        itemWidth: 8, // 设置宽度
                        itemHeight: 8, // 设置高度
                        // data: [
                        //     { name: '提升国际化水平', icon: 'circle' },
                        //     { name: '提升信息化水平', icon: 'circle' },
                        //     { name: '提升学校治理水平', icon: 'circle' },
                        //     { name: '提升服务发展水平', icon: 'circle' },
                        //     { name: '提升校企合作水平', icon: 'circle' },
                        //     { name: '打造高水平专业群', icon: 'circle' }
                        // ],
                        data:legendData,
                        formatter: (name) => {
                            let amount = 0
                            pieData.map(item=>{
                                if(name === item.name){
                                    amount = item.value
                                }
                            })
                            return name + '{a|' + '：  '+ amount + '}' + '{b|' + '}';
                        },
                        tooltip: {
                            show: true//这个为true的话悬停到legend上会出现tooltip
                        },
                        textStyle: {
                            fontSize: 14,
                            //在rich中给formatter添加个别字体颜色
                            rich: {
                                a:{
                                    width: 120,
                                    fontSize: 14,
                                    color: '#333333',
                                    align: 'left'
                                },
                                b: {
                                    color: '#888888',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    width: 30,
                                    align: 'left'
                                },
                                c: {
                                    color: '##40a9ff',
                                    padding: [0, 10],
                                    fontSize: 14,
                                    align: 'left'
                                },
                            }
                        }
                    },
                    color: color,
                    grid: {
                        left: 100,
                        right: 100,
                        bottom: 100
                    },
                    yAxis: yAxis,
                    series: series
                };
                myChart.setOption(option)
                window.addEventListener("resize", function () {
                    myChart.resize();
                })
            }
        })
    }
    //预览图表
    preView = (pageNum) => {
        let conditionValuesQo = this.editCondition.getConditionValueChange()
        this.queryCustomChartDetail({
            oneDimension:this.state.initialValueOfOneDimension,
            twoDimension:this.state.initialValueOfTwoDimension,
            conditionValuesQo:conditionValuesQo,
            pageNum:pageNum,
            pageSize:this.state.pageSize,
            code:'1',//预览
        }).then(res=>{
            this.setState({previewFlag:1})
        })
    }
    //修改名称
    changeName = (e) => {
        console.log('name',e.target.value)
        this.setState({chartName:e.target.value})
    }
    //名称那下一步是保存还是编辑
    editName =(editName) =>{
        this.setState({editName:editName})
        console.log('editName',editName)
        // if(this.state.chartId){
        //     if(editName == '编辑'){
        //         this.editCustomChartName(editName).then(res=>{
        //             if(res){
        //                 this.mesSuccess("名称修改成功")
        //                 this.setState({editName:editName,chartName:this.state.changeName})
        //             }
        //         })
        //     }
        //     if(editName == '保存'){
        //         this.setState({editName:editName})
        //     }
        // }else{
        //     this.setState({editName:editName})
        // }


    }
    //翻页
    onChangePage = (pageNum, filters, sorter, extra)=>{
        //预览
        if(this.state.previewFlag == 1){
            this.preView(pageNum)
        }
        //非预览
        if (this.state.previewFlag == 0){
            this.setState({
                pageNum:pageNum,
            },()=>{

                this.queryCustomChartDetail({
                    chartId:this.state.chartId,
                    pageNum:this.state.pageNum,
                    pageSize:this.state.pageSize,

                })
            })
        }


    }
    //切页
    sizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.queryCustomChartDetail({
                chartId:this.state.chartId,
                pageNum:this.state.pageNum,
                pageSize:this.state.pageSize,

            })
        })
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res=>{
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension:'1'},()=>{
                this.queryCustomChart()
            })

        })
    }
    //按钮保存(编辑筛选条件、保存图表)
    saveOk = ()=>{
        if(this.state.statusButten){ //防双击
            console.log('111')
            this.setState({statusButten: false})
            let conditionValues = this.editCondition.getConditionValueChange()
            let dataPost = {
                chartId:this.state.chartId,
                chartName:this.state.chartName,
                shape:this.state.initialValueOfChartShape,
                oneDimension:this.state.initialValueOfOneDimension,
                twoDimension:this.state.initialValueOfTwoDimension,
                conditionValuesQo:conditionValues
            }
            this.saveCustomChart(dataPost)
        }

        setTimeout(() => {   // 设置延迟事件,3// 秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 3000);
    }
    //编辑筛选条件
    editConditionOk = ()=>{
        console.log(this.state.chartId)
        if(!this.state.chartId){
            this.mesWarning('请先保存图表')
            return
        }
        let conditions = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < conditions.length; i++) {
            data.push({conditionId:conditions[i]})
        }

        this.editChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                this.queryCustomChart()
            })
        })

    }
    //编辑图表筛选条件
    editChartConfition  =  async (data) => {
        await editChartConfition(data).then(res => {
            if(res){
                this.mesSuccess("操作成功")
            }

        })
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        await clearChartFilterValues({chartId:this.state.chartId})
    }
    //删除图表
    deleteOk = ()=>{
        this.deleteChart()
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {pageSize, total, pageNum,isreportEditor,dataSet,getEcharts,columns,pageDataList,oneDimensionList,initialValueOfOneDimension,
            twoDimensionList,initialValueOfTwoDimension,initialValueOfChartShape,columns1,columns2,columns3,columns4,columns5,columns6,columns7,columns8,
            chartName,editName
        } = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['20', '40', '60', '80'],
            onChange: this.onChangePage,
            onShowSizeChange: this.sizeChange
        }
        return (
            <div className={style.unnamedblocks}>
                <div className={'taskStatistical_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        {
                            editName === '编辑' ? <div style={{display:'flex'}}>
                                <span className={'span_task'}>{chartName}</span>
                                <span className={'span_svg'} onClick={()=> this.editName('保存')}><Rededitor/><span className={'text'}>编辑</span></span>
                            </div> :
                                <div style={{display:'flex'}}>
                                    <Input defaultValue={chartName} className={'span_task'} onChange={this.changeName}/>
                                    <span className={'span_svg'} onClick={()=> this.editName('编辑')}><SaveName/><span className={'text'}>确认</span></span>
                                </div>
                        }
                    </div>
                    <div className={'task_right'}>
                        {/*<div className={'button_topR'}>*/}
                        {/*    <span>完成</span>*/}
                        {/*</div>*/}
                        <div className={'button_topRB'} onClick={()=>this.saveOk()}>
                            <span>保存</span>
                        </div>
                        {
                            this.state.chartId ? <Dropdown overlay={<Menu>
                                <Menu.Item><span>订阅设置</span></Menu.Item>
                                <Menu.Item><span onClick={()=>this.setState({deleteFile: true})}>删除</span></Menu.Item>
                            </Menu>}>
                                <div className={'button_topG'}>
                                    <span>更多</span>
                                </div>
                            </Dropdown>:''
                        }

                        <div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>
                            <span>关闭</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        {
                            getEcharts ? <div>
                                <div id={'generateEchart'} className={'echarts'}></div>
                                <div className={'taskprogress_listbox_box'}>
                                    <div className={'taskprogress_title'}>
                                        <span>详情表</span>
                                    </div>
                                    <div className={'border'}></div>
                                    <div className={'table_t'}>
                                        <Table cellspacing={20} columns={
                                            initialValueOfOneDimension==1?columns1:
                                            initialValueOfOneDimension==2?columns2:
                                                initialValueOfOneDimension==3?columns3:
                                                    initialValueOfOneDimension==4?columns4:
                                                        initialValueOfOneDimension==5?columns5:
                                                            initialValueOfOneDimension==6?columns6:
                                                                initialValueOfOneDimension==7?columns7:
                                                                    initialValueOfOneDimension==8?columns8:''
                                        }
                                               className={'tableUserStyle'}
                                               dataSource={pageDataList}
                                               scroll={{ x: 700,y:290 }}
                                               onChange={this.changePage}
                                               pagination={pagination}
                                               rowKey={(r, i) => (r.id)}
                                               locale={{emptyText:<><div className="Empty-conet-ss">
                                                       <div className="img">
                                                           <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                           <span>暂无数据</span>
                                                       </div>
                                                   </div></>}}
                                        />
                                    </div>
                                </div>
                            </div> :
                                <div className={'taskprogress_left'}>
                                    <Ineffectiveness></Ineffectiveness>
                                </div>
                        }
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'form_lable'}>
                            <Form>
                                <Form.Item label="分析维度">
                                    {
                                        getFieldDecorator('oneDimensionForm', {
                                            initialValue: initialValueOfOneDimension,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select placeholder="请选择"
                                                   onChange={(e)=>this.oneDimensionChange(e)}
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >

                                            {
                                                oneDimensionList && oneDimensionList.map((item,index)=>(
                                                    <Select.Option value={item.id}>{item.dimensionName}</Select.Option>
                                                ))
                                            }
                                        </Select>)
                                    }
                                </Form.Item>
                                <Form.Item label="数据设置">
                                {
                                    getFieldDecorator('twoDimensionForm', {
                                        initialValue: initialValueOfTwoDimension,
                                        rules: [
                                            {
                                                required: true,
                                                message: ''
                                            }
                                        ]
                                    })(<Select placeholder="请选择"
                                               onChange={(e)=>this.twoDimensionChange(e)}
                                               getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                    >
                                        {
                                            twoDimensionList && twoDimensionList.map((item,index)=>{
                                                return <Select.Option value={item.id}>{item.dimensionName}</Select.Option>
                                            })
                                        }
                                    </Select>)
                                }
                            </Form.Item>
                                <Form.Item label="图标类型">
                                    {
                                        getFieldDecorator('echartsType', {
                                            initialValue: initialValueOfChartShape,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: ''
                                                }
                                            ]
                                        })(<Select placeholder="请选择"
                                                   onChange={(e)=>this.chartTypeChange(e)}
                                                   getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                        >
                                            <Select.Option value={1}>柱形图</Select.Option>
                                            <Select.Option value={2}>环状图</Select.Option>
                                            <Select.Option value={3}>折线图</Select.Option>
                                        </Select>)
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                        <div className={'taskprogress_right_listbox'}>
                            <p></p>
                            <span className={'span_licon'}>筛选</span>
                        </div>
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.screeningKey}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                oneDimensionId = {this.state.initialValueOfOneDimension}
                                editCondition ={(child)=>this.editCondition=child}
                            />
                            <p className={'shaixuank'} onClick={()=>this.handLook()}>
                                <span className={'Readsign_svg'}><Readsign></Readsign></span>
                                <span className={'span_add'}>添加筛选条件</span>
                            </p>
                        </div>

                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.trendType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                oneDimensionId = {this.state.initialValueOfOneDimension}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>
                        <div className={'button_button'}>
                            <div className={'button_away'}>
                                <span onClick={()=>this.clearCondition()}>清除筛选</span>
                            </div>
                            <div className={'button_chart'} onClick={this.generateEcharts}>
                                <span onClick={()=>this.preView(1)}>预览图表</span>
                            </div>
                        </div>

                    </div>
                </div>
                {/*删除提示框*/}
                <Modal
                    title={'提示'}
                    visible={this.state.deleteFile}
                    z-index={99999999}
                    onOk={()=>this.deleteOk()}
                    onCancel={()=>{this.setState({deleteFile: false})}}
                    width={450}
                    closable={false}
                    centered={true}>
                    <div>
                        <div className={'mWarningCircle'}>
                            <S.SvgWarningCircle/>
                        </div>
                        <p>是否确认删除</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

Unnamedblocks = Form.create()(Unnamedblocks);
export default Unnamedblocks;
