/*资金与绩效柱形图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';
import {queryTaskMeritTrend} from "../../../api/chart/index"

@withRouter
class taskPerformance extends Component {
    state = {
        trendType:this.props.trendType,
        dateType:this.props.dateType,
        xData:[],
        yData:[],
        datanull: false
    }

    componentDidMount() {
        this.queryTaskMeritTrend()
    }
    //任务与绩效进展走势接口
    queryTaskMeritTrend = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType
        }
        await queryTaskMeritTrend(data).then(res => {
            if(res){
                let trendDatas = res.countOfCompletedVos
                let xData = []//x轴数据
                let yData = []//y轴数据
                let months = []//前推12个月数据
                // for (let i = 0; i < 12; i++) {
                //     let date = new Date()
                //     date.setMonth(date.getMonth()-i)
                //     months.push(this.dateFormat("YYYY-mm",date))
                // }
                // months.reverse()
                // console.log('months',months)
                // if(trendDatas){
                //     let dataMonths = []
                //     let dataCounts = []
                //     for (let i = 0; i < trendDatas.length; i++) {
                //         dataMonths.push(trendDatas[i].completeMonth)
                //         dataCounts.push(trendDatas[i].completeCount)
                //     }
                //     for (let i = 0; i < months.length; i++) {
                //         xData.push(months[i].split('-')[1]+'月')
                //         if(dataMonths.includes(months[i])){
                //             console.log('months[i]',months[i])
                //             yData.push(dataCounts[dataMonths.indexOf(months[i])])
                //         }else{
                //             yData.push(0)//没有数据情况
                //         }
                //     }
                // }
                console.log('1111111111111111111111111')
                //12个月
                if(this.state.dateType == 3){
                    for (let i = 0; i < 12; i++) {
                        let date = new Date()
                        date.setMonth(date.getMonth()-i)
                        months.push(this.dateFormat("YYYY-mm",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'月')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //7日
                else if(this.state.dateType ==1){
                    for (let i = 0; i < 7; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    console.log('months',months)
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //30日
                else if(this.state.dateType ==2){
                    for (let i = 0; i < 30; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                console.log('xData2222222222222222',xData)
                this.setState({xData:xData,yData:yData},()=>{
                    if(yData.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.taskPerformance()
                        this.setState({datanull: false})
                    }
                })

            }

        })
    }
    dateFormat = (fmt, date) => {
        console.log('date',typeof(date))
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    }
    taskPerformance = () => {
       console.log('ydata',this.state.yData)
        if(document.getElementById('taskPerformance')) {
            let myChart = echarts.init(document.getElementById('taskPerformance'));
           let option = {
               tooltip: {
                   axisPointer: {
                       type: "shadow"
                   },
                   backgroundColor: 'rgba(51, 51, 51, 0.8)',
                   borderWidth: 0,
                   textStyle: {
                       color: '#FFFFFF',
                       fontSize: 12,
                   },
                   formatter: function(params){
                       console.log('params',params)
                       let res;
                       res = '<div>' +
                           '<div class="echarts-bar-tooltip" style="text-align: center">' +
                           '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>'+params.name+'</div>' +
                           '<div style="text-align: center">'+params.value
                           + '个</div>' +
                           '</div>'
                       return res
                   }
               },
               grid:{
                   left:25,
                   top: 30,
                   bottom:50,
                   right:50
               },
               xAxis: {
                   type: 'category',
                   // data: ['10月', '11月', '12月', '1月', '2月', '3月', '4月','5月','6月','7月','8月','9月']
                   data: this.state.xData,
               },
               color: '#588BF7',
               yAxis: {
                   type: 'value',
                   minInterval:1,
                   splitLine: {
                       show: true,
                       lineStyle:{
                           type: 'dashed',
                           color: '#F3F3F3'
                       }
                   }
               },
               series: [
                   {
                       // data: [120, 200, 150, 80, 70, 110, 130,120, 200, 150, 80, 70],
                       data: this.state.yData,
                       type: 'bar',
                       barWidth: 20,
                       showBackground: true,
                       backgroundStyle: {
                           color: 'rgba(238, 238, 238, 0.3)'
                       }
                   }
               ]
           };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    render() {
        return (
            <div style={{float: 'left',width: '100%'}}>
                {
                    this.state.datanull ?
                        <div className="Empty-conet-ss Empty-conet-ss-echarts">
                            <div className="img">
                                <img src={require("../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                <span>暂无数据</span>
                            </div>
                        </div> :
                        <div id={'taskPerformance'} className={'echarts'} style={{width: '100%', height: '295px'}}></div>
                }
            </div>

        );
    }
}

taskPerformance = Form.create()(taskPerformance);
export default taskPerformance;
