// 绩效指标达标情况——详情
import React, {Component} from "react";
import {Form, Table, Tabs, Button, Modal, Menu, Dropdown} from "antd";
import {withRouter} from "react-router";
import {Readsign, Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import * as echarts from "echarts";
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import {
    meritIndicatorsQuery,
    queryConfitionByCTAndDT,
    editChartConfition,
    queryConfitionValues,
    editChartConfitionValues,
    clearChartFilterValues, queryDefaultIconId
} from "../../../../../api/chart";
import {queryCatgoryById} from "../../../../../api/synchrodatment";
import Cookies from "js-cookie";
import ScreeningCondition from "../../newReportFile/screeningCondition";

const {TabPane} = Tabs;

@withRouter
class Edittaskachievementofindicators extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        columns: [
            {
                title: '绩效指标',
                dataIndex: 'meritName',
                width: 200,
                align: "center",
                key: 'meritName',


            },
            {
                title: '层面',
                align: "center",
                width: 100,
                dataIndex: 'meritType',
                key: 'meritType',
                render: (text) => {
                    return (<div>{this.state.queryCatgory === 1 ? this.state.projectTypes.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    }) : this.state.projectTypess.map((item, index) => {
                        if (text == item.value) {
                            return <span key={index}>{item.label}</span>
                        }
                    })}</div>)
                }


            },
            {
                title: '一级指标',
                align: "center",
                width: 200,
                dataIndex: 'oneIndicatorName',
                key: 'oneIndicatorName',

            },
            {
                title: '二级指标',
                align: "center",
                width: 200,
                dataIndex: 'twoIndicatorName',
                key: 'twoIndicatorName',

            }, {
                title: '完成度',
                align: "center",
                width: 200,
                dataIndex: 'completeRate',
                key: 'completeRate',
                render: (text) => {
                    return <div>{text ? <span>{text}%</span> : <span>--</span>}</div>
                }

            },


        ],
        projectTypes: [
            {'label': '学校', 'value': 1},
            {'label': '专业群一', 'value': 2},
            {'label': '专业群二', 'value': 3},
            {'label': ' 其他', 'value': 4}

        ],
        projectTypess: [
            {'label': '学校', 'value': '1'},
            {'label': '专业群', 'value': '2'},
            {'label': ' 其他', 'value': '4'}

        ],

        meritIndicatorsQueryList: [],
        name: [],
        indicatorsNum: [],
        notIndicatorsNum: [],
        exceedIndicatorsNum: [],
        type: '1',
        queryCatgory: 0,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        chartId: 8,
        chartType: 8,
        allConditionList: [],
        addConditionList: [],
        noAddConditionList: [],
        allConfitionValues: [],
        defaultType: 1,
        chartMeritId: 8,
        chartMeritType: 8,
        //筛选
        useChildFlag: false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey: 0,//screening子组件控制加载

    }


    componentDidMount() {
        this.onChangeMeritTab()
        this.queryCatgoryById()
    }

    onChangeMeritTab = async () => {
        let dataType = {
            schoolId: this.state.schoolId,
            chartType: this.state.chartType
        };
        await queryDefaultIconId(dataType).then(e => {
            this.setState({
                chartId:e.id
            })
        }).then( s=> { let datas = this.editCondition.getConditionValueChange()
            let data = {
                type: this.state.type,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                code: 1,
                screenType: datas,
                defaultType: this.state.defaultType,
                chartId: this.state.chartMeritId,
                chartType: this.state.chartMeritType,
                schoolId: this.state.schoolId,

            }
            if (this.state.defaultType == 1) {
                meritIndicatorsQuery(data).then(e => {
                    let name = []//名称
                    let indicatorsNum = []//指标数
                    let notIndicatorsNum = []//未完成指标数
                    let exceedIndicatorsNum = []//超预期指标数
                    e.expendList.map(item => {
                        name.push(item.name.slice(0, -2))
                        indicatorsNum.push(item.indicatorsNum)
                        notIndicatorsNum.push(item.notIndicatorsNum)
                        exceedIndicatorsNum.push(item.exceedIndicatorsNum)
                    })
                    this.setState({
                        name: name,
                        indicatorsNum: indicatorsNum,
                        notIndicatorsNum: notIndicatorsNum,
                        exceedIndicatorsNum: exceedIndicatorsNum,
                        meritIndicatorsQueryList: e.pageData.data,
                        total: e.pageData.total,
                        useChildFlag: true,//首次进入，子组件不调用接口，赋值后再调用
                        screeningKey: this.state.screeningKey + 1,//screening子组件控制加载
                    }, () => {
                        this.echarts()
                    })
                })
            } else {
                 meritIndicatorsQuery(data).then(e => {
                    let name = []//名称
                    let indicatorsNum = []//指标数
                    let notIndicatorsNum = []//未完成指标数
                    let exceedIndicatorsNum = []//超预期指标数
                    e.expendList.map(item => {
                        name.push(item.name.slice(0, -2))
                        indicatorsNum.push(item.indicatorsNum)
                        notIndicatorsNum.push(item.notIndicatorsNum)
                        exceedIndicatorsNum.push(item.exceedIndicatorsNum)
                    })
                    this.setState({
                        name: name,
                        indicatorsNum: indicatorsNum,
                        notIndicatorsNum: notIndicatorsNum,
                        exceedIndicatorsNum: exceedIndicatorsNum,
                        meritIndicatorsQueryList: e.pageData.data,
                        total: e.pageData.total,
                    }, () => {
                        this.echarts()
                    })
                })
            }})



    }

    //查询专业群
    async queryCatgoryById(index) {
        let data = {
            id: this.state.schoolId
        };  //区分是专业群1或2
        let queryCatgory = await queryCatgoryById(data)
        this.setState({
            queryCatgory: queryCatgory
        })
    }

    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum.current,
            pageSize: pageNum.pageSize
        }, () => {
            //列表方法
            this.onChangeMeritTab()
        })
    }



    //预览图表
    preview = () => {
        this.state.defaultType = 2
        this.onChangeMeritTab()

    }

    echarts = () => {
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('meetPerformanceIndicators')) {
            let data1 = this.state.indicatorsNum
            let data2 = this.state.notIndicatorsNum
            let data3 = this.state.exceedIndicatorsNum
            let myChart = echarts.init(document.getElementById('meetPerformanceIndicators'));
            // 绘制图表
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#3E78ED', '#23B899', '#FFEE7E'],
                legend: {
                    itemHeight: 10,
                    itemWidth: 10,
                    data: [{name: '指标数', icon: 'circle'}, {name: '未完成指标数', icon: 'circle'}, {
                        name: '超出预期完成数',
                        icon: 'circle'
                    }],
                    bottom: 0,
                    left: '20%',
                },
                grid: {
                    left: '10%',
                    top: 10
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.state.name,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        type: 'value',
                        // axisLabel: {
                        //     formatter: ''
                        // }
                    },
                ],
                series: [
                    {
                        name: '指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1,
                        barWidth: 10
                    },
                    {
                        name: '未完成指标数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2,
                        barWidth: 10
                    },
                    {
                        name: '超出预期完成数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data3,
                        barWidth: 10
                    },

                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }
    // 编辑
    handLook = () => {
        this.setState({
            isreportEditor: true
        })
    }
    onChangeTabMeetPerformanceIndicators = (item) => {
        this.setState({type: item}, () => {
            this.onChangeMeritTab()
        })

    }
    //————————筛选操作——————————
    //编辑图表筛选条件api
    editChartConfition = async (data) => {
        await editChartConfition(data)
    }
    //编辑图表筛选条件的值api
    editChartConfitionValues = async (data) => {
        await editChartConfitionValues(data).then(res => {
        })
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        await clearChartFilterValues({chartId: this.state.chartId})
    }
    //刷新数据
    refreshPageData = () => {
        this.onChangeMeritTab()
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res => {
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension: '1'}, () => {
                this.refreshPageData()
            })

        })
        this.state.defaultType = 1
        this.onChangeMeritTab()
    }
    //按钮保存(编辑筛选条件值、保存图表)
    saveOk = () => {
        let conditionValues = this.editCondition.getConditionValueChange()
        let dataPost = {
            chartId: this.state.chartId,
            conditionValuesQo: conditionValues
        }
        this.editChartConfitionValues(dataPost).then(res => {
            this.mesSuccess("操作成功")
            this.refreshPageData()
        })
    }
    //编辑筛选条件
    editConditionOk = () => {
        if (!this.state.chartId) {
            this.mesWarning('请先保存图表')
            return
        }
        let conditions = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < conditions.length; i++) {
            data.push({conditionId: conditions[i]})
        }
        this.editChartConfition({chartId: this.state.chartId, conditionValuesQo: data}).then(res => {
            this.setState({isreportEditor: false, initialValueOfOneDimension: '1'}, () => {
                this.mesSuccess("操作成功")
                this.refreshPageData()
            })
        })

    }
    //导出任务图表
    exportExcel = () => {
        // window.location.href = "http://localhost:30001/api/v1/chart/exportExcel"
         window.location.href = "http://" + window.location.hostname +":"+ window.location.port + "/api/ss/api/v1/chart/exportExcel?chartId=" + this.state.chartId + "&schoolId=" + this.state.schoolId + "&chartType=" + this.state.chartType + ""
        // exportExcel();
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {columns, pageDataList, pageSize, total, pageNum, isreportEditor, meritIndicatorsQueryList, queryCatgory} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            // onChange: this.onChange,
            pageSizeOptions: ['20', '40', '60', '80'],
        }
        return (
            <div className={style.edittaskachievementofindicators}>
                <div className={'taskprogress_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>绩效指标达标情况</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'button_topR'}>*/}
                        {/*    <span  onClick={()=>this.exportExcel()}>导出</span>*/}
                        {/*</div>*/}
                        {/*<div className={'button_topRB'} onClick={() => this.saveOk()}>*/}
                        {/*    <span>保存</span>*/}
                        {/*</div>*/}
                        {/* todo 图表详情页无用图标暂时注掉 end*/}

                        {/*<Dropdown overlay={<Menu>*/}
                        {/*    <Menu.Item><span>订阅设置</span></Menu.Item>*/}
                        {/*</Menu>}>*/}
                        {/*    <div className={'button_topG'}>*/}
                        {/*        <span>更多</span>*/}
                        {/*    </div>*/}
                        {/*</Dropdown>*/}
                        <div className={'button_topgb'} onClick={() => {
                            // this.props.history.push('/main/doublehigh/dataStatisticsShow')
                            this.props.history.goBack()
                        }}>
                            <span>返回</span>
                        </div>


                    </div>


                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            {queryCatgory == 1 ? <Tabs className='all' defaultActiveKey=""
                                                       onChange={(item) => this.onChangeTabMeetPerformanceIndicators(item)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane tab={'专业群一'} key="2">
                                </TabPane>
                                <TabPane tab={'专业群二'} key="3">
                                </TabPane>
                                <TabPane tab={'其他'} key="4">
                                </TabPane>
                            </Tabs> : <Tabs className='all' defaultActiveKey=""
                                            onChange={(item) => this.onChangeTabMeetPerformanceIndicators(item)}>
                                <TabPane tab={'学校'} key="1">
                                </TabPane>
                                <TabPane tab={'专业群'} key="2">
                                </TabPane>
                                <TabPane tab={'其他'} key="4">
                                </TabPane>
                            </Tabs>}
                            <div id={'meetPerformanceIndicators'} className={'echarts'}
                                 style={{width: '100%', height: '370px', marginTop: '20px'}}></div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    <span>绩效指标达标情况</span>
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={columns}
                                           className={'tableUserStyle'}
                                           dataSource={meritIndicatorsQueryList}
                                           scroll={{x: 900, y: 290}}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{
                                               emptyText: <>
                                                   <div className="Empty-conet-ss">
                                                       <div className="img">
                                                           <img
                                                               src={require("../../../../../assets/imgnew/AE_empty.jpg")}
                                                               alt={''}/>
                                                           <span>暂无数据</span>
                                                       </div>
                                                   </div>
                                               </>
                                           }}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>

                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'taskprogress_right_listbox'}>*/}
                        {/*    <p></p>*/}
                        {/*    <span className={'span_licon'}>筛选</span>*/}
                        {/*</div>*/}
                        <div className={'Filter'}>
                            <ScreeningCondition
                                key={this.state.screeningKey}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType={this.state.initialValueOfOneDimension}
                                cengMian={this.state.defaultType}
                                editCondition={(child) => this.editCondition = child}
                            />
                            {/*<p className={'shaixuank'} onClick={this.handLook}>*/}
                            {/*    <span className={'Readsign_svg'}><Readsign></Readsign></span>*/}
                            {/*    <span className={'span_add'}>添加筛选条件</span>*/}
                            {/*</p>*/}
                        </div>
                        {/* todo 图表详情页无用图标暂时注掉 end*/}

                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={() => this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.chartType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType={this.state.initialValueOfOneDimension}
                                addCondition={(child) => this.addCondition = child}
                            />
                        </Modal>

                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'button_button'}>*/}
                        {/*    <div className={'button_away'}>*/}
                        {/*        <span onClick={() => this.clearCondition()}>清除筛选</span>*/}
                        {/*    </div>*/}
                        {/*    <div className={'button_chart'}>*/}
                        {/*        <span onClick={() => this.preview()}>预览图表</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* todo 图表详情页无用图标暂时注掉 end*/}

                    </div>
                </div>


            </div>
        );
    }
}

Edittaskachievementofindicators = Form.create()(Edittaskachievementofindicators);
export default Edittaskachievementofindicators;
