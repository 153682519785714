
import React from 'react'
import ReactEcharts from 'echarts-for-react';

class GeneralTwo extends React.Component {
    state = {
    }

    componentDidMount() {
        this.setState({
            ratePerformance: this.props.overallFundSituationInfo.ratePerformance
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('执行率 888', nextProps)
        this.setState({
            ratePerformance: nextProps.overallFundSituationInfo.ratePerformance
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    optionPie = () => { // 饼状图
        var data = [
            {
                name: '累计执行率',
                value: this.state.ratePerformance,
                num: 200,
            },
        ];

        var titleArr = [];
        var seriesArr = [];
        var colors = [
            ['#9063FF', '#F7F7F7'],
        ];
        data.forEach(function (item, index) {
            seriesArr.push({
                name: item.name,
                type: 'pie',
                clockWise: true,   //顺时针
                radius: [60, 85],
                itemStyle: {
                    normal: {
                        color: colors[index][0],
                        shadowColor: colors[index][0],
                        shadowBlur: 0,
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                    },
                },
                hoverAnimation: false,
                center: ['50%', '50%'],
                data: [
                    {
                        value: item.value,
                        label: {
                            normal: {
                                formatter: function (params) {
                                    return params.value + '%';
                                },
                                position: 'center',
                                show: true,
                                textStyle: {
                                    fontSize: '20',
                                    fontWeight: 'bold',
                                    color: '#333',
                                },
                            },
                        },
                    },
                    {
                        value: 100 - item.value,
                        name: 'invisible',
                        itemStyle: {
                            normal: {
                                color: colors[index][1],
                            },
                            emphasis: {
                                color: colors[index][1],
                            },
                        },
                    },
                ],
            });
        });
        return {
            backgroundColor: '#fff',
            series: seriesArr,
            legend:{
                icon: 'circle',
                itemWidth: 10,
                itemHeight: 10,
                bottom:0,
                textStyle: {
                    color: '#888888',
                    fontSize: 14,
                },
                data:data,

            }
        };
    }

    render() {
        return (
            <div className={'GeneralTwo'} style={{width:'100%'}}>
                <ReactEcharts option={this.optionPie()} style={{height:'300px'}}/>
            </div>
        )
    }

}
export default GeneralTwo;
