import { post } from '../../until/axios_instans';


//消息中心-查询全部评论
export function queryAllMessage(data){
    return post('/api/ss/api/v1/message/queryAllMessage',data);
}
//消息中心-查询已读评论
export function queryReadMessage(data){
    return post('/api/ss/api/v1/message/queryReadMessage',data);
}
//消息中心-查询未读评论
export function queryUnReadMessage(data){
    return post('/api/ss/api/v1/message/queryUnReadMessage',data);
}
//消息中心-查询@我的评论
export function queryAtMeMessage(data){
    return post('/api/ss/api/v1/message/queryAtMeMessage',data);
}
//消息中心-标记所有评论为已读
export function allMessageToRead(data){
    return post('/api/ss/api/v1/message/allMessageToRead',data);
}
//消息中心-删除所有已读消息
export function deleteAllReadMessage(data){
    return post('/api/ss/api/v1/message/deleteAllReadMessage',data);
}

//消息中心-初始化信息
export function queryBeginMessage(data){
    return post('/api/ss/api/v1/message/queryBeginMessage',data);
}

//消息中心-单条信息未读转已读状态
export function singleMsgToRead(data){
    return post('/api/ss/api/v1/message/singleMsgToRead',data);
}

//检查跳转目标是否被删除（绩效、建设任务、任务）
export function checkTargetIsDeleted(data){
    return post('/api/ss/api/v1/message/checkTargetIsDeleted',data);
}

//查询已读未读数量判断铃铛
export function unReadMsgCount(data){
    return post('/api/ss/api/v1/message/unReadMsgCount',data);
}





