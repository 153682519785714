import { post ,get} from '../../until/axios_instans';

//校级双高建设任务表分页查询
export function selectProjectPageList(data){
    return post('/api/ss/api/v1/project/selectProjectPageList',data);
}

//校级双高建设任务表信息查询
export function infoProjectById(data){
    return post('/api/ss/api/v1/project/infoProjectById',data);
}

//校级双高建设任务表保存
export function projectSave(data){
    return post('/api/ss/api/v1/project/projectSave',data);
}

//建设任务描述历史查询
export function selectProjectListHistory(data){
    return post('/api/ss/api/v1/project/selectProjectListHistory',data);
}

//更新状态描述
export function updateStatusDescribe(data){
    return post('/api/ss/api/v1/project/updateStatusDescribe',data);
}

//修改建设任务信息
export function updateProjectById(data){
    return post('/api/ss/api/v1/project/updateProjectById',data);
}

//修改建设任务操作
export function updateProjectOperation(data){
    return post('/api/ss/api/v1/project/updateProjectOperation',data);
}

//解散建设任务操作
export function deleteProject(data){
    return post('/api/ss/api/v1/project/deleteProject',data);
}

//新增管理组成员添加
export function groupSaveProject(data){
    return post('/api/ss/api/v1/project/groupSaveProject',data);
}

//建设任务管理人员查询
export function selectProjectManagerList(data){
    return post('/api/ss/api/v1/project/selectProjectManagerList',data);
}

//建设任务面板和动态
export function getProjectPanelAndTaskDynamic(data){
    return post('/api/ss/api/v1/task/getProjectPanelAndTaskDynamic',data);
}
//负责人下拉
export function selectUserList(data){
    return post('/api/ss/api/v1/project/selectUserList',data);
}
//建设任务数据总览
export function projectListOverview(data){
    return post('/api/ss/api/v1/project/projectListOverview',data);
}
//建设任务负责人列表
export function projectDirectorList(data){
    return post('/api/ss/api/v1/project/projectDirectorList',data);
}
//建设任务条件查询列表
export function projectConditionList(data){
    return post('/api/ss/api/v1/project/projectConditionList',data);
}
//查询文件列表
export function queryprojectFiles(data){
    return post('/api/ss/api/v1/document/queryprojectFiles',data);
}
//根据文件名称、大小和更新时间查询文件列表
export function queryFilesByCondition(data){
    return post('/api/ss/api/v1/document/queryFilesByCondition',data);
}
//重命名文件或文件夹
export function updateFileName(data){
    return post('/api/ss/api/v1/document/updateFileName',data);
}
//新建文件夹
export function createNewFolder(data){
    return post('/api/ss/api/v1/document/createNewFolder',data);
}
//删除文件或文件夹
export function removeFile(data){
    return post('/api/ss/api/v1/document/removeFile',data);
}
//批量删除文件或文件夹
export function removeFiles(data){
    return post('/api/ss/api/v1/document/removeFiles',data);
}
//文件批量下载
export function downFiles(data){
    return post('/api/ss/api/v1/document/downFiles',data);
}
//文件批量导出
export function exportFiles(){
    return get('/api/ss/api/v1/document/exportFiles');
}
//服务器文件下载
export function exportFileFromServer(data){
    return get('/api/ss/api/v1/document/exportFileFromServer',data);
}
//移动文件或文件夹
export function moveFile(data){
    return post('/api/ss/api/v1/document/moveFile',data);
}
//批量移动文件或文件夹
export function moveFiles(data){
    return post('/api/ss/api/v1/document/moveFiles',data);
}
//根据父id查询其下文件列表
export function queryFileListById(data){
    return post('/api/ss/api/v1/document/queryFileListById',data);
}
//文档查询所有文件夹
export function queryFileTreeQo(data){
    return post('/api/ss/api/v1/document/queryFileTreeQo',data);
}
//查询文件可见性成员列表
export function queryVisablePerson(data){
    return post('/api/ss/api/v1/document/queryVisablePerson',data);
}
//设置文件人员可见性
export function setVisablePerson(data){
    return post('/api/ss/api/v1/document/setVisablePerson',data);
}

//上传新文件
export function uploadNewFile(data){
    return post('/api/ss/api/v1/document/uploadNewFile',data);
}
//上传新文件
export function previewFile(data){
    return post('/api/ss/api/v1/document/previewFile',data);
}
//检验文件
export function checkFile(data){
    return post('/api/ss/api/v1/document/checkFile',data);
}
