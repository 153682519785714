/*建设任务管理*/
import React, {Component} from "react";
import {
    Form, Tabs
} from "antd";
import "./index.scss";
import Gaikuang from './components/gaikuang/index'
import RenWu from './components/renwu/index'
import FinancialDetails from './components/financialdetails'
import Wendang from './components/wendang/index'
import PerformanceListLook from '../performancelistLook/index'
import {withRouter} from "react-router";
import Cookies from "js-cookie";
const { TabPane } = Tabs;

@withRouter
class Project extends Component {
    state = {
        activeKey: this.props.location.returnFlag?this.props.location.returnFlag:'1',
        handis:true,
        rwtaskStatus: '',
        projectId: this.props.match.params.id,
    }

    componentDidMount() {
        console.log('projectId',this.state.projectId)

    }
    tiaozhuan = (e) =>{
        this.setState({activeKey: '2', rwtaskStatus: e})
    }
    tiaozhuanzj = () =>{
        this.setState({activeKey: '3'})
    }
    tabClickHandler = (params) => {
        let name = ''
        if(params === '1'){
            name = '概况'
        }else if(params === '2'){
            name = '任务'
        }else if(params === '3'){
            name = '资金'
        }else if(params === '4'){
            name = '绩效'
        }else if(params === '5'){
            name = '文档'
        }
        let breadcrumb = JSON.parse(Cookies.get('list2'))
        breadcrumb.forEach((item, index) => {
            if (item) {
                if (item.id === "191") {
                    breadcrumb.splice(index, 1)
                    breadcrumb.push({"id": '191', "menuName": name})
                    let end = breadcrumb.slice(-3);
                    Cookies.set('list2', breadcrumb)
                    this.props.history.push({
                        pathname: this.props.location.pathname,
                    })
                    this.tiaozhuan(params)
                }
            }

        })
        this.setState({
            activeKey:params,
            rwtaskStatus: ''
        })


    }
    render() {
        const {handis, activeKey, rwtaskStatus} = this.state
        return (
            <div className='xiangmuguanli'>
                <Tabs activeKey={activeKey} onTabClick={(params)=>this.tabClickHandler(params)}>
                    <TabPane tab={
                        <span className={'tabspan'}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.25 0.25V9.89336H13.75V0.25H0.25ZM11.8217 7.96504H2.17832V2.17832H11.8217V7.96504ZM0.25 13.75H13.75V11.8217H0.25V13.75ZM9.89336 4.10664H4.10664V6.03496H9.8916V4.10664H9.89336Z" fill="#333333"/>
                          </svg>
                            <span>概况</span>
                        </span>

                    }
                     key="1">
                        {activeKey === "1"?<Gaikuang tiaozhuan={this.tiaozhuan}></Gaikuang>:null}
                    </TabPane>

                    <TabPane tab={
                        <span className={'tabspan'}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.10664 0.25V2.17832H13.75V0.25H4.10664ZM0.25 2.17832H2.17832V0.25H0.25V2.17832ZM4.10664 6.03496H13.75V4.10664H4.10664V6.03496ZM0.25 6.03496H2.17832V4.10664H0.25V6.03496ZM4.10664 9.89336H13.75V7.96504H4.10664V9.89336ZM0.25 9.89336H2.17832V7.96504H0.25V9.89336ZM4.10664 13.75H13.75V11.8217H4.10664V13.75ZM0.25 13.75H2.17832V11.8217H0.25V13.75Z" fill="#333333"/>
                          </svg>

                          任务
                        </span>
                    }
                             key="2">
                        {activeKey === "2"?<RenWu rwtaskStatus={rwtaskStatus}></RenWu>:null}
                    </TabPane>

                    <TabPane tab={
                        <span className={'tabspan'}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.25C3.27168 0.25 0.25 3.27168 0.25 7C0.25 10.7283 3.27168 13.75 7 13.75C10.7283 13.75 13.75 10.7283 13.75 7C13.75 3.27168 10.7283 0.25 7 0.25ZM7 11.8217C4.33691 11.8217 2.17832 9.66309 2.17832 7C2.17832 4.33691 4.33691 2.17832 7 2.17832C9.66309 2.17832 11.8217 4.33691 11.8217 7C11.8217 9.66309 9.66309 11.8217 7 11.8217ZM4.27187 7L7 9.72813L9.72637 7L7 4.27187L4.27187 7Z" fill="#333333"/>
                            </svg>

                          资金
                        </span>
                    }
                             key="3">
                        {activeKey === "3"?<FinancialDetails tiaozhuanzj={this.tiaozhuanzj}></FinancialDetails>:null}
                    </TabPane>
                    <TabPane tab={
                        <span className={'tabspan'}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 5.07168V4.10664H3.14336V5.07168H7ZM3.14336 10.8566H5.07168V9.8916H3.14336V10.8566ZM2.17832 2.17832H7.96328V5.07168H9.8916V0.25H0.25V13.75H5.07168V11.8217H2.17832V2.17832ZM3.14336 7.96328H5.07168V7H3.14336V7.96328ZM9.89336 6.03496C7.76289 6.03496 6.03672 7.76113 6.03672 9.8916C6.03672 12.0221 7.76289 13.7482 9.89336 13.7482C12.0238 13.75 13.75 12.0238 13.75 9.89336C13.75 7.76289 12.0238 6.03496 9.89336 6.03496ZM9.89336 11.8217C8.82812 11.8217 7.96504 10.9586 7.96504 9.89336C7.96504 8.82812 8.82812 7.96504 9.89336 7.96504C10.9586 7.96504 11.8217 8.82812 11.8217 9.89336C11.8217 10.9586 10.9586 11.8217 9.89336 11.8217Z" fill="#333333"/>
                            </svg>

                          绩效
                        </span>
                    }
                             key="4">
                       <PerformanceListLook projectId={this.state.projectId}/>
                    </TabPane>
                    <TabPane tab={
                        <span className={'tabspan'}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.14336 10.8566H6.03672V9.8916H3.14336V10.8566ZM10.8566 7.96504C9.25879 7.96504 7.96328 9.26055 7.96328 10.8584C7.96328 12.4563 9.25879 13.7518 10.8566 13.7518C12.4545 13.7518 13.75 12.4545 13.75 10.8566C13.75 9.25879 12.4545 7.96504 10.8566 7.96504ZM10.8566 12.2207L9.49258 10.8566L10.8566 9.49258L12.2207 10.8566L10.8566 12.2207ZM6.03496 7H3.14336V7.96504H6.03672V7H6.03496ZM9.89336 4.10664H3.14336V5.07168H9.89336V4.10664ZM0.25 0.25V13.75H7V11.8217H2.17832V2.17832H11.8217V7H13.75V0.25H0.25Z" fill="#333333"/>
                            </svg>
                          文档
                        </span>
                    }
                             key="5">
                        {activeKey === "5"?<Wendang></Wendang>:null}
                    </TabPane>
                </Tabs>
            </div>

        );
    }
}

Project = Form.create()(Project);
export default Project;
