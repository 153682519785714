import { post } from '../../until/axios_instans';

//分配用户

//添加用户列表到角色中
export function addUserToRole(data){
    return post('/api/ss/api/v1/sysuserrole/addUserToRole',data);
}
//移除角色下用户
export function deleteById(data){
    return post('/api/ss/api/v1/sysuserrole/deleteById',data);
}
//查询用户列表by组织机构、用户名
export function getUserListByNameOrg(data){
    return post('/api/ss/api/v1/sysuserrole/getUserListByNameOrg',data);
}
//角色下用户分页列表
export function roleUserpageList(data){
    return post('/api/ss/api/v1/sysuserrole/roleUserpageList',data);
}
//角色下用户列表
export function roleUserList(data){
    return post('/api/ss/api/v1/sysuserrole/roleUserList',data);
}




