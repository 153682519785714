/*院校管理*/
import React, {Component} from "react";
import {
    Popover,
    Button,
    Table,
    Select,
    Modal,
    Form,
    Icon,
    Tabs,
    Avatar,
    Tooltip,
    Mentions,
    Input,
    Cascader, message, Drawer, Dropdown, Menu
} from "antd";
import style from "./index.module.scss";
import {withRouter} from "react-router";
import {
    activeTaskById,
    addTask,
    addTaskDocumentList,
    cancelTaskDocumentList,
    deleteTaskById,
    finishTaskById,
    getTaskDocumentList,
    getTaskProjectMemberList,
    getTaskzDynamicList,
    stopTaskById,
    updateTaskById,
    updateTaskDirectorById,
    getTaskSimpleById,
    getTaskOneLevelList,
    modifyChildTaskToSingleTask,
    taskTransfer,
    addTaskMeritList,
    cancleTaskMerit,
    seclectTaskMeritList,
    seclectTaskMeritListExcludeExist,
    addTaskUserList,
    cancleTaskUser,
    selectTaskUserList,
    selectTaskUserListExcludeExist,
    queryTaskComments,
    addComment,
    deleteComment,
    getAspectAndIndicator,
    getTaskInvestAndExpendByTaskId,
    queryTaskCommentImages,
    queryComment,
    urgeTaskById,
    startTaskById
} from "../../../../../api/renwu"

import AddNewTask from "../components/addNewTask";
import AssociatedDocumentation from "./components/associatedDocumentation";
import ChangeChildTask from "./components/changeChildTask";
import RelatedContent from './components/relatedContent/index'//关联内容文档
import RelatedContentjx from './components/relatedContentjx/index'//关联内容绩效
import S from "gongyongicon";
import {
    TaskType, TaskPriority, TaskPrincipal, SchedulePlanning, Description, AssociatedMoney, ContextualPerformance,
    AssociatedDocument, QuestionMark, Add, SendImages, DeleteImg, RedDelete
} from '../../../../../assets/svg'
import UploadImg from "../../../../../components/upload_img";
import RenderImg from "../../../../../components/renderImg";
import Cookies from "js-cookie";
import {previewFile} from "../../../../../api/project";
import Readdetailist from "../../../../readdetails";
import {ImageIcon} from '../../../../../assets/svg'
import GongyongImagePreview from '../../../../../components/GongyongImagePreview'
const {TabPane} = Tabs;
const {Option} = Mentions;

@withRouter
class Operation extends Component {
    state = {
        userId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        taskcommentList: [],//任务评论列表
        dataSource: [],
        columns: [],
        defaultExpandAllRows: true,
        btnStyle: 1,//1顶部按钮默认样式2选中样式
        addVisable: false,//新建modal
        deleteCommentVisable: false,
        deleteCommentItem: '',
        complete: false,
        cancelLink: false,
        associatedFile: false,
        csFlag: 1,//1完成任务2暂停任务
        independent: false,//独立任务modal
        taskId: this.props.match.params.taskId,
        projectId: this.props.match.params.projectId,
        projectName: "",
        popView: false,//更多任务-展示
        popViewChi: false,//子任务-展示q
        suspendedVisable: false,//暂停任务Modal
        completeVisable: false,//完成任务Modal
        startVisable: false,//开始任务Modal
        urgeVisable: false,//催办任务Modal
        deleteTaskVisable: false,//删除任务Modal
        updateTaskVisable: false,//编辑任务Modal
        addTaskVisable: false,//添加任务Modal
        activeVisable: false,//暂停任务Modal
        deleteTaskChildVisable: false,//删除子任务Modal
        taskTransferType: 9,//9:转化为子任务,10:变更父任务
        taskTransferTypeTitle: '',//9:转化为子任务,10:变更父任务
        show: 0,
        taskInfo: {
            endTime: "",
            beginTime: "",
            humanName: "",
            directorId: "",
            portrait: "",
            taskCode: "",
            taskDescribe: "",
            taskName: "",
            taskPriority: "",
            taskPriorityName: "",
            taskStatus: "",
            taskStatusName: "",
            childrenTask: [],
            projectName: "",
            isNew: 0,
            isStart: 0,
            statYear: null,
            parentId: '0',
        },
        taskFormInfo: {},
        taskFormTitle: "",
        taskFormOption: 1,//1.编辑任务、2.添加子任务、3.编辑子任务
        editTaskData: {},
        taskDynamicList: "",
        taskChildId: "",
        isEditTaskChild: false,
        taskPriorityOptions: [
            {'label': '较低', 'value': 1},
            {'label': '普通', 'value': 2},
            {'label': '紧急', 'value': 3},
            {'label': '非常紧急', 'value': 4},
        ],
        taskStatusOptions: [
            {'label': '进行中', 'value': 1},
            {'label': '已暂停', 'value': 2},
            {'label': '已完成', 'value': 3},
            {'label': '已逾期', 'value': 4},
            {'label': '未开始', 'value': 5},
            {'label': '未开始', 'value': 0},
        ],
        taskDocumentList: [],
        taskOneLevel: [],
        cancelTaskDocumentList: [],
        projectMemberList: [],
        claimUserModal: false,
        openVal: true,
        updateDirectorId: '',
        statusButten: true,
        prefix: '@',
        taskUserModal: false,
        notaskUserList: [],//还未是参与人列表
        willAddTaskUserList: [],//要加添的参与人列表
        groupUsers: [],//参与人列表
        groupUsersCount: 0,//参与人数
        lookGroupUser: false,//查看参与人详情
        relatedContentModal: false,//关联内容modal
        noTaskMeritListColumn: [
            {
                title: '绩效ID',
                dataIndex: 'meritCode',
                key: 'meritCode',
                width: 240
            },
            {
                title: '绩效名称',
                dataIndex: 'meritName',
                key: 'meritName',
            },
        ],
        noTaskMeritData: [],//未关联绩效列表
        willTaskMeritData: [],//要加添的关联绩效列表
        taskMeritData: [],//关联绩效列表
        cancelMeritLinkModal: false,//取消关联绩效提示框
        expandedKeys: '',
        treeList: [
            {
                id: 1,
                title: '111',
                children: [
                    {
                        id: 11,
                        title: '111111'
                    },
                    {
                        id: 12,
                        title: '111222'
                    },
                ]
            },
            {
                id: 2,
                title: '2222'
            },
        ],
        searchValue: '',
        autoExpandParent: true,
        selectedUsers: [],//奖金选中的数据
        selectedUserRow: [],//弹窗选中的数据
        columnsTable: [
            {
                title: '111',
                dataIndex: 'humanName',
                key: 'humanName',
                align: 'center',
            },
            {
                title: '222',
                key: 'phone',
                dataIndex: 'phone',
                align: 'center',
            },
            {
                title: '333',
                key: 'orgName',
                dataIndex: 'orgName',
                align: 'center',
            },

        ],
        projectUserList: [
            {
                id: 1,
                humanName: '111',
                phone: '999'
            },
            {
                id: 2,
                humanName: '222',
                phone: '444'
            }
        ],
        RelatedContentKey: '1',//关联内容tabs当前值
        imgList: [],//评论图片列表
        popoverShow: 0,//参与人Popover显示
        commentMessage: '',//评论信息
        atUserList: [],//@的人列表
        // relatedPic:[],//评论图片
        aspectAndIndicatorTree: [],//层面、指标树结构
        commentMessageDefault: "",//评论默认值
        meritName: '',//关联内容绩效查询绩效名称
        aspectAndIndicatorValue: [],//关联内容绩效查询指标值
        openSelectOption: true,
        onPush:false,
        record: '',//删除文件存值
        yulanrecord: [],//预览更多用到的数据
        previewFileRes: [],//
        userInfoId: JSON.parse(Cookies.get('ssInfo')).userInfo.id,
        userInfoisAdmin: JSON.parse(Cookies.get('ssInfo')).userInfo.isAdmin,
        jxId: 0,//从绩效进来的绩效id,返回用
        fundInfo:{
            investAmountSum:0,//预算金额
            expendAmountSum:0,//实际支使用金额（元）
            useRate:0,//资金执行率
        },
        showImagevisible: false, //评论查看图片弹窗
        imagePreviewVisible: false,
        imagePreviewIndex: 0,
        showImageList:[],
        currentYear:new Date().getFullYear(),
        currentMonth:new Date().getMonth()+1 >=10?new Date().getMonth()+1:'0'+ (new Date().getMonth()+1),
    }

    // 初始化
    componentDidMount() {
        this.getTaskDocumentList()
        this.getTaskSimpleById()
        this.getTaskzDynamicList()
        this.getTaskOneLevelList()
        this.selectTaskUserList()
        this.seclectTaskMeritList()
        this.getTaskProjectMemberList()
        this.getAspectAndIndicator()
        this.getTaskInvestAndExpendByTaskId()
        // document.getElementsByClassName('pingluncontul').scrollTop=document.getElementsByClassName('pingluncontul')[0].scrollHeight
        let pathname = this.props.location.pathname
        let pathname1 = pathname.substring(pathname.lastIndexOf("?")+1)
        if(pathname.indexOf('?') != -1){
            this.setState({jxId: pathname1})
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps',nextProps)
        nextProps.match.params.taskId !== this.props.match.params.taskId && this.setState({
            taskId: nextProps.match.params.taskId,
            projectId: nextProps.match.params.projectId
        }, () => {
            this.refreshPage()
        })
    }

    getAspectAndIndicator = async () => {
        await getAspectAndIndicator().then(res => {
            this.setState({aspectAndIndicatorTree: res})
        })
    }
    // region 一
    //关联资金 信息
    getTaskInvestAndExpendByTaskId = async () => {
        let data = {
            taskId: this.state.taskId,
        }
        await getTaskInvestAndExpendByTaskId(data).then(res => {
            this.setState({fundInfo:res})
        })
    }
    updateTaskDirectorById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            directorId: this.state.updateDirectorId
        }
        await updateTaskDirectorById(data).then(res => {
            this.getTaskSimpleById()
            this.getTaskzDynamicList()
            this.mesSuccess("负责人设置成功")
        })
    }
    refreshPage = () => {
        this.getTaskDocumentList()
        this.getTaskSimpleById()
        this.getTaskzDynamicList()
        this.getTaskOneLevelList()
        this.selectTaskUserList()
        this.seclectTaskMeritList()
        this.queryTaskComments()
        this.getTaskProjectMemberList()
        this.getAspectAndIndicator()
    }
    wendangRefreshPage = () => {
        this.getTaskDocumentList()
        this.getTaskzDynamicList()
    }
    taskRefreshPage = () => {
        this.getTaskSimpleById()
        this.getTaskzDynamicList()
    }
    childTaskRefreshPage = () => {
        this.getTaskOneLevelList()
    }
    // 查询建设任务成员列表
    getTaskProjectMemberList = async () => {
        let data = {
            projectId: this.state.projectId,
        }
        await getTaskProjectMemberList(data).then(res => {
            this.setState({projectMemberList: res})
        })
    }


    onUserChange = (val) => {
        this.setState({updateDirectorId: val})
    }
    // region api
    // 查询任务详情
    getTaskSimpleById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await getTaskSimpleById(data).then(res => {
            let taskPriorityName = this.getTaskPriorityName(res.taskPriority)
            let taskStatusName = this.getTaskStatusName(res.taskStatus)
            this.setState({
                taskInfo: {
                    endTime: res.endTime ? res.endTime.split("T")[0] : "",
                    beginTime: res.beginTime ? res.beginTime.split("T")[0] : "",
                    humanName: res.humanName,
                    directorId: res.directorId,
                    portrait: res.portrait,
                    taskCode: res.taskCode,
                    taskDescribe: res.taskDescribe,
                    taskName: res.taskName,
                    taskLevel: res.taskLevel,
                    taskPriority: res.taskPriority,
                    taskStatus: res.taskStatus,
                    childrenTask: res.children,
                    projectId: res.projectId,
                    taskId: res.id,
                    projectName: res.projectName,
                    taskPriorityName: taskPriorityName,
                    taskStatusName: taskStatusName,
                    isNew: res.isNew,
                    isStart: res.isStart,
                    statYear: res.statYear,
                    parentId: res.parentId,
                },
                statYear: res.statYear,
                projectName: res.projectName,
            })
        })
    }
    // 添加子任务
    addTask = async (data) => {
        await addTask(data).then(res => {
            this.mesSuccess("添加子任务成功")
            this.refreshPage()
        })

    }

    // 查询任务动态
    getTaskzDynamicList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await getTaskzDynamicList(data).then(res => {
            this.setState({taskDynamicList: res})

        })
    }
    //完成任务
    finishTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await finishTaskById(data).then(res => {
            this.mesSuccess("完成任务成功")
            this.refreshPage()
        })

    }
    //开始任务
    startTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await startTaskById(data).then(res => {
            this.mesSuccess("开始任务成功")
            this.refreshPage()
        })
    }
    //催办任务
    urgeTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await urgeTaskById(data).then(res => {
            this.mesSuccess("催办任务成功")
            this.refreshPage()
        })
    }
    //暂停任务
    stopTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await stopTaskById(data).then(res => {
            this.mesSuccess("暂停任务成功")
            this.refreshPage()
        })

    }
    //激活任务
    activeTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await activeTaskById(data).then(res => {
            this.mesSuccess("激活任务成功")
            this.refreshPage()
        })

    }
    //编辑任务
    updateTaskById = async (data) => {
        await updateTaskById(data).then(res => {
            this.totalCancel()
            this.mesSuccess("编辑任务成功")
            this.refreshPage()
        })

    }
    //删除任务
    deleteTaskById = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await deleteTaskById(data).then(res => {
            this.mesSuccess("删除任务成功")
            this.props.history.push({
                pathname: '/main/doublehigh/project/' + this.state.projectId,
                returnFlag: '2'
            })
        })

    }
    //任务关联文档列表
    getTaskDocumentList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await getTaskDocumentList(data).then(res => {
            this.setState({taskDocumentList: res})
        })
    }
    //任务下一级所有任务列表
    getTaskOneLevelList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await getTaskOneLevelList(data).then(res => {
            this.setState({taskOneLevel: res})
        })
    }
    // 取消 任务与文档关联
    cancelTaskDocumentList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            documentIds: this.state.cancelTaskDocumentList,
        }
        await cancelTaskDocumentList(data).then(res => {
            this.mesSuccess("取消文档关联成功")
            this.refreshPage()
        })

    }
    // 变更为独立任务
    modifyChildTaskToSingleTask = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await modifyChildTaskToSingleTask(data).then(res => {
            this.mesSuccess("转化为独立任务成功")
            this.refreshPage()
        })

    }
    //转化任务
    taskTransfer = async (data) => {
        await taskTransfer(data).then(res => {
            this.mesSuccess(this.state.taskTransferTypeTitle + "成功")
            this.refreshPage()
        })

    }
    // 关联文档
    addTaskDocumentList = async (ids) => {
        let data = {
            projectId: this.state.projectId,
            id: this.state.taskId,
            documentIds: ids,
        }
        await addTaskDocumentList(data).then(res => {
            this.mesSuccess("文档关联成功")
            this.totalCancel()
            this.refreshPage()
        })

    }


    // endregion

    gotoTool = () => {

    }


    getTaskPriorityName = (value) => {
        let temp = ""
        this.state.taskPriorityOptions.map((item, index) => {
            if (item.value === value) {
                temp = item.label
            }
        })
        return temp
    }
    getTaskStatusName = (value) => {
        let temp = ""
        this.state.taskStatusOptions.map((item, index) => {
            if (item.value === value) {
                temp = item.label
            }

        })
        return temp
    }
    changeBtnStyle = () => {
    }
    //完成任务按钮
    startTask = () => {
        this.setState({
            startVisable: true,
            csFlag: 1,
        })
    }
    //开始任务按钮ok
    startOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.startTaskById().then(res => {
            })
            this.totalCancel()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    urgeTask = () => {
        //this.state.taskInfo.directorId ===  this.state.userInfoId
        this.setState({
            urgeVisable: true,
            csFlag: 1,
        })
    }
    //催办任务按钮ok
    urgeOk = () => {
        this.urgeTaskById().then(res => {
            this.totalCancel()
        })
    }
    //催办任务按钮cancel
    hideModal = () => {
        this.totalCancel()
    };

    //完成任务按钮
    tocomplete = () => {
        this.setState({
            completeVisable: true,
            csFlag: 1,
        })
    }
    //完成任务按钮ok
    tocompleteOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.finishTaskById().then(res => {
            })
            this.totalCancel()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    //暂停任务
    suspendedTask = () => {
        this.setState({
            suspendedVisable: true,
        })
    }
    //暂停任务ok
    suspendedTaskOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.stopTaskById().then(() => {
                this.totalCancel()
            })
            this.totalCancel()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);

    }
    //激活任务
    activeTask = () => {
        this.setState({
            activeVisable: true,
        })
    }
    //激活任务ok
    activeTaskOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.activeTaskById().then(() => {
                this.totalCancel()
            })
            this.totalCancel()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    //编辑任务、添加子任务、修改子任务
    updateTask = (value) => {
        // value:1.编辑任务、2.新增子任务，3.编辑子任务
        if (value === 1) {
            this.setState({
                popViewChi: false,
                updateTaskVisable: true,
                taskFormTitle: "编辑任务",
                taskFormOption: 1,
            })
        }
        if (value === 2) {
            this.setState({
                popViewChi: false,
                updateTaskVisable: true,
                taskFormTitle: "添加子任务",
                taskFormOption: 2,
            })
        }
        if (value === 3) {
            this.setState({
                popViewChi: false,
                updateTaskVisable: true,
                taskFormTitle: "编辑子任务",
                taskFormOption: 3,
            })
        }
    }
    updateChildTask = (taskId) => {
        this.state.taskChildId = taskId
        this.updateTask(3)
    }
    //编辑任务Ok
    updateTaskOk = () => {
        console.log("查看数据2：", this.state.statYear)
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            let data = this.addNewTaskComponent.getFormValue()
            if (data) {
                let newData = {}
                if (this.state.taskFormOption === 2) {
                    let newData = {
                        directorId: data.directorId,
                        taskDescribe: data.taskDescribe,
                        taskPriority: data.taskPriority,
                        parentId: this.state.taskId,
                        projectId: this.state.projectId,
                        beginTime: data.beginTime,
                        endTime: data.endTime,
                        taskName: data.taskName,
                        statYear: this.state.statYear
                    }
                    this.addTask(newData).then(res => {
                        this.refreshPage()
                        this.totalCancel()
                    })
                } else {
                    newData = data
                    this.updateTaskById(newData).then(res => {

                    })
                }
            }
        }
        setTimeout(() => {   // 设置延迟事件，5秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }

    showModal = () => {
        this.setState({
            complete: true,
        });
    };

    hideModal = () => {
        this.totalCancel()
    };

//是否取消关联？
    IshidecancelLink = (documentId) => {
        let documentIds = []
        documentIds.push(documentId)
        this.state.cancelTaskDocumentList = documentIds
        this.setState({
            cancelLink: true,
        });
    };
    //确认取消关联
    hidecancelLink = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.cancelTaskDocumentList()
            this.setState({
                cancelLink: false,
            });
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    };


    //返回主页面
    backTask = () => {
        if(this.state.jxId !== 0){
            this.props.history.push({pathname: '/main/doublehigh/performancedetails/'+ this.state.jxId})
        }else{
            Cookies.set('taskStateYear', this.state.taskInfo.statYear);
            this.props.history.push({pathname: '/main/doublehigh/project/' + this.state.projectId, returnFlag: '2'})
        }
    };

    //关联文件
    associatedFile = () => {
        this.setState({associatedFile: true})
    }

    //文件关联取消btn
    associatedFileCancel = () => {
        this.setState({associatedFile: false})
        console.log('取消关联文件')
    }

    //新建————确认按钮
    addTaskOk = () => {
        console.log("查看数据1：", this.state.statYear)
        let taskName = this.props.form.getFieldValue('taskName')
        let taskPriority = this.props.form.getFieldValue('taskPriority')
        let directorId = this.props.form.getFieldValue('directorId')
        let taskDescribe = this.props.form.getFieldValue('taskDescribe')

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data = {
                    projectId: this.state.projectId,
                    taskName: taskName,
                    taskPriority: taskPriority,
                    beginTime: this.state.beginTime ? this.state.beginTime.format('YYYY-MM-DD HH:mm:ss') : "",
                    endTime: this.state.endTime ? this.state.endTime.format('YYYY-MM-DD HH:mm:ss') : "",
                    directorId: directorId,
                    taskDescribe: taskDescribe,
                    statYear: this.state.statYear
                }
                this.addTask(data).then(() => {
                    this.setState({addVisable: false})
                    this.getTaskPageList()
                })

            }

        });

    }

    //新建/编辑————取消按钮
    addHandleCancel = () => {
        this.setState({addVisable: false, count: 0})
    }

    //转化独立任务?
    changeIndependent = () => {
        // this.props.form.resetFields();
        this.setState({
            popView: false,
            independent: true,

        })
    }
    //转化独立任务!
    changeIndependentOK = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.modifyChildTaskToSingleTask()
            this.setState({independent: false})
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    //先 不 转化独立任务!
    changeIndependentCancel = () => {
        // this.props.form.resetFields();
        this.setState({independent: false})
    }

    //删除任务
    deleteTask = () => {
        this.setState({
            popView: false,
            popViewChi: false,
            deleteTaskVisable: true,
        })
    }
    //删除子任务
    deleteTaskChild = (id, isNew) => {
        if (isNew === 0) {
            this.mesWarning("同步的任务，不能被删除")
            this.setState({deleteTaskChildVisable: false, popView: false, popViewChi: false})
            return false

        }
        this.setState({deleteTaskChildId: id, deleteTaskChildVisable: true, popView: false, popViewChi: false})
    }

    //删除任务Ok
    deleteTaskOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.deleteTaskById().then(() => {
            })
            this.setState({deleteTaskVisable: false})
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    //删除子任务Ok
    deleteTaskChildOk = async () => {
        let data = {
            id: this.state.deleteTaskChildId,
            projectId: this.state.projectId,
        }
        this.totalCancel()
        await deleteTaskById(data).then(res => {
            this.mesSuccess("删除任务成功")
            this.refreshPage()
        })

    }

    handleVisibleChange = popView => {
        this.setState({popView});
    };

    handleVisibleChangeChild = (popViewChi, index) => {
        this.setState(
            {
                popViewChi,
                show: index,
            });
    };

    totalCancel = () => {
        this.setState({
            popView: false,//更多任务展示
            popViewChi: false,//子任务操作
            suspendedVisable: false,//暂停任务Modal
            completeVisable: false,//完成任务Modal
            startVisable: false,//开始任务Modal
            urgeVisable: false,//催办任务Modal
            deleteTaskVisable: false,//删除任务Modal
            updateTaskVisable: false,//编辑任务Modal
            addTaskVisable: false,//添加任务Modal
            activeVisable: false,//暂停任务Modal
            associatedFile: false,//关联文档Modal
            deleteTaskChildVisable: false,//删除子任务Modal
            deleteCommentVisable: false,
            cancelLink: false,
            taskFormInfo: {},
            willTaskMeritData: [],//关联内容——选中关联绩效
            relatedContentModal: false,//关联内容Modal
            RelatedContentKey: '1',//关联内容Tab
            meritName: "",//关联内容——绩效名称搜索
            aspectAndIndicatorValue: []//关联内容——绩效指标搜索
        })

    }

    //转化为子任务、变更父任务
    changeChildTask = (type) => {
        this.setState({
            popView: false,
            changeChildTask: true,
            taskTransferType: type,
            taskTransferTypeTitle: type === 9 ? '转化为子任务' : '变更父任务',
        })
    }

    changeChildTaskOK = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            // this.props.form.resetFields();
            let taskId = this.changeChildTaskComp.getValues()
            if (taskId === '') {
                return false
            }
            let data = {
                projectId: this.state.projectId,
                parentId: taskId,
                id: this.state.taskId,
                type: this.state.taskTransferType
            }
            this.taskTransfer(data)
            this.setState({
                changeChildTask: false,
            })
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);

    }
    changeChildTaskCancel = () => {
        this.setState({
            changeChildTask: false,

        })
    }
    getTaskChildInfo = (id) => {
        this.props.history.push({pathname: '/main/doublehigh/operation/' + this.state.projectId + "/" + id})
        this.state.taskId = id
        this.refreshPage()
    }
    associatedFileOk = () => {
        let selectDocumentIds = this.associatedDocumentation.getValues()
        if (selectDocumentIds.length > 0) {
            this.addTaskDocumentList(selectDocumentIds)
        } else {
            this.mesWarning("请选择关联的文档")
            return false
        }
    }
    getIcon = (isDir, fileType) => {
        if (isDir === 1) {
            return <img src={require('@/assets/imgnew/wenjianjia.png')}/>
        } else if (fileType === 1) {
            return <img src={require('@/assets/imgnew/word.png')}/>
        } else if (fileType === 2) {
            return <img src={require('@/assets/imgnew/ppt.png')}/>
        } else if (fileType === 3) {
            return <img src={require('@/assets/imgnew/excel.png')}/>
        } else if (fileType === 4) {
            return <img src={require('@/assets/imgnew/shipin.png')}/>
        } else if (fileType === 5) {
            return <img src={require('@/assets/imgnew/yinpin.png')}/>
        } else if (fileType === 6) {
            return <img src={require('@/assets/imgnew/image.png')}/>
        } else if (fileType === 7) {
            return <img src={require('@/assets/imgnew/pdf.png')}/>
        } else {
            return <img src={require('@/assets/imgnew/weizhi.png')}/>
        }
    }

    getClaimUser = () => {
        // console.log("222222222222222")
        // setTimeout(()=>{
        //     this.setState({
        //         openVal:true
        //     })
        // },300)
        this.setState({
            claimUserModal: true,

        })
    }

    claimUserOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            if (!this.state.updateDirectorId) {
                this.mesError("请选择负责人")
                return false
            }
            this.updateTaskDirectorById()
            this.setState({
                claimUserModal: false,
                // openVal:false
            })
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }

    claimUserCancel = () => {
        this.setState({
            claimUserModal: false,
            // openVal:false

        })
    }

    tipDelMes = () => {
        this.mesWarning("该文档已被删除")
        return false
    }

    // 上传图片
    handleImgChange = (fileId, mediaType, formName, fId) => { // 上传图片
        const list = this.state.imgList
        let obj = {
            fileId,
            mediaType,
            formName,
            fId
        }
        list.push(obj)
        // this.state.relatedPic.push(fileId)
        this.setState({imgList: list,})
        console.log('imgList', list)
    }

    // 评论内容
    remarkChange = (e) => {
        e.persist()
        const value = e.target.value
        if (value.indexOf('@') !== -1) {
        }
    }
    onSearch = (_, prefix) => {
        this.setState({prefix});
    };
    // endregion
    // 任务详情-查询全部评论
    queryTaskComments = async () => {
        let data = {
            schoolId: this.state.schoolId,
            taskId: this.state.taskId,
            userId: this.state.userId
        }
        await queryTaskComments(data).then(res => {
            this.setState({taskcommentList: res.result}, () => {
                if (document.getElementById('pingluncont')) {
                    document.getElementById('pingluncont').scrollTo(0, document.getElementById('pingluncont').scrollHeight)
                }
            })
        })
    }
    //新增评论
    addComment = async () => {
        console.log('this.state.atUserList', this.state.atUserList)
        let imgList = this.state.imgList
        let imgAddreessList = []
        for (let i = 0; i < imgList.length; i++) {
            imgAddreessList.push(imgList[i].fileId)
        }
        let data = {
            reviewerId: this.state.userId,//评论发表人
            schoolId: this.state.schoolId,//学校ID
            taskId: this.state.taskId,//任务ID
            projectId: this.state.projectId,//项目ID
            reviewDetail: this.state.commentMessage,//评论详情
            relatedPic: imgAddreessList.length > 0 ? imgAddreessList.join(',') : '',//评论关联图片（最多5张）传送多个图片时，将id以“,”隔开的字符串即可
            relatedUserId: this.state.atUserList.length > 0 ? this.state.atUserList.join(',') : '',//@人员id，多个使用“,”分开"
        }
        console.log('data', data)
        // return false
        await addComment(data).then(res => {
            this.mesSuccess("评论成功")
            this.props.form.setFieldsValue({
                commentMessage: '',//清空评论内容
            })
            this.setState({atUserList: [], commentMessageDefault: '', imgList: []})
            this.queryTaskComments().then(res => {
            })
        })


    }
    //删除评论
    deleteComment = async () => {
        let item = this.state.deleteCommentItem
        if (item.reviewerId !== this.state.userId) {
            this.mesWarning('非本人发布评论，无法删除');
        }
        let data = {
            reviewerId: this.state.userId,
            commentId: item.id
        }
        await deleteComment(data).then(res => {
            if (res.code === 1000) {
                this.mesError(res.message);
            }
            if (res.code === 0) {
                this.mesSuccess('评论删除成功');
            }
            this.queryTaskComments()
        })
        this.setState({deleteCommentVisable: false})

    }
    //查询 评论图片
    queryTaskCommentImages = async () => {
        await queryTaskCommentImages({taskId:this.state.taskId}).then(res => {
            if(res){
                var showImageKeyList = res?Object.keys(res).sort().reverse():[]
                var showImageValueList = []
                if(showImageKeyList){
                    showImageKeyList.map((item,index)=>{
                        for (let i = 0; i < res[item].length ; i++) {
                            let obj = {id: res[item][i]}
                            showImageValueList.push(obj)
                        }
                    })
                }
                this.setState({showImageList:res?res:[],showImageKeyList:showImageKeyList,showImageValueList:showImageValueList,showImagevisible: true},()=>{
                })
            }


        })
    }
    //查询 评论by评论id
    queryComment = async (item,index) => {
        await queryComment({id:item.id}).then(res => {
            if(res.result){
                let showImageValueList = []
                if(res.result.relatedPic){
                    let relatedPicList = res.result.relatedPic.split(",")
                    for (let i = 0; i < relatedPicList.length ; i++) {
                        let obj = {id: relatedPicList[i]}
                        showImageValueList.push(obj)
                    }
                }
                this.setState({showImageValueList:showImageValueList,imagePreviewVisible: true,imagePreviewIndex:index},()=>{
                })
            }
        })
    }
    //删除评论
    deleteCommentOk = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.deleteComment()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    //添加任务绩效
    addTaskMeritList = async () => {
        if (this.state.willTaskMeritData.length <= 0) {
            this.mesWarning("请选择绩效")
            return false
        }
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            meritIds: this.state.willTaskMeritData
        }
        await addTaskMeritList(data).then(res => {
            this.mesSuccess("关联绩效成功")
            this.seclectTaskMeritList()
            this.getTaskzDynamicList()
            this.totalCancel()
        })

    }
    //取消任务绩效
    cancleTaskMerit = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            meritId: this.state.cancleTaskMeritId
        }
        await cancleTaskMerit(data).then(res => {
            this.mesSuccess("取消绩效关联成功")
        })

    }
    //查看任务绩效列表
    seclectTaskMeritList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
        }
        await seclectTaskMeritList(data).then(res => {
            this.setState({taskMeritData: res})
        })
    }
    //查看任务绩效列表，不包括关联
    seclectTaskMeritListExcludeExist = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            aspectAndIndicators: this.state.aspectAndIndicatorValue,
            meritName: this.state.meritName
        }
        await seclectTaskMeritListExcludeExist(data).then(res => {
            this.setState({noTaskMeritData: res})
        })
    }
    //添加任务参与人
    addTaskUserList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId,
            userIds: this.state.willAddTaskUserList
        }
        await addTaskUserList(data).then(res => {
            this.mesSuccess("参与人添加成功")
        })
    }
    //取消任务参与人
    cancleTaskUser = async (userId) => {
        let data = {userId: userId, id: this.state.taskId, projectId: this.state.projectId}
        await cancleTaskUser(data).then(res => {
            this.mesSuccess("移除成功")
        })

    }
    //查询任务参与人列表
    selectTaskUserList = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId
        }
        await selectTaskUserList(data).then(res => {
            this.setState({groupUsers: res, groupUsersCount: res.length})
        })
    }
    //查看项目成员，不包括任务参与人列表
    selectTaskUserListExcludeExist = async () => {
        let data = {
            id: this.state.taskId,
            projectId: this.state.projectId
        }
        await selectTaskUserListExcludeExist(data).then(res => {
            this.setState({notaskUserList: res})
        })
    }
    //移除参与者
    removeUser = (userId) => {
        this.cancleTaskUser(userId).then(res => {
            this.setState({lookGroupUser: false, popoverShow: 0})
            this.selectTaskUserList().then(res => {
                this.refreshPage()
            })
        })

    }
    // 关联内容确定
    RelatedConfirm = () => {
        console.log('this.state.statusButten1',this.state.statusButten)
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            if (this.state.RelatedContentKey === '1') {//文档
                this.associatedFileOk()
            }
            if (this.state.RelatedContentKey === '2') {//绩效

                this.addTaskMeritList().then(res => {
                })
            }
            if (this.state.RelatedContentKey === '3') {//资金

            }
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            console.log('this.state.statusButten2',this.state.statusButten)
            this.setState({statusButten: true})   // 将isChange设置为true
            console.log('this.state.statusButten3',this.state.statusButten)
        }, 1000);

    }
    //关联内容切换
    RelatedContentChange = (key) => {
        this.setState({RelatedContentKey: key})
        console.log('key', key)
        if (key === '1') {//文档

        }
        if (key === '2') {//绩效
            this.seclectTaskMeritListExcludeExist()
        }
    }
    //评论图片删除
    deleteimg = (fileId, fId) => {
        console.log('fileId,fId', fileId, fId)
        const imgList = this.state.imgList
        imgList.map((item, index) => {
            if (fileId === item.fileId) {
                imgList.splice(index, 1)
                // this.state.relatedPic.splice(index,1)
                this.setState({imgList})
            }
        })
    }

    taskUserOk = () => {
        if(this.state.willAddTaskUserList.length <=0){
            this.mesWarning("请选择任务参与人")
            return false
        }
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.addTaskUserList().then(res => {
                this.setState({taskUserModal: false,willAddTaskUserList:[]})
                this.selectTaskUserList().then(res => {
                    this.refreshPage()
                })
            })
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    // 选中添加任务参与人
    handleChange = (e) => {
        this.setState({willAddTaskUserList: e})
    }
    showTaskUserModal = () => {
        this.setState({notaskUserList: []})
        //获取项目组成员，不包括当前任务参与人
        this.selectTaskUserListExcludeExist().then(res => {
            this.setState({taskUserModal: true})
        })
    }

    showCancleTaskMerit = (cancleTaskMeritId) => {
        console.log('cancleTaskMeritId', cancleTaskMeritId)
        this.setState({
            cancelMeritLinkModal: true,
            cancleTaskMeritId: cancleTaskMeritId
        })
    }
    okMeritLink = () => {
        this.cancleTaskMerit().then(res => {
            this.setState({
                cancelMeritLinkModal: false,
                cancleTaskMeritId: ""
            })
            this.refreshPage()
        })
    }
    okCancelMeritLink = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            this.cancleTaskMerit().then(res => {
                this.refreshPage()
            })
            this.setState({
                cancelMeritLinkModal: false,
                cancleTaskMeritId: ""
            })
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);
    }
    handlePeopelVisibleChange = (visible, num) => {
        this.setState({lookGroupUser: visible, popoverShow: num})
    }
    showRelatedContentModal = () => {
        this.setState({relatedContentModal: true,})
    }

    sendMessage = () => {
        if (this.state.statusButten) {
            this.setState({statusButten: false})
            console.log('this.state.atUserList', this.state.atUserList)

            let groupUsers = this.state.groupUsers //参与人
            let commentMessage = this.state.commentMessageDefault // 评论内容

            if (commentMessage === undefined) {
                this.mesWarning("评论内容不能为空")
                return false
            }
            let count = 0
            let atUserIds = []
            //判断@人的个数
            for (let i = 0; i < groupUsers.length; i++) {
                //@aaa
                let st = '@' + groupUsers[i].humanName + ' '
                if (commentMessage.match(RegExp(st))) {
                    count += 1
                    atUserIds.push(groupUsers[i].id)
                }
            }
            // 不相等，则使用新的数组
            if (count !== this.state.atUserList.length) {
                this.state.atUserList = atUserIds
            }
            //去掉@name，剩下评论信息
            for (let i = 0; i < groupUsers.length; i++) {
                //@aaa
                let st = '@' + groupUsers[i].humanName + ' '
                if (commentMessage.match(RegExp(st))) {
                    commentMessage = commentMessage.replace(RegExp(st, "g"), "")
                }
            }
            console.log('commentMessage', commentMessage)
            if (commentMessage === undefined || commentMessage.length === 0) {
                this.mesWarning("评论内容不能为空")
                return false
            }
            console.log('commentMessage.length', commentMessage.length)
            if (commentMessage.length > 200) {
                this.mesWarning("评论内容不能超过200个字符")
                return false
            }

            this.state.commentMessage = this.state.commentMessageDefault
            this.addComment()
        }
        setTimeout(() => {   // 设置延迟事件，3秒后将执行
            this.setState({statusButten: true})   // 将isChange设置为true
        }, 1000);

    }
    atUserChange = (e) => {
        console.log('atUserChange', e)
        this.setState({commentMessageDefault: e})
    }
    atUserSelect = (e) => {
        console.log('atUserSelect', e)
        this.state.atUserList.push(e.atUserId)
    }
    DPPaneChange = (key) => {
        if (key === '1') {//动态
            this.getTaskzDynamicList()
        }
        if (key === '2') {//评论
            this.queryTaskComments()
        }


    }
    ////
    RelatedCancle = () => {
        this.setState({
            relatedContentModal: false,
            RelatedContentKey: '1',
            meritName: "",
            aspectAndIndicatorValue: [],
            willAddTaskUserList: [],
            noTaskMeritData: [],
        })
    }
    // Cascader显示最后一级
    displayRender = (label) => {
        return label[label.length - 1];
    }
    // 关联内容--绩效查询--绩效名称
    performancenameChange = (e) => {
        this.setState({meritName: e.target.value})
    }
    // 关联内容--绩效查询--指标
    aspectAndIndicatorChange = (value) => {
        console.log('value', value)
        this.setState({aspectAndIndicatorValue: value})
    }
    // 关联内容--绩效查询
    performanceQuery = () => {
        this.seclectTaskMeritListExcludeExist()
    }
    //
    clearSearch = () => {
        this.props.form.resetFields()
        this.setState({aspectAndIndicatorValue: [], meritName: ''})
    }
    // 跳转绩效详情
    getToMeritInfo = (id) =>{
        this.props.history.push({pathname: '/main/doublehigh/performancedetails/' + id})
    }
    // 跳转文档详情
    getToDocumentInfo = async (record)=>{
        let fileId = record.id
        const {projectId, schoolId} = this.state
        let data = {
            fileId,
            projectId,
            schoolId
        }
        console.log('data...',data)
        await previewFile(data).then(res=>{
            if(res){
                if(res.transStatus === '0' || res.transStatus === '1'){
                    console.log('res...',res)
                    this.setState({previewFileRes: res},()=>{
                        this.setState({
                            onPush:true,
                            yulanrecord: record
                        })
                    })
                }else if(res.transStatus === '2'){
                    message.error('转码失败');
                }
                else if(res.transStatus === '3'){
                    message.warning('转码中');
                }
            }
        })

    }
    //删除文件
    // removeFile = async () =>{
    //     let data = {
    //         fileId: this.state.record.id,
    //         isDir: this.state.record.isDir,
    //     }
    //     await removeFile(data).then(res=>{
    //         this.queryFileListById('',0)
    //         this.setState({deleteFile: false, onPush: false})
    //         this.mesSuccess('删除成功');
    //     })
    // }
    // handPush = async (record)=>{
    //     let fileId = record.id
    //     const {projectId, schoolId} = this.state
    //     let data = {
    //         fileId,
    //         projectId,
    //         schoolId
    //     }
    //     console.log('data...',data)
    //     await previewFile(data).then(res=>{
    //         if(res){
    //             if(res.transStatus === '0' || res.transStatus === '1'){
    //                 this.setState({previewFileRes: res},()=>{
    //                     this.setState({
    //                         onPush:true,
    //                         yulanrecord: record
    //                     })
    //                 })
    //             }else if(res.transStatus === '2'){
    //                 message.error('转码失败');
    //             }
    //             else if(res.transStatus === '3'){
    //                 message.warning('转码中');
    //             }
    //         }
    //     })
    // }
    onClose = () =>{
        this.setState({
            onPush:false,
            previewFileRes: []
        })
    }
    //预览查看详情
    getChildMethod = () =>{
        this.readdetailist.handonclick()
    }
    goToFundDetailByTaskId = ()=>{
        this.props.history.push({pathname: '/main/doublehigh/operation/'+this.state.projectId+'/'+this.state.taskId+'/financialdetailsone'})
        // main/doublehigh/operation/3dff7e80347f4c918a0c1d0a65336d83/1422813332490637313/financialdetailsone
    }
    showBigImg = (item) =>{
        console.log('1122')
        var index = 0
        for (let i = 0; i < this.state.showImageValueList.length; i++) {
            if(this.state.showImageValueList[i]['id'] === item){
                index = i;
            }
        }

        console.log('item',item)
        console.log('index',index)
        console.log('this.state.showImageValueList',this.state.showImageValueList)
        this.setState({imagePreviewIndex: index,imagePreviewVisible:true})
    }

    render() {
        let {
            prefix, csFlag, associatedFile, changeChildTask, taskInfo, suspendedVisable,
            popViewChi, cancelLink, independent, deleteTaskVisable, popView,
            claimUserModal, imgList, relatedContentModal, projectUserList, RelatedContentKey, popoverShow, lookGroupUser, indexOptions,
            onPush,yulanrecord,previewFileRes,userInfoId,userInfoisAdmin,showImageList,showImageKeyList
        } = this.state
        const {getFieldDecorator} = this.props.form;
        const text = `all of the world.`;
        const genExtra = () => (
            <div>
                <span>2021-08-05</span>
                <Avatar size="small" icon="user" style={{'marginLeft': '20px', 'marginTop': '-5px'}}/>
            </div>
        );
        const meritRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    willTaskMeritData: selectedRowKeys,
                })
            },
        };
        console.log(this.state.groupUsers,'----------------')
        return (
            <div className={style.task_center}
                 style={{'background': '#f0f2f5'}}
            >
                <div className="content">
                    <div className="cont-top">
                        <div className='button-topL' onClick={this.backTask}>
                            <Icon type="rollback"/>
                            <span> 返回</span></div>
                        <div className='button-topR'>
                            {this.state.taskInfo.directorId ===  this.state.userInfoId ?
                                <Button className={'btnN'} onClick={this.urgeTask}>催办任务</Button> : ""}
                            {this.state.taskInfo.isStart !== 1 ?
                                <Button className={'btnN'} onClick={this.startTask}>开始任务</Button> : ""}
                            {this.state.taskInfo.taskStatus !== 3 ?
                                <Button className={'btnN'} onClick={this.tocomplete}>完成任务</Button> : ""}
                            {this.state.taskInfo.taskStatus === 2 || this.state.taskInfo.taskStatus === 3 ?
                                <Button className={'btn'} onClick={this.activeTask}>激活任务</Button> :
                                <Button className={'btn'} onClick={this.suspendedTask}>暂停任务</Button>}
                            <Button className={'btn'} onClick={() => this.updateTask(1)}>编辑任务</Button>

                            <Popover placement="bottomRight"
                                     content={(
                                         <div className={style.popover}>
                                             {
                                                 this.state.taskInfo.isNew === 0 ? '' :
                                                     <div>
                                                         {this.state.taskInfo.parentId === '0' ?
                                                             <Button className='popoverD'
                                                                     onClick={() => this.changeChildTask(9)}>转化为子任务</Button> : ''}
                                                         {this.state.taskInfo.parentId !== '0' ?
                                                             <Button className='popoverD'
                                                                     onClick={() => this.changeChildTask(10)}>变更父任务</Button> : ''}
                                                         {this.state.taskInfo.parentId !== '0' ?
                                                             <Button className='popoverD'
                                                                     onClick={this.changeIndependent}>转化为独立任务</Button> : ''}
                                                         <Button className='popoverD'
                                                                 onClick={this.deleteTask}>删除任务</Button>
                                                     </div>


                                             }


                                         </div>
                                     )}
                                     visible={popView}
                                     onVisibleChange={this.handleVisibleChange}


                                     trigger="click">

                                {this.state.taskInfo.isNew === 0 ? '' :
                                    <Button className={'btn'} onClick={this.changeBtnStyle}>更多操作</Button>}

                            </Popover>


                        </div>

                    </div>

                    <div className="cont-bottom">
                        {/*下-左部分*/}
                        <div className="cont-bottomL">
                            <div className='cont-bottomLT'>
                                <p></p>
                                <div className='titlee'
                                     style={{
                                         'fontSize': '20px',
                                         'color': '#333333', 'Weight': '550'
                                     }}>任务详情
                                </div>
                            </div>

                            <div className='cont-bottomLM'>
                                <div className='titleT'>
                                    <div className='taskidd'>{taskInfo.taskCode}</div>
                                    <Tooltip placement="bottomLeft" title={taskInfo.taskName}
                                             overlayClassName='TitleTooltip'
                                    >
                                        <div className='taskidT'>{taskInfo.taskName}</div>
                                    </Tooltip>

                                </div>
                                <div className='titleB'>
                                    <div className='titleBDiv'><span
                                        className='titleB0'><TaskType/><span>任务状态：</span></span><span
                                        className={taskInfo.taskStatus === 4 ? 'yiyuqi' : taskInfo.taskStatus === 3 ? 'yiwancheng' : ''}>{taskInfo.taskStatusName}</span>
                                    </div>
                                    <div className='titleBDiv'><span
                                        className='titleB0'><TaskPriority/><span>任务优先级：</span></span>
                                        <span
                                            className={taskInfo.taskPriority === 1 ? '' : taskInfo.taskPriority === 2 ? 'putong' : taskInfo.taskPriority === 3 ? 'jinji' : taskInfo.taskPriority === 4 ? 'feichangjinji' : ''}>{taskInfo.taskPriorityName}</span>
                                    </div>
                                    <div className='titleBDiv'
                                         style={{'height': '40px', 'lineHeight': '40px', display: 'flex'}}>

                                        <span className='titleB0'><TaskPrincipal/><span>负责人：</span></span>
                                        <Avatar icon="user" src={`${window.$$imgSrc(taskInfo.portrait)}`} className={'user'}/>
                                        {
                                            taskInfo.humanName ? <span>{taskInfo.humanName}</span> :
                                                <span className='claim' onClick={() => this.getClaimUser()}>待认领</span>
                                        }
                                    </div>

                                    <div className='titleBDiv'><span
                                        className='titleB0'><SchedulePlanning/><span>日程规划：</span></span><span>{taskInfo.beginTime ? taskInfo.beginTime : "--"} 至 {taskInfo.endTime ? taskInfo.endTime : "--"}</span>
                                    </div>
                                    <div className='titleBDiv'>
                                        <div className='titleBDF'><span
                                            className='titleB0'><Description/><span>描 述：</span></span><span
                                            className='titleBtaskDescribe'>{taskInfo.taskDescribe ? taskInfo.taskDescribe : "待添加"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='cont-bottomLB'>
                                <div className='cont-bottomLBTitle'>
                                    <p className='titleL'>子任务{this.state.taskOneLevel.completeCount}/{this.state.taskOneLevel.sumCount}： </p>
                                    {taskInfo.taskLevel >= 3 ? "":
                                        <div className='titleR' onClick={() => this.updateTask(2)}>
                                            <Icon type="plus-circle"/>
                                            <span>添加子任务</span>
                                        </div>
                                    }
                                </div>


                                <div className='zirenwuList'>
                                    <ul>

                                        {
                                            this.state.taskOneLevel.taskVos ? this.state.taskOneLevel.taskVos.map((item, index) => (
                                                <li>
                                                    <div className='zirenwuListTitle'>
                                                        <span className={'taskCode'}>{item.taskCode}</span>
                                                    </div>
                                                    <div className='zirenwuListTitle zirenwuListTitle1'>
                                                        {
                                                            item.taskName && item.taskName.length > 30
                                                                ?
                                                                <Tooltip placement="bottomLeft" title={item.taskName}
                                                                         overlayClassName='TitleTooltip'
                                                                         className='zrws'>
                                                                    <a onClick={() => this.getTaskChildInfo(item.id)} ><span
                                                                        className='zrw'>{item.taskName.slice(0, 30)}...</span></a>
                                                                </Tooltip> :
                                                                <a onClick={() => this.getTaskChildInfo(item.id)} style={{'color':'#40a9ff'}}><span
                                                                    className='zrw'>{item.taskName}</span></a>
                                                        }
                                                    </div>

                                                    <div className={'zirenwuListright'}>
                                                        <span>{item.createTime.split("T")[0]}</span>
                                                        {item.portrait ? <Avatar size="small" icon="user"
                                                                                 style={{'margin': '-3px 20px 0'}}
                                                                                 src={`${window.$$imgSrc(item.portrait)}`}/> :
                                                            <Avatar size="small" icon="user"
                                                                    style={{'margin': '-3px 20px 0'}}/>}
                                                        <Popover placement="bottom"
                                                                 visible={this.state.show === index && popViewChi}
                                                                 content={(
                                                                     <div className={style.popoverTool}>

                                                                         <span className='popoverD'
                                                                               onClick={() => this.updateChildTask(item.id)}>编辑</span>
                                                                         <span style={{
                                                                             'display': 'inline-block',
                                                                             'width': '20px',
                                                                             'textAlign': 'center'
                                                                         }}>|</span>
                                                                         <span className='popoverD'
                                                                               onClick={() => this.deleteTaskChild(item.id, item.isNew)}>删除</span>

                                                                     </div>
                                                                 )}
                                                                 onVisibleChange={(e) => this.handleVisibleChangeChild(e, index)}
                                                                 trigger="click">
                                                            <Icon type="down"/>
                                                        </Popover>

                                                    </div>

                                                </li>
                                            )) : ""
                                        }

                                    </ul>

                                </div>


                            </div>
                            <div className='cont-bottomLB cont-bottomLB1'>
                                <p className='titleL'>资金情况：</p>
                                <div className='zirenwuList guanlizj'>
                                    <div className={'intitle'}>
                                        <span><AssociatedMoney/>资金</span>
                                        <span className={'addzhichu'} onClick={()=>this.goToFundDetailByTaskId()}>查看明细</span>
                                    </div>
                                    <ul className={'glzjlist'}>
                                        <li>
                                            <span>预算金额（元）：{this.state.fundInfo.investAmountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                                            <span>实际支使用金额（元）：{this.state.fundInfo.expendAmountSum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
                                            <span>资金执行率：{this.state.fundInfo.useRate}%</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='cont-bottomLB cont-bottomLB1'>
                                <div className='cont-bottomLBTitle'>
                                    <p className='titleL'>关联内容：</p>
                                    <div className='titleR' onClick={() => this.showRelatedContentModal()}>
                                        <Icon type="plus-circle"/>
                                        <span>添加关联内容</span>
                                    </div>
                                </div>

                                <div className='zirenwuList zirenwuList1'>
                                    <div className={'intitle'}>
                                        <span><ContextualPerformance/>关联的绩效</span>
                                    </div>
                                    <ul className={'glzjlist'}>
                                        {
                                            this.state.taskMeritData ? this.state.taskMeritData.map((item, index) => (
                                                item.isDelete == '1' ? '' :
                                                item.meritName.length > 30 ?
                                                    <li>
                                                        <Tooltip placement="bottomLeft" title={item.meritName}
                                                                 overlayClassName='TitleTooltip'>
                                                            <a onClick={() => this.getToMeritInfo(item.id)} style={{'color':'#333333'}}> <span>{item.meritName.slice(0, 30)}...</span></a>
                                                        </Tooltip>
                                                        <span className={'quxiaogl'}
                                                              onClick={() => this.showCancleTaskMerit(item.id)}>取消关联</span>
                                                    </li> :
                                                    <li>
                                                        <a onClick={() => this.getToMeritInfo(item.id)} style={{'color':'#333333'}}> <span>{item.meritName}</span></a>
                                                        <span className={'quxiaogl'}
                                                              onClick={() => this.showCancleTaskMerit(item.id)}>取消关联</span>
                                                    </li>
                                            )) : ""
                                        }
                                    </ul>
                                </div>
                                <div className='zirenwuList zirenwuList1'>
                                    <div className={'intitle'}>
                                        <span><AssociatedDocument/>关联的文档</span>
                                        {/*<div className='titleR' onClick={this.associatedFile}>*/}
                                        {/*    <span>从文档添加</span>*/}
                                        {/*</div>*/}
                                    </div>

                                    <ul className={'glzjlist'}>

                                        {
                                            this.state.taskDocumentList && this.state.taskDocumentList.length > 0 ? this.state.taskDocumentList.map((item) => (
                                                item.isDelete == 0 ?
                                                    <li className="cont-bottomRT2UI">
                                                        <a onClick={() => this.getToDocumentInfo(item)} style={{'color':'#333333'}}> <span>{this.getIcon(item.isDir, item.fileType)} {item.resourceName}</span></a>
                                                        <div className="cont-bottomRT2UID">
                                                            {
                                                                <div className='cont-bottomRT2UIDD'>
                                                                    <a href={`${window.$$fujinDownload(item.uploadFileId + "/" + item.resourceName)}`}><span
                                                                        className='downloadColor11'> 下载</span></a>
                                                                </div>
                                                            }
                                                            <span className="bk"></span>
                                                            {
                                                                <div className='cont-bottomRT2UIDD'
                                                                     style={{"cursor": "pointer"}}>
                                                                    <span
                                                                        onClick={() => this.IshidecancelLink(item.id)}> 取消关联</span>
                                                                </div>
                                                            }
                                                        </div>

                                                    </li> :''
                                                    // <Tooltip title="该文档已删除" >
                                                    //     <li className="cont-bottomRT2UI2">
                                                    //         <span>{this.getIcon(item.isDir, item.fileType)} {item.resourceName}</span>
                                                    //         <div className="cont-bottomRT2UID">
                                                    //             {
                                                    //                 <div className='cont-bottomRT2UIDD'>
                                                    //                     <span className='downloadColor' style={{'color':'#888888'}}>下载</span>
                                                    //                 </div>
                                                    //             }
                                                    //
                                                    //             <span className="bk"></span>
                                                    //             {
                                                    //                 <div className='cont-bottomRT2UIDD' style={{"cursor": "pointer"}}>
                                                    //                     <span onClick={() => this.IshidecancelLink(item.id)}>取消关联</span>
                                                    //                 </div>
                                                    //             }
                                                    //
                                                    //
                                                    //         </div>
                                                    //
                                                    //     </li>
                                                    // </Tooltip>

                                            )) : ""
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>


                        {/*下-右部分*/}
                        <div className="cont-bottomR">
                            <div className={'left-xmzt left-dongtai canyuren'}>
                                <div className={'xmgl-title'}>
                                    参与人
                                    <div className={'QuestionMark'}>
                                        <Tooltip placement="top" title={'参与人将会收到评论通知'} className={'tooltip'}>
                                            <QuestionMark/><span></span>
                                        </Tooltip>
                                        <span>{this.state.groupUsersCount}人 </span>
                                    </div>

                                </div>
                                <div className={'canyurenul'}>
                                    <ul>
                                        {

                                            this.state.groupUsers ? this.state.groupUsers.map((item, index) => (
                                                <li>
                                                    <Popover placement="bottom" title={''} trigger="click"
                                                             visible={lookGroupUser && popoverShow === index}
                                                             onVisibleChange={(e) => this.handlePeopelVisibleChange(e, index)}
                                                             content={<div className={style.PopoverCont}>
                                                                 <div className={'left'}>
                                                                     <img src={`${window.$$imgSrc(item.portraitId)}`} alt={''}/>
                                                                 </div>
                                                                 <div className={'right'}>
                                                                     <div className={'righttop'}>
                                                                         <span>{item.humanName}</span>
                                                                         <span>职务：{item.position ? item.position : '无'}</span>
                                                                     </div>
                                                                     <span className={'remove'} onClick={() => {
                                                                         this.removeUser(item.id)
                                                                     }}>移除成员</span>
                                                                 </div>
                                                             </div>}>
                                                        <img src={`${window.$$imgSrc(item.portraitId)}`} alt={''}/>
                                                    </Popover>

                                                </li>
                                            )) : ""

                                        }
                                        <li><span className={'add'}
                                                  onClick={() => this.showTaskUserModal()}><Add/></span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'left-xmzt left-dongtai dongtaipingjia'}>
                                <Tabs defaultActiveKey="1" className={'rightbotTabs'}
                                      onChange={(e) => this.DPPaneChange(e)}>
                                    <TabPane tab="动态" key="1">
                                        <ul className={'dtlist'}>
                                            {
                                                this.state.taskDynamicList && this.state.taskDynamicList.map((item, index) => (
                                                    <li>
                                                        <span className={'left-time'}>{item.date}</span>
                                                        <span className={'yuan'}><i></i></span>
                                                        <ul className={'right-sj'}>
                                                            {
                                                                item.taskDynamicVoList.map((item2, index2) => (
                                                                    <li>
                                                                        <span
                                                                            className={'right-name'}>{item2.editorName}</span>
                                                                        <span
                                                                            className={'right-action'}>{item2.action}</span>

                                                                        <Tooltip placement="bottomLeft"
                                                                                 title={item2.describe + (item2.describeDetail?item2.describeDetail:'')}
                                                                                 overlayClassName='TitleTooltip'
                                                                        >
                                                                            <span
                                                                                className={'right-message'}>{item2.describe}{item2.describeDetail}</span>
                                                                        </Tooltip>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </TabPane>
                                    <TabPane tab="评论" key="2">
                                        <div className={'pinglun'}>
                                            <div className={'showImage'}>
                                                <div className={'showImageCont'} onClick={()=>this.queryTaskCommentImages()}>
                                                    <ImageIcon/>查看图片
                                                </div>
                                            </div>
                                            <Form>
                                                {/*<div className={'pingluncont'}>*/}
                                                {/*评论列表*/}
                                                <ul className='pingluncont' id="pingluncont">
                                                    {
                                                        this.state.taskcommentList ? this.state.taskcommentList.map((item, index) => (
                                                            <li>
                                                                <div className={'touxing'}>
                                                                    <img src={`${window.$$imgSrc(item.reviewerPortrait)}`}
                                                                         alt={''}/>
                                                                </div>
                                                                <div className={'plcontright'}>
                                                                    <div className={'righttop1'}>
                                                                        <span
                                                                            className={'name'}>{item.reviewerName}</span>
                                                                        <span
                                                                            className={'time'}>{item.createTime.replace('T', ' ')}</span>
                                                                        {
                                                                            item.reviewerId === this.state.userId && item.after15Minite == 0 ?
                                                                                <div className={'delete'}
                                                                                     onClick={() => this.setState({
                                                                                         deleteCommentVisable: true,
                                                                                         deleteCommentItem: item
                                                                                     })}>
                                                                                    <RedDelete/>删除
                                                                                </div> : ''
                                                                        }
                                                                    </div>
                                                                    <div className={'text'}>{item.reviewDetail}</div>
                                                                    <ul className={'imglist'}>
                                                                        {
                                                                            item.relatedPicList ? item.relatedPicList.map((item2, index) => (
                                                                                <li><img src={`${window.$$imgSrc(item2)}`} alt={''} onClick={() =>{this.queryComment(item,index)}}/></li>
                                                                            )) : ""
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )) : ""
                                                    }

                                                </ul>
                                                {/*</div>*/}
                                                <ul className={'uploadImgul'}>
                                                    {
                                                        imgList ? imgList.map(item => {
                                                            return <li>
                                                                <div onClick={() => this.deleteimg(item.fileId)}>
                                                                    <DeleteImg/>
                                                                </div>
                                                                <RenderImg size='m' id={item.fileId}
                                                                           type={item.mediaType}/>

                                                            </li>
                                                        }) : ''
                                                    }
                                                </ul>

                                                <div className={'pinglunfooter'}>
                                                    {imgList.length > 3 ? '' :
                                                        <UploadImg
                                                            handleImgChange={this.handleImgChange}
                                                            fileId={''}
                                                            html={<SendImages/>}
                                                        />
                                                    }

                                                    {/*<div className={'shuruk'}>*/}
                                                    {/*    <Mentions*/}
                                                    {/*        placeholder="输入评论内容，可通过@提醒参与人"*/}
                                                    {/*        prefix={['@']}*/}
                                                    {/*        onSearch={this.onSearch}*/}
                                                    {/*        placement={'top'}*/}
                                                    {/*    >*/}
                                                    {/*        <div className={'mentionsnameMentionstitle'}><span></span>群成员</div>*/}
                                                    {/*        <Option key={'111'} value={'111'} className={'mentionsnameOption'}><img src={require('../../../../../assets/imgnew/tg.png')} alt={''} className={'mentionsnametximg'}/><span>1111</span></Option>*/}
                                                    {/*        <Option key={'222'} value={'222'} className={'mentionsnameOption'}><img src={require('../../../../../assets/imgnew/tg.png')} alt={''} className={'mentionsnametximg'}/><span>2222</span></Option>*/}
                                                    {/*    </Mentions>*/}
                                                    {/*    <span className={'fasong'} onClick={()=>sendMessage()}>发送</span>*/}
                                                    {/*</div>*/}
                                                    <Form.Item>
                                                        {
                                                            getFieldDecorator('commentMessage', {})(
                                                                <div className={'shuruk'}>
                                                                    <Mentions
                                                                        placeholder="输入评论内容，可通过@提醒参与人"
                                                                        prefix={['@']}
                                                                        onSearch={this.onSearch}
                                                                        placement={'top'}
                                                                        onChange={(e) => this.atUserChange(e)}
                                                                        onSelect={(e) => {
                                                                            this.atUserSelect(e)
                                                                        }}
                                                                        value={this.state.commentMessageDefault}
                                                                    >
                                                                        <div className={style.mentionsnameMentionstitle}>
                                                                            <span className={'canyuren'}>参与人</span></div>
                                                                        {/*<Option value='111' className={'mentionsnameOption'}><img src={require('../../../../../assets/imgnew/tg.png')} alt={''} className={'mentionsnametximg'}/><span>1111</span></Option>*/}
                                                                        {/*<Option value='222' className={'mentionsnameOption'}><img src={require('../../../../../assets/imgnew/tg.png')} alt={''} className={'mentionsnametximg'}/><span>2222</span></Option>*/}
                                                                        {
                                                                            this.state.groupUsers ? this.state.groupUsers.map((item, index) => (
                                                                                <Option atUserId={item.id}
                                                                                        value={item.humanName}
                                                                                        className={style.mentionsnameOption}>
                                                                                    <img src={`${window.$$imgSrc(item.portraitId)}`} alt={''} className='mentionsnametximg'/><span>{item.humanName}</span></Option>
                                                                            )) : ""
                                                                        }
                                                                    </Mentions>
                                                                    <span className={'fasong'}
                                                                          onClick={() => this.sendMessage()}>发送</span>
                                                                </div>
                                                            )
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                            {/*动态部分end*/}


                        </div>


                    </div>

                </div>

                {/*编辑任务*/}
                <Modal
                    width={680}
                    title={this.state.taskFormTitle}
                    className={`${style.bianjirenwu_modal} histy_modal_con`}
                    visible={this.state.updateTaskVisable}
                    destroyOnClose={true}
                    closable={false}
                    onOk={this.updateTaskOk}
                    onCancel={this.totalCancel}
                    maskClosable={false}
                    centered={true}
                >
                    <AddNewTask
                        addNewTaskComponent={(form) => this.addNewTaskComponent = form}
                        statYear = {this.state.statYear}
                        taskInfo={this.state.taskFormInfo}
                        taskId={this.state.taskId}
                        projectId={this.state.projectId}
                        projectName={this.state.projectName}
                        taskChildId={this.state.taskChildId}
                        isEditTaskChild={this.state.isEditTaskChild}
                        taskFormOption={this.state.taskFormOption}
                    />
                </Modal>

                {/*完成任务*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.completeVisable}
                    onOk={this.tocompleteOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认{csFlag === 1 ? "完成" : '暂停'}该任务？</p>
                </Modal>
                {/*开始任务*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.startVisable}
                    onOk={this.startOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认开始该任务？</p>
                </Modal>
                {/*催办任务*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.urgeVisable}
                    onOk={this.urgeOk}
                    onCancel={this.hideModal}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>继续该操作，会已消息的方式通知该任务下所有成员，是否继续？</p>
                </Modal>

                {/*暂停任务*/}
                <Modal
                    title="温馨提示"
                    visible={suspendedVisable}
                    onOk={this.suspendedTaskOk}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认暂停该任务？</p>
                </Modal>

                {/*激活任务*/}
                <Modal
                    title="温馨提示"
                    visible={this.state.activeVisable}
                    onOk={this.activeTaskOk}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确认激活该任务？</p>
                </Modal>


                {/*关联文档*/}
                <Modal
                    width={700}
                    title={'关联文件'}
                    className='histy_modal_con'
                    visible={associatedFile}
                    destroyOnClose={true}
                    closable={false}
                    onOk={this.associatedFileOk}
                    onCancel={this.totalCancel}
                    maskClosable={false}
                    centered={true}
                >
                    <AssociatedDocumentation
                        onRef={(form) => this.associatedDocumentation = form}
                        taskId={this.state.taskId}
                        projectId={this.state.projectId}
                    />


                </Modal>


                {/*文档取消关联*/}
                <Modal
                    title="取消提示"
                    visible={cancelLink}
                    onOk={this.hidecancelLink}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>将取消该文档与当前任务的关联，是否继续？</p>
                </Modal>

                {/*绩效取消关联*/}
                <Modal
                    title="取消提示"
                    visible={this.state.cancelMeritLinkModal}
                    onOk={this.okCancelMeritLink}
                    onCancel={() => this.setState({cancelMeritLinkModal: false})}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>将取消该绩效与当前任务的关联，是否继续？</p>
                </Modal>


                {/*转为独立任务*/}
                <Modal
                    title="提示"
                    visible={independent}
                    onOk={this.changeIndependentOK}
                    onCancel={this.changeIndependentCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>将该任务转化为独立任务，是否继续？</p>
                </Modal>


                {/*转为子任务*/}
                <Modal
                    title={this.state.taskTransferTypeTitle}
                    visible={changeChildTask}
                    onOk={this.changeChildTaskOK}
                    onCancel={this.changeChildTaskCancel}
                    width={660}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                    destroyOnClose={true}
                    okText={'添加'}

                >
                    <ChangeChildTask
                        onRef={form => {
                            this.changeChildTaskComp = form
                        }}
                        projectId={this.state.projectId}
                        taskId={this.state.taskId}
                        taskTransferType={this.state.taskTransferType}
                    />
                </Modal>


                {/*删除任务*/}
                <Modal
                    title="提示"
                    visible={deleteTaskVisable}
                    onOk={this.deleteTaskOk}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>删除任务后，该任务及其子任务将会被永久被删除，是否继续？</p>
                </Modal>

                {/*删除子任务*/}
                <Modal
                    title="提示"
                    visible={this.state.deleteTaskChildVisable}
                    onOk={this.deleteTaskChildOk}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>删除任务后，该任务及其子任务将会被永久被删除，是否继续？</p>
                </Modal>

                {/*删除评论*/}
                <Modal
                    title="提示"
                    visible={this.state.deleteCommentVisable}
                    onOk={this.deleteCommentOk}
                    onCancel={this.totalCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>是否确定永久删除此条评论？</p>
                </Modal>


                {/*认领负责人*/}
                <Modal
                    title="请选择负责人"
                    visible={claimUserModal}
                    onOk={this.claimUserOk}
                    onCancel={this.claimUserCancel}
                    width={450}

                    closable={false}
                    className='modaltishi FZRstyle FZRstyle1'
                    maskClosable={false}
                    centered={true}
                >
                    <div id={'area'}>
                        <Select
                            style={{'width': '100%', 'font-weight': 'bold', 'color': 'black'}}
                            // defaultValue=""
                            placeholder="请选择负责人"
                            allowClear={true}
                            onChange={(e) => this.onUserChange(e)}
                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                            showSearch="true"
                            optionFilterProp="children"
                            // dropdownClassName="TreeSelect_warp"
                            open={this.state.openVal}
                        >
                            {
                                this.state.projectMemberList && this.state.projectMemberList.map(item =>
                                    <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                )
                            }

                        </Select>
                    </div>

                </Modal>

                {/*认领负责人*/}
                <Modal
                    title="添加任务参与人"
                    visible={this.state.taskUserModal}
                    onOk={this.taskUserOk}
                    onCancel={() => this.setState({taskUserModal: false})}
                    width={450}
                    closable={false}
                    className={`${style.CYRstyle} modaltishi`}
                    maskClosable={false}
                    centered={true}
                    destroyOnClose
                >
                    <div id={'area'}>
                        <Select
                            autoFocus={true}
                            placeholder="请选择任务参与人"
                            mode={'multiple'}
                            allowClear={true}
                            showSearch="true"
                            optionFilterProp="children"
                            onChange={this.handleChange}
                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                            style={{width: '100%'}}
                            open={this.state.openSelectOption}
                        >
                            {

                                this.state.notaskUserList ? this.state.notaskUserList.map((item, index) => (
                                    <Option value={item.id}>{item.humanName}</Option>
                                )) : ""
                            }

                        </Select>
                    </div>

                </Modal>

                <Modal
                    title={<span style={{'font-size': '18px'}}>关联内容</span>}
                    visible={relatedContentModal}
                    onOk={this.RelatedConfirm}
                    onCancel={() => this.RelatedCancle()}
                    width={880}
                    closable={false}
                    className={style.RelatedContent}
                    maskClosable={false}
                    centered={true}
                    destroyOnClose={true}
                    style={{'font-size': '18px'}}
                >
                    <Tabs defaultActiveKey={RelatedContentKey} onChange={this.RelatedContentChange}>
                        <TabPane tab="文档" key="1">
                            {
                                RelatedContentKey === '1' ?
                                    <div className={'treewrap'}>
                                        <AssociatedDocumentation
                                            onRef={(form) => this.associatedDocumentation = form}
                                            taskId={this.state.taskId}
                                            projectId={this.state.projectId}
                                        />
                                    </div> :
                                    ''
                            }
                        </TabPane>
                        <TabPane tab="绩效" key="2">
                            {RelatedContentKey === '2' ?
                                <div>
                                    <div className="jixiaoss">
                                        <Cascader
                                            fieldNames={{label: 'indicatorName', value: 'id', children: 'children'}}
                                            options={this.state.aspectAndIndicatorTree}
                                            popupPlacement='bottomLeft'
                                            changeOnSelect
                                            displayRender={this.displayRender}
                                            onChange={this.aspectAndIndicatorChange}
                                            getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                            placeholder="请选择指标层级"
                                            value={this.state.aspectAndIndicatorValue}
                                        />

                                        <Input placeholder="请输入绩效名称" value={this.state.meritName}
                                               onChange={this.performancenameChange}/>
                                        <Button type={'primary'} onClick={() => this.performanceQuery()}>查询</Button>
                                        <Button onClick={() => this.clearSearch()}>重置</Button>
                                    </div>
                                    <Table className='table-ta performancet-table'
                                           rowSelection={{
                                               type: "radio",
                                               ...meritRowSelection,
                                           }}
                                           columns={this.state.noTaskMeritListColumn}关联内容
                                           dataSource={this.state.noTaskMeritData}
                                           scroll={{y: 238}}
                                           rowKey="id"
                                           pagination={false}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}

                                    />
                                </div> : ""
                            }
                        </TabPane>
                    </Tabs>
                </Modal>

                {/*查看详情*/}
                <Drawer
                    title={
                        <div className='left-return'>
                            <div className= 'left-retu' onClick={this.onClose}>
                                <img className='return' src={require('../../../../../assets/img/fanhui.png')} alt={'返回'}/>
                                <span>返回</span>
                            </div>
                            <span className='center'>{previewFileRes.resourceName}</span>
                            <div className='right-culars'>
                                <div className='particulars'>
                                    {/*<img src={require('../../../../assets/img/yanjing.png')} alt=""/>*/}
                                    <span onClick={()=>this.getChildMethod()}>详情</span>
                                    {/*<div className='download'>*/}
                                    {/*<img src={require('../../../../assets/img/xiazai.png')} alt=""/>*/}
                                    {/*<span>下载</span>*/}
                                    {/*    <a href={downloadRc(yulanrecord.uploadFileId+"/"+yulanrecord.resourceName)}><span className='downloadColor'>下载</span></a>*/}
                                    {/*    {userInfoId == yulanrecord.createrId || userInfoisAdmin === 1 ?*/}
                                    {/*        <Dropdown overlay={<Menu>*/}
                                    {/*            <Menu.Item><span onClick={()=>this.remove(yulanrecord)} style={{color: '#40a9ff'}}>移动文件</span></Menu.Item>*/}
                                    {/*            <Menu.Item><span style={{color: '#40a9ff'}} onClick={()=>{this.setState({deleteFile: true, record: yulanrecord})}}>删除文件</span></Menu.Item>*/}
                                    {/*        </Menu>}>*/}
                                    {/*            <Button className='more' type="primary" >*/}
                                    {/*                <span className='more-m'> 更多</span>*/}
                                    {/*                <Icon type="down" />*/}
                                    {/*            </Button>*/}
                                    {/*        </Dropdown> : ''*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    }
                    placement="right"
                    className="particulars_cont"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPush}
                >
                    <Readdetailist previewFileRes={previewFileRes}  onRef={(form) => this.readdetailist = form}/>
                </Drawer>
                {/*评论查看图片*/}
                <Modal
                    title="查看图片"
                    visible={this.state.showImagevisible}
                    width={880}
                    closable={false}
                    className={`${style.showImageModal} modaltishi`}
                    maskClosable={false}
                    centered={true}
                    destroyOnClose
                    footer={
                        <div><Button type={'primary'} onClick={() => this.setState({showImagevisible: false})}>关闭</Button></div>
                    }
                >
                    <div className={'imglistul'}>
                        {
                            showImageKeyList && showImageKeyList.length >0 ? showImageKeyList.map((item,index)=>{
                                return <div className={'monthCont'}>
                                    <p>{item === this.state.currentYear + '-'+ this.state.currentMonth?'本':item}{'月'}</p>
                                        <div className={'monthCont'}>
                                    {
                                        showImageList[item].map((imgUrl,index2)=>{
                                            return <span className={'spanli'} onClick={()=>this.showBigImg(imgUrl)}>
                                                <img src={`${window.$$imgSrc(imgUrl)}`} alt={''}/>
                                            </span>
                                        })
                                    }
                                        </div>
                                </div>
                            }) : <><div className="Empty-conet-ss">
                                <div className="img">
                                    <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                    <span>暂无图片</span>
                                </div>
                            </div></>
                        }
                        <div className="clear"></div>
                    </div>
                </Modal>
                <GongyongImagePreview
                    visible={this.state.imagePreviewVisible}
                    imglist={this.state.showImageValueList}
                    curPreview={this.state.imagePreviewIndex}
                    cancel={() => {
                        this.setState({
                            imagePreviewVisible: false
                        },()=>{
                            document.body.classList.remove('pic_image_preview_add_body')
                        })
                    }}
                />
            </div>
        );
    }
}

Operation = Form.create()(Operation);
export default Operation;
