/*资金使用额度柱形图*/
import React, {Component} from "react";
import {withRouter} from "react-router";
import {Form} from "antd";
import * as echarts from 'echarts';

@withRouter
class FundUsageLimit extends Component {
    state = {
        month: [],
    }

    componentDidMount() {
        this.props.formRef(this)
    }

    fundUsageLimit = () => {
        let date = new Date();
        let month1 = date.getMonth() + 1;
        let month2 = date.getMonth();
        let month3 = date.getMonth() - 1;
        let month4 = date.getMonth() - 2;
        let month5 = date.getMonth() - 3;
        this.state.month.push(month5 + '月')
        this.state.month.push(month4 + '月')
        this.state.month.push(month3 + '月')
        this.state.month.push(month2 + '月')
        this.state.month.push(month1 + '月')


        let data = [this.props.surveyExpendQuery.fourUseQuota,this.props.surveyExpendQuery.threeUseQuota, this.props.surveyExpendQuery.twoUseQuota,this.props.surveyExpendQuery.oneUseQuota,
            this.props.surveyExpendQuery.currentMonth]
        let data1 = [this.props.surveyExpendQuery.fourUseQuota,this.props.surveyExpendQuery.threeUseQuota, this.props.surveyExpendQuery.twoUseQuota,this.props.surveyExpendQuery.oneUseQuota,
            this.props.surveyExpendQuery.currentMonth]
        let showBackground = false
        if(!data1.some(item=>item !== 0)){
            showBackground = true
        }
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('fundUsageLimit')) {
            let myChart = echarts.init(document.getElementById('fundUsageLimit'));
            // 绘制图表
            let option = {
                color: ["#3E78ED"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function (params) {
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>' + params[0].axisValue + '</div>' +
                            '<div style="text-align: center">¥' + data[params[0].dataIndex].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(".00","")
                            + '元</div>' +
                            '</div>'
                        return res
                    }
                },
                grid: {
                    left: "0",
                    top: '0',
                    bottom: '0'
                },
                xAxis: {
                    type: 'category',
                    data: this.state.month,
                    show: true,
                    position: 'bottom',
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data1,
                    type: 'bar',
                    showBackground: showBackground,
                    itemStyle: {
                        normal: {
                            color: function (farams) {
                                if (data[farams.dataIndex] > 0) {
                                    return '#3E78ED'
                                } else {
                                    return '#27CDA2'
                                }
                            }
                        }
                    }
                }]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })

        }
    }

    render() {
        return (
            <div style={{marginTop: '10px'}}>
                <div id={'fundUsageLimit'} className={'echarts'} style={{width: '100%', height: '40px'}}></div>
            </div>

        );
    }
}

FundUsageLimit = Form.create()(FundUsageLimit);
export default FundUsageLimit;
