//前台加密主要是为了防止post请求明文传递密码


//使用例子
// var CryptoJS = require("crypto-js");

// var data = [{id: 1}, {id: 2}]

// // Encrypt
// var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

// // Decrypt
// var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
const urlObj = {};
//TODO  不同环境需要更换，或者之后统一更换
urlObj.commonalityUrl = 'https://sgfile.cveducloud.com/';
function create(src) {
    return function (path) {
        if(!path)return;
        if (path[0] === '/') {
            return src + path.slice(1);
        } else {
            return src + path;
        }
    }
}

export const domainUrl = ''
export const fillInYear = 2021;
export const commonalityUrl = urlObj.commonalityUrl;
export const previewUrl = urlObj.previewUrl;
export const path = 'dh-front-img/dh-doublehigh-front/';
export const AESKey = 'dvyYRQlnPRCMdQSe';
