/*绩效指标完成度*/
import React, {Component} from "react";
import {
    Form,Select,Tabs,Table,Tag
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Dnamicmap from "./map1";
import Dnamicmapa from "./map2";
import OverviewTwo from "../../situationFunds/map4";
const {Option} = Select;
const { TabPane } = Tabs;

@withRouter
class DegreeCompletion extends Component {
    state = {
        schoolIndicatorOneLevel:'',
        seniorBrace:[],
        schoolList:[],
        groupList:[],
        placeList:[],
        tableList:[]
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }


    render() {
        const {schoolIndicatorOneLevel,columns,seniorBrace,columnslist,tableList} = this.state
        const {getFieldDecorator} = this.props.form;
        const data = [
            {
                title: '3级指标',
                dataIndex: 'indicatorName',
                key: 'indicatorName',
                align: 'center',
            },
            {
                title: '4级指标个数',
                dataIndex: 'indicatorNum',
                key: 'indicatorNum',
                align: 'center',
                render: (indicatorNum, row) => {
                    if (this.isInArr(row.maxValueArr, indicatorNum, 1)) {
                        return (
                            <Tag color='red' key={indicatorNum}>
                                {indicatorNum}
                            </Tag>
                        )
                    } else {
                        return (
                            <span>{indicatorNum}</span>
                        )
                    }
                },
            },
            {
                title: '超出预期数',
                dataIndex: 'beyondExpectationsNum',
                key: 'beyondExpectationsNum',
                align: 'center',
                render: (beyondExpectationsNum, row) => {
                    if (this.isInArr(row.maxValueArr, beyondExpectationsNum, 2)) {
                        return (
                            <Tag color='red' key={beyondExpectationsNum}>
                                {beyondExpectationsNum}
                            </Tag>
                        )
                    } else {
                        return (
                            <span>{beyondExpectationsNum}</span>
                        )
                    }
                },
            },
            {
                title: '未达预期数',
                dataIndex: 'notExpectedNum',
                key: 'notExpectedNum',
                align: 'center',
                render: (notExpectedNum, row) => {
                    if (this.isInArr(row.maxValueArr, notExpectedNum, 3)) {
                        return (
                            <Tag color='red' key={notExpectedNum}>
                                {notExpectedNum}
                            </Tag>
                        )
                    } else {
                        return (
                            <span>{notExpectedNum}</span>
                        )
                    }
                },
            },
            {
                title: (
                    <div>阶段性目标值<br/>为0指标数</div>
                ),
                dataIndex: 'targetValueZeroNum',
                key: 'targetValueZeroNum',
                align: 'center',
                render: (targetValueZeroNum, row) => {
                    if (this.isInArr(row.maxValueArr, targetValueZeroNum, 5)) {
                        return (
                            <Tag color='red' key={targetValueZeroNum}>
                                {targetValueZeroNum}
                            </Tag>
                        )
                    } else {
                        return (
                            <span>{targetValueZeroNum}</span>
                        )
                    }
                },
            },
            {
                title: '平均完成度（%）',
                dataIndex: 'finishDegree',
                key: 'finishDegree',
                align: 'center',
                render: (finishDegree, row) => {
                    if (this.isInArr(row.maxValueArr, finishDegree, 4)) {
                        return (
                            <Tag color='red' key={finishDegree}>
                                {finishDegree}
                            </Tag>
                        )
                    } else {
                        return (
                            <span>{finishDegree}</span>
                        )
                    }
                },
            },
        ]
        return (
            <div className={'DegreeCompletion'}>
                <div className={'StatisticsOfone_top'}>
                    <h3>指标完成情况概览</h3>
                    <div className={'ul'}>
                        <div className="graph-li" >
                            <h4>指标、成果支撑度数量情况（四级指标）</h4>
                            <div className="graph-dynamic">
                                <Dnamicmap indicatorOneLevel={schoolIndicatorOneLevel}/>
                            </div>
                        </div>
                        <div className="graph-li">
                            <h4>指标、成果支撑度占比情况（四级指标）</h4>
                            <div className="graph-dynamic">
                                <Dnamicmapa indicatorOneLevel={schoolIndicatorOneLevel}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'StatisticsOfone_cen'}>
                    <div className={'top_Overview'}>
                        <h3>院校资金情况总览</h3>
                        <div className="Overview_cont">
                            <OverviewTwo/>
                        </div>
                    </div>
                </div>
                <div className={'StatisticsOfone_bottom'}>
                    <h3>产出指标</h3>
                    <div className={'Output_ul'}>
                        <h4 className={'h6_active'}>数量指标</h4>
                        <div className={'bottom-table'}>
                            <Table columns={data} dataSource={tableList} pagination={false}
                                   locale={{emptyText:<><div className="Empty-content">
                                           <div className="text">
                                               <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                               <span>暂无数据</span>
                                           </div>
                                       </div></>}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DegreeCompletion = Form.create()(DegreeCompletion);
export default DegreeCompletion;
