/*角色权限设置*/
import React, {Component} from "react";
import {Drawer, Form, Select} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import Dnamicmap from './map1'
import Dnamicmapa from './map2'
import Achievements from '../achievementsshuju'
import Cookies from "js-cookie";
import SchoolRatingCheck from "../school_Examineshuju";
import {getSchoolAssessmentOverview,getSchoolInfoByEduCode,getExpertResultsSummary,getSchoolGroupId,getIndicatorsNumber} from '../../../../api/doublehigh/index'

const {Option} = Select;

@withRouter
class FinancialAssetShuju extends Component {
    state = {
        activeIndex: 1,//初始化时默认显示第一个li
        schoolIndicatorOneLevel: null,
        schoolEduCode: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.schoolEduCode : "",
        onPush:false,  //年度专家评审平均得分查看
        schoolInfo: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo : "",
        userInfo: JSON.parse(Cookies.get('ssInfo')).userInfo ? JSON.parse(Cookies.get('ssInfo')).userInfo : "",
        year: 2020
    }

    componentDidMount() {
        console.log('999------------')
        this.getSchoolInfoByEduCode()
        this.getExpertResultsSummary()
        this.getSchoolGroupId()
        this.getIndicatorsNumber()
        // this.getSchoolAssessmentOverview()
    }

    getIndicatorsNumber=()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            dimensionMark: null,
        }
        getIndicatorsNumber(data).then(res =>{
            if(res){
                this.setState({
                    indicatorsNumberInfo: res
                })

            }

        })
    }

    getSchoolInfoByEduCode = async () => {
        let { userInfo,schoolEduCode} = this.state;
        let schoolInfo = await getSchoolInfoByEduCode({schoolEduCode: schoolEduCode,userId: userInfo.id});
        this.setState({
            schoolInfo: schoolInfo
        }, () =>{
            this.expertsInfo()
            this.pingInfo()
        })
    }

    getSchoolGroupId = ()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode
        }
        getSchoolGroupId(data).then(res => {
            if(res){
                this.setState({
                    groupIdInfo: res
                })
            }
        })
    }

    expertsInfo =()=>{
        console.log('9999999', this.state.schoolInfo)
        let schoolInfo = this.state.schoolInfo
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            catgory: schoolInfo ? schoolInfo.catgory : '',
            gear: schoolInfo ? schoolInfo.gear : '',
            groupType: 1,
            year: '',
        }
        getSchoolAssessmentOverview(data).then(res =>{
            if(res){
                this.setState({
                    expertsInfo: res
                })
            }
        })
    }

    pingInfo =()=>{
        console.log('9999999', this.state.schoolInfo)
        let schoolInfo = this.state.schoolInfo
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            catgory: schoolInfo ? schoolInfo.catgory : '',
            gear: schoolInfo ? schoolInfo.gear : '',
            groupType: 2,
            year: '',
        }
        getSchoolAssessmentOverview(data).then(res =>{
            if(res){
                this.setState({
                    pingInfo: res
                })
            }
        })
    }

    getExpertResultsSummary =()=>{
        let data = {
            schoolEduCode: this.state.schoolEduCode
        }
        getExpertResultsSummary(data).then(res =>{
            console.log('res 111', res)
            if(res){
                this.setState({
                    summaryInfo: res
                })
            }
        })
    }

    //切换标签
    handleTab = (index) => {
        this.setState({activeIndex: index})
        //查询专业群
        // this.getSpecialtyGroupByspecialtyType(index)
    }
    handleBoox=()=>{
        this.setState({
            onPush:true,
            isExpertsOrPing: 1
        })
    }
    onClose = () =>{
        this.setState({
            onPush:false
        })
    }
    handleScore = () =>{
        // this.props.history.push('/main/doublehigh/specificyearDetails')
        this.props.history.push('/main/doublehigh/expertConsensusShuju')
    }
    handAnalysis = () =>{
        let indicatorsNumberInfo = this.state.indicatorsNumberInfo
        this.props.history.push({
            pathname: '/main/doublehigh/analysisPerformanceShuju',
            search: JSON.stringify({indicatorsNumberInfo})
        })
    }
    handMutual = () =>{
        let pingInfo = this.state.pingInfo
        let groupIdInfo = this.state.groupIdInfo
        this.props.history.push({
            pathname: '/main/doublehigh/detailsPeerShuju',
            search: JSON.stringify({pingInfo,groupIdInfo})
        })
    }
    render() {
        const {indicatorsNumberInfo,groupIdInfo,isExpertsOrPing,pingInfo,summaryInfo,expertsInfo,schoolInfo,schoolIndicatorOneLevel,onPush} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className={'FinancialAssetshuju_warp'}>
                <div className={'financialAsset'}>
                    <div className={'title_top'}>
                        <h3>“双高计划”项目建设评分情况</h3>
                        <div className="li-span">
                            <Form name="basic">
                                <Form.Item >
                                    <Select
                                        placeholder="年份"
                                        allowClear
                                        style={{width: "110px"}}
                                        value={'2'}
                                    >
                                        {/*<Select.Option value={'0'} key={'0'}>2019</Select.Option>*/}
                                        {/*<Select.Option value={'1'} key={'1'}>2020</Select.Option>*/}
                                        <Select.Option value={'2'} key={'2'}>2021</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <div className={'financialAsset_bottom'}>
                        <div className={'bottom_left'}>
                            <h4>同类型同档次院校年度建设成效评估概览</h4>
                            <span>年度：2021 类型：{schoolInfo && schoolInfo.catgory === 1 ? "学校" : "专业群"} 档次：{schoolInfo && schoolInfo.gear === 1 ? "A档" : schoolInfo && schoolInfo.gear === 2 ? "B档": schoolInfo && schoolInfo.gear === 3 ? "C档" :''}  结果如下</span>
                            <ul>
                                <li className={'li_cur'}  onClick={() => this.handleBoox()}>
                                    <span className={'icon'}>
                                        <img src={require('../../../../assets/imgschool/icon_a.png')}/>
                                        <i>点击查看详情</i>
                                    </span>
                                    <div className={'right_text'}>
                                        <h5>年度专家评审平均得分</h5>
                                        <h4>{expertsInfo ? expertsInfo.expertsAverage : ''}</h4>
                                        <div className={'ul_text'}>
                                            <span>
                                                <i>最高得分</i>
                                                <b>{expertsInfo ? expertsInfo.mixScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>最低得分</i>
                                                <b>{expertsInfo ? expertsInfo.minScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>本校情况</i>
                                                <b className={'red'}>{expertsInfo ? expertsInfo.schoolScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>学校排名</i>
                                                <b>No.{expertsInfo ? expertsInfo.ranking : ''}</b>
                                            </span>

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span className={'icon'}>
                                        <img src={require('../../../../assets/imgschool/icon_a.png')}/>
                                    </span>
                                    <div className={'right_text'}>
                                        <h5>年度专家评审中位数</h5>
                                        <h4>{expertsInfo ? expertsInfo.median : ''}</h4>
                                        <div className={'ul_text_wz'}>
                                            <div className={'left'}>
                                                <span>
                                                    高于中位数院校：<b>{expertsInfo ? expertsInfo.aboveMedianNum : ''}个</b>占比<b>{expertsInfo ? expertsInfo.aboveMedianAccounted : ''}%</b>
                                                </span>
                                                <span>
                                                    低于中位数院校：<b>{expertsInfo ? expertsInfo.belowMedianNum : ''}个</b>占比<b>{expertsInfo ? expertsInfo.belowMedianAccounted : ''}%</b>
                                                </span>
                                            </div>
                                            <div className={'right'}>
                                                <span>本校情况：<b>{expertsInfo && expertsInfo.shcoolSituation === 1 ? "高于中位数" : "低于中位数"}</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <h4>同类型同档次院校互评评估概览</h4>
                            <ul>
                                <li className={'li_cur'} onClick={() => this.handMutual()}>
                                    <span className={'icon'}>
                                        <img src={require('../../../../assets/imgschool/icon_b.png')}/>
                                        <i>点击查看详情</i>
                                    </span>
                                    <div className={'right_text'}>
                                        <h5>年度院校互评平均得分</h5>
                                        <h4>{pingInfo ? pingInfo.expertsAverage : ''}</h4>
                                        <div className={'ul_text'}>
                                            <span>
                                                <i>最高得分</i>
                                                <b>{pingInfo ? pingInfo.mixScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>最低得分</i>
                                                <b>{pingInfo ? pingInfo.minScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>本校情况</i>
                                                <b className={'red'}>{pingInfo ? pingInfo.schoolScore : ''}</b>
                                            </span>
                                            <span>
                                                <i>学校排名</i>
                                                <b>No.{pingInfo ? pingInfo.ranking : ''}</b>
                                            </span>

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span className={'icon'}>
                                        <img src={require('../../../../assets/imgschool/icon_b.png')}/>
                                    </span>
                                    <div className={'right_text'}>
                                        <h5>年度院校互评打分中位数</h5>
                                        <h4>{pingInfo ? pingInfo.median : ''}</h4>
                                        <div className={'ul_text_wz'}>
                                            <div className={'left'}>
                                                <span>
                                                    高于中位数院校：<b>{pingInfo ? pingInfo.aboveMedianNum : ''}个</b>占比<b>{pingInfo ? pingInfo.aboveMedianAccounted : ''}%</b>
                                                </span>
                                                <span>
                                                    低于中位数院校：<b>{pingInfo ? pingInfo.belowMedianNum : ''}个</b>占比<b>{pingInfo ? pingInfo.belowMedianAccounted : ''}%</b>
                                                </span>
                                            </div>
                                            <div className={'right'}>
                                                <span>本校情况：<b>{pingInfo && pingInfo.shcoolSituation === 1 ? "高于中位数" : "低于中位数"}</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={'bottom_right'} onClick={() => this.handleScore()}>
                            <i>年度综合评级</i>
                            <div className={'bg_icon'}>
                                <i className={'icon'}><img src={require('../../../../assets/imgschool/icon_x.png')}/></i>
                                <b>{expertsInfo && expertsInfo.reviewGrade === 1 ? "优" :
                                    expertsInfo && expertsInfo.reviewGrade === 2 ? "良" :
                                        expertsInfo && expertsInfo.reviewGrade === 3 ? "中" :
                                            expertsInfo && expertsInfo.reviewGrade === 4 ? "差" : ''}</b>
                            </div>
                            <i className={'red_i'}>点击查看评审意见</i>
                        </div>
                    </div>
                </div>
                <div className={'financialAsset_cen'}>
                    <h3>指标完成情况概览</h3>
                    <div className={'ul'}>
                        {/*<div className="graph-li" onClick={() => this.handAnalysis()}>*/}
                        {/*    <h4>指标、成果支撑度数量情况（四级指标）</h4>*/}
                        {/*    <div className="graph-dynamic">*/}
                        {/*        <Dnamicmap indicatorsNumberInfo = {indicatorsNumberInfo} indicatorOneLevel={schoolIndicatorOneLevel}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="graph-li" onClick={() => this.handAnalysis()}>
                            <h4>指标、成果支撑度占比情况（四级指标）</h4>
                            <div className="graph-dynamic">
                                <Dnamicmapa indicatorsNumberInfo = {indicatorsNumberInfo}indicatorOneLevel={schoolIndicatorOneLevel}/>
                            </div>
                        </div>
                        <div className={'graph-li_list'}>
                            <div className={'tablr'}>
                                <ul>
                                    <li>维度</li>
                                    <li>总数</li>
                                    <li>支撑度较高</li>
                                    <li>支撑度一般</li>
                                    <li>支撑度较弱</li>
                                </ul>
                                <ul>
                                    <li>绩效指标</li>
                                    <li>{(indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsHigh : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsGeneral : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsWeak : 0)}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsHigh : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsGeneral : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.indicatorsWeak : 0}</li>
                                </ul>
                                <ul>
                                    <li>视角</li>
                                    <li>{(indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveHigh : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveGeneral : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveWeak : 0)}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveHigh : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveGeneral : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.perspectiveWeak : 0}</li>
                                </ul>
                                <ul>
                                    <li>标志性成果</li>
                                    <li>{(indicatorsNumberInfo ? indicatorsNumberInfo.resultsHigh : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.resultsGeneral : 0) +
                                    (indicatorsNumberInfo ? indicatorsNumberInfo.resultsWeak : 0)}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.resultsHigh : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.resultsGeneral : 0}</li>
                                    <li>{indicatorsNumberInfo ? indicatorsNumberInfo.resultsWeak : 0}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'financialAsset_bot'}>
                    <h3>国家层面成果参考</h3>
                    <div className={'connt_b'}>
                        <div className={'landmark_Achievements'}>
                            <div className={'list_color'}>
                                <span className={'span1'}>党建思政</span>
                                <span className={'span2'}>人才培养</span>
                                <span className={'span3'}>师资队伍</span>
                                <span className={'span4'}>科研与社会服务</span>
                                <span className={'span5'}>学校治理</span>
                                <span className={'span6'}>信息化建设</span>
                                <span className={'span7'}>国际交流</span>
                            </div>
                            <Achievements schoolEduCode = {this.state.schoolEduCode} summaryInfo = {summaryInfo}/>
                        </div>
                        <div className={'bottom_right'}>
                            <i>产出国家层面成果</i>
                            <div className={'bg_icon'}>
                                <i className={'icon'}><img src={require('../../../../assets/imgschool/icon_v.png')}/></i>
                                <b>{summaryInfo ? summaryInfo.total : ''}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    title={
                        <div className='main-contWarp'>
                            <div className={'title_liwrp'}>
                                <div className= 'left-retu' onClick={this.onClose}>
                                    <span>返回</span>
                                </div>
                                <div className="Breadcrumbcont" style={{padding:'0 30px',}}>
                                    <div className="ant-breadcrumb"><span><span className="default">双高院校配置管理</span><span
                                        className="ant-breadcrumb-separator">/</span></span><span><span
                                        className="pointer">建设数据统计</span><span className="ant-breadcrumb-separator">/</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    placement="right"
                    className="SchoolRatingCheckshuju_war"
                    closable={false}
                    onClose={this.onClose}
                    visible={onPush}
                >
                    <SchoolRatingCheck groupIdInfo = {groupIdInfo} expertsInfo = {expertsInfo} onPush={onPush}/>
                </Drawer>
            </div>

        );
    }
}

FinancialAssetShuju = Form.create()(FinancialAssetShuju);
export default FinancialAssetShuju;
