/*角色权限设置*/
import React, {Component} from "react";
import {Form, Divider, Checkbox, Button} from "antd";
import "./index.scss";
import {getPermissionButton,getRoleById,savePermissionButton} from "../../api/roleauthorization"
import {withRouter} from "react-router";

@withRouter
class Roleauthorization extends Component {
    state = {
        //院系管理
        yxhavecheckedList: [],//被选中,id
        yxcheckAll: false,//院系——全选
        yxplainOptions: [],//按钮：['查看列表页面', '查看人数', '新建组织', '编辑', '删除']
        yxMenuId:"", //菜单Id
        yxhaveMenuCheck:false,//菜单是否被选中
        yxSelectListId:"6", //查询列表id
        //用户管理
        yhhavecheckedList: [],//被选中,id
        yhcheckAll: false,//用户全选
        yhplainOptions: [],//['查看列表页面', '查看用户详情', '新建用户', '编辑', '重置密码', '停用/恢复'],
        yhMenuId:"", //菜单Id
        yhhaveMenuCheck:false,//菜单是否被选中
        yhSelectListId:"11", //查询列表id

        //角色权限管理
        jshavecheckedList: [],//被选中,id
        jscheckAll: false,//角色权限全选
        jsplainOptions: [],//['查看列表页面', '新建角色', '分配用户', '设置', '编辑', '删除', '停用/恢复'],
        jsMenuId:"", //菜单Id
        jshaveMenuCheck:false,//菜单是否被选中
        jsSelectListId:"24", //查询列表id

        //学校与专业群管理
        xxhavecheckedList: [],//被选中,id
        xxcheckAll: false,//角色权限全选
        xxplainOptions: [],//['查看列表页面', '新建角色', '分配用户', '设置', '编辑', '删除', '停用/恢复'],
        xxMenuId:"", //菜单Id
        xxhaveMenuCheck:false,//菜单是否被选中
        xxSelectListId:"8", //查询列表id

        //数据同步管理
        sjhavecheckedList: [],//被选中,id
        sjcheckAll: false,//角色权限全选
        sjplainOptions: [],//['查看列表页面', '新建角色', '分配用户', '设置', '编辑', '删除', '停用/恢复'],
        sjMenuId:"", //菜单Id
        sjhaveMenuCheck:false,//菜单是否被选中

        //项目管理 ————建设任务管理
        xmhavecheckedList: [],//被选中,id
        xmcheckAll: false,//角色权限全选
        xmplainOptions: [],//['查看列表页面', '新建角色', '分配用户', '设置', '编辑', '删除', '停用/恢复'],
        xmMenuId:"", //菜单Id
        xmhaveMenuCheck:false,//菜单是否被选中

        //项目管理 ————建设任务管理
        jxhavecheckedList: [],//被选中,id
        jxcheckAll: false,//角色权限全选
        jxplainOptions: [],//['查看列表页面', '新建角色', '分配用户', '设置', '编辑', '删除', '停用/恢复'],
        jxMenuId:"", //菜单Id
        jxhaveMenuCheck:false,//菜单是否被选中


        roleInfo:{
            id:this.props.match.params.id,
            roleName:"",
            remark: ''
        },
        permissionChecks :[]
    }


    componentDidMount() {
        this.getRoleById()
        this.getPermissionButton()
        let contWidth = document.getElementById("contwidth")
        let footer = document.getElementsByClassName("footer")[0]
        footer.style.width = contWidth.clientWidth + "px"
        window.addEventListener('resize',function () {
            if(footer){
                footer.style.width = contWidth.clientWidth + "px"
            }

        })
    }

    // region api
    // 获取角色byid
    getRoleById = async ()=>{
        let data = {id:this.state.roleInfo.id}
        await getRoleById(data).then(res=>{
            this.setState({roleInfo:
                    {
                        id:this.state.roleInfo.id,
                        roleName:res.roleName,
                        remark:res.remark
                    }})
        })
    }
    // 获取权限
    getPermissionButton = async ()=>{
        let data = {id:this.state.roleInfo.id}
        await getPermissionButton(data).then(res=>{
            let menus = res
            for (let i = 0; i < menus.length; i++) {
                let children = menus[i].list
                if(menus[i].menuName == "院系管理"){
                    let yxplainOptions = []
                    let yxhavecheckedList = []
                    let yxcheckAll= true
                    let yxMenuId = menus[i].id
                    let yxhaveMenuCheck= false
                    if(menus[i].permission != null){
                        yxhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        yxplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            yxhavecheckedList.push(children[j].id)
                        }else{
                            yxcheckAll= false
                        }
                    }
                   this.setState({yxplainOptions,yxhavecheckedList,yxcheckAll,yxMenuId,yxhaveMenuCheck})
                }
                if(menus[i].menuName == "用户管理"){
                    let yhplainOptions = []
                    let yhhavecheckedList = []
                    let yhcheckAll= true
                    let yhMenuId = menus[i].id
                    let yhhaveMenuCheck= false
                    if(menus[i].permission != null){
                        yhhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        yhplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            yhhavecheckedList.push(children[j].id)
                        }else{
                            yhcheckAll = false
                        }
                    }
                    this.setState({yhplainOptions,yhhavecheckedList,yhcheckAll,yhMenuId,yhhaveMenuCheck})
                }
                if(menus[i].menuName == "角色权限管理"){
                    let jsplainOptions = []
                    let jshavecheckedList = []
                    let jscheckAll = true
                    let jsMenuId = menus[i].id
                    let jshaveMenuCheck= false
                    if(menus[i].permission != null){
                        jshaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        jsplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            jshavecheckedList.push(children[j].id)
                        }else{
                            jscheckAll = false
                        }
                    }
                    this.setState({jsplainOptions,jshavecheckedList,jscheckAll,jsMenuId,jshaveMenuCheck})
                }
                if(menus[i].menuName == "学校与专业群管理"){
                    let xxplainOptions = []
                    let xxhavecheckedList = []
                    let xxcheckAll = true
                    let xxMenuId = menus[i].id
                    let xxhaveMenuCheck= false
                    if(menus[i].permission != null){
                        xxhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        xxplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            xxhavecheckedList.push(children[j].id)
                        }else{
                            xxcheckAll = false
                        }
                    }
                    this.setState({xxplainOptions,xxhavecheckedList,xxcheckAll,xxMenuId,xxhaveMenuCheck})
                }

                if(menus[i].menuName == "数据同步管理"){
                    let sjplainOptions = []
                    let sjhavecheckedList = []
                    let sjcheckAll = true
                    let sjMenuId = menus[i].id
                    let sjhaveMenuCheck= false
                    if(menus[i].permission != null){
                        sjhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        sjplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            sjhavecheckedList.push(children[j].id)
                        }else{
                            sjcheckAll = false
                        }
                    }
                    this.setState({sjplainOptions,sjhavecheckedList,sjcheckAll,sjMenuId,sjhaveMenuCheck})
                }
                if(menus[i].menuName == "建设任务管理"){
                    let xmplainOptions = []
                    let xmhavecheckedList = []
                    let xmcheckAll = true
                    let xmMenuId = menus[i].id
                    let xmhaveMenuCheck= false
                    if(menus[i].permission != null){
                        xmhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        xmplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            xmhavecheckedList.push(children[j].id)
                        }else{
                            xmcheckAll = false
                        }
                    }
                    this.setState({xmplainOptions,xmhavecheckedList,xmcheckAll,xmMenuId,xmhaveMenuCheck})
                }
                if(menus[i].menuName == "绩效管理"){
                    let jxplainOptions = []
                    let jxhavecheckedList = []
                    let jxcheckAll = true
                    let jxMenuId = menus[i].id
                    let jxhaveMenuCheck= false
                    if(menus[i].permission != null){
                        jxhaveMenuCheck = true
                    }
                    for (let j = 0; j < children.length; j++) {
                        jxplainOptions.push({label:children[j].menuName,value:children[j].id})
                        if(children[j].permission != null){
                            jxhavecheckedList.push(children[j].id)
                        }else{
                            jxcheckAll = false
                        }
                    }
                    this.setState({jxplainOptions,jxhavecheckedList,jxcheckAll,jxMenuId,jxhaveMenuCheck})
                }
            }

        })
    }

    // 保存权限
    savePermissionButton = async (data) =>{
        await savePermissionButton(data).then(res=>{
            this.mesSuccess("权限保存成功")
        })
    }
    // endregion

    // region 菜单选择
    //院系菜单选择,联动列表
    menuonCheck = (e,value)=>{
        if(value === "院系管理"){
            this.setState({yxhaveMenuCheck:e.target.checked},()=>{
                this.menuJoinButton("院系管理")
            })
        }
        if(value === "用户管理"){
            this.setState({yhhaveMenuCheck:e.target.checked},()=>{
                this.menuJoinButton("用户管理")
            })
        }
        if(value === "角色权限管理"){
            this.setState({jshaveMenuCheck:e.target.checked},()=>{
                this.menuJoinButton("角色权限管理")
            })
        }
        if(value === "学校与专业群管理"){
            this.setState({xxhaveMenuCheck:e.target.checked},()=>{
                this.menuJoinButton("学校与专业群管理")
            })
        }
        if(value === "数据同步管理"){
            this.setState({sjhaveMenuCheck:e.target.checked})
        }
        if(value === "建设任务管理"){
            this.setState({xmhaveMenuCheck:e.target.checked})
        }
        if(value === "绩效管理"){
            this.setState({jxhaveMenuCheck:e.target.checked})
        }


    }
    // endregion

    // region 按钮权限选择
    // 院系按钮权限点击
    buttononChange = (checkedList, value) => {//院系点击事件
        if(value === "院系管理"){
            if(checkedList.length>0){
                this.setState({yxhaveMenuCheck:true})
            }
            this.setState({
                yxhavecheckedList: checkedList,
            },() => {
                this.setState({yxcheckAll: checkedList.length === this.state.yxplainOptions.length})
                this.buttonJoinMenu("院系管理",checkedList)
            });
        }
        if(value === "用户管理"){
            if(checkedList.length>0){
                this.setState({yhhaveMenuCheck:true})
            }
            this.setState({
                yhhavecheckedList: checkedList,
            },() => {
                this.setState({yhcheckAll: checkedList.length === this.state.yhplainOptions.length})
                this.buttonJoinMenu("用户管理",checkedList)
            });
        }
        if(value === "角色权限管理"){
            if(checkedList.length>0){
                this.setState({jshaveMenuCheck:true})
            }
            this.setState({
                jshavecheckedList: checkedList,
            },() => {
                this.setState({jscheckAll: checkedList.length === this.state.jsplainOptions.length})
                this.buttonJoinMenu("角色权限管理",checkedList)
            });
        }
        if(value === "学校与专业群管理"){
            if(checkedList.length>0){
                this.setState({xxhaveMenuCheck:true})
            }
            this.setState({
                xxhavecheckedList: checkedList,
            },() => {
                this.setState({xxcheckAll: checkedList.length === this.state.xxplainOptions.length})
                this.buttonJoinMenu("学校与专业群管理",checkedList)
            });
        }
        if(value === "数据同步管理"){
            if(checkedList.length>0){
                this.setState({sjhaveMenuCheck:true})
            }
            this.setState({
                sjhavecheckedList: checkedList,
            },() => {
                this.setState({sjcheckAll: checkedList.length === this.state.sjplainOptions.length})
            });
        }
        if(value === "建设任务管理"){
            if(checkedList.length>0){
                this.setState({xmhaveMenuCheck:true})
            }
            this.setState({
                xmhavecheckedList: checkedList,
            },() => {
                this.setState({xmcheckAll: checkedList.length === this.state.xmplainOptions.length})
            });
        }
        if(value === "绩效管理"){
            if(checkedList.length>0){
                this.setState({jxhaveMenuCheck:true})
            }
            this.setState({
                jxhavecheckedList: checkedList,
            },() => {
                this.setState({jxcheckAll: checkedList.length === this.state.jxplainOptions.length})
            });
        }

    };
    //用户管理点击事件
    yhonChange = (checkedList, value) => {
        this.setState({
            yhhavecheckedList: checkedList,
        },() => {
            this.setState({yhcheckAll: checkedList.length === this.state.yhplainOptions.length})
        });
    };

    //角色权限管理点击事件
    jsonChange = (checkedList, value) => {
        this.setState({
            jshavecheckedList: checkedList,
        },() => {
            this.setState({jscheckAll: checkedList.length === this.state.jsplainOptions.length})
        });
    };
    // endregion

    // region 全选操作

    //去选按钮事件
    onCheckAllChange = (e,value) => {
        if(value === "院系管理"){
            this.setState({
                yxhavecheckedList: e.target.checked ? this.getIds(this.state.yxplainOptions) : [],
                yxcheckAll: e.target.checked,
                yxhaveMenuCheck:e.target.checked,
            });
        }
        if(value === "用户管理"){
            this.setState({
                yhhavecheckedList: e.target.checked ? this.getIds(this.state.yhplainOptions) : [],
                yhcheckAll: e.target.checked,
                yhhaveMenuCheck:e.target.checked,
            });
        }
        if(value === "角色权限管理"){
            this.setState({
                jshavecheckedList: e.target.checked ? this.getIds(this.state.jsplainOptions) : [],
                jscheckAll: e.target.checked,
                jshaveMenuCheck:e.target.checked,
            });
        }
        if(value === "学校与专业群管理"){
            this.setState({
                xxhavecheckedList: e.target.checked ? this.getIds(this.state.xxplainOptions) : [],
                xxcheckAll: e.target.checked,
                xxhaveMenuCheck:e.target.checked,
            });
        }
        if(value === "数据同步管理"){
            this.setState({
                sjhavecheckedList: e.target.checked ? this.getIds(this.state.sjplainOptions) : [],
                sjcheckAll: e.target.checked,
                sjhaveMenuCheck:e.target.checked,
            });
        }
        if(value === "建设任务管理"){
            this.setState({
                xmhavecheckedList: e.target.checked ? this.getIds(this.state.xmplainOptions) : [],
                xmcheckAll: e.target.checked,
                xmhaveMenuCheck:e.target.checked,
            });
        }
        if(value === "绩效管理"){
            this.setState({
                jxhavecheckedList: e.target.checked ? this.getIds(this.state.jxplainOptions) : [],
                jxcheckAll: e.target.checked,
                jxhaveMenuCheck:e.target.checked,
            });
        }

    };
    // endregion

    //联动————勾选菜单去联动列表
    menuJoinButton = (value) => {
        if(value === "院系管理"){
            //院系菜单、院系列表联动————选中
            if(this.state.yxhaveMenuCheck){
                //不包含
                if(this.state.yxhavecheckedList.indexOf(this.state.yxSelectListId) === -1){
                    let list = this.state.yxhavecheckedList
                    list.push(this.state.yxSelectListId)
                    this.setState({yxhavecheckedList:list})
                }
            }
            //院系菜单、院系列表联动————不选中
            else{
                //包含
                if(this.state.yxhavecheckedList.indexOf(this.state.yxSelectListId) !== -1){
                    let list = this.state.yxhavecheckedList
                    list.splice(list.indexOf(this.state.yxSelectListId), 1)
                    this.setState({yxhavecheckedList:list})
                }
            }
        }
        if(value === "用户管理"){
            //院系菜单、院系列表联动————选中
            if(this.state.yhhaveMenuCheck){
                //不包含
                if(this.state.yhhavecheckedList.indexOf(this.state.yhSelectListId) === -1){
                    let list = this.state.yhhavecheckedList
                    list.push(this.state.yhSelectListId)
                    this.setState({yhhavecheckedList:list})
                }
            }
            //院系菜单、院系列表联动————不选中
            else{
                //包含
                if(this.state.yhhavecheckedList.indexOf(this.state.yhSelectListId) !== -1){
                    let list = this.state.yhhavecheckedList
                    list.splice(list.indexOf(this.state.yhSelectListId), 1)
                    this.setState({yhhavecheckedList:list})
                }
            }
        }
        if(value === "角色权限管理"){

            //院系菜单、院系列表联动————选中
            if(this.state.jshaveMenuCheck){

                //不包含
                if(this.state.jshavecheckedList.indexOf(this.state.jsSelectListId) === -1){
                    let list = this.state.jshavecheckedList
                    list.push(this.state.jsSelectListId)
                    this.setState({jshavecheckedList:list})
                }
            }
            //院系菜单、院系列表联动————不选中
            else{
                //包含
                if(this.state.jshavecheckedList.indexOf(this.state.jsSelectListId) !== -1){
                    let list = this.state.jshavecheckedList
                    list.splice(list.indexOf(this.state.jsSelectListId), 1)
                    this.setState({jshavecheckedList:list})
                }
            }
        }
        if(value === "学校与专业群管理"){

            //院系菜单、院系列表联动————选中
            if(this.state.xxhaveMenuCheck){

                //不包含
                if(this.state.xxhavecheckedList.indexOf(this.state.xxSelectListId) === -1){
                    let list = this.state.xxhavecheckedList
                    list.push(this.state.xxSelectListId)
                    this.setState({xxhavecheckedList:list})
                }
            }
            //院系菜单、院系列表联动————不选中
            else{
                //包含
                if(this.state.xxhavecheckedList.indexOf(this.state.xxSelectListId) !== -1){
                    let list = this.state.xxhavecheckedList
                    list.splice(list.indexOf(this.state.xxSelectListId), 1)
                    this.setState({xxhavecheckedList:list})
                }
            }
        }
    }

    //联动————去掉勾选列表，联动去掉勾选菜单
    buttonJoinMenu = (value,checkList) => {
        //判断是否是 去掉勾列表操作

        if(value === "院系管理"){
            if(checkList.indexOf(this.state.yxSelectListId) === -1){
                this.setState({yxhaveMenuCheck:false})
            }
        }
        if(value === "用户管理"){
            if(checkList.indexOf(this.state.yhSelectListId) === -1){
                this.setState({yhhaveMenuCheck:false})
            }
        }
        if(value === "角色权限管理"){
            if(checkList.indexOf(this.state.jsSelectListId) === -1){
                this.setState({jshaveMenuCheck:false})
            }
        }
        if(value === "学校与专业群管理"){
            if(checkList.indexOf(this.state.xxSelectListId) === -1){
                this.setState({xxhaveMenuCheck:false})
            }
        }

    }

    getIds = e =>{
        let list = []
        for (let i = 0; i < e.length; i++) {
            list.push(e[i].value)
        }
        return list
    }

    // region 保存权限
    save = () => {
        let yxhavecheckedList = this.state.yxhavecheckedList    //院系管理已选
        let yhhavecheckedList = this.state.yhhavecheckedList    //用户管理已选
        let jshavecheckedList = this.state.jshavecheckedList    //角色权限管理已选
        let xxhavecheckedList = this.state.xxhavecheckedList    //学校与专业群管理
        let sjhavecheckedList = this.state.sjhavecheckedList    //数据同步管理
        let xmhavecheckedList = this.state.xmhavecheckedList    //数据同步管理
        if(yxhavecheckedList.length > 0 && this.state.yxhaveMenuCheck === false){
            this.mesWarning("保存失败，请先勾选院系管理菜单")
            return false
        }
        if(yhhavecheckedList.length > 0 && this.state.yhhaveMenuCheck === false){
            this.mesWarning("保存失败，请先请勾选用户管理菜单")
            return false
        }
        if(jshavecheckedList.length > 0 && this.state.jshaveMenuCheck === false){
            this.mesWarning("保存失败，请先请勾选角色权限管理理菜单")
            return false
        }
        if(xxhavecheckedList.length > 0 && this.state.xxhaveMenuCheck === false){
            this.mesWarning("保存失败，请先请勾选学校与专业群管理菜单")
            return false
        }
        if(sjhavecheckedList.length > 0 && this.state.sjhaveMenuCheck === false){
            this.mesWarning("保存失败，请先请勾选数据同步管理菜单")
            return false
        }
        if(xmhavecheckedList.length > 0 && this.state.xmhaveMenuCheck === false){
            this.mesWarning("保存失败，请先请勾选建设任务管理菜单")
            return false
        }
        let menuIds = yxhavecheckedList.concat(yhhavecheckedList).concat(jshavecheckedList).concat(xxhavecheckedList).concat(sjhavecheckedList).concat(xmhavecheckedList)
        //院系管理已选
        if(this.state.yxhaveMenuCheck){
            menuIds.push(this.state.yxMenuId)
        }
        //用户管理已选
        if(this.state.yhhaveMenuCheck){
            menuIds.push(this.state.yhMenuId)
        }
        //角色权限管理已选
        if(this.state.jshaveMenuCheck){
            menuIds.push(this.state.jsMenuId)
        }
        //学校与专业群管理
        if(this.state.xxhaveMenuCheck){
            menuIds.push(this.state.xxMenuId)
        }
        //数据同步管理
        if(this.state.sjhaveMenuCheck){
            menuIds.push(this.state.sjMenuId)
        }
        //建设任务管理
        if(this.state.xmhaveMenuCheck){
            menuIds.push(this.state.xmMenuId)
        }
        //绩效管理
        if(this.state.jxhaveMenuCheck){
            menuIds.push(this.state.jxMenuId)
        }
        // return false
        let data = {
            menuIds:menuIds,
            id:this.state.roleInfo.id
        }
        this.savePermissionButton(data).then(res=>{
            this.props.history.push({ pathname: '/main/doublehigh/rolement'})
        })

    }
    // endregion


    reset = () =>{ //重置
        this.props.history.push({
            pathname: '/main/doublehigh/rolement',
        })
    }
    render() {
        const CheckboxGroup = Checkbox.Group
        const {roleInfo} = this.state
        return (
            <div className='quanxiansz_center1'>
                <div className='content' id={'contwidth'}>
                    <div className='cont-top'>
                        <div className='title'>
                            <p></p>
                            <div>{roleInfo.roleName}</div>
                        </div>
                    </div>
                    <Divider style={{margin: 0}}/>
                    <div className='alert'>
                        <span>·</span>
                        <p>{roleInfo.remark}</p>
                    </div>

                    <div className='caozuogroup'>
                        {/*院系管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"院系管理")}
                                    className='cbx cbx1'
                                    value={this.state.yxMenuId}
                                    checked={this.state.yxhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>院系管理</span>

                            </div>
                            <div className='checkgroup'>
                                <Checkbox
                                    onChange={(e)=>this.onCheckAllChange(e,"院系管理")}
                                    checked={this.state.yxcheckAll}

                                >
                                    全选
                                </Checkbox>
                                <CheckboxGroup
                                    options={this.state.yxplainOptions}
                                    value={this.state.yxhavecheckedList}
                                    onChange={(e)=>this.buttononChange(e,"院系管理")}
                                />
                            </div>
                        </div>
                        {/*学校与专业群管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"学校与专业群管理")}
                                    className='cbx cbx1'
                                    value={this.state.xxMenuId}
                                    checked={this.state.xxhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>学校与专业群管理</span>

                            </div>
                            <div className='checkgroup'>
                                <Checkbox
                                    onChange={(e)=>this.onCheckAllChange(e,"学校与专业群管理")}
                                    checked={this.state.xxcheckAll}

                                >
                                    全选
                                </Checkbox>
                                <CheckboxGroup
                                    options={this.state.xxplainOptions}
                                    value={this.state.xxhavecheckedList}
                                    onChange={(e)=>this.buttononChange(e,"学校与专业群管理")}
                                />
                            </div>
                        </div>
                        {/*用户管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"用户管理")}
                                    className='cbx cbx1'
                                    value={this.state.yhMenuId}
                                    checked={this.state.yhhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>用户管理</span>

                            </div>
                            <div className='checkgroup'>
                                <Checkbox
                                    onChange={(e)=>this.onCheckAllChange(e,"用户管理")}
                                    checked={this.state.yhcheckAll}

                                >
                                    全选
                                </Checkbox>
                                <CheckboxGroup
                                    options={this.state.yhplainOptions}
                                    value={this.state.yhhavecheckedList}
                                    onChange={(e)=>this.buttononChange(e,"用户管理")}
                                />
                            </div>
                        </div>
                        {/*角色权限管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"角色权限管理")}
                                    className='cbx cbx1'
                                    value={this.state.jsMenuId}
                                    checked={this.state.jshaveMenuCheck}
                                >
                                </Checkbox>

                                <span>角色权限管理</span>

                            </div>
                            <div className='checkgroup'>
                                <Checkbox
                                    onChange={(e)=>this.onCheckAllChange(e,"角色权限管理")}
                                    checked={this.state.jscheckAll}

                                >
                                    全选
                                </Checkbox>
                                <CheckboxGroup
                                    options={this.state.jsplainOptions}
                                    value={this.state.jshavecheckedList}
                                    onChange={(e)=>this.buttononChange(e,"角色权限管理")}
                                />
                            </div>
                        </div>
                        {/*数据同步管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"数据同步管理")}
                                    className='cbx cbx1'
                                    value={this.state.sjMenuId}
                                    checked={this.state.sjhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>数据同步管理</span>

                            </div>

                            <div className='checkgroup'>
                                <Checkbox
                                    onChange={(e)=>this.onCheckAllChange(e,"数据同步管理")}
                                    checked={this.state.sjcheckAll}

                                >
                                    全选
                                </Checkbox>
                                <CheckboxGroup
                                    options={this.state.sjplainOptions}
                                    value={this.state.sjhavecheckedList}
                                    onChange={(e)=>this.buttononChange(e,"数据同步管理")}
                                />
                            </div>

                        </div>

                        {/*建设任务管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"建设任务管理")}
                                    className='cbx cbx1'
                                    value={this.state.xmMenuId}
                                    checked={this.state.xmhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>建设任务管理</span>

                            </div>
                            {
                                this.state.xmplainOptions && this.state.xmplainOptions.length>0?
                                    <div className='checkgroup'>
                                        <Checkbox
                                            onChange={(e)=>this.onCheckAllChange(e,"建设任务管理")}
                                            checked={this.state.xmcheckAll}

                                        >
                                            全选
                                        </Checkbox>
                                        <CheckboxGroup
                                            options={this.state.xmplainOptions}
                                            value={this.state.xmhavecheckedList}
                                            onChange={(e)=>this.buttononChange(e,"建设任务管理")}
                                        />
                                    </div> :
                                    ""
                            }
                        </div>
                        {/*绩效管理*/}
                        <div className='yuanxi'>
                            <div className='title'>
                                <Checkbox
                                    onChange={(e)=>this.menuonCheck(e,"绩效管理")}
                                    className='cbx cbx1'
                                    value={this.state.jxMenuId}
                                    checked={this.state.jxhaveMenuCheck}
                                >
                                </Checkbox>

                                <span>绩效管理</span>

                            </div>
                            {
                                this.state.jxplainOptions && this.state.jxplainOptions.length>0?
                                    <div className='checkgroup'>
                                        <Checkbox
                                            onChange={(e)=>this.onCheckAllChange(e,"绩效管理")}
                                            checked={this.state.jxcheckAll}

                                        >
                                            全选
                                        </Checkbox>
                                        <CheckboxGroup
                                            options={this.state.jxplainOptions}
                                            value={this.state.jxhavecheckedList}
                                            onChange={(e)=>this.buttononChange(e,"绩效管理")}
                                        />
                                    </div> :
                                    ""
                            }
                        </div>

                    </div>
                </div>
                <div className='footer'>
                    <Button className='quanxiansz_btnReset' onClick={() => this.reset()}>取消</Button>
                    <Button className='quanxiansz_btnSave' onClick={() => this.save()}>保存</Button>
                </div>
            </div>

        );
    }
}

Roleauthorization = Form.create()(Roleauthorization);
export default Roleauthorization;
