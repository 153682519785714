// 任务与绩效进展走势——详情
import React, {Component} from "react";
import {Button, Dropdown, Form, Menu, Modal, Table, Tabs,} from "antd";
import {withRouter} from "react-router";
import {Rededitor,Readsign,Redcheck} from '../../../../../assets/svg'
import style from './index.module.scss'
import TaskPerformance from "../../taskPerformance";
import * as echarts from "echarts";
import TaskstatEditor from "../../newReportFile/taskstatEditor";
import {queryTaskMeritTrendDetail,editChartConfition,editChartConfitionValues,clearChartFilterValues } from "../../../../../api/chart/index"
import ScreeningCondition from "../../newReportFile/screeningCondition";
import Cookies from "js-cookie";

const { TabPane } = Tabs;

@withRouter
class Editorunnameds extends Component {
    state = {
        activeKey: 1,
        pageNum: 1,
        pageSize: 20,
        total:0,
        pageDataList: [],
        trendType:1,//1任务2绩效
        xData:[],
        yData:[],
        personOfCompletedVos:[],
        dateType:3,//1七日内 2一月内 3全年
        taskColumns: [
            {
                title: '任务',
                dataIndex: 'name',
                width: 200,
                align: "center",
                key: 'name',


            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'director',
                key: 'director',

            },
            {
                title: '完成日期',
                align: "center",
                width: 300  ,
                dataIndex: 'completeDate',
                key: 'completeDate',
                render: (text, record) =>
                    <div>
                        <span>{text.split("T")[0]}</span>
                    </div>
            },

        ],
        meritColumns: [
            {
                title: '绩效',
                dataIndex: 'name',
                width: 200,
                align: "center",
                key: 'name',


            },
            {
                title: '负责人',
                align: "center",
                width: 200,
                dataIndex: 'director',
                key: 'director',

            },
            {
                title: '完成日期',
                align: "center",
                width: 300  ,
                dataIndex: 'completeDate',
                key: 'completeDate',
                render: (text, record) =>
                    <div>
                        <span>{text.split("T")[0]}</span>
                    </div>
            },

        ],
        activeS:false,
        activeT:false,
        activeY:true,
        datanull: false,
        chartId:'',
        chartType:'',
        allConditionList:[],
        addConditionList:[],
        noAddConditionList:[],
        allConfitionValues:[],
        //筛选
        useChildFlag:false,//首次进入，子组件不调用接口，赋值后再调用
        screeningKey:0,//screening子组件控制加载
        schoolId: JSON.parse(Cookies.get('ssInfo')).schoolInfo ? JSON.parse(Cookies.get('ssInfo')).schoolInfo.id : "",
        previewFlag:0,//0:不是预览，1：是预览
    }
    componentDidMount() {
        this.queryTaskMeritTrendDetail()
    }
    //任务与绩效进展走势接口
    queryTaskMeritTrendDetail = async () => {
        let data = {
            trendType: this.state.trendType,
            dateType:this.state.dateType,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
        }
        await queryTaskMeritTrendDetail(data).then(res => {
            if(res){
                //趋势图
                let trendDatas = res.countOfCompletedVos
                let xData = []//x轴数据
                let yData = []//y轴数据
                let months = []//前推12个月数据
                //12个月
                if(this.state.dateType == 3){
                    for (let i = 0; i < 12; i++) {
                        let date = new Date()
                        date.setMonth(date.getMonth()-i)
                        months.push(this.dateFormat("YYYY-mm",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'月')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //7日
                else if(this.state.dateType ==1){
                    for (let i = 0; i < 7; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    console.log('months',months)
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                //30日
                else if(this.state.dateType ==2){
                    for (let i = 0; i < 30; i++) {
                        let date = new Date()
                        date.setDate(date.getDate()-i)
                        months.push(this.dateFormat("mm-dd",date))
                    }
                    months.reverse()
                    if(trendDatas){
                        let dataMonths = []
                        let dataCounts = []
                        for (let i = 0; i < trendDatas.length; i++) {
                            dataMonths.push(trendDatas[i].completeMonth)
                            dataCounts.push(trendDatas[i].completeCount)
                        }
                        //数据日期处理
                        for (let i = 0; i < months.length; i++) {
                            xData.push(months[i].split('-')[1]+'日')
                            if(dataMonths.includes(months[i])){
                                yData.push(dataCounts[dataMonths.indexOf(months[i])])
                            }else{
                                yData.push(0)//没有数据情况
                            }
                        }
                    }
                }
                this.setState({
                    xData:xData,
                    yData:yData,
                    personOfCompletedVos:res.personOfCompletedVos,
                    pageDataList:res.pageData.data,
                    pageNum:res.pageData.pageNum,
                    pageSize:res.pageData.pageSize,
                    total:res.pageData.total,
                    chartId:res.chartId,
                    chartType:res.chartType,
                    useChildFlag:true,
                    screeningKey:this.state.screeningKey+1,
                    previewFlag:0,
                },()=>{
                    if(yData.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.taskPerformance()
                        this.setState({datanull: false})
                    }
                })
                //负责人完成图
                this.setState({})
                //数据详情


            }

        })
    }

    dateFormat = (fmt, date) => {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    }
    //图表
    taskPerformance = () => {
        var xData2 = ["1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月"];
        var data1 = [30, 20, 30, 20, 20, 40,30, 20, 30, 20, 20, 40];
        if(document.getElementById('taskPerformance')) {
            let myChart = echarts.init(document.getElementById('taskPerformance'));
            let option = {
                tooltip: {
                    axisPointer: {
                        type: "shadow"
                    },
                    backgroundColor: 'rgba(51, 51, 51, 0.8)',
                    borderWidth: 0,
                    textStyle: {
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    formatter: function(params){
                        let res;
                        res = '<div>' +
                            '<div class="echarts-bar-tooltip" style="text-align: center">' +
                            '<span style="width: 6px;height: 6px;border-radius: 50%;display:block;float:left;margin: 6px 4px 0 0;background: #40a9ff"></span>'+params.name+'</div>' +
                            '<div style="text-align: center">'+params.value
                            + '个</div>' +
                            '</div>'
                        return res
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.state.xData
                },
                color: '#588BF7',
                grid: {
                    left: 30,
                    right: 30
                },
                yAxis: {
                    type: 'value',
                    minInterval:1,
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type: 'dashed',
                            color: '#F3F3F3'
                        }
                    }
                },
                series: [
                    {
                        data: this.state.yData,
                        type: 'bar',
                        barWidth: 20,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(238, 238, 238, 0.3)'
                        }
                    }
                ]
            };
            myChart.setOption(option)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    // 编辑
    handLook = () =>{
        this.setState({
            isreportEditor:true
        })
    }
    //tab切换
    onChangeTab = (e) =>{
        this.setState({
            trendType:e
        },()=>{
            this.queryTaskMeritTrendDetail()
        })
    }
    //1七日内
    sevenDayChange = ()=>{
        this.setState({dateType:1,activeS:true,activeT:false,activeY:false},()=>{
            this.queryTaskMeritTrendDetail()
        })
    }
    //2一月内
    ThirtyDayChange = ()=>{
        this.setState({dateType:2,activeS:false,activeT:true,activeY:false},()=>{
            this.queryTaskMeritTrendDetail()
        })
    }
    //3全年
    oneYearChange = ()=>{
        this.setState({dateType:3,activeS:false,activeT:false,activeY:true},()=>{
            this.queryTaskMeritTrendDetail()
        })
    }
    //翻页
    onChangePage = (pageNum, filters, sorter, extra)=>{
        //预览
        if(this.state.previewFlag == 1){
            this.previewModelChart(pageNum)
        }
        //非预览
        if (this.state.previewFlag == 0){
            this.setState({
                pageNum:pageNum,
            },()=>{
                this.queryTaskMeritTrendDetail()
            })
        }


    }
    //切页
    sizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.queryTaskMeritTrendDetail()
        })
    }

    //————————筛选操作——————————
    //编辑图表筛选条件api
    editChartConfition  =  async (data) => {
        await editChartConfition(data)
    }
    //编辑图表筛选条件的值api
    editChartConfitionValues =  async (data) => {
        await editChartConfitionValues(data).then(res=>{})
    }
    //清除筛选条件值
    clearChartFilterValues = async () => {
        await clearChartFilterValues({chartId:this.state.chartId})
    }
    //刷新数据
    refreshPageData = ()=>{
        this.queryTaskMeritTrendDetail()
    }
    //清除筛选
    clearCondition = () => {
        this.clearChartFilterValues().then(res=>{
            this.mesSuccess("清除成功")
            this.setState({initialValueOfOneDimension:'1'},()=>{
                this.refreshPageData()
            })

        })
    }
    //按钮保存(编辑筛选条件值、保存图表)
    saveOk = ()=>{
        let conditionValues = this.editCondition.getConditionValueChange()
        let dataPost = {
            chartId:this.state.chartId,
            conditionValuesQo:conditionValues
        }
        this.editChartConfitionValues(dataPost).then(res=>{
            this.mesSuccess("操作成功")
            this.refreshPageData()
        })
    }
    //编辑筛选条件
    editConditionOk = ()=>{
        if(!this.state.chartId){
            this.mesWarning('请先保存图表')
            return
        }
        let conditions = this.addCondition.getCheckValues()
        let data = []
        for (let i = 0; i < conditions.length; i++) {
            data.push({conditionId:conditions[i]})
        }
        this.editChartConfition({chartId:this.state.chartId,conditionValuesQo:data}).then(res=>{
            this.setState({isreportEditor: false,initialValueOfOneDimension:'1'},()=>{
                this.mesSuccess("操作成功")
                this.refreshPageData()
            })
        })

    }
    //导出任务图表
    exportExcel= ()=>{
        if (this.state.trendType==1){
            window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?" +
                "chartId="+this.state.chartId+"&schoolId="+this.state.schoolId+"&chartType="+4+"&trendType="+this.state.trendType+""
        }
        if (this.state.trendType==2){
            window.location.href = "http://"+window.location.hostname+":"+window.location.port+"/api/ss/api/v1/chart/exportExcel?" +
                "chartId="+this.state.chartId+"&schoolId="+this.state.schoolId+"&chartType="+5+"&trendType="+this.state.trendType+""
        }
    }
    //预览图表
    previewModelChart = async(pageNum) => {
        let chartConditionValuesQoList = this.editCondition.getConditionValueChange()
        let data = {
            pageNum: pageNum,
            pageSize: this.state.pageSize,
            schoolId:'',
            trendType:this.state.trendType,
            chartId:this.state.chartId,
            chartType:this.state.chartType,
            dateType:this.state.dateType,
            chartConditionValuesQoList:chartConditionValuesQoList,
            code:'1',//预览
        }
        await queryTaskMeritTrendDetail(data).then(res => {
            if(res){
                //趋势图
                let trendDatas = res.countOfCompletedVos
                let xData = []//x轴数据
                let yData = []//y轴数据
                let months = []//前推12个月数据
                for (let i = 0; i < 12; i++) {
                    let date = new Date()
                    date.setMonth(date.getMonth()-i)
                    months.push(this.dateFormat("YYYY-mm",date))
                }
                months.reverse()
                if(trendDatas){
                    let dataMonths = []
                    let dataCounts = []
                    for (let i = 0; i < trendDatas.length; i++) {
                        dataMonths.push(trendDatas[i].completeMonth)
                        dataCounts.push(trendDatas[i].completeCount)
                    }
                    for (let i = 0; i < months.length; i++) {
                        xData.push(months[i].split('-')[1]+'月')
                        if(dataMonths.includes(months[i])){
                            yData.push(dataCounts[dataMonths.indexOf(months[i])])
                        }else{
                            yData.push(0)//没有数据情况
                        }
                    }
                }
                this.setState({
                    xData:xData,
                    yData:yData,
                    personOfCompletedVos:res.personOfCompletedVos,
                    pageDataList:res.pageData.data,
                    pageNum:res.pageData.pageNum,
                    pageSize:res.pageData.pageSize,
                    total:res.pageData.total,
                    chartId:res.chartId,
                    chartType:res.chartType,
                    previewFlag:1,//是预览
                    // useChildFlag:true,
                    // screeningKey:this.state.screeningKey+1
                },()=>{
                    if(yData.length === 0){
                        this.setState({datanull: true})
                    }else{
                        this.taskPerformance()
                        this.setState({datanull: false})
                    }
                })
            }

        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {taskColumns,meritColumns,pageDataList,pageSize, total, pageNum,isreportEditor,personOfCompletedVos,activeS,activeT,activeY} = this.state
        let pagination = {
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['20', '40', '60', '80'],
            onChange: this.onChangePage,
            onShowSizeChange: this.sizeChange
        }
        return (
            <div className={style.editorunnameds}>
                <div className={'performanceindicator_head'}>
                    <div className={'taskprogress_left_s'}>
                        <p></p>
                        <span className={'span_task'}>任务与绩效进展走势</span>
                        {/*<span className={'span_svg'}><Rededitor></Rededitor></span>*/}
                    </div>
                    <div className={'task_right'}>
                        {/*//todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'button_topR'} onClick={()=>this.exportExcel()}>*/}
                        {/*    <span>导出</span>*/}
                        {/*</div>*/}
                        {/*<div className={'button_topRB'} onClick={()=>this.saveOk()}>*/}
                        {/*    <span>保存</span>*/}
                        {/*</div>*/}
                        {/*//todo 图表详情页无用图标暂时注掉 end*/}

                        {/*<Dropdown overlay={<Menu>*/}
                        {/*    <Menu.Item><span>订阅设置</span></Menu.Item>*/}
                        {/*</Menu>}>*/}
                        {/*    <div className={'button_topG'}>*/}
                        {/*        <span>更多</span>*/}
                        {/*    </div>*/}
                        {/*</Dropdown>*/}
                        {/*<div className={'button_topgb'} onClick={()=>{this.props.history.push('/main/doublehigh/dataStatisticsShow')}}>*/}
                        <div className={'button_topgb'} onClick={()=>{this.props.history.goBack()}}>
                            <span>返回</span>
                        </div>
                    </div>
                </div>
                <div className={'taskprogress_big_box'}>
                    <div className={'taskprogress_Lbox'}>
                        <div className={'taskprogress_left'}>
                            <div className='tab-wrap'>
                                <Tabs className='all' defaultActiveKey="" onChange={(e)=>this.onChangeTab(e)}>
                                    <TabPane tab={'任务'} key="1">
                                    </TabPane>
                                    <TabPane  tab={'绩效'} key="2">
                                    </TabPane>
                                </Tabs>
                                <div className={'riqi'}>
                                    <span className={activeS&&'active'} onClick={()=>{this.sevenDayChange()}}>7日</span>
                                    <span className={activeT&&'active'} onClick={()=>{this.ThirtyDayChange()}}>30日</span>
                                    <span className={activeY&&'active'} onClick={()=>{this.oneYearChange()}}>全年</span>
                                </div>
                                <div className={'task-performance-cont'}>
                                    <div className={'cont-left-echarts'}>
                                        <span className={'name'}>完成数量</span>
                                        {
                                            this.state.datanull ?
                                                <div style={{float:'left',width: '100%'}}>
                                                    <div className="Empty-conet-ss Empty-conet-ss-echarts">
                                                        <div className="img">
                                                            <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                            <span>暂无数据</span>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div id={'taskPerformance'} className={'echarts'} style={{width: '100%', height: '340px'}}></div>
                                        }
                                    </div>
                                    <div className={'cont-right-ranking'}>
                                        <span className={'name'}>负责人完成情况排名</span>
                                        <div className={'center-wrap'}>
                                            {
                                                personOfCompletedVos && personOfCompletedVos.map((item,index)=>(
                                                    <div className={'center'}>
                                                        <div className={'center-left'}>
                                                            <span className={'top'}>{index+1}</span>
                                                            <span className={'span-name'}>{item.completePerson}</span>
                                                        </div>
                                                        <span className={'center-right'}>{item.completeCount}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'taskprogress_listbox'}>
                            <div className={'taskprogress_listbox_box'}>
                                <div className={'taskprogress_title'}>
                                    {
                                        this.state.trendType==1?<span>任务进展走势</span>:<span>绩效进展走势</span>
                                    }
                                </div>
                                <div className={'border'}></div>
                                <div className={'table_t'}>
                                    <Table cellspacing={20} columns={this.state.trendType==1?taskColumns:meritColumns}
                                           className={'tableUserStyle'}
                                           dataSource={pageDataList}
                                           scroll={{ x: 700,y:290 }}
                                           onChange={this.changePage}
                                           pagination={pagination}
                                           rowKey={(r, i) => (r.id)}
                                           locale={{emptyText:<><div className="Empty-conet-ss">
                                                   <div className="img">
                                                       <img src={require("../../../../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                       <span>暂无数据</span>
                                                   </div>
                                               </div></>}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'taskprogress_right_box'}>
                        <div className={'taskprogress_right_span'}>
                            <span className={'span_icon'}>图表设置</span>
                        </div>
                        <div className={'title-wrap'}>
                            <div className={'title_fxwd title-first'}>
                                <span>分析维度:</span>
                            </div>
                            <div className={'title_fxwd border-bottom'}>
                                <span>绩效指标</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>数据设置:</span>
                            </div>
                            <div className={'title_fxwd'}>
                                <span>完成度</span>
                                <div className={'redchrvk'}><Redcheck></Redcheck></div>
                            </div>
                        </div>
                        {/*//todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'taskprogress_right_listbox'}>*/}
                        {/*    <span className={'span_licon'}>筛选</span>*/}
                        {/*</div>*/}
                        {/*<div className={'Filter'}>*/}
                        {/*    {*/}
                        {/*        <ScreeningCondition*/}
                        {/*            key={this.state.screeningKey}*/}
                        {/*            useChildFlag={this.state.useChildFlag}*/}
                        {/*            chartId={this.state.chartId}*/}
                        {/*            chartType={this.state.chartType}*/}
                        {/*            dimensionType = {this.state.initialValueOfOneDimension}*/}
                        {/*            editCondition ={(child)=>this.editCondition=child}*/}
                        {/*        />*/}
                        {/*    }*/}

                        {/*    <p className={'shaixuank'} onClick={()=>this.handLook()}>*/}
                        {/*        <span className={'Readsign_svg'}><Readsign></Readsign></span>*/}
                        {/*        <span className={'span_add'}>添加筛选条件</span>*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        {/*//todo 图表详情页无用图标暂时注掉 start*/}
                        <Modal
                            title={'筛选条件'}
                            visible={isreportEditor}
                            width={660}
                            scroll={{ x: 700 }}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            wrapClassName={style.Modal_Password}
                            footer={
                                <div className='modal_btn_ja'>
                                    <Button onClick={() => this.setState({isreportEditor: false})}>取 消</Button>
                                    <Button onClick={()=>this.editConditionOk()} type='primary'>确 定</Button>
                                </div>
                            }>
                            <TaskstatEditor
                                key={this.state.chartType}
                                useChildFlag={this.state.useChildFlag}
                                chartId={this.state.chartId}
                                chartType={this.state.chartType}
                                dimensionType = {this.state.initialValueOfOneDimension}
                                addCondition ={(child)=>this.addCondition=child}
                            />
                        </Modal>
                        {/* todo 图表详情页无用图标暂时注掉 start*/}
                        {/*<div className={'button_button'}>*/}
                        {/*    <div className={'button_away'}>*/}
                        {/*        <span onClick={()=>this.clearCondition()}>清除筛选</span>*/}
                        {/*    </div>*/}
                        {/*    <div className={'button_chart'}>*/}
                        {/*        <span onClick={()=>this.previewModelChart(1)}>预览图表</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*//todo 图表详情页无用图标暂时注掉 end*/}
                    </div>
                </div>
            </div>
        );
    }
}

Editorunnameds = Form.create()(Editorunnameds);
export default Editorunnameds;
