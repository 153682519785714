/*学校项目2020年度预算*/
import React, {Component} from 'react';
import {Card, Row, Col, Table} from 'antd';
import './schoolItemSumBudgetStat.scss';
import '../styles/global.scss';
import Cookies from "js-cookie";

import { investStat} from '@/api/synchrodatment';

class TotalSchoolBudgetTwo extends Component {
  state = {
    ifFefreshLeft:0,
    columns : [
      {
        title: '',
        dataIndex: 'seniorName',
        key: 'seniorName',
        width: 280,
        fixed: 'left',
        align: 'center',
      },
      {
        title: '小计',
        dataIndex: 'subtotal',
        key: 'subtotal',
        width: 100,
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'xjMoney',
            key: 'xjMoney',
            width: 120,
            align: 'center',
          },
        ]
      },
      {
        title: '中央财政投入',
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'zyMoney',
            key: 'zyMoney',
            width: 110,
            align: 'center',
          },
          {
            title: '比例（%）',
            dataIndex: 'zyScale',
            key: 'zyScale',
            width: 110,
            align: 'center',
          },
        ],
      },
      {
        title: '地方各级财政投入资金（万元）',
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'dfMoney',
            key: 'dfMoney',
            width: 110,
            align: 'center',
          },
          {
            title: '比例（%）',
            dataIndex: 'dfScale',
            key: 'dfScale',
            width: 110,
            align: 'center',
          },
        ],
      },
      {
        title: '举办方投入资金（万元）',
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'jbfMoney',
            key: 'jbfMoney',
            width: 110,
            align: 'center',
          },
          {
            title: '比例（%）',
            dataIndex: 'jbfScale',
            key: 'jbfScale',
            width: 110,
            align: 'center',
          },
        ],
      },
      {
        title: '行业企业支持资金（万元）',
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'hyMoney',
            key: 'hyMoney',
            width: 110,
            align: 'center',
          },
          {
            title: '比例（%）',
            dataIndex: 'hyScale',
            key: 'hyScale',
            width: 110,
            align: 'center',
          },
        ],
      },
      {
        title: '学校自筹资金（万元）',
        align: 'center',
        children: [
          {
            title: '资金（万元）',
            dataIndex: 'xxMoney',
            key: 'xxMoney',
            width: 110,
            align: 'center',
          },
          {
            title: '资金比例（%）',
            dataIndex: 'xxScale',
            key: 'xxScale',
            width: 110,
            align: 'center',
          },
        ],
      },
    ],
    data:[
      {
        name:'加强党的建设',
        xjfund:'610',
      },
      {
        name:'合计资金(万元)',
        fund:'610',
      }
    ],
    taskId:  JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId,
    upSeniorId: "3.3",
    investStatList: [],
  };

  componentDidMount() {
    this.setState({
      taskId:  JSON.parse(Cookies.get('ssInfo')).schoolInfo.taskId
    },() => {
      this.investStat();
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  investStat = async () => {
    let {taskId, upSeniorId} = this.state;
    let investStatList = await investStat({taskId: taskId, upSeniorId: upSeniorId});
    console.log("investStatList:", investStatList)
    this.setState({investStatList: investStatList})
  }

  setCode=()=>{

  }
  previous = async () => {
    this.props.history.push('/MajorFirstGroupBudget')
  }
  next = async () => {
    this.props.history.push('/MajorFirstGroupYearBudget')
  }
  render() {
    const {columns,investStatList} = this.state;
    return (
        <div className="SchoolItemSumBudgetStat">
          <div className="TotalSchoolBudget-bottom clearfix">
            <div className="">
              <Row className="row">
                <Col span={24}>
                  <Card className="card-main clearfix">

                    <Row>
                      <Col span={24} className="col-list-table">
                        <h5>专业群一项目总预算</h5>
                        <div className="cont-table">
                          <Table
                              columns={columns}
                              dataSource={investStatList}
                              pagination={false}
                              bordered
                              size="middle"
                              scroll={{ x: 1500}}
                          />
                        </div>
                        <div className="aggregate-cont">
                          注：比例（%）=当前类型投入资金数*100/所属建设内容合计投入资金数
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
    );
  }
}

export default TotalSchoolBudgetTwo;
