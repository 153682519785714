import React from 'react'
import { GetImageBaseUrl, ImagePreview } from 'gongyongpicpreview'

class GongyongImagePreview extends React.Component {
    componentWillUnmount() {
        document.body.classList.remove('pic_image_preview_add_body')
    }

    render() {
        console.log('this.props.curPreviewt',this.props.curPreview)
        return (
            <>
                <style>
                {`
                    .pic_image_preview_wrappers{
                        z-index:99999999;
                        position:fixed;
                        top:0;
                        left:0;
                    }
                `}
                </style>
                <GetImageBaseUrl
                    imageBaseUrl={window.$$imgSrcLcl}
                    style={{zIndex: '9999999999'}}
                >
                    {
                        this.props.visible && (
                            <ImagePreview
                                visible={this.props.visible}
                                ImgList={this.props.imglist}
                                onCancel={this.props.cancel}
                                curPreview={this.props.curPreview}
                            />
                        )
                    }
                </GetImageBaseUrl>
            </>
        )
    }
}

export default GongyongImagePreview

