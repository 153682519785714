import React from 'react';

//失败
const SvgError = props => {
    return <svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10.8491" cy="10.3208" r="10" fill="white" />
        <path d="M10.8491 0.320801C5.32628 0.320801 0.849121 4.79796 0.849121 10.3208C0.849121 15.8437 5.32628 20.3208 10.8491 20.3208C16.372 20.3208 20.8491 15.8437 20.8491 10.3208C20.8491 4.79794 16.372 0.320801 10.8491 0.320801ZM14.8203 13.3006C15.0939 13.5744 15.0939 14.0183 14.8203 14.2918C14.5462 14.5656 14.1025 14.5656 13.8285 14.2918L10.854 11.3173L7.87936 14.2918C7.60552 14.5656 7.16179 14.5656 6.88775 14.2918C6.61408 14.0183 6.61408 13.5744 6.88775 13.3006L9.86257 10.3253L6.88775 7.35084C6.61408 7.0774 6.61408 6.63309 6.88775 6.35925C7.16177 6.0858 7.60549 6.0858 7.87936 6.35925L10.854 9.33409L13.8285 6.35925C14.1025 6.0858 14.5462 6.0858 14.8202 6.35925C15.0939 6.63309 15.0939 7.07744 14.8202 7.35084L11.8454 10.3253L14.8202 13.3006H14.8203Z" fill="#40a9ff" />
    </svg>
}

export default SvgError;