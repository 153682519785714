/*角色上面搜索*/
import React, {Component} from "react";
import {
    Form, Input, Select, Button,
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
const {Option} = Select;
@withRouter
class Department extends Component {
    state = {

    }

    componentDidMount() {

    }
    handleReset = () => { //重置
        this.props.form.resetFields();
    };
    toParent = () => { //传值给父组件
        let rolename = this.props.form.getFieldValue('rolename')
        let courseType = this.props.form.getFieldValue('courseType')
        let msg = [{rolename, courseType}]
        this.props.parent.getChildrenMsg(this, msg)
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='role-search_center'>
                <Form className="Learnform">
                    <div>
                        <span className="li-span">
                            <i className="i">分类</i>
                            {
                                getFieldDecorator('courseType', {
                                    initialValue: '0'
                                })(
                                    <Select defaultValue="0" style={{width: 230}}>
                                        <Option value="0">全部</Option>
                                        <Option value="1">正常</Option>
                                    </Select>
                                )
                            }
                        </span>
                            <span className="li-span">
                                <i className="i">角色名称</i>
                                {
                                    getFieldDecorator('rolename', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder="请输入角色名称" className='input' autocomplete="off"/>
                                    )
                                }
                            </span>
                    </div>
                    <span className="li-span1">
                            <Button onClick={this.handleReset}>重 置</Button>
                            <Button className='chaxun' onClick={ this.toParent }>查 询</Button>
                        </span>
                </Form>
            </div>

        );
    }
}

Department = Form.create()(Department);
export default Department;
