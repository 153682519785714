/*院校管理*/
import React, {Component} from "react";
import {
    Button, Table, Input, Select, Modal, Form,Cascader,Tooltip,
} from "antd";
import "./index.scss";
import "../../styles/global.less"
import {withRouter} from "react-router";
import {getOrganizationTreeList,getOrganizationTypeList,deleteOrganization,getOrganizationById,addOrganization,editOrganization,getUserList} from "../../api/organizationManage"
import RenderButton from "../../components/renderButton/index.js";
import S from "gongyongicon";
const { TextArea } = Input;

@withRouter
class Department extends Component {
    state = {
        textAreaValue:"",
        total: 0,
        pageNum: 1,
        pageSize: 10,
        allPlanList: [],
        searchValue: '',
        count:0,
        search:{//给接口的参数（对象）
            orgName:"",//文本框
            orgType:""//下拉选
        },
        optionList:[],
        userOptions:[],//用户选择框// 机构名称

        // 列名
        columns: [
            {title: '院校组织层级', dataIndex: 'orgName', key: 'orgName',align: 'left',width: 430,},
            {title: '分类 ', dataIndex: 'orgTypeName', key: 'orgTypeName', align: 'center',width: 200},
            {title: '负责人', dataIndex: 'userName', key: 'userName', align: 'center',width: 200},
            {title: '手机号码',  dataIndex: 'userPhone', key: 'managerNum', align: 'center',width: 200},
            {
                title: '人数', dataIndex: 'sumCount', key: 'sumCount', align: 'center',width: 200,
                render: (text, record) => record.sumCount !==0?
                    <Tooltip title="查看该组织及所有下级组织成员">
                        <a onClick={() => this.goToUserPage(record)} style={{'color':'#40a9ff'}}>{record.sumCount}</a>
                    </Tooltip>
                    :<span >{record.sumCount}</span>
            },
            {
                title: '备注', dataIndex: 'remark', key: 'remark', align: 'center',width: 300,
                render: (text, record) =>
                    <Tooltip  placement="bottom" title={text}
                              overlayClassName='TitleTooltip'
                    >
                    <span>{text}</span>
                    </Tooltip>
            },
            {
                title: '操作', align: 'center',
                width: 220,fixed:"right",
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => <p className='render-span vertical_j oper'>
                    <div>

                        <RenderButton id='SS-01-B01-b_btnEditHierarchy'>
                        <span onClick={() => this.addDept(record)}>编辑</span>
                        </RenderButton>
                        <RenderButton id='SS-01-B01-b_btnDelHierarchy'>

                        <span onClick={() => this.gotoDel(record)}>删除</span>
                        </RenderButton>
                    </div>
                </p>
            }
        ],


        dataSource:[],//组织列表
        orgOptions:[],//组织下拉框
        planStatus: "1",
        newDept: false, //新建组织
        deleteVisible:false,
        editFlag: false,//编辑组织
        tableLoading: true,
        deleteOrgId:"",//删除组织Id
        editOrgId:"",//编辑组织Id
        orgInfo:{
            isHighestLevel:false,//是否为最高层级
        },//组织信息
        defaultExpandAllRows:''


    }
    // 初始化
    componentDidMount() {
        this.getOrganizationTypeList();
        this.getOrganizationTreeList();
        this.getUserList();
        this.opendefaultExpandAllRows()//展开所有行数据
    }

    //展开所有行数据
    opendefaultExpandAllRows=()=>{
        this.setState({defaultExpandAllRows:true})
    }
    //获组织列表
    getOrganizationTreeList = async () => {
        let data = {
            orgName: this.state.search.orgName,
            orgType: this.state.search.orgType
        }
        await getOrganizationTreeList(data).then(e => {
            this.setState({dataSource: e})
            this.setState({orgOptions: e})
        })
    }
    // Cascader显示最后一级
    displayRender=(label) => {
        return label[label.length - 1];
    }
    goToUserPage(record){
        let genOrgId = record.id
        this.props.history.push({ pathname: '/main/doublehigh/userManagement', query: { genOrgId: genOrgId } })
    }
    //获取组织分类
    getOrganizationTypeList = async () => {
        await getOrganizationTypeList().then(e => {
            this.setState({optionList: e})
        })
    }
    //获取用户列表
    getUserList = async () => {
        await getUserList().then(e => {
            this.setState({userOptions: e})
        })
    }
    //组织删除
    deleteOrganization = async (data) => {
        await deleteOrganization(data).then(e => {
            //
        })
    }
    //组织新增
    addOrganization = async (data) => {
        await addOrganization(data).then(e => {
            this.mesSuccess("组织新增成功")
        })
    }
    //组织编辑
    editOrganization = async (data) => {
        await editOrganization(data).then(e => {
            this.mesSuccess("组织编辑成功")
        })
    }
    getOrganizationById = async (data)=>{
        await getOrganizationById(data).then(e=>{
            this.setState({orgInfo:{
                    id:e.id,
                    parentId:e.relation,//上级组织
                    orgName:e.orgName,//组织名称
                    orgType:e.orgType,//组织分类
                    userId:e.userId,//负责人
                    userName:e.userName,//负责人
                    userPhone:e.userPhone,//手机号码
                    remark:e.remark,
                    isHighestLevel:e.parentId == "0" ? true :false
                }})
        })
    }

    //新建or编辑组织——提示
    addDept=(record)=>{
            this.setState({newDept:true,textAreaValue:record.remark},()=>{
            //编辑
            if(record){
                this.getOrganizationById({id:record.id}).then(e=>{
                    this.setState({editFlag: true,editOrgId:record.id})

                    this.setState({editFlag: true,editOrgId:record.id,count:record.remark != null ? record.remark.length:0},()=>{
                        this.props.form.setFieldsValue({
                            parentId:this.state.orgInfo.parentId,//上级组织
                            orgName:this.state.orgInfo.orgName,//组织名称
                            orgType:this.state.orgInfo.orgType,//组织分类
                            userId:this.state.orgInfo.userId,//负责人
                            userName:this.state.orgInfo.userName,//负责人
                            userPhone:record.userPhone,//手机号码
                            remark:this.state.orgInfo.remark,
                        })
                    })
                })

            }
            //新增
            else{
                this.setState({
                    editFlag: false,
                    orgInfo:{isHighestLevel:false}
                },);

            }
        });

    }
    //新建/编辑————取消按钮
    addHandleCancel = () =>{
        this.props.form.resetFields();
        this.setState({newDept: false,count:0})
    }
    //新建/编辑————确认按钮
    addHandleOk = () =>{
        let parentId = this.props.form.getFieldValue('parentId')
        let orgName = this.props.form.getFieldValue('orgName')
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let parnentIds = this.props.form.getFieldValue('parentId');
                //编辑
                if(this.state.editFlag && this.state.editROleId != ""){

                    let data = {
                        id:this.state.editOrgId,
                        parentId : parnentIds[parnentIds.length-1],
                        orgName : this.props.form.getFieldValue('orgName'),
                        orgType : this.props.form.getFieldValue('orgType'),
                        userId : this.props.form.getFieldValue('userId'),
                        userPhone : this.props.form.getFieldValue('userPhone'),
                        remark : this.props.form.getFieldValue('remark') //
                    }
                    this.editOrganization(data).then(()=>{
                        this.setState({newDept:false,editOrgId:"",count:0})
                        this.getOrganizationTreeList()
                    })
                }
                //新增
                else{
                    let data = {
                        parentId : parnentIds[parnentIds.length-1],
                        orgName : this.props.form.getFieldValue('orgName'),
                        orgType : this.props.form.getFieldValue('orgType'),
                        userId : this.props.form.getFieldValue('userId'),
                        remark : this.props.form.getFieldValue('remark')
                    }
                    this.addOrganization(data).then(()=>{
                        this.setState({newDept:false,editOrgId:"",count:0})
                        this.getOrganizationTreeList()
                    })
                }
            }

        });

    }


    // 删除
    gotoDel = (record) => {
        if(record.parentId === "0"){
            this.mesWarning("该组织机构不能被删除")
            return false
        }
        this.setState({deleteVisible: true,deleteOrgId:record.id})
    }
    //取消删除
    deleteHandleCancel = () =>{
        this.setState({deleteVisible: false,deleteOrgId:""})
    }
    //确定删除
    deleteHandleConfirm = async() =>{
        let data = {
            id : this.state.deleteOrgId
        }
        await deleteOrganization(data).then(res =>{
            this.mesSuccess("删除成功")
            this.setState({deleteVisible: false})
            this.getOrganizationTreeList()
        })
    }
    //获取文本款输入的值e是文本框，值需要再取value
    inputClick(e){
        this.state.search.orgName=e.target.value
    }

    //获取下拉选选择的值
    onGenderChange(e){
        this.state.search.orgType=e
    }

    //检索查询
    selectInfo = () => {
        this.getOrganizationTreeList();
    }


    // 重置
    reset() {
        this.props.form.resetFields()
    }
    //检索查询
    async getCheckList() {
        const { pagination, searchValue ,} = this.state
    }

    //备注value事件，计算字数放在count里回显到界面上
    marksClick(e){
        this.setState({
            count:e.target.value.length
        })
    }

    selectlabel = () =>{
        let {roleName, courseType} = this.state
    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
            //列表方法
            this.selectlabel()
        })
    }
    showSizeChange = (current, size) => {
        this.setState({pageSize: size},() => {
            this.selectlabel()
        })
    }
    onUserChange = (e)=>{
        let users = this.state.userOptions
        for (let i = 0; i <users.length ; i++) {
            if(e === users[i].id){
                this.props.form.setFieldsValue({userPhone:users[i].phone})
                break;
            }
        }
    }
    //点人数跳转页面
    gotoUserManagement=()=>{

        this.props.history.push(
        `/main/doublehigh/userManagement?${encodeURI(
            JSON.stringify(
            )
        )}`
    )}




    render() {
        const {getFieldDecorator} = this.props.form;
        const style={position: "absolute",
            top: "75px",
            right: "-345px"}
        let {columns, dataSource,
            newDept,deleteVisible,editFlag,count,
            optionList,textAreaValue,orgOptions,userOptions,defaultExpandAllRows} = this.state;

        return (
            <div className='cloumn_center'
                 style={{'background': '#f0f2f5'}}
    >
                <div className="content">
                    <div className="cont-top">
                        <div className="title">
                            <p></p>
                            <div>院系管理</div>
                        </div>
                        <RenderButton id = "SS-01-B01-b_btnAddHierarchy">
                        <Button className="add-user"   onClick={()=>{this.addDept('')}}>新建组织</Button>
                        </RenderButton>
                   </div>
                    <div className="tabl">
                        {
                            dataSource&&dataSource.length === 1 && dataSource[0]=== null ? '' : dataSource && dataSource.length ?
                                <Table columns={columns}
                                       defaultExpandAllRows={defaultExpandAllRows}
                                       className='table-Style'
                                       scroll={{ x: 1500, y: 720 }}
                                       dataSource={dataSource}
                                       pagination={false}
                                       rowKey={(r, i) => (r&&r.id)}
                                       locale={{emptyText:<><div className="Empty-conet-ss">
                                               <div className="img">
                                                   <img src={require("../../assets/imgnew/AE_empty.jpg")} alt={''}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div></>}}
                        /> : ''
                        }
                    </div>
                </div>
                {/*新建组织*/}
                <Modal
                    width={660}
                    title={editFlag ? '编辑组织' : '新建组织'}
                    className='histy_modal_con histy_modal_con_zuzhi'
                    visible={newDept}
                    destroyOnClose={true}
                    closable={false}
                    onOk={this.addHandleOk}
                    onCancel={this.addHandleCancel}
                    maskClosable={false}
                    centered={true}
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} colon={false}>
                        <Form.Item label="上级组织">
                            {
                                getFieldDecorator('parentId', {
                                    rules: [{
                                            required: !this.state.orgInfo.isHighestLevel,
                                            message: '上级组织不能为空'}]
                                })

                                (<Cascader fieldNames={{ label: 'orgName', value: 'id', children: 'children' }}
                                           options={orgOptions}
                                           popupPlacement='bottomLeft'
                                           changeOnSelect
                                           displayRender ={this.displayRender}
                                           disabled={this.state.orgInfo.isHighestLevel}
                                           getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                placeholder="请选择上级组织" />)


                            }
                        </Form.Item>

                        <Form.Item label="组织名称">
                            {
                                getFieldDecorator('orgName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '组织名称不能为空'
                                        },
                                        {
                                            type: 'string',
                                            min: 2,
                                            max: 15,
                                            message: '组织名称不能小于2或大于15字符!',
                                        },
                                    ]
                                })(<Input
                                    placeholder="请输入组织名称"
                                    disabled={this.state.orgInfo.isHighestLevel}
                                    autocomplete="off"
                                />)
                            }
                        </Form.Item>

                        <Form.Item label="分类">
                            {
                                getFieldDecorator('orgType', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '分类不能为空'
                                        }
                                    ]
                                })(<Select defaultValue="lucyA" placeholder="请选择分类"
                                           disabled={this.state.orgInfo.isHighestLevel}
                                           getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {
                                        optionList&&optionList.map(item=>
                                            <Select.Option value={item.dicKey}>{item.dicValue}</Select.Option>

                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label="负责人">
                            {
                                getFieldDecorator('userId', {

                                })(<Select
                                    defaultValue=""
                                    placeholder="请选择负责人"
                                    onChange={(e)=>this.onUserChange(e)}
                                    showSearch="true"
                                    optionFilterProp="children"
                                    getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
                                >
                                    {
                                        userOptions&&userOptions.map(item=>
                                            <Select.Option value={item.id}>{item.humanName}</Select.Option>
                                        )
                                    }

                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label="手机号码">
                            {
                                getFieldDecorator('userPhone', {
                                    rules: [
                                        {

                                            // pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                                            pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|16[6]|18[0|1|2|3|4|5|6|7|8|9]|17[3|5|6|7|8]|19[9|8])\d{8}$/,
                                            message: '请输入正确手机号码'
                                        }
                                    ]
                                })(<Input placeholder="请输入手机号码" disabled={true} autocomplete="off"/>)
                            }
                        </Form.Item>

                        <Form.Item label="备注">
                            {
                                getFieldDecorator('remark')(
                                    <div>
                                        <TextArea maxLength={50}
                                                  placeholder="请输入备注"
                                                  style={{width: '100%', resize: 'none'}}
                                                  onChange={(e)=>this.marksClick(e)}
                                                  rows={5}
                                                  defaultValue={textAreaValue}
                                        />
                                        <div className="user-management-count">{count}/50</div>
                                    </div>
                               )
                            }
                        </Form.Item>
                    </Form>
                </Modal>


                {/*删除弹窗*/}
                <Modal
                    title="温馨提示"
                    visible={deleteVisible}
                    onOk={this.deleteHandleConfirm}
                    onCancel={this.deleteHandleCancel}
                    width={450}
                    closable={false}
                    className='modaltishi'
                    maskClosable={false}
                    centered={true}
                >
                    <div className={'mWarningCircle'}>
                        <S.SvgWarningCircle/>
                    </div>
                    <p>删除该组织如包含下级组织将一并删除，是否继续？</p>
                </Modal>
           </div>

        );
    }
}

Department = Form.create()(Department);
export default Department;
