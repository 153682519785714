
import LoadableComponent from '../until/LoadableComponent'
import ConstructionShuju from "../page/constructionShuju";
let moduleFile = require.context('../page', true, /\index.js$/);
let result = moduleFile.keys().reduce((prev, item) => {
    let str = item.split('/')[item.split('/').length - 2];
    let name = str[0].toLocaleUpperCase() + str.slice(1);
    let aa = moduleFile(item).default;
    prev = Object.assign({}, prev, { [name]: LoadableComponent(import('../page' + item.slice(1))) })
    // prev = Object.assign({}, prev, { [name]: aa })
    return prev
}, {});
result.DocumentManagement = undefined;

console.log(result)
export default [
    {
        name: "首页",
        path: "/",
        redirect: '/main/doublehigh/workbench',
    },
    {
        name: "首页",
        path: "/main",
        component: result.Main,
        children: [
            //大国工匠
            {
                name: '双高院校配置管理',
                path: '/main/doublehigh',
                component: result.Doublehigh,
                children: [
                    {
                        path: '/main/doublehigh',
                        redirect: '/main/doublehigh/department'
                    },
                    {
                        name: '院系管理',
                        path: '/main/doublehigh/department',
                        component: result.Department
                    },
                    {
                        name: '用户管理',
                        path: '/main/doublehigh/userManagement',
                        component: result.UserManagement
                    },
                    {
                        name: '角色权限管理',
                        path: '/main/doublehigh/rolement',
                        component: result.Rolement
                    },
                    {
                        name: '学校与专业群管理',
                        path: '/main/doublehigh/professionGroup',
                        component: result.ProfessionGroup
                    },
                    {
                        name: '角色权限设置',
                        path: '/main/doublehigh/roleauthorization/:id',
                        component: result.Roleauthorization
                    },
                    {
                        name: '数据同步管理',
                        path: '/main/doublehigh/synchrodatment',
                        component: result.Synchrodatment
                    },
                    {
                        name: '建设数据统计',
                        path: '/main/doublehigh/constructionShuju',
                        component: result.ConstructionShuju,
                    },
                    {
                        name: '建设数据',
                        path: '/main/doublehigh/padiList',
                        component: result.PadiList,
                    },
                    {
                        name: '专家合议结果',
                        path: '/main/doublehigh/expertConsensusShuju',
                        component: result.ExpertConsensusShuju,
                    },
                    {
                        name: '绩效指标分析',
                        path: '/main/doublehigh/analysisPerformanceShuju',
                        component: result.AnalysisPerformanceShuju,
                    },
                    {
                        name: '院校双高计划建设情况',
                        path: '/main/doublehigh/collegesUniversitiesshuju',
                        component: result.CollegesUniversitiesShuju,
                    },
                    {
                        name: '绩效指标完成度',
                        path: '/main/doublehigh/completionIndicatorsshuju',
                        component: result.CompletionIndicatorsShuju,
                    },
                    {
                        name: '评分情况明细-互评',
                        path: '/main/doublehigh/detailsPeerShuju',
                        component: result.DetailsPeerShuju,
                    },
                    {
                        name: '文档管理',
                        path: '/main/doublehigh/documentManagementa',
                        component: result.DocumentManagementa,
                    },
                    {
                        name: '年度绩效详情',
                        path: '/main/doublehigh/specificDetails',
                        component: result.SpecificDetails,
                    },
                    {
                        name: '任务管理-绩效管理',
                        path: '/main/doublehigh/specificDetails',
                        component: result.SpecificDetails,
                    },
                    {
                        name: '任务管理-绩效管理详情',
                        path: '/main/doublehigh/performanceDetailsLook',
                        component: result.PerformanceDetailsLook,
                    },
                    {
                        name: '分配用户',
                        path: '/main/doublehigh/distributionuser/:id',
                        component: result.Distributionuser,
                    },
                    {
                        name: '菜单为空时',
                        path: '/main/doublehigh/workbench',
                        component: result.MenuListNull,
                    },
                    {
                        name: '建设任务管理列表',
                        path: '/main/doublehigh/projectlist',
                        component: result.Projectlist,
                    },
                    {
                        name: '支出填报(建设任务)',
                        path: '/main/doublehigh/project/:projectId/spendingallowed',
                        component: result.Spendingallowed,
                    },
                    {
                        name: '支出填报(任务)',
                        path: '/main/doublehigh/project/:projectId/:taskId/spendingallowed',
                        component: result.Spendingallowed,
                    },
                    {
                        name: '建设任务管理概括',
                        path: '/main/doublehigh/project/:id',
                        component: result.Project,
                    },
                    {
                        name: '任务主页面',
                        path: '/main/doublehigh/renwu',
                        component: result.RenWu,
                    },
                    {
                        name: '资金支出明细（任务）',
                        path: '/main/doublehigh/operation/:projectId/:taskId/financialdetailsone',
                        component: result.Financialdetailsone,
                    },
                    {
                        name: '任务详情',
                        path: '/main/doublehigh/operation/:projectId/:taskId',
                        component: result.Operation,
                    },
                    {
                        name: '绩效管理列表',
                        path: '/main/doublehigh/performancelist',
                        component: result.Performancelist,
                    },
                    {
                        name: '绩效管理详情',
                        path: '/main/doublehigh/performancedetails/:id',
                        component: result.PerformanceDetails,
                    },
                    {
                        name: '资金管理',
                        path: '/main/doublehigh/fundsmanagement',
                        component: result.Fundsmanagement,
                    },
                    {
                        name: '查看资金支出',
                        path: '/main/doublehigh/capitalexpenditurereview/:id',
                        component: result.Capitalexpenditurereview,
                    },
                    {
                        name: '资金支出待审批' ,
                        path: '/main/doublehigh/detailspendingapproval',
                        component: result.Detailspendingapproval,
                    },
                    {
                        name: '资金支出已审批' ,
                        path: '/main/doublehigh/approvedDetails',
                        component: result.ApprovedDetails,
                    },
                    {
                        name: '预算设置二级',
                        path: '/main/doublehigh/budgetsettingLeveltwo/:id/:parentId',
                        component: result.BudgetsettingLeveltwo,
                    },
                    // {
                    //     name: '双师队伍',
                    //     path: '/main/doublehigh/doubleteam',
                    //     component: result.Doubleteam,
                    // },
                    {
                        name: '资金任务列表(从资金模块跳的)',
                        path: '/main/doublehigh/doubleteam/:id/:projectName',
                        component: result.Doubleteam,
                    },
                    {
                        name: '任务与绩效进展走势',
                        path: '/main/doublehigh/taskProgress',
                        component: result.Taskprogress,
                    },

                    {
                        name: '数据统计',
                        path: '/main/doublehigh/dataStatisticsShow',
                        component: result.DataStatisticsShow,
                    },

                    {
                        name: '任务统计',
                        path: '/main/doublehigh/taskStatistical',
                        component: result.Taskstatistical,
                    },
                    {
                        name: '各建设任务支出占比',
                        path: '/main/doublehigh/taskspendingpercent',
                        component: result.Taskspendingpercent,

                    },
                    {
                        name: '绩效指标完成度',
                        path: '/main/doublehigh/performanceIndicator',
                        component: result.Performanceindicator,
                    },
                    {
                        name: '绩效指标达标情况',
                        path: '/main/doublehigh/taskachievementofindicators',
                        component: result.Taskachievementofindicators,
                    },
                    {
                        name: '未命名',
                        path: '/main/doublehigh/unnamed',
                        component: result.Unnamed,
                    },
                    {
                        name: '未命名报表',
                        path: '/main/doublehigh/unnamedBlocks',
                        component: result.Unnamedblocks,
                    },
                    {
                        name: '自定义报表详情',
                        path: '/main/doublehigh/chart/:chartId',
                        component: result.Unnamedblocks,
                    },
                    {
                        name: '任务与绩效进展走势_编辑',
                        path: '/main/doublehigh/editorUnnameds',
                        component: result.Editorunnameds,
                    },
                    {
                        name: '任务统计_编辑',
                        path: '/main/doublehigh/editortaskStatistical',
                        component: result.Editortaskstatistical,
                    },
                    {
                        name: '绩效指标完成度编辑',
                        path: '/main/doublehigh/editorPerformanceindicator',
                        component: result.Editorperformanceindicator,
                    },
                    {
                        name: '数据大屏',
                        path: '/main/doublehigh/dataScreenPage',
                        component: result.DataScreenPage,
                    },
                    {
                        name: '绩效指标达标情况-编辑',
                        path: '/main/doublehigh/edittaskachievementofindicators',
                        component: result.Edittaskachievementofindicators,
                    }, {
                        name: '各建设任务支出占比-编辑',
                        path: '/main/doublehigh/edittaskspendingpercent',
                        component: result.Edittaskspendingpercent,
                    },
                    {
                        name: '建设任务总进度-概览',
                        path: '/main/doublehigh/constructionscheduleview',
                        component: result.Constructionscheduleview,
                    },
                    {
                        name: '建设任务总进度-概览-编辑',
                        path: '/main/doublehigh/editconstructionscheduleview',
                        component: result.Editconstructionscheduleview,
                    },



                ]
            },
            {
                name: '双高任务智能管理',
                path: '/main/doublehigh',
                component: result.Doublehigh,
                children: [
                    {
                        name: '建设任务管理列表',
                        path: '/main/doublehigh/projectlist',
                        component: result.Projectlist,
                    },
                    {
                        name: '建设任务管理概括',
                        path: '/main/doublehigh/project/:id',
                        component: result.Project,
                    },
                    {
                        name: '任务主页面',
                        path: '/main/doublehigh/renwu',
                        component: result.RenWu,
                    },
                    {
                        name: '任务详情',
                        path: '/main/doublehigh/operation',
                        component: result.OperationWancheng,
                    },

                ]
            },
        ]},
    {
        name: '登录',
        path: '/login',
        component: result.Login,
    },
    {
        name: '学校情况',  //分配用户查看第一页学校基本概况
        path: '/situationcontent',
        component: result.Situationcontent,
    },
    {
        name: '学校建设任务与进度',  //分配用户查看第二页学校建设任务与进度
        path: '/schoolschedule',
        component: result.Schoolschedule,
    },
    {
        name: '学校项目总预算',  //分配用户查看第三页学校项目总预算
        path: '/threebudget',
        component: result.Threebudget,
    },
    {
        name: '学校2020年度预算',  //分配用户查看第四页学校2020年度预算
        path: '/fourbudget',
        component: result.Fourbudget,
    },
    {
        name: '学校绩效目标',  //分配用户查看第五页学校绩效目标
        path: '/addschool',
        component: result.Addschool,
    },
    {
        name: '专业群一基本概况',  //分配用户查看专业群一基本概况
        path: '/groupcontent',
        component: result.Groupcontent,
    },
    {
        name: '专业群一任务与进度',  //分配用户查看第二页专业群一任务与进度
        path: '/SpecialtyGroupScheduleOne',
        component: result.Specialtygroupscheduleone,
    },
    {
        name: '专业群一项目总预算',  //分配用户查看第三页专业群一项目总预算
        path: '/SpecialtyGroupThreebudgetOne',
        component: result.Specialtygroupthreebudgetone,
    },
    {
        name: '专业群一项目总预算',  //分配用户查看第四页专业群一2020年度预算
        path: '/SpecialtyGroupFourbudgetOne',
        component: result.Specialtygroupfourbudgetone,
    },

    {
        name: '专业群一绩效目标',  //分配用户查看第五页专业群一绩效目标
        path: '/SpecialtyGroupAddschoolOne',
        component: result.Specialtygroupaddschoolone,
    },
    {
        name: '专业群二基本概况',  //分配用户查看专业群二基本概况
        path: '/GroupContentTwo',
        component: result.Groupcontenttwo,
    },
    {
        name: '专业群二建设任务与进度',  //分配用户查看第二页专业群二建设任务与进度
        path: '/SpecialtyGroupScheduleTwo',
        component: result.Specialtygroupscheduletwo,
    },
    {
        name: '专业群二项目总预算',  //分配用户查看第三页专业群二项目总预算
        path: '/SpecialtyGroupThreebudgetTwo',
        component: result.Specialtygroupthreebudgettwo,
    },
    {
        name: '专业群二2020年度预算',  //分配用户查看第四页专业群二2020年度预算
        path: '/SpecialtyGroupFourbudgetTwo',
        component: result.Specialtygroupfourbudgettwo,
    },
    {
        name: '专业群二绩效目标',  //分配用户查看第五页专业群二绩效目标
        path: '/SpecialtyGroupAddschoolTwo',
        component: result.Specialtygroupaddschooltwo,
    },
    {
        name: '地方政府联系人',  //分配用户查看最后一页地方政府联系人
        path: '/LocalMent',
        component: result.Localment,
    },
    {
        name: '数据大屏展示',  //数据大屏展示
        path: '/dataScreen/:schoolId',
        component: result.DataScreen,
    },
    {
        name: '数据大屏检测',  //双高计划管理与智能检测
        path: '/domesticDetection/:schoolId',
        component: result.DomesticDetection,
    },
];

