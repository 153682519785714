/*关联内容绩效*/
import React, {Component} from "react";
import {
    TreeSelect , Tree ,Button, Table, Row, Input, Col, Select, Modal, Form, message, Spin, Icon, Tabs, Checkbox, DatePicker
} from "antd";
import "./index.scss";
// import "../../../styles/global.less"
import {withRouter} from "react-router";
import {getOneLevelDocumentList,getNextLevelDocumentList,addTaskDocumentList} from "../../../../../../../api/renwu/index"
const {Option} = Select;

const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { TreeNode } = Tree;

const { SHOW_PARENT } = TreeSelect;
// import { getList, pass } from '@/api/check/index'

@withRouter
class RelatedContentjx extends Component {
    state = {
        expandedKeys: ['0-0-0', '0-0-1'],
        autoExpandParent: true,
        checkedKeys: ['0-0-0'],
        selectedKeys: [],
        projectId:this.props.projectId,
        taskId:this.props.taskId,
        selectedValue: [],
        treeData:[],
        openVal:false
    };

    componentDidMount() {
        this.props.onRef(this)
        // this.getOneLevelDocumentList()
        setTimeout(()=>{
            this.setState({
                openVal:true
            })
        },300)
    }
    getOneLevelDocumentList = async ()=>{
        await getOneLevelDocumentList({projectId:this.state.projectId,taskId:this.state.taskId}).then(res=>{
            let treeData = []
            if(res){
                for (let i = 0; i < res.length; i++) {
                    let item = res[i]
                    treeData.push({
                        id:item.id,
                        pId:item.parentId,
                        value:item.id,
                        // title:item.resourceName,
                        // title:<div className='treeSelectedD'><span>{this.getIcon(item.isDir,item.fileType)}</span>{item.resourceName}</div>,
                        title:<div className='treeSelectedD'>{this.getIcon(item.isDir,item.fileType)} {item.resourceName}</div>,
                        isLeaf:item.isDir !== 1,
                        disabled: item.isDir === 1,
                    })
                }
            }
            this.setState({treeData:treeData})
        })
    }

    getNextLevelDocumentList = async (id)=>{
        await getNextLevelDocumentList({id:id,taskId:this.state.taskId}).then(res=>{
            let treeData = []
            if(res){
                for (let i = 0; i < res.length; i++) {
                    let item = res[i]
                    treeData.push({
                        id:item.id,
                        pId:item.parentId,
                        value:item.id,
                        // title:item.resourceName,
                        title:<div className='treeSelectedD'>{this.getIcon(item.isDir,item.fileType)} {item.resourceName}</div>,
                        isLeaf:item.isDir !== 1,
                        disabled: item.isDir === 1
                    })
                }
            }
            this.setState({treeData:this.state.treeData.concat(treeData)})

        })
    }

    getIcon = (isDir,fileType)=>{
        if(isDir === 1){
            return <img src={require('@/assets/imgnew/wenjianjia.png')}/>
        }
        else if(fileType === 1){
            return <img src={require('@/assets/imgnew/word.png')}/>
        }
        else if(fileType === 2){
            return <img src={require('@/assets/imgnew/ppt.png')}/>
        }
        else if(fileType === 3){
            return <img src={require('@/assets/imgnew/excel.png')}/>
        }
        else if(fileType === 4){
            return <img src={require('@/assets/imgnew/shipin.png')}/>
        }
        else if(fileType === 5){
            return <img src={require('@/assets/imgnew/yinpin.png')}/>
        }
        else if(fileType === 6){
            return <img src={require('@/assets/imgnew/image.png')}/>
        }
        else if(fileType === 7){
            return <img src={require('@/assets/imgnew/pdf.png')}/>
        }
        else {
            return <img src={require('@/assets/imgnew/weizhi.png')}/>
        }
    }

    getValues = () => {
        return this.state.selectedValue;
    };

    onExpand = expandedKeys => {
        console.log('onExpand', expandedKeys);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onLoadData = treeNode =>

        new Promise(resolve =>
        {
            console.log('treeNode',treeNode)
            const { id } = treeNode.props;
            setTimeout(() => {
                console.log('this.state.treeData',this.state.treeData)
                this.getNextLevelDocumentList(id)
                resolve();
            }, 300);
        });

    onChange = value => {
        console.log('value',value);
        this.setState({ selectedValue:value });
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        let {} = this.state;

        return (
            <div className='AssDocumentation_center' style={{'height':'523px'}}>
                <TreeSelect
                    treeDataSimpleMode
                    dropdownClassName="contTree_warp"
                     treeData={this.state.treeData}
                     onChange={this.onChange}
                     treeCheckable={true}
                     searchPlaceholder={'请添加关联绩效'}
                     style= {{width:'100%'}}
                     loadData={this.onLoadData}
                    open={this.state.openVal}

                />


            </div>

        );
    }
}

RelatedContentjx = Form.create()(RelatedContentjx);
export default RelatedContentjx;
