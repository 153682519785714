/*y预览*/
import React, {Component} from "react";
import {
    Form, Button
} from "antd";
import {withRouter} from "react-router";
import "../readdetails/index.scss";
@withRouter
class ReaddetailsPrew extends Component {
    state = {
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {

    }

    render() {
        const {iframeSrc} = this.props
        return (
            <div className='Readdetails'>
                <div className='details'>
                    <div className='left-title' style={{width:'100%'}}>
                        <iframe style={{width:"100%",marginTop:"20px",height:"720px"}}
                                src={iframeSrc}></iframe>
                    </div>
                </div>
            </div>
        );
    }
}

ReaddetailsPrew = Form.create()(ReaddetailsPrew);
export default ReaddetailsPrew;
